// This component is uses to display the Qualitatio main browser window

import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import ShutterSpeedRoundedIcon from '@mui/icons-material/ShutterSpeedRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ROLES from '../authentication/roleConst';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';
import { Modules } from '../config/enums';
import { useAuthStore } from '../store/auth.store';
import './Homepage.css';

const Homepage = ({ setPageName, allowedModules }) => {
  const { t } = useTranslation();
  setPageName(t('home'));
  const user = useAuthStore((state) => state.user);

  return (
    <PageFrame title={t('home')} Icon={HomeRoundedIcon} particlesActive={true}>
      <div className="menu-page">
        <div className="text-wrapper-5">{t('welcomeToQualiatio')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingModules')}</p>
        <div className="moduleCardWrapper">
          {user?.role === ROLES.ADMIN && (
            <ModulCard
              module={t('systemAdministration')}
              moduleText={t('userManagementAndConfigurationOfData')}
              icon={<HandymanRoundedIcon color="primary" fontSize="large" />}
              href="/basis"
            />
          )}{' '}
          {user?.role === ROLES.ADMIN && allowedModules.includes('Artificial Intelligence') && (
            <ModulCard
              module={t('artificialIntelligence')}
              moduleText={t('trainingAndDeploymentOfAIModels')}
              icon={<ScatterPlotRoundedIcon color="primary" fontSize="large" />}
              href="/artificial_intelligence"
            />
          )}
          {allowedModules.includes('Intelligent Testing') && (
            <ModulCard
              module={t(Modules.INTELLIGENT_TESTING)}
              moduleText={t('configurationOfAnAIbasedTestingStrategy')}
              icon={<VerifiedRoundedIcon color="primary" fontSize="large" />}
              href="/intelligent_testing"
            />
          )}
          {allowedModules.includes('Business Insights') && (
            <ModulCard
              module={t('businessInsights')}
              moduleText={t('performanceMonitoringAndReports')}
              icon={<InsightsRoundedIcon color="primary" fontSize="large" />}
              href="/business_insights"
            />
          )}
          {allowedModules.includes('Efficient Testing') && (
            <ModulCard
              module={t('efficientTesting')}
              moduleText={t('configurationOfAIbasedTestExecution')}
              icon={<ShutterSpeedRoundedIcon color="primary" fontSize="large" />}
              href="/efficient_testing"
            />
          )}
        </div>
      </div>
    </PageFrame>
  );
};

export default Homepage;
