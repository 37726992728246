import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';
import './BusinessInsights.css';

export const BusinessInsights = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('businessInsights'));

  return (
    <PageFrame title={t('businessInsights')} Icon={InsightsRoundedIcon} particlesActive={true}>
      <div className="text-wrapper-5">{t('welcomeToBusinessInsightsModule')}</div>
      <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
      <div className="moduleCardWrapper">
        <ModulCard
          module={t('monitoring')}
          moduleText={t('liveMonitoringOfQualityKPIs')}
          icon={<BarChartRoundedIcon color="primary" fontSize="large" />}
          href="/business_insights/monitor"
        />
        <ModulCard
          module={t('reporting')}
          moduleText={t('derivationOfKPIsIntoReports')}
          icon={<DescriptionRoundedIcon color="primary" fontSize="large" />}
          href="/business_insights/reporter"
        />
      </div>
    </PageFrame>
  );
};

export default BusinessInsights;
