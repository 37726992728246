import { Visibility, VisibilityOff } from '@mui/icons-material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './KafkaConnectionForm.css';


export default function KafkaConnectionForm({ readonly, formik }) {
    /** LANGUAGE */
    const { t } = useTranslation();;
    /* PASSWORD VISIBILITY */
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword((prevShow) => !prevShow);
    };

    /* FORM HELPERS */
    const [show, setShow] = useState({})
    const handleClick = (consumer) => {
        if (consumer) {
            setShow((prevShow) => ({ ...prevShow, consumer: !show.consumer }));
        } else {
            setShow((prevShow) => ({ ...prevShow, noConsumer: !show.noConsumer }));
        }
    }

    return (
        <Box>
            <Typography align='left' variant='body2' textAlign='center'>
                {t("kafkaConnectionProperties")}
            </Typography>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item sm={12} container justifyContent="center">
                    {/* FIRST PAGE */}
                    <FormControl required={true} fullWidth>
                        <TextField
                            id="kafka.bootstrapServers"
                            name="kafka.bootstrapServers"
                            placeholder="yourServer:yourPort"
                            label="Bootstrap Server"
                            value={formik.values.kafka.bootstrapServers}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.bootstrapServers &&
                                Boolean(formik.errors.kafka?.bootstrapServers)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.bootstrapServers && formik.errors.kafka?.bootstrapServers && (
                            <FormHelperText error={true}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {formik.errors.kafka.bootstrapServers}
                                </Typography>
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item sm={12} container justifyContent="center">
                    <FormControl required={true} fullWidth>
                        <TextField
                            id="kafka.topic"
                            name="kafka.topic"
                            placeholder="yourTopic"
                            label="Topic"
                            value={formik.values.kafka.topic}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.topic &&
                                Boolean(formik.errors.kafka?.topic)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.topic && formik.errors.kafka?.topic && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.topic}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid item sm={12} container justifyContent="center">
                    <FormControl required={true} fullWidth>
                        <TextField
                            id="kafka.keyConverter"
                            name="kafka.keyConverter"
                            placeholder="org.apache.kafka.connect.storage.StringConverter"
                            label="Key Converter"
                            value={formik.values.kafka.keyConverter}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.keyConverter &&
                                Boolean(formik.errors.kafka?.keyConverter)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.keyConverter && formik.errors.kafka?.keyConverter && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.keyConverter}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Value Converter Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl required={true} fullWidth>
                        <TextField
                            id="kafka.valueConverter"
                            name="kafka.valueConverter"
                            placeholder="org.apache.kafka.connect.storage.StringConverter"
                            label="Value Converter"
                            value={formik.values.kafka.valueConverter}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.valueConverter &&
                                Boolean(formik.errors.kafka?.valueConverter)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.valueConverter && formik.errors.kafka?.valueConverter && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.valueConverter}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Endpoint Identification Algorithm Field */}
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <FormControl
                        required={true}
                        fullWidth
                        error={
                            formik.touched.kafka?.endpointIdentificationAlgorithm &&
                            Boolean(formik.errors.kafka?.endpointIdentificationAlgorithm)
                        }
                    >
                        <Autocomplete
                            id="kafka.endpointIdentificationAlgorithm"
                            name="kafka.endpointIdentificationAlgorithm"
                            options={['', 'http', 'https']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.endpointIdentificationAlgorithm || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.endpointIdentificationAlgorithm', newValue) : formik.setFieldValue('kafka.endpointIdentificationAlgorithm', '')}
                            onBlur={() => formik.setFieldTouched('kafka.endpointIdentificationAlgorithm')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Endpoint Identification Algorithm"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.endpointIdentificationAlgorithm &&
                                        Boolean(formik.errors.kafka?.endpointIdentificationAlgorithm)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.endpointIdentificationAlgorithm && formik.errors.kafka?.endpointIdentificationAlgorithm ? (
                            <FormHelperText error>
                                {formik.errors.kafka.endpointIdentificationAlgorithm}
                            </FormHelperText>
                        ) : null}
                    </FormControl>

                </Grid>
                {/* Security Protocol Field */}
                <Grid item xs={12} sm={6} container justifyContent="center">
                    <FormControl
                        required={true}
                        fullWidth
                        error={
                            formik.touched.kafka?.securityProtocol &&
                            Boolean(formik.errors.kafka?.securityProtocol)
                        }
                    >
                        <Autocomplete
                            id="kafka.securityProtocol"
                            name="kafka.securityProtocol"
                            options={['', 'SASL_SSL']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.securityProtocol || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.securityProtocol', newValue) : formik.setFieldValue('kafka.securityProtocol', '')}
                            onBlur={() => formik.setFieldTouched('kafka.securityProtocol')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Security Protocol"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.securityProtocol &&
                                        Boolean(formik.errors.kafka?.securityProtocol)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.securityProtocol && formik.errors.kafka?.securityProtocol ? (
                            <FormHelperText error>
                                {formik.errors.kafka.securityProtocol}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                </Grid>

                {/* SASL Mechanism Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl
                        fullWidth
                        error={
                            formik.touched.kafka?.saslMechanism &&
                            Boolean(formik.errors.kafka?.saslMechanism)
                        }
                    >
                        <Autocomplete
                            id="kafka.saslMechanism"
                            name="kafka.saslMechanism"
                            options={['', 'PLAIN']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.saslMechanism || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.saslMechanism', newValue) : formik.setFieldValue('kafka.saslMechanism', '')}
                            onBlur={() => formik.setFieldTouched('kafka.saslMechanism')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="SASL Mechanism"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.saslMechanism &&
                                        Boolean(formik.errors.kafka?.saslMechanism)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.saslMechanism && formik.errors.kafka?.saslMechanism ? (
                            <FormHelperText error>
                                {formik.errors.kafka.saslMechanism}
                            </FormHelperText>
                        ) : null}
                    </FormControl>
                </Grid>

                {/* SASL JAAS Config Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.saslJAASConfig"
                            name="kafka.saslJAASConfig"
                            placeholder="org.apache.kafka.common.security.plain.PlainLoginModule"
                            label="SASL JAAS Config"
                            value={formik.values.kafka.saslJAASConfig}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.saslJAASConfig &&
                                Boolean(formik.errors.kafka?.saslJAASConfig)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.saslJAASConfig && formik.errors.kafka?.saslJAASConfig && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.saslJAASConfig}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Username Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.username"
                            name="kafka.username"
                            placeholder="yourUsername"
                            label="Username"
                            value={formik.values.kafka.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.kafka?.username && Boolean(formik.errors.kafka?.username)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.username && formik.errors.kafka?.username && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.username}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Password Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.password"
                            name="kafka.password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="yourPassword"
                            label="Password"
                            value={formik.values.kafka.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={handleClickShowPassword}
                                            color={show.consumer ? "default" : "primary"}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.kafka?.password &&
                                Boolean(formik.errors.kafka?.password)
                            }
                        />
                        {formik.touched.kafka?.password && formik.errors.kafka?.password && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.password}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Grid>

                {/* Consumer Endpoint Identification Algorithm Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl
                        fullWidth
                        error={
                            formik.touched.kafka?.consumerEndpointIdentificationAlgorithm &&
                            Boolean(formik.errors.kafka?.consumerEndpointIdentificationAlgorithm)
                        }
                    >
                        <Autocomplete
                            id="kafka.consumerEndpointIdentificationAlgorithm"
                            name="kafka.consumerEndpointIdentificationAlgorithm"
                            options={['', 'http', 'https']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.consumerEndpointIdentificationAlgorithm || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.consumerEndpointIdentificationAlgorithm', newValue) : formik.setFieldValue('kafka.consumerEndpointIdentificationAlgorithm', '')
                            }
                            onBlur={() =>
                                formik.setFieldTouched('kafka.consumerEndpointIdentificationAlgorithm')
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Consumer Endpoint Identification Algorithm"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.consumerEndpointIdentificationAlgorithm &&
                                        Boolean(formik.errors.kafka?.consumerEndpointIdentificationAlgorithm)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.consumerEndpointIdentificationAlgorithm &&
                            formik.errors.kafka?.consumerEndpointIdentificationAlgorithm ? (
                            <FormHelperText error>
                                {formik.errors.kafka.consumerEndpointIdentificationAlgorithm}
                            </FormHelperText>
                        ) : null}
                    </FormControl>

                </Grid>

                {/* Consumer Security Protocol Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl
                        fullWidth
                        error={
                            formik.touched.kafka?.consumerSecurityProtocol &&
                            Boolean(formik.errors.kafka?.consumerSecurityProtocol)
                        }
                    >
                        <Autocomplete
                            id="kafka.consumerSecurityProtocol"
                            name="kafka.consumerSecurityProtocol"
                            options={['', 'SASL_SSL']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.consumerSecurityProtocol || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.consumerSecurityProtocol', newValue) : formik.setFieldValue('kafka.consumerSecurityProtocol', '')}
                            onBlur={() =>
                                formik.setFieldTouched('kafka.consumerSecurityProtocol')
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Consumer Security Protocol"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.consumerSecurityProtocol &&
                                        Boolean(formik.errors.kafka?.consumerSecurityProtocol)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.consumerSecurityProtocol &&
                            formik.errors.kafka?.consumerSecurityProtocol ? (
                            <FormHelperText error>
                                {formik.errors.kafka.consumerSecurityProtocol}
                            </FormHelperText>
                        ) : null}
                    </FormControl>

                </Grid>

                {/* Consumer SASL Mechanism Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl
                        fullWidth
                        error={
                            formik.touched.kafka?.consumerSaslMechanism &&
                            Boolean(formik.errors.kafka?.consumerSaslMechanism)
                        }
                    >
                        <Autocomplete
                            id="kafka.consumerSaslMechanism"
                            name="kafka.consumerSaslMechanism"
                            options={['', 'PLAIN']}
                            getOptionLabel={(option) => option === '' ? t("none") : option}
                            value={formik.values.kafka.consumerSaslMechanism || ''}
                            onChange={(_, newValue) => newValue ? formik.setFieldValue('kafka.consumerSaslMechanism', newValue) : formik.setFieldValue('kafka.consumerSaslMechanism', '')}
                            onBlur={() =>
                                formik.setFieldTouched('kafka.consumerSaslMechanism')
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Consumer SASL Mechanism"
                                    placeholder={t("select")}
                                    error={
                                        formik.touched.kafka?.consumerSaslMechanism &&
                                        Boolean(formik.errors.kafka?.consumerSaslMechanism)
                                    }
                                />
                            )}
                        />
                        {formik.touched.kafka?.consumerSaslMechanism &&
                            formik.errors.kafka?.consumerSaslMechanism ? (
                            <FormHelperText error>
                                {formik.errors.kafka.consumerSaslMechanism}
                            </FormHelperText>
                        ) : null}
                    </FormControl>

                </Grid>

                {/* Consumer SASL JAAS Config Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.consumerSaslJAASConfig"
                            name="kafka.consumerSaslJAASConfig"
                            placeholder="org.apache.kafka.common.security.plain.PlainLoginModule"
                            label="Consumer SASL JAAS Config"
                            value={formik.values.kafka.consumerSaslJAASConfig}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.consumerSaslJAASConfig &&
                                Boolean(formik.errors.kafka?.consumerSaslJAASConfig)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.consumerSaslJAASConfig &&
                            formik.errors.kafka?.consumerSaslJAASConfig && (
                                <FormHelperText error={true}>
                                    {formik.errors.kafka.consumerSaslJAASConfig}
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>


                {/* Consumer Username Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.consumerUsername"
                            name="kafka.consumerUsername"
                            placeholder="yourUsername"
                            label="Consumer Username"
                            value={formik.values.kafka.consumerUsername}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.consumerUsername &&
                                Boolean(formik.errors.kafka?.consumerUsername)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.consumerUsername &&
                            formik.errors.kafka?.consumerUsername && (
                                <FormHelperText error={true}>
                                    {formik.errors.kafka.consumerUsername}
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>

                {/* Consumer Password Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.consumerPassword"
                            name="kafka.consumerPassword"
                            type={show.consumer ? 'text' : 'password'}
                            placeholder="yourPassword"
                            label="Consumer Password"
                            value={formik.values.kafka.consumerPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleClick(true)}
                                            color={show.consumer ? "default" : "primary"}
                                        >
                                            {show.consumer ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={
                                formik.touched.kafka?.consumerPassword &&
                                Boolean(formik.errors.kafka?.consumerPassword)
                            }
                        />
                        {formik.touched.kafka?.consumerPassword && formik.errors.kafka?.consumerPassword && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.consumerPassword}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Grid>

                {/* Consumer Group ID Field */}
                <Grid item sm={12} container justifyContent="center">
                    <FormControl fullWidth>
                        <TextField
                            id="kafka.consumerGroupID"
                            name="kafka.consumerGroupID"
                            placeholder="yourGroupID"
                            label="Consumer Group ID"
                            value={formik.values.kafka.consumerGroupID}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.kafka?.consumerGroupID &&
                                Boolean(formik.errors.kafka?.consumerGroupID)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <CreateOutlinedIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {formik.touched.kafka?.consumerGroupID && formik.errors.kafka?.consumerGroupID && (
                            <FormHelperText error={true}>
                                {formik.errors.kafka.consumerGroupID}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}