import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import { Box, Checkbox } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import {
  defectDescriptionFieldsEndpoint,
  getQualityStationMonitoringDataEndpoint,
  getQualityStationsEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDropdown from '../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioToggleGroup, {
  QualitatioToggleButton,
} from '../../components/QualitatioToggleGroup/QualitatioToggleGroup';
import { TableToolbar } from '../../components/TableToolbar/TableToolbar';
import GaugeChart from '../../components/dist/index';
import './Monitor.css';

export const Monitor = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('monitoring'));

  const [qualityStations, setQualityStations] = useState([]);
  const [selectedQualityStation, setSelectedQualityStation] = useState('all');
  const [defectDescriptionFields, setDefectDescriptionFields] = useState([]);

  const timeOptions = [
    { value: { startTime: '-1h', endTime: 'now' }, label: 'Letzte Stunde' },
    { value: { startTime: '@d', endTime: 'now' }, label: 'Heute' },
    { value: { startTime: '@d-1d', endTime: '@d' }, label: 'Gestern' },
    { value: { startTime: '@w', endTime: 'now' }, label: 'Aktuelle Woche' },
    { value: { startTime: '@w-1w', endTime: '@w' }, label: 'Letzte Woche' },
    { value: { startTime: '@mon', endTime: 'now' }, label: 'Aktueller Monat' },
    { value: { startTime: '@mon-1mon', endTime: '@mon' }, label: 'Letzter Monat' },
    { value: { startTime: '@y', endTime: 'now' }, label: 'Aktuelles Jahr' },
    { value: { startTime: '@y-1y', endTime: '@y' }, label: 'Letztes Jahr' },
  ];
  const [timePeriod, setTimePeriod] = useState({ startTime: '@d', endTime: 'now' });

  const [numberChecks, setNumberChecks] = useState(0);
  const [numberProducts, setNumberProducts] = useState(0);
  const [numberDefects, setNumberDefects] = useState(0);
  const [dpu, setDpu] = useState(0);

  const [chartNames, setChartNames] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [selectedDefectDescriptionField, setSelectedDefectDescriptionField] = useState('');
  const [top5Defects, setTop5Defects] = useState([]);
  const matchingDefects = top5Defects.find(
    (defect) => defect.defectDescriptionField === selectedDefectDescriptionField,
  );
  const defectsData = matchingDefects ? matchingDefects.top5Defects : [];
  const transformedDataset = defectsData.map((defect) => {
    return {
      // cut off the defectField after 10 characters and add "..." if it's longer
      defectField: defect[0],
      count: Number(defect[1]),
    };
  });

  const [animateUpdateButton, setAnimateUpdateButton] = useState(false);

  const handleAnimation = () => {
    setAnimateUpdateButton(true);
    setTimeout(() => setAnimateUpdateButton(false), 2000);
  };

  useEffect(() => {
    getQualityStations();
    getDefectDescriptionFields();
    getQualityStationMonitoringData();

    const intervalId = setInterval(() => {
      getQualityStationMonitoringData();
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const getQualityStations = async () => {
    try {
      const response = await axiosClient.get(getQualityStationsEndpoint);
      setQualityStations(['all', ...response.data.qualityStations]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getQualityStationMonitoringData = async () => {
    try {
      const response = await axiosClient.post(getQualityStationMonitoringDataEndpoint, {
        qualityStationName: selectedQualityStation,
        timePeriod: timePeriod,
        timeInterval: getSuitableTimeInterval(timePeriod),
      });
      setNumberChecks(response.data.kpiData.numberChecks || 0);
      setNumberProducts(response.data.kpiData.numberProducts || 0);
      setNumberDefects(response.data.kpiData.numberDefects || 0);
      setDpu(response.data.kpiData.dpu || 0);
      setChartNames(response.data.timeseriesData.fields || []);
      setChartData(response.data.timeseriesData.rows || []);
      setTop5Defects(response.data.top5Defects || []);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionFields = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionFieldsEndpoint);
      setDefectDescriptionFields(response.data.defectDescriptionFields);
      setSelectedDefectDescriptionField(response.data?.defectDescriptionFields[0] || '');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getSuitableTimeInterval = (timePeriod) => {
    let interval;

    // Here's where we modify the interval determining logic:
    const selectedOptionLabel = timeOptions.find(
      (option) =>
        option.value.startTime === timePeriod.startTime &&
        option.value.endTime === timePeriod.endTime,
    )?.label;

    switch (selectedOptionLabel) {
      case 'Letzte Stunde':
        interval = '5m';
        break;
      case 'Heute':
      case 'Gestern':
        interval = '1h';
        break;
      case 'Aktuelles Jahr':
      case 'Letztes Jahr':
        interval = '1mon';
        break;
      case 'Aktueller Monat':
      case 'Letzter Monat':
        interval = '1d';
        break;
      default:
        interval = '1d';
    }

    return interval;
  };

  const time = chartData?.map((row) => new Date(row[0]));
  // if (chartData.length === 0) fill it with zeros in length of time
  const series = chartNames
    .filter((name) => name !== ' time')
    .map((name, index) => {
      return {
        data: chartData.map((row) => parseFloat(row[index + 1])), // index + 1 because we've skipped the time column
        type: 'line',
        label: name,
        area: true,
        stack: 'total',
        showMark: false,
      };
    });

  const chartSetting = {
    xAxis: [
      {
        label: 'Häufigkeit',
      },
    ],
    width: 500,
    height: 400,
  };

  return (
    <PageFrame title={t('monitoring')} Icon={BarChartRoundedIcon}>
      <div className="monitor">
        <TableToolbar
          className="table-toolbar-header"
          divClassName="table-toolbar-2"
          text="Prüfstation und Zeitraum auswählen"
        />
        <div className="monitor-content-header">
          <QualitatioDropdown
            label={t('testPeriod')}
            options={timeOptions}
            value={{
              value: timePeriod,
              label: timeOptions.find(
                (option) =>
                  option.value.startTime === timePeriod.startTime &&
                  option.value.endTime === timePeriod.endTime,
              )?.label,
            }}
            size="lg"
            onChange={(event, newValue) => {
              setTimePeriod(newValue?.value || { startTime: '@d', endTime: 'now' });
              handleAnimation();
            }}
            width={'20%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox
                    variant="qualitatio"
                    checked={
                      option.value.startTime === timePeriod.startTime &&
                      option.value.endTime === timePeriod.endTime
                    }
                  />
                </Box>
              </Box>
            )}
          />
          <QualitatioDropdown
            label={t('testStation')}
            options={
              qualityStations.map((qualityStation) => ({
                value: qualityStation,
                label: qualityStation === 'all' ? 'Alle' : qualityStation,
              })) || []
            }
            value={{
              value: selectedQualityStation,
              label: selectedQualityStation === 'all' ? 'Alle' : selectedQualityStation,
            }}
            size="lg"
            onChange={(event, newValue) => {
              setSelectedQualityStation(newValue?.value || 'all');
              handleAnimation();
            }}
            width={'20%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox
                    variant="qualitatio"
                    checked={option.value === selectedQualityStation}
                  />
                </Box>
              </Box>
            )}
          />
          <QualitatioButton
            text={t('update')}
            order="primary"
            onClick={getQualityStationMonitoringData}
            className={animateUpdateButton ? 'animate' : ''}
            width={'17%'}
            height={'100%'}
            fontSize={'calc(12px + 0.25vw)'}
          />
        </div>
        <div className="monitor-content-details">
          <div className="upper-two-details">
            <div className="key-performance-indicators">
              <TableToolbar
                className="table-toolbar-sub"
                divClassName="table-toolbar-2"
                text="Key Performance Indicators"
              />
              <div className="frame-16">
                <div className="frame-17">
                  <div className="ellipse">
                    <div className="frame-18">
                      <div className="text-wrapper-6">{dpu.toFixed(2)}</div>
                      <div className="text-wrapper-7">{t('defectsPerUnit')}</div>
                    </div>
                  </div>
                </div>
                <div className="frame-17">
                  <div className="ellipse-2">
                    <div className="frame-19">
                      <div className="text-wrapper-8">{numberProducts}</div>
                      <div className="text-wrapper-9">{t('carNumber')}</div>
                    </div>
                  </div>
                </div>
                <div className="frame-20">
                  <div className="ellipse-3">
                    <div className="frame-21">
                      <div className="text-wrapper-10">{numberChecks}</div>
                      <div className="text-wrapper-11">{t('checkNumber')}</div>
                    </div>
                  </div>
                </div>
                <div className="frame-20">
                  <div className="ellipse-4">
                    <div className="frame-21">
                      <div className="text-wrapper-12">{numberDefects}</div>
                      <div className="text-wrapper-13">{t('defectNumber')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="defects-per-unit">
              <TableToolbar
                className="table-toolbar-sub"
                divClassName="table-toolbar-2"
                text={t('testPerformance')}
              />
              <GaugeChart
                id="performance-gauge-chart"
                nrOfLevels={2}
                arcsLength={[0.33, 0.33, 0.33]}
                colors={['#F03838CC', '#E2A925C9', '#1FBEBC']}
                percent={dpu * 2.222}
                arcPadding={0.02}
                textColor={'black'}
                fontSize={'calc(12px + 0.35vw)'}
                animDelay={500}
                animateDuration={5000}
                needleColor={
                  dpu * 2.222 < 0.33 ? '#F03838CC' : dpu * 2.222 < 0.66 ? '#E2A925C9' : '#1FBEBC'
                }
                formatTextValue={(value) => 'DPU: \n' + (value / 2.222).toFixed(2) + '%'}
              />
            </div>
          </div>
          <div className="lower-two-details">
            <div className="defects-over-time">
              <TableToolbar
                className="table-toolbar-sub"
                divClassName="table-toolbar-2"
                text={t('dpuOverTime')}
              />
              <LineChart
                className="nathan-chart"
                width={400}
                height={300}
                series={series}
                // colors={["rgba(31, 190, 188, 1)", "rgba(204, 232, 231, 1)", "rgba(224, 227, 226, 1)", "rgba(255, 218, 214, 1)"]}
                xAxis={[
                  {
                    scaleType: 'time',
                    data: time,
                    min: time[0]?.getTime(),
                    max: time[time.length - 1]?.getTime(),
                    // valueFormatter: valueFormatter,
                  },
                ]}
                sx={{
                  '--ChartsLegend-itemMarkSize': '10px',
                  '.MuiChartsLegend-label': {
                    fontSize: 'calc(10px + 0.1vw)',
                  },
                }}
              />
            </div>
            <div className="defects-by-category">
              <TableToolbar
                className="table-toolbar-sub"
                divClassName="table-toolbar-2"
                text={t('top5defectCategories')}
              />
              <QualitatioToggleGroup
                color="primary"
                value={selectedDefectDescriptionField}
                exclusive
                onChange={(event, newValue) => setSelectedDefectDescriptionField(newValue)}
                aria-label="Platform"
                style={{ width: '100%' }}
              >
                {defectDescriptionFields?.map((defectDescriptionField) => (
                  <QualitatioToggleButton
                    key={defectDescriptionField}
                    value={defectDescriptionField}
                  >
                    {defectDescriptionField}
                  </QualitatioToggleButton>
                ))}
              </QualitatioToggleGroup>
              {transformedDataset.length === 0 ? (
                <div className="no-data">{t('noDataAvailable')}</div>
              ) : (
                <BarChart
                  dataset={transformedDataset}
                  xAxis={[{ scaleType: 'band', dataKey: 'count', tickMinStep: 100 }]}
                  yAxis={[
                    {
                      scaleType: 'band',
                      dataKey: 'defectField',
                      valueFormatter: (value) => value,
                    },
                  ]}
                  series={[
                    {
                      dataKey: 'count',
                      label: selectedDefectDescriptionField,
                      valueFormatter: (value) => `${value} mal aufgetreten`,
                    },
                  ]}
                  layout="horizontal"
                  margin={{ left: 100, right: 10 } || {}}
                  sx={{
                    '.css-b9xjnq-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                      transform: 'rotate(-50deg)',
                    },
                  }}
                  {...chartSetting}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageFrame>
  );
};

export default Monitor;
