import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../../api/axiosClient';
import {
  getAllEventsFromSplunkEndpoint,
  liveStreamDataEndpoint,
} from '../../../../../api/endpoints';
import EventSelectionModal from './EventSelectionModal';
import LiveStreamDataSelectionModal from '../../components/LiveStreamDataSelectionModal';

// A class to select an event to which a test card can to mapped
const NewEventModal = ({ isOpen, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [liveStreamData, setLiveStreamData] = useState([]);
  const [selectedLiveStreamData, setSelectedLiveStreamData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const [streamFieldModalOpen, setStreamFieldModalOpen] = useState(true);
  const [eventTableModalOpen, setEventTableModalOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      getLiveStreamData();
    }
  }, []);

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getEventData = async (selectedLiveStreamData) => {
    setSelectedLiveStreamData(selectedLiveStreamData);
    const startTime = new Date();
    startTime.setDate(startTime.getDate() - 7);
    const endTime = new Date();
    try {
      if (selectedLiveStreamData && startTime && endTime && moment(startTime).isBefore(endTime)) {
        await fetchEventsFromSplunk(selectedLiveStreamData, startTime, endTime);
        setStep(1);
      } else {
        enqueueSnackbar(t('invalidStartOrEndTime'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchEventsFromSplunk = async (selectedData, startTime, endTime) => {
    try {
      const response = await axiosClient.post(getAllEventsFromSplunkEndpoint, {
        streams: selectedData,
        startTime: startTime,
        endTime: endTime,
      });
      setEventData(response.data.events);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      {step === 0 ? (
        <LiveStreamDataSelectionModal
          streamFieldModalOpen={streamFieldModalOpen && isOpen}
          setStreamFieldModalOpen={setStreamFieldModalOpen}
          liveStreamData={liveStreamData}
          saveSelectedLiveStreamData={getEventData}
        />
      ) : (
        <EventSelectionModal
          eventModalOpen={eventTableModalOpen && isOpen}
          setEventModalOpen={setEventTableModalOpen}
          events={eventData}
          selectedLiveStreamData={selectedLiveStreamData}
          eventSelected={handleSave}
        />
      )}
    </>
  );
};

export default NewEventModal;
