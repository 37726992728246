import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';

export const CardPaperWrapper = ({ paperStyle = {}, gridStyle = {}, children }) => {
  return (
    <Paper
      elevation={3}
      style={{ display: 'flex', justifyContent: 'center', borderRadius: '15px', ...paperStyle }}
    >
      <Grid container style={{ padding: '2px', gap: '2px', ...gridStyle }}>
        {children}
      </Grid>
    </Paper>
  );
};

CardPaperWrapper.propTypes = {
  paperStyle: PropTypes.object,
  gridStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default CardPaperWrapper;
