/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const QualitatioSpinner = ({ className = "", ...props }) => {
  let classes = "spinner";
  if (className)
    classes += ` ${className}`;
  return (
    <div className={classes} style={{ backgroundImage: 'url(/images/spinner.svg)' }} {...props} />
  );
};

export default QualitatioSpinner