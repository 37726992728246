import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import axiosClient from '../api/axiosClient';
import {
  getManageUsersEndpoint,
  loginEndpoint,
  logoutEndpoint,
  refreshTokenEndpoint,
  registerEndpoint,
  verifySessionEndpoint,
} from '../api/endpoints';
import i18n from '../language_settings/i18n';

// Zustand store for authentication
export const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,

      // Set user and trigger language update if needed
      setUser: (user) => {
        set({ user });
        if (user?.language && user.language !== i18n.language) {
          i18n.changeLanguage(user.language); // Update i18n language if it's different from the current language
        }
      },

      // Login action
      login: async (credentials) => {
        try {
          await axiosClient.post(loginEndpoint, credentials);
          await get().fetchUserStatus(); // Fetch user status after login
        } catch (error) {
          console.error('Login failed:', error);
          throw error;
        }
      },

      // Register action
      register: async (userData) => {
        try {
          const response = await axiosClient.post(registerEndpoint, userData);
          // Uncomment to auto-login after registration
          // await get().login({ email: userData.email, password: userData.password });
          return response.data.user;
        } catch (error) {
          console.error('Registration failed:', error);
          throw error;
        }
      },

      // Logout action
      logout: async () => {
        try {
          await axiosClient.post(logoutEndpoint);
          set({ user: null, isAuthenticated: false });
        } catch (error) {
          //break
          console.error('Logout failed:', error);
          throw error;
        }
      },

      // Fetch user status action
      fetchUserStatus: async () => {
        try {
          const response = await axiosClient.get(verifySessionEndpoint);
          set({
            user: response.data.user,
            isAuthenticated: response.data.isAuthenticated,
          });
          if (response.data.user?.language && response.data.user.language !== i18n.language) {
            i18n.changeLanguage(response.data.user.language);
          }
        } catch (error) {
          set({ user: null, isAuthenticated: false });
        }
      },

      // Refresh token action
      refreshToken: async () => {
        try {
          await axiosClient.post(refreshTokenEndpoint);
          await get().fetchUserStatus(); // Fetch user status after token refresh
        } catch (error) {
          console.error('Token refresh failed:', error);
          get().logout(); // Logout if token refresh fails
        }
      },

      // Update user action
      updateUser: async () => {
        try {
          const response = await axiosClient.get(getManageUsersEndpoint());
          set({
            user: response.data,
          });
          if (response.data?.language && response.data.language !== i18n.language) {
            i18n.changeLanguage(response.data.language);
          }
        } catch (error) {
          console.error('Update user failed:', error);
          throw error;
        }
      },
    }),
    {
      name: 'auth-storage', // Unique name for local storage
      storage: createJSONStorage(() => localStorage), // Use createJSONStorage for storage option
      onRehydrateStorage: () => (state) => {
        if (state?.user?.language && state.user.language !== i18n.language) {
          i18n.changeLanguage(state.user.language); // Update language when rehydrating from storage if it's different
        }
      },
    }
  )
);
