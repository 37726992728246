import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { outputStreamSubscriptionEndpoint } from '../../../api/endpoints';

const DeleteOutputStreamSubscriptionOverlay = ({
  subscriptionID,
  onClose,
  showDeleteConfirm,
  setOutputStreamSubscriptions,
  reload,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Assuming you're using notistack for snackbars

  const { t } = useTranslation();

  const handleConfirmDelete = async () => {
    try {
      const snackbarKey = enqueueSnackbar(t('deletingSubscription'), {
        variant: 'info',
        persist: true,
      });
      // Proceed with model deletion
      const response = await axiosClient.delete(outputStreamSubscriptionEndpoint, {
        data: {
          subscriptionID: subscriptionID,
        },
      });
      setOutputStreamSubscriptions(response.data.outputStreamSubscriptions);
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('outputStreamSubscriptionSuccessfullyDeleted'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorWhileDeletingOutputStreamSubscription'), { variant: 'error' });
      console.error('Error:', error);
    }
    reload();
    onClose();
  };

  return (
    <Dialog open={showDeleteConfirm} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t('confirmDeletion')}</DialogTitle>
      <DialogContent>{t('confirmStreamDeletionText')}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmDelete} variant="qualitatio">
          {t('delete')}
        </Button>
        <Button onClick={onClose} variant="qualitatio" color="secondary">
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOutputStreamSubscriptionOverlay;
