import CloseIcon from '@mui/icons-material/Close';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { Box, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscCircleFilled } from 'react-icons/vsc';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import axiosClient from '../../api/axiosClient';
import {
  getProductIDsEndpoint,
  getQualityStationImagesEndpoint,
  getQualityStationReportingDataEndpoint,
  getQualityStationsEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDropdown from '../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioEmptyDialog from '../../components/QualitatioEmptyDialog/QualitatioEmptyDialog';
import QualitatioHighlightBox from '../../components/QualitatioHighlightBox/QualitatioHighlightBox';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import { TableToolbar } from '../../components/TableToolbar/TableToolbar';
import './Reporter.css';

export const Reporter = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('reporting'));

  const [qualityStations, setQualityStations] = useState([]);
  const [selectedQualityStation, setSelectedQualityStation] = useState('all');

  const timeOptions = [
    { value: { startTime: '-1h', endTime: 'now' }, label: 'Letzte Stunde' },
    { value: { startTime: '@d', endTime: 'now' }, label: 'Heute' },
    { value: { startTime: '@d-1d', endTime: '@d' }, label: 'Gestern' },
    { value: { startTime: '@w', endTime: 'now' }, label: 'Aktuelle Woche' },
    { value: { startTime: '@w-1w', endTime: '@w' }, label: 'Letzte Woche' },
    { value: { startTime: '@mon', endTime: 'now' }, label: 'Aktueller Monat' },
    { value: { startTime: '@mon-1mon', endTime: '@mon' }, label: 'Letzter Monat' },
    { value: { startTime: '@y', endTime: 'now' }, label: 'Aktuelles Jahr' },
    { value: { startTime: '@y-1y', endTime: '@y' }, label: 'Letztes Jahr' },
  ];
  const [timePeriod, setTimePeriod] = useState({ startTime: '@d', endTime: 'now' });

  const testOptions = [
    { value: 'all', label: 'Alle' },
    { value: 'io', label: 'i. O.' },
    { value: 'nio', label: 'n. i. O.' },
  ];
  const [testOption, setTestOption] = useState('all');

  const [productIDs, setProductIDs] = useState([]);
  const [selectedProductIDs, setSelectedProductIDs] = useState([]);

  const [reportData, setReportData] = useState({ rows: [], fields: [] });

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const openGallery = (images) => {
    const mappedImages = images.map((image) => ({ original: image, thumbnail: image }));
    setGalleryImages(mappedImages);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const [qualityStationImages, setQualityStationImages] = useState([]);
  const getQualityStationImages = async () => {
    try {
      const response = await axiosClient.get(getQualityStationImagesEndpoint);
      setQualityStationImages(response.data.qualityStationsWithImages);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const colors = [
    '#003736',
    '#1fbebc',
    '#008584',
    '#00504f',
    '#6ff7f5',
    '#00a1a0',
    '#4ddad8',
    '#006a69',
  ];

  useEffect(() => {
    getQualityStationImages();
  }, []);

  const fieldsToColumns = (fields) => {
    return fields.map((field) => ({
      field: field,
      headerName: getTableHeader(field),
      width: field === 'defectComment' || field === 'defectImages' ? 300 : 150,
      renderCell: (params) => {
        if (field === 'defectDetected') {
          return (
            <QualitatioHighlightBox
              text={params.value}
              color={params.value === 'i. O.' ? '#1fbebc' : '#ba1a1a'}
              icon={params.value === 'i. O.' ? <VscCircleFilled /> : <CloseIcon />}
              maxHeight={'25px'}
            />
          );
        }

        if (field === 'qualityStation') {
          return (
            <QualitatioHighlightBox
              text={params.value}
              color={colors[qualityStations.indexOf(params.value) % colors.length]}
              icon={
                <img
                  src={
                    qualityStationImages.find(
                      (qualityStation) => qualityStation.name === params.value,
                    )?.image
                  }
                  alt={params.value}
                  style={{ borderRadius: '50%', objectFit: 'cover', width: '20px', height: '20px' }}
                />
              }
              maxHeight={'25px'}
            />
          );
        }

        if (field === 'testImages' || field === 'defectImages') {
          const images = params.value
            ?.split('http')
            .filter((image) => image.length > 0)
            .map((image) => ('http' + image).trim());

          return (
            <>
              {images?.slice(0, 3).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={image}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: '5px', cursor: 'pointer', marginRight: '5px' }}
                  onClick={() => {
                    setGalleryOpen(true);
                    openGallery(images);
                  }}
                />
              ))}
              <QualitatioEmptyDialog open={isGalleryOpen} onClose={() => setGalleryOpen(false)}>
                <div className="gallery-wrapper">
                  <Gallery items={galleryImages} />
                </div>
              </QualitatioEmptyDialog>
            </>
          );
        }

        return <>{params.value}</>;
      },
    }));
  };

  const getTableHeader = (field) => {
    switch (field) {
      case '_time':
        return t('testTime');
      case 'productID':
        return t('productID');
      case 'qualityStation':
        return t('testStation');
      case 'testTitle':
        return t('testTitle');
      case 'testObject':
        return t('testObject');
      case 'testMethod':
        return t('testMethod');
      case 'testLocation':
        return t('testLocation');
      case 'testComment':
        return t('testComment');
      case 'testImages':
        return t('testImages');
      case 'defectDetected':
        return t('testResult');
      case 'defectComment':
        return t('defectComment');
      case 'defectImages':
        return t('defectImages');
      default:
        return field;
    }
  };

  const columns = fieldsToColumns(reportData.fields);

  // Map rows
  const rows = reportData.rows.map((row, index) => {
    const defectDetectedIndex = reportData.fields.indexOf('defectDetected');
    const timeIndex = reportData.fields.indexOf('_time');
    const testImagesIndex = reportData.fields.indexOf('testImages');
    const defectImagesIndex = reportData.fields.indexOf('defectImages');

    let rowObj = {};
    row.forEach((data, idx) => {
      if (idx === defectDetectedIndex) {
        data = data === 'yes' ? 'n. i. O.' : 'i. O.';
      } else if (idx === timeIndex) {
        data = new Date(data).toLocaleString();
      } else if (idx === testImagesIndex || idx === defectImagesIndex) {
        data = data?.replaceAll(', ', ',\n');
      }
      rowObj[reportData.fields[idx]] = data;
    });
    rowObj.id = index; // DataGrid expects a unique 'id' for each row
    return rowObj;
  });

  const [animateUpdateButton, setAnimateUpdateButton] = useState(false);

  const handleAnimation = () => {
    setAnimateUpdateButton(true);
    setTimeout(() => setAnimateUpdateButton(false), 2000);
  };

  useEffect(() => {
    getQualityStations();
    getProductIDs();
    getQualityStationReportingData();
  }, []);

  const getQualityStations = async () => {
    try {
      const response = await axiosClient.get(getQualityStationsEndpoint);
      setQualityStations(['all', ...response.data.qualityStations]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getQualityStationReportingData = async () => {
    try {
      const response = await axiosClient.post(getQualityStationReportingDataEndpoint, {
        qualityStationName: selectedQualityStation,
        timePeriod: timePeriod,
        testOption: testOption,
        productIDs: selectedProductIDs.map((productID) => productID.value),
      });
      setReportData(response.data.reportData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getProductIDs = async () => {
    try {
      const response = await axiosClient.get(getProductIDsEndpoint);
      setProductIDs(
        response.data.productIDs.map((productID) => ({ value: productID, label: productID })),
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <PageFrame title={t('reporting')} Icon={DescriptionRoundedIcon}>
      <div className="reporter">
        <TableToolbar
          className="table-toolbar-header"
          divClassName="table-toolbar-2"
          text={t('chooseParameter')}
        />
        <div className="reporter-content-header">
          <QualitatioDropdown
            label={t('testPeriod')}
            options={timeOptions}
            value={{
              value: timePeriod,
              label: timeOptions.find(
                (option) =>
                  option.value.startTime === timePeriod.startTime &&
                  option.value.endTime === timePeriod.endTime,
              )?.label,
            }}
            size="lg"
            onChange={(event, newValue) => {
              setTimePeriod(newValue?.value || { startTime: '@d', endTime: 'now' });
              handleAnimation();
            }}
            width={'15%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox
                    variant="qualitatio"
                    checked={
                      option.value.startTime === timePeriod.startTime &&
                      option.value.endTime === timePeriod.endTime
                    }
                  />
                </Box>
              </Box>
            )}
          />
          <QualitatioDropdown
            label={t('testStation')}
            options={
              qualityStations.map((qualityStation) => ({
                value: qualityStation,
                label: qualityStation === 'all' ? 'Alle' : qualityStation,
              })) || []
            }
            value={{
              value: selectedQualityStation,
              label: selectedQualityStation === 'all' ? 'Alle' : selectedQualityStation,
            }}
            size="lg"
            onChange={(event, newValue) => {
              setSelectedQualityStation(newValue?.value || 'all');
              handleAnimation();
            }}
            width={'15%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox
                    variant="qualitatio"
                    checked={option.value === selectedQualityStation}
                  />
                </Box>
              </Box>
            )}
          />
          <QualitatioDropdown
            label={t('testResult')}
            options={testOptions}
            value={{
              value: testOption,
              label: testOptions.find((option) => option.value === testOption)?.label,
            }}
            size="lg"
            onChange={(event, newValue) => {
              setTestOption(newValue?.value || 'all');
              handleAnimation();
            }}
            width={'15%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox variant="qualitatio" checked={option.value === testOption} />
                </Box>
              </Box>
            )}
          />
          <QualitatioDropdown
            label={t('productID')}
            options={productIDs}
            value={selectedProductIDs}
            size="lg"
            multiple={true}
            onChange={(event, newValues) => {
              if (newValues) {
                setSelectedProductIDs(newValues);
              } else {
                setSelectedProductIDs([]);
              }
              handleAnimation();
            }}
            width={'15%'}
            minWidth={'200px'}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
                <Box marginLeft={'auto'}>
                  <Checkbox
                    variant="qualitatio"
                    checked={selectedProductIDs.some((selected) => selected.value === option.value)}
                  />
                </Box>
              </Box>
            )}
          />
          <QualitatioButton
            text={t('update')}
            order="primary"
            onClick={getQualityStationReportingData}
            className={animateUpdateButton ? 'animate' : ''}
            width={'12%'}
            minWidth={'150px'}
            height={'100%'}
            fontSize={'calc(12px + 0.25vw)'}
          />
        </div>
        <div className="reporter-content-details">
          <div className="key-performance-indicators">
            <TableToolbar
              className="table-toolbar-sub"
              divClassName="table-toolbar-2"
              text={t('testResultTable')}
            />
            {rows.length === 0 ? (
              <div className="no-data">{t('noDataAvailable')}</div>
            ) : (
              <QualitatioTable rows={rows} columns={columns} height={'50vh'} />
            )}
          </div>
        </div>
      </div>
    </PageFrame>
  );
};

export default Reporter;
