import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import './QualitatioMobileStepper.css';

const QualitatioMobileStepper = ({ totalSteps, controllable, activeStep: activeStepProp }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <MobileStepper
      className="qualitatio-mobile-stepper"
      variant="progress"
      steps={totalSteps}
      position="static"
      activeStep={activeStepProp || activeStep}
      nextButton={
        controllable &&
        <Button size="small" onClick={handleNext} disabled={activeStep === totalSteps - 1}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        controllable &&
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
        </Button>
      }
      sx={{
        '.css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress': { backgroundColor: 'rgba(31, 190, 188, 0.3)' },
        '.css-5xe99f-MuiLinearProgress-bar1': { backgroundColor: 'var(--m-3refprimaryprimary-70)' },
        '.MuiLinearProgress-root': { width: '100%' },
      }}
    />
  );
};

export default QualitatioMobileStepper;
