/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const IconsIo = ({ className }) => {
  return (
    <div className={`icons-io ${className}`}>
      <img
        className="check-mark-in-a"
        alt="Check mark in a"
        src="/images/Check.svg"
      />
    </div>
  );
};
