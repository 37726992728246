import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { inputStreamsEndpoint } from '../../../api/endpoints';
import QualitatioMultiStepForm from '../../../components/QualitatioMultiStepForm/QualitatioMultiStepForm';
import { useAuthStore } from '../../../store/auth.store';
import { connectionSetupInitialValues } from '../../../utils/initFormikValues';
import { valuesChanged } from '../../../utils/utilities';
import { getConnectionSetupValidationSchema } from '../../../utils/validationSchemas';
import ApiConnectionForm from './ApiConnectionForm';
import './ConnectionSetup.css';
import GeneralConnectionForm from './GeneralConnectionForm';
import InputStreamSummary from './InputStreamSummary';
import KafkaConnectionForm from './KafkaConnectionForm';

const TestConnectionProgress = ({ testConnectionLoading }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Reset progress when loading starts
    if (testConnectionLoading) {
      setProgress(0);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = oldProgress + 100 / 33; // Increment to fill up in 33 seconds
          if (newProgress >= 100) {
            clearInterval(timer);
            return 100; // Cap at 100%
          }
          return newProgress;
        });
      }, 1000); // Update every second

      return () => {
        clearInterval(timer); // Cleanup on unmount or when loading stops
      };
    }
  }, [testConnectionLoading]); // Effect runs whenever testConnectionLoading changes

  return testConnectionLoading ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <CircularProgress variant="determinate" value={progress} size={80} />
      <Typography variant="body1" marginTop="2%">
        {t('testingConnection')}
      </Typography>
    </Box>
  ) : null;
};

/**
 * The flow in this component is as follows:
 * 1. The user fills in the form fields and clicks the "Next" button.
 * 2. The form is validated and if there are no errors, the user is taken to the next step.
 * 3. In step 3, the user clicks the "Create Connection" button, and the backend tries to establish a connection with the input source.
 * 4. If the connection is successfully established, the user is taken to the next step, where they can see a summary of the input stream
 * and can change fields if they want to like the product ID field name.
 */
export default function ConnectionSetup({
  isConnectionSetupOpen,
  onConnectionSetupClose,
  setInputStreams,
}) {
  /** LANGUAGE */
  const { t, i18n } = useTranslation();

  /** OTHER VARIABLES */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useAuthStore((state) => state.user);

  /* FORM HELPERS */
  const numPages = 4;
  const [progress, setProgress] = useState(1);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);
  const [testConnectionSuccess, setTestConnectionSuccess] = useState(false);
  const [receivedStream, setReceivedStream] = useState({});

  const formik = useFormik({
    initialValues: connectionSetupInitialValues,
    validationSchema: getConnectionSetupValidationSchema(progress, i18n.language),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      //The on submit will put the inputStream to the server if any changes have been made in the last step
      if (valuesChanged(values, receivedStream)) {
        const streamID = values.id;
        let requestBody = { ...values };
        delete requestBody.lastEvent;
        delete requestBody.firstEvent;
        delete requestBody.size;
        delete requestBody.kafka?.hecToken;
        delete requestBody.api?.hecToken;
        delete requestBody.streamFields;
        delete requestBody.created;
        delete requestBody.updated;
        delete requestBody.id;
        delete requestBody.index;
        //if method is set and to password, delete token
        if (requestBody.api?.authentication?.method === 'password') {
          delete requestBody.api.authentication.credentials.token;
        } else if (requestBody.api?.authentication?.method === 'token') {
          delete requestBody.api.authentication.credentials.username;
          delete requestBody.api.authentication.credentials.password;
        }

        let enqueuedSnackbar;
        try {
          enqueuedSnackbar = enqueueSnackbar(t('startingUpdateOfInputStream'), {
            variant: 'info',
            persist: true,
          });
          const response = await axiosClient.patch(
            `${inputStreamsEndpoint}/${streamID}`,
            requestBody
          );
          if (response.status === 400 && response.data.inputStreams) {
            //this happens when there was an error with the new input stream an a rollback was performed
            setInputStreams(response.data.inputStreams);
            enqueueSnackbar(t('inputStreamRollbackSnack'), { variant: 'error' });
          } else if (response.status === 200 && response.data.inputStreams) {
            enqueueSnackbar(t('inputStreamSuccessfullySaved'), { variant: 'success' });
            setInputStreams(response.data.inputStreams);
            resetForm();
          } else {
            enqueueSnackbar(t('inputStreamCouldNotBeSaved'), { variant: 'error' });
          }
        } catch (error) {
          console.error('Error:', error);
          enqueueSnackbar(t('inputStreamCouldNotBeSaved'), { variant: 'error' });
        } finally {
          closeSnackbar(enqueuedSnackbar);
          setSubmitting(false); // Always reset submitting state, even on error
        }
      } else {
        enqueueSnackbar(t('noChangesMade'), { variant: 'info' });
      }
    },
  });

  const createConnection = async () => {
    try {
      setTestConnectionSuccess(false);
      setTestConnectionLoading(true);
      formik.setSubmitting(true); // Use Formik's setSubmitting to handle UI feedback

      let requestBody = {
        ...formik.values,
        creatorName: user.name,
      };

      const response = await axiosClient.post(inputStreamsEndpoint, requestBody);

      if (response.data.success) {
        setTestConnectionLoading(false);
        setTestConnectionSuccess(true);
        enqueueSnackbar(t('connectionSuccessfullyEstablished'), { variant: 'success' });
        //set the input streams for the table
        setInputStreams(response.data.inputStreams);
        //set the input stream for the summary
        console.log('response.data.inputStream:', response.data.inputStream);
        //set received stream to compare with formik values later
        setReceivedStream(response.data.inputStream);
        formik.setValues(response.data.inputStream);
        setProgress((prevStep) => prevStep + 1);
      } else {
        enqueueSnackbar(t('connectionCouldNotBeEstablishedOrNoDataCouldBeFetched'), {
          variant: 'error',
        });
        setTestConnectionLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('connectionCouldNotBeEstablishedOrNoDataCouldBeFetched'), {
        variant: 'error',
      });
      setTestConnectionLoading(false);
      formik.setErrors({ submit: 'An error occurred when trying to test the connection.' });
    } finally {
      formik.setSubmitting(false); // Reset submitting state
    }
  };

  const handleNext = async () => {
    //submit form if last page
    if (progress === numPages) {
      formik.handleSubmit();
      onConnectionSetupClose();
      return;
    }
    //validate form on first and second page
    if (progress === 1) {
      //set all fields as touched
      formik.setTouched({
        name: true,
        description: true,
        type: true,
        maxSize: true,
        productIDFieldName: true,
      });
    } else if (progress === 2 && formik.values.type === 'kafka') {
      //set all fields as touched
      formik.setTouched({
        kafka: {
          bootstrapServers: true,
          topic: true,
          keyConverter: true,
          valueConverter: true,
          endpointIdentificationAlgorithm: true,
          securityProtocol: true,
          saslMechanism: true,
          saslJAASConfig: true,
          username: true,
          password: true,
          consumerEndpointIdentificationAlgorithm: true,
          consumerSecurityProtocol: true,
          consumerSaslMechanism: true,
          consumerSaslJAASConfig: true,
          consumerUsername: true,
          consumerPassword: true,
          consumerGroupID: true,
        },
      });
    } else if (progress === 2 && formik.values.type === 'api') {
      //set all fields as touched
      formik.setTouched({
        api: {
          url: true,
          port: true,
          securityProtocol: true,
          authentication: {
            method: true,
            credentials: {
              token: true,
              username: true,
              password: true,
            },
          },
        },
      });
    }

    // Manually trigger validation
    const errors = await formik.validateForm();
    // Check if errors object is empty, indicating successful validation
    if (Object.keys(errors).length === 0 && errors.constructor === Object) {
      setProgress((prevStep) => prevStep + 1); // Navigate to the next step
    }
  };

  const nextIsDisabled = progress === 3 && !testConnectionSuccess;

  const backButtonAction = () => {
    if (progress !== 1) {
      if (progress === 3) {
        setTestConnectionSuccess(false);
      }
      setProgress(progress - 1);
    } else {
      setCancelDialogOpen(true);
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setCancelDialogOpen(false);
    onConnectionSetupClose();
  };

  return (
    <QualitatioMultiStepForm
      backButtonAction={backButtonAction}
      forwardButtonAction={handleNext}
      numPages={numPages}
      progress={progress}
      nextIsDisabled={nextIsDisabled}
      previousIsDisabled={testConnectionSuccess}
      cancelForm={handleCancel}
      formTitle={t('setupNewDataSourceConnection')}
      cancelDialogOpen={cancelDialogOpen}
      setCancelDialogOpen={setCancelDialogOpen}
    >
      {progress === 1 && <GeneralConnectionForm readonly={false} formik={formik} />}

      {progress === 2 && formik.values.type === 'kafka' && (
        <KafkaConnectionForm readonly={false} formik={formik} />
      )}

      {progress === 2 && formik.values.type === 'api' && (
        <ApiConnectionForm readonly={false} formik={formik} />
      )}

      {progress === 3 && (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {testConnectionLoading && (
              <TestConnectionProgress testConnectionLoading={testConnectionLoading} />
            )}
            {testConnectionSuccess && (
              <Typography variant="body1" marginTop="15%">
                {t('inputStreamSuccessfullySetups')}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="qualitatio"
              size="large"
              type="submit"
              onClick={createConnection}
              disabled={testConnectionLoading}
            >
              {testConnectionSuccess ? t('testConnectionAgain') : t('testConnection')}
            </Button>
          </Grid>
        </Grid>
      )}

      {progress === 4 && (
        <Box>
          <InputStreamSummary readonly={true} formik={formik} />
        </Box>
      )}
    </QualitatioMultiStepForm>
  );
}
