import React from "react";
import { useTranslation } from 'react-i18next';
import { BottomBarWhite } from "../../../components/BottomBarWhite";
import { QualitatioSpinner } from "../../../components/QualitatioSpinner";
import "./CalculateTesting.css";

export default function CalculateTesting({ productID, qualityStation, recommenderMenuOpen, setRecommenderMenuOpen }) {
    const { t } = useTranslation();

    return (
        <>
            {recommenderMenuOpen && <div className="calculate-testing-layout">
                <div className="calculate-testing-header">
                    {t("configureTest")}...
                </div>
                <div className="calculate-testing-spinner">
                    <QualitatioSpinner
                        className="spinner-with-BG-instance"
                    />
                </div>
                <div className="calculate-testing-text">
                    {t("createTest")}
                    <div className="calculate-testing-text-details">
                        {t("for")}
                        <span className="product-id">{productID}</span>
                        {t("at")}
                        <span className="quality-station">{qualityStation}</span>...
                    </div>
                </div>
                <div className="calculate-testing-bottom-bar">
                    <BottomBarWhite
                        className="bottom-bar-white-instance"
                    />
                </div>
            </div>}
        </>
    )
};