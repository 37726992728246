import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './OverlapChart.module.css';

export default function OverlapChart({ dataLine, centerLine, controlLimit, timestamps }) {
  const ref = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    if (!dataLine || !controlLimit || !timestamps) return;

    const margins = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 800 - margins.left - margins.right;
    const height = 400 - margins.top - margins.bottom;

    const svg = d3.select(ref.current);
    svg.selectAll('*').remove(); // Clear SVG on each render

    const g = svg.append('g').attr('transform', `translate(${margins.left},${margins.top})`);

    // Scale setup
    const x = d3.scaleTime().domain(d3.extent(timestamps)).range([0, width]);
    const y = d3
      .scaleLinear()
      .domain([d3.min([...dataLine, ...controlLimit]), d3.max([...dataLine, ...controlLimit])])
      .nice()
      .range([height, 0]);

    // Line generators
    const lineData = d3
      .line()
      .x((d) => x(d.timestamp))
      .y((d) => y(d.data));

    // Append the areas
    const dataArea = d3
      .area()
      .x((d) => x(d.timestamp))
      .y0(height)
      .y1((d) => y(d.dataPoint));

    const limitArea = d3
      .area()
      .x((d) => x(d.timestamp))
      .y0(height)
      .y1((d) => y(d.controlLimit));

    // Combine data for area
    const combinedData = timestamps.map((t, i) => ({
      timestamp: t,
      dataPoint: dataLine[i],
      controlLimit: controlLimit[i],
      centerLine: centerLine[i],
    }));

    // Threshold for maximum gap (x difference) allowed for line connectivity
    const xThreshold = 600000; // 10 minutes. Should be dynamic based on data

    // Constructing segments of connected lines based on threshold
    let segments = [];
    let currentSegment = [];
    combinedData.forEach((currentData, index) => {
      if (
        index > 0 &&
        combinedData[index].timestamp - combinedData[index - 1].timestamp > xThreshold
      ) {
        segments.push(currentSegment);
        currentSegment = [];
      }
      currentSegment.push(currentData);
    });
    if (currentSegment.length > 0) segments.push(currentSegment);

    // Initialize a counter for label numbers
    let labelCounter = 1;

    // Draw the areas and lines for each segment
    segments.forEach((seg) => {
      //g.append('path').datum(seg).attr('opacity', 0.5).attr('fill', 'red').attr('d', dataArea);

      //g.append('path').datum(seg).attr('fill', 'white').attr('d', limitArea);

      g.append('path')
        .datum(
          seg.map((d) => ({
            timestamp: d.timestamp,
            data: d.dataPoint,
          }))
        )
        .attr('fill', 'none')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', 1.5)
        .attr('d', lineData);

      g.append('path')
        .datum(
          seg.map((d) => ({
            timestamp: d.timestamp,
            data: d.centerLine,
          }))
        )
        .attr('fill', 'none')
        .attr('stroke', 'gray')
        .attr('stroke-dasharray', '5,5')
        .attr('d', lineData);

      g.append('path')
        .datum(
          seg.map((d) => ({
            timestamp: d.timestamp,
            data: d.controlLimit,
          }))
        )
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', 1.5)
        .attr('d', lineData);

      // Add labels above the datapoints where the datapoint is greater than the control limit
      seg.forEach((d) => {
        if (d.dataPoint > d.controlLimit && d.controlLimit !== 0) {
          g.append('text')
            .attr('x', x(d.timestamp))
            .attr('y', y(d.dataPoint) - 5) // Position label slightly above the datapoint
            .attr('text-anchor', 'middle')
            .attr('fill', 'black')
            .attr('font-weight', 'bold')
            .text(labelCounter++);
        }
      });
    });

    // ------------------- Highlight areas of alert -----------------
    // Identify segments where dataPoint exceeds controlLimit
    let highlightSegments = [];
    let segmentStart = null;

    combinedData.forEach((d, i) => {
      if (d.dataPoint > d.controlLimit && d.controlLimit !== 0) {
        if (segmentStart === null) {
          segmentStart = d.timestamp;
        }
      } else {
        if (segmentStart !== null) {
          highlightSegments.push([segmentStart, d.timestamp]);
          segmentStart = null;
        }
      }
    });

    // If a segment is ongoing at the end, close it with the last timestamp
    if (segmentStart !== null) {
      highlightSegments.push([segmentStart, timestamps[timestamps.length - 1]]);
    }
    // Draw highlighted areas
    highlightSegments.forEach(([start, end]) => {
      g.append('rect')
        .attr('x', x(start))
        .attr('width', x(end) - x(start))
        .attr('y', 0)
        .attr('height', height)
        .attr('fill', 'red')
        .attr('opacity', 0.3);
    });

    // ------------------- Handlers for mouse interaction -----------------
    const mouseG = g.append('g').attr('class', 'mouse-over-effects');

    // This rect captures mouse movements
    mouseG
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mousemove', onMouseMove)
      .on('mouseout', () => {
        mouseG.select('#mouse-line').style('opacity', '0');
        mouseG.selectAll('.mouse-per-line circle').style('opacity', '0');
        d3.select(tooltipRef.current).style('display', 'none');
      });

    let mouseLine = mouseG
      .append('line')
      .attr('id', 'mouse-line')
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '3')
      .style('opacity', '0.5');

    const dataPoints = mouseG.append('g').attr('class', 'mouse-per-line');

    const datapointCircle = mouseG
      .append('circle')
      .attr('r', 7)
      .attr('stroke', 'blue')
      .attr('fill', 'none')
      .style('opacity', '0');

    const centerLineCircle = mouseG
      .append('circle')
      .attr('r', 7)
      .attr('stroke', 'gray')
      .attr('fill', 'none')
      .style('opacity', '0');

    const limitCircle = mouseG
      .append('circle')
      .attr('r', 7)
      .attr('stroke', 'red')
      .attr('fill', 'none')
      .style('opacity', '0');

    function onMouseMove(event) {
      if (!combinedData || combinedData.length === 0) return;
      const pointer = d3.pointer(event);
      const mouseX = pointer[0];
      const x0 = x.invert(mouseX); // translate mouse position to corresponding x (date)

      const bisect = d3.bisector((d) => d.timestamp).left; // assume data is sorted
      const idx = bisect(combinedData, x0, 1);
      let closestData;
      if (combinedData.length === 1) {
        closestData = combinedData[0];
      } else {
        const d0 = combinedData[idx - 1];
        const d1 = combinedData[idx];
        if (idx === 0) {
          closestData = d1;
        } else if (idx >= combinedData.length) {
          closestData = d0;
        } else {
          closestData = x0 - d0.timestamp > d1.timestamp - x0 ? d1 : d0;
        }
      }

      datapointCircle
        .style('opacity', '1')
        .attr('cx', x(closestData.timestamp))
        .attr('cy', y(closestData.dataPoint));

      centerLineCircle
        .style('opacity', '1')
        .attr('cx', x(closestData.timestamp))
        .attr('cy', y(closestData.centerLine));

      limitCircle
        .style('opacity', '1')
        .attr('cx', x(closestData.timestamp))
        .attr('cy', y(closestData.controlLimit));

      dataPoints
        .select('text')
        .text(`Data: ${closestData.dataPoint}, Limit: ${closestData.controlLimit}`)
        .attr('x', x(closestData.timestamp) + 10)
        .attr('y', y(closestData.dataPoint))
        .style('opacity', '1');

      // Format numbers to two decimal places
      const formattedDataPoint = parseFloat(closestData.dataPoint).toFixed(2);
      const formattedControlLimit = parseFloat(closestData.controlLimit).toFixed(2);
      const formattedCenterLine = parseFloat(closestData.centerLine).toFixed(2);

      const tooltipHtml = `
        <span class="${styles.tooltipCircle}" style='background-color: red;'></span> Limit: ${formattedControlLimit}%<br>
        <span class="${styles.tooltipCircle}" style='background-color: gray;'></span> Mean: ${formattedCenterLine}%<br>
        <span class="${styles.tooltipCircle}" style='background-color: blue;'></span> Data: ${formattedDataPoint}%
    `;

      const tooltip = d3.select(tooltipRef.current);
      tooltip
        .style('display', 'block')
        .style('left', `${mouseX}px`)
        .style('top', `${event.pageY + 200}px`)
        .html(tooltipHtml);

      mouseLine
        .attr('x1', x(closestData.timestamp))
        .attr('x2', x(closestData.timestamp))
        .attr('y1', 0)
        .attr('y2', height)
        .style('opacity', 1);
    }

    // -------------------  Legend Setup -----------------
    const legendData = [
      { color: 'steelblue', label: 'Data Point' },
      { color: 'red', label: 'Control Limit' },
      { color: 'gray', label: 'Mean' },
    ];

    const legend = svg.append('g').attr('transform', `translate(${width / 2 - 60}, 10)`);

    legend
      .selectAll(null)
      .data(legendData)
      .enter()
      .append('rect')
      .attr('x', (d, i) => i * 100)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', (d) => d.color);

    legend
      .selectAll(null)
      .data(legendData)
      .enter()
      .append('text')
      .attr('x', (d, i) => i * 100 + 15)
      .attr('y', 10)
      .text((d) => d.label)
      .attr('font-size', '12px')
      .attr('alignment-baseline', 'middle');

    // Append axes
    g.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(x));

    g.append('g').call(d3.axisLeft(y));
  }, [dataLine, controlLimit, timestamps]);

  return (
    <>
      <svg ref={ref} width="800" height="400"></svg>
      <div ref={tooltipRef} className={styles.tooltip} style={{ display: 'none' }}></div>
    </>
  );
}

OverlapChart.propTypes = {
  dataLine: PropTypes.array.isRequired,
  centerLine: PropTypes.array.isRequired,
  controlLimit: PropTypes.array.isRequired,
  timestamps: PropTypes.array.isRequired,
};
