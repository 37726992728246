import React from 'react';
import Rating from '@mui/material/Rating';
import './QualitatioRating.css';

const QualitatioRating = ({ value, onChange, size, ...otherProps }) => {
    return (
        <Rating
            name="qualitatio-rating"
            value={value}
            onChange={onChange}
            className="qualitatio-rating"
            style={{ fontSize: size }}
            {...otherProps}
        />
    );
};

export default QualitatioRating;
