import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import axiosClient from '../../../api/axiosClient';
import { getSPCMonitorSystemsEndpoint } from '../../../api/endpoints';
import QualitatioDateRangePicker from '../../../components/QualitatioDateRangePicker/QualitatioDateRangePicker';
import QualitatioDropdown from '../../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioDropdownList from '../../../components/QualitatioDropdownList/QualitatioDropdownList';
import styles from './DashboardSelector.module.css';

export default function DashboardSelector({
  selectedDateRange,
  setSelectedDateRange,
  selectedMonitorSystemId,
  setSelectedMonitorSystemId,
  selectedFilters,
  setSelectedFilters,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // States:
  const [monitorSystems, setMonitorSystems] = useState([]);
  const [defaultMonitorSystemId, setDefaultMonitorSystemId] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  // Methods:
  const onFiltersChange = (name, value) => {
    setSelectedFilters({ ...selectedFilters, [name]: value ? value : defaultFilterValues[name] });
  };

  // Fetch monitored fields' all values and set default values upon selected monitor system change
  const fetchMonitoredFieldValues = async () => {
    if (!selectedMonitorSystemId) return; // Wait until the selected system is set
    try {
      // Initialize the filter values object
      let allValues = {};
      let defaultValues = {};
      // Fetch the selected monitor system
      const fetchedMonitorSystem = await axiosClient.get(
        getSPCMonitorSystemsEndpoint + '/' + selectedMonitorSystemId
      );
      // Iterate over the monitored filter fields of the selected system
      for (const field of fetchedMonitorSystem.data.filterFields) {
        const fetchedFieldValues = await axiosClient.get(
          getSPCMonitorSystemsEndpoint + '/' + selectedMonitorSystemId + '/fieldValues/' + field
        );
        allValues[field] = fetchedFieldValues.data;
        // Set the default filter values to the first one of the fetched values
        defaultValues[field] = fetchedFieldValues.data[0];
      }
      setFilterValues(allValues);
      setDefaultFilterValues(defaultValues);
      setSelectedFilters(defaultValues);
    } catch (error) {
      enqueueSnackbar(t('ei.errorLoadingSystems'), { variant: 'error' });
      console.error(error);
    }
  };

  const fetchMonitorSystems = async () => {
    try {
      const fetchedSystems = await axiosClient.get(getSPCMonitorSystemsEndpoint);
      setMonitorSystems(fetchedSystems.data);
      if (fetchedSystems.data.length > 0) {
        setDefaultMonitorSystemId(fetchedSystems.data[0].systemId);
        setSelectedMonitorSystemId(fetchedSystems.data[0].systemId);
      }
    } catch (error) {
      enqueueSnackbar(t('ei.errorLoadingSystems'), { variant: 'error' });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMonitorSystems();
  }, []);

  useEffect(() => {
    fetchMonitoredFieldValues();
  }, [selectedMonitorSystemId]);

  return (
    <div className={styles.dashboardSelector}>
      <div className={styles.dashboardMainSelector}>
        <div className="tour-monitor-selector">
          <QualitatioDropdown
            label={t('ei.monitorSystem')}
            options={monitorSystems}
            getOptionLabel={(option) => option?.name || ''}
            value={
              monitorSystems.find((option) => option.systemId === selectedMonitorSystemId) || null
            }
            size="lg"
            onChange={(event, newValue) => {
              setSelectedMonitorSystemId(
                newValue?.systemId ? newValue.systemId : defaultMonitorSystemId
              );
            }}
            width={'20%'}
            minWidth={'200px'}
          />
        </div>
        <div className={`${styles.dashboardDateRangePicker} tour-date-range-selector`}>
          <QualitatioDateRangePicker
            defaultValue={selectedDateRange}
            onDateRangeChange={setSelectedDateRange}
          />
        </div>
      </div>
      {Object.keys(filterValues).length > 0 && (
        <div className="tour-filter-selector">
          <QualitatioDropdownList
            dropdownListValues={filterValues}
            defaultValues={defaultFilterValues}
            selectedDropdownValues={selectedFilters}
            onDropdownListChange={onFiltersChange}
          />
        </div>
      )}
    </div>
  );
}

DashboardSelector.propTypes = {
  selectedDateRange: PropTypes.array.isRequired,
  setSelectedDateRange: PropTypes.func.isRequired,
  selectedMonitorSystemId: PropTypes.string,
  setSelectedMonitorSystemId: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};
