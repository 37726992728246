import { Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ImagePanel = ({ images, backgroundImage, children, heightOffset = 0, cardLayout = false }) => {
  const theme = useTheme();

  // Media queries for visual cardLayout
  const isXXSmallScreenVisual = useMediaQuery('(max-height:420px)');
  const isXSmallScreenVisual = useMediaQuery('(min-height:421px) and (max-height:530px)');
  const isSmallScreenVisual = useMediaQuery('(min-height:531px) and (max-height:700px)');
  const isMediumScreenVisual = useMediaQuery('(min-height:701px) and (max-height:800px)');
  // Media queries for textual cardLayout
  const isXXSmallScreenTextual = useMediaQuery('(max-height:450px)');
  const isXSmallScreenTextual = useMediaQuery('(min-height:451px) and (max-height:510px)');
  const isSmallScreenTextual = useMediaQuery('(min-height:511px) and (max-height:600px)');
  const isMediumScreenTextual = useMediaQuery('(min-height:601px) and (max-height:830px)');

  const getMinHeightNumber = (cardLayout) => {
    if (cardLayout) {
      return isXXSmallScreenVisual
        ? 40
        : isXSmallScreenVisual
          ? 50
          : isSmallScreenVisual
            ? 60
            : isMediumScreenVisual
              ? 70
              : 75;
    } else {
      return isXXSmallScreenTextual
        ? 15
        : isXSmallScreenTextual
          ? 25
          : isSmallScreenTextual
            ? 35
            : isMediumScreenTextual
              ? 45
              : 60;
    }
  };

  const getMinHeightOfImagePanel = (cardLayout, offset) => {
    return (getMinHeightNumber(cardLayout) - offset) + 'vh';
  };

  let minHeight = getMinHeightOfImagePanel(cardLayout, heightOffset);

  return (
    <Grid item xs={12}>
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1vh',
          width: '100%',
          padding: '2px',
          backgroundColor: images?.length === 0 && theme.palette.success.secondary,
          boxShadow: '0px 6.63px 13.25px #1018280d',
          overflow: images?.length !== 0 && 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          spacing={2}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            minHeight: minHeight,
            justifyContent: 'space-between',
          }}
        >
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ImagePanel;
