import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import GeneralSettings from './createRuleAccordionPanels/GeneralSettings';
import MainCondition from './createRuleAccordionPanels/MainCondition';
import RangeSettings from './createRuleAccordionPanels/RangeSettings';
import RuleType from './createRuleAccordionPanels/RuleType';

export default function CreateRuleModalAccordion({
  accordionItems,
  currentIndex,
  setCurrentIndex,
  qualityStations,
  streamFields,
  sidebarOpen,
}) {
  const renderComponent = (componentName) => {
    switch (componentName) {
      case 'ruleType':
        return <RuleType />;
      case 'mainCondition':
        return <MainCondition streamFields={streamFields} sidebarOpen={sidebarOpen} />;
      case 'rangeSettings':
        return <RangeSettings streamFields={streamFields} />;
      case 'generalSettings':
        return <GeneralSettings qualityStations={qualityStations} />;
      default:
        return null;
    }
  };

  let panelHeight = 52.2; // To track the height of a closed panel
  const [expanded, setExpanded] = useState(null); // State to track the opened accordion
  const panelRef = useRef(); // Reference to the accordion panel

  // Measure height of closed accordion panels once after mounting
  useEffect(() => {
    if (panelRef.current && expanded === null) {
      panelHeight = panelRef.current.clientHeight;
    }
  }, [expanded]);

  useEffect(() => {
    if (currentIndex !== undefined && accordionItems[currentIndex]) {
      setExpanded(accordionItems[currentIndex].panel);
    }
  }, [currentIndex, accordionItems]);

  // Handle accordion open/close state
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
    setCurrentIndex(accordionItems.findIndex((item) => item.panel === panel));
  };

  // Generate styles for smooth sliding and opacity changes based on panel position
  const getAccordionStyle = (panel, index) => ({
    opacity: expanded === null || expanded === panel ? 1 : 0,
    transform:
      expanded === panel ? `translateY(${-(index * panelHeight) - 12}px)` : 'translateY(0)', // No translation when it's expanded
    // Use negative translate based on panel index
    transition: 'all 0.5s ease', // Smooth transition for both transform and opacity
    visibility: expanded === panel || expanded === null ? 'visible' : 'hidden',
    marginTop: expanded === panel ? '1rem' : '0', // Add margin to the first panel when expanded
  });

  return (
    <Grid
      item
      display={'flex'}
      direction={'column'}
      position="relative" // Required for relative positioning of the grid
      padding={1}
      height={'100%'}
    >
      {accordionItems.map(({ panel, label, component }, index) => (
        <Accordion
          key={panel}
          expanded={expanded === panel}
          onChange={handleAccordionChange(panel)}
          style={getAccordionStyle(panel, index)}
          ref={panel === 'ruleType' ? panelRef : null} // Reference the first accordion to get height
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{label}</Typography>
          </AccordionSummary>
          <Collapse in={expanded === panel} timeout="auto" unmountOnExit>
            <AccordionDetails
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'scroll',
                width: '100%',
                maxHeight: '40vh',
              }}
            >
              {renderComponent(component)}
            </AccordionDetails>
          </Collapse>
        </Accordion>
      ))}
    </Grid>
  );
}

CreateRuleModalAccordion.propTypes = {
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      panel: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  qualityStations: PropTypes.array.isRequired,
  streamFields: PropTypes.array.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
};
