import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth.store';
import { getRedirectPath } from '../utils/accessUtils';

function UnauthorizedPage({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('unauthorized'));

  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  const handleBackHome = () => {
    navigate(getRedirectPath(user));
  };

  return (
    <Box
      minHeight="80vh"
      align="center"
      justifyContent="center"
      p={8}
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1">{t('unauthorized')}</Typography>
      <Typography variant="h3">{t('noPermissionToViewPage')}</Typography>
      <Button onClick={handleBackHome} size="large" variant="qualitatio">
        {t('goBackHome')}
      </Button>
    </Box>
  );
}

export default UnauthorizedPage;
