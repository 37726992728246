import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { createTheme } from '@mui/material/styles';
import * as React from 'react';
import QualitatioButton from '../QualitatioButton/QualitatioButton';
import './QualitatioDialog.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QualitatioDialog = ({
  title,
  description,
  open,
  onClose,
  actions,
  children,
  buttonOnLeft,
  footer,
  subheader = '',
  maxWidth = 'lg',
  overflow = 'auto',
  gap = '2vh',
  ...otherProps
}) => {
  const defaultMuiTheme = createTheme();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="qualitatio-dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
      {...otherProps}
      variant="qualitatio_dialog"
    >
      {/* Modified DialogTitle */}
      <DialogTitle
        sx={{
          textAlign: 'center',
          position: 'relative', // Relative positioning to anchor the close icon
          padding: '16px',
        }}
      >
        {title}
        {/* Add Close Icon in the top-right corner */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: gap, overflow: overflow }}
      >
        <DialogContentText>{subheader}</DialogContentText>
        <DialogContentText id="qualitatio-dialog-description">
          {/* Handle HTML tags in description */}
          {description?.split('\n')?.map((item, idx) => (
            <div key={idx} dangerouslySetInnerHTML={{ __html: item }}></div>
          ))}
        </DialogContentText>
        {children}
        <DialogContentText>{footer}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        {buttonOnLeft && (
          <div className="button-on-the-left">
            <QualitatioButton
              text={buttonOnLeft.label}
              onClick={buttonOnLeft.onClick}
              order={buttonOnLeft.order}
              startIcon={buttonOnLeft.startIcon}
              width={buttonOnLeft.width}
              disabled={buttonOnLeft.disabled}
            />
          </div>
        )}
        <div className={'other-buttons-' + (buttonOnLeft ? 'half' : 'full')}>
          {actions?.map((action, idx) => (
            <QualitatioButton
              key={idx}
              text={action.label}
              onClick={action.onClick || onClose}
              order={action.order}
              margin={'0 0 0 10px'}
              disabled={action.disabled}
              startIcon={action.startIcon}
              width={action.width}
              type="submit"
            />
          ))}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default QualitatioDialog;
