import * as Yup from 'yup';
import ROLES from '../authentication/roleConst';
import { LANG } from '../language_settings/languageConst';
import { dictionaryList } from '../language_settings/languages';
import { authSystems } from '../config/enums';

const password_regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()_\-+=<>?{}[\]|/\\:;"',.]{8,}$/;
const bootstrapServersRegex = /\w+:\w+/;
const subscriptionNameRegex = /^[a-zA-Z0-9\s]*$/;
const kafkaTopicRegex = /^[a-zA-Z0-9_-]+$/;

/**
 * Returns the localized message for a given key and language.
 * @param {string} key - The key of the message to retrieve.
 * @param {string} language - The language of the message to retrieve.
 * @param {boolean} [usedInYup=true] - Whether the message is used in Yup validation schema.
 * @returns {string} The localized message for the given key and language, or the key itself if translation is missing.
 */
const getLocalizedMessage = (key, language, usedInYup = true) => {
  if (usedInYup) {
    key = 'validationTranslations.' + key;
  }
  const keys = key.split('.');
  let message = dictionaryList[language];

  for (let k of keys) {
    message = message[k];
    if (message === undefined) {
      return key; // Fallback to the key itself if translation is missing
    }
  }
  return message;
};


export const getUserValidationSchema = (lang = 'en') => {
  return Yup.object({
    name: Yup.string().required(getLocalizedMessage('required', lang)),
    email: Yup.string()
      .email(getLocalizedMessage('invalidMail', lang))
      .required(getLocalizedMessage('required', lang)),
    password: Yup.string()
      .min(8, getLocalizedMessage('minCharacters', lang))
      .matches(password_regex, getLocalizedMessage('passwordCriteria', lang))
      .notRequired(),
    role: Yup.string().oneOf(Object.values(ROLES), getLocalizedMessage('invalidRole', lang)),
    authSystem: Yup.string()
      .oneOf(Object.values(authSystems), getLocalizedMessage('invalidAuthSystem', lang))
      .notRequired(),
    language: Yup.string().oneOf(Object.values(LANG), getLocalizedMessage('invalidLanguage', lang)),
  });
};

export const addUserValidationSchema = (lang = 'en') => {
  return Yup.object({
    name: Yup.string().required(getLocalizedMessage('required', lang)),
    email: Yup.string()
      .email(getLocalizedMessage('invalidMail', lang))
      .required(getLocalizedMessage('required', lang)),
    password: Yup.string()
      .min(8, getLocalizedMessage('minCharacters', lang))
      .matches(password_regex, getLocalizedMessage('passwordCriteria', lang))
      .required(getLocalizedMessage('required', lang)),
    role: Yup.string()
      .oneOf(Object.values(ROLES), getLocalizedMessage('invalidRole', lang))
      .required(getLocalizedMessage('required', lang)),
    language: Yup.string()
      .oneOf(Object.values(LANG), getLocalizedMessage('invalidLanguage', lang))
      .required(getLocalizedMessage('required', lang)),
  });
};

export const getLDAPSettingsValidationSchema = (lang = 'en') => {
  return Yup.object({
    url: Yup.string().required(getLocalizedMessage('required', lang)),
    bindDN: Yup.string().required(getLocalizedMessage('required', lang)),
    bindCredentials: Yup.string().required(getLocalizedMessage('required', lang)),
    searchBase: Yup.string().required(getLocalizedMessage('required', lang)),
    searchFilter: Yup.string().required(getLocalizedMessage('required', lang)),
  });
};

export const getConnectGroupsValidationSchema = (lang = 'en') => {
  return Yup.object({
    role: Yup.string().required(getLocalizedMessage('required', lang)),
    groups: Yup.array().required(getLocalizedMessage('required', lang)),
  });
};

export const getPasswordValidationSchema = (lang = 'en') => {
  return Yup.object({
    password: Yup.string()
      .min(8, getLocalizedMessage('minCharacters', lang))
      .matches(password_regex, getLocalizedMessage('passwordCriteria', lang))
      .required(getLocalizedMessage('required', lang)),
  });
};

export const getSetupValidationSchema = (lang = 'en') => {
  return Yup.object({
    name: Yup.string().required(getLocalizedMessage('required', lang)),
    email: Yup.string()
      .email(getLocalizedMessage('invalidEmail', lang))
      .required(getLocalizedMessage('required', lang)),
    password: Yup.string()
      .min(8, getLocalizedMessage('minCharacters', lang))
      .matches(password_regex, getLocalizedMessage('passwordCriteria', lang))
      .required(getLocalizedMessage('required', lang)),
  });
};

/**
 * `getConnectionSetupValidationSchema` function
 *
 * This function provides validation schemas based on the provided `step` argument.
 * It's tailored to validate connection setup details for different stages of a setup process.
 * The function leverages the `Yup` library to create these validation schemas.
 *
 * @param {number} [step] - Represents the stage or step of the connection setup process.
 *                          Depending on its value, a specific validation schema is returned.
 *
 *                          - `1`: Returns a schema related to general connection setup fields.
 *                          - `2`: Returns a schema related to Kafka-specific setup fields.
 *                          - Any other value (or if omitted): Returns a combined schema of
 *                            both general and Kafka-specific fields.
 *
 * @returns {object} - A Yup validation schema object tailored to the provided `step`.
 *
 * @example
 *
 * const generalSchema = getConnectionSetupValidationSchema(1);
 * const kafkaSchema = getConnectionSetupValidationSchema(2);
 * const combinedSchema = getConnectionSetupValidationSchema();
 */
export const getConnectionSetupValidationSchema = (step, lang = 'en') => {
  const getCredentialsSchema = (method, lang) => {
    console.log('method: ' + method);
    switch (method) {
      case 'token':
        return Yup.object({
          token: Yup.string().required(getLocalizedMessage('required', lang)),
        });
      case 'password':
        return Yup.object({
          username: Yup.string().required(getLocalizedMessage('required', lang)),
          password: Yup.string().required(getLocalizedMessage('required', lang)),
        });
      default:
        // Default to a minimal schema if method is unknown
        return Yup.object({});
    }
  };
  const kafkaSchema = Yup.object({
    bootstrapServers: Yup.string()
      .required(getLocalizedMessage('required', lang))
      .matches(bootstrapServersRegex, getLocalizedMessage('bootstrapServersInvalid', lang)),
    topic: Yup.string().required(getLocalizedMessage('required', lang)),
    keyConverter: Yup.string().required(getLocalizedMessage('required', lang)),
    valueConverter: Yup.string().required(getLocalizedMessage('required', lang)),
    endpointIdentificationAlgorithm: Yup.string(),
    securityProtocol: Yup.string(),
    saslMechanism: Yup.string(),
    saslJAASConfig: Yup.string(),
    username: Yup.string(),
    password: Yup.string(),
    consumerEndpointIdentificationAlgorithm: Yup.string(),
    consumerSecurityProtocol: Yup.string(),
    consumerSaslMechanism: Yup.string(),
    consumerSaslJAASConfig: Yup.string(),
    consumerUsername: Yup.string(),
    consumerPassword: Yup.string(),
    consumerGroupID: Yup.string(),
  });

  const apiSchema = Yup.object({
    url: Yup.string().required(getLocalizedMessage('required', lang)),
    port: Yup.number().required(getLocalizedMessage('required', lang)),
    securityProtocol: Yup.string().required(getLocalizedMessage('required', lang)),
    authentication: Yup.object({
      method: Yup.string().oneOf(['token', 'password'], getLocalizedMessage('invalid', lang)),
      // Dynamically determine the credentials schema based on the method value at runtime
      credentials: Yup.lazy((values) => getCredentialsSchema(values?.method, lang)),
    }),
  });

  const generalSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, getLocalizedMessage('nameCharactersAllowed', lang))
      .min(4, getLocalizedMessage('nameMinCharacters', lang))
      .max(50, getLocalizedMessage('nameMaxCharacters', lang))
      .required(getLocalizedMessage('required', lang)),
    description: Yup.string(),
    type: Yup.string()
      .required(getLocalizedMessage('required', lang))
      .oneOf(['kafka', 'api'], getLocalizedMessage('invalidType', lang)),
    maxSize: Yup.number()
      .min(1, getLocalizedMessage('inputSourceMaxSizeMin', lang))
      .max(99999, getLocalizedMessage('inputSourceMaxSizeMax', lang))
      .required(getLocalizedMessage('required', lang)),
    productIDFieldName: Yup.string().required(getLocalizedMessage('required', lang)),
  });
  switch (step) {
    case 1:
      return generalSchema;
    case 2:
      return Yup.lazy((values) => {
        switch (values.type) {
          case 'kafka':
            return Yup.object().shape({
              kafka: kafkaSchema,
              // Ensure other fields not relevant to Kafka are not validated
              api: Yup.object().strip(),
            });
          case 'api':
            return Yup.object().shape({
              api: apiSchema,
              // Ensure other fields not relevant to API are not validated
              kafka: Yup.object().strip(),
            });
          default:
            // Return a minimal schema if type is not recognized
            return Yup.object().shape({
              kafka: Yup.object().strip(),
              api: Yup.object().strip(),
            });
        }
      });
    default:
      // For the default case, we adjust the approach to dynamically apply the schema
      return Yup.lazy((values) => {
        const type = values.type; // This should be adjusted based on your form structure
        let schema = generalSchema;
        if (type === 'kafka') {
          schema = schema.concat(Yup.object({ kafka: kafkaSchema }));
        } else if (type === 'api') {
          schema = schema.concat(Yup.object({ api: apiSchema }));
        }
        // For types other than 'kafka' or 'api', or if type is not provided, we keep the general schema only
        return schema;
      });
  }
};

export const getSubscriptionValidationSchema = (step, lang = 'en') => {
  switch (step) {
    case 1:
      return Yup.object({
        name: Yup.string()
          .matches(subscriptionNameRegex, getLocalizedMessage('subscriptionNameInvalid', lang))
          .min(3, getLocalizedMessage('subscriptionNameMin', lang))
          .max(50, getLocalizedMessage('subscriptionNameMax', lang))
          .required(getLocalizedMessage('required', lang)),
        stream: Yup.string().required(getLocalizedMessage('required', lang)),
        type: Yup.string().required(getLocalizedMessage('required', lang)),
      });
    case 2:
      return Yup.object({
        connectionDetails: Yup.object({
          topic: Yup.string()
            .matches(kafkaTopicRegex, getLocalizedMessage('kafkaTopicInvalid', lang))
            .required(getLocalizedMessage('required', lang)),
          format: Yup.string().required(getLocalizedMessage('required', lang)),
          updateFrequency: Yup.number().required(getLocalizedMessage('required', lang)),
        }),
      });
    default:
      return Yup.object({
        name: Yup.string()
          .matches(subscriptionNameRegex, getLocalizedMessage('subscriptionNameInvalid', lang))
          .min(3, getLocalizedMessage('subscriptionNameMin', lang))
          .max(50, getLocalizedMessage('subscriptionNameMax', lang))
          .required(getLocalizedMessage('required', lang)),
        stream: Yup.string().required(getLocalizedMessage('required', lang)),
        type: Yup.string().required(getLocalizedMessage('required', lang)),
        connectionDetails: Yup.object({
          topic: Yup.string()
            .matches(kafkaTopicRegex, getLocalizedMessage('kafkaTopicInvalid', lang))
            .required(getLocalizedMessage('required', lang)),
          format: Yup.string().required(getLocalizedMessage('required', lang)),
          updateFrequency: Yup.number().required(getLocalizedMessage('required', lang)),
        }),
      });
  }
};

export const oAuthValidationSchema = (lang = 'en') => {
  return Yup.object({
    providerName: Yup.string().required(getLocalizedMessage('providerNameRequired', lang)),
    active: Yup.boolean().required(getLocalizedMessage('activeStatusRequired', lang)),
    clientID: Yup.string().required(getLocalizedMessage('clientIdRequired', lang)),
    clientSecret: Yup.string(),
    authorizationURL: Yup.string()
      .url(getLocalizedMessage('validUrl', lang))
      .required(getLocalizedMessage('authorizationUrlRequired', lang)),
    tokenExchangeURL: Yup.string()
      .url(getLocalizedMessage('validUrl', lang))
      .required(getLocalizedMessage('tokenExchangeUrlRequired', lang)),
    scopes: Yup.array().of(Yup.string()).min(1, getLocalizedMessage('atLeastOneScope', lang)),
    userInformation: Yup.object({
      mapping: Yup.array().of(
        Yup.object({
          key: Yup.string().required(getLocalizedMessage('mappingKeyRequired', lang)),
          value: Yup.string().required(getLocalizedMessage('mappingValueRequired', lang)),
        }),
      ),
      url: Yup.string()
        .url(getLocalizedMessage('validUrl', lang))
        .required(getLocalizedMessage('userInformationUrlRequired', lang)),
      method: Yup.string()
        .oneOf(['get', 'post'], getLocalizedMessage('invalidMethod', lang))
        .required(getLocalizedMessage('methodRequired', lang)),
      headers: Yup.array().of(
        Yup.object({
          key: Yup.string().required(getLocalizedMessage('headerKeyRequired', lang)),
          value: Yup.string().required(getLocalizedMessage('headerValueRequired', lang)),
        }),
      ),
      params: Yup.array().of(
        Yup.object({
          key: Yup.string().required(getLocalizedMessage('paramKeyRequired', lang)),
          value: Yup.string().required(getLocalizedMessage('paramValueRequired', lang)),
        }),
      ),
    }),
  });
};

export const monitoredSystemValidationSchema = (lang = 'en') => {
  return Yup.object({
    name: Yup.string().required(getLocalizedMessage('required', lang)),
    inputStreamID: Yup.string().required(getLocalizedMessage('required', lang)),
    fields: Yup.array().of(Yup.string()).min(1, getLocalizedMessage('atLeastOneField', lang)),
    frequency: Yup.number()
      .required(getLocalizedMessage('required', lang))
      .min(60, getLocalizedMessage('frequencyMin', lang)),
    days: Yup.array().of(Yup.number()).min(1, getLocalizedMessage('atLeastOneDay', lang)),
    timespan: Yup.object({
      start: Yup.number().required(getLocalizedMessage('required', lang)),
      end: Yup.number().required(getLocalizedMessage('required', lang)),
    }).test(
      'is-greater',
      getLocalizedMessage('endTimeGreaterThanStartTime', lang),
      function (value) {
        const { start, end } = value;
        return end >= start;
      },
    ),
    sampleSize: Yup.number().min(1).required(getLocalizedMessage('required', lang)),
    forecastHorizon: Yup.number().test(
      'forecast-horizon-required',
      getLocalizedMessage('required', lang),
      function (value) {
        const { timeseriesForecastModel } = this.parent;
        return timeseriesForecastModel ? value != null && value > 0 : true;
      },
    ),
  });
};

export const monitoredSystemThresholdValidationSchema = (lang = 'en') => {
  return Yup.object({
    upperThreshold: Yup.number().when('lowerThreshold', (lowerThreshold, schema) => {
      return lowerThreshold != null
        ? schema.min(lowerThreshold, getLocalizedMessage('upperLowerLimit', lang))
        : schema;
    }),
    lowerThreshold: Yup.number(),
  }).test(
    'upper-greater-than-lower',
    getLocalizedMessage('upperLowerLimit', lang),
    function (value) {
      const { upperThreshold, lowerThreshold } = value;
      return !upperThreshold || !lowerThreshold || upperThreshold > lowerThreshold;
    },
  );
};
