import { Box, FormControl, Stack, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi';
import { QualitatioDialog } from '../../../../components';

// This component can be used to select live streams and fields for the event labeling process.
const LiveStreamDataSelectionModal = ({
  streamFieldModalOpen,
  setStreamFieldModalOpen,
  liveStreamData,
  saveSelectedLiveStreamData,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [editableSelectedLiveStreamData, setEditableSelectedLiveStreamData] = useState([]);

  const updateSelectedLiveStream = (streamName) => {
    let newSelectedLiveStreams = [];
    if (
      !editableSelectedLiveStreamData ||
      !editableSelectedLiveStreamData.some((e) => e.streamName === streamName)
    ) {
      newSelectedLiveStreams = [
        ...(editableSelectedLiveStreamData || []),
        { streamName, fieldNames: [], imageFieldName: null, filterFieldNames: [] },
      ];
    } else {
      newSelectedLiveStreams = editableSelectedLiveStreamData.filter(
        (el) => el.streamName !== streamName
      );
    }
    setEditableSelectedLiveStreamData(newSelectedLiveStreams);
  };

  const updateSelectedStreamFields = (field, streamName) => {
    if (
      editableSelectedLiveStreamData.some(
        (e) => e.streamName === streamName && e.fieldNames.includes(field)
      )
    ) {
      const newSelectedInputFields = editableSelectedLiveStreamData.map((el) => {
        if (el.streamName === streamName) {
          const updatedFieldNames = el.fieldNames.filter((fieldName) => fieldName !== field);
          return {
            streamName: el.streamName,
            fieldNames: updatedFieldNames,
            imageFieldName: field,
            filterFieldNames: el.filterFieldNames,
          };
        }
        return el;
      });
      setEditableSelectedLiveStreamData(newSelectedInputFields);
    } else if (
      editableSelectedLiveStreamData.some(
        (e) => e.streamName === streamName && e.imageFieldName === field
      )
    ) {
      const newSelectedInputFields = editableSelectedLiveStreamData.map((el) => {
        if (el.streamName === streamName) {
          return {
            streamName: el.streamName,
            fieldNames: el.fieldNames,
            imageFieldName: null,
            filterFieldNames: [...el.filterFieldNames, field],
          };
        }
        return el;
      });
      setEditableSelectedLiveStreamData(newSelectedInputFields);
    } else if (
      editableSelectedLiveStreamData.some(
        (e) => e.streamName === streamName && e.filterFieldNames?.includes(field)
      )
    ) {
      const newSelectedInputFields = editableSelectedLiveStreamData.map((el) => {
        if (el.streamName === streamName) {
          const updatedFilterFieldNames = el.filterFieldNames.filter(
            (fieldName) => fieldName !== field
          );
          return {
            streamName: el.streamName,
            fieldNames: el.fieldNames,
            imageFieldName: el.imageFieldName,
            filterFieldNames: updatedFilterFieldNames,
          };
        }
        return el;
      });
      setEditableSelectedLiveStreamData(newSelectedInputFields);
    } else {
      const newSelectedInputFields = editableSelectedLiveStreamData.map((el) => {
        if (el.streamName === streamName) {
          const updatedFieldNames = [...el.fieldNames];
          if (!updatedFieldNames.includes(field)) {
            updatedFieldNames.push(field);
          }
          return {
            streamName: el.streamName,
            fieldNames: updatedFieldNames,
            imageFieldName: el.imageFieldName,
            filterFieldNames: el.filterFieldNames,
          };
        }
        return el;
      });
      setEditableSelectedLiveStreamData(newSelectedInputFields);
    }
  };

  return (
    <QualitatioDialog
      title={t('selectLiveStreamsAndEventFields')}
      description={t(
        'pleaseSelectAllLiveStreamsAndFieldsYouWantToUseForLabelingQualityRelevantEvents'
      )}
      open={streamFieldModalOpen}
      onClose={() => setStreamFieldModalOpen(false)}
      actions={[
        {
          label: t('save'),
          onClick: () => {
            saveSelectedLiveStreamData(editableSelectedLiveStreamData);
            setStreamFieldModalOpen(false);
          },
          order: 'primary',
        },
        {
          label: t('cancel'),
          onClick: () => {
            setStreamFieldModalOpen(false);
          },
          order: 'secondary',
        },
      ]}
    >
      <Typography variant="h4" component="h4">
        {t('legend')}
      </Typography>
      <Box alignItems="center" display={'flex'} style={{ gap: '5px' }}>
        <Chip
          label={t('selected')}
          icon={<BiCheckboxChecked />}
          sx={{ backgroundColor: theme.palette.success.secondary }}
        />
        <Chip
          label={t('selectedAsImageField')}
          icon={<BiCheckboxChecked />}
          sx={{ backgroundColor: theme.palette.error.secondary }}
        />
        <Chip
          label={t('selectedAsFilterField')}
          icon={<BiCheckboxChecked />}
          sx={{ backgroundColor: theme.palette.warning.secondary }}
        />
        <Chip
          label={t('notSelected')}
          icon={<BiCheckboxChecked />}
          sx={{ backgroundColor: theme.palette.gray.main }}
        />
      </Box>
      <Typography variant="h4" component="h4">
        {t('streams')}
      </Typography>
      <FormControl>
        <Stack
          id="liveDataInputStreams"
          name="liveDataInputStreams"
          direction="row"
          spacing={1}
          align="center"
          style={{ overflowY: 'auto' }}
          maxH="200px"
        >
          {liveStreamData?.map((data, index) => (
            <Chip
              label={data.streamName}
              onClick={() => updateSelectedLiveStream(data.streamName)}
              icon={
                editableSelectedLiveStreamData?.some((e) => e.streamName === data.streamName) ? (
                  <BiCheckboxChecked />
                ) : (
                  <BiCheckbox />
                )
              }
              sx={{
                backgroundColor: editableSelectedLiveStreamData?.some(
                  (e) => e.streamName === data.streamName
                )
                  ? theme.palette.success.secondary
                  : theme.palette.gray.main,
                cursor: 'pointer',
              }}
            />
          ))}
        </Stack>
      </FormControl>
      <Typography variant="h4" component="h4" style={{ marginTop: '2%' }}>
        {t('streamFields')}
      </Typography>
      {liveStreamData?.map((data, index) => (
        <>
          {editableSelectedLiveStreamData?.map((e) => e.streamName).includes(data.streamName) && (
            <>
              <Typography variant="h4" component="h4" style={{ margin: '10px 0 0 0' }}>
                {data.streamName}
              </Typography>
              <Stack
                id="liveDataInputStreams"
                name="liveDataInputStreams"
                direction="row"
                spacing={1}
                align="center"
                style={{ overflowY: 'auto', minHeight: '70px' }}
                maxH="200px"
              >
                {data.fieldNames.map((field, index) => (
                  <Chip
                    label={field}
                    onClick={() => updateSelectedStreamFields(field, data.streamName)}
                    icon={
                      editableSelectedLiveStreamData
                        ?.flatMap((e) => (e.streamName === data.streamName ? e.fieldNames : []))
                        .includes(field) ||
                      editableSelectedLiveStreamData?.find((e) => e.streamName === data.streamName)
                        ?.imageFieldName === field ||
                      editableSelectedLiveStreamData
                        ?.flatMap((e) =>
                          e.streamName === data.streamName ? e.filterFieldNames : []
                        )
                        .includes(field) ? (
                        <BiCheckboxChecked />
                      ) : (
                        <BiCheckbox />
                      )
                    }
                    sx={{
                      backgroundColor: editableSelectedLiveStreamData
                        ?.flatMap((e) => (e.streamName === data.streamName ? e.fieldNames : []))
                        .includes(field)
                        ? theme.palette.success.secondary
                        : editableSelectedLiveStreamData?.find(
                            (e) => e.streamName === data.streamName
                          )?.imageFieldName === field
                        ? theme.palette.error.secondary
                        : editableSelectedLiveStreamData
                            ?.flatMap((e) =>
                              e.streamName === data.streamName ? e.filterFieldNames : []
                            )
                            .includes(field)
                        ? theme.palette.warning.secondary
                        : theme.palette.gray.main,
                      cursor: 'pointer',
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </>
      ))}
    </QualitatioDialog>
  );
};

export default LiveStreamDataSelectionModal;
