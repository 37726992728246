import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Grid, IconButton, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { monitoredSystemsEndpoint } from '../api/endpoints';
import { QualitatioTable } from '../components';
import ConfirmationDialog from '../components/ConfirmationOverlay';
import PageFrame from '../components/PageFrame';
import { WebSocketContext } from './../provider/WebsocketProvider';
import MonitoredSystemOverlay from './components/ChangeMonitoredSystemOverlay';
import WebSocketStatusIndicator from './components/WSStatusIndicator';

//Some notes:
/*  Every time the page is loaded or the monitoredSystems are updated, we fetch the monitoredSystems from the server
 * We store the monitoredSystems in a state variable
 * We display the monitoredSystems in a table with the systemStatus indicated by a colored icon
 * We set up a websocket connection to the server to receive updates on the monitoredSystems
 * We update the monitoredSystems in the state variable when we receive an update from the server
 * We update periodically or when the status of a monitoredSystem changes
 * The refresh interval can be set by the user default is 10 seconds
 * Add a Feedback for the user that the Websocket is connected and live updates are received
 *
 */
export default function WarningSystem({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('ews.name'));
  const theme = useTheme();
  const navigate = useNavigate();
  const { socket } = useContext(WebSocketContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [monitoredSystems, setMonitoredSystems] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [monitoredSystemDialogOpen, setMonitoredSystemDialogOpen] = useState(false);
  const [deleteOverlayOpen, setDeleteOverlayOpen] = useState(false);
  // State to hold the selected row data
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };
  const handleEdit = () => {
    setMonitoredSystemDialogOpen(true);
  };
  const handleAdd = () => {
    setSelectedRow(null);
    setMonitoredSystemDialogOpen(true);
  };
  const handleInfo = (systemId) => {
    navigate(`/early_warning_system/monitor/${systemId}`);
  };

  const handleDelete = async (systemID) => {
    try {
      const res = await axiosClient.delete(`${monitoredSystemsEndpoint}/${systemID}`);
      if (res.status === 204) {
        enqueueSnackbar(t('ews.monitoredSystemDeleteSuccessSnack'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('ews.monitoredSystemDeleteErrorSnack'), { variant: 'error' });
      }
      await fetchMonitoredSystems();
      setDeleteOverlayOpen(false);
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemDeleteErrorSnack'), { variant: 'error' });
    }
  };

  // Function to request monitored systems from the server
  const fetchMonitoredSystems = useCallback(async () => {
    try {
      const response = await axiosClient.get(monitoredSystemsEndpoint);
      if (response.data) {
        const monitoredSystems = response.data;
        setMonitoredSystems(monitoredSystems);
      } else {
        throw new Error('Failed to fetch monitored systems');
      }
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemsFetchErrorSnack'), { variant: 'error' });
    }
  }, []);

  const onAddClose = async () => {
    setMonitoredSystemDialogOpen(false);
    await fetchMonitoredSystems();
  };

  const onActivationToggle = async (system) => {
    const systemID = system.id;
    const nextState = system.active ? 'deactivate' : 'activate';
    try {
      const response = await axiosClient.post(
        `${monitoredSystemsEndpoint}/${systemID}/${nextState}`,
      );
      if (response.data) {
        const monitoredSystems = response.data;
        setMonitoredSystems(monitoredSystems);
        enqueueSnackbar(t('ews.monitoredSystemsToggleSuccessSnack'), { variant: 'success' });
      } else {
        throw new Error('Failed to fetch monitored systems');
      }
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemsToggleErrorSnack'), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchMonitoredSystems();

    let snackId = setTimeout(() => {
      snackId = enqueueSnackbar(t('ews.monitoredSystemsInfoSnack'), {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 15000,
      });
    }, 3000);

    // Return a cleanup function
    return () => {
      if (snackId) {
        closeSnackbar(snackId);
      }
    };
  }, []); // Make sure to include all dependencies here

  useEffect(() => {
    let cleanupWebSocket;
    const handleSystemStatusUpdate = (updatedSystem) => {
      if (!updatedSystem) {
        console.error('No updated system received from the server');
      }
      setMonitoredSystems((prevSystems) => {
        const systemIndex = prevSystems.findIndex((system) => system.id === updatedSystem.id);
        if (systemIndex > -1) {
          const newSystems = [...prevSystems];
          newSystems[systemIndex] = updatedSystem;
          return newSystems;
        }
        return [...prevSystems, updatedSystem];
      });
    };
    try {
      if (socket) {
        socket.on('ews.systemStatus', handleSystemStatusUpdate);

        cleanupWebSocket = () => {
          socket.off('ews.systemStatus', handleSystemStatusUpdate);
        };
      }
    } catch (error) {
      enqueueSnackbar(t('ews.errorLoadingMonitoredSystems'), { variant: 'error' });
    }
    return () => {
      if (cleanupWebSocket) cleanupWebSocket();
    };
  }, [socket]);

  //table setup
  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
      flex: 1,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: 'inputStreamName',
      headerName: t('stream'),
      width: 200,
      flex: 1,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: 'systemStatus',
      headerName: t('ews.status'),
      width: 200,
      flex: 1,
      renderCell: (params) => <div>{params.value}</div>,
    },
    //edit and delete button field
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      flex: 1.5,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '15px',
            alignItems: 'center',
          }}
        >
          <Switch
            variant="qualitatio"
            checked={params.row.active}
            onChange={() => onActivationToggle(params.row)}
          />
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => handleInfo(params.row.id)}
            style={{ backgroundColor: theme.palette.primary.main, width: '30px', height: '30px' }}
          >
            <ShowChartIcon color="white" />
          </IconButton>

          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => handleEdit()}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '30px',
              height: '30px',
            }}
          >
            <CreateOutlinedIcon color="primary" />
          </IconButton>
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => setDeleteOverlayOpen(true)}
            background={theme.palette.error.main}
            style={{ backgroundColor: theme.palette.error.main, width: '30px', height: '30px' }}
          >
            <DeleteForeverIcon color="white" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const deleteOverlayProps = {
    open: deleteOverlayOpen,
    onCancel: () => setDeleteOverlayOpen(false),
    onConfirm: () => handleDelete(selectedRow.id),
    title: t('ews.confirmDeletion'),
    content: t('ews.confirmMonitoredSystemDeletionText'),
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
  };

  return (
    <PageFrame title={t('ews.name')} Icon={WarningAmberIcon}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
              marginBottom={2}
            >
              <div></div>
              <Typography variant="h3" gutterBottom>
                {t('ews.monitoredSystems')}
              </Typography>
              <IconButton
                variant="qualitatio"
                squared
                onClick={handleAdd}
                style={{
                  backgroundColor: theme.palette.success.main,
                }}
              >
                <PlaylistAddIcon />
              </IconButton>
            </Box>
            <QualitatioTable
              rows={monitoredSystems}
              columns={columns}
              onRowClick={handleRowClick} // Attach the row click handler
              getRowClassName={(params) => (params.id === selectedRow?.id ? 'selectedRow' : '')}
              checkboxSelection={false}
              // Remove the checkboxSelection prop
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              slots={{}}
              pageSizeOptions={[5, 10]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
            >
              <div></div>
              <Typography variant="h3" gutterBottom>
                {t('ews.systemStatus')}
              </Typography>
              <WebSocketStatusIndicator />
            </Box>

            {/* Box with border */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
              marginTop={2}
              sx={{
                border: 1,
                borderColor: theme.palette.divider,
                borderStyle: 'solid',
                borderRadius: 1,
              }}
            >
              {selectedRow && (
                <Box
                  sx={{
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  {selectedRow.systemStatus === 'OK' && (
                    <CheckCircleIcon sx={{ fontSize: 120, color: 'green' }} />
                  )}
                  {selectedRow.systemStatus === 'Warning' && (
                    <WarningAmberIcon sx={{ fontSize: 120, color: 'orange' }} />
                  )}
                  {selectedRow.systemStatus === 'Critical' && (
                    <CancelIcon sx={{ fontSize: 120, color: 'red' }} />
                  )}
                  <Typography variant="h4">{t(selectedRow.systemStatusAlert)}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {monitoredSystemDialogOpen && (
        <MonitoredSystemOverlay onClose={onAddClose} selectedSystem={selectedRow} />
      )}
      <ConfirmationDialog {...deleteOverlayProps} />
    </PageFrame>
  );
}
