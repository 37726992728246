import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { verifyEmailEndpoint } from '../api/endpoints';
import { useTranslation } from 'react-i18next';
import QualitatioButton from '../components/QualitatioButton/QualitatioButton';
import './VerifyEmailPage.css';
import { useSnackbar } from "notistack";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function VerifyEmailPage({ setPageName }) {
    const { t } = useTranslation();;
    setPageName(t("verifyEmail"));

    const query = useQuery();
    const token = query.get('token');
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const verifyEmail = async () => {
            setLoading(true);
            try {
                await axiosClient.post(verifyEmailEndpoint, {}, {
                    params: {
                        token: token
                    }
                });
                enqueueSnackbar(t("emailVerifiedSnack") || "Email verified.", {
                    variant: "success",
                });
                setSuccess(true);
            } catch (error) {
                enqueueSnackbar(t("emailVerficiationFailed") || "Verification failed. Try again or conntact an admin.", {
                    variant: "error",
                });
                setSuccess(false);
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [token]);

    return (
        <div className="verify-email-page">
            <div className="frame">
                <div className="div-wrapper">
                    <div className="text-wrapper-2">
                        {
                            t("verifyEmail") ? t("verifyEmail") : "Verify Email"
                        }
                    </div>
                </div>
                <img className="logo" alt="Logo" src="/images/qualitatio.svg" />
                <div className="frame-3">
                    <p>
                        {
                            loading ?
                                "Loading..."
                                : (
                                    success ?
                                        t("verifySuccess") ? t("verifySuccess") : "Email verified."
                                        : t("verifyFailed") ? t("verifyFailed") : "Email not verified."
                                )
                        }
                    </p>
                    <QualitatioButton
                        text={t("goToLogin") ? t("goToLogin") : "Go to Login"}
                        order='secondary'
                        onClick={() => navigate("/login")}
                        width={"100%"}
                        height={"5vh"}
                        fontSize={"calc(12px + 0.25vw)"}
                    />
                </div>
            </div>
        </div>
    );
}

export default VerifyEmailPage;