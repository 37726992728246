import { Add } from '@mui/icons-material';
import { Button, Grid, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioSelect from '../../../components/QualitatioSelect/QualitatioSelect';

export default function ETActionButtons({ onCreateRule, onApplyAction, selectedRuleIds }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const multiActionOptions = [
    { value: 'delete', label: t('delete') },
    { value: 'deactivate', label: t('deactivate') },
    { value: 'activate', label: t('activate') },
  ];

  const [selectedAction, setSelectedAction] = useState('');

  const handleApply = () => {
    if (selectedAction && selectedRuleIds.length > 0) {
      onApplyAction(selectedAction);
      setSelectedAction('');
    } else {
      // Show a message that no action or no selection is made
      enqueueSnackbar(t('selectActionAndRules'), { variant: 'warning' });
    }
  };

  return (
    <Grid item display={'flex'} direction={'row'} justifyContent={'space-between'}>
      <Grid item display={'flex'} justifyContent={'left'} style={{ width: '50%' }} gap={2}>
        <QualitatioSelect
          value={selectedAction}
          onChange={(event) => setSelectedAction(event.target.value)}
          optionProps={multiActionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          size="small"
          defaultText={t('multipleActions')}
          sx={{ marginLeft: 0, minWidth: 120, width: '50%' }}
        />
        <Button variant="qualitatio" color="primary" style={{ width: '20%' }} onClick={handleApply}>
          {t('apply')}
        </Button>
      </Grid>
      <Grid
        item
        spacing={2}
        display={'flex'}
        direction={'row'}
        justifyContent={'right'}
        style={{ width: '50%' }}
        alignItems={'center'}
        gap={2}
      >
        {/* <Button
          variant="qualitatio"
          color="primary"
          endIcon={<AutoAwesome />}
          style={{ width: '30%' }}
        >
          {t('aiAssistant')}
        </Button> */}
        <Button
          variant="qualitatio"
          color="primary"
          endIcon={<Add />}
          style={{ width: '30%' }}
          onClick={onCreateRule}
        >
          {t('createRule')}
        </Button>
      </Grid>
    </Grid>
  );
}

ETActionButtons.propTypes = {
  onCreateRule: PropTypes.func.isRequired,
  onApplyAction: PropTypes.func.isRequired,
  selectedRuleIds: PropTypes.array.isRequired,
};
