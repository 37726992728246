export const LANG = {
  EN: 'en',
  DE: 'de',
  FR: 'fr',
  ES: 'es',
  RU: 'ru',
  RO: 'ro',
  PL: 'pl',
  CZ: 'cz',
  HU: 'hu',
  IT: 'it',
  TR: 'tr',
};
