import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import InputRoundedIcon from '@mui/icons-material/InputRounded';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';
import './Basis.css';

const Basis = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('systemAdministration'));

  return (
    <PageFrame title={t('systemAdministration')} Icon={HandymanRoundedIcon} particlesActive={true}>
      <div className="basisFrame">
        <div className="text-wrapper-5">{t('welcomeToSystemAdministrationModule')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
        <div className="moduleCardWrapper">
          <ModulCard
            module={t('userManagement')}
            moduleText={t('setupAndManagementOfRightsAndRoles')}
            icon={<PersonAddAltRoundedIcon color="primary" fontSize="large" />}
            href="/basis/user_management"
          />
          <ModulCard
            module={t('inputConfiguration')}
            moduleText={t('configurationAndManagementOfDataSources')}
            icon={<InputRoundedIcon color="primary" fontSize="large" />}
            href="/basis/input_configurator"
          />
          <ModulCard
            module={t('consumerConfiguration')}
            moduleText={t('setupAndManagementOfConsumerSystems')}
            icon={<OutputRoundedIcon color="primary" fontSize="large" />}
            href="/basis/consumer_configurator"
          />
          <ModulCard
            module={t('systemConfiguration')}
            moduleText={t('individualConfigurationOfTheSystem')}
            icon={<SettingsRoundedIcon color="primary" fontSize="large" />}
            href="/basis/system_configurator"
          />
        </div>
      </div>
    </PageFrame>
  );
};

export default Basis;
