import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CircleIcon from '@mui/icons-material/Circle';
import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export default function CreateRuleModalSteps({ panels, currentIndex, setCurrentIndex }) {
  return (
    <Grid
      item
      gap={2}
      display={'flex'}
      justifyContent={'center'}
      direction={'row'}
      alignItems={'center'}
      style={{ backgroundColor: 'white' }}
    >
      <IconButton
        onClick={() => setCurrentIndex(currentIndex - 1)}
        disabled={currentIndex === 0}
        variant="qualitatio"
        squared={true}
      >
        <ArrowBackIosNewRoundedIcon />
      </IconButton>
      {panels.map((panel, index) => (
        <CircleIcon
          key={index}
          color={index === currentIndex ? 'primary' : 'disabled'}
          onClick={() => setCurrentIndex(index)}
        />
      ))}
      <IconButton
        onClick={() => setCurrentIndex(currentIndex + 1)}
        disabled={currentIndex === panels.length - 1}
        variant="qualitatio"
        squared={true}
      >
        <ArrowForwardIosRoundedIcon />
      </IconButton>
    </Grid>
  );
}

CreateRuleModalSteps.propTypes = {
  panels: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
};
