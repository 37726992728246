import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import {
  cardLayoutEndpoint,
  getCardsEndpoint,
  getDefectDescriptionHierarchieAtDocumentationLevelsEndpoint,
  testFinishedEndpoint,
} from '../../../api/endpoints';
import LabelIcon from '../../../assets/icons/LabelIcon';
import TestCommentIcon from '../../../assets/icons/TestCommentIcon';
import TestLocationIcon from '../../../assets/icons/TestLocationIcon';
import TestMethodIcon from '../../../assets/icons/TestMethodIcon';
import TestObjectIcon from '../../../assets/icons/TestObjectIcon';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import { useAuthStore } from '../../../store/auth.store';
import Menu from '../Menu';
import CalculateTesting from '../feedback_screens/CalculateTesting';
import CardPaperWrapper from './CardPaperWrapper';
import CardWrapper from './CardWrapper';
import { ImageInput } from './DefectInput/AIProcessing/ImageInput';
import DefectInput from './DefectInput/DefectInput';
import './Swipe.css';
import { ImagePanel } from './SwipeCard/ImagePanel';
import { SwipeCardButtonBar } from './SwipeCard/SwipeCardButtonBar';
import { SwipeCardButtonWithLabel } from './SwipeCard/SwipeCardButtonWithLabel';
import { SwipeCardFooter } from './SwipeCard/SwipeCardFooter';
import { SwipeCardHeader } from './SwipeCard/SwipeCardHeader';
import { SwipeCardImageStepper } from './SwipeCard/SwipeCardImageStepper';
import { SwipeCardInput } from './SwipeCard/SwipeCardInput';
import TestFeedback from './TestFeedback';
import TestOverview from './TestOverview';
import TinderCard from './TinderCard';

//Note from Tim: This is the worst code I have ever seen.
// This will be replaced in the middle of November 2024.
// Please dont spend too much time on this code.
export default function Swipe({
  setPageName,
  activeQualityStation,
  productID,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
}) {
  const { t } = useTranslation();

  // Query Parameters
  const [queryParameters] = useSearchParams();
  productID = productID || queryParameters.get('productID');
  const qualityStation = activeQualityStation || queryParameters.get('qualityStation');

  const finishTest = queryParameters.get('finishTest');

  const [qualityStationDefectPhotoRequired, setQualityStationDefectPhotoRequired] = useState(null);
  const [qualityStationRegexPattern, setQualityStationRegExPattern] = useState(null);

  useEffect(() => {
    if (qualityStation) {
      const required = queryParameters.get('defectPhotoRequired') === 'true';
      setQualityStationDefectPhotoRequired(required);
    }
  }, [qualityStation]);

  useEffect(() => {
    if (qualityStation) {
      const regex = queryParameters.get('regexPattern');
      setQualityStationRegExPattern(regex);
    }
  }, [qualityStation]);

  console.log('qualityStationDefectPhotoRequired', qualityStationDefectPhotoRequired);

  // Global Variables
  const [itCards, setItCards] = useState([]);
  const [cardLayout, setCardLayout] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Page State Variables
  const [isDefectInput, setIsDefectInput] = useState(false);
  const [isImageInput, setIsImageInput] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [cameraState, setCameraState] = useState(false);
  const [modalOpenDueToOtherDefect, setModalOpenDueToOtherDefect] = useState(false);
  const hideSwipeCard = isDefectInput || isSummaryOpen || isFeedbackOpen || isImageInput;

  setPageName(isFeedbackOpen ? t('giveFeedback') : isSummaryOpen ? t('summary') : t('swipe'));

  // Index Variables
  const [dataIndex, setDataIndex] = useState(0);
  const [otherDefectIndex, setOtherDefectIndex] = useState(-1);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Defects
  const [defectsFound, setDefectsFound] = useState([]);
  const [defectDescriptionFields, setDefectDescriptionFields] = useState(null);
  const [defectDescriptionFieldOptions, setDefectDescriptionFieldOptions] = useState(null);

  const [isExploding, setIsExploding] = useState(false);
  const [dataRequestFinished, setDataRequestFinished] = useState(false);
  const { user } = useAuthStore((state) => ({ user: state.user }));

  // Check if card layout is set to visual or textual
  useEffect(() => {
    handleCardLayoutSetting();
  }, []);

  /**
   *  Sets cardLayout to visual or textual depending on system setting
   */
  const handleCardLayoutSetting = async () => {
    try {
      const response = await axiosClient.get(cardLayoutEndpoint);
      setCardLayout(response.data.layout);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingCardLayout'), { variant: 'error' });
      navigate('/intelligent_testing/recommender/select');
      console.error('Error:', error);
    }
  };

  const getCards = async () => {
    try {
      const response = await axiosClient.post(getCardsEndpoint, {
        productID: productID,
        qualityStation: qualityStation,
      });
      if (response.data.cards) {
        setItCards(response.data.cards);
        setDataRequestFinished(true);
      } else {
        enqueueSnackbar(t('pleaseReenterQualityStation'), { variant: 'error' });
        navigate('/intelligent_testing/recommender/select');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionHierarchieAtDocumentationLevels = async () => {
    if (!defectDescriptionFields || !defectDescriptionFieldOptions) {
      try {
        const response = await axiosClient.get(
          getDefectDescriptionHierarchieAtDocumentationLevelsEndpoint
        );
        setDefectDescriptionFields(
          response.data.defectDescriptionHierarchie.defectDescriptionFields
        );
        setDefectDescriptionFieldOptions(
          response.data.defectDescriptionHierarchie.defectDescriptionFieldOptions
        );
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    // get productID from URL paras
    if (
      (productID == undefined || productID == null || productID == '') &&
      (queryParameters.get('productID') == undefined ||
        queryParameters.get('productID') == null ||
        queryParameters.get('productID') == '')
    ) {
      navigate('/intelligent_testing/recommender/select');
    }
    getCards();
    getDefectDescriptionHierarchieAtDocumentationLevels();
  }, []);

  const getDefectTemplate = (dataIdx = -1) => {
    let defectTemplate = {
      productID: productID,
      qualityStation: qualityStation,
      defectDetected: '',
      ...defectDescriptionFields?.defectDescriptionFields?.reduce(
        (obj, field) => ({ ...obj, [field]: '', [field + '_id']: '' }),
        {}
      ),
      defectComment: '',
      defectImages: [],
    };
    if (dataIdx !== -1) {
      defectTemplate = {
        ...defectTemplate,
        ...{
          cardID: itCards[dataIdx].cardID,
          testTitle: itCards[dataIdx].title,
          testImages: itCards[dataIdx].images,
          testObject: itCards[dataIdx].testObject,
          testMethod: itCards[dataIdx].testMethod,
          testLocation: itCards[dataIdx].testLocation,
          testComment: itCards[dataIdx].testComment,
          orderIdentifier: itCards[dataIdx].orderIdentifier,
        },
      };
    }
    return defectTemplate;
  };

  useEffect(() => {
    setDefectsFound(getInitialDefectsFound());
  }, [itCards]);

  const getInitialDefectsFound = () => {
    const initDefectsFound = [];
    for (let i = 0; i < itCards.length; i++) {
      initDefectsFound.push(getDefectTemplate(i));
    }
    return initDefectsFound;
  };

  /**
   * Updates or creates all key-value pairs of the given object in the current defect.
   * @param {object} newValuesObj
   */
  const updateCurrentDefect = (newValuesObj, objectID) => {
    setDefectsFound((prevState) => {
      const newDefectsFound = [...prevState];

      const otherDefectToUpdateIndex = newDefectsFound.findIndex(
        (el) => el.otherDefectID === objectID
      );

      if (otherDefectToUpdateIndex !== -1) {
        newDefectsFound[otherDefectToUpdateIndex] = {
          ...newDefectsFound[otherDefectToUpdateIndex],
          ...newValuesObj,
        };
      } else {
        const defectToUpdateIndex = newDefectsFound.findIndex((el) => el.cardID === objectID);

        if (defectToUpdateIndex !== -1) {
          newDefectsFound[defectToUpdateIndex] = {
            ...newDefectsFound[defectToUpdateIndex],
            ...newValuesObj,
          };
        }
      }
      return newDefectsFound;
    });
  };

  /** =============== Handle button clicks / swipes =============== */
  const checkIfCardHasNotBeenSwiped = (index) => {
    return [
      defectsFound[index].defectDetected === '',
      defectDescriptionFields?.defectDescriptionFields?.some(
        (field) =>
          defectDescriptionFields?.defectDescriptionFieldsMandatory[field] &&
          (defectsFound[index][field] === '' ||
            defectsFound[index][field] === null ||
            defectsFound[index][field] === undefined)
      ) ?? false,
    ];
  };

  const handleSwipe = async (direction) => {
    if (direction === 'right') {
      let newIndex = dataIndex - 1;
      if (newIndex >= 0) {
        setDataIndex(newIndex);
        await childRefs[newIndex].current.restoreCard();
      }
    } else if (direction === 'left') {
      // no defects found
      if (
        checkIfCardHasNotBeenSwiped(dataIndex)
          .map((el) => el)
          .every((el) => el === true)
      ) {
        updateCurrentDefect(
          { ...getDefectTemplate(dataIndex), defectDetected: 'no' },
          defectsFound[dataIndex].cardID
        );
      } else if (
        !checkIfCardHasNotBeenSwiped(dataIndex)[1] &&
        checkIfCardHasNotBeenSwiped(dataIndex)[0]
      ) {
        updateCurrentDefect({ defectDetected: 'yes' }, defectsFound[dataIndex].cardID);
      }
      setDataIndex(dataIndex + 1);
    }
  };

  const handleIOButtonClicked = () => {
    updateCurrentDefect(
      { ...getDefectTemplate(dataIndex), defectDetected: 'no' },
      defectsFound[dataIndex].cardID
    );
    if (dataIndex < itCards.length) {
      childRefs[dataIndex].current.swipe('left');
    }
  };

  const handleNIOButtonClicked = async () => {
    setModalOpenDueToOtherDefect(false);
    setIsDefectInput(true);
  };

  const handleOtherDefectButtonClicked = () => {
    const defectTemplate = getDefectTemplate();
    const newDefect = {
      ...defectTemplate,
      ...{
        defectDetected: 'yes',
        otherDefect: 'yes',
        defectFixed: false,
        otherDefectID: new Date().getTime() + (defectsFound.length - itCards.length),
      },
    };

    setOtherDefectIndex((prevIndex) => prevIndex + 1);

    // Insert new defect at current index
    setDefectsFound((prevState) => [...prevState, newDefect]);

    setModalOpenDueToOtherDefect(true);
    setIsDefectInput(true);
  };

  // if buttons below cards were clicked
  const handleGoBackButtonClicked = async (defectID) => {
    const newOtherDefectIndex = defectsFound.findIndex((el) => el.otherDefectID === defectID);
    // Other Defect clicked
    if (newOtherDefectIndex !== -1) {
      setModalOpenDueToOtherDefect(true);
      setOtherDefectIndex(newOtherDefectIndex - itCards.length);
      const updatedDefectsFound = [...defectsFound];
      updatedDefectsFound[newOtherDefectIndex].defectDetected = '';
      setDefectsFound(updatedDefectsFound);
      setIsDefectInput(true);
    }
    // Usual Card clicked
    else {
      // Get index of Card which matches defectsFound[newIndex]
      const cardIndex = defectsFound.findIndex((el) => el.cardID === defectID);
      const updatedDefectsFound = [...defectsFound];
      updatedDefectsFound[cardIndex].defectDetected = '';
      setDefectsFound(updatedDefectsFound);
      setDataIndex(cardIndex);
      await childRefs[cardIndex].current.restoreCard();
    }
  };

  const handleSaveButtonClicked = async () => {
    try {
      await axiosClient.post(testFinishedEndpoint, {
        defectsFound: defectsFound,
        status: 'saved',
        userID: user.id,
      });
      enqueueSnackbar(t('qualityCheckSuccessfullySaved'), { variant: 'success' });
    } catch (error) {
      console.error('Error:', error);

      enqueueSnackbar(t('qualityCheckError'), { variant: 'error' });
    }

    //  navigate(`/intelligent_testing/recommender/scan?qualityStationName=${qualityStation}`);

    navigate(
      `/intelligent_testing/recommender/scan?qualityStationName=${qualityStation}&qualityStationRegexPattern=${qualityStationRegexPattern}&defectPhotoRequired=${qualityStationDefectPhotoRequired}`
    );
  };

  const handleDiscardButtonClicked = async () => {
    try {
      await axiosClient.post(testFinishedEndpoint, {
        defectsFound: defectsFound,
        status: 'discarded',
        userID: user.id,
      });
      enqueueSnackbar(t('qualityCheckSuccessfullyDiscarded'), {
        variant: 'info',
      });
      // set new User state
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('qualityCheckError'), { variant: 'error' });
    }
    navigate(
      `/intelligent_testing/recommender/scan?qualityStationName=${qualityStation}&qualityStationRegexPattern=${qualityStationRegexPattern}&defectPhotoRequired=${qualityStationDefectPhotoRequired}`
    );
  };
  /** ------------------------------------------------------------- */

  const handleCameraInput = () => {
    setIsImageInput(true);
    setIsDefectInput(false);
  };

  const [aiProcessingResponse, setAiProcessingResponse] = React.useState(null);
  const [aiIsProcessing, setAiIsProcessing] = useState(false);

  const handleCamerInputProcessing = () => {
    setIsImageInput(false);
    setIsDefectInput(true);
    setAiIsProcessing(true);
  };

  const handleAiProcessingResponse = (response) => {
    setAiIsProcessing(false);
    setAiProcessingResponse(response);
  };

  const cards = useMemo(
    () =>
      itCards.map((character, index) => ({
        character,
      })),
    [itCards]
  );

  const childRefs = useMemo(
    () =>
      Array(itCards.length)
        .fill(0)
        .map(() => React.createRef()),
    [itCards]
  );

  const checkValidDefectDescription = () => {
    if (modalOpenDueToOtherDefect) {
      return (
        !defectsFound[defectsFound.length - 1] ||
        defectDescriptionFields?.defectDescriptionFields?.some(
          (field) =>
            defectDescriptionFields?.defectDescriptionFieldsMandatory[field] &&
            (defectsFound[defectsFound.length - 1][field] === '' ||
              defectsFound[defectsFound.length - 1][field] === null ||
              defectsFound[defectsFound.length - 1][field] === undefined)
        )
      );
    } else {
      return (
        !defectsFound[dataIndex] ||
        defectDescriptionFields?.defectDescriptionFields?.some(
          (field) =>
            defectDescriptionFields?.defectDescriptionFieldsMandatory[field] &&
            (defectsFound[dataIndex][field] === '' ||
              defectsFound[dataIndex][field] === null ||
              defectsFound[dataIndex][field] === undefined)
        )
      );
    }
  };

  const defectDescriptionSaved = () => {
    // Check based on defectDescriptionFields if all fields are filled out
    if (checkValidDefectDescription()) {
      enqueueSnackbar(t('pleaseFillOutAllFields'), { variant: 'warning' });
      return;
    }
    if (
      qualityStationDefectPhotoRequired &&
      defectsFound[defectInputIndex]?.defectImages.length === 0
    ) {
      enqueueSnackbar(t('pleaseCaptureImage'), { variant: 'warning' });
      return;
    }
    if (modalOpenDueToOtherDefect) {
      updateCurrentDefect(
        { defectDetected: 'yes' },
        defectsFound[itCards.length + otherDefectIndex].otherDefectID
      );
    } else {
      updateCurrentDefect({ defectDetected: 'yes' }, defectsFound[dataIndex].cardID);
      childRefs[dataIndex].current.swipe('left');
      setDataIndex(dataIndex + 1);
    }
    setIsDefectInput(false);
    setCameraState(false);
    triggerAnimation();
  };

  const defectDescriptionCanceled = async () => {
    setIsDefectInput(false);
    const updatedDefectsFound = [...defectsFound];
    if (modalOpenDueToOtherDefect) {
      updatedDefectsFound.splice(-1, 1);
      setOtherDefectIndex((prevIndex) => prevIndex - 1);
    } else {
      updatedDefectsFound[dataIndex] = getDefectTemplate(dataIndex);
    }
    setDefectsFound(updatedDefectsFound);
    setCameraState(false);
  };

  const restoreCardsAfterFinishedTest = async () => {
    itCards.map((el, ind) => {
      childRefs[ind].current.restoreCard();
    });
    // set defectsdetected to '' for all cards
    const updatedDefectsFound = [...defectsFound];
    updatedDefectsFound.map((el, ind) => {
      updatedDefectsFound[ind].defectDetected = '';
    });
    setDefectsFound(updatedDefectsFound);
    navigate(
      '/intelligent_testing/recommender/swipe?qualityStation=' +
        qualityStation +
        '&productID=' +
        productID +
        '&finishTest=true'
    );
  };

  useEffect(() => {
    if (finishTest === 'false') {
      restoreCardsAfterFinishedTest();
      setDataIndex(0);
    }
  }, [finishTest]);

  useEffect(() => {
    if (dataIndex !== 0 && !defectsFound.some((el) => el.defectDetected === '')) {
      setIsSummaryOpen(true);
    }
  }, [dataIndex, itCards.length, defectsFound[otherDefectIndex + itCards.length]?.defectDetected]);

  const triggerAnimation = () => {
    setIsExploding(true);
    setTimeout(() => {
      setIsExploding(false);
    }, 5000);
  };

  const memoizedCards = useMemo(() => cards.reverse(), [cards]);

  const defectInputIndex = useMemo(() => {
    if (modalOpenDueToOtherDefect) {
      return itCards.length + otherDefectIndex;
    } else {
      return dataIndex;
    }
  }, [
    modalOpenDueToOtherDefect,
    dataIndex,
    otherDefectIndex,
    itCards.length,
    defectsFound[otherDefectIndex + itCards.length]?.defectDetected,
  ]);

  const headerCenterText = `${dataIndex + 1}/${itCards.length}`;

  return (
    <>
      <DefectInput
        productID={productID}
        qualityStation={qualityStation}
        dataIndex={dataIndex}
        isDefectInput={isDefectInput}
        setIsDefectInput={setIsDefectInput}
        defectsFound={defectsFound}
        setDefectsFound={setDefectsFound}
        updateCurrentDefect={updateCurrentDefect}
        defectsCurrentIndex={defectInputIndex}
        cameraState={cameraState}
        setCameraState={setCameraState}
        defectDescriptionFields={defectDescriptionFields}
        defectDescriptionFieldOptions={defectDescriptionFieldOptions}
        setDefectDescriptionFieldOptions={setDefectDescriptionFieldOptions}
        defectDescriptionCanceled={defectDescriptionCanceled}
        defectDescriptionSaved={defectDescriptionSaved}
        recommenderMenuOpen={recommenderMenuOpen}
        setRecommenderMenuOpen={setRecommenderMenuOpen}
        handleCameraInput={handleCameraInput}
        aiProcessingResponse={aiProcessingResponse}
        setAiProcessingResponse={setAiProcessingResponse}
        aiIsProcessing={aiIsProcessing}
        setAiIsProcessing={setAiIsProcessing}
        itCards={itCards}
        defectPhotoRequired={qualityStationDefectPhotoRequired}
        regExPattern={qualityStationRegexPattern}
      />
      <TestOverview
        qualityStation={qualityStation}
        cards={cards}
        defectsFound={defectsFound}
        handleGoBackButtonClicked={handleGoBackButtonClicked}
        isSummaryOpen={isSummaryOpen}
        setIsSummaryOpen={setIsSummaryOpen}
        handleSaveButtonClicked={handleSaveButtonClicked}
        handleDiscardButtonClicked={handleDiscardButtonClicked}
        recommenderMenuOpen={recommenderMenuOpen}
        setRecommenderMenuOpen={setRecommenderMenuOpen}
        defectDescriptionFields={defectDescriptionFields}
      />
      <TestFeedback
        productID={productID}
        setPageName={setPageName}
        setIsFeedbackOpen={setIsFeedbackOpen}
        isFeedbackOpen={isFeedbackOpen}
        recommenderMenuOpen={recommenderMenuOpen}
        setRecommenderMenuOpen={setRecommenderMenuOpen}
        testTitle={itCards[dataIndex]?.title}
        testImages={itCards[dataIndex]?.images}
        testObject={itCards[dataIndex]?.testObject}
        testMethod={itCards[dataIndex]?.testMethod}
        testLocation={itCards[dataIndex]?.testLocation}
        cardID={itCards[dataIndex]?.cardID}
      />
      {isImageInput && (
        <ImageInput
          handleProcessing={handleCamerInputProcessing}
          handleResponse={(response) => handleAiProcessingResponse(response)}
        />
      )}
      {recommenderMenuOpen ? (
        itCards?.length !== 0 || dataRequestFinished ? (
          <CardWrapper hideCard={hideSwipeCard}>
            {memoizedCards.map((card, index) => (
              <TinderCard
                ref={childRefs[card.character.id]}
                className="swipe"
                key={card.character.title}
                onSwipe={(dir) => {
                  handleSwipe(dir);
                  setCurrentImageIndex(0);
                }}
                swipeRequirementType="position"
                swipeThreshold={50}
                preventSwipe={
                  card.character.id === dataIndex ? ['up', 'down'] : ['up', 'down', 'left', 'right']
                }
                flickOnSwipe={true}
                rightSwipeRestore={true}
              >
                <CardPaperWrapper>
                  <SwipeCardHeader
                    productID={productID}
                    centerText={headerCenterText}
                    qualityStation={qualityStation}
                    isExploding={isExploding}
                  />
                  <ImagePanel
                    images={card?.character?.images}
                    backgroundImage={
                      card?.character?.images && card?.character?.images?.length > 0
                        ? card?.character?.images[currentImageIndex]
                            ?.split('?')[0]
                            .split('/')
                            .map((part, index) => (index <= 2 ? part : encodeURIComponent(part)))
                            .join('/')
                        : '/images/placeholderImage.png'
                    }
                    heightOffset={10}
                    cardLayout={cardLayout}
                  >
                    <SwipeCardImageStepper
                      images={card?.character?.images}
                      card={card}
                      currentImageIndex={currentImageIndex}
                      setCurrentImageIndex={setCurrentImageIndex}
                      setIsFeedbackOpen={setIsFeedbackOpen}
                    />
                  </ImagePanel>
                  <SwipeCardInput Icon={LabelIcon}>
                    <QualitatioInput
                      label={t('title')}
                      value={card.character?.title || ''}
                      width="100%"
                      xs={true}
                      readOnly={true}
                    />
                  </SwipeCardInput>
                  {!cardLayout && (
                    <>
                      <SwipeCardInput Icon={TestObjectIcon}>
                        <QualitatioInput
                          label={t('testObject')}
                          value={card.character?.testObject || ''}
                          width="100%"
                          xs={true}
                          readOnly={true}
                        />
                      </SwipeCardInput>
                      <SwipeCardInput Icon={TestLocationIcon}>
                        <QualitatioInput
                          label={t('testLocation')}
                          value={card.character?.testLocation || ''}
                          width="100%"
                          xs={true}
                          readOnly={true}
                        />
                      </SwipeCardInput>
                      <SwipeCardInput Icon={TestMethodIcon}>
                        <QualitatioInput
                          label={t('testMethod')}
                          value={card.character?.testMethod || ''}
                          width="100%"
                          xs={true}
                          readOnly={true}
                        />
                      </SwipeCardInput>
                      <SwipeCardInput Icon={TestCommentIcon}>
                        <QualitatioInput
                          label={t('testComment')}
                          value={card.character?.testComment || ''}
                          width="100%"
                          xs={true}
                          readOnly={true}
                        />
                      </SwipeCardInput>
                    </>
                  )}
                  <SwipeCardButtonBar>
                    <SwipeCardButtonWithLabel
                      Icon={CheckIcon}
                      label={t('io')}
                      handleClick={handleIOButtonClicked}
                      backgroundColor="#1fbebd"
                    />
                    <SwipeCardButtonWithLabel
                      Icon={AddIcon}
                      label={t('otherDefect')}
                      handleClick={handleOtherDefectButtonClicked}
                      backgroundColor="#E0E3E2"
                      color="black"
                      labelStyle={{ whiteSpace: 'nowrap' }}
                    />
                    <SwipeCardButtonWithLabel
                      Icon={CloseIcon}
                      label={t('nio')}
                      handleClick={handleNIOButtonClicked}
                      backgroundColor="#ff0000"
                    />
                  </SwipeCardButtonBar>
                  <SwipeCardFooter text={card.character?.explanation} />
                </CardPaperWrapper>
              </TinderCard>
            ))}
            {dataRequestFinished && itCards?.length === 0 && (
              <Typography variant="h3" color="text.secondary" style={{ marginTop: '8px' }}>
                {t('noTestsAvailable')}
              </Typography>
            )}
          </CardWrapper>
        ) : (
          <CalculateTesting
            productID={productID}
            qualityStation={qualityStation}
            recommenderMenuOpen={recommenderMenuOpen}
            setRecommenderMenuOpen={setRecommenderMenuOpen}
          />
        )
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}
