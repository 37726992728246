import { Modules } from "../../../config/enums";

/**
 * Retrieves the possible algorithms based on the module name and anomaly flag.
 *
 * @param {string} moduleName - The name of the module.
 * @param {boolean} [anomaly=false] - Flag indicating if anomaly detection algorithms should be included.
 * @param {function} t - The translation function.
 * @returns {Array} - An array of possible algorithms.
 */
export const getPossibleAlgorithms = (moduleName, anomaly = false, t) => {
  // Define possible algorithms within the scope of this function to utilize the `t` function
  const possibleAlgorithms = [
    { value: "autoLearning", label: `Auto Learning (${t("recommended")})` },
    { value: "neuralNetwork", label: "Neural Network" },
    { value: "randomForest", label: "Random Forest" },
    { value: "xgboost", label: "XGBoost" },
    { value: "catboost", label: "CatBoost" },
    { value: "tabnet", label: "TabNet" },
    { value: "logisticRegression", label: "Logistic Regression" },
  ];

  const possibleAnomalyDetectionAlgorithms = [
    { value: "anomalyDetectionEcod", label: "Anomaly Detection (ECOD)" },
  ];

  const possibleTimeseriesForecastAlgorithms = [
    { value: "prophetForecast", label: "Prophet Forecast" },
  ];

  switch (moduleName) {
    case Modules.EFFICIENT_TESTING:
    case Modules.INTELLIGENT_TESTING:
      return possibleAlgorithms;
    case Modules.EARLY_WARNING_SYSTEM:
      return anomaly
        ? possibleAnomalyDetectionAlgorithms
        : possibleTimeseriesForecastAlgorithms;
    default:
      return possibleAlgorithms;
  }
};

//function that gets true or false based on if the algorithm is in the possibleAnomalyDetectionAlgorithms array
export const isAnomalyDetectionAlgorithm = (algorithm) =>
  getPossibleAlgorithms(Modules.EARLY_WARNING_SYSTEM, true).some(
    (a) => a.value === algorithm
  );

/**
 * Returns an array of possible pattern recognition types.
 *
 * @param {Function} t - The translation function.
 * @returns {Array} An array of objects containing the value and label for each pattern recognition type.
 */
export const getPossiblePatternRecognitionTypes = (t) => [
  { value: "anomalyDetection", label: t("ews.anomalyDetection") },
  { value: "timeseriesForecast", label: t("ews.timeseriesForecast") },
];
