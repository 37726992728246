import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Autocomplete, Badge, Button, Chip, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineUser } from 'react-icons/ai';
import { FaCalendarAlt } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import { MdGroup, MdOutlineManageAccounts, MdPassword } from 'react-icons/md';
import axiosClient from '../../api/axiosClient';
import { verifyUserEndpoint } from '../../api/endpoints';
import { getUsers } from '../../api/user';
import ROLES from '../../authentication/roleConst';
import { flagComponents } from '../../components/Flags/FlagComponents';
import PageFrame from '../../components/PageFrame';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import { useAuthStore } from '../../store/auth.store';
import './UserManager.css';
import ConnectGroupOverlay from './components/ConnectGroupOverlay';
import DeleteUserOverlay from './components/DeleteUserOverlay';
import MultiActionSpecifyOverlay from './components/MultiActionSpecifyOverlay';
import RequestsOverlay from './components/RequestsOverlay';
import UserAddOverlay from './components/UserAddOverlay';
import UserEditOverlay from './components/UserEditOverlay';

export default function UserManager({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('userManagement'));
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isRequestsModalOpen, setIsRequestsModalOpen] = useState(false);
  const [isMultiActionModalOpen, setIsMultiActionModalOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [registerRequests, setRegisterRequests] = useState([]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    //get register requests from server
    const requests = getUsers({ isVerified: false, isEmailVerified: true });
    requests
      .then((res) => {
        setRegisterRequests(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function fetchUsers() {
    try {
      const fetchedUsers = await getUsers({ isVerified: true }, { limit: 99999 });
      if (fetchedUsers && !fetchedUsers.error) {
        setUsers(fetchedUsers);
      } else {
        enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      console.error(error);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAction = (userId, accepted) => {
    axiosClient
      .post(verifyUserEndpoint, { userId: userId, accepted: accepted })
      .then((res) => {
        //if the request was successful, remove the request from the list of requests
        if (res.status === 204) {
          setRegisterRequests(registerRequests.filter((request) => request.id !== userId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddUser = () => {
    setIsAddModalOpen(true);
  };

  const handleAddClose = () => {
    setIsAddModalOpen(false);
    fetchUsers();
  };

  const handleConnectGroup = () => {
    setIsConnectModalOpen(true);
  };

  const handleConnectClose = () => {
    setIsConnectModalOpen(false);
  };

  // Function to handle edit button click. Here you would generally navigate to the edit user page with the user id.
  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    setShowDeleteConfirm(true);
  };

  const handleClose = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
    // Refetch users to update the table
    fetchUsers();
  };

  const handleRequests = () => {
    setIsRequestsModalOpen(true);
  };

  const handleRequestsClose = () => {
    setIsRequestsModalOpen(false);
  };

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t('email'),
      width: 200,
      flex: 1,
    },
    {
      field: 'role',
      headerName: t('role'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={t(`users.${params.value}`)}
          sx={{ backgroundColor: theme.palette.success.secondary }}
          icon={
            params.value === ROLES.ADMIN ? (
              <GrUserAdmin />
            ) : params.value === ROLES.IT_USER || params.value === ROLES.ET_USER ? (
              <AiOutlineUser />
            ) : (
              <MdOutlineManageAccounts />
            )
          }
        />
      ),
    },
    {
      field: 'authSystem',
      headerName: t('authSystem'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={t(`labels.${params.value}`)}
          icon={params.value === 'password' ? <MdPassword /> : <MdGroup />}
        />
      ),
    },
    {
      field: 'lastLogin',
      headerName: t('lastLogin'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{ backgroundColor: theme.palette.warning.main }}
          icon={<FaCalendarAlt />}
        />
      ),
    },
    {
      field: 'language',
      headerName: t('language'),
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const FlagIcon = flagComponents[params.value];
        return (
          <div
            style={{
              background: 'transparent', // Set the background
              display: 'flex', // Make it a flex container to center the image
              alignItems: 'center', // Center vertically
              height: '100%', // Set the height to 100% to center the image
              width: '100%', // Set the width to 100% to center the image
            }}
          >
            {FlagIcon && <FlagIcon style={{ width: '31px', height: 'auto', marginRight: '5px' }} />}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <div className="userManagerButtons">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => handleEdit(params.row)}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '30px',
              height: '30px',
            }}
          >
            <CreateOutlinedIcon color="primary" />
          </IconButton>
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => handleDelete(params.row)}
            background={theme.palette.error.main}
            disabled={params.row.id === user.id}
            style={{ backgroundColor: theme.palette.error.main, width: '30px', height: '30px' }}
          >
            <DeleteForeverIcon color="white" />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = users.map((user) => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      authSystem: user.authSystem,
      lastLogin: user.lastLogin ? new Date(user.lastLogin).toLocaleString() : t('never'),
      language: user.language,
    };
  });

  const [multipleActions, setMultipleActions] = useState();

  const multipleActionsOptions = [
    { label: t('none'), value: null },
    { value: 'changeRole', label: t('changeRole') },
    { value: 'changeLanguage', label: t('changeLanguage') },
    { value: 'delete', label: t('delete') },
  ];

  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const handleMultipleActions = () => {
    if (selectedRowIDs.length === 0) {
      enqueueSnackbar(t('noUserSelectedSnack'), { variant: 'error' });
      return;
    }
    if (multipleActions?.value === undefined || multipleActions?.value === null) {
      enqueueSnackbar(t('noActionSelectedSnack'), { variant: 'error' });
      return;
    }

    setIsMultiActionModalOpen(true);
  };

  const handleMultipleFunctions = async (...functions) => {
    for (const func of functions) {
      if (typeof func === 'function') {
        await func();
      }
    }
  };

  return (
    <PageFrame title={t('userManagement')} Icon={PersonAddAltIcon}>
      <div className="layoutUserManagement">
        <div className="container">
          <div className="multipleActions">
            <Autocomplete
              value={multipleActions}
              onChange={(event, newValue) => {
                setMultipleActions(newValue);
              }}
              options={multipleActionsOptions}
              getOptionLabel={(option) => option.label} // Assuming each option has a label property
              sx={{ width: 250 }} // Styling
              renderInput={(params) => (
                <TextField {...params} label={t('selectAction')} variant="outlined" />
              )}
            />
            <Button
              variant="qualitatio"
              color="primary"
              onClick={handleMultipleActions}
              data-testid="handleMultiActions"
            >
              {t('apply')}
            </Button>
          </div>
          <Button
            variant="qualitatio"
            onClick={handleAddUser}
            className="buttonManage"
            data-testid="handleAddUser"
          >
            {t('createUserTitle')}
          </Button>
          <Button
            variant="qualitatio"
            onClick={handleConnectGroup}
            className="buttonManage"
            data-testid="handleConnectGroup"
          >
            {t('manageRoleMappings')}
          </Button>
          <IconButton variant="qualitatio" onClick={handleRequests} data-testid="handleRequests">
            <Badge badgeContent={registerRequests.length} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
        </div>
        <div className="userMangerTable">
          <QualitatioTable
            rows={rows}
            columns={columns}
            checkboxSelection={true}
            height="90%"
            disableRowSelectionOnClick={true}
            onRowSelectionModelChange={(selection) => {
              if (selection.length === users.length) {
                // "select all" checkbox was clicked
                setSelectedRowIDs(rows.map((row) => row.id));
              } else {
                // Other rows were selected
                setSelectedRowIDs(selection.map((selectedRow) => selectedRow));
              }
            }}
          />
        </div>
      </div>
      {isModalOpen && selectedUser && (
        <UserEditOverlay
          user={selectedUser}
          onClose={() => handleMultipleFunctions(handleClose, fetchUsers)}
        />
      )}
      {isAddModalOpen && (
        <UserAddOverlay onClose={() => handleMultipleFunctions(handleAddClose, fetchUsers)} />
      )}
      {isConnectModalOpen && (
        <ConnectGroupOverlay
          onClose={() => handleMultipleFunctions(handleConnectClose, fetchUsers)}
        />
      )}
      {isRequestsModalOpen && (
        <RequestsOverlay
          onClose={() => handleMultipleFunctions(handleRequestsClose, fetchUsers)}
          requests={registerRequests}
          handleAction={handleAction}
        />
      )}
      <DeleteUserOverlay
        selectedUser={selectedUser}
        onClose={() => setShowDeleteConfirm(false)}
        showDeleteConfirm={showDeleteConfirm}
        reload={fetchUsers}
      />
      <MultiActionSpecifyOverlay
        selectedUsers={users.filter((user) => selectedRowIDs.includes(user.id))}
        onClose={() => setIsMultiActionModalOpen(false)}
        open={isMultiActionModalOpen}
        reload={fetchUsers}
        action={multipleActions}
      />
    </PageFrame>
  );
}
