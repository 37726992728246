import { Grid, Paper } from '@mui/material';

export const CardPanelWrapper = ({ children, ...props }) => {

    const paperLayoutProps = {
        square: false,
        style: {
            width: '100%',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 6.63px 13.25px #1018280d',
            position: 'relative',
        },
    };

    return (
        <Grid item xs={12} {...props}>
            <Paper {...paperLayoutProps}>
                {children}
            </Paper>
        </Grid>
    )
}

export default CardPanelWrapper;
