import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import "./QualitatioDropzone.css";
import { useSnackbar } from "notistack";

/**
 * QualitatioDropzone: A drag-and-drop file input component.
 * 
 * Props:
 * - onDropExternal (function): Callback after file drop.
 * - enablePreview (boolean): Enables image preview (default: false).
 * - acceptedFileTypes (object): MIME types/ extensions for accepted files, exact type can be found here: https://react-dropzone.org/#!/Accepting%20specific%20file%20types
 * - children (ReactNode): Optional content inside dropzone.
 * - previewOld (string): URL/Data URL for previous image preview.
 * - argsForUpload (array): Additional args for onDropExternal callback.
 * Styles are defined in 'QualitatioDropzone.css'.
 * 
 * Limitation: Image previews only.
 */
export default function QualitatioDropzone({
    onDrop: onDropExternal,
    enablePreview = false,
    acceptedFileTypes,
    children,
    previewOld = null,
    argsForUpload = null,
    ...props
}) {
    const { t } = useTranslation();;
    const [preview, setPreview] = useState(null);
    const [fileType, setFileType] = useState(null);
    const { enqueueSnackbar } = useSnackbar();


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles, rejectedFiles) => {
            acceptedFiles.forEach((file) => {
                setFileType(file.type);

                if (onDropExternal) {
                    if (argsForUpload === null)
                        onDropExternal(file);
                    else {
                        onDropExternal(file, ...argsForUpload);
                    }
                }

                if (enablePreview) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setPreview(reader.result);
                    };
                    reader.readAsDataURL(file);
                }
            });
            rejectedFiles.forEach((file) => {
                console.log(file);
                enqueueSnackbar(t("fileRejected") + file.file.name, { variant: 'error' });
            });
        },
        accept: acceptedFileTypes
    });

    const renderPreview = () => {
        if (preview !== null) {
            switch (true) {
                case /^image\//.test(fileType):
                    return <img src={preview} alt="Image preview" className='dropzone-preview' />;
                /*                 case /^text\//.test(fileType):
                                    return <pre className='dropzone-preview'>{preview}</pre>; */
                // case "application/pdf":
                // You'd handle PDF previews here, perhaps using pdf.js
                default:
                    return null;
            }
        } else if (previewOld !== null) {
            return <img src={previewOld} alt="Image preview" className='dropzone-preview' />;
        } else {
            return null;
        }
    }
    return (
        <Box>
            <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
                <Box {...getRootProps()}
                    className={isDragActive ? "dropzone-active" : "dropzone-inactive"}
                    alignItems="center" justifyContent="center" display="flex" flexDirection="column"
                >
                    <input {...getInputProps()} />
                    {children || (
                        <>
                            <Typography>
                                {t("dropFilesHere")}
                            </Typography>
                            <Typography>
                                {t("orClickToUpload")}
                            </Typography>
                        </>
                    )}
                    {enablePreview && renderPreview()}
                </Box>
            </Grid>
        </Box>
    );
}
