// src/components/CreateRuleFormDialog.jsx

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QualitatioButton } from '../../../../components';
import './CreateRuleFormDialog.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const countErrors = (errorsObj) => {
  let count = 0;

  const traverse = (obj) => {
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        traverse(obj[key]);
      }
    } else if (obj !== undefined && obj !== null && obj !== '') {
      count += 1;
    }
  };

  traverse(errorsObj);
  return count;
};

const CreateRuleFormDialog = ({
  title,
  description,
  open,
  onClose,
  actions,
  children,
  onSubmit,
  footer,
  subheader = '',
  maxWidth = 'lg',
  overflow = 'auto',
  gap = '2vh',
  results,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const { errors } = useFormikContext();
  const errorCount = countErrors(errors);

  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (results && results.value !== undefined) {
      setHighlight(true);
      const timer = setTimeout(() => setHighlight(false), 1000); // Duration matches CSS animation
      return () => clearTimeout(timer);
    }
  }, [results.value]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="create-rule-form-dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
      {...otherProps}
      variant="qualitatio_dialog"
      PaperProps={{ sx: { minWidth: '85vw', minHeight: '90vh' } }}
    >
      {/* Dialog Title with Close Icon */}
      <DialogTitle
        sx={{
          textAlign: 'center',
          position: 'relative',
          padding: '16px',
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={
            actions.some((action) => action.label === t('cancel'))
              ? actions.find((action) => action.label === t('cancel')).onClick
              : onClose
          }
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: gap, overflow: overflow }}
      >
        {subheader && (
          <DialogContentText display={'flex'} justifyContent={'center'}>
            {subheader}
          </DialogContentText>
        )}
        {description && (
          <DialogContentText
            id="create-rule-form-dialog-description"
            display={'flex'}
            justifyContent={'center'}
          >
            {description.split('\n').map((item, idx) => (
              <div key={idx} dangerouslySetInnerHTML={{ __html: item }}></div>
            ))}
          </DialogContentText>
        )}
        {children}
        {footer && <DialogContentText>{footer}</DialogContentText>}
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        {/* Results Section */}
        {results && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <Typography variant="h4" sx={{ display: 'flex', direction: 'row' }}>
              <Box>{`${t('matches')}: `}</Box>
              <Box
                className={highlight ? 'create-rule-modal-highlight' : ''}
                style={{ marginLeft: '4px' }}
              >
                {results.isFetchingNumberOfApplications ? (
                  <CircularProgress size={12} style={{ marginLeft: '4px' }} /> // Adjust the size to match the number
                ) : (
                  `${results.value ? results.value : 0}${results.isPercent ? '%' : ''}`
                )}
              </Box>
            </Typography>
            <TextField
              label={t('TimeValue')}
              type="number"
              value={results.timeValue}
              onChange={results.onTimeValueChange}
              size="small"
              sx={{ width: '100px' }}
              InputProps={{ inputProps: { min: 1 } }}
            />
            <FormControl variant="outlined" size="small" sx={{ width: '100px' }}>
              <InputLabel id="time-unit-label">{t('TimeUnit')}</InputLabel>
              <Select
                labelId="time-unit-label"
                value={results.timeUnit}
                onChange={results.onTimeUnitChange}
                label={t('TimeUnit')}
                input={<OutlinedInput label={t('TimeUnit')} variant="qualitatio" />}
              >
                <MenuItem value="h">{t('hour')}</MenuItem>
                <MenuItem value="d">{t('day')}</MenuItem>
                <MenuItem value="w">{t('week')}</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={results.isPercent}
                  onChange={results.onIsPercentChange}
                  color="primary"
                />
              }
              label={t('percent')}
              style={{ marginRight: 0 }}
            />

            {/* Divider and Error Count */}
            <Divider orientation="vertical" flexItem />
            <Typography variant="h4">{`${t('missingFields')}: ${errorCount}`}</Typography>
          </div>
        )}

        {/* Action Buttons */}
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <form onSubmit={onSubmit}>
            {actions?.map((action, idx) => {
              return (
                <QualitatioButton
                  key={idx}
                  text={action.label}
                  onClick={action.onClick}
                  order={action.order}
                  margin={'0 0 0 10px'}
                  disabled={action.disabled}
                  startIcon={action.startIcon}
                  width={action.width}
                  type={action.type || 'button'}
                />
              );
            })}
          </form>
        </div>
      </DialogActions>
    </Dialog>
  );
};

CreateRuleFormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired, // Now required
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      order: PropTypes.oneOf(['primary', 'secondary']),
      disabled: PropTypes.bool,
      startIcon: PropTypes.element,
      width: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.node,
  footer: PropTypes.string,
  subheader: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  overflow: PropTypes.oneOf(['auto', 'hidden', 'scroll', 'visible']),
  gap: PropTypes.string,
  results: PropTypes.shape({
    timeValue: PropTypes.number.isRequired,
    timeUnit: PropTypes.oneOf(['h', 'd', 'w']).isRequired,
    isPercent: PropTypes.bool.isRequired,
    onTimeValueChange: PropTypes.func.isRequired,
    onTimeUnitChange: PropTypes.func.isRequired,
    onIsPercentChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    isFetchingNumberOfApplications: PropTypes.bool.isRequired,
  }),
};

export default CreateRuleFormDialog;
