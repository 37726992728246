import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { getQualityStationsWithImagesEndpoint } from '../../../api/endpoints';
import { Avatar } from '../../../components/Avatar';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import { useAuthStore } from '../../../store/auth.store';
import Menu from '../Menu';
import './Select.css';

export default function Select({
  setPageName,
  setActiveQualityStation,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
}) {
  const { t } = useTranslation();
  setPageName(t('selectQS'));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthStore((state) => ({ user: state.user }));

  const [availableQualityStations, setAvailableQualityStations] = useState([]);

  useEffect(() => {
    loadQualityStations();
  }, []);

  const loadQualityStations = async () => {
    try {
      const response = await axiosClient.get(getQualityStationsWithImagesEndpoint);
      setAvailableQualityStations(response.data.qualityStations);
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('errorDuringLoadingQualityStations'), { variant: 'error' });
    }
  };

  const qualityStationChosen = async (qualityStation, image, regexPattern, defectPhotoRequired) => {
    try {
      setActiveQualityStation(qualityStation);
      navigate(
        `/intelligent_testing/recommender/scan?qualityStationName=${qualityStation}&qualityStationImage=${image}&qualityStationRegexPattern=${regexPattern}&defectPhotoRequired=${defectPhotoRequired}`
      );
    } catch (error) {
      enqueueSnackbar(t('errorDuringSelectingQualityStations'), { variant: 'error' });
    }
  };

  return (
    <>
      {recommenderMenuOpen ? (
        <div className="select-layout">
          <div className="select-welcome-bar">
            <div className="select-welcome-content">
              <>
                {t('welcome')},
                <br />
                {user.name.split(' ')[0]}!
              </>
              <Avatar profileImage={user.profileImage} />
            </div>
          </div>
          <div className="select-station">
            <div className="select-station-content">
              <div className="select-station-text">{t('selectQualityStation')}</div>
              {availableQualityStations?.length > 0 ? (
                availableQualityStations.map((qualityStation) => (
                  <QualitatioButton
                    text={qualityStation.name}
                    startIcon={<img src={qualityStation.image} alt={qualityStation.name} />}
                    startIconIsImage={true}
                    startIconRounded={true}
                    endIcon={<ArrowForwardIosRoundedIcon />}
                    width={'100%'}
                    onClick={() =>
                      qualityStationChosen(
                        qualityStation.name,
                        qualityStation.image,
                        qualityStation.regexPattern,
                        qualityStation.defectPhotoRequired
                      )
                    }
                  />
                ))
              ) : (
                <div className="no-quality-stations-available">
                  {t('noQualityStationAvailable')}
                </div>
              )}
            </div>
          </div>
          <div className="select-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}
