import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { FaFileCsv, FaRegClock } from 'react-icons/fa';
import { MdDownload, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import axiosClient from '../../api/axiosClient';
import {
  getDefectClassNamesEndpoint,
  getLiveTrainingMetaDataEndpoint,
  getStreamTrainingDataAsCSVEndpoint,
  liveStreamDataEndpoint,
  saveCSVFileTrainingEndpoint,
} from '../../api/endpoints';
import QualitatioAccordion from '../../components/QualitatioAccordion/QualitatioAccordion';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioChipArray from '../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioDateTimePicker from '../../components/QualitatioDateTimePicker/QualitatioDateTimePicker';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDragAndDrop from '../../components/QualitatioDragAndDrop/QualitatioDragAndDrop';
import QualitatioHighlightBox from '../../components/QualitatioHighlightBox/QualitatioHighlightBox';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioMobileStepper from '../../components/QualitatioMobileStepper/QualitatioMobileStepper';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import { Modules } from '../../config/enums';
import './AIConfiguratorSteps.css';
import {
  getPossibleAlgorithms,
  getPossiblePatternRecognitionTypes,
} from './components/aiConfiguratorEnums';

export default function AIConfiguratorSteps({
  progress,
  csvDecisionMade,
  liveTrainingData,
  setLiveTrainingData,
  resetForm,
  onAddNewModelClose,
  startTraining,
  newModelPerformance,
  activeStep,
  setActiveStep,
  checkForWrongCharacter,
  nextIsDisabled,
  prevIsDisabled,
  newModelType,
  setNewModelType,
  checkIfModelNameExists,
  newModelName,
  setNewModelName,
  newModelModule,
  setNewModelModule,
  newModelLookupFileName,
  setNewModelLookupFileName,
  newModelInputFieldNames,
  setNewModelInputFieldNames,
  newModelInputFieldMapping,
  setNewModelInputFieldMapping,
  newModeldefectClassFieldNames,
  setNewModeldefectClassFieldNames,
  newModeldefectClassFieldMapping,
  setNewModeldefectClassFieldMapping,
  newModeldefectClassNames,
  setNewModeldefectClassNames,
  newModelAllFieldNames,
  setNewModelAllFieldNames,
  newModelTraining,
  setNewModelTraining,
  newModelRetraining,
  setNewModelRetraining,
  newModelOther,
  setNewModelOther,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [startTime, setStartTime] = useState(moment().subtract(1, 'years').format().split('+')[0]);
  const [endTime, setEndTime] = useState(moment().format().split('+')[0]);
  const [liveStreamData, setLiveStreamData] = useState([]);

  const [dateField, setDateField] = useState('');
  const [costField, setCostField] = useState('');

  const updateStates = () => {
    if (activeStep === 4 && newModelTraining.csvFileOrLiveData === 'live') {
      updateLiveTrainingMetaData();
      updateStartTime(moment(startTime).format().split('+')[0]);
      updateEndTime(moment(endTime).format().split('+')[0]);
    }
    if (activeStep === 6 && newModelTraining.csvFileOrLiveData === 'csv') {
      getDefectClassNames();
    }
    if (activeStep === 3) {
      getLiveStreamData();
    }
  };

  const updateLiveTrainingMetaData = async () => {
    try {
      const response = await axiosClient.post(getLiveTrainingMetaDataEndpoint, {
        timePeriod: {
          startTime: moment(startTime).format().split('+')[0],
          endTime: moment(endTime).format().split('+')[0],
        },
      });
      setLiveTrainingData(response.data.liveTrainingData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectClassNames = async () => {
    try {
      const response = await axiosClient.post(getDefectClassNamesEndpoint, {
        csvFile: newModelTraining.csvFile,
        defectClassFieldNames: newModeldefectClassFieldNames,
        dateField: dateField,
        costField: costField,
        module: newModelModule,
      });
      setNewModeldefectClassNames(response.data.defectClassNames);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getParsecsvdata = (data) => {
    // Parse CSV data with PapaParse
    Papa.parse(data, {
      complete: (results) => {
        const parsedData = results.data; // Parsed data rows

        // Assume the first row contains field names
        const fieldNames = parsedData[0];

        // Initialize an array to keep track of whether each column might be a Date
        let potentialDateColumns = new Array(fieldNames.length).fill(true);
        let potentialCostColumns = new Array(fieldNames.length).fill(true);

        // Check the first 3 rows (excluding header) to determine if each column could be a Date
        for (let rowIndex = 1; rowIndex < Math.min(parsedData.length, 3); rowIndex++) {
          for (let colIndex = 0; colIndex < fieldNames.length; colIndex++) {
            if (!isValidDate(parsedData[rowIndex][colIndex])) {
              potentialDateColumns[colIndex] = false;
            }
            if (!isPotentialCost(parsedData[rowIndex][colIndex])) {
              potentialCostColumns[colIndex] = false;
            }
          }
        }

        const potentialCostFieldIndex = fieldNames.findIndex(
          (el) =>
            el.toLowerCase().includes('cost') ||
            el.toLowerCase().includes('kosten') ||
            el.toLowerCase().includes('warranty') ||
            el.toLowerCase().includes('gewährleistung')
        );

        // Find the first column that could be a Date and call setDateField with its name
        const dateColumnIndex = potentialDateColumns.indexOf(true);
        if (dateColumnIndex !== -1) {
          setDateField(fieldNames[dateColumnIndex]);
        }

        if (potentialCostFieldIndex !== -1 && potentialCostColumns[potentialCostFieldIndex]) {
          setCostField(fieldNames[potentialCostFieldIndex]);
        }

        setNewModelAllFieldNames(fieldNames);
        setNewModelInputFieldMapping([]);
        setNewModelInputFieldNames([]);
        setNewModeldefectClassFieldNames([]);
        setNewModeldefectClassFieldMapping([]);
        setNewModeldefectClassNames([]);
      },
      skipEmptyLines: true,
    });
  };

  // Utility function to check if a string could be a Date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const isPotentialCost = (value) => {
    // A basic check for cost; adjust according to your data format
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const saveCSVFileOnServer = async (csvFileContent) => {
    try {
      const response = await axiosClient.post(saveCSVFileTrainingEndpoint, {
        modelName: newModelName,
        csvFile: csvFileContent,
      });
      setNewModelTraining((prevTraining) => ({
        ...prevTraining,
        csvFile: response.data.csvFile,
      }));
      setNewModelLookupFileName(response.data.lookupFileName);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const csvFileUploaded = (e) => {
    const input = document.getElementById('trainingFile');
    if (input.files && input.files[0]) {
      const csvFile = input.files[0];

      // Check if the file has a ".csv" extension
      var reader = new FileReader();

      reader.addEventListener('load', function (e) {
        let csvdata = e.target.result;
        getParsecsvdata(csvdata);
        saveCSVFileOnServer(e.target.result);
      });

      reader.readAsText(csvFile, 'utf8');
    }
  };

  const handleSelectField = (field) => {
    if (newModeldefectClassFieldNames.includes(field)) {
      setNewModeldefectClassFieldNames(
        newModeldefectClassFieldNames.filter((item) => item !== field)
      );
    } else if (newModelInputFieldNames.includes(field)) {
      setNewModelInputFieldNames(newModelInputFieldNames.filter((item) => item !== field));
    } else {
      setNewModelInputFieldNames([...newModelInputFieldNames, field]);
    }
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      streamTrainingDataAsCSV: null,
    }));
  };

  const updateStartTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    await setStartTime(selectedDateTime);
    await setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      timePeriod: { startTime: selectedDateTime, endTime: endTime },
      streamTrainingDataAsCSV: null,
    }));
  };

  const updateEndTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    await setEndTime(selectedDateTime);
    await setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      timePeriod: { startTime: startTime, endTime: selectedDateTime },
      streamTrainingDataAsCSV: null,
    }));
  };

  const handleDefectClassChange = (defectClassName, e) => {
    if (e.target.checked) {
      setNewModeldefectClassNames([...newModeldefectClassNames, defectClassName]);
    } else {
      setNewModeldefectClassNames(
        newModeldefectClassNames.filter((item) => item !== defectClassName)
      );
    }
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      streamTrainingDataAsCSV: null,
    }));
  };

  const getSelectedValue = (field, state) => {
    const mapping = state.find((el) => el.inputFieldName === field || el.defectClassName === field);
    if (mapping) {
      return `${mapping.mappedStreamName} > ${mapping.mappedStreamField}`;
    }
    return '';
  };

  const updateInputFieldMapping = (field, selectedValue) => {
    setNewModelInputFieldMapping((prevMapping) => {
      const updatedMapping = [...prevMapping];
      if (selectedValue === '') {
        // Handle the case when the selected value is empty
        const index = updatedMapping.findIndex((el) => el.inputFieldName === field);
        if (index !== -1) {
          // Remove the mapping if it exists
          updatedMapping.splice(index, 1);
        }
      } else {
        // Handle the case when a valid value is selected
        const [streamName, streamField] = selectedValue.split(' > ');
        const index = updatedMapping.findIndex((el) => el.inputFieldName === field);
        if (index === -1) {
          updatedMapping.push({
            inputFieldName: field,
            mappedStreamName: streamName,
            mappedStreamField: streamField,
          });
        } else {
          updatedMapping[index] = {
            inputFieldName: field,
            mappedStreamName: streamName,
            mappedStreamField: streamField,
          };
        }
      }
      return updatedMapping;
    });
  };

  const handleInputSelectionFieldChange = (field, mappedStreamName) => {
    if (newModelInputFieldNames.includes(field)) {
      setNewModelInputFieldNames(newModelInputFieldNames.filter((item) => item !== field));
      // update newModelInputFieldMapping
      const newModelInputFieldMappingCopy = [...newModelInputFieldMapping];
      const index = newModelInputFieldMappingCopy.findIndex((el) => el.inputFieldName === field);
      newModelInputFieldMappingCopy.splice(index, 1);
      setNewModelInputFieldMapping(newModelInputFieldMappingCopy);
    } else {
      setNewModelInputFieldNames([...newModelInputFieldNames, field]);
      // update newModelInputFieldMapping
      setNewModelInputFieldMapping([
        ...newModelInputFieldMapping,
        {
          inputFieldName: field,
          mappedStreamName: mappedStreamName,
          mappedStreamField: field,
        },
      ]);
    }
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      streamTrainingDataAsCSV: null,
    }));
  };

  const updateBuiltInAlgorithmsUsed = (newValue) => {
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      builtInAlgorithmsUsed: newValue,
    }));
  };

  const updateBuiltInAlgorithm = (newValue) => {
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      builtInAlgorithm: newValue,
    }));
  };

  const updateCustomAlgorithmName = (newValue) => {
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      customAlgorithmName: newValue,
    }));
  };

  const updateAutomaticRetrainingEnabled = (newValue) => {
    setNewModelRetraining((prevRetraining) => ({
      ...prevRetraining,
      automaticRetrainingEnabled: newValue,
    }));
  };

  const updateRetrainingBasedOnModelDecay = (newValue) => {
    setNewModelRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingBasedOnModelDecay: newValue,
    }));
  };

  const updateRetrainingModelDecayRateInPercent = (newValue) => {
    setNewModelRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingModelDecayRateInPercent: newValue,
    }));
  };

  const updateRetrainingFrequencyInDays = (newValue) => {
    setNewModelRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingFrequencyInDays: newValue,
    }));
  };

  const updateOnlineLearning = (newValue) => {
    setNewModelRetraining((prevRetraining) => ({
      ...prevRetraining,
      onlineLearning: newValue,
    }));
  };

  const updateCachingFrequencyInMinutes = (newValue) => {
    setNewModelOther((prevOther) => ({
      ...prevOther,
      cachingFrequencyInMinutes: newValue,
    }));
  };

  const updateModelIsWarranty = (newValue) => {
    setNewModelOther((prevOther) => ({
      ...prevOther,
      isWarranty: newValue,
    }));
  };

  const updateSelectedFeatureStream = (el, e) => {
    let newSelectedFeatureStreams = [];
    if (e.target.checked) {
      if (newModelTraining.selectedFeatureStreams) {
        newSelectedFeatureStreams = [...newModelTraining.selectedFeatureStreams, el.streamName];
      } else {
        newSelectedFeatureStreams = [el.streamName];
      }
      let allFieldNames = [...newModelAllFieldNames, ...el.fieldNames];
      setNewModelAllFieldNames(allFieldNames);
    } else {
      newSelectedFeatureStreams = newModelTraining.selectedFeatureStreams.filter((el_inner) => {
        return el_inner != el.streamName;
      });
      // Remove all fields from the allFieldNames array that are from the stream that was unchecked
      let allFieldNames = newModelAllFieldNames.filter((el_inner) => {
        return !el.fieldNames.includes(el_inner);
      });
      setNewModelAllFieldNames(allFieldNames);
    }
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      selectedFeatureStreams: newSelectedFeatureStreams,
      streamTrainingDataAsCSV: null,
    }));
  };

  const updateStreamTrainingDataAsCSV = (newValue) => {
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      streamTrainingDataAsCSV: newValue,
    }));
  };

  const [nameError, setNameError] = React.useState('');

  const setNewModelNameWithCheck = (newModelName) => {
    const wrongCharacter = checkForWrongCharacter(newModelName);
    const modelNameExists = checkIfModelNameExists(newModelName);
    if (wrongCharacter) {
      setNameError(t('wrongCharacter'));
    } else if (modelNameExists) {
      setNameError(t('modelAlreadyExists'));
    } else {
      setNameError('');
    }
    setNewModelName(newModelName);
  };

  const downloadTrainingDataSet = async () => {
    let csvDataLink = '';
    if (newModelTraining.csvFileOrLiveData === 'csv') {
      csvDataLink = newModelTraining.csvFile;
    } else {
      if (newModelTraining.streamTrainingDataAsCSV) {
        csvDataLink = newModelTraining.streamTrainingDataAsCSV;
      } else {
        try {
          const response = await axiosClient.post(getStreamTrainingDataAsCSVEndpoint, {
            name: newModelName,
            inputFieldMapping: newModelInputFieldMapping,
            defectClassNames: newModeldefectClassNames,
            timePeriod: { startTime: startTime, endTime: endTime },
          });
          updateStreamTrainingDataAsCSV(response.data.streamTrainingDataAsCSV);
          csvDataLink = response.data.streamTrainingDataAsCSV;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', csvDataLink);
    linkElement.setAttribute('download', 'trainingDataSet.csv');
    linkElement.click();

    // Clean up the temporary URL object
    URL.revokeObjectURL(linkElement.href);
  };

  const possibleModules = [
    { value: Modules.EFFICIENT_TESTING, label: t(Modules.EFFICIENT_TESTING) },
    {
      value: Modules.INTELLIGENT_TESTING,
      label: t(Modules.INTELLIGENT_TESTING),
    },
    {
      value: Modules.EARLY_WARNING_SYSTEM,
      label: t(Modules.EARLY_WARNING_SYSTEM),
    },
  ];

  const cachingFrequencies = [
    { value: 1, label: '1 ' + t('minutes') },
    { value: 2, label: '2 ' + t('minutes') },
    { value: 5, label: '5 ' + t('minutes') },
    { value: 10, label: '10 ' + t('minutes') },
    { value: 15, label: '15 ' + t('minutes') },
    { value: 30, label: '30 ' + t('minutes') },
  ];

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  }, [nextIsDisabled, activeStep]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !nextIsDisabled) {
      event.preventDefault();
      setActiveStep(activeStep + 1);
      updateStates();
    }
  };

  const [infoOpen, setInfoOpen] = useState(false);

  const startTrainingIsDisabled =
    newModelName.length <= 3 ||
    checkForWrongCharacter(newModelName) ||
    checkIfModelNameExists(newModelName) ||
    newModelModule == null ||
    newModelModule == '' ||
    newModelTraining.csvFileOrLiveData == null ||
    (newModelTraining.csvFileOrLiveData == 'live' && liveTrainingData == null) ||
    (newModelModule !== Modules.EARLY_WARNING_SYSTEM &&
      newModelAllFieldNames.length == 0 &&
      newModelTraining.csvFileOrLiveData == 'csv') || //Why is this needed?
    (((newModelModule !== Modules.EARLY_WARNING_SYSTEM && newModeldefectClassNames.length == 0) ||
      !newModelTraining.selectedFeatureStreams ||
      newModelTraining.selectedFeatureStreams.length == 0) &&
      newModelTraining.csvFileOrLiveData == 'live') ||
    ((newModelInputFieldNames.length == 0 ||
      (newModelModule !== Modules.EARLY_WARNING_SYSTEM && newModeldefectClassFieldNames == 0)) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    ((newModelInputFieldMapping.some((item) => item.mappedStreamField == null) ||
      newModelInputFieldMapping.length < newModelInputFieldNames.length) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (newModelInputFieldNames.length == 0 && newModelTraining.csvFileOrLiveData == 'live') ||
    (((newModelTraining.builtInAlgorithmsUsed &&
      (newModelTraining.builtInAlgorithm == null || newModelTraining.builtInAlgorithm == '')) ||
      (!newModelTraining.builtInAlgorithmsUsed &&
        (newModelTraining.customAlgorithmName == null ||
          newModelTraining.customAlgorithmName == ''))) &&
      newModelTraining.csvFileOrLiveData == 'live') ||
    (((newModelTraining.builtInAlgorithmsUsed &&
      (newModelTraining.builtInAlgorithm == null || newModelTraining.builtInAlgorithm == '')) ||
      (!newModelTraining.builtInAlgorithmsUsed &&
        (newModelTraining.customAlgorithmName == null ||
          newModelTraining.customAlgorithmName == ''))) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (newModelRetraining.automaticRetrainingEnabled &&
      ((newModelRetraining.retrainingBasedOnModelDecay &&
        (newModelRetraining.retrainingModelDecayRateInPercent == null ||
          newModelRetraining.retrainingModelDecayRateInPercent == '')) ||
        (!newModelRetraining.retrainingBasedOnModelDecay &&
          (newModelRetraining.retrainingFrequencyInDays == null ||
            newModelRetraining.retrainingFrequencyInDays == '')))) ||
    (newModelOther.cachingFrequencyInMinutes === '' &&
      newModelTraining.csvFileOrLiveData === 'live') ||
    (newModelOther.cachingFrequencyInMinutes === '' &&
      newModelTraining.csvFileOrLiveData === 'csv');

  return (
    <div className="aiAllSteps">
      <div className="container-back">
        <IconButton
          variant="qualitatio"
          color="primary"
          squared={true}
          size="small"
          disabled={prevIsDisabled}
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
          style={{
            width: '48px',
            height: '48px',
          }}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      </div>
      <div className="container-card">
        <div className="steps-overview">
          {activeStep - 1}{' '}
          {newModelTraining?.csvFileOrLiveData === 'csv' ? t('/of9Steps') : t('/of8Steps')}
        </div>
        <div className="mobile-stepper-container">
          <QualitatioMobileStepper
            totalSteps={newModelTraining?.csvFileOrLiveData === 'csv' ? 10 : 9}
            activeStep={activeStep - 1}
            controllable={false}
          />
        </div>
        {activeStep === 2 && (
          <div className="step-1">
            <div className="name-the-model">{t('nameTheModel')}</div>
            <div className="model-name-input">
              <QualitatioInput
                label={t('nameOfModel')}
                value={newModelName}
                onChange={(e) => setNewModelNameWithCheck(e.target.value)}
                width="100%"
                placeholder={t('enterAModelName')}
              />
              {nameError && <div className="error-message">{nameError}</div>}
            </div>
            <div className="model-warranty-checkbox">
              {t('isWarranty')}
              <Checkbox
                variant="qualitatio"
                checked={newModelOther.isWarranty}
                onChange={(e) => updateModelIsWarranty(e.target.checked)}
              />
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="step-2">
            <div className="select-module">{t('selectAModule')}</div>

            <div className="select-module-container">
              <QualitatioSelect
                value={newModelModule}
                onChange={(e) => setNewModelModule(e.target.value)}
                defaultText={t('module')}
                optionProps={possibleModules.map((module) => (
                  <MenuItem key={module.value} value={module.value}>
                    {module.label}
                  </MenuItem>
                ))}
              />
            </div>
          </div>
        )}

        {activeStep === 4 && (
          <div className="step-3">
            <div className="select-data-source">{t('selectADataSource')}</div>
            <div className="csv-or-live">
              <QualitatioHighlightBox
                text={t('csvFile')}
                color="#1fbebc"
                icon={<FaFileCsv />}
                onClick={() => csvDecisionMade('csv')}
                border={
                  newModelTraining.csvFileOrLiveData === 'csv'
                    ? '1px solid rgba(31, 190, 188, 1)'
                    : '1px solid rgba(31, 190, 188, 0)'
                }
                opacity={newModelTraining.csvFileOrLiveData === 'csv' ? '1' : '0.5'}
                backgroundColor={
                  newModelTraining.csvFileOrLiveData === 'csv'
                    ? 'rgba(31, 190, 188, 0.3)'
                    : 'rgba(31, 190, 188, 0.1)'
                }
              />
              <QualitatioHighlightBox
                text={t('liveData')}
                color="#1fbebc"
                icon={<FaRegClock />}
                onClick={() => csvDecisionMade('live')}
                border={
                  newModelTraining.csvFileOrLiveData === 'live'
                    ? '1px solid rgba(31, 190, 188, 1)'
                    : '1px solid rgba(31, 190, 188, 0)'
                }
                opacity={newModelTraining.csvFileOrLiveData === 'live' ? '1' : '0.5'}
                backgroundColor={
                  newModelTraining.csvFileOrLiveData === 'live'
                    ? 'rgba(31, 190, 188, 0.3)'
                    : 'rgba(31, 190, 188, 0.1)'
                }
              />
            </div>
            {newModelTraining.csvFileOrLiveData === 'live' && (
              <div className="date-picker">
                <div className="select-date">{t('selectDate')}</div>
                <QualitatioDateTimePicker
                  value={startTime}
                  onChange={(e) => updateStartTime(e.$d)}
                  label={t('from')}
                  containerStyle={{ display: 'flex', justifyContent: 'center' }}
                />
                <QualitatioDateTimePicker
                  value={endTime}
                  onChange={(e) => updateEndTime(e.$d)}
                  label={t('to')}
                  containerStyle={{ display: 'flex', justifyContent: 'center' }}
                />
              </div>
            )}
          </div>
        )}

        {activeStep === 5 && newModelTraining.csvFileOrLiveData === 'csv' && (
          <div className="step-4">
            <div className="select-data-set">{t('selectADataSet')}</div>
            {newModelModule === Modules.EARLY_WARNING_SYSTEM && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  padding: '0 15vw',
                }}
                marginTop={4}
                marginBottom={4}
              >
                <Typography>{t('ews.csvFormat')}</Typography>
              </Box>
            )}
            <div className="data-set-input">
              <QualitatioInput
                id="trainingFile"
                label={t('csvFile')}
                onChange={csvFileUploaded}
                type="file"
                inputProps={{ accept: '.csv' }}
              />
            </div>
          </div>
        )}

        {activeStep === 5 && newModelTraining.csvFileOrLiveData === 'live' && (
          <div className="step-4">
            <div className="select-data-set">{t('selectADataSet')}</div>
            <div className="step-4-live">
              {newModelModule !== Modules.EARLY_WARNING_SYSTEM && (
                <div className="select-prediction-fields">
                  <div className="select-defect-classes-as-predictions">
                    {t('selectDefectClassesAsPredictions')}
                  </div>
                  <div className="prediction-streams">
                    <div className="number-of-predictions">
                      {t('youCan') +
                        (liveTrainingData.numberCarriedOutTests || 0) +
                        t('predictions')}
                    </div>
                    <div className="prediction-streams-list">
                      {liveTrainingData.availableDefectClasses?.map((el, index) => (
                        <div className="prediction-streams-list-item">
                          {el.nameDefectClass +
                            ' (' +
                            el.numberSuggestions +
                            ' ' +
                            t('labelsWith') +
                            ' ' +
                            el.numberOccurances +
                            ' ' +
                            t('positive') +
                            ')'}
                          <Checkbox
                            variant="qualitatio"
                            checked={newModeldefectClassNames.includes(el.nameDefectClass)}
                            onChange={(e) => handleDefectClassChange(el.nameDefectClass, e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="select-data-source-as-features">
                <div className="select-data-sources-as-features-text">
                  {t('selectDataSourcesAsFeatures')}
                </div>
                {liveStreamData?.map((el, index) => (
                  <div className="data-source-item">
                    <div className="data-source-item-name">{el.streamName}</div>
                    <div className="data-source-item-checkbox">
                      <Checkbox
                        variant="qualitatio"
                        id={el.streamName}
                        checked={
                          newModelTraining.selectedFeatureStreams &&
                          newModelTraining.selectedFeatureStreams.includes(el.streamName)
                        }
                        onChange={(e) => updateSelectedFeatureStream(el, e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {activeStep === 6 && newModelTraining.csvFileOrLiveData === 'csv' && (
          <div className="step-5">
            <div className="select-fields">
              {t('selectFields')}
              <IconButton
                variant="qualitatio"
                color="primary"
                size="small"
                squared={true}
                onClick={() => setInfoOpen(true)}
                style={{
                  width: '36px',
                  height: '36px',
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <QualitatioDialog
                open={infoOpen}
                onClose={() => setInfoOpen(false)}
                title={t('selectFields')}
                description={
                  newModelModule === Modules.EARLY_WARNING_SYSTEM
                    ? t('ews.selectFeatureAndPredictionFieldsInfoText')
                    : t('selectFeatureAndPredictionFieldsInfoText')
                }
                maxWidth="sm"
                actions={[
                  {
                    label: t('alright'),
                    onClick: () => setInfoOpen(false),
                    order: 'primary',
                  },
                ]}
              />
            </div>

            <div className="list-of-all-fields-and-names">
              <QualitatioDragAndDrop
                fields={newModelAllFieldNames}
                setFields={setNewModelAllFieldNames}
                columns={[
                  {
                    name: t('feature'),
                    fields: newModelInputFieldNames,
                    setFields: setNewModelInputFieldNames,
                    color: theme.palette.success.secondary,
                  },
                  ...(newModelModule !== Modules.EARLY_WARNING_SYSTEM
                    ? [
                        {
                          name: t('target'),
                          fields: newModeldefectClassFieldNames,
                          setFields: setNewModeldefectClassFieldNames,
                          color: theme.palette.error.secondary,
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </div>
        )}

        {activeStep === 6 && newModelTraining.csvFileOrLiveData === 'live' && (
          <div className="step-5-live">
            <div className="select-fields">{t('selectFeatureFields')}</div>
            <QualitatioChipArray
              chipData={[
                {
                  label: t('feature'),
                  icon: <MdOutlineCheckBox />,
                  backgroundColor: theme.palette.success.secondary,
                },
                {
                  label: t('notSelected'),
                  icon: <MdOutlineCheckBoxOutlineBlank />,
                  color: 'default',
                },
              ]}
              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
            />
            <div className="divider-container">
              <Divider variant="middle" />
            </div>
            <div className="list-of-all-fields-and-names">
              {liveStreamData?.map((stream) => (
                <>
                  {newModelTraining.selectedFeatureStreams &&
                    newModelTraining.selectedFeatureStreams.includes(stream.streamName) && (
                      <div key={stream.streamName}>
                        <div className="stream-name">{stream.streamName}</div>
                        <QualitatioChipArray
                          chipData={stream.fieldNames.map((fieldName, index) => ({
                            label: fieldName,
                            icon:
                              newModelInputFieldNames.includes(fieldName) &&
                              newModelInputFieldMapping.find(
                                (el) => el.inputFieldName === fieldName
                              ).mappedStreamName === stream.streamName ? (
                                <MdOutlineCheckBox />
                              ) : (
                                <MdOutlineCheckBoxOutlineBlank />
                              ),
                            backgroundColor:
                              newModelInputFieldNames.includes(fieldName) &&
                              newModelInputFieldMapping.find(
                                (el) => el.inputFieldName === fieldName
                              ).mappedStreamName === stream.streamName
                                ? theme.palette.success.secondary
                                : 'default',
                            onClick: () =>
                              handleInputSelectionFieldChange(fieldName, stream.streamName),
                          }))}
                          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                        />
                      </div>
                    )}
                </>
              ))}
            </div>
          </div>
        )}

        {activeStep === 7 && newModelTraining.csvFileOrLiveData === 'csv' && (
          <div className="step-6">
            <div className="map-feature-fields">{t('featureFieldDataSourceMapping')}</div>
            <div className="feature-field-mapping">
              {newModelInputFieldNames.map((fieldName, index) => (
                <div key={fieldName} className="grid-item">
                  <Chip
                    label={fieldName}
                    icon={<MdOutlineCheckBox />}
                    sx={{ backgroundColor: theme.palette.success.secondary }}
                  />
                  <QualitatioSelect
                    value={getSelectedValue(fieldName, newModelInputFieldMapping)}
                    onChange={(e) => updateInputFieldMapping(fieldName, e.target.value)}
                    optionProps={
                      !liveStreamData || liveStreamData.length === 0 ? (
                        <MenuItem disabled value="">
                          {t('noStreamsAvailable')}
                        </MenuItem>
                      ) : (
                        liveStreamData.flatMap((stream) => [
                          <ListSubheader key={`header-${stream.streamName}`} className="subheader">
                            {stream.streamName}
                          </ListSubheader>,
                          ...stream.fieldNames.map((field) => (
                            <MenuItem
                              value={`${stream.streamName} > ${field}`}
                              key={`${stream.streamName}-${field}`}
                              className="option"
                            >
                              {`${stream.streamName} > ${field}`}
                            </MenuItem>
                          )),
                        ])
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {((activeStep === 8 && newModelTraining.csvFileOrLiveData === 'csv') ||
          (activeStep === 7 && newModelTraining.csvFileOrLiveData === 'live')) && (
          <div className="step-7">
            <div className="select-an-algorithm">{t('selectAnAlgorithm')}</div>
            <div className="built-in-algorithm">
              <Switch
                variant="qualitatio"
                checked={newModelTraining.builtInAlgorithmsUsed}
                onChange={(e) => updateBuiltInAlgorithmsUsed(e.target.checked)}
              />
              <div className="built-in-algorithm-text">{t('builtInAlgorithm')}</div>
            </div>
            {newModelModule === Modules.EARLY_WARNING_SYSTEM && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop={5}
              >
                <Typography variant="body1">{t('ews.selectPatternRecognitionType')}</Typography>
                <RadioGroup
                  column
                  value={newModelType}
                  onChange={(e) => {
                    setNewModelType(e.target.value);
                  }}
                >
                  {getPossiblePatternRecognitionTypes(t).map((patternRecognitionType) => (
                    <FormControlLabel
                      value={patternRecognitionType.value}
                      control={<Radio />}
                      label={patternRecognitionType.label}
                    />
                  ))}
                </RadioGroup>
              </Box>
            )}
            <div className="algorithm-name">
              {newModelTraining.builtInAlgorithmsUsed ? (
                <QualitatioSelect
                  value={newModelTraining.builtInAlgorithm}
                  onChange={(e) => updateBuiltInAlgorithm(e.target.value)}
                  optionProps={getPossibleAlgorithms(
                    newModelModule,
                    newModelType === 'anomalyDetection',
                    t
                  ).map((algorithm) => (
                    <MenuItem value={algorithm.value} key={algorithm.value} className="option">
                      {algorithm.label}
                    </MenuItem>
                  ))}
                  style={{ width: '100%' }}
                />
              ) : (
                <QualitatioInput
                  label={t('algorithmName')}
                  value={newModelTraining.customAlgorithmName}
                  onChange={(e) => updateCustomAlgorithmName(e.target.value)}
                  width="100%"
                />
              )}
            </div>
          </div>
        )}

        {((activeStep === 9 && newModelTraining.csvFileOrLiveData === 'csv') ||
          (activeStep === 8 && newModelTraining.csvFileOrLiveData === 'live')) && (
          <div className="step-8">
            <div className="define-retraining-and-caching">
              {t('defineRetrainingAndCachingInfoText')}
            </div>
            <div className="define-automatic-retraining">
              <Switch
                variant="qualitatio"
                checked={newModelRetraining.automaticRetrainingEnabled}
                disabled={newModelModule === Modules.EARLY_WARNING_SYSTEM}
                onChange={(e) => updateAutomaticRetrainingEnabled(e.target.checked)}
              />
              <div className="automatic-retraining">{t('automaticRetraining')}</div>
            </div>
            {newModelRetraining.automaticRetrainingEnabled && (
              <div className="retraining-settings">
                <Switch
                  variant="qualitatio"
                  checked={newModelRetraining.retrainingBasedOnModelDecay}
                  onChange={(e) => updateRetrainingBasedOnModelDecay(e.target.checked)}
                />
                <div className="retraining-based-on-model-decay">
                  {t('retrainingBasedOnModelDecay')}
                </div>
                <QualitatioInput
                  label={
                    newModelRetraining.retrainingBasedOnModelDecay
                      ? t('retrainingModelDecayRateInPercent')
                      : t('retrainingFrequencyInDays')
                  }
                  value={
                    newModelRetraining.retrainingBasedOnModelDecay
                      ? newModelRetraining.retrainingModelDecayRateInPercent
                      : newModelRetraining.retrainingFrequencyInDays
                  }
                  onChange={(e) => {
                    newModelRetraining.retrainingBasedOnModelDecay
                      ? updateRetrainingModelDecayRateInPercent(e.target.value)
                      : updateRetrainingFrequencyInDays(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {newModelRetraining.retrainingBasedOnModelDecay ? '%' : t('days')}
                    </InputAdornment>
                  }
                  width="100%"
                />
              </div>
            )}
            <div className="caching-settings">
              <Switch variant="qualitatio" disabled defaultChecked />
              <div className="caching-frequency-in-minutes">{t('cachingFrequencyInMinutes')}</div>
              <QualitatioSelect
                value={newModelOther.cachingFrequencyInMinutes}
                onChange={(e) => updateCachingFrequencyInMinutes(e.target.value)}
                optionProps={cachingFrequencies.map((frequency) => (
                  <MenuItem value={frequency.value} key={frequency.value} className="option">
                    {frequency.label}
                  </MenuItem>
                ))}
              />
            </div>
          </div>
        )}

        {((activeStep === 10 && newModelTraining.csvFileOrLiveData === 'csv') ||
          (activeStep === 9 && newModelTraining.csvFileOrLiveData === 'live')) && (
          <div className="step-9">
            <div className="check-model-settings">{t('checkModelSettings')}</div>
            <div className="model-settings">
              <div className="model-name-input">
                <QualitatioInput
                  label={t('nameOfModel')}
                  value={newModelName}
                  onChange={(e) => setNewModelNameWithCheck(e.target.value)}
                  width="100%"
                  placeholder={t('enterAModelName')}
                />
                {nameError && <div className="error-message">{nameError}</div>}
              </div>
              <div className="model-warranty-checkbox">
                {t('isWarranty')}
                <Checkbox
                  variant="qualitatio"
                  checked={newModelOther.isWarranty}
                  onChange={(e) => updateModelIsWarranty(e.target.checked)}
                />
              </div>
              <QualitatioChipArray
                chipData={[
                  {
                    label:
                      newModelTraining.csvFileOrLiveData === 'csv' ? t('csvFile') : t('liveData'),
                    icon:
                      newModelTraining.csvFileOrLiveData === 'csv' ? <FaFileCsv /> : <FaRegClock />,
                    backgroundColor: theme.palette.success.secondary,
                  },
                  {
                    label: t('downloadTrainingSet'),
                    icon: <MdDownload />,
                    color: 'default',
                    onClick: () => {
                      downloadTrainingDataSet();
                    },
                  },
                ]}
                justifyContent="left"
              />
              {newModelTraining.csvFileOrLiveData === 'live' && (
                <div className="summary-date-time-picker">
                  <QualitatioDateTimePicker
                    value={startTime}
                    onChange={(e) => updateStartTime(e.currentTarget.value)}
                    label={t('from')}
                  />
                  <QualitatioDateTimePicker
                    value={endTime}
                    onChange={(e) => updateEndTime(e.currentTarget.value)}
                    label={t('to')}
                  />
                </div>
              )}
              <QualitatioAccordion
                accordionData={[
                  {
                    title: t('featureAndPredictionFields'),
                    panel: 'panel1',
                    render: (
                      <div className="step-7-step-8-summary">
                        {newModelTraining.csvFileOrLiveData === 'csv' ? (
                          <div className="step-7-summary">
                            <div className="select-fields">
                              {t('selectFields')}
                              <IconButton
                                variant="qualitatio"
                                color="primary"
                                size="small"
                                squared={true}
                                onClick={() => setInfoOpen(true)}
                                style={{
                                  width: '36px',
                                  height: '36px',
                                }}
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                              <QualitatioDialog
                                open={infoOpen}
                                onClose={() => setInfoOpen(false)}
                                title={t('selectFields')}
                                description={t('selectFeatureAndPredictionFieldsInfoText')}
                                maxWidth="sm"
                                actions={[
                                  {
                                    label: t('alright'),
                                    onClick: () => setInfoOpen(false),
                                    order: 'primary',
                                  },
                                ]}
                              />
                            </div>
                            <div className="list-of-all-fields-and-names">
                              <QualitatioDragAndDrop
                                fields={newModelAllFieldNames}
                                setFields={setNewModelAllFieldNames}
                                columns={[
                                  {
                                    name: t('feature'),
                                    fields: newModelInputFieldNames,
                                    setFields: setNewModelInputFieldNames,
                                    color: theme.palette.success.secondary,
                                  },
                                  {
                                    name: t('target'),
                                    fields: newModeldefectClassFieldNames,
                                    setFields: setNewModeldefectClassFieldNames,
                                    color: theme.palette.error.secondary,
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="step-4-live-summary">
                              <div className="feature-input-streams">
                                <div className="select-prediction-fields">
                                  <div className="select-defect-classes-as-predictions-text">
                                    {t('selectDefectClassesAsPredictions')}
                                  </div>
                                  <div className="prediction-streams">
                                    <div className="number-of-predictions">
                                      {t('youCan') +
                                        (liveTrainingData.numberCarriedOutTests || 0) +
                                        t('predictions')}
                                    </div>
                                    <div className="prediction-streams-list">
                                      {liveTrainingData.availableDefectClasses?.map((el, index) => (
                                        <div className="prediction-streams-list-item">
                                          {el.nameDefectClass +
                                            ' (' +
                                            el.numberSuggestions +
                                            ' ' +
                                            t('labelsWith') +
                                            ' ' +
                                            el.numberOccurances +
                                            ' ' +
                                            t('positive') +
                                            ')'}
                                          <Checkbox
                                            variant="qualitatio"
                                            checked={newModeldefectClassNames.includes(
                                              el.nameDefectClass
                                            )}
                                            onChange={(e) =>
                                              handleDefectClassChange(el.nameDefectClass, e)
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="select-data-source-as-features">
                                  <div className="select-data-sources-as-features-text">
                                    {t('selectDataSourcesAsFeatures')}
                                  </div>
                                  {liveStreamData?.map((el, index) => (
                                    <div className="data-source-item">
                                      <div className="data-source-item-name">{el.streamName}</div>
                                      <div className="data-source-item-checkbox">
                                        <Checkbox
                                          variant="qualitatio"
                                          id={el.streamName}
                                          checked={
                                            newModelTraining.selectedFeatureStreams &&
                                            newModelTraining.selectedFeatureStreams.includes(
                                              el.streamName
                                            )
                                          }
                                          onChange={(e) => updateSelectedFeatureStream(el, e)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="step-7-live-summary">
                              <div className="select-fields">{t('selectFeatureFields')}</div>
                              <div className="list-legend">
                                <QualitatioChipArray
                                  chipData={[
                                    {
                                      label: t('feature'),
                                      icon: <MdOutlineCheckBox />,
                                      backgroundColor: theme.palette.success.secondary,
                                    },
                                    {
                                      label: t('notSelected'),
                                      icon: <MdOutlineCheckBoxOutlineBlank />,
                                      color: 'default',
                                    },
                                  ]}
                                  boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                                />
                              </div>
                              <div className="divider-container">
                                <Divider variant="middle" />
                              </div>
                              <div className="list-of-all-fields-and-names">
                                {liveStreamData?.map((stream) => (
                                  <>
                                    {newModelTraining.selectedFeatureStreams &&
                                      newModelTraining.selectedFeatureStreams.includes(
                                        stream.streamName
                                      ) && (
                                        <>
                                          <div className="stream-name">{stream.streamName}</div>
                                          <QualitatioChipArray
                                            chipData={stream.fieldNames.map((fieldName, index) => ({
                                              label: fieldName,
                                              icon:
                                                newModelInputFieldNames.includes(fieldName) &&
                                                newModelInputFieldMapping.find(
                                                  (el) => el.inputFieldName === fieldName
                                                ).mappedStreamName === stream.streamName ? (
                                                  <MdOutlineCheckBox />
                                                ) : (
                                                  <MdOutlineCheckBoxOutlineBlank />
                                                ),
                                              backgroundColor:
                                                newModelInputFieldNames.includes(fieldName) &&
                                                newModelInputFieldMapping.find(
                                                  (el) => el.inputFieldName === fieldName
                                                ).mappedStreamName === stream.streamName
                                                  ? theme.palette.success.secondary
                                                  : 'default',
                                              onClick: () =>
                                                handleInputSelectionFieldChange(
                                                  fieldName,
                                                  stream.streamName
                                                ),
                                            }))}
                                            boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                                          />
                                        </>
                                      )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                        {newModelTraining.csvFileOrLiveData === 'csv' && (
                          <div className="step-8-summary">
                            <div className="map-feature-fields">
                              {t('featureFieldDataSourceMapping')}
                            </div>
                            <div className="feature-field-mapping">
                              {newModelInputFieldNames.map((fieldName, index) => (
                                <div key={fieldName} className="grid-item">
                                  <Chip
                                    label={fieldName}
                                    icon={<MdOutlineCheckBox />}
                                    sx={{
                                      backgroundColor: theme.palette.success.secondary,
                                    }}
                                  />
                                  <QualitatioSelect
                                    value={getSelectedValue(fieldName, newModelInputFieldMapping)}
                                    onChange={(e) =>
                                      updateInputFieldMapping(fieldName, e.target.value)
                                    }
                                    optionProps={
                                      !liveStreamData || liveStreamData.length === 0 ? (
                                        <MenuItem disabled value="">
                                          {t('noStreamsAvailable')}
                                        </MenuItem>
                                      ) : (
                                        liveStreamData.flatMap((stream) => [
                                          <ListSubheader
                                            key={`header-${stream.streamName}`}
                                            className="subheader"
                                          >
                                            {stream.streamName}
                                          </ListSubheader>,
                                          ...stream.fieldNames.map((field) => (
                                            <MenuItem
                                              value={`${stream.streamName} > ${field}`}
                                              key={`${stream.streamName}-${field}`}
                                              className="option"
                                            >
                                              {`${stream.streamName} > ${field}`}
                                            </MenuItem>
                                          )),
                                        ])
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: t('algorithm'),
                    panel: 'panel2',
                    render: (
                      <div className="step-9-summary">
                        <div className="built-in-algorithm">
                          <Switch
                            variant="qualitatio"
                            checked={newModelTraining.builtInAlgorithmsUsed}
                            onChange={(e) => updateBuiltInAlgorithmsUsed(e.target.checked)}
                          />
                          <div className="built-in-algorithm-text">{t('builtInAlgorithm')}</div>
                        </div>
                        <div className="algorithm-name">
                          {newModelTraining.builtInAlgorithmsUsed ? (
                            <QualitatioSelect
                              value={newModelTraining.builtInAlgorithm}
                              onChange={(e) => updateBuiltInAlgorithm(e.target.value)}
                              optionProps={getPossibleAlgorithms(
                                newModelModule,
                                newModelType === 'anomalyDetection',
                                t
                              ).map((algorithm) => (
                                <MenuItem
                                  value={algorithm.value}
                                  key={algorithm.value}
                                  className="option"
                                >
                                  {algorithm.label}
                                </MenuItem>
                              ))}
                              style={{ width: '100%' }}
                            />
                          ) : (
                            <QualitatioInput
                              label={t('algorithmName')}
                              value={newModelTraining.customAlgorithmName}
                              onChange={(e) => updateCustomAlgorithmName(e.target.value)}
                              width="100%"
                            />
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: t('retrainingAndCaching'),
                    panel: 'panel3',
                    render: (
                      <div className="step-10-summary">
                        <div className="define-automatic-retraining">
                          <Switch
                            variant="qualitatio"
                            checked={newModelRetraining.automaticRetrainingEnabled}
                            disabled={newModelModule === Modules.EARLY_WARNING_SYSTEM}
                            onChange={(e) => updateAutomaticRetrainingEnabled(e.target.checked)}
                          />
                          <div className="automatic-retraining">{t('automaticRetraining')}</div>
                        </div>
                        {newModelRetraining.automaticRetrainingEnabled && (
                          <div className="retraining-settings">
                            <Switch
                              variant="qualitatio"
                              checked={newModelRetraining.retrainingBasedOnModelDecay}
                              onChange={(e) => updateRetrainingBasedOnModelDecay(e.target.checked)}
                            />
                            <div className="retraining-based-on-model-decay">
                              {t('retrainingBasedOnModelDecay')}
                            </div>
                            <QualitatioInput
                              label={
                                newModelRetraining.retrainingBasedOnModelDecay
                                  ? t('retrainingModelDecayRateInPercent')
                                  : t('retrainingFrequencyInDays')
                              }
                              value={
                                newModelRetraining.retrainingBasedOnModelDecay
                                  ? newModelRetraining.retrainingModelDecayRateInPercent
                                  : newModelRetraining.retrainingFrequencyInDays
                              }
                              onChange={(e) => {
                                newModelRetraining.retrainingBasedOnModelDecay
                                  ? updateRetrainingModelDecayRateInPercent(e.target.value)
                                  : updateRetrainingFrequencyInDays(e.target.value);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {newModelRetraining.retrainingBasedOnModelDecay ? '%' : t('days')}
                                </InputAdornment>
                              }
                              width="100%"
                            />
                          </div>
                        )}
                        <div className="caching-settings">
                          <Switch variant="qualitatio" disabled defaultChecked />
                          <div className="caching-frequency-in-minutes">
                            {t('cachingFrequencyInMinutes')}
                          </div>
                          <QualitatioSelect
                            value={newModelOther.cachingFrequencyInMinutes}
                            onChange={(e) => updateCachingFrequencyInMinutes(e.target.value)}
                            optionProps={cachingFrequencies.map((frequency) => (
                              <MenuItem
                                value={frequency.value}
                                key={frequency.value}
                                className="option"
                              >
                                {frequency.label}
                              </MenuItem>
                            ))}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
              <QualitatioButton
                text={t('startTrainingProcess')}
                width="30%"
                onClick={() => {
                  setActiveStep(0);
                  resetForm();
                  startTraining();
                }}
                startIcon={<AiOutlinePlayCircle />}
                startIconIsImage={false}
                startIconRounded={false}
                disabled={startTrainingIsDisabled}
              />
            </div>
          </div>
        )}
      </div>
      <div className="container-next">
        <IconButton
          color="primary"
          variant="qualitatio"
          size="small"
          squared
          disabled={nextIsDisabled}
          onClick={() => {
            setActiveStep(activeStep + 1);
            updateStates();
          }}
          style={{
            width: '48px',
            height: '48px',
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
}
