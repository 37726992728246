/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { ModulCardPr } from "../ModulCardPr";
import "./style.css";

export const ModulCard = ({
  module,
  moduleText,
  icon,
  href,
}) => {
  return (
    <div className={`modul-card`}>
        <ModulCardPr
          buttonLabelTextClassName="instance-node"
          className={`class-2`}
          divClassName="modul-card-pr-instance"
          rectangleClassName="modul-card-pr-fplanung"
          icon={icon}
          text={module}
          text1={moduleText}
          href={href}
        />
    </div>
  );
};
