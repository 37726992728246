import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { getManageUsersEndpoint, sendVerificationEmailEndpoint } from '../api/endpoints';
import QualitatioInput from '../components/QualitatioInput/QualitatioInput';
import { useAuthStore } from '../store/auth.store';
import { getSetupValidationSchema } from '../utils/validationSchemas';
import './SetupProcessPage.css';

export default function SetupProcessPage({ setPageName }) {
  const { t, i18n } = useTranslation();
  setPageName(t('setupProcess') || 'Setup Process');
  const navigate = useNavigate();
  const { user, logout, setUser, updateUser } = useAuthStore((state) => ({
    user: state.user,
    logout: state.logout,
    setUser: state.setUser,
    updateUser: state.updateUser,
  }));
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);

  useEffect(() => {
    setPageNumber(!user.isEmailVerified && user.email !== 'admin@example.com' ? 1 : 0);
  }, [user.isEmailVerified, user.email]);

  useEffect(() => {
    //TODO: update user here
    if (user.isEmailVerified && user.email !== 'admin@example.com') {
      navigate('/home');
    }
  }, []);

  //handle email button click
  const handleEmailButtonClick = async () => {
    //send verification email again
    try {
      setLoading(true);
      await axiosClient.post(sendVerificationEmailEndpoint);
      enqueueSnackbar(t('emailVerificationSnack') || 'Email verification sent successfully', {
        variant: 'success',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error during setup process:', error);
      enqueueSnackbar(t('errorSetupSnack') || 'Error during setup process', {
        variant: 'error',
      });
    }
  };

  //handle login button click
  const handleLoginButtonClick = async () => {
    //logout
    try {
      await logout(navigate);
    } catch (error) {
      console.error('Error during setup process:', error);
      enqueueSnackbar(t('errorSetupSnack') || 'Error during setup process', {
        variant: 'error',
      });
    }
  };

  //update user information from the server
  const reloadUser = async () => {
    try {
      setLoading(true);
      await updateUser();
      //add a bit of time to show the loading bar
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: getSetupValidationSchema(i18n.language),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await axiosClient.patch(getManageUsersEndpoint(), values);
        //update user in local storage
        const { name, email } = values;
        setUser({ ...user, name, email });
        await axiosClient.post(sendVerificationEmailEndpoint);
        enqueueSnackbar(t('emailVerificationSnack') || 'Email verification sent successfully', {
          variant: 'success',
        });
        setPageNumber(1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error during setup process:', error);
        enqueueSnackbar(t('errorSetupSnack') || 'Error during setup process', {
          variant: 'error',
        });
      }
    },
  });

  // Check user email status
  if (pageNumber === 1) {
    return (
      <Dialog
        open={true} // This ensures the dialog is always open
        disableEscapeKeyDown={true} // This ensures pressing ESC doesn't close the dialog
        disableBackdropClick={true} // This ensures clicking outside the dialog doesn't close it
        variant="qualitatio"
      >
        <DialogTitle>
          {loading && <LinearProgress />}
          <Typography variant="h4">{t('verifyEmail') || 'Verify Email'}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography>{t('notYetVerified') || 'Your email is not yet verified.'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleEmailButtonClick}
            color="primary"
            variant="qualitatio"
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('sendVerificationAgain') || 'Send Verification Again'}
          </Button>
          <Button onClick={reloadUser} color="secondary" variant="qualitatio">
            {t('reload') || 'Reload'}
          </Button>

          <Button
            onClick={handleLoginButtonClick}
            color="secondary"
            variant="qualitatio"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('goToLogin') || 'Go to Login'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (user.isEmailVerified && user.email !== 'admin@example.com') {
    navigate('/home');
    return null;
  }

  if (pageNumber === 0) {
    return (
      <Dialog
        open={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        variant="qualitatio"
      >
        <DialogTitle>
          {loading && <LinearProgress />}
          {t('setupProcess')}
        </DialogTitle>

        <DialogContent style={{ minHeight: '250px' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <QualitatioInput
                  fullWidth
                  {...formik.getFieldProps('name')}
                  width={'100%'}
                  placeholder={t('name') || 'Name'}
                  label={t('name') || 'Name'}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  style={{ marginTop: '5px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <QualitatioInput
                  {...formik.getFieldProps('email')}
                  width={'100%'}
                  placeholder={t('email') || 'Email'}
                  label={t('email') || 'Email'}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <QualitatioInput
                  {...formik.getFieldProps('password')}
                  placeholder="*******"
                  width={'100%'}
                  label={t('password') || 'Password'}
                  type="password"
                  showInput={showPassword}
                  toggleShowInput={() => setShowPassword(!showPassword)}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="qualitatio"
            fullWidth
            type="submit"
            onClick={() => {
              formik.setTouched({
                name: true,
                email: true,
                password: true,
              });
              formik.setSubmitting(true);
              formik.handleSubmit();
            }}
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('finishSetup') || 'Finish Setup'}
          </Button>
          <Button
            onClick={handleLoginButtonClick}
            color="secondary"
            variant="qualitatio"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('goToLogin') || 'Go to Login'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
