import InfoIcon from '@mui/icons-material/Info';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

function RangeSettings({ streamFields }) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const timeUnits = [
    { value: 'h', label: 'Hours' },
    { value: 'd', label: 'Days' },
    { value: 'w', label: 'Weeks' },
    { value: 'm', label: 'Months' },
    { value: 'y', label: 'Years' },
  ];
  const indices = [...new Set(streamFields.map((field) => field.indexName))];

  const aggregationRangeDisabled =
    values.ruleType === 'simple' &&
    !values.ruleDetails?.mainCondition?.some((condition) =>
      condition.valueCombinations.some(
        (valueCombination) =>
          valueCombination.aggregationFunction !== null &&
          valueCombination.aggregationFunction !== ''
      )
    );

  useEffect(() => {
    if (aggregationRangeDisabled) {
      setFieldValue('ruleDetails.aggregationRange', {
        type: '',
        range: null,
        timeUnit: '',
        groundTruthStreamIndex: '',
      });
    } else {
      if (!values.ruleDetails.aggregationRange.type) {
        setFieldValue('ruleDetails.aggregationRange', {
          type: 'time',
          range: 1,
          timeUnit: 'w',
          groundTruthStreamIndex: '',
        });
      }
    }
  }, [aggregationRangeDisabled]);

  const [localActionFrequency, setLocalActionFrequency] = useState(
    values.ruleDetails.actionFrequency.frequency
  );
  const [localTimeValue, setLocalTimeValue] = useState(
    values.ruleDetails.actionFrequency.timeReference.timeValue
  );
  const [localAggregationRange, setLocalAggregationRange] = useState(
    values.ruleDetails.aggregationRange.range
  );

  // Initialize local state when Formik's values change externally
  useEffect(() => {
    setLocalActionFrequency(values.ruleDetails.actionFrequency.frequency);
  }, [values.ruleDetails.actionFrequency.frequency]);

  useEffect(() => {
    setLocalTimeValue(values.ruleDetails.actionFrequency.timeReference.timeValue);
  }, [values.ruleDetails.actionFrequency.timeReference.timeValue]);

  useEffect(() => {
    setLocalAggregationRange(values.ruleDetails.aggregationRange.range);
  }, [values.ruleDetails.aggregationRange.range]);

  // Debounced setters
  const debouncedSetActionFrequency = useMemo(
    () =>
      debounce((value) => {
        setFieldValue('ruleDetails.actionFrequency.frequency', value);
      }, 1000), // 1 second debounce
    [setFieldValue]
  );

  const debouncedSetTimeValue = useMemo(
    () =>
      debounce((value) => {
        setFieldValue('ruleDetails.actionFrequency.timeReference.timeValue', value);
      }, 1000),
    [setFieldValue]
  );

  const debouncedSetAggregationRange = useMemo(
    () =>
      debounce((range) => {
        setFieldValue('ruleDetails.aggregationRange.range', range);
      }, 1000),
    [setFieldValue]
  );

  // Clean up debounced functions on unmount
  useEffect(() => {
    return () => {
      debouncedSetActionFrequency.cancel();
      debouncedSetTimeValue.cancel();
      debouncedSetAggregationRange.cancel();
    };
  }, [debouncedSetActionFrequency, debouncedSetTimeValue, debouncedSetAggregationRange]);

  useEffect(() => {
    if (aggregationRangeDisabled) {
      setFieldValue('ruleDetails.aggregationRange', {
        type: '',
        range: null,
        timeUnit: '',
        groundTruthStreamIndex: '',
      });
    } else {
      if (!values.ruleDetails.aggregationRange.type) {
        setFieldValue('ruleDetails.aggregationRange', {
          type: 'time',
          range: 1,
          timeUnit: 'w',
          groundTruthStreamIndex: '',
        });
      }
    }
  }, [aggregationRangeDisabled, setFieldValue, values.ruleDetails.aggregationRange.type]);

  return (
    <Grid
      item
      display={'flex'}
      direction={'column'}
      justifyContent={'center'}
      width={'100%'}
      gap={3}
    >
      <Typography variant={'body2'}>
        {t('rangeSettingsDescription.determine')}
        <br />
        {t('rangeSettingsDescription.actionFrequency')}
        <br />
        {t('rangeSettingsDescription.aggregationRange')}
      </Typography>
      <Grid item display={'flex'} direction={'column'} justifyContent={'center'} gap={1}>
        {/* Action Frequency */}
        <Grid item display={'flex'} direction={'row'} justifyContent={'center'}>
          <FormControl
            component="fieldset"
            error={Boolean(
              touched.ruleDetails?.actionFrequency?.type &&
                errors.ruleDetails?.actionFrequency?.type
            )}
          >
            <FormLabel id="actionFrequency">{`a) ${t('ActionFrequency')}*`}</FormLabel>
            <RadioGroup
              name="ruleDetails.actionFrequency.type"
              value={values.ruleDetails.actionFrequency.type}
              onChange={(event) =>
                setFieldValue('ruleDetails.actionFrequency.type', event.target.value)
              }
              row
              aria-labelledby={'actionFrequency'}
            >
              <FormControlLabel value="product" control={<Radio />} label="Product" />
              <FormControlLabel value="time" control={<Radio />} label="Time" />
            </RadioGroup>
            {touched.ruleDetails?.actionFrequency?.type &&
              errors.ruleDetails?.actionFrequency?.type && (
                <FormHelperText error>
                  {touched.ruleDetails?.actionFrequency?.type &&
                    errors.ruleDetails?.actionFrequency?.type}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>

        {values.ruleDetails.actionFrequency.type === 'product' && (
          <TextField
            name="ruleDetails.actionFrequency.frequency"
            label={`${t('everyXthProduct')}*`}
            type="number"
            value={localActionFrequency}
            onChange={(event) => {
              const value = event.target.value;
              setLocalActionFrequency(value);
              debouncedSetActionFrequency(value);
            }}
            variant="standard"
            size="small"
            fullWidth
            inputProps={{ min: 1 }}
            error={
              touched.ruleDetails?.actionFrequency?.frequency &&
              Boolean(errors.ruleDetails?.actionFrequency?.frequency)
            }
            helperText={
              touched.ruleDetails?.actionFrequency?.frequency &&
              errors.ruleDetails?.actionFrequency?.frequency
            }
          />
        )}

        {values.ruleDetails.actionFrequency.type === 'time' && (
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextField
              name="ruleDetails.actionFrequency.frequency"
              label={`${t('XtimesPer')}*`}
              type="number"
              value={localActionFrequency}
              onChange={(event) => {
                const value = event.target.value;
                setLocalActionFrequency(value);
                debouncedSetActionFrequency(value);
              }}
              variant="standard"
              size="small"
              style={{ flexGrow: 1, marginRight: '1rem' }}
              inputProps={{ min: 1 }}
              error={
                touched.ruleDetails?.actionFrequency?.frequency &&
                Boolean(errors.ruleDetails?.actionFrequency?.frequency)
              }
              helperText={
                touched.ruleDetails?.actionFrequency?.frequency &&
                errors.ruleDetails?.actionFrequency?.frequency
              }
            />
            <TextField
              name="ruleDetails.actionFrequency.timeReference.timeValue"
              label={`${t('TimeValue')}*`}
              type="number"
              value={localTimeValue}
              onChange={(event) => {
                const value = event.target.value;
                setLocalTimeValue(value);
                debouncedSetTimeValue(value);
              }}
              variant="standard"
              size="small"
              style={{ minWidth: 120, marginRight: '1rem' }}
              inputProps={{ min: 1 }}
              error={
                touched.ruleDetails?.actionFrequency?.timeReference?.timeValue &&
                Boolean(errors.ruleDetails?.actionFrequency?.timeReference?.timeValue)
              }
              helperText={
                touched.ruleDetails?.actionFrequency?.timeReference?.timeValue &&
                errors.ruleDetails?.actionFrequency?.timeValue
              }
            />
            <FormControl
              variant="standard"
              size="small"
              style={{ minWidth: 120 }}
              error={
                touched.ruleDetails?.actionFrequency?.timeReference?.timeUnit &&
                Boolean(errors.ruleDetails?.actionFrequency?.timeReference?.timeUnit)
              }
            >
              <InputLabel>{`${t('TimeUnit')}*`}</InputLabel>
              <Select
                name="ruleDetails.actionFrequency.timeReference.timeUnit"
                value={values.ruleDetails.actionFrequency.timeReference.timeUnit}
                onChange={(event) =>
                  setFieldValue(
                    'ruleDetails.actionFrequency.timeReference.timeUnit',
                    event.target.value
                  )
                }
                label={`${t('TimeUnit')}*`}
              >
                {timeUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {t(unit.label)}
                  </MenuItem>
                ))}
              </Select>
              {touched.ruleDetails?.actionFrequency?.timeReference?.timeUnit &&
                errors.ruleDetails?.actionFrequency?.timeReference?.timeUnit && (
                  <FormHelperText error>
                    {touched.ruleDetails?.actionFrequency?.timeReference?.timeUnit &&
                      errors.ruleDetails?.actionFrequency?.timeReference?.timeUnit}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        )}
      </Grid>

      {/* Aggregation Range */}
      <Grid item display={'flex'} direction={'column'} justifyContent={'center'} gap={1}>
        <Grid item display={'flex'} direction={'row'} justifyContent={'center'} alignItems="center">
          <FormControl
            component="fieldset"
            error={Boolean(
              touched.ruleDetails?.aggregationRange?.type &&
                errors.ruleDetails?.aggregationRange?.type
            )}
            disabled={aggregationRangeDisabled}
          >
            <Grid container alignItems="center">
              <Grid item>
                <FormLabel id="aggregationRange">{`b) ${t('AggregationRange')}`}</FormLabel>
              </Grid>
              <Grid item>
                <Tooltip title={t('AggregationRangeInfo')} placement="right">
                  <IconButton size="small" aria-label="info">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <RadioGroup
              name="ruleDetails.aggregationRange.type"
              value={values.ruleDetails.aggregationRange.type}
              onChange={(event) =>
                setFieldValue('ruleDetails.aggregationRange.type', event.target.value)
              }
              row
              aria-labelledby={'aggregationRange'}
            >
              <FormControlLabel
                value="product"
                control={<Radio />}
                label="Product"
                disabled={aggregationRangeDisabled}
              />
              <FormControlLabel
                value="time"
                control={<Radio />}
                label="Time"
                disabled={aggregationRangeDisabled}
              />
            </RadioGroup>
            {touched.ruleDetails?.aggregationRange?.type &&
              errors.ruleDetails?.aggregationRange?.type && (
                <FormHelperText error>
                  {touched.ruleDetails?.aggregationRange?.type &&
                    errors.ruleDetails?.aggregationRange?.type}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>

        {values.ruleDetails.aggregationRange.type === 'time' && !aggregationRangeDisabled && (
          <Grid
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <TextField
              name="ruleDetails.aggregationRange.range"
              label={t('lastX')}
              type="number"
              value={localAggregationRange}
              onChange={(event) => {
                const value = event.target.value;
                setLocalAggregationRange(value);
                debouncedSetAggregationRange(value);
              }}
              variant="standard"
              size="small"
              style={{ flexGrow: 1, marginRight: '1rem' }}
              inputProps={{ min: 1 }}
              error={
                touched.ruleDetails?.aggregationRange?.range &&
                Boolean(errors.ruleDetails?.aggregationRange?.range)
              }
              helperText={
                touched.ruleDetails?.aggregationRange?.range &&
                errors.ruleDetails?.aggregationRange?.range
              }
            />
            <FormControl
              variant="standard"
              size="small"
              style={{ minWidth: 120 }}
              error={
                touched.ruleDetails?.aggregationRange?.timeUnit &&
                Boolean(errors.ruleDetails?.aggregationRange?.timeUnit)
              }
            >
              <InputLabel>{t('TimeUnit')}</InputLabel>
              <Select
                name="ruleDetails.aggregationRange.timeUnit"
                value={values.ruleDetails.aggregationRange.timeUnit}
                onChange={(event) =>
                  setFieldValue('ruleDetails.aggregationRange.timeUnit', event.target.value)
                }
                label={t('TimeUnit')}
              >
                {timeUnits.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {t(unit.label)}
                  </MenuItem>
                ))}
              </Select>
              {touched.ruleDetails?.aggregationRange?.timeUnit &&
                errors.ruleDetails?.aggregationRange?.timeUnit && (
                  <FormHelperText error>
                    {touched.ruleDetails?.aggregationRange?.timeUnit &&
                      errors.ruleDetails?.aggregationRange?.timeUnit}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        )}

        {values.ruleDetails.aggregationRange.type === 'product' && !aggregationRangeDisabled && (
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            <TextField
              name="ruleDetails.aggregationRange.range"
              label={t('lastXProducts')}
              type="number"
              value={localAggregationRange}
              onChange={(event) => {
                const value = event.target.value;
                setLocalAggregationRange(value);
                debouncedSetAggregationRange(value);
              }}
              variant="standard"
              size="small"
              style={{ width: '100%', marginBottom: '1rem' }}
              inputProps={{ min: 1 }}
              error={
                touched.ruleDetails?.aggregationRange?.range &&
                Boolean(errors.ruleDetails?.aggregationRange?.range)
              }
              helperText={
                touched.ruleDetails?.aggregationRange?.range &&
                errors.ruleDetails?.aggregationRange?.range
              }
            />
            <FormControl
              variant="standard"
              size="small"
              style={{ width: '100%' }}
              error={
                touched.ruleDetails?.aggregationRange?.groundTruthStreamIndex &&
                Boolean(errors.ruleDetails?.aggregationRange?.groundTruthStreamIndex)
              }
            >
              <InputLabel>{t('groundTruthStreamIndex')}</InputLabel>
              <Select
                name="ruleDetails.aggregationRange.groundTruthStreamIndex"
                value={values.ruleDetails.aggregationRange.groundTruthStreamIndex}
                onChange={(event) =>
                  setFieldValue(
                    'ruleDetails.aggregationRange.groundTruthStreamIndex',
                    event.target.value
                  )
                }
                label={t('groundTruthStreamIndex')}
              >
                <MenuItem
                  value=""
                  sx={{
                    height: '36px',
                  }}
                ></MenuItem>
                {indices.map((index) => (
                  <MenuItem key={index} value={index}>
                    {index}
                  </MenuItem>
                ))}
              </Select>
              {touched.ruleDetails?.aggregationRange?.groundTruthStreamIndex &&
                errors.ruleDetails?.aggregationRange?.groundTruthStreamIndex && (
                  <FormHelperText error>
                    {touched.ruleDetails?.aggregationRange?.groundTruthStreamIndex &&
                      errors.ruleDetails?.aggregationRange?.groundTruthStreamIndex}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default RangeSettings;

RangeSettings.propTypes = {
  streamFields: PropTypes.array.isRequired,
};
