import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import QualitatioDialog from '../../../../../components/QualitatioDialog/QualitatioDialog';
import PropTypes from 'prop-types';

const DeleteRule = ({ index, deleteRule, handleDeleteRule }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [deleteRuleWarningOpen, setDeleteRuleWarningOpen] = useState(false);

  return (
    <>
      <IconButton
        className="delete-button"
        variant="qualitatio"
        squared={true}
        onClick={() => {
          handleDeleteRule(index);
          setDeleteRuleWarningOpen(true);
        }}
        style={{
          backgroundColor: theme.palette.error.main,
          width: '36px',
          height: '36px',
        }}
      >
        <DeleteForeverRoundedIcon color="white" />
      </IconButton>
      <QualitatioDialog
        title={t('areYouSureYouWantToDeleteThisRule')}
        description={t('thisActionCannotBeReverted')}
        open={deleteRuleWarningOpen}
        onClose={() => setDeleteRuleWarningOpen(false)}
        maxWidth="sm"
        actions={[
          {
            label: t('cancel'),
            onClick: () => {
              setDeleteRuleWarningOpen(false);
            },
            order: 'secondary',
          },
          {
            label: t('continue'),
            onClick: () => {
              deleteRule();
              setDeleteRuleWarningOpen(false);
            },
            order: 'primary',
          },
        ]}
      />
    </>
  );
};

DeleteRule.propTypes = {
  index: PropTypes.number.isRequired,
  deleteRule: PropTypes.func.isRequired,
  handleDeleteRule: PropTypes.func.isRequired,
};

export default DeleteRule;
