import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import {
  inputStreamsEndpoint,
  mlModelEndpoint,
  monitoredSystemsEndpoint,
  usersEndpoint,
} from '../../api/endpoints';
import ROLES from '../../authentication/roleConst';
import InfoTooltip from '../../components/TooltipWrapper';
import { Modules } from '../../config/enums';
import { getMonitoredSystemInitialValues } from '../../utils/initFormikValues';
import { monitoredSystemValidationSchema } from '../../utils/validationSchemas';

const generateHourOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    options.push({ label: i < 10 ? `0${i}:00` : `${i}:00`, value: i });
  }
  return options;
};

export default function MonitoredSystemOverlay({ selectedSystem = null, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [activeInputStreams, setActiveInputStreams] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingModels, setLoadingModels] = useState(true);
  const hourOptions = generateHourOptions();
  const [always, setAlways] = useState(
    selectedSystem?.timespan?.start === 0 && selectedSystem?.timespan?.end === 0
  );
  const [anomalyModelOptions, setAnomalyModelOptions] = useState([]);
  const [forecastModelOptions, setForecastModelOptions] = useState([]);

  const getActiveInputStreams = async () => {
    try {
      const response = await axiosClient.get(inputStreamsEndpoint);
      if (response.data?.inputStreams && !response.data.error) {
        return response.data.inputStreams.filter((inputStream) => inputStream.active);
      }
    } catch (error) {
      enqueueSnackbar(t('ews.inputStreamFetchErrorSnack'), { variant: 'error' });
    }
  };

  const getAdminManagerUsers = async () => {
    try {
      const responseAdmin = await axiosClient.get(usersEndpoint, {
        params: { isEmailVerified: true, role: ROLES.ADMIN },
      });
      const responseManager = await axiosClient.get(usersEndpoint, {
        params: { isEmailVerified: true, role: ROLES.EWS_MANAGER },
      });
      //concat responseAdmin.data.results and responseManager.data.results also supports empty arrays
      const response = {
        data: {
          users: [...(responseAdmin.data.results || []), ...(responseManager.data.results || [])],
        },
      };
      if (response.data) {
        setUserOptions(
          response.data.users.map((user) => ({
            id: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      enqueueSnackbar(t('ews.userFetchErrorSnack'), { variant: 'error' });
    }
  };

  const getEWSModels = async () => {
    try {
      const response = await axiosClient.get(mlModelEndpoint);
      if (response.data && !response.data.error) {
        return response.data.mlModelsData?.filter(
          (model) => model.module === Modules.EARLY_WARNING_SYSTEM && model.active
        );
      }
    } catch (error) {
      enqueueSnackbar(t('ews.modelFetchErrorSnack'), { variant: 'error' });
    }
  };

  const handleAddMonitoredSystem = async (values) => {
    //set all formik fields to touched to show error messages
    formik.setTouched({
      name: true,
      inputStreamID: true,
      fields: true,
      frequency: true,
      days: true,
      timespan: true,
      connectedUsers: true,
      anomalyDetectionModel: true,
      timeseriesForecastModel: true,
      sampleSize: true,
      forecastHorizon: true,
    });

    //trigger validation
    formik.validateForm();

    if (
      formik.errors.name ||
      formik.errors.inputStreamID ||
      formik.errors.fields ||
      formik.errors.frequency ||
      formik.errors.days ||
      formik.errors.timespan ||
      formik.errors.connectedUsers ||
      formik.errors.anomalyDetectionModel ||
      formik.errors.timeseriesForecastModel ||
      formik.errors.sampleSize ||
      formik.errors.forecastHorizon
    ) {
      return;
    }

    try {
      const response = await axiosClient.post(monitoredSystemsEndpoint, values);
      if (response.data && !response.data.error) {
        enqueueSnackbar(t('ews.monitoredSystemCreateSuccessSnack'), { variant: 'success' });
        onClose();
      } else {
        enqueueSnackbar(t('ews.monitoredSystemCreateErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemCreateErrorSnack'), { variant: 'error' });
    }
  };

  const handleEditMonitoredSystem = async (values) => {
    formik.setTouched({
      name: true,
      inputStreamID: true,
      fields: true,
      frequency: true,
      days: true,
      timespan: true,
      connectedUsers: true,
      anomalyDetectionModel: true,
      timeseriesForecastModel: true,
      sampleSize: true,
      forecastHorizon: true,
    });
    formik.validateForm();
    if (
      formik.errors.name ||
      formik.errors.inputStreamID ||
      formik.errors.fields ||
      formik.errors.frequency ||
      formik.errors.days ||
      formik.errors.timespan ||
      formik.errors.connectedUsers ||
      formik.errors.anomalyDetectionModel ||
      formik.errors.timeseriesForecastModel ||
      formik.errors.sampleSize ||
      formik.errors.forecastHorizon
    ) {
      return;
    }

    try {
      const response = await axiosClient.patch(
        `${monitoredSystemsEndpoint}/${selectedSystem.id}`,
        values
      );
      if (response.data && !response.data.error) {
        enqueueSnackbar(t('ews.monitoredSystemEditSuccessSnack'), { variant: 'success' });
        onClose();
      } else {
        enqueueSnackbar(t('ews.monitoredSystemEditErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemEditErrorSnack'), { variant: 'error' });
    }
  };

  useEffect(() => {
    getActiveInputStreams().then((activeInputStreams) => {
      if (activeInputStreams?.length === 0) {
        formik.setTouched({ inputStreamID: true });
        formik.setErrors({ inputStreamID: t('ews.noActiveInputStreams') });
      }
      setActiveInputStreams(activeInputStreams);
      setLoading(false);
    });
    getAdminManagerUsers();
    getEWSModels().then((models) => {
      if (models?.length === 0) {
        formik.setTouched({ anomalyDetectionModel: true });
        formik.setErrors({ anomalyDetectionModel: t('ews.noActiveModels') });
      }
      if (models) {
        setAnomalyModelOptions(models.filter((model) => model.type === 'anomalyDetection'));
        setForecastModelOptions(models.filter((model) => model.type === 'timeseriesForecast'));
      }
      setLoadingModels(false);
    });
  }, []);

  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: getMonitoredSystemInitialValues(selectedSystem),
    validationSchema: monitoredSystemValidationSchema(i18n.language),
    onSubmit: selectedSystem ? handleEditMonitoredSystem : handleAddMonitoredSystem,
  });

  return (
    <Dialog open={true} onClose={onClose} variant="qualitatio">
      <DialogTitle>
        {selectedSystem ? t('ews.editMonitoredSystem') : t('ews.addMonitoredSystem')}
      </DialogTitle>
      <Typography variant="subtitle1" align="center">
        {selectedSystem
          ? t('ews.editMonitoredSystemDescription')
          : t('ews.addMonitoredSystemDescription')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {loading || loadingModels ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : activeInputStreams?.length === 0 ? (
            <Typography variant="subtitle1" align="center" color="red">
              {t('ews.noActiveInputStreams')}
            </Typography>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  {...formik.getFieldProps('name')}
                  label={t('name')}
                  placeholder={t('name')}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreateOutlinedIcon
                          color={
                            formik.touched.name && Boolean(formik.errors.name) ? 'error' : 'primary'
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <InfoTooltip description={t('ews.nameTip')} />
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  variant="qualitatio"
                  options={activeInputStreams || []} // Ensure activeInputStreams is always an array
                  getOptionLabel={(option) => option?.name || ''} // Safely access 'name' and provide a fallback
                  value={
                    activeInputStreams.find(
                      (option) => option.id === formik.values.inputStreamID
                    ) || null
                  } // Ensure a valid object is set or null
                  onChange={(event, newValue) => {
                    //set fields to empty array when changing the selected inputStream
                    formik.setFieldValue('fields', []);
                    formik.setFieldValue('inputStreamID', newValue ? newValue.id : '');
                  }}
                  onBlur={() => formik.setFieldTouched('inputStreamID', true)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('ews.inputStream')}
                      label={t('ews.inputStream')}
                      error={formik.touched.inputStreamID && Boolean(formik.errors.inputStreamID)}
                      helperText={formik.touched.inputStreamID && formik.errors.inputStreamID}
                      fullWidth
                    />
                  )}
                  fullWidth
                />
                <InfoTooltip description={t('ews.inputStreamTip')} />
              </Grid>
              {formik.values.inputStreamID && (
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Autocomplete
                    multiple
                    id="fields-selector"
                    options={
                      // Find the selected inputStream and map its streamFields to options
                      (
                        activeInputStreams.find(
                          (stream) => stream.id === formik.values.inputStreamID
                        )?.streamFields || []
                      ).map((field) => field.name)
                    }
                    getOptionLabel={(option) => option} // Assuming option is just the field name string
                    value={formik.values.fields} // The selected field names
                    onChange={(event, newValue) => {
                      formik.setFieldValue('fields', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t('ews.selectedFields')}
                        placeholder={t('ews.selectFields')}
                        error={formik.touched.fields && Boolean(formik.errors.fields)}
                        helperText={formik.touched.fields && formik.errors.fields}
                        fullWidth
                      />
                    )}
                    fullWidth
                  />
                  <InfoTooltip description={t('ews.fieldsTip')} />
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  {...formik.getFieldProps('frequency')}
                  label={t('ews.frequency')}
                  placeholder={t('ews.frequency')}
                  error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                  helperText={formik.touched.frequency && formik.errors.frequency}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreateOutlinedIcon
                          color={
                            formik.touched.frequency && Boolean(formik.errors.frequency)
                              ? 'error'
                              : 'primary'
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <InfoTooltip description={t('ews.frequencyTip')} />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.days?.length === 7}
                        onChange={(event) => {
                          if (event.target.checked) {
                            formik.setFieldValue('days', [1, 2, 3, 4, 5, 6, 7]); // Select all days
                          } else {
                            formik.setFieldValue('days', []); // Deselect all
                          }
                        }}
                      />
                    }
                    label={t('ews.allDays')}
                  />
                  {[1, 2, 3, 4, 5, 6, 7].map((dayNumber) => (
                    <FormControlLabel
                      key={dayNumber}
                      control={
                        <Checkbox
                          checked={formik.values.days.includes(dayNumber)}
                          onChange={(event) => {
                            if (event.target.checked) {
                              formik.setFieldValue('days', [...formik.values.days, dayNumber]); // Add day
                            } else {
                              formik.setFieldValue(
                                'days',
                                formik.values.days.filter((d) => d !== dayNumber) // Remove day
                              );
                            }
                          }}
                        />
                      }
                      label={t(`ews.day${dayNumber}`)} // Assuming your translation keys are like 'ews.day1' for Monday, etc.
                    />
                  ))}
                </FormGroup>
                {formik.touched.days && formik.errors.days && (
                  <FormHelperText error>{formik.errors.days}</FormHelperText>
                )}
                <InfoTooltip description={t('ews.daysTip')} />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t('ews.startTime')}
                      value={formik.values.timespan.start}
                      onChange={(e) => formik.setFieldValue('timespan.start', e.target.value)}
                      fullWidth
                      disabled={always} // Disable if "Always" is checked
                    >
                      {hourOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t('ews.endTime')}
                      value={formik.values.timespan.end}
                      onChange={(e) => formik.setFieldValue('timespan.end', e.target.value)}
                      fullWidth
                      disabled={always} // Disable if "Always" is checked
                    >
                      {hourOptions.map(
                        (option) =>
                          formik.values.timespan.start < option.value && (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )
                      )}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={always}
                          onChange={(e) => {
                            setAlways(e.target.checked);
                            if (e.target.checked) {
                              // Set start and end to 0 if "Always" is checked
                              formik.setFieldValue('timespan.start', 0);
                              formik.setFieldValue('timespan.end', 0);
                            }
                          }}
                        />
                      }
                      label={t('ews.always')}
                    />
                  </Grid>
                </Grid>
                <InfoTooltip description={t('ews.timespanTip')} />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  multiple
                  id="user-selector"
                  options={
                    userOptions.map((user) => user.id) || [] // Ensure userOptions is always an array
                  }
                  getOptionLabel={(option) =>
                    userOptions.find((user) => user.id === option)?.name || ''
                  } // Safely access 'name' and provide a fallback
                  value={formik.values.connectedUsers}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('connectedUsers', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('ews.connectedUsers')}
                      placeholder={t('ews.selectUsers')}
                      error={formik.touched.connectedUsers && Boolean(formik.errors.connectedUsers)}
                      helperText={formik.touched.connectedUsers && formik.errors.connectedUsers}
                      fullWidth
                    />
                  )}
                  fullWidth
                />
                <InfoTooltip description={t('ews.connectedUsersTip')} />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  variant="qualitatio"
                  options={anomalyModelOptions || []}
                  getOptionLabel={(option) =>
                    anomalyModelOptions.find((model) => model.id === option.id)?.name || ''
                  }
                  value={
                    anomalyModelOptions.find(
                      (option) => option.id === formik.values.anomalyDetectionModel
                    ) || ''
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('anomalyDetectionModel', newValue ? newValue.id : '');
                  }}
                  onBlur={() => formik.setFieldTouched('anomalyDetectionModel', true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('ews.anomalyDetectionModel')}
                      label={t('ews.anomalyDetectionModel')}
                      error={
                        formik.touched.anomalyDetectionModel &&
                        Boolean(formik.errors.anomalyDetectionModel)
                      }
                      helperText={
                        formik.touched.anomalyDetectionModel && formik.errors.anomalyDetectionModel
                      }
                      fullWidth
                    />
                  )}
                  fullWidth
                />
                <InfoTooltip description={t('ews.anomalyDetectionModelTip')} />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                  variant="qualitatio"
                  options={forecastModelOptions || []}
                  getOptionLabel={(option) =>
                    forecastModelOptions.find((model) => model.id === option.id)?.name || ''
                  }
                  value={
                    forecastModelOptions.find(
                      (option) => option.id === formik.values.timeseriesForecastModel
                    ) || ''
                  } // Ensure a valid object is set or null
                  onChange={(event, newValue) => {
                    formik.setFieldValue('timeseriesForecastModel', newValue ? newValue.id : '');
                  }}
                  onBlur={() => formik.setFieldTouched('timeseriesForecastModel', true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('ews.timeseriesForecastModel')}
                      label={t('ews.timeseriesForecastModel')}
                      error={
                        formik.touched.timeseriesForecastModel &&
                        Boolean(formik.errors.timeseriesForecastModel)
                      }
                      helperText={
                        formik.touched.timeseriesForecastModel &&
                        formik.errors.timeseriesForecastModel
                      }
                      fullWidth
                    />
                  )}
                  fullWidth
                />
                <InfoTooltip description={t('ews.timeseriesForecastModelTip')} />
              </Grid>
              {formik.values.timeseriesForecastModel && (
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    {...formik.getFieldProps('forecastHorizon')}
                    label={t('ews.forecastHorizon')}
                    placeholder={t('ews.forecastHorizon')}
                    error={formik.touched.forecastHorizon && Boolean(formik.errors.forecastHorizon)}
                    helperText={formik.touched.forecastHorizon && formik.errors.forecastHorizon}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CreateOutlinedIcon
                            color={
                              formik.touched.forecastHorizon &&
                              Boolean(formik.errors.forecastHorizon)
                                ? 'error'
                                : 'primary'
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <InfoTooltip description={t('ews.forecastHorizonTip')} />
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  {...formik.getFieldProps('sampleSize')}
                  label={t('ews.sampleSize')}
                  placeholder={t('ews.sampleSize')}
                  error={formik.touched.sampleSize && Boolean(formik.errors.sampleSize)}
                  helperText={formik.touched.sampleSize && formik.errors.sampleSize}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CreateOutlinedIcon
                          color={
                            formik.touched.sampleSize && Boolean(formik.errors.sampleSize)
                              ? 'error'
                              : 'primary'
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <InfoTooltip description={t('ews.sampleSizeTip')} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="qualitatio"
            endIcon={<ArrowForwardIosIcon />}
          >
            {selectedSystem ? t('save') : t('create')}
          </Button>
          <Button
            onClick={onClose}
            color="secondary"
            variant="qualitatio"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
