import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';

// Contains 'delete card' and 'discard unsaved changes' dialogs that are general to all test cards
const CardEditDialogs = ({
  isDeleteCardWarningPresent,
  isDiscardChangesWarningPresent,
  deleteCardWarningDialog,
  discardChangesWarningDialog,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isDeleteCardWarningPresent && deleteCardWarningDialog && (
        <QualitatioDialog
          title={t('areYouSureYouWantToDeleteThisCard')}
          description={t('thisActionCannotBeReverted')}
          open={deleteCardWarningDialog.deleteCardWarningOpen}
          onClose={() => {
            deleteCardWarningDialog.setDeleteCardWarningOpen(false);
          }}
          maxWidth="sm"
          actions={[
            {
              label: t('cancel'),
              onClick: () => {
                deleteCardWarningDialog.setDeleteCardWarningOpen(false);
              },
              order: 'secondary',
            },
            {
              label: t('continue'),
              onClick: () => {
                deleteCardWarningDialog.deleteCard();
                deleteCardWarningDialog.setDeleteCardWarningOpen(false);
              },
              order: 'primary',
            },
          ]}
        />
      )}
      {isDiscardChangesWarningPresent && discardChangesWarningDialog && (
        <QualitatioDialog
          title={t('unsavedChanges')}
          description={t(
            'youHaveUnsavedChangesWhichCanBeLostIfYouProceedNowAreYouSureYouWantToProceed'
          )}
          open={discardChangesWarningDialog.discardChangesWarningOpen !== -1}
          onClose={(event, reason) => {
            if (reason && reason === 'backdropClick') {
              return;
            }
            discardChangesWarningDialog.setDiscardChangesWarningOpen(-1);
          }}
          maxWidth="sm"
          actions={[
            {
              label: t('continue'),
              onClick: (event, reason) => {
                if (reason && reason === 'backdropClick') {
                  return;
                }
                discardChangesWarningDialog.discardChanges();
                discardChangesWarningDialog.setDiscardChangesWarningOpen(-1);
              },
              order: 'secondary',
            },
            {
              label: t('cancel'),
              onClick: (event, reason) => {
                if (reason && reason === 'backdropClick') {
                  return;
                }
                discardChangesWarningDialog.setDiscardChangesWarningOpen(-1);
              },
              order: 'primary',
            },
          ]}
        />
      )}
    </>
  );
};

const deleteCardWarningDialogPropTypes = {
  deleteCardWarningOpen: PropTypes.bool.isRequired,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

const discardChangesWarningDialogPropTypes = {
  discardChangesWarningOpen: PropTypes.number.isRequired,
  setDiscardChangesWarningOpen: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};

CardEditDialogs.propTypes = {
  isDeleteCardWarningPresent: PropTypes.bool,
  isDiscardChangesWarningPresent: PropTypes.bool,
  deleteCardWarningDialog: PropTypes.shape(deleteCardWarningDialogPropTypes),
  discardChangesWarningDialog: PropTypes.shape(discardChangesWarningDialogPropTypes),
};

CardEditDialogs.defaultProps = {
  isDeleteCardWarningPresent: true,
  isDiscardChangesWarningPresent: true,
  deleteCardWarningDialog: null,
  discardChangesWarningDialog: null,
};

export default CardEditDialogs;
