const ROLES = {
  ADMIN: 'admin', // Admin has access to all modules
  IT_MANAGER: 'ITManager', // Intelligent Testing Manager - has access to the entire Intelligent Testing module
  IT_USER: 'ITUser', // Intelligent Testing User - has access to Intelligent Testing recommender
  ET_MANAGER: 'ETManager', // Efficient Testing Manager - has access to the entire Efficient Testing module
  ET_USER: 'ETUser', // Efficient Testing User - has access to Efficient Testing recommender
  BUSINESS_ANALYST: 'businessAnalyst', // has access to Business Insights and Extended Insights modules
  EWS_MANAGER: 'EWSManager', // Early Warning System Manager - has access to the entire Early Warning System module
  AI_ENGINEER: 'AIEngineer', // has access to the AI module
};

export default ROLES;
