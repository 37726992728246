import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { monitoredSystemsEndpoint } from '../api/endpoints';
import { QualitatioTable } from '../components';
import PageFrame from '../components/PageFrame';
import { WebSocketContext } from '../provider/WebsocketProvider';
import { formatNumber } from '../utils/utilities';
import SystemInfoChart from './components/MonitoredSystemInfoChart';
import ThresholdOverlay from './components/ThresholdOverlay';
import WebSocketStatusIndicator from './components/WSStatusIndicator';

/**
 * MonitoredSystemInfo component renders detailed insights and interactive threshold settings
 * for a specific monitored system. It displays system's statistical data, allows threshold adjustments,
 * and visualizes data trends over time.
 *
 * Props:
 * - setPageName: Function to set the current page name for display.
 *
 * State:
 * - selectedRow: Holds the currently selected field's data row for operations.
 * - monitoredSystem: Contains the data of the monitored system fetched from the server.
 * - loading: Indicates whether the component is currently fetching data.
 * - columns, rows: Configurations for the data table displaying system insights.
 * - openThresholdDialog: Controls the visibility of the threshold setting dialog.
 * - chartKey: Used to force re-render the chart component upon data updates.
 *
 * Unresolved Issues:
 * - After updating the thresholds through the ThresholdOverlay dialog, the SystemInfoChart
 *   component does not immediately reflect the changes.
 * - When the fields are not of numeric type, the component does not look correct ->
 *  in this case insead of showing the field values, it should show a message that the field is not numeric.
 *  and when a row is clicked it should show a table with the field values and the time of the values instead of the chart.
 *
 * @param {{ setPageName: Function }} props The component props.
 * @returns React functional component.
 */

export default function MonitoredSystemInfo({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('ews.systemInsights'));

  // Get the monitored system ID from the URL
  const { systemId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { socket } = useContext(WebSocketContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRow, setSelectedRow] = useState(null);
  const [monitoredSystem, setMonitoredSystem] = useState();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [openThresholdDialog, setOpenThresholdDialog] = useState(false);
  // State to force the chart to re-render when the threshold dialog is closed
  const [chartKey, setChartKey] = useState(0);
  const [thresholdType, setThresholdType] = useState('warn');

  // State to hold the selected row data
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  const handleOpenThresholdDialog = (row, type) => {
    setSelectedRow(row);
    setThresholdType(type);
    setOpenThresholdDialog(true);
  };

  const getMonitoredSystemById = useCallback(async (systemId) => {
    try {
      const response = await axiosClient.get(monitoredSystemsEndpoint + '/' + systemId);
      setMonitoredSystem(response.data);
    } catch (error) {
      enqueueSnackbar(t('ews.errorLoadingMonitoredSystem'), {
        variant: 'error',
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getMonitoredSystemById(systemId);

    let snackId;

    setTimeout(() => {
      snackId = enqueueSnackbar(t('ews.fieldsInfoSnack'), {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 15000,
      });
    }, 3000);

    return () => {
      if (snackId) {
        closeSnackbar(snackId);
      }
    };
  }, []);

  useEffect(() => {
    let cleanupWebSocket;
    const handleSystemStatusUpdate = (updatedSystem) => {
      if (!updatedSystem) {
        console.error('No updated system received from the server');
      }
      if (updatedSystem.id !== systemId) return;
      setMonitoredSystem(updatedSystem);
    };

    try {
      if (socket) {
        socket.on('ews.systemStatus', handleSystemStatusUpdate);
        cleanupWebSocket = () => {
          socket.off('ews.systemStatus', handleSystemStatusUpdate);
        };
      }
    } catch (error) {
      enqueueSnackbar(t('ews.errorLoadingMonitoredSystem'), {
        variant: 'error',
      });
    }
    return () => {
      if (cleanupWebSocket) cleanupWebSocket();
    };
  }, [socket, systemId]);

  const onCloseThresholdDialog = async () => {
    setOpenThresholdDialog(false);
    await getMonitoredSystemById(systemId);
    setChartKey((prevKey) => prevKey + 1);
  };
  const renderThresholdField = (threshold) => {
    const formatValue = (value, isLower = false) => {
      if (value === null) {
        if (isLower) return '-∞';
        else return '∞';
      }
      if (value === undefined) return '-';
      return formatNumber(value, 5);
    };

    const lowerThreshold = formatValue(threshold?.[0], true);
    const upperThreshold = formatValue(threshold?.[1]);

    // Construct the threshold string with appropriate defaults for undefined values
    const thresholdString =
      lowerThreshold === '-' && upperThreshold === '-'
        ? '[-∞, ∞]' // Both values are undefined
        : `[${lowerThreshold === '-' ? '-∞' : lowerThreshold}, ${upperThreshold === '-' ? '∞' : upperThreshold}]`;

    return thresholdString;
  };

  //table setup
  useEffect(() => {
    if (!monitoredSystem) return;

    setLoading(false);

    const columns = [
      {
        field: 'field',
        headerName: t('field'),
        width: 200,
        flex: 1,
        renderCell: (params) => <div>{params.value}</div>,
      },
      {
        field: 'mean',
        headerName: t('mean'),
        width: 200,
        flex: 0.7,
        renderCell: (params) => <div>{formatNumber(params.value)}</div>,
      },
      {
        field: 'ninetiethPercentile',
        headerName: t('ews.ninetiethPercentile'),
        width: 200,
        flex: 1,
        renderCell: (params) => <div>{formatNumber(params.value)}</div>,
      },
      {
        field: 'warnThresholds',
        headerName: t('ews.warnThresholds'),
        width: 200,
        flex: 1.5,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            {renderThresholdField(params.value)}
            <IconButton
              onClick={() => handleOpenThresholdDialog(params.row, 'warn')}
              variant="qualitatio"
              squared={true}
              style={{ backgroundColor: theme.palette.primary.main, width: '25px', height: '25px' }}
            >
              <AddchartRoundedIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        field: 'criticalThresholds',
        headerName: t('ews.criticalThresholds'),
        width: 200,
        flex: 1.5,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            {renderThresholdField(params.value)}
            <IconButton
              onClick={() => handleOpenThresholdDialog(params.row, 'critical')}
              variant="qualitatio"
              squared={true}
              style={{ backgroundColor: theme.palette.primary.main, width: '25px', height: '25px' }}
            >
              <AddchartRoundedIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        field: 'fieldStatus',
        headerName: t('ews.fieldStatus'),
        width: 200,
        flex: 1,
        renderCell: (params) => <div>{t(`ews.${params.value}`)}</div>,
      },
    ];

    const rows = monitoredSystem?.fields?.map((field, index) => {
      const fieldData = monitoredSystem?.insights?.[field];
      return {
        id: index,
        systemId: monitoredSystem.id,
        field: field,
        mean: fieldData?.mean,
        ninetiethPercentile: fieldData?.ninetiethPercentile,
        criticalThresholds: fieldData?.criticalThresholds,
        warnThresholds: fieldData?.warnThresholds,
        fieldStatus: fieldData?.fieldStatus,
      };
    });
    setColumns(columns);
    setRows(rows);
  }, [monitoredSystem]);

  return (
    <PageFrame title={t('ews.systemInsights')} Icon={ShowChartIcon}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
              marginBottom={2}
            >
              <IconButton color="primary" variant="qualitatio" squared onClick={() => navigate(-1)}>
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
              <Typography variant="h3" gutterBottom>
                {t('ews.systemInsights')}
              </Typography>
              <div></div>
            </Box>
            {loading && rows ? (
              <CircularProgress />
            ) : (
              <QualitatioTable
                rows={rows}
                columns={columns}
                onRowClick={handleRowClick} // Attach the row click handler
                getRowClassName={(params) => (params.id === selectedRow?.id ? 'selectedRow' : '')}
                checkboxSelection={false}
                // Remove the checkboxSelection prop
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                slots={{}}
                pageSizeOptions={[5, 10]}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
              marginBottom={2}
            >
              <div></div>
              <Typography variant="h3" gutterBottom>
                {t('ews.fieldData')}
              </Typography>
              <WebSocketStatusIndicator />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              flexDirection="column"
              sx={{ height: '600px' }}
            >
              {monitoredSystem && selectedRow && (
                <SystemInfoChart
                  key={chartKey}
                  dataPoints={monitoredSystem.lastDataPoints}
                  selectedField={selectedRow.field}
                  mean={selectedRow.mean}
                  ninetiethPercentile={selectedRow.ninetiethPercentile}
                  criticalThresholds={
                    selectedRow.criticalThresholds ? selectedRow.criticalThresholds : undefined
                  }
                  warnThresholds={
                    selectedRow.warnThresholds ? selectedRow.warnThresholds : undefined
                  }
                  height={600}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openThresholdDialog && (
        <ThresholdOverlay
          onClose={onCloseThresholdDialog}
          selectedRow={selectedRow}
          isCritical={thresholdType === 'critical'}
        />
      )}
    </PageFrame>
  );
}
