import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactCard from './ContactCard/ContactCard';
import PageFrame from './PageFrame';
import './SupportPage.css';

const SupportPage = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('support'));

  return (
    <PageFrame title={t('support')} Icon={InfoRoundedIcon}>
      <div className="support-page">
        <div className="headline">{t('contactPerson')}</div>
        <div className="contact-card-wrapper">
          <ContactCard
            name="Nathan Gruber"
            email="nathan.gruber@datagon.ai"
            phone="+49 152 32783077"
            image="/images/Nathan.png"
          />
          <ContactCard
            name="Dr. Andreas Schoch"
            email="andreas.schoch@datagon.ai"
            phone="+49 176 45703782"
            image="/images/Andreas.png"
          />
        </div>
        <div className="support-information">
          {t('inCaseOfSystemMalfunctionOrQuestionsAboutTheSoftwareYouCanContactUsAtAnyTime')}
          <br />
          <br />
          {t('feelFreeToWriteUsAnEmailOrContactUsByPhone')}
          <br />
          <br />
          {t('weAreAlwaysAvailableOnWeekdaysFrom')}
          <b>06:00 - 22:00</b>
          {t('forYou')}
        </div>
      </div>
    </PageFrame>
  );
};

export default SupportPage;
