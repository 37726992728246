// Enum-like object for module names
const Modules = {
  EFFICIENT_TESTING: 'efficientTesting',
  INTELLIGENT_TESTING: 'intelligentTesting',
  EARLY_WARNING_SYSTEM: 'earlyWarningSystem',
};

// Array of possible modules, utilizing the enum-like object for values
const possibleModules = [
  Modules.EFFICIENT_TESTING,
  Modules.INTELLIGENT_TESTING,
  Modules.EARLY_WARNING_SYSTEM,
];

// Allowed authentication systems
const authSystems = {
  LDAP: 'ldap',
  PASSWORD: 'password',
  OAUTH: 'oauth',
};

// src/utils/routeUtils.js

const protectedRoutes = [
  '/setup',
  '/home',
  '/basis',
  '/intelligent_testing',
  '/artificial_intelligence',
  '/business_insights',
  '/extended_insights',
  '/efficient_testing',
  '/early_warning_system/monitor',
  // Add more protected routes here as needed
];

const isProtectedRoute = (pathname) => {
  return protectedRoutes.some((route) => pathname.startsWith(route));
};

// Exporting both the enum-like object and the array for use elsewhere
export { Modules, authSystems, isProtectedRoute, possibleModules };
