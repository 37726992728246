import ROLES from '../authentication/roleConst';

//function that returns /home or /intelligent_testing/recommender/select based on user role
function getRedirectPath(user, from) {
  if (user && user.role && user.role === ROLES.IT_USER) {
    return '/intelligent_testing/recommender/select';
  } else if (from) {
    return from;
  } else {
    return '/home';
  }
}

export { getRedirectPath };
