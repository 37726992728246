import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box, Grid,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaDatabase, FaRocket, FaServer } from "react-icons/fa";
import { capitalizeFirstLetter } from "../../../utils/utilities";
import KafkaSubscriptionForm from "./KafkaSubscriptionForm";

const SubscriptionSummary = ({ formik, readonly, availableOutputStreams }) => {
    const [streamConnectionDetailsIsOpen, setStreamConnectionDetailsIsOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
                <TextField
                    {...formik.getFieldProps('name')}
                    label="Name"
                    placeholder="Name"
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    disabled={readonly}
                />
            </Grid>

            <Grid item xs={12}>
                <Autocomplete
                    options={availableOutputStreams}
                    getOptionLabel={(option) => option.name}
                    value={availableOutputStreams.find(stream => stream.name === formik.values.stream) || null}
                    onChange={(event, newValue) => {
                        formik.setFieldValue('stream', newValue ? newValue.name : '');
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Stream"
                            error={formik.touched.stream && Boolean(formik.errors.stream)}
                            helperText={formik.touched.stream && formik.errors.stream}
                        />
                    )}
                    disableClearable
                    disabled={readonly}
                    freeSolo={false}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {formik.values.type === "kafka" && <FaRocket style={{ marginRight: 8 }} />}
                {formik.values.type === "database" && <FaDatabase style={{ marginRight: 8 }} />}
                {formik.values.type === "api" && <FaServer style={{ marginRight: 8 }} />}
                <Typography variant="h3">
                    {capitalizeFirstLetter(formik.values.type)}
                </Typography>
            </Grid>


            <Grid item xs={12}>
                <Accordion expanded={streamConnectionDetailsIsOpen} onChange={() => setStreamConnectionDetailsIsOpen(!streamConnectionDetailsIsOpen)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                            {t("connectionDetails")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <KafkaSubscriptionForm readonly={readonly} formik={formik} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                    <Box mr={1}>
                        {formik.values.active ? t("active") : t("inactive")}
                    </Box>
                    <Switch
                        {...formik.getFieldProps('active')}
                        checked={formik.values.active}
                        disabled={readonly}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default SubscriptionSummary;
