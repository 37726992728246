import { Grid } from '@mui/material';
import { QualitatioSpinner } from "../../../../../components/QualitatioSpinner";
import styles from './ProcessingPromt.module.css';

export const ProcessingPromt = ({ promt }) => {
    return (
        <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} display='flex' flexDirection='column' alignItems='center'>
                <div>{promt}</div>
            </Grid>
            <Grid item xs={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <QualitatioSpinner className={styles.spinner} />
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    );
}

export default ProcessingPromt;