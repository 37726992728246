import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Box, Grid, IconButton, Slider, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PresentationControls, Stage } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileImage } from 'react-icons/fa';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import axiosClient from '../../../api/axiosClient';
import { productModel3DEndpoint, productModel3DFileEndpoint } from '../../../api/endpoints';
import QualitatioDropzone from '../../../components/QualitatioDropzone/QualitatioDropzone';

const ThreeDModelSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();

  const [threeDProductModels, setThreeDProductModels] = useState([]);

  const get3DProductModels = async () => {
    try {
      const response = await axiosClient.get(productModel3DEndpoint);
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const upload3DProductModel = async (threeDProductModelFile) => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('threeDProductModelFile', threeDProductModelFile);

    // Save File to Server
    try {
      const response = await axiosClient.post(productModel3DFileEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
        enqueueSnackbar(t('3DModelUploadedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    get3DProductModels();
  }, []);

  const Product3DModel = ({ modelData, ...props }) => {
    //check if the modelFileURL is available and a valid json
    try {
      const model = useLoader(GLTFLoader, modelData.modelFileURL);
      return <primitive object={model.scene} {...props} />;
    } catch (error) {
      console.error('Error:', error);
      return <Box>{t('3DModelNotAvailable')}</Box>;
    }
  };

  const [rotation, setRotation] = useState([0, Math.PI / 4, 0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotation((prevRotation) => [prevRotation[0], prevRotation[1] + 0.01, prevRotation[2]]);
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  const handleZoomChange = async (threeDProductModel, value) => {
    try {
      const response = await axiosClient.patch(productModel3DEndpoint, {
        threeDProductModelId: threeDProductModel.id,
        modelName: threeDProductModel.modelName,
        modelZoom: value,
      });
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleNameChange = async (threeDProductModel, value) => {
    try {
      const response = await axiosClient.patch(productModel3DEndpoint, {
        threeDProductModelId: threeDProductModel.id,
        modelName: value,
        modelZoom: threeDProductModel.modelZoom,
      });
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handle3DModelDelete = async (threeDProductModelId) => {
    try {
      const response = await axiosClient.delete(productModel3DEndpoint, {
        data: {
          threeDProductModelId: threeDProductModelId,
        },
      });
      if (response.data.threeDProductModels) {
        setThreeDProductModels(response.data.threeDProductModels);
        enqueueSnackbar(t('3DModelDeletedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t('3DModelDeleteFailed'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QualitatioDropzone
          onDrop={upload3DProductModel}
          enablePreview={false}
          acceptedFileTypes={{ 'binary/glb': ['.glb'] }}
        >
          <FaFileImage size={30} />
          <Typography>{t('dropGLBHere')}</Typography>
          <Typography>{t('orClickToUpload')}</Typography>
        </QualitatioDropzone>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0px 20px', height: '300px' }}
      >
        {threeDProductModels.map((threeDProductModel, index) => (
          <Box key={index} style={{ display: 'inline-block', margin: '10px' }} maxHeight={'150px'}>
            <Canvas
              dpr={[1, 2]}
              camera={{ fov: 45 }}
              style={{ width: '100%', height: '100%', borderRadius: '5px' }}
            >
              <color attach="background" args={['#ffffff']} />
              <PresentationControls speed={1.5} global zoom={0.75}>
                <Stage environment={'sunset'}>
                  <Product3DModel
                    modelData={threeDProductModel}
                    position={[0, 2, 0]}
                    rotation={rotation}
                    scale={[
                      threeDProductModel.modelZoom || 0.75,
                      threeDProductModel.modelZoom || 0.75,
                      threeDProductModel.modelZoom || 0.75,
                    ]}
                  />
                </Stage>
              </PresentationControls>
            </Canvas>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label={t('modelName')}
                value={threeDProductModel.modelName}
                onChange={(e) => handleNameChange(threeDProductModel, e.target.value)}
                style={{ margin: '10px' }}
              />
              <IconButton
                className="delete-button"
                variant="qualitatio"
                squared={true}
                onClick={() => {
                  handle3DModelDelete(threeDProductModel.id);
                }}
                style={{ backgroundColor: theme.palette.error.main, width: '36px', height: '36px' }}
              >
                <DeleteForeverRoundedIcon color="white" />
              </IconButton>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', padding: '0px 10%' }}>
              <Slider
                value={threeDProductModel.modelZoom || 0.75}
                min={0.01}
                max={2}
                valueLabelDisplay="true"
                step={0.01}
                onChange={(e, value) => handleZoomChange(threeDProductModel, value)}
              />
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default ThreeDModelSettings;
