import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';

export const ExtendedInsights = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('extendedInsights'));

  return (
    <PageFrame title={t('extendedInsights')} Icon={QueryStatsIcon} particlesActive={true}>
      <div className="menu-page">
        <div className="text-wrapper-5">{t('welcomeToExtendedInsightsModule')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
        <div className="moduleCardWrapper">
          <ModulCard
            module={t('ei.settings')}
            moduleText={t('extendedInsightsSettingsDescription')}
            icon={<SettingsRoundedIcon color="primary" fontSize="large" />}
            href="/extended_insights/settings"
          />
          <ModulCard
            module={t('ei.dashboard')}
            moduleText={t('extendedInsightsDashboardDescription')}
            icon={<DescriptionRoundedIcon color="primary" fontSize="large" />}
            href="/extended_insights/dashboard"
          />
        </div>
      </div>
    </PageFrame>
  );
};

ExtendedInsights.propTypes = {
  setPageName: PropTypes.func.isRequired,
};

export default ExtendedInsights;
