import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { apiTokenEndpoint } from '../../../api/endpoints';
import styles from './AuthTokenSettings.module.css';
import AuthTokenSettingsDialog from './AuthTokenSettingsDialog';

const AuthTokenSettings = () => {
  const [tokens, setTokens] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    try {
      const response = await axiosClient.get(`${apiTokenEndpoint}?blacklisted=false`);
      setTokens(response.data);
    } catch (error) {
      enqueueSnackbar(t('authToken.fetchError'), { variant: 'error' });
    }
  };

  const handleDelete = async (apiKeyId) => {
    try {
      await axiosClient.delete(`${apiTokenEndpoint}/${apiKeyId}`);
      enqueueSnackbar(t('authToken.revokeSuccess'), { variant: 'success' });
      fetchTokens(); // Refresh the token list
    } catch (error) {
      enqueueSnackbar(t('authToken.revokeError'), { variant: 'error' });
    }
  };

  const handleDialogClose = (refresh = false) => {
    setOpenDialog(false);
    if (refresh) fetchTokens();
  };

  return (
    <>
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <List>
            {tokens.length === 0 ? (
              <ListItem divider>
                <ListItemText primary={t('authToken.noTokens')} className={styles.noTokens} />
              </ListItem>
            ) : (
              tokens.map((token) => (
                <ListItem key={token.id} divider className={styles.listItem}>
                  <ListItemText
                    primary={token.scope.join(', ')}
                    secondary={t('authToken.expires', {
                      date: dayjs(token.expires).format('MMMM D, YYYY h:mm A'),
                    })}
                  />
                  <IconButton
                    variant="qualitatio"
                    onClick={() => handleDelete(token.id)}
                    data-testid={`delete-${token.id}`}
                    squared="true"
                    background={theme.palette.error.main}
                    style={{ backgroundColor: theme.palette.error.main }}
                  >
                    <DeleteForeverIcon color="white" />
                  </IconButton>
                </ListItem>
              ))
            )}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="qualitatio"
              color="primary"
              className={mobile ? styles.addButtonMobile : styles.addButton}
              onClick={() => setOpenDialog(true)}
            >
              {t('authToken.createButton')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <AuthTokenSettingsDialog
        open={openDialog}
        onClose={handleDialogClose}
        apiTokenEndpoint={apiTokenEndpoint}
      />
    </>
  );
};

export default AuthTokenSettings;
