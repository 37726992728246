import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axiosClient from '../api/axiosClient';
import { forgotPasswordEndpoint } from '../api/endpoints';
import QualitatioButton from '../components/QualitatioButton/QualitatioButton';
import QualitatioInput from '../components/QualitatioInput/QualitatioInput';
import { useRedirectIfAuthenticated } from '../components/customHooks ';
import './RequestPasswordResetPage.css';

function RequestPasswordResetPage({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('requestResetPassword'));

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useRedirectIfAuthenticated();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axiosClient.post(forgotPasswordEndpoint, {
          email: values.email,
        });

        if (response.status === 204) {
        } else {
        }
        navigate('/login');
      } catch (error) {
        // Handle error (display an error message or handle it accordingly)
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="request-reset-password-page">
      <div className="frame">
        <div className="div-wrapper">
          <div className="text-wrapper-2">
            {t('resetPassword') ? t('resetPassword') : 'Reset Password'}
          </div>
        </div>
        <img className="logo" alt="Logo" src="/images/qualitatio.svg" />
        <form onSubmit={formik.handleSubmit} className="frame-3">
          <QualitatioInput
            label="Email"
            type="email"
            placeholder="test@test.com"
            width={'100%'}
            size="lg"
            onChange={(e) => formik.setFieldValue('email', e.currentTarget.value)}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <QualitatioButton
            text={t('requestResetPassword') ? t('requestResetPassword') : 'Request Password Reset'}
            order="primary"
            type="submit"
            width={'100%'}
            height={'5vh'}
            fontSize={'calc(12px + 0.25vw)'}
            disabled={loading}
          />
          <QualitatioButton
            text={t('back') ? t('back') : 'Back'}
            order="secondary"
            onClick={() => navigate('/login')}
            width={'100%'}
            height={'5vh'}
            fontSize={'calc(12px + 0.25vw)'}
            startIcon={<ArrowBackIosNewRoundedIcon />}
          />
        </form>
      </div>
    </div>
  );
}

export default RequestPasswordResetPage;
