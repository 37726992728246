import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Slider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import {
  addETQualityStationEndpoint,
  deleteETQualityStationEndpoint,
  getETModelsEndpoint,
  getETQualityStationsEndpoint,
  saveETQualityStationImageEndpoint,
  updateETQualityStationEndpoint,
  updateETQualityStationSelectedModelEndpoint,
  updateETQualityStationSensitivityEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDropdown from '../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioEmptyDialog from '../../components/QualitatioEmptyDialog/QualitatioEmptyDialog';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioMobileStepper from '../../components/QualitatioMobileStepper/QualitatioMobileStepper';
import QualitatioProgressBar from '../../components/QualitatioProgressBar/QualitatioProgressBar';
import './EfficientTestingConfigurator.css';
import OverviewQualityStations from './components/OverviewQualityStations';

export default function EfficientTestingConfigurator({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('productionConfigurator'));

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);

  const [selectedQualityStation, setSelectedQualityStation] = useState();
  const [selectedQualityStationImage, setSelectedQualityStationImage] = useState();

  const [qualityStations, setQualityStations] = useState([]);
  const orderedQualityStations = orderStations(qualityStations);
  const [availableModels, setAvailableModels] = useState([]);

  const [addNewQualityStationOpen, setAddNewQualityStationOpen] = useState(false);
  const [newQualityStation, setNewQualityStation] = useState({ predecessor: -1 });

  const [editQualityStationOpen, setEditQualityStationOpen] = useState(false);
  const [editQualityStation, setEditQualityStation] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const [infoOpen, setInfoOpen] = useState(false);
  const nextDisabled =
    (activeStep === 1 && !selectedQualityStation) ||
    (activeStep === 2 && selectedQualityStation?.model === null) ||
    (activeStep === 3 && selectedQualityStation?.sensitivity === null) ||
    activeStep === 4;
  const [discardWarningOpen, setDiscardWarningOpen] = useState(false);
  const [discardWarningNextOrBack, setDiscardWarningNextOrBack] = useState('');

  function orderStations(stations, predecessor = -1) {
    const nextStation = stations.find((s) => s.predecessor == predecessor);
    if (nextStation) {
      return [nextStation, ...orderStations(stations, nextStation.id)];
    }
    return [];
  }

  useEffect(() => {
    loadQualityStations();
    loadETModels();
  }, []);

  const loadQualityStations = async () => {
    try {
      const response = await axiosClient.get(getETQualityStationsEndpoint);
      setQualityStations(response.data.qualityStations);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const loadETModels = async () => {
    try {
      const response = await axiosClient.get(getETModelsEndpoint);
      setAvailableModels(response.data.mlModels);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const uploadQualityStationImageToServer = async (file, qs, call) => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('file', file);
    formData.append('qualityStationName', qs.name);

    // Save File to Server
    try {
      console.log(file);
      const response = await axiosClient.post(saveETQualityStationImageEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      call({ ...qs, image: response.data.qualityStationImageURL });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [contactError, setContactError] = useState('');

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const setNewQualityStationContact = (contact, qualityStation, call) => {
    const isValidEmail = validateEmail(contact);
    if (isValidEmail) {
      setContactError(''); // Clear any previous validation error
    } else {
      setContactError(t('invalidEmailAddress'));
    }
    call({ ...qualityStation, contact: contact });
  };

  const qualitySaveButtonIsDisabled =
    newQualityStation.name?.length < 2 ||
    newQualityStation.responsible?.length < 3 ||
    !validateEmail(newQualityStation.contact) ||
    !newQualityStation.image ||
    (orderedQualityStations.length !== 0 &&
      (newQualityStation.predecessor === undefined ||
        newQualityStation.predecessor === null ||
        newQualityStation.predecessor === ''));

  const handleQualitySaveButton = async () => {
    try {
      const response = await axiosClient.post(addETQualityStationEndpoint, {
        qualityStation: newQualityStation,
      });
      setQualityStations(response.data.qualityStations);
      enqueueSnackbar(t('qualityStationSuccessfullyCreated'), { variant: 'success' });
      setNewQualityStation({});
      handleCloseDialog();
    } catch (error) {
      enqueueSnackbar(t('errorDuringCreationOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const qualityEditButtonIsDisabled =
    editQualityStation.name?.length < 2 ||
    editQualityStation.responsible?.length < 3 ||
    !validateEmail(editQualityStation.contact) ||
    !editQualityStation.image ||
    editQualityStation.predecessor === undefined ||
    editQualityStation.predecessor === null ||
    editQualityStation.predecessor === '';

  const handleQualityEditButton = async () => {
    try {
      const response = await axiosClient.post(updateETQualityStationEndpoint, {
        qualityStation: editQualityStation,
      });
      setQualityStations(response.data.qualityStations);
      enqueueSnackbar(t('qualityStationSuccessfullyEdited'), { variant: 'success' });
      setEditQualityStation({});
      handleCloseDialog();
    } catch (error) {
      enqueueSnackbar(t('errorDuringEditingOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState([]);
  const handleQualityDeleteButton = async (qualityStationIndex) => {
    const qualityStationID = orderedQualityStations[qualityStationIndex].id;
    try {
      const response = await axiosClient.post(deleteETQualityStationEndpoint, {
        qualityStationID: qualityStationID,
      });
      setQualityStations(response.data.qualityStations);
      enqueueSnackbar(t('qualityStationSuccessfullyDeleted'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorDuringDeletionOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [fileInputKey, setFileInputKey] = useState(0);

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setAddNewQualityStationOpen(false);
    setEditQualityStationOpen(false);
    setConfirmationDialogOpen([]);
    setDiscardWarningOpen(false);
  };

  const updateSelectedModel = async (event) => {
    const selectedModel = event.target.value;
    const selectedQualityStationID = qualityStations.find(
      (qualityStation) => qualityStation.name === selectedQualityStation
    )?.id;
    try {
      const response = await axiosClient.post(updateETQualityStationSelectedModelEndpoint, {
        qualityStationID: selectedQualityStationID,
        model: selectedModel,
      });
      setQualityStations(response.data.qualityStations);
      enqueueSnackbar(t('qualityStationSuccessfullyEdited'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorDuringEditingOfQualityStation'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const updateSensitivity = async (event, value) => {
    const selectedQualityStationID = qualityStations.find(
      (qualityStation) => qualityStation.name === selectedQualityStation
    )?.id;
    if (
      value !==
      qualityStations.find((qualityStation) => qualityStation.name === selectedQualityStation)
        ?.sensitivity
    ) {
      try {
        const response = await axiosClient.post(updateETQualityStationSensitivityEndpoint, {
          qualityStationID: selectedQualityStationID,
          sensitivity: value,
        });
        setQualityStations(response.data.qualityStations);
        // enqueueSnackbar(t("qualityStationSuccessfullyEdited"), { variant: "success" });
      } catch (error) {
        enqueueSnackbar(t('errorDuringEditingOfQualityStation'), { variant: 'error' });
        console.error('Error:', error);
      }
    }
  };

  return (
    <PageFrame title={t('productionConfigurator')} Icon={AccountTreeRoundedIcon}>
      <div className="prod-config-efficient-testing">
        {activeStep === 0 ? (
          <div className="step-0">
            <div className="welcome-slogan">{t('welcomeToTestPlaning')}</div>
            <div className="configurator-description">
              {t('theProcessOfTestPlaningConsistsOfTheFollowingFourSubsteps')}
            </div>
            <div className="configurator-steps">
              <QualitatioProgressBar
                steps={[
                  t('selectQualityStation'),
                  t('selectModel'),
                  t('configureSensitivity'),
                  t('checkQualityStation'),
                ]}
                nonLinear={false}
                alternativeLabel={true}
                activeStep={3}
                nonLinearSteps={[3]}
                setActiveStep={setActiveStep}
              />
            </div>
            <div className="start-button">
              <div className="start-button-wrapper">
                <QualitatioButton
                  text={t('start')}
                  width={'100%'}
                  onClick={() => {
                    setActiveStep(1);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="all-steps">
            <div className="container-back">
              <IconButton
                variant="qualitatio"
                squared={true}
                onClick={() => {
                  setActiveStep(activeStep - 1);
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '48px',
                  height: '48px',
                }}
              >
                <ArrowBackIosNewRoundedIcon color="primary" />
              </IconButton>
            </div>
            <div className="container-card">
              <div className="steps-overview">
                {activeStep} {t('/of4Steps')}
              </div>
              <div className="mobile-stepper-container">
                <QualitatioMobileStepper
                  totalSteps={5}
                  activeStep={activeStep}
                  controllable={false}
                />
              </div>
              {activeStep === 1 && (
                <div className="step-2">
                  <div className="choose-quality-station">{t('selectAQualityStation')}</div>
                  <div className="quality-station-list">
                    {orderedQualityStations.map((qualityStation, index) => {
                      return (
                        <div className="quality-station" key={index}>
                          <div className="quality-station-name-wrapper">
                            <QualitatioButton
                              text={qualityStation.name}
                              startIcon={<img src={qualityStation.image} />}
                              startIconIsImage={true}
                              startIconRounded={true}
                              endIcon={<ArrowForwardIosRoundedIcon />}
                              width={'100%'}
                              onClick={() => {
                                setSelectedQualityStation(qualityStation.name);
                                setSelectedQualityStationImage(qualityStation.image);
                                setActiveStep(activeStep + 1);
                              }}
                            />
                          </div>
                          <div className="quality-station-edit-wrapper">
                            <IconButton
                              variant="qualitatio"
                              squared={true}
                              onClick={() => {
                                setEditQualityStation(qualityStation);
                                setEditQualityStationOpen(true);
                              }}
                              style={{
                                backgroundColor: theme.palette.success.secondary,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <EditRoundedIcon color="primary" />
                            </IconButton>
                            <QualitatioEmptyDialog
                              open={editQualityStationOpen}
                              onClose={handleCloseDialog}
                            >
                              <div className="add-quality-station-modal">
                                <div className="steps-overview-add-QS">
                                  {activeStep} {t('/of4Steps')}
                                </div>
                                <div className="mobile-stepper-container-add-QS">
                                  <QualitatioMobileStepper
                                    totalSteps={5}
                                    activeStep={activeStep}
                                    controllable={false}
                                  />
                                </div>

                                <div className="add-quality-station-title">
                                  {t('editQualityStation')}
                                </div>
                                <div className="add-quality-station-form">
                                  <QualitatioInput
                                    label={t('qualityStationName')}
                                    value={editQualityStation.name}
                                    type="text"
                                    placeholder={t('qualityStationPlaceholder')}
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        name: e.currentTarget.value,
                                      })
                                    }
                                  />
                                  <QualitatioInput
                                    label={t('responsible')}
                                    value={editQualityStation.responsible}
                                    type="text"
                                    placeholder={t('defaultName')}
                                    width={'100%'}
                                    onChange={(e) =>
                                      setEditQualityStation({
                                        ...editQualityStation,
                                        responsible: e.currentTarget.value,
                                      })
                                    }
                                  />
                                  <QualitatioInput
                                    label={t('contact')}
                                    value={editQualityStation.contact}
                                    type="text"
                                    placeholder="max.mustermann@mail.com"
                                    width={'100%'}
                                    onChange={(e) =>
                                      setNewQualityStationContact(
                                        e.currentTarget.value,
                                        editQualityStation,
                                        setEditQualityStation
                                      )
                                    }
                                  />
                                  {contactError && (
                                    <div style={{ color: 'red' }} className="error">
                                      {contactError}
                                    </div>
                                  )}
                                  <QualitatioInput
                                    label={t('qualityStationImage')}
                                    type="file"
                                    width={'100%'}
                                    onChange={(e) =>
                                      uploadQualityStationImageToServer(
                                        e.target.files[0],
                                        editQualityStation,
                                        setEditQualityStation
                                      )
                                    }
                                  />
                                  <QualitatioDropdown
                                    label={t('previousQualityStation')}
                                    value={{
                                      value: editQualityStation.predecessor,
                                      label:
                                        qualityStations.find(
                                          (qualityStation) =>
                                            qualityStation.id === editQualityStation.predecessor
                                        )?.name || t('noPredecessor'),
                                    }}
                                    options={[
                                      { label: t('noPredecessor'), value: -1 },
                                      ...qualityStations
                                        .filter(
                                          (qualityStation) =>
                                            qualityStation.id !== editQualityStation.id
                                        )
                                        .map((qualityStation) => {
                                          return {
                                            label: qualityStation.name,
                                            value: qualityStation.id,
                                          };
                                        }),
                                    ]}
                                    width={'100%'}
                                    onChange={(e, option) => {
                                      if (option) {
                                        setEditQualityStation({
                                          ...editQualityStation,
                                          predecessor: option.value,
                                        });
                                      }
                                    }}
                                  />

                                  <div className="add-quality-station-buttons">
                                    <QualitatioButton
                                      text={t('save')}
                                      order="primary"
                                      disabled={qualityEditButtonIsDisabled}
                                      onClick={async () => {
                                        await handleQualityEditButton();
                                      }}
                                      width={'100%'}
                                      height={'5vh'}
                                      fontSize={'calc(12px + 0.25vw)'}
                                      endIcon={<ArrowForwardIosRoundedIcon />}
                                    />
                                    <QualitatioButton
                                      text={t('cancel')}
                                      order="secondary"
                                      onClick={() => {
                                        setEditQualityStation({});
                                        handleCloseDialog();
                                      }}
                                      width={'100%'}
                                      height={'5vh'}
                                      fontSize={'calc(12px + 0.25vw)'}
                                      startIcon={<ArrowBackIosNewRoundedIcon />}
                                    />
                                  </div>
                                </div>
                              </div>
                            </QualitatioEmptyDialog>
                          </div>
                          <div className="quality-station-delete-wrapper">
                            <IconButton
                              className="delete-button"
                              variant="qualitatio"
                              squared={true}
                              onClick={() => {
                                let newConfirmationDialogOpen = [...confirmationDialogOpen];
                                newConfirmationDialogOpen[index] = true;
                                setConfirmationDialogOpen(newConfirmationDialogOpen);
                              }}
                              style={{
                                backgroundColor: theme.palette.error.main,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <DeleteForeverRoundedIcon color="white" />
                            </IconButton>
                            <QualitatioDialog
                              title={t('deleteQualityStation')}
                              description={t('doYouReallyWantToDeleteThisQualityStation')}
                              open={confirmationDialogOpen[index]}
                              onClose={handleCloseDialog}
                              maxWidth="sm"
                              actions={[
                                {
                                  label: t('cancel'),
                                  onClick: () => handleCloseDialog(),
                                  order: 'secondary',
                                },
                                {
                                  label: t('delete'),
                                  onClick: () => {
                                    handleQualityDeleteButton(index);
                                    handleCloseDialog();
                                  },
                                  order: 'primary',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="add-quality-station">
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      onClick={() => setAddNewQualityStationOpen(true)}
                      style={{
                        backgroundColor: theme.palette.success.secondary,
                        width: '36px',
                        height: '36px',
                      }}
                    >
                      <AddRoundedIcon color="primary" />
                    </IconButton>
                    <QualitatioEmptyDialog
                      open={addNewQualityStationOpen}
                      onClose={handleCloseDialog}
                    >
                      <div className="add-quality-station-modal">
                        <div className="steps-overview-add-QS">
                          {activeStep} {t('/of4Steps')}
                        </div>
                        <div className="mobile-stepper-container-add-QS">
                          <QualitatioMobileStepper
                            totalSteps={5}
                            activeStep={activeStep}
                            controllable={false}
                          />
                        </div>

                        <div className="add-quality-station-title">{t('addNewQualityStation')}</div>
                        <div className="add-quality-station-form">
                          <QualitatioInput
                            label={t('qualityStationName')}
                            type="text"
                            placeholder="Prüfstation 1"
                            width={'100%'}
                            value={newQualityStation.name || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                name: e.currentTarget.value,
                              })
                            }
                          />
                          <QualitatioInput
                            label={t('responsible')}
                            type="text"
                            placeholder="Max Mustermann"
                            width={'100%'}
                            value={newQualityStation.responsible || ''}
                            onChange={(e) =>
                              setNewQualityStation({
                                ...newQualityStation,
                                responsible: e.currentTarget.value,
                              })
                            }
                          />
                          <QualitatioInput
                            label={t('contact')}
                            type="text"
                            placeholder="max.mustermann@mail.com"
                            width={'100%'}
                            value={newQualityStation.contact || ''}
                            onChange={(e) =>
                              setNewQualityStationContact(
                                e.currentTarget.value,
                                newQualityStation,
                                setNewQualityStation
                              )
                            }
                          />
                          {contactError && (
                            <div style={{ color: 'red' }} className="error">
                              {contactError}
                            </div>
                          )}
                          <QualitatioInput
                            key={fileInputKey}
                            label={t('qualityStationImage')}
                            type="file"
                            width={'100%'}
                            onChange={(e) => {
                              uploadQualityStationImageToServer(
                                e.target.files[0],
                                newQualityStation,
                                setNewQualityStation
                              );
                            }}
                          />

                          <QualitatioDropdown
                            label={t('previousQualityStation')}
                            options={[
                              { label: t('noPredecessor'), value: -1 },
                              ...qualityStations.map((qualityStation) => {
                                return { label: qualityStation.name, value: qualityStation.id };
                              }),
                            ]}
                            width={'100%'}
                            value={{
                              value: newQualityStation.predecessor,
                              label:
                                qualityStations.find(
                                  (qualityStation) =>
                                    qualityStation.id === newQualityStation.predecessor
                                )?.name || t('noPredecessor'),
                            }}
                            onChange={(e, option) => {
                              if (option) {
                                setNewQualityStation({
                                  ...newQualityStation,
                                  predecessor: option.value,
                                });
                              }
                            }}
                          />
                          <div className="add-quality-station-buttons">
                            <QualitatioButton
                              text={t('save')}
                              order="primary"
                              disabled={qualitySaveButtonIsDisabled}
                              onClick={handleQualitySaveButton}
                              width={'100%'}
                              height={'5vh'}
                              fontSize={'calc(12px + 0.25vw)'}
                              endIcon={<ArrowForwardIosRoundedIcon />}
                            />
                            <QualitatioButton
                              text={t('cancel')}
                              order="secondary"
                              onClick={() => {
                                setNewQualityStation({});
                                handleCloseDialog();
                                setFileInputKey(fileInputKey + 1);
                              }}
                              width={'100%'}
                              height={'5vh'}
                              fontSize={'calc(12px + 0.25vw)'}
                              startIcon={<ArrowBackIosNewRoundedIcon />}
                            />
                          </div>
                        </div>
                      </div>
                    </QualitatioEmptyDialog>
                  </div>
                </div>
              )}

              {activeStep === 2 && (
                <div className="step-2">
                  <div className="choose-model">{t('selectAPredictionModel')}</div>
                  <div className="test-logic-list">
                    <div className="test-logic">
                      <div className="test-logic-name-wrapper">
                        <QualitatioButton
                          text={selectedQualityStation}
                          disabled={true}
                          startIcon={<img src={selectedQualityStationImage} />}
                          startIconIsImage={true}
                          width={'100%'}
                          startIconRounded={true}
                        />
                      </div>
                    </div>
                    <div className="test-logic">
                      <div className="test-logic-name-wrapper">
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%', margin: '8px 0px' }}
                        >
                          <InputLabel id="test-select-label">
                            {t('selectedPredictionModel')}
                          </InputLabel>
                          <Select
                            value={
                              qualityStations.find(
                                (qualityStation) => qualityStation.name === selectedQualityStation
                              )?.model || null
                            }
                            onChange={updateSelectedModel}
                            label={t('selectedPredictionModel')}
                          >
                            {availableModels.map((model) => (
                              <MenuItem key={model.id} value={model.id}>
                                {model.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="test-logic-info-wrapper">
                        <IconButton
                          variant="qualitatio"
                          squared={true}
                          onClick={() => setInfoOpen(true)}
                          style={{
                            backgroundColor: theme.palette.success.secondary,
                            width: '36px',
                            height: '36px',
                          }}
                        >
                          <InfoRoundedIcon color="primary" />
                        </IconButton>
                        <QualitatioDialog
                          title={t('predictionModelInfo')}
                          description={t('predictionModelInfoDescription')}
                          open={infoOpen}
                          onClose={() => setInfoOpen(false)}
                          maxWidth="sm"
                          actions={[
                            {
                              label: t('alright'),
                              onClick: () => {
                                setInfoOpen(false);
                              },
                              order: 'primary',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeStep === 3 && (
                <div className="step-3">
                  <div className="add-test-card">{t('chooseSensitivity')}</div>
                  <div className="sensitivity-explanation">{t('sensitivityExplanation')}</div>
                  <div className="sensitivity-slider">
                    <div className="sensitivity-slider-labels">
                      {t('currentSensitivity') +
                        ': ' +
                        qualityStations.find(
                          (qualityStation) => qualityStation.name === selectedQualityStation
                        )?.sensitivity}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Slider
                        value={
                          qualityStations.find(
                            (qualityStation) => qualityStation.name === selectedQualityStation
                          )?.sensitivity || 75
                        }
                        valueLabelDisplay="auto"
                        step={0.01}
                        min={0}
                        max={100}
                        onChange={updateSensitivity}
                        style={{ flex: 1 }} // makes the slider take up remaining space
                      />
                      <input
                        type="number"
                        value={
                          qualityStations.find(
                            (qualityStation) => qualityStation.name === selectedQualityStation
                          )?.sensitivity || 75
                        }
                        onChange={(e) => {
                          const newValue = parseFloat(e.target.value);
                          if (newValue >= 0 && newValue <= 100) {
                            updateSensitivity(null, newValue);
                          }
                        }}
                        step="0.01"
                        min="0"
                        max="100"
                        style={{
                          width: '70px', // sets the width of the input field
                          padding: '5px',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {activeStep === 4 && (
                <div className="step-4">
                  <div className="overview-about-test-cards">{t('qualityStationsOverview')}</div>
                  <OverviewQualityStations
                    qualityStations={qualityStations}
                    selectedQualityStation={selectedQualityStation}
                    setQualityStation={setSelectedQualityStation}
                    availableModels={availableModels}
                    updateSelectedModel={updateSelectedModel}
                    updateSensitivity={updateSensitivity}
                  />
                </div>
              )}
            </div>
            <div className="container-next">
              <IconButton
                variant="qualitatio"
                squared={true}
                disabled={nextDisabled}
                onClick={async () => {
                  setActiveStep(activeStep + 1);
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '48px',
                  height: '48px',
                }}
              >
                <ArrowForwardIosRoundedIcon color="primary" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </PageFrame>
  );
}
