import { useTranslation } from 'react-i18next';
import { Slider, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './OverviewQualityStations.css';
import QualitatioDropdown from '../../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioSelect from '../../../components/QualitatioSelect/QualitatioSelect';

const OverviewQualityStations = ({ qualityStations, selectedQualityStation, setQualityStation, availableModels, updateSelectedModel, updateSensitivity }) => {
    const { t } = useTranslation();

    return (
        <div className="overview-wrapper">
            <div className="overview">
                <div className="overview-wrapper">
                    <div className="overview-body">
                        <div className="choose-quality-station">
                            {t("1SelectQualityStation")}
                        </div>
                        <div className="choose-quality-station-wrapper">
                            <QualitatioDropdown
                                label={t("testStation")}
                                className="choose-quality-station-dropdown"
                                options={qualityStations?.map((station) => {
                                    return {
                                        value: station.name,
                                        label: station.name,
                                    }
                                })}
                                onChange={(e, option) => {
                                    if (option) {
                                        setQualityStation(option.value);
                                    }
                                }}
                                value={{ value: selectedQualityStation, label: selectedQualityStation }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cards-overview">
                <div className="cards-overview-wrapper">
                    <div className="cards-overview-body">
                        <div className="quality-station-model">
                            Model
                            <FormControl variant='outlined' style={{ width: '100%', margin: "8px 0px" }} >
                                <InputLabel id='test-select-label'>{t("selectedPredictionModel")}</InputLabel>
                                <Select
                                    value={qualityStations.find((qualityStation) => qualityStation.name === selectedQualityStation)?.model || null}
                                    onChange={updateSelectedModel}
                                    label={t("selectedPredictionModel")}
                                >
                                    {availableModels.map((model) => (
                                        <MenuItem key={model.id} value={model.id}>
                                            {model.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="quality-station-sensitivity">
                            Sensitivity
                            <div className="sensitivity-slider">
                                <div className="sensitivity-slider-labels">
                                    {t("currentSensitivity") + ": " + qualityStations.find((qualityStation) => qualityStation.name === selectedQualityStation)?.sensitivity}
                                </div>
                                <Slider
                                    value={qualityStations.find((qualityStation) => qualityStation.name === selectedQualityStation)?.sensitivity || 75}
                                    valueLabelDisplay="true"
                                    step={1}
                                    onChange={updateSensitivity}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OverviewQualityStations;