import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { intelligentDocumentationSettingsEndpoint } from '../../../api/endpoints';

const IDSettings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [imageBasedDocumentation, setImageBasedDocumentation] = useState(true);
  const [speechBasedDocumentation, setSpeechBasedDocumentation] = useState(true);

  const [externalImageAPIUsage, setExternalImageAPIUsage] = useState(true);
  const [externalTextAPIUsage, setExternalTextAPIUsage] = useState(true);

  useEffect(() => {
    getIDSettings();
  }, []);

  const getIDSettings = async () => {
    try {
      const response = await axiosClient.get(intelligentDocumentationSettingsEndpoint);
      if (response.data.idSettings) {
        setImageBasedDocumentation(response.data.idSettings.imageBasedDocumentation);
        setSpeechBasedDocumentation(response.data.idSettings.speechBasedDocumentation);
        setExternalTextAPIUsage(response.data.idSettings.externalTextAPIUsage);
        setExternalImageAPIUsage(response.data.idSettings.externalImageAPIUsage);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSaveIDSettings = async () => {
    try {
      const response = await axiosClient.post(intelligentDocumentationSettingsEndpoint, {
        imageBasedDocumentation: imageBasedDocumentation,
        speechBasedDocumentation: speechBasedDocumentation,
        externalTextAPIUsage: externalTextAPIUsage,
        externalImageAPIUsage: externalImageAPIUsage,
      });
      if (response.status === 200) {
        enqueueSnackbar(t('idSettingsUpdatedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhenUpdatingIDSettings'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container direction="row" spacing={2}>
        {/* Image and Text Documentation Switches */}
        <Grid item>
          <Box display="flex" alignItems="center">
            <Switch
              checked={imageBasedDocumentation}
              onChange={() => setImageBasedDocumentation((prevState) => !prevState)}
            />
            <Typography ml={2}>{t('imageBasedDocumentation')}</Typography>
          </Box>
          {imageBasedDocumentation && (
            <Box display="flex" alignItems="center">
              <Switch
                checked={externalImageAPIUsage}
                onChange={() => setExternalImageAPIUsage((prevState) => !prevState)}
              />
              <Typography ml={2}>{t('externalImageAPIUsage')}</Typography>
            </Box>
          )}
        </Grid>

        {/* External API Usage Switches */}
        <Grid item>
          <Box display="flex" alignItems="center" ml={4}>
            <Switch
              checked={speechBasedDocumentation}
              onChange={() => setSpeechBasedDocumentation((prevState) => !prevState)}
            />
            <Typography ml={2}>{t('speechBasedDocumentation')}</Typography>
          </Box>
          {speechBasedDocumentation && (
            <Box display="flex" alignItems="center" ml={4}>
              <Switch
                checked={externalTextAPIUsage}
                onChange={() => setExternalTextAPIUsage((prevState) => !prevState)}
              />
              <Typography ml={2}>{t('externalTextAPIUsage')}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="qualitatio" color="primary" onClick={handleSaveIDSettings}>
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default IDSettings;
