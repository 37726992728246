import Button from '@mui/material/Button';
import './QualitatioButton.css';

const QualitatioButton = ({
  text,
  onClick,
  className,
  width,
  height,
  minWidth,
  fontSize,
  disabled,
  margin,
  startIcon,
  startIconIsImage,
  startIconRounded,
  endIcon,
  order = 'primary',
  ...otherProps
}) => {
  const style = {
    width: width || 'auto',
    minWidth: minWidth || 'auto',
    height: height || 'auto',
    fontSize: fontSize || '1rem',
    background: !disabled
      ? order === 'primary'
        ? 'var(--m-3refprimaryprimary-70)'
        : ''
      : 'var(--m3state-layerslighton-surfaceopacity-012)',
    color: !disabled
      ? order === 'primary'
        ? 'var(--pure-white)'
        : 'var(--m-3refprimaryprimary-70)'
      : 'rgba(25, 28, 28, 1)',
    margin: margin || '0',
    borderColor: !disabled
      ? order === 'primary'
        ? 'var(--m-3refprimaryprimary-70)'
        : 'var(--m-3refprimaryprimary-70)'
      : 'rgba(25, 28, 28, 1)',
  };

  return (
    <Button
      className={
        'qualitatio-button' +
        (className ? ' ' + className : '') +
        (startIconIsImage ? ' start-icon-is-image' : '') +
        (startIconRounded ? ' start-icon-rounded' : '')
      }
      variant={order === 'primary' ? 'contained' : 'outlined'}
      style={style}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      {...otherProps}
    >
      {text}
    </Button>
  );
};

export default QualitatioButton;
