import { Grid } from '@mui/material';

export const SwipeCardButtonBar = ({ children }) => {
    // Assure children is a list => in case of one child children is just an object
    if (children.length == undefined)
        children = [children];

    // dynamic grid width depending on number of buttons
    const gridItemWidth = Math.floor(12 / children.length);
    const gridProps = { xs: gridItemWidth, display: "flex", flexDirection: "column", alignItems: "center" }
    
    return (
        <Grid container display="flex" justifyContent="center">
            {children?.map((child) =>
                <Grid item {...gridProps}>
                    {child}
                </Grid>
            )}
        </Grid>
    );
}

export default SwipeCardButtonBar;
