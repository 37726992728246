export const primaryColor = '#1FBEBD';
export const qualitatioBlack = '#000000';
export const qualitatioWhite = '#FFFFFF';
export const qualitatioGray = '#E0E3E2'


export const h2Styles = {
    fontFamily: 'Roboto-Bold',
    fontSize: "calc(16px + 1vw)",
    fontWeight: 500,
    color: "#78FFF3",
};