// Dialogs.js
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioDialog from '../../../../components/QualitatioDialog/QualitatioDialog';
import CardEditDialogs from '../components/CardEditDialogs';

const Dialogs = ({
  discardWarningDialog,
  deleteCardWarningDialog,
  discardChangesWarningDialog,
}) => {
  const { t } = useTranslation();

  const { discardWarningOpen, setDiscardWarningOpen, updateEventTestsData } = discardWarningDialog;

  return (
    <>
      <QualitatioDialog
        title={t('unsavedChanges')}
        description={t('ifYouNowUpdateYourChangesWillLostAreYouSureYouWantToPreceed')}
        open={discardWarningOpen}
        onClose={() => setDiscardWarningOpen(false)}
        maxWidth="sm"
        actions={[
          {
            label: t('discard'),
            onClick: () => {
              setDiscardWarningOpen(false);
              updateEventTestsData();
            },
            order: 'secondary',
          },
          {
            label: t('cancel'),
            onClick: () => {
              setDiscardWarningOpen(false);
            },
            order: 'primary',
          },
        ]}
      />
      <CardEditDialogs
        deleteCardWarningDialog={deleteCardWarningDialog}
        discardChangesWarningDialog={discardChangesWarningDialog}
      />
    </>
  );
};

const discardWarningDialogPropTypes = {
  discardWarningOpen: PropTypes.bool.isRequired,
  setDiscardWarningOpen: PropTypes.func.isRequired,
  updateEventTestsData: PropTypes.func.isRequired,
};

const deleteCardWarningDialogPropTypes = {
  deleteCardWarningOpen: PropTypes.bool.isRequired,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

const discardChangesWarningDialogPropTypes = {
  discardChangesWarningOpen: PropTypes.number.isRequired,
  setDiscardChangesWarningOpen: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};

Dialogs.propTypes = {
  discardWarningDialog: PropTypes.shape(discardWarningDialogPropTypes).isRequired,
  deleteCardWarningDialog: PropTypes.shape(deleteCardWarningDialogPropTypes).isRequired,
  discardChangesWarningDialog: PropTypes.shape(discardChangesWarningDialogPropTypes).isRequired,
};

export default Dialogs;
