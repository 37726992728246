import OutputIcon from '@mui/icons-material/Output';
import { Box, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import {
  availableOutputStreamsEndpoint,
  outputStreamSubscriptionEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import { useDisclosure } from '../../utils/customHooks';
import './ConsumerConfigurator.css';
import AvailableStreamsTable from './components/AvailableStreamsTable';
import OutputStreamSubscriptionsTable from './components/OutputStreamSubscriptionsTable';
import SubscriptionSetup from './components/SubscriptionSetup';

export default function ConsumerConfigurator({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('consumerConfiguration'));

  const [outputStreamSubscription, setOutputStreamSubscription] = useState([]);
  const [availableOutputStreams, setAvailableOutputStreams] = useState([]);

  /** HELPER VARIABLES */
  const { enqueueSnackbar } = useSnackbar();
  const {
    isOpen: isSubscriptionSetupOpen,
    onOpen: onSubscriptionSetup,
    onClose: onSubscriptionSetupClose,
  } = useDisclosure();

  useEffect(() => {
    reloadFromServer();
  }, []);

  const reloadFromServer = () => {
    getAvailableOutputStreams();
    getOutputStreamSubscription();
  };

  const getOutputStreamSubscription = async () => {
    try {
      const response = await axiosClient.get(outputStreamSubscriptionEndpoint);
      if (response.data.outputStreamSubscription) {
        setOutputStreamSubscription(response.data.outputStreamSubscription);
      }
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('errorWhileFetchingOutputStreamSubscriptions'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const getAvailableOutputStreams = async () => {
    try {
      const response = await axiosClient.get(availableOutputStreamsEndpoint);
      if (response.data.availableOutputStreams) {
        setAvailableOutputStreams(response.data.availableOutputStreams);
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhileFetchingAvailableOutputStreams'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <PageFrame title={t('consumerConfiguration')} Icon={OutputIcon}>
      {!isSubscriptionSetupOpen && (
        <div>
          {/* HEADER */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h2">{t('availableOutputStreams')}</Typography>
          </Box>
          {/* TABLE */}
          <AvailableStreamsTable
            availableOutputStreams={availableOutputStreams}
            setAvailableOutputStreams={setAvailableOutputStreams}
          />

          {/* SUBSCRIBED DATA CONNECTIONS */}

          {/* HEADER */}
          <Grid container spacing={2} style={{ marginTop: '5%' }} justifyContent="space-between">
            <Grid item sm={12} md={6} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography variant="h2">{t('outputStreamSubscriptions')}</Typography>
            </Grid>
          </Grid>

          <OutputStreamSubscriptionsTable
            outputStreamSubscriptions={outputStreamSubscription}
            setOutputStreamSubscriptions={setOutputStreamSubscription}
            reload={getOutputStreamSubscription}
            availableOutputStreams={availableOutputStreams}
            onSubscriptionSetup={onSubscriptionSetup}
          />
        </div>
      )}
      {isSubscriptionSetupOpen && (
        <SubscriptionSetup
          onSubscriptionSetupClose={onSubscriptionSetupClose}
          setOutputStreamSubscriptions={setOutputStreamSubscription}
          availableOutputStreams={availableOutputStreams}
          reload={reloadFromServer}
        />
      )}
    </PageFrame>
  );
}
