import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Scanner, centerText } from '@yudiel/react-qr-scanner';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { getQualityStationImageEndpoint } from '../../../api/endpoints';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import Menu from '../Menu';
import './Scan.css';
import scannerFormats from './scannerFormats';

const Scan = ({ setPageName, setProductID, qs, recommenderMenuOpen, setRecommenderMenuOpen }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [value, setValue] = useState('');
  const [scanning, setScanning] = useState(false);
  const [qualityStationImage, setQualityStationImage] = useState('');
  const [startClickReady, setStartClickReady] = useState(false); // New state
  const qualityStation = qs || queryParameters.get('qualityStationName');
  const qualityStationImageParam = queryParameters.get('qualityStationImage');
  const qualityStationregexPattern = queryParameters.get('qualityStationRegexPattern');
  const qualityStationDefectPhotoRequired = queryParameters.get('defectPhotoRequired');

  useEffect(() => {
    setPageName(t('scan'));
    if (!qualityStationImageParam) {
      fetchQualityStationImage();
    } else {
      setQualityStationImage(qualityStationImageParam);
    }
  }, [qualityStationImageParam, t, setPageName]);

  const fetchQualityStationImage = useCallback(async () => {
    try {
      const response = await axiosClient.post(getQualityStationImageEndpoint, { qualityStation });
      setQualityStationImage(response.data.image);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [qualityStation]);

  const isValid = (str) => /^[a-zA-Z0-9_-]+$/.test(str);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (value && isValid(value)) {
        document.getElementById('link').click();
      } else {
        enqueueSnackbar(t('invalidProductID'), { variant: 'warning' });
      }
    }
  };

  const isNull = (str) => str === null || str === undefined || str === '' || str === 'null';

  const handleScan = (detectedCodes) => {
    if (detectedCodes.length > 0) {
      const detectedValue = detectedCodes[0].rawValue;
      if (isNull(qualityStationregexPattern)) {
        setValue(detectedValue);
        setScanning(false);
        setStartClickReady(true);
      } else {
        try {
          const regex = new RegExp(qualityStationregexPattern);
          if (regex.test(detectedValue)) {
            setValue(detectedValue);
            setScanning(false);
            setStartClickReady(true);
          } else {
            enqueueSnackbar(t('invalidProductIDregExPattern'), { variant: 'error' });
          }
        } catch (e) {
          enqueueSnackbar(t('invalidProductIDregExPattern'), { variant: 'error' });
        }
      }
    }
  };

  useEffect(() => {
    if (startClickReady) {
      handleStartClick();
      setStartClickReady(false); // Reset the flag
    }
  }, [startClickReady]); // Depend on startClickReady

  const handleStartClick = () => {
    const goToPage = () => {
      const url = encodeURI(
        `/intelligent_testing/recommender/swipe?qualityStation=${qualityStation}&productID=${value}&regexPattern=${qualityStationregexPattern}&defectPhotoRequired=${qualityStationDefectPhotoRequired}`
      );
      navigate(url);
    };

    try {
      const regex = new RegExp(qualityStationregexPattern);
      if (isNull(qualityStationregexPattern) || regex.test(value)) {
        goToPage();
      } else {
        enqueueSnackbar(t('invalidProductIDPattern'), { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar(t('invalidProductIDPattern'), { variant: 'error' });
    }
  };

  const handleCancelClick = () => {
    navigate('/intelligent_testing/recommender/select');
  };

  return (
    <>
      {recommenderMenuOpen ? (
        <div className="scan-layout">
          <div className="scan-upper-bar">
            <div className="scan-upper-bar-content">
              <div className="scan-upper-bar-text">{t('startNewTest')}...</div>
              <img className="image" alt="Layer" src="/images/qualitatio.svg" />
            </div>
          </div>
          <div className="scan-product">
            <div className="scan-product-content">
              {scanning ? (
                <Box className="scanner-container">
                  <Scanner
                    onScan={handleScan}
                    components={{
                      audio: true,
                      torch: true,
                      zoom: true,
                      finder: true,
                      tracker: centerText,
                    }}
                    formats={scannerFormats}
                    allowMultiple
                    scanDelay={2000}
                  />
                </Box>
              ) : (
                <div className="scan-product-input">
                  <QualitatioButton
                    text={qualityStation}
                    disabled
                    startIcon={<img src={qualityStationImage} alt={qualityStation} />}
                    startIconIsImage
                    startIconRounded
                    endIcon={<ArrowForwardIosRoundedIcon />}
                    width="100%"
                  />
                  <TextField
                    label={`${t('enterProductID')}*`}
                    type="text"
                    placeholder={`${t('enterProductID')}...`}
                    fullWidth
                    onChange={(e) => setValue(e.currentTarget.value)}
                    onKeyDown={handleKeyPress}
                    value={value}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setScanning(true)}>
                            <QrCodeScannerIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="scan-product-buttons">
                <QualitatioButton
                  text={t('startTest')}
                  id="link"
                  order="primary"
                  fullWidth
                  height="5vh"
                  fontSize="calc(12px + 0.25vw)"
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  disabled={!value || !isValid(value)}
                  onClick={handleStartClick}
                />
                <QualitatioButton
                  text={t('cancel')}
                  order="secondary"
                  fullWidth
                  height="5vh"
                  fontSize="calc(12px + 0.25vw)"
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                  onClick={handleCancelClick}
                />
              </div>
            </div>
          </div>
          <div className="scan-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
};

Scan.propTypes = {
  setPageName: PropTypes.func.isRequired,
  setProductID: PropTypes.func,
  qs: PropTypes.string,
  recommenderMenuOpen: PropTypes.bool.isRequired,
  setRecommenderMenuOpen: PropTypes.func.isRequired,
};

export default Scan;
