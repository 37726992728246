import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchUser } from '../../../api/user';
import ROLES from '../../../authentication/roleConst';
import { QualitatioLanguageSelector } from '../../../components';
import { useAuthStore } from '../../../store/auth.store';
import { getUserInitialValues } from '../../../utils/initFormikValues';
import { capitalizeFirstLetter } from '../../../utils/utilities';
import { getUserValidationSchema } from '../../../utils/validationSchemas';
import DeleteUserOverlay from './DeleteUserOverlay';

export default function UserEditOverlay({ user: selectedUser, onClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { user, updateUser } = useAuthStore((state) => ({
    user: state.user,
    updateUser: state.fetchUserStatus,
  }));
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: getUserInitialValues(selectedUser),
    validationSchema: getUserValidationSchema(i18n.language),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isLDAP = formik.values.authSystem === 'ldap';
  const isUserItself = selectedUser.id === user.id;

  const handleSubmit = async (values) => {
    const { password, ...payload } = values;
    if (password !== '') {
      payload.password = password;
    }
    // remove profileImage if it is empty
    if (payload.profileImage === '') {
      delete payload.profileImage;
    }
    const result = await patchUser(selectedUser.id, payload);
    if (result && result.error) {
      enqueueSnackbar(t('userUpdateFailedSnack'), { variant: 'error' });
    } else {
      enqueueSnackbar(t('userUpdatedSnack'), { variant: 'success' });
      //update user in context
      updateUser();
      onClose();
    }
  };

  const handleDeleteUser = () => {
    console.log('handleDeleteUser');
    setShowDeleteConfirm(true);
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} variant="qualitatio">
        <DialogTitle>{t('editUserTitle')}</DialogTitle>
        <Typography variant="subtitle1" align="center">
          {t('editUserDescription')}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {isLDAP ? (
                  <TextField disabled value={formik.values.name} fullWidth label={t('name')} />
                ) : (
                  <TextField
                    label={t('name')}
                    placeholder={t('name')}
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CreateOutlinedIcon
                            color={
                              formik.touched.name && Boolean(formik.errors.name)
                                ? 'error'
                                : 'primary'
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {isLDAP ? (
                  <TextField disabled value={formik.values.email} fullWidth label={t('email')} />
                ) : (
                  <TextField
                    label={t('email')}
                    placeholder={t('email')}
                    {...formik.getFieldProps('email')}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CreateOutlinedIcon
                            color={
                              formik.touched.name && Boolean(formik.errors.name)
                                ? 'error'
                                : 'primary'
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>

              {!isLDAP && (
                <Grid item xs={12}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter new password"
                    {...formik.getFieldProps('password')}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Autocomplete
                  disabled={isUserItself || isLDAP}
                  /* variant="qualitatio" */
                  options={Object.values(ROLES)}
                  getOptionLabel={(option) => capitalizeFirstLetter(option)}
                  value={formik.values.role}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('role', newValue);
                  }}
                  onBlur={() => formik.setFieldTouched('role')}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('role')}
                      label={t('role')}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.touched.role && formik.errors.role}
                      fullWidth
                    />
                  )}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled
                  value={formik.values.authSystem}
                  fullWidth
                  label={t('authSystem')}
                />
              </Grid>

              <Grid item xs={12}>
                <QualitatioLanguageSelector
                  value={formik.values.language || ''}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('language', newValue);
                  }}
                  onBlur={() => formik.setFieldTouched('language')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="qualitatio"
              color="primary"
              endIcon={<ArrowForwardIosIcon />}
            >
              {t('save')}
            </Button>
            <Button
              onClick={onClose}
              variant="qualitatio"
              color="secondary"
              startIcon={<ArrowBackIosNewRoundedIcon />}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Delete Confirmation Dialog
       */}

      <DeleteUserOverlay
        selectedUser={selectedUser}
        onClose={() => setShowDeleteConfirm(false)}
        showDeleteConfirm={showDeleteConfirm}
        reload={() => {}}
      />
    </>
  );
}
