import * as Yup from 'yup';
import axiosClient from '../../../api/axiosClient';
import {
  createSimpleRuleEndpoint,
  getETQualityStationsEndpoint,
  getETStreamFieldsEndpoint,
  getHighLevelDescriptionEndpoint,
  getNumberOfApplicationsEndpoint,
  getRulesTableDataEndpoint,
  getStreamFieldValuesForSidebarEndpoint,
  ruleEndpoint,
} from '../../../api/endpoints';

export const getStreamFields = async () => {
  try {
    const response = await axiosClient.get(getETStreamFieldsEndpoint);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getQualityStations = async () => {
  try {
    const response = await axiosClient.get(getETQualityStationsEndpoint);
    console.log('response:', JSON.stringify(response));
    return response.data.qualityStations;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getHighLevelDescription = async (rule) => {
  console.log('getHighLevelDescription:', JSON.stringify(rule));
  try {
    const response = await axiosClient.get(getHighLevelDescriptionEndpoint, {
      headers: {
        rule: JSON.stringify(rule),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getNumberOfApplications = async (rule, timeInterval, isPercent) => {
  try {
    const response = await axiosClient.get(getNumberOfApplicationsEndpoint, {
      headers: {
        rule: JSON.stringify(rule),
        timeinterval: timeInterval,
        percent: JSON.stringify(isPercent),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getStreamFieldValuesForSidebar = async (rule) => {
  try {
    const response = await axiosClient.get(getStreamFieldValuesForSidebarEndpoint, {
      headers: {
        rule: JSON.stringify(rule),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const createSimpleRule = async (rule) => {
  try {
    const response = await axiosClient.post(createSimpleRuleEndpoint, {
      rule,
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getRulesTableData = async () => {
  try {
    const response = await axiosClient.get(getRulesTableDataEndpoint);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getRuleData = async (ruleID) => {
  console.log('getRuleData:', ruleID);
  try {
    const response = await axiosClient.get(ruleEndpoint, {
      headers: {
        ruleid: ruleID,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const updateSimpleRule = async (ruleID, rule) => {
  try {
    const response = await axiosClient.patch(`${ruleEndpoint}/${ruleID}`, {
      rule,
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deleteRule = async (ruleID) => {
  try {
    const response = await axiosClient.delete(ruleEndpoint, {
      data: {
        ruleID,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const activateRule = async (ruleID) => {
  try {
    const response = await axiosClient.patch(`${ruleEndpoint}/${ruleID}/activate`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const deactivateRule = async (ruleID) => {
  try {
    const response = await axiosClient.patch(`${ruleEndpoint}/${ruleID}/deactivate`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Define schema for valueCombinations
const valueCombinationSchema = Yup.object().shape({
  combinationOrderNumber: Yup.number().required(),
  combinationOperator: Yup.string().when('combinationOrderNumber', {
    is: (val) => val > 0,
    then: () =>
      Yup.string().oneOf(['+', '-', '*', '/']).required('Combination Operator is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  aggregationFunction: Yup.string().oneOf([
    'avg',
    'count',
    'dc',
    'max',
    'median',
    'min',
    'mode',
    'perc',
    'range',
    'stdev',
    'sum',
    'var',
    '',
    null,
  ]),
  aggregationColumn: Yup.object().when('aggregationFunction', {
    is: (val) => val && val.trim() !== '',
    then: () =>
      Yup.object().shape({
        column: Yup.string().required('Aggregation Column is required'),
        columnType: Yup.string().required('Column Type is required'),
        indexName: Yup.string().required('Index Name is required'),
      }),
    otherwise: () => Yup.object().notRequired(),
  }),
  value: Yup.mixed().test('value-required', 'Value is required', function (value) {
    const { aggregationFunction } = this.parent; // Access the parent context

    if (!aggregationFunction || aggregationFunction === 'perc') {
      // When aggregationFunction is empty or 'perc', value is required
      if (aggregationFunction === 'perc') {
        // Check if value is a number between 1 and 100 for 'perc'
        return isNaN(value) || value < 1 || value > 100 ? false : true;
      } else {
        return value === undefined || value === null || value === '' ? false : true;
      }
    }
    return true; // If aggregationFunction is something else, value is not required
  }),
  executionOrderNumber: Yup.number().required('Execution Order Number is required'),
});

// Define schema for mainCondition
const mainConditionSchema = Yup.object().shape({
  conditionOrderNumber: Yup.number().required(),
  logicalOperator: Yup.string().when('conditionOrderNumber', {
    is: (val) => val > 0,
    then: () =>
      Yup.string()
        .oneOf(['AND', 'OR'])
        .required('Logical Operator is required for non-first conditions'),
    otherwise: () => Yup.string().notRequired(),
  }),
  columnOperator: Yup.string().when('columns', {
    is: (cols) => cols && cols.length > 1,
    then: () =>
      Yup.string()
        .oneOf(['+', '-', '*', '/'])
        .required('Column Operator is required when multiple columns are selected'),
    otherwise: () => Yup.string().notRequired(),
  }),
  columns: Yup.array()
    .of(
      Yup.object().shape({
        column: Yup.string().required(),
        columnType: Yup.string().required(),
        indexName: Yup.string().required(),
      })
    )
    .min(1, 'At least one column must be selected'),
  operator: Yup.string().when('columns', {
    is: (cols) => cols && cols.every((col) => col.columnType === 'number'),
    then: () =>
      Yup.string().oneOf(['=', '!=', '>', '<', '>=', '<=']).required('Operator is required'),
    otherwise: () => Yup.string().oneOf(['=', '!=']).required('Operator is required'),
  }),
  valueCombinations: Yup.array()
    .of(valueCombinationSchema)
    .min(1, 'At least one value combination is required')
    .test('unique-execution-order', 'Execution Order Numbers must be unique', function (value) {
      console.log('value:', JSON.stringify(value));
      const executionOrders = value.map((vc) => vc.executionOrderNumber);
      return new Set(executionOrders).size === executionOrders.length;
    }),
});

// Define the overall validation schema for creating a rule
export const fullValidationSchema = Yup.object().shape({
  creator: Yup.string().required(),
  expirationDate: Yup.date().min(new Date(), 'Expiration Date must be in the future').required(),
  ruleName: Yup.string().required('Rule Name is required'),
  ruleType: Yup.string().oneOf(['simple', 'complex']).required('Rule Type is required'),
  ruleDetails: Yup.object().shape({
    mainCondition: Yup.array()
      .of(mainConditionSchema)
      .min(1, 'At least one main condition is required'),
    actionFrequency: Yup.object().shape({
      type: Yup.string().oneOf(['product', 'time']).required('Action Frequency Type is required'),
      frequency: Yup.number()
        .min(1, 'Frequency must be at least 1')
        .required('Frequency is required'),
      timeReference: Yup.object().when('type', {
        is: (val) => val === 'time',
        then: () =>
          Yup.object().shape({
            timeValue: Yup.number()
              .min(1, 'Time Value must be at least 1')
              .required('Time Value is required'),
            timeUnit: Yup.string()
              .oneOf(['s', 'm', 'h', 'd', 'w'])
              .required('Time Unit is required'),
          }),
        otherwise: () => Yup.object().notRequired(),
      }),
    }),
    aggregationRange: Yup.object().shape({
      type: Yup.string()
        .oneOf(['time', 'product', '', null])
        .notRequired('Aggregation Range Type is required'),
      range: Yup.number().when('type', {
        is: (val) => val && val.trim() !== '',
        then: () =>
          Yup.number()
            .typeError('Range must be a number')
            .min(1, 'Range must be at least 1')
            .required('Range is required'),
        otherwise: () => Yup.mixed().notRequired(),
      }),
      timeUnit: Yup.string().when('type', {
        is: (val) => val === 'time',
        then: () => Yup.string().oneOf(['s', 'm', 'h', 'd', 'w']).required('Time Unit is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      groundTruthStreamIndex: Yup.string().notRequired(),
    }),
    highLevelDescription: Yup.string(),
  }),
  qualityStation: Yup.string().required('Quality Station is required'),
  applicationMode: Yup.string().oneOf(['test', 'skip']).required('Application Mode is required'),
});

// Define the validation schema for the high level description
export const highLevelDescriptionValidationSchema = Yup.object().shape({
  ruleDetails: Yup.object().shape({
    mainCondition: Yup.array()
      .of(mainConditionSchema)
      .min(1, 'At least one main condition is required'),
  }),
});
