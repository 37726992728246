import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import BadgeIcon from '@mui/icons-material/Badge'; // Assuming Badge icon for Role
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../utils/utilities';
import './RequestsOverlay.css';

export default function RequestsOverlay({ onClose, requests, handleAction }) {
  const [dismissedRequest, setDismissedRequest] = useState(null);
  const [visibleRequests, setVisibleRequests] = React.useState(requests);

  const { t } = useTranslation();

  const handleRequestAction = (requestId, isAccepted) => {
    setDismissedRequest({ id: requestId, direction: isAccepted ? 'left' : 'right' });

    setTimeout(() => {
      handleAction(requestId, isAccepted); // Your original logic

      // Remove the request from the local state after the animation
      const updatedRequests = visibleRequests.filter((req) => req.id !== requestId);
      setVisibleRequests(updatedRequests);

      setDismissedRequest(null); // Reset
    }, 600);
  };

  return (
    <Dialog open={true} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t('registerRequests')}</DialogTitle>
      <DialogContent>
        <List className="request-list">
          {visibleRequests.map((request) => (
            <ListItem key={request.id}>
              <Card
                variant="qualitatio"
                className={
                  dismissedRequest?.id === request.id
                    ? dismissedRequest.direction === 'left'
                      ? 'card-fly-left'
                      : 'card-fly-right'
                    : ''
                }
              >
                <Grid container spacing={2} className="register-requests">
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body1">
                        <PersonIcon fontSize="small" /> <strong>{t('name')}:</strong> {request.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body1">
                        <EmailIcon fontSize="small" /> <strong>{t('email')}:</strong>{' '}
                        {request.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body1">
                        <BadgeIcon fontSize="small" /> <strong>{t('role')}:</strong>{' '}
                        {capitalizeFirstLetter(request.role)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} container justifyContent="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Tooltip title={t('acceptRegistrationTooltip')} placement="bottom" arrow>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleRequestAction(request.id, true)}
                          fullWidth
                          startIcon={<CheckIcon />}
                        >
                          {t('accept')}
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Tooltip title={t('declineRegistrationTooltip')} placement="bottom" arrow>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleRequestAction(request.id, false)}
                          fullWidth
                          startIcon={<CloseIcon />}
                        >
                          {t('deny')}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </ListItem>
          ))}
          {visibleRequests.length === 0 && (
            <Typography variant="body1" className="no-requests">
              {t('noRequestsToShow')}
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="qualitatio"
          onClick={onClose}
          color="secondary"
          startIcon={<ArrowBackIosNewRoundedIcon />}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
