import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TestCardWrapper from '../../../testCards/TestCardWrapper';

const RuleTestCard = ({
  matchingCards,
  rules,
  ruleTests,
  setRuleTests,
  currentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  qualityStation,
  orderIdentifierMapping,
  restoreChange,
  handlePreviousCard,
  handleNextCard,
  handleDeleteCard,
  handleAddCard,
  handleSaveCard,
}) => {
  const { t } = useTranslation();

  const setCardField = (field, value, modifier = null) => {
    setRuleTests((prevState) =>
      prevState.map((card) => {
        if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
          const cardIndexInSubarray = ruleTests
            .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
            .indexOf(card);
          if (cardIndexInSubarray === currentCardIndex) {
            const updatedProperty = modifier ? modifier(card[field], value) : value;
            return { ...card, [field]: updatedProperty };
          }
        }
        return card;
      })
    );
  };

  return (
    <TestCardWrapper
      cardsToDisplay={matchingCards}
      cardType={'rules'}
      currentCardIndex={currentCardIndex}
      setCurrentCardIndex={setCurrentCardIndex}
      testCardProps={{
        editable: true,
        qualityStation,
        orderIdentifierMapping,
        restoreChange,
        index: currentCardIndex,
        metaIndex: currentIndex,
        generation: t('Rule'),
        explanation: matchingCards[currentCardIndex]?.rule.ruleName,
      }}
      setCardField={setCardField}
      handlePreviousCard={handlePreviousCard}
      handleNextCard={handleNextCard}
      handleAddCard={handleAddCard}
      handleDeleteCard={handleDeleteCard}
      handleSaveCard={handleSaveCard}
      isAddCardDisabled={!rules[currentIndex]}
      isCopilotButtonPresent={false} // TODO: consider adding copilot option for rule based cards
    />
  );
};

RuleTestCard.propTypes = {
  matchingCards: PropTypes.array.isRequired,
  rules: PropTypes.array.isRequired,
  setRules: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  qualityStation: PropTypes.object.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  restoreChange: PropTypes.func.isRequired,
  handlePreviousCard: PropTypes.func.isRequired,
  handleNextCard: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  handleSaveCard: PropTypes.func.isRequired,
};

export default RuleTestCard;
