import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { MuiChipsInput } from 'mui-chips-input';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { connectGroupsEndpoint, getLDAPGroupsForRoleEndpoint } from '../../../api/endpoints';
import ROLES from '../../../authentication/roleConst';
import { capitalizeFirstLetter } from '../../../utils/utilities';
import { getConnectGroupsValidationSchema } from '../../../utils/validationSchemas';

function initialRoleMapping() {
  const mapping = {};
  for (const role in ROLES) {
    mapping[ROLES[role]] = [];
  }
  return mapping;
}

export default function UserEditOverlay({ onClose }) {
  const [roleMapping, setRoleMapping] = useState(initialRoleMapping());
  const [selectedRole, setSelectedRole] = useState();
  const [groups, setGroups] = useState([]);
  const [notEnteredString, setNotEnteredString] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const handleGroupChange = (newGroups) => {
    setGroups(newGroups);
    formik.setFieldValue('groups', newGroups);

    //add new groups to the roleMapping
    setRoleMapping((prevMapping) => ({
      ...prevMapping,
      [selectedRole]: newGroups,
    }));
  };

  const handleInputChange = (event) => {
    setNotEnteredString(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      role: '',
      groups: [],
    },
    validationSchema: getConnectGroupsValidationSchema(i18n.language),
    onSubmit: (values) => {
      // Make a copy of roleMapping so we can potentially modify it without
      // affecting state directly.
      let updatedRoleMapping = { ...roleMapping };

      // If there's a notEnteredString and a role is selected, add it to the mapping.
      if (
        notEnteredString &&
        selectedRole &&
        !updatedRoleMapping[selectedRole].includes(notEnteredString)
      ) {
        updatedRoleMapping = {
          ...updatedRoleMapping,
          [selectedRole]: [...updatedRoleMapping[selectedRole], notEnteredString],
        };
      }

      // Generate an array of promises for each role and send the requests.
      const promises = Object.values(ROLES).map((role) => {
        return axiosClient.post(connectGroupsEndpoint, {
          systemRole: role,
          ldapRoles: updatedRoleMapping[role],
        });
      });

      // Use Promise.all to wait for all promises to resolve, then handle their results.
      Promise.allSettled(promises)
        .then((results) => {
          const allSucceeded = results.every((result) => result.status === 'fulfilled');

          if (allSucceeded) {
            enqueueSnackbar(t('roleMappingsSuccessSnack'), { variant: 'success' });
          } else {
            enqueueSnackbar(t('roleMappingsErrorSnack'), { variant: 'error' });
          }
          onClose();
        })
        .catch((error) => {
          console.error('Unexpected error:', error);
          enqueueSnackbar(t('roleMappingsErrorSnack'), { variant: 'error' });
          onClose();
        });
    },
  });

  useEffect(() => {
    const fetchLDAPGroupsForRole = async (role) => {
      const response = await axiosClient.get(`${getLDAPGroupsForRoleEndpoint}/${role}`);
      return { [role]: response.data.ldapRoles }; // Extract ldapRoles from the response data
    };

    const fetchAllLDAPGroups = async () => {
      const allRoles = Object.values(ROLES);

      const allPromises = allRoles.map(fetchLDAPGroupsForRole);

      try {
        const allLdapRoles = await Promise.all(allPromises);

        // Convert array of objects to a single object
        const ldapRoles = Object.assign({}, ...allLdapRoles);

        // Update roleMapping state with the fetched ldapRoles
        setRoleMapping((prevMapping) => ({
          ...prevMapping,
          ...ldapRoles,
        }));
      } catch (error) {
        console.error('Error fetching LDAP groups for roles', error);
        enqueueSnackbar(t('errorFetchingRoleMappingsSnack'), { variant: 'error' });
      }
    };

    fetchAllLDAPGroups();
  }, []);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setGroups(roleMapping[e.target.value]);
    formik.setFieldValue('role', e.target.value);
    formik.setFieldValue('groups', roleMapping[e.target.value]);
  };

  return (
    <Dialog open={true} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t('manageRoleMappings')}</DialogTitle>
      <Typography variant="subtitle1" align="center">
        {t('manageRoleMappingsDescription')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                error={formik.touched.role && !!formik.errors.role}
                variant="outlined"
                fullWidth
              >
                <InputLabel id="role-label">{t('roleInQualitatio')}</InputLabel>
                <Select
                  id="role"
                  labelId="role-label"
                  value={selectedRole}
                  onChange={handleRoleChange}
                  label={t('roleInQualitatio')}
                >
                  {Object.values(ROLES).map((role) => (
                    <MenuItem key={role} value={role}>
                      {capitalizeFirstLetter(role)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formik.errors.role}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {selectedRole && (
                <FormControl
                  error={formik.touched.groups && !!formik.errors.groups}
                  variant="outlined"
                  fullWidth
                >
                  <MuiChipsInput
                    value={groups}
                    onChange={handleGroupChange}
                    inputProps={{
                      onChange: handleInputChange,
                    }}
                    label={t('roleInConnectedSystem')}
                    placeholder={t('enterGroupAndPressEnter')}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="qualitatio"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('save')}
          </Button>
          <Button
            onClick={onClose}
            variant="qualitatio"
            color="secondary"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
