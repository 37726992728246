import Rule from '@mui/icons-material/Rule';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../components/ConfirmationOverlay';
import PageFrame from '../../components/PageFrame';
import ETActionButtons from './components/ETActionButtons';
import ETRuleTable from './components/ETRuleTable';
import CreateRuleModal from './components/createRuleModal/CreateRuleModal';
import { activateRule, deactivateRule, deleteRule } from './components/utils';

export default function ETRules({ setPageName }) {
  setPageName('ruleManagement');
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const ruleTableRef = useRef();

  // **Dialog State Variables**
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRuleData, setCurrentRuleData] = useState(null);

  // **Selected Rows**
  const [selectedRuleIds, setSelectedRuleIds] = useState([]);

  // **Confirmation Dialog State**
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteAction, setDeleteAction] = useState(''); // 'single' or 'multiple'
  const [rulesToDelete, setRulesToDelete] = useState([]);

  // **Callback to Refresh Table Data**
  const handleRefreshRules = () => {
    if (ruleTableRef.current && typeof ruleTableRef.current.refreshData === 'function') {
      ruleTableRef.current.refreshData();
    }
  };

  // **Function to Open Create Dialog**
  const openCreateDialog = () => {
    setIsEditMode(false);
    setCurrentRuleData(null);
    setIsDialogOpen(true);
  };

  // **Function to Open Edit Dialog with Rule Data**
  const openEditDialog = (ruleData) => {
    setIsEditMode(true);
    setCurrentRuleData(ruleData);
    setIsDialogOpen(true);
  };

  // **Function to Close Dialog**
  const closeDialog = (_, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsDialogOpen(false);
    setCurrentRuleData(null);
    setIsEditMode(false);
  };

  // **Function to Handle Selection Change from ETRuleTable**
  const handleSelectionChange = (newSelection) => {
    setSelectedRuleIds(newSelection);
  };

  // **Function to Handle Single Delete**
  const handleDeleteSingleRule = (id) => {
    setDeleteAction('single');
    setRulesToDelete([id]);
    setDeleteDialogOpen(true);
  };

  // **Function to Handle Multiple Actions**
  const handleApplyAction = (action) => {
    if (selectedRuleIds.length === 0) {
      enqueueSnackbar(t('noRulesSelected'), { variant: 'warning' });
      return;
    }

    if (action === 'delete') {
      setDeleteAction('multiple');
      setRulesToDelete([...selectedRuleIds]);
      setDeleteDialogOpen(true);
    } else if (action === 'activate') {
      activateMultipleRules(selectedRuleIds);
    } else if (action === 'deactivate') {
      deactivateMultipleRules(selectedRuleIds);
    }
  };

  // **Function to Activate Multiple Rules**
  const activateMultipleRules = async (ids) => {
    try {
      await Promise.all(ids.map((id) => activateRule(id)));
      enqueueSnackbar(t('rulesActivated'), { variant: 'success' });
      handleRefreshRules();
      setSelectedRuleIds([]);
    } catch (error) {
      console.error('Error activating rules:', error);
      enqueueSnackbar(t('errorActivatingRules'), { variant: 'error' });
    }
  };

  // **Function to Deactivate Multiple Rules**
  const deactivateMultipleRules = async (ids) => {
    try {
      await Promise.all(ids.map((id) => deactivateRule(id)));
      enqueueSnackbar(t('rulesDeactivated'), { variant: 'success' });
      handleRefreshRules();
      setSelectedRuleIds([]);
    } catch (error) {
      console.error('Error deactivating rules:', error);
      enqueueSnackbar(t('errorDeactivatingRules'), { variant: 'error' });
    }
  };

  // **Function to Confirm Delete**
  const handleDeleteConfirm = async () => {
    if (deleteAction === 'single') {
      // Single delete
      const id = rulesToDelete[0];
      try {
        await deleteRule(id);
        enqueueSnackbar(t('ruleDeleted'), { variant: 'success' });
        handleRefreshRules();
        setSelectedRuleIds(selectedRuleIds.filter((ruleId) => ruleId !== id));
      } catch (error) {
        console.error('Error deleting rule:', error);
        enqueueSnackbar(t('errorDeletingRule'), { variant: 'error' });
      }
    } else if (deleteAction === 'multiple') {
      // Multiple delete
      try {
        await Promise.all(rulesToDelete.map((id) => deleteRule(id)));
        enqueueSnackbar(t('rulesDeleted'), { variant: 'success' });
        handleRefreshRules();
        setSelectedRuleIds([]);
      } catch (error) {
        console.error('Error deleting rules:', error);
        enqueueSnackbar(t('errorDeletingRules'), { variant: 'error' });
      }
    }

    setDeleteDialogOpen(false);
    setRulesToDelete([]);
    setDeleteAction('');
  };

  // **Function to Cancel Delete**
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setRulesToDelete([]);
    setDeleteAction('');
  };

  // **Define deleteDialogProps**
  const deleteDialogProps = {
    open: deleteDialogOpen,
    onCancel: handleDeleteCancel,
    onConfirm: handleDeleteConfirm,
    title: t('confirmDeletion'),
    content:
      deleteAction === 'single' ? t('confirmRuleDeletionText') : t('confirmRulesDeletionText'),
    confirmButtonText: t('delete'),
    cancelButtonText: t('cancel'),
  };

  return (
    <PageFrame title={t('ruleManagement')} Icon={Rule}>
      <Grid container spacing={2} display={'flex'} justifyContent={'center'} direction={'column'}>
        <ETActionButtons
          onCreateRule={openCreateDialog}
          onApplyAction={handleApplyAction}
          selectedRuleIds={selectedRuleIds}
        />
        <ETRuleTable
          ref={ruleTableRef}
          onEditRule={openEditDialog}
          onDeleteRule={handleDeleteSingleRule}
          onSelectionModelChange={handleSelectionChange}
          selectedRuleIds={selectedRuleIds}
        />
        <CreateRuleModal
          open={isDialogOpen}
          onClose={closeDialog}
          isEditMode={isEditMode}
          initialData={currentRuleData}
          onRuleCreated={handleRefreshRules}
        />
        <ConfirmationDialog {...deleteDialogProps} />
      </Grid>
    </PageFrame>
  );
}

ETRules.propTypes = {
  setPageName: PropTypes.func.isRequired,
};
