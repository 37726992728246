import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const operators = [
  { value: '=', types: ['number', 'string'] },
  { value: '!=', types: ['number', 'string'] },
  { value: '>', types: ['number'] },
  { value: '<', types: ['number'] },
  { value: '>=', types: ['number'] },
  { value: '<=', types: ['number'] },
];
const columnOperations = ['+', '-', '*', '/'];
const combinationOperations = ['+', '-', '*', '/'];
const aggregationFunctions = [
  { value: 'avg', types: ['number'], label: 'Average' },
  { value: 'count', types: ['number'], label: 'Count' },
  { value: 'dc', types: ['number'], label: 'DistinctCount' },
  { value: 'max', types: ['number'], label: 'Max' },
  { value: 'median', types: ['number'], label: 'Median' },
  { value: 'min', types: ['number'], label: 'Min' },
  { value: 'mode', types: ['number', 'string'], label: 'Mode' },
  { value: 'perc', types: ['number'], label: 'Percentile' },
  { value: 'range', types: ['number'], label: 'Range' },
  { value: 'stdev', types: ['number'], label: 'StandardDeviation' },
  { value: 'sum', types: ['number'], label: 'Sum' },
  { value: 'var', types: ['number'], label: 'Variance' },
];
const logicalOperators = ['AND', 'OR'];

function MainCondition({ streamFields, sidebarOpen }) {
  const { t } = useTranslation();
  const { values, setFieldValue, touched, errors } = useFormikContext();

  // Initialize selectOpen state for each mainCondition
  const [selectOpen, setSelectOpen] = useState(values.ruleDetails.mainCondition.map(() => false));

  // Initialize localInputValue based on mainCondition
  const initializeLocalInputValue = (mainCondition) => {
    return mainCondition.map((condition) =>
      condition.valueCombinations.map((combo) => combo.value || '')
    );
  };
  const [localInputValue, setLocalInputValue] = useState(
    initializeLocalInputValue(values.ruleDetails.mainCondition)
  );

  // Debounced function to update Formik's state
  const debouncedSetFieldValue = useMemo(
    () =>
      _.debounce((conditionIndex, comboIndex, value) => {
        setFieldValue(
          `ruleDetails.mainCondition.${conditionIndex}.valueCombinations.${comboIndex}.value`,
          value
        );
      }, 500),
    [setFieldValue]
  );

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSetFieldValue.cancel();
    };
  }, [debouncedSetFieldValue]);

  // Handle input changes in Autocomplete with debouncing
  const handleInputChange = (conditionIndex, comboIndex, newInputValue) => {
    setLocalInputValue((prevState) => {
      const updatedState = [...prevState];
      updatedState[conditionIndex][comboIndex] = newInputValue;
      return updatedState;
    });
    debouncedSetFieldValue(conditionIndex, comboIndex, newInputValue);
  };

  // Helper to get options for Autocomplete based on selected columns
  const getAutocompleteOptions = (columns) => {
    if (!columns || columns.length === 0) return [];
    const firstColumn = columns[0].column;
    const field = streamFields.find((f) => f.name === firstColumn);
    return field?.examples || [];
  };

  // Group streamFields by indexName for multi-select
  const childrenOfMultiSelect = useMemo(() => {
    return streamFields.reduce((acc, field) => {
      if (!acc[field.indexName]) {
        acc[field.indexName] = [];
      }
      acc[field.indexName].push(field);
      return acc;
    }, {});
  }, [streamFields]);

  // Update selectOpen state when mainCondition length changes
  const updateSelectOpenState = (newLength) => {
    setSelectOpen((prevState) => {
      const updatedState = [...prevState];
      while (updatedState.length < newLength) {
        updatedState.push(false);
      }
      while (updatedState.length > newLength) {
        updatedState.pop();
      }
      return updatedState;
    });
  };

  // Synchronize localInputValue when mainCondition changes structure
  useEffect(() => {
    const updatedLocalInputValue = initializeLocalInputValue(values.ruleDetails.mainCondition);
    if (!_.isEqual(localInputValue, updatedLocalInputValue)) {
      setLocalInputValue(updatedLocalInputValue);
    }
  }, [values.ruleDetails.mainCondition]);

  const removeMainCondition = (index, values, setFieldValue) => {
    const updatedMainConditions = [...values.ruleDetails.mainCondition];

    if (updatedMainConditions.length === 1) {
      return;
    }

    // Remove the main condition at the given index
    updatedMainConditions.splice(index, 1);

    // Reorder the remaining main conditions by adjusting conditionOrderNumber
    updatedMainConditions.forEach((condition, i) => {
      condition.conditionOrderNumber = i;
      if (i === 0) {
        condition.logicalOperator = '';
      }
    });

    // Update the Formik field value
    setFieldValue('ruleDetails.mainCondition', updatedMainConditions);
  };

  const removeValueCombination = (mainConditionIndex, comboIndex, values, setFieldValue) => {
    const mainConditions = [...values.ruleDetails.mainCondition];
    const valueCombinations = [...mainConditions[mainConditionIndex].valueCombinations];

    // Remove the value combination at the given comboIndex
    const deletedExecutionOrder = valueCombinations[comboIndex].executionOrderNumber;
    valueCombinations.splice(comboIndex, 1);

    // Adjust combinationOrderNumber (by index)
    valueCombinations.forEach((combo, i) => {
      combo.combinationOrderNumber = i;
      if (i === 0) {
        combo.combinationOperator = '';
      }
    });

    // Adjust executionOrderNumber (by value, keeping custom order intact)
    valueCombinations.forEach((combo) => {
      if (combo.executionOrderNumber > deletedExecutionOrder) {
        combo.executionOrderNumber -= 1; // Decrement executionOrderNumber for combos with higher numbers
      }
    });

    // Update the valueCombinations array in the mainCondition object
    mainConditions[mainConditionIndex].valueCombinations = valueCombinations;

    // Update the Formik field value
    setFieldValue('ruleDetails.mainCondition', mainConditions);
  };

  return (
    <FieldArray
      name="ruleDetails.mainCondition"
      render={(arrayHelpers) => (
        <Grid
          item
          display={'flex'}
          direction={'column'}
          justifyContent={'center'}
          width={'100%'}
          gap={3}
        >
          {values.ruleDetails.mainCondition.map((condition, index) => (
            <Grid key={index} display={'flex'} direction={'column'} alignItems={'stretch'} gap={1}>
              {/* Main Condition Row */}
              <Grid display={'flex'} direction={'row'} alignItems={'center'}>
                {/* Delete Button */}
                <Grid
                  item
                  display={'flex'}
                  direction={'row'}
                  alignItems={'center'}
                  style={{ marginRight: '1rem' }}
                >
                  <IconButton
                    onClick={() => {
                      removeMainCondition(index, values, setFieldValue);
                      updateSelectOpenState(values.ruleDetails.mainCondition.length - 1);
                    }}
                    size="small"
                    disabled={values.ruleDetails.mainCondition.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>

                {/* Logical Operator */}
                {values.ruleDetails.mainCondition.length > 1 && (
                  <Grid item display={'flex'} direction={'row'} alignItems={'center'}>
                    <FormControl
                      variant="standard"
                      size="small"
                      sx={{
                        minWidth: '6vw',
                        marginRight: '1rem',
                      }}
                      error={Boolean(
                        touched.ruleDetails?.mainCondition?.[index]?.logicalOperator &&
                          errors.ruleDetails?.mainCondition?.[index]?.logicalOperator
                      )}
                    >
                      {index > 0 && (
                        <>
                          <Tooltip title={`${t('AND/OR')}*: ${condition.logicalOperator}`}>
                            <InputLabel>{`${t('AND/OR')}*`}</InputLabel>
                          </Tooltip>
                          <Select
                            variant="standard"
                            name={`ruleDetails.mainCondition.${index}.logicalOperator`}
                            value={condition.logicalOperator || ''}
                            onChange={(e) =>
                              setFieldValue(
                                `ruleDetails.mainCondition.${index}.logicalOperator`,
                                e.target.value
                              )
                            }
                            label={`${t('AND/OR')}*`}
                          >
                            <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                            {logicalOperators.map((op) => (
                              <MenuItem key={op} value={op}>
                                {op}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {/* Main Condition Inputs */}
                <Grid item display={'flex'} direction={'row'} alignItems={'center'} flexGrow={1}>
                  {/* Column Operation */}
                  <Collapse in={condition.columns.length > 1} timeout="auto" unmountOnExit>
                    <FormControl
                      variant="standard"
                      size="small"
                      sx={{
                        minWidth: sidebarOpen
                          ? condition.columns.length > 1 &&
                            values.ruleDetails.mainCondition.length > 1
                            ? '6vw'
                            : '9vw'
                          : '12vw',
                      }}
                      error={Boolean(
                        touched.ruleDetails?.mainCondition?.[index]?.columnOperator &&
                          errors.ruleDetails?.mainCondition?.[index]?.columnOperator
                      )}
                    >
                      <Tooltip title={`${t('ColumnOperation')}*: ${condition.columnOperator}`}>
                        <InputLabel>{`${t('ColumnOperation')}*`}</InputLabel>
                      </Tooltip>
                      <Select
                        variant="standard"
                        name={`ruleDetails.mainCondition.${index}.columnOperator`}
                        value={condition.columnOperator || ''}
                        onChange={(e) =>
                          setFieldValue(
                            `ruleDetails.mainCondition.${index}.columnOperator`,
                            e.target.value
                          )
                        }
                        label={`${t('ColumnOperation')}*`}
                      >
                        <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                        {columnOperations.map((op) => (
                          <MenuItem key={op} value={op}>
                            {op}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Collapse>

                  {/* Columns */}
                  <FormControl
                    variant="standard"
                    size="small"
                    style={{ width: sidebarOpen ? '12vw' : '16vw' }}
                    error={Boolean(
                      touched.ruleDetails?.mainCondition?.[index]?.columns &&
                        errors.ruleDetails?.mainCondition?.[index]?.columns
                    )}
                  >
                    <Tooltip
                      title={`${t('Columns')}*: ${condition.columns
                        .map((col) => col.column)
                        .join(', ')}`}
                    >
                      <InputLabel>{`${t('Columns')}*`}</InputLabel>
                    </Tooltip>
                    <Select
                      variant="standard"
                      multiple
                      open={selectOpen[index]}
                      onOpen={() =>
                        setSelectOpen((prevState) =>
                          prevState.map((state, idx) => (idx === index ? true : state))
                        )
                      }
                      onClose={() => setSelectOpen((prevState) => prevState.map((_) => false))}
                      name={`ruleDetails.mainCondition.${index}.columns`}
                      value={condition.columns.map((col) => `${col.column}|${col.indexName}`)} // Combine name and index for the value
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        // Update selected columns based on the selected values
                        const selectedColumns = value.map((combinedValue) => {
                          const [colName, colIndexName] = combinedValue.split('|');
                          const field = streamFields.find(
                            (f) => f.name === colName && f.indexName === colIndexName
                          );
                          return {
                            column: colName,
                            columnType: field?.type,
                            indexName: colIndexName,
                          };
                        });

                        const isAnySelectedColumnString = selectedColumns.some(
                          (col) => col.columnType === 'string'
                        );

                        const previouslySelectedType = condition.columns[0]?.columnType;
                        const newSelectedType = selectedColumns.length
                          ? selectedColumns[selectedColumns.length - 1]?.columnType
                          : null;

                        // Determine if type has changed
                        const hasTypeChanged = previouslySelectedType !== newSelectedType;

                        // Update columns in Formik
                        if (isAnySelectedColumnString) {
                          const latestSelectedColumn = selectedColumns[selectedColumns.length - 1];
                          setFieldValue(`ruleDetails.mainCondition.${index}.columns`, [
                            latestSelectedColumn,
                          ]); // Replace with the latest selection
                        } else {
                          // If the first column is a number, allow multiple numbers to be selected
                          setFieldValue(
                            `ruleDetails.mainCondition.${index}.columns`,
                            selectedColumns
                          );
                        }

                        // If type has changed, reset valueCombinations and localInputValue
                        if (hasTypeChanged) {
                          const newValueCombinations = {
                            combinationOrderNumber: 0,
                            value: '',
                            aggregationFunction: '',
                            aggregationColumn: {
                              column: '',
                              columnType: '',
                              indexName: '',
                            },
                            executionOrderNumber: 0,
                          };
                          setFieldValue(`ruleDetails.mainCondition.${index}.operator`, '');
                          setFieldValue(`ruleDetails.mainCondition.${index}.valueCombinations`, [
                            newValueCombinations,
                          ]);
                          setLocalInputValue((prev) => {
                            const updated = [...prev];
                            updated[index] = [''];
                            return updated;
                          });
                        }

                        // Close the Select dropdown
                        if (selectedColumns.length > 0) {
                          setSelectOpen((prevState) => prevState.map((_) => false));
                        }

                        // Reset columnOperator if less than 2 columns are selected
                        if (selectedColumns.length < 2) {
                          setFieldValue(`ruleDetails.mainCondition.${index}.columnOperator`, '');
                        }
                      }}
                      renderValue={(selected) =>
                        selected.map((combinedValue) => combinedValue.split('|')[0]).join(', ')
                      }
                      label={`${t('Columns')}*`}
                    >
                      {Object.keys(childrenOfMultiSelect).map((indexName) => [
                        <ListSubheader key={`${indexName}-header`}>{indexName}</ListSubheader>,
                        ...childrenOfMultiSelect[indexName].map((field) => (
                          <MenuItem
                            key={`${field.name}|${field.indexName}`}
                            value={`${field.name}|${field.indexName}`}
                            disabled={
                              condition.columns.length > 0 &&
                              condition.columns[0]?.columnType === 'number' &&
                              field.type === 'string'
                            }
                          >
                            <Checkbox
                              checked={condition.columns.some(
                                (col) =>
                                  col.column === field.name && col.indexName === field.indexName
                              )}
                            />
                            <ListItemText primary={field.name} />
                          </MenuItem>
                        )),
                      ])}
                    </Select>
                  </FormControl>

                  {/* Operator */}
                  <FormControl
                    variant="standard"
                    size="small"
                    sx={{
                      minWidth: sidebarOpen
                        ? condition.columns.length > 1 &&
                          values.ruleDetails.mainCondition.length > 1
                          ? '4vw'
                          : '6.5vw'
                        : '8vw',
                    }}
                    error={Boolean(
                      touched.ruleDetails?.mainCondition?.[index]?.operator &&
                        errors.ruleDetails?.mainCondition?.[index]?.operator
                    )}
                  >
                    <Tooltip title={`${t('Operator')}*: ${condition.operator}`}>
                      <InputLabel>{`${t('Operator')}*`}</InputLabel>
                    </Tooltip>
                    <Select
                      variant="standard"
                      name={`ruleDetails.mainCondition.${index}.operator`}
                      value={condition.operator || ''}
                      onChange={(e) =>
                        setFieldValue(`ruleDetails.mainCondition.${index}.operator`, e.target.value)
                      }
                      label={`${t('Operator')}*`}
                    >
                      <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                      {operators
                        .filter((op) =>
                          op.types.includes(condition.columns[0]?.columnType || 'string')
                        )
                        .map((op) => (
                          <MenuItem key={op.value} value={op.value}>
                            {op.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  {/* Aggregation Function */}
                  <FormControl
                    variant="standard"
                    size="small"
                    sx={{ flexGrow: 1 }}
                    error={Boolean(
                      touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                        ?.aggregationFunction &&
                        errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                          ?.aggregationFunction
                    )}
                  >
                    <Tooltip
                      title={`${t('AggregationFunction')}: ${
                        condition.valueCombinations[0].aggregationFunction
                      }`}
                    >
                      <InputLabel>{t('AggregationFunction')}</InputLabel>
                    </Tooltip>
                    <Select
                      variant="standard"
                      name={`ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationFunction`}
                      value={condition.valueCombinations[0].aggregationFunction || ''}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setFieldValue(
                          `ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationFunction`,
                          newValue
                        );
                        setFieldValue(
                          `ruleDetails.mainCondition.${index}.valueCombinations.0.value`,
                          ''
                        );
                        setLocalInputValue((prev) => {
                          const updated = [...prev];
                          updated[index][0] = '';
                          return updated;
                        });
                        if (!newValue) {
                          // Reset aggregation column when aggregationFunction is unset
                          setFieldValue(
                            `ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationColumn`,
                            {
                              column: '',
                              columnType: '',
                              indexName: '',
                            }
                          );
                        }
                      }}
                      label={t('AggregationFunction')}
                    >
                      <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                      {aggregationFunctions
                        .filter((fct) =>
                          fct.types.includes(condition.columns[0]?.columnType || 'string')
                        )
                        .map((fct) => (
                          <MenuItem key={fct.value} value={fct.value}>
                            {t(fct.label)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  {/* Aggregation Column */}
                  <Collapse
                    in={condition.valueCombinations[0].aggregationFunction}
                    timeout="auto"
                    unmountOnExit
                  >
                    <FormControl
                      variant="standard"
                      size="small"
                      sx={{
                        width:
                          condition.valueCombinations[0].aggregationFunction === 'perc'
                            ? sidebarOpen
                              ? condition.columns.length > 1 &&
                                values.ruleDetails.mainCondition.length > 1
                                ? '7vw'
                                : '10vw'
                              : '11vw'
                            : sidebarOpen
                              ? condition.columns.length > 1 &&
                                values.ruleDetails.mainCondition.length > 1
                                ? '8.5vw'
                                : '12vw'
                              : '12vw',
                      }}
                      error={Boolean(
                        touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                          ?.aggregationColumn?.column &&
                          errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                            ?.aggregationColumn?.column
                      )}
                    >
                      <Tooltip
                        title={`${t('AggregationColumn')}*: ${
                          condition.valueCombinations[0].aggregationColumn.column
                        }`}
                      >
                        <InputLabel>{`${t('AggregationColumn')}*`}</InputLabel>
                      </Tooltip>
                      <Select
                        variant="standard"
                        name={`ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationColumn.column`}
                        value={
                          condition.valueCombinations[0].aggregationColumn.column &&
                          condition.valueCombinations[0].aggregationColumn.indexName
                            ? `${condition.valueCombinations[0].aggregationColumn.column}|${condition.valueCombinations[0].aggregationColumn.indexName}`
                            : ''
                        }
                        onChange={(e) => {
                          const selectedField = streamFields.find(
                            (field) => `${field.name}|${field.indexName}` === e.target.value
                          );

                          if (selectedField) {
                            setFieldValue(
                              `ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationColumn`,
                              {
                                column: selectedField.name,
                                columnType: selectedField.type,
                                indexName: selectedField.indexName,
                              }
                            );
                          } else {
                            setFieldValue(
                              `ruleDetails.mainCondition.${index}.valueCombinations.0.aggregationColumn`,
                              {
                                column: '',
                                columnType: '',
                                indexName: '',
                              }
                            );
                          }
                        }}
                        label={`${t('AggregationColumn')}*`}
                      >
                        <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                        {Object.keys(childrenOfMultiSelect).map((indexName) => [
                          <ListSubheader key={`${indexName}-header`}>{indexName}</ListSubheader>,
                          ...childrenOfMultiSelect[indexName].map((field) => {
                            // Get the types allowed by the selected aggregation function
                            const selectedAggFunction = aggregationFunctions.find(
                              (func) =>
                                func.value === condition.valueCombinations[0].aggregationFunction
                            );
                            const allowedTypes = selectedAggFunction?.types || [];

                            // Determine if the field should be disabled based on its type
                            const isFieldDisabled =
                              !allowedTypes.includes(field.type) ||
                              field.type !== condition.columns[0]?.columnType;

                            return (
                              <MenuItem
                                key={`${field.name}|${field.indexName}`}
                                value={`${field.name}|${field.indexName}`}
                                disabled={isFieldDisabled}
                              >
                                {field.name}
                              </MenuItem>
                            );
                          }),
                        ])}
                      </Select>
                    </FormControl>
                  </Collapse>

                  {/* Value (Autocomplete) */}
                  <Collapse
                    in={
                      !condition.valueCombinations[0].aggregationFunction ||
                      condition.valueCombinations[0].aggregationFunction === 'perc'
                    }
                    timeout="auto"
                    unmountOnExit
                    style={{
                      minWidth:
                        condition.valueCombinations[0].aggregationFunction === 'perc'
                          ? sidebarOpen
                            ? condition.columns.length > 1 &&
                              values.ruleDetails.mainCondition.length > 1
                              ? '2vw'
                              : '6vw'
                            : '8vw'
                          : sidebarOpen
                            ? condition.columns.length > 1 &&
                              values.ruleDetails.mainCondition.length > 1
                              ? '10vw'
                              : '13vw'
                            : '16vw',
                    }}
                  >
                    <FormControl
                      variant="standard"
                      size="small"
                      fullWidth
                      error={Boolean(
                        touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                          ?.value &&
                          errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]?.value
                      )}
                    >
                      <Autocomplete
                        options={getAutocompleteOptions(condition.columns)}
                        freeSolo
                        value={condition.valueCombinations[0].value || ''}
                        onChange={(event, newValue) => {
                          handleInputChange(index, 0, newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleInputChange(index, 0, newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            size="small"
                            label={`${t('Value')}*`}
                            fullWidth
                            error={Boolean(
                              touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                                ?.value &&
                                errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[0]
                                  ?.value
                            )}
                          />
                        )}
                        disabled={condition.columns.length === 0}
                        style={{
                          minWidth: '6vw',
                        }}
                      />
                    </FormControl>
                  </Collapse>
                </Grid>
              </Grid>

              {/* Value Combinations */}
              <FieldArray name={`ruleDetails.mainCondition.${index}.valueCombinations`}>
                {(combinationHelpers) => (
                  <Grid
                    item
                    display={'flex'}
                    direction={'column'}
                    gap={2}
                    style={{
                      marginLeft:
                        values.ruleDetails.mainCondition.length > 1
                          ? 'calc(3.5rem + 6vw)'
                          : '2.5rem',
                    }}
                  >
                    {[...condition.valueCombinations]
                      .slice(1)
                      .map((valueCombination, comboIndex) => {
                        const comboFieldIndex = comboIndex + 1; // Adjust index since slice(1)
                        return (
                          <Grid
                            key={comboFieldIndex}
                            display={'flex'}
                            direction={'row'}
                            alignItems={'center'}
                          >
                            {/* Close Button */}
                            <Grid
                              item
                              display={'flex'}
                              alignItems={'center'}
                              style={{ marginRight: '1rem' }}
                            >
                              <IconButton
                                onClick={() => {
                                  removeValueCombination(
                                    index,
                                    comboFieldIndex,
                                    values,
                                    setFieldValue
                                  );
                                  setLocalInputValue((prev) => {
                                    const updated = [...prev];
                                    updated[index].splice(comboFieldIndex, 1);
                                    return updated;
                                  });
                                }}
                                size="small"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>

                            {/* Combination Operation */}
                            <FormControl
                              variant="standard"
                              size="small"
                              sx={{ minWidth: sidebarOpen ? '11vw' : '14vw' }}
                              error={Boolean(
                                touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                  comboFieldIndex
                                ]?.combinationOperator &&
                                  errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                    comboFieldIndex
                                  ]?.combinationOperator
                              )}
                            >
                              <Tooltip
                                title={`${t('CombinationOperation')}*: ${
                                  valueCombination.combinationOperator
                                }`}
                              >
                                <InputLabel>{`${t('CombinationOperation')}*`}</InputLabel>
                              </Tooltip>
                              <Select
                                variant="standard"
                                name={`ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.combinationOperator`}
                                value={valueCombination.combinationOperator || ''}
                                onChange={(e) =>
                                  setFieldValue(
                                    `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.combinationOperator`,
                                    e.target.value
                                  )
                                }
                                label={`${t('CombinationOperation')}*`}
                              >
                                <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                                {combinationOperations.map((op) => (
                                  <MenuItem key={op} value={op}>
                                    {op}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {/* Aggregation Function */}
                            <FormControl
                              variant="standard"
                              size="small"
                              sx={{ flexGrow: 1 }}
                              error={Boolean(
                                touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                  comboFieldIndex
                                ]?.aggregationFunction &&
                                  errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                    comboFieldIndex
                                  ]?.aggregationFunction
                              )}
                            >
                              <Tooltip
                                title={`${t('AggregationFunction')}: ${
                                  valueCombination.aggregationFunction
                                }`}
                              >
                                <InputLabel>{t('AggregationFunction')}</InputLabel>
                              </Tooltip>
                              <Select
                                variant="standard"
                                name={`ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationFunction`}
                                value={valueCombination.aggregationFunction || ''}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setFieldValue(
                                    `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationFunction`,
                                    newValue
                                  );
                                  setFieldValue(
                                    `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.value`,
                                    ''
                                  );
                                  setLocalInputValue((prev) => {
                                    const updated = [...prev];
                                    updated[index][comboFieldIndex] = '';
                                    return updated;
                                  });
                                  if (!newValue) {
                                    // Reset aggregation column when aggregationFunction is unset
                                    setFieldValue(
                                      `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationColumn`,
                                      {
                                        column: '',
                                        columnType: '',
                                        indexName: '',
                                      }
                                    );
                                  }
                                }}
                                label={t('AggregationFunction')}
                              >
                                <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                                {aggregationFunctions
                                  .filter((fct) =>
                                    fct.types.includes(condition.columns[0]?.columnType || 'string')
                                  )
                                  .map((fct) => (
                                    <MenuItem key={fct.value} value={fct.value}>
                                      {t(fct.label)}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            {/* Aggregation Column */}
                            <Collapse
                              in={valueCombination.aggregationFunction}
                              timeout="auto"
                              unmountOnExit
                            >
                              <FormControl
                                variant="standard"
                                size="small"
                                sx={{
                                  minWidth:
                                    valueCombination.aggregationFunction === 'perc'
                                      ? sidebarOpen
                                        ? '10vw'
                                        : '16vw'
                                      : sidebarOpen
                                        ? '12vw'
                                        : '18vw',
                                }}
                                error={Boolean(
                                  touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                    comboFieldIndex
                                  ]?.aggregationColumn?.column &&
                                    errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                      comboFieldIndex
                                    ]?.aggregationColumn?.column
                                )}
                              >
                                <Tooltip
                                  title={`${t('AggregationColumn')}*: ${
                                    valueCombination.aggregationColumn.column
                                  }`}
                                >
                                  <InputLabel>{`${t('AggregationColumn')}*`}</InputLabel>
                                </Tooltip>
                                <Select
                                  variant="standard"
                                  name={`ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationColumn.column`}
                                  value={
                                    valueCombination.aggregationColumn.column &&
                                    valueCombination.aggregationColumn.indexName
                                      ? `${valueCombination.aggregationColumn.column}|${valueCombination.aggregationColumn.indexName}`
                                      : ''
                                  }
                                  onChange={(e) => {
                                    const selectedField = streamFields.find(
                                      (field) =>
                                        `${field.name}|${field.indexName}` === e.target.value
                                    );

                                    if (selectedField) {
                                      setFieldValue(
                                        `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationColumn`,
                                        {
                                          column: selectedField.name,
                                          columnType: selectedField.type,
                                          indexName: selectedField.indexName,
                                        }
                                      );
                                    } else {
                                      setFieldValue(
                                        `ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.aggregationColumn`,
                                        {
                                          column: '',
                                          columnType: '',
                                          indexName: '',
                                        }
                                      );
                                    }
                                  }}
                                  label={`${t('AggregationColumn')}*`}
                                  disabled={!valueCombination.aggregationFunction}
                                >
                                  <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
                                  {Object.keys(childrenOfMultiSelect).map((indexName) => [
                                    <ListSubheader key={`${indexName}-header`}>
                                      {indexName}
                                    </ListSubheader>,
                                    ...childrenOfMultiSelect[indexName].map((field) => {
                                      // Get the types allowed by the selected aggregation function
                                      const selectedAggFunction = aggregationFunctions.find(
                                        (func) =>
                                          func.value === valueCombination.aggregationFunction
                                      );
                                      const allowedTypes = selectedAggFunction?.types || [];

                                      // Determine if the field should be disabled based on its type
                                      const isFieldDisabled =
                                        !allowedTypes.includes(field.type) ||
                                        field.type !== condition.columns[0]?.columnType;

                                      return (
                                        <MenuItem
                                          key={`${field.name}|${field.indexName}`}
                                          value={`${field.name}|${field.indexName}`}
                                          disabled={isFieldDisabled}
                                        >
                                          {field.name}
                                        </MenuItem>
                                      );
                                    }),
                                  ])}
                                </Select>
                              </FormControl>
                            </Collapse>

                            {/* Value (Autocomplete) */}
                            <Collapse
                              in={
                                !valueCombination.aggregationFunction ||
                                valueCombination.aggregationFunction === 'perc'
                              }
                              timeout="auto"
                              unmountOnExit
                              style={{
                                minWidth:
                                  valueCombination.aggregationFunction === 'perc'
                                    ? sidebarOpen
                                      ? '6vw'
                                      : '8vw'
                                    : sidebarOpen
                                      ? '13vw'
                                      : '16vw',
                              }}
                            >
                              <FormControl
                                variant="standard"
                                size="small"
                                fullWidth
                                error={Boolean(
                                  touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                    comboFieldIndex
                                  ]?.value &&
                                    errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                      comboFieldIndex
                                    ]?.value
                                )}
                              >
                                <Autocomplete
                                  options={getAutocompleteOptions(condition.columns)}
                                  freeSolo
                                  value={valueCombination.value || ''}
                                  onChange={(event, newValue) => {
                                    handleInputChange(index, comboFieldIndex, newValue);
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    handleInputChange(index, comboFieldIndex, newInputValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      size="small"
                                      label={`${t('Value')}*`}
                                      fullWidth
                                      error={Boolean(
                                        touched.ruleDetails?.mainCondition?.[index]
                                          ?.valueCombinations?.[comboFieldIndex]?.value &&
                                          errors.ruleDetails?.mainCondition?.[index]
                                            ?.valueCombinations?.[comboFieldIndex]?.value
                                      )}
                                    />
                                  )}
                                  disabled={condition.columns.length === 0}
                                />
                              </FormControl>
                            </Collapse>

                            {/* Execution Order */}
                            <FormControl
                              variant="standard"
                              size="small"
                              sx={{ minWidth: '80px' }}
                              error={Boolean(
                                touched.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                  comboFieldIndex
                                ]?.executionOrderNumber &&
                                  errors.ruleDetails?.mainCondition?.[index]?.valueCombinations?.[
                                    comboFieldIndex
                                  ]?.executionOrderNumber
                              )}
                            >
                              <Tooltip
                                title={`${t('Order')}*: ${valueCombination.executionOrderNumber}`}
                              >
                                <InputLabel>{`${t('Order')}*`}</InputLabel>
                              </Tooltip>
                              <Select
                                variant="standard"
                                name={`ruleDetails.mainCondition.${index}.valueCombinations.${comboFieldIndex}.executionOrderNumber`}
                                value={valueCombination.executionOrderNumber || ''}
                                onChange={(e) => {
                                  const newOrder = Number(e.target.value);
                                  const oldOrder = valueCombination.executionOrderNumber;

                                  if (!newOrder || newOrder === oldOrder) return; // If no change or invalid input, do nothing

                                  // Get the current value combinations, excluding the one with executionOrderNumber 0
                                  const valueCombinations = [
                                    ...values.ruleDetails.mainCondition[index].valueCombinations,
                                  ].filter((combination) => combination.executionOrderNumber !== 0);

                                  // Adjust execution order for all combinations except the one with executionOrderNumber 0
                                  valueCombinations.forEach((combination) => {
                                    if (combination.executionOrderNumber === oldOrder) {
                                      // Set the execution order for the changed combination
                                      combination.executionOrderNumber = newOrder;
                                    } else if (
                                      newOrder > oldOrder &&
                                      combination.executionOrderNumber > oldOrder &&
                                      combination.executionOrderNumber <= newOrder
                                    ) {
                                      // If moving the order higher, decrement the others
                                      combination.executionOrderNumber -= 1;
                                    } else if (
                                      newOrder < oldOrder &&
                                      combination.executionOrderNumber >= newOrder &&
                                      combination.executionOrderNumber < oldOrder
                                    ) {
                                      // If moving the order lower, increment the others
                                      combination.executionOrderNumber += 1;
                                    }
                                  });

                                  // Update the form values with the modified execution order
                                  setFieldValue(
                                    `ruleDetails.mainCondition.${index}.valueCombinations`,
                                    [
                                      ...values.ruleDetails.mainCondition[index].valueCombinations,
                                    ].map((combination) =>
                                      combination.executionOrderNumber === 0
                                        ? combination // Keep the first combination unchanged
                                        : valueCombinations.find(
                                            (item) =>
                                              item.combinationOrderNumber ===
                                              combination.combinationOrderNumber
                                          ) || combination
                                    )
                                  );
                                }}
                                label={`${t('Order')}*`}
                              >
                                {/* Dynamically render the available order numbers, excluding 0 */}
                                {[...Array(condition.valueCombinations.length - 1)]
                                  .map((_, idx) => idx + 1) // Available execution order numbers starting from 1
                                  .map((op) => (
                                    <MenuItem key={op} value={op}>
                                      {op}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        );
                      })}

                    {/* Add Combination Button */}
                    {condition.columns.every((col) => col.columnType === 'number') && (
                      <Grid
                        item
                        display={'flex'}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                      >
                        <Button
                          variant="text"
                          color="secondary"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            combinationHelpers.push({
                              combinationOrderNumber: condition.valueCombinations.length,
                              combinationOperator: '',
                              aggregationFunction: '',
                              aggregationColumn: {
                                column: '',
                                columnType: '',
                                indexName: '',
                              },
                              value: '',
                              executionOrderNumber: condition.valueCombinations.length,
                            })
                          }
                        >
                          {t('addCombination')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          ))}

          {/* Add Filter Button */}
          <Grid
            item
            display={'flex'}
            direction={'row'}
            gap={3}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                arrayHelpers.push({
                  conditionOrderNumber: values.ruleDetails.mainCondition.length,
                  logicalOperator: null,
                  columns: [],
                  columnOperator: null,
                  operator: '',
                  valueCombinations: [
                    {
                      combinationOrderNumber: 0,
                      value: '',
                      executionOrderNumber: 0,
                      aggregationFunction: '',
                      aggregationColumn: {
                        column: '',
                        columnType: '',
                        indexName: '',
                      },
                    },
                  ],
                });
                updateSelectOpenState(values.ruleDetails.mainCondition.length + 1);
              }}
              style={{ marginRight: 8 }}
              startIcon={<AddIcon />}
            >
              {t('addFilter')}
            </Button>
          </Grid>
        </Grid>
      )}
    />
  );
}

MainCondition.propTypes = {
  streamFields: PropTypes.array.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
};

export default MainCondition;
