import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CopilotButton from '../testOptions/copilot/components/CopilotButton';
import styles from './TestCard.module.css';

const TestCardsManipulation = ({
  cards,
  isAddCardDisabled,
  isCopilotButtonPresent,
  copilotButtonProps,
  handleDeleteCard,
  handleAddCard,
  handleSaveCard,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <div className={styles.testCardManipulation}>
      {cards && cards.length > 0 && (
        <Tooltip title={t('deleteTestCardTooltip')}>
          <IconButton
            className={styles.testCardManipulationElement}
            variant="qualitatio"
            squared={true}
            onClick={() => handleDeleteCard()}
            style={{
              backgroundColor: theme.palette.error.main,
            }}
          >
            <DeleteForeverRoundedIcon color="white" fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('addTestCardTooltip')}>
        <IconButton
          className={styles.testCardManipulationElement}
          variant="qualitatio"
          squared={true}
          disabled={isAddCardDisabled}
          onClick={() => handleAddCard()}
          style={{
            backgroundColor: theme.palette.success.secondary,
          }}
        >
          <AddRoundedIcon color="primary" fontSize="large" />
        </IconButton>
      </Tooltip>
      {isCopilotButtonPresent && (
        <CopilotButton
          className={styles.testCardManipulationElement}
          disabled={isAddCardDisabled}
          {...copilotButtonProps}
        />
      )}
      {cards && cards.length > 0 && (
        <Tooltip title={t('saveTestCardsTooltip')}>
          <IconButton
            className={styles.testCardManipulationElement}
            variant="qualitatio"
            squared={true}
            onClick={() => handleSaveCard()}
            style={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SaveIcon color="white" fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

TestCardsManipulation.propTypes = {
  cards: PropTypes.array.isRequired,
  isAddCardDisabled: PropTypes.bool.isRequired,
  isCopilotButtonPresent: PropTypes.bool.isRequired,
  copilotButtonProps: PropTypes.object,
  handleDeleteCard: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  handleSaveCard: PropTypes.func.isRequired,
};

export default TestCardsManipulation;
