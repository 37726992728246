import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth.store';
import { getRedirectPath } from '../utils/accessUtils';

export function useRedirectIfAuthenticated() {
  const { isAuthenticated, user } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(getRedirectPath(user));
    }
  }, [isAuthenticated, navigate]);
}

export function useMockHook() {
  // Another hook logic...
}
