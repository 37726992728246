import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

// The language constant might need adjustment or import if it's external
import { LANG } from '../../language_settings/languageConst';
import { flagComponents } from '../Flags/FlagComponents';

const QualitatioLanguageSelector = ({
  value,
  onChange,
  size = 'lg',
  width = '100%',
  fullWidth = true,
  disableClearable = true,
  onBlur = null,
  disabled = false,
  readOnly = false,
  resetOnOpen = false,
  ...props
}) => {
  const { t } = useTranslation();

  const renderOption = (props, option) => {
    console.log(option);
    const FlagIcon = flagComponents[option];
    return (
      <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
        {FlagIcon && <FlagIcon style={{ width: 24, height: 'auto', marginRight: 8 }} />}
        <span>{t(`langCodes.${option}`)}</span>
        <Box marginLeft="auto">
          <PlayArrowIcon color="gray" />
        </Box>
      </Box>
    );
  };

  return (
    <Autocomplete
      label={t('language')}
      options={Object.values(LANG)}
      value={value}
      onChange={onChange}
      size={size}
      width={width}
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      onBlur={onBlur}
      getOptionLabel={(option) => t(`langCodes.${option}`)}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('language')}
          placeholder={t('language')}
          variant="outlined"
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {...props}
    />
  );
};

export default QualitatioLanguageSelector;
