// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { dictionaryList } from './languages';

i18n
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: dictionaryList.en,
            },
            tr: {
                translation: dictionaryList.tr,
            },
            de: {
                translation: dictionaryList.de,
            },
            fr: {
                translation: dictionaryList.fr,
            },
            es: {
                translation: dictionaryList.es,
            },
            it: {
                translation: dictionaryList.it,
            },
            ru: {
                translation: dictionaryList.ru,
            },
            ro: {
                translation: dictionaryList.ro,
            },
            pl: {
                translation: dictionaryList.pl,
            },
            cz: {
                translation: dictionaryList.cz,
            },
            hu: {
                translation: dictionaryList.hu,
            },
        },
        lng: "de",
        //todo activate this for production
        /*         fallbackLng: "de", */
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
