import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import './QRCodeComponent.css';

/**
 * @param {object} defectFound - Object containing information about the defect found
 * @param {string} encodingString - Encoding string provided by the user, assuming to have a format where
 * placeholders are enclosed in curly braces expected to be replaced by actual values.
 *   Example encodingString: "$D${defectLocation}${defectComment}"
 *   Example parsed string: "$D$641d35$comment"
 */
export const QRCodeComponent = ({ defectFound, encodingString }) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [qrData, setQrData] = useState('');
  const [isQRCodeEmpty, setIsQRCodeEmpty] = useState(true);

  // Function to parse the encoding string and replace it with actual values
  const parseEncodingString = () => {
    return encodingString.replace(/\{(\w+)\}/g, (match, key) => {
      const replacement = defectFound?.[key] || '';
      if (replacement !== '') {
        setIsQRCodeEmpty(false);
      }
      return replacement;
    });
  };

  useEffect(() => {
    setQrData(parseEncodingString());
  }, [defectFound, encodingString]);

  const handleQRClick = () => {
    setIsEnlarged(!isEnlarged);
  };

  return (
    <>
      {!isQRCodeEmpty && (
        <>
          <div className="qr-container" onClick={handleQRClick}>
            <QRCodeSVG value={qrData} size={'80%'} style={{ borderRadius: '8px' }} />
          </div>
          <Modal
            open={isEnlarged}
            onClose={handleQRClick}
            componentsProps={{
              backdrop: {
                // Define this modal's 'backdrop' slot so it does not get overridden by the global backdrop
                className: 'custom-backdrop',
              },
            }}
          >
            <Box className="qr-code-modal" onClick={handleQRClick}>
              <QRCodeSVG value={qrData} size={'80%'} style={{ borderRadius: '8px' }} />
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

QRCodeComponent.propTypes = {
  defectFound: PropTypes.object.isRequired,
  encodingString: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default QRCodeComponent;
