import { Grid, Paper } from '@mui/material';

export const SwipeCardInput = ({ Icon, children }) => {
    const paperStyleProps = {
        square: false,
        style: {
            width: '100%',
            padding: '4px',
            display: 'flex',
            flexDirection: 'row',
            gap: '1vw',
            alignItems: 'center',
            boxShadow: '0px 6.63px 13.25px #1018280d',
            position: 'relative',
        }
    };

    return (
        <Grid item xs={12} display="flex" flexDirection="column" gap="3px">
            <Paper {...paperStyleProps}>
                <Icon fill="black" />
                {children}
            </Paper>
        </Grid>
    );
}

export default SwipeCardInput;