import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import LockIcon from '@mui/icons-material/Lock';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import QualitatioButton from '../components/QualitatioButton/QualitatioButton';
import QualitatioInput from '../components/QualitatioInput/QualitatioInput';
import { useRedirectIfAuthenticated } from '../components/customHooks ';
import { useAuthStore } from '../store/auth.store';
import { getRedirectPath } from '../utils/accessUtils';
import './LoginPage.css';

//this needs to be reworked and is just for testing purposes
import {
  activeAuthenticationSystemsEndpoint,
  activeOAuthProvidersEndpoint,
  loginOauthEndpoint,
} from '../api/endpoints';
import { oauthIconMapping } from '../utils/utilities';

function LoginPage({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('login'));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const [showPassword, setShowPassword] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1030);
  const [activeProviders, setActiveProviders] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1030);
    };

    window.addEventListener('resize', handleResize);

    const fetchData = async () => {
      try {
        const oAuthIsActive = await axiosClient.get(activeAuthenticationSystemsEndpoint);
        if (!oAuthIsActive.data.includes('oauth')) {
          return;
        }
        const activeProviders = await axiosClient.get(activeOAuthProvidersEndpoint);
        setActiveProviders(activeProviders.data);
      } catch (error) {
        enqueueSnackbar('Failed to fetch OAuth provider settings', { variant: 'error' });
      }
    };
    fetchData();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useRedirectIfAuthenticated();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const user = await login({ email, password });
        if (user) {
          enqueueSnackbar(t('loginSuccessSnack') || 'Login successful', {
            variant: 'success',
          });
          //check if user is system admin and if he has already done the setup
          //setup is done when sytem admin is verified
          const setupDone = user.systemAdmin && user.isEmailVerified;
          if (setupDone) {
            const from = localStorage.getItem('loginRedirect') || '/home';
            localStorage.removeItem('loginRedirect');
            navigate(getRedirectPath(user, from));
          }
          if (user.systemAdmin && !setupDone) {
            navigate('/setup');
          }
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.message || error.message;
        enqueueSnackbar(t(errorMessage) || t('loginErrorSnack'), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, email, login, navigate, password, t]
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleSubmit]);

  return (
    <div className="login-page">
      <div className="frame">
        <div className="div-wrapper">
          <div className="text-wrapper-2">{t('signIn')}</div>
        </div>
        <img className="logo" alt="Logo" src="/images/qualitatio.svg" />
        <div className="frame-2">
          <div className="frame-3">
            <QualitatioInput
              label={t('email')}
              type={'email'}
              placeholder={'you@mail.com'}
              width={'100%'}
              size="lg"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <QualitatioInput
              label={t('password')}
              type="password"
              placeholder="*******"
              width={'100%'}
              size="lg"
              onChange={(e) => setPassword(e.currentTarget.value)}
              showInput={showPassword}
              toggleShowInput={() => setShowPassword(!showPassword)}
            />
          </div>
          {/* LDAP is not used in the current version
           */}
          {/* <div className="frame-4">
            <div className="frame-5">
              <div className="text-wrapper-4">
                {isMobileView ? <PersonIcon /> : t('personalLogin')}
              </div>
              <Switch variant="qualitatio" checked={useLdap} onChange={handleSwitchChange} />
              <div className="text-wrapper-5">
                {isMobileView ? <BusinessIcon /> : t('companyLogin')}
              </div>
            </div>
          </div> */}
        </div>
        <div className="frame-6">
          <div className="buttons">
            <QualitatioButton
              text={t('signIn')}
              order="primary"
              onClick={handleSubmit}
              width={'100%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              endIcon={<ArrowForwardIosRoundedIcon />}
            />
            <QualitatioButton
              text={t('back')}
              order="secondary"
              onClick={() => navigate('/welcome')}
              width={'100%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            />
            <Grid container justifyContent="center" spacing={2}>
              {' '}
              {/* Ensure the items are spaced out and centered */}
              {activeProviders.map((provider) => (
                <Grid item key={provider}>
                  <Tooltip
                    title={t('oauth.infoTooltip', { provider: provider })}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      onClick={() => {
                        window.location.href = `${loginOauthEndpoint}?provider=${provider}`;
                      }}
                    >
                      {oauthIconMapping[provider] ? (
                        React.createElement(oauthIconMapping[provider])
                      ) : (
                        <LockIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="kein-konto">
            <p className="sie-haben-kein-konto">
              <span className="span">{t('noAccountYet')}&nbsp;</span>
              <Link to="/register" className="link">
                <span className="text-wrapper-6">{t('registerHere')}</span>
              </Link>
            </p>
          </div>

          <div className="kein-konto">
            <p className="sie-haben-kein-konto">
              <span className="span">{t('forgotPassword')}&nbsp;</span>
              <Link to="/request-reset-password" className="link">
                <span className="text-wrapper-6">{t('resetHere')}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
