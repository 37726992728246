// GeneralSettings.js

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QualitatioDateTimePicker } from '../../../../../components';

function GeneralSettings({ qualityStations }) {
  const { t } = useTranslation();

  const { values, setFieldValue, touched, errors } = useFormikContext();

  return (
    <Grid
      item
      display={'flex'}
      direction={'column'}
      justifyContent={'center'}
      width={'100%'}
      gap={3}
    >
      {/* Rule Name */}
      <TextField
        name="ruleName"
        label={`${t('ruleName')}*`}
        value={values.ruleName}
        onChange={(event) => setFieldValue('ruleName', event.target.value)}
        variant="standard"
        fullWidth
        error={Boolean(touched.ruleName && errors.ruleName)}
        helperText={touched.ruleName && errors.ruleName}
      />
      {/* Quality Station */}
      <FormControl
        variant="standard"
        fullWidth
        error={Boolean(touched.qualityStation && errors.qualityStation)}
      >
        <InputLabel>{`${t('qualityStation')}*`}</InputLabel>
        <Select
          name="qualityStation"
          value={values.qualityStation}
          onChange={(event) => setFieldValue('qualityStation', event.target.value)}
          label="Quality Station"
        >
          {/* Replace with actual quality station options */}
          <MenuItem value="" sx={{ height: '36px' }}></MenuItem>
          {qualityStations.map((station) => (
            <MenuItem key={station.id} value={station.id}>
              {station.name}
            </MenuItem>
          ))}
        </Select>
        {touched.qualityStation && errors.qualityStation && (
          <FormHelperText error>{errors.qualityStation}</FormHelperText>
        )}
      </FormControl>

      {/* Expiration Date */}
      <FormControl
        component="fieldset"
        fullWidth
        error={Boolean(touched.expirationDate && errors.expirationDate)}
      >
        <QualitatioDateTimePicker
          label={`${t('expirationDate')}*`}
          value={values.expirationDate}
          onChange={(value) => setFieldValue('expirationDate', value)}
          inputVariant="standard"
          onlyFuture
          width={'100%'}
          error={Boolean(touched.expirationDate && errors.expirationDate)}
          helperText={touched.expirationDate && errors.expirationDate}
        />
      </FormControl>

      {/* Application Mode */}
      <FormControl
        component="fieldset"
        fullWidth
        error={Boolean(touched.applicationMode && errors.applicationMode)}
      >
        <FormLabel id="applicationMode">{`${t('applicationMode')}*`}</FormLabel>
        <RadioGroup
          row
          name="applicationMode"
          value={values.applicationMode}
          onChange={(event) => setFieldValue('applicationMode', event.target.value)}
          aria-labelledby="applicationMode"
        >
          <FormControlLabel value="test" control={<Radio />} label="Test" />
          <FormControlLabel value="skip" control={<Radio />} label="Skip" />
        </RadioGroup>
        {touched.applicationMode && errors.applicationMode && (
          <FormHelperText error>{errors.applicationMode}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

export default GeneralSettings;

GeneralSettings.propTypes = {
  qualityStations: PropTypes.array.isRequired,
};
