import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { eventCardEndpoint } from '../../../../api/endpoints';
import { useAuthStore } from '../../../../store/auth.store';
import { useCardOperations } from '../../testCards/useCardOperations';
import TestCardWrapper from '../../testCards/TestCardWrapper';
import styles from './EventTests.module.css';

const EventTestCards = ({
  currentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  setEventTestsGroundTruth,
  eventTestsGroundTruth,
  eventTests,
  setEventTests,
  qualityStation,
  orderIdentifierMapping,
  selectedLiveStreamData,
  events,
  restoreChange,
  filteredEventData,
  explanation,
  selectedEvent,
  setDeleteCardWarningOpen,
  eventDataWithId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();
  const { addTestCard, saveTestCard } = useCardOperations();

  // The generic function that sets any card property, with an optional modifier function
  const setCardProperty = (property, value, modifier = null) => {
    const currentEventExplanation = explanation;
    const currentEventExplanationParts = new Set(currentEventExplanation.split(' - '));

    setEventTests((prevState) =>
      prevState.map((test) => {
        const testExplanationParts = new Set(test.explanation.split(' - '));
        const isMatchingExplanation = [...testExplanationParts].every((part) =>
          currentEventExplanationParts.has(part)
        );

        if (isMatchingExplanation) {
          const indexInSubset = prevState
            .filter((t) => {
              const parts = new Set(t.explanation.split(' - '));
              return [...parts].every((part) => currentEventExplanationParts.has(part));
            })
            .indexOf(test);

          if (indexInSubset === currentCardIndex) {
            const updatedProperty = modifier ? modifier(test[property], value) : value;
            return { ...test, [property]: updatedProperty };
          }
        }
        return test;
      })
    );
  };

  const calculateCurrentCardIndex = (newCardExplanation) => {
    const newCardExplanationParts = new Set(newCardExplanation.split(' - '));
    const matchingTests = eventTests.filter((test) => {
      const testExplanationParts = new Set(test.explanation.split(' - '));
      return [...testExplanationParts].every((part) => newCardExplanationParts.has(part));
    });
    return matchingTests.length;
  };

  const handleAddCard = addTestCard({
    qualityStation: qualityStation,
    creator: user,
    customFields: (() => {
      const currentEvent = filteredEventData?.[currentIndex];
      const currentStreamData = selectedLiveStreamData.find(
        (el) => el.streamName === currentEvent?.[0]
      );
      const eventImageField = currentStreamData?.imageFieldName;
      const fieldNames = currentStreamData?.fieldNames.reduce((acc, fieldName, index) => {
        acc[fieldName] = currentEvent?.[index + 1];
        return acc;
      }, {});

      return {
        type: 'Dynamic',
        explanation: explanation,
        dynamic: {
          eventImageField,
          eventStreamName: currentEvent?.[0],
          eventFrequency: currentEvent?.slice(-1)[0],
          isEvent: true,
          ...fieldNames,
        },
      };
    })(), // Immediately Invoked Function Expression to calculate when handleAddCard is called
    currentTestCards: eventTests,
    setTestCards: setEventTests,
    setCurrentCardIndex: setCurrentCardIndex,
    getNewCardIndex: (newCard) => {
      return calculateCurrentCardIndex(newCard.explanation) || 0;
    },
  });

  const isValidEventCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.dynamic.eventStreamName !== '' &&
        card.dynamic.eventFrequency !== ''
      );
    });
  };

  const _validateCards = (updatedEventTests) => {
    if (!isValidEventCards(updatedEventTests || eventTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), {
        variant: 'warning',
      });
      return false;
    }
    return true;
  };

  const handleSaveCard = saveTestCard({
    validateCards: _validateCards,
    filterCardToSave: (card, groundTruth, index) =>
      (!card.id || !_.isEqual(card, groundTruth)) && card.explanation === explanation,
    cardEndpoint: eventCardEndpoint,
    qualityStation: qualityStation,
    creator: user,
    currentTestCards: eventTests,
    setTestCards: setEventTests,
    groundTruthCards: eventTestsGroundTruth,
    setGroundTruthCards: setEventTestsGroundTruth,
    errorMessageKey: 'errorWhileSavingDynamicTestProposal',
    successMessageKey: 'eventBasedTestingSavedSuccessfully',
  });

  const updateCurrentCardIndex = (direction) => {
    const currentEventExplanationParts = new Set(explanation.split(' - '));
    const matchingTests = eventTests.filter((test) => {
      const testExplanationParts = new Set(test.explanation.split(' - '));
      return [...testExplanationParts].every((part) => currentEventExplanationParts.has(part));
    });

    if (direction === 'inc') {
      if (currentCardIndex < matchingTests.length - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(currentCardIndex - 1);
      }
    }
  };

  const getFilteredCards = () => {
    const currentExplanation = explanation;
    const explanationParts = new Set(currentExplanation.split(' - '));

    return eventTests
      .map((card) => {
        const cardExplanationParts = new Set(card.explanation.split(' - '));
        const isExactMatch =
          explanationParts.size === cardExplanationParts.size &&
          [...explanationParts].every((part) => cardExplanationParts.has(part));
        const isSubset = [...cardExplanationParts].every((part) => explanationParts.has(part));
        return {
          ...card,
          isExactMatch,
          isSubset: isSubset && !isExactMatch,
        };
      })
      .filter((card) => card.isSubset || card.isExactMatch);
  };

  const filteredCards = useMemo(
    () => getFilteredCards(),
    [explanation, eventTests, currentCardIndex]
  );

  return (
    <>
      <div className={styles.testCards}>
        <TestCardWrapper
          cardsToDisplay={filteredCards}
          cardType={'dynamic'}
          currentCardIndex={currentCardIndex}
          setCurrentCardIndex={setCurrentCardIndex}
          testCardProps={{
            editable: filteredCards[currentCardIndex]?.isExactMatch,
            generation: t('Dynamic'),
            index: currentCardIndex,
            metaIndex: currentIndex,
            qualityStation,
            orderIdentifierMapping,
            event: selectedEvent,
            restoreChange,
          }}
          setCardField={setCardProperty}
          handlePreviousCard={() => updateCurrentCardIndex('dec')}
          handleNextCard={() => updateCurrentCardIndex('inc')}
          handleAddCard={handleAddCard}
          handleDeleteCard={() => setDeleteCardWarningOpen(true)}
          handleSaveCard={handleSaveCard}
          isAddCardDisabled={!events || !events[currentIndex]}
          isCopilotButtonPresent={true}
          copilotButtonProps={{
            defectDefinition:
              eventDataWithId &&
              eventDataWithId[currentIndex] &&
              selectedLiveStreamData[0] &&
              selectedLiveStreamData[0].filterFieldNames &&
              Object.fromEntries(
                Object.entries(eventDataWithId[currentIndex]).filter(
                  ([key]) => !selectedLiveStreamData[0].filterFieldNames.includes(key)
                )
              ),
            qualityStation,
            allCards: eventTests,
            setAllCards: setEventTests,
            filteredCardsByEvent: filteredCards,
            setCurrentCardIndex: setCurrentCardIndex,
            explanation: explanation,
            cardType: 'dynamic',
          }}
        />
      </div>
    </>
  );
};

EventTestCards.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  setEventTestsGroundTruth: PropTypes.func.isRequired,
  eventTestsGroundTruth: PropTypes.array.isRequired,
  eventTests: PropTypes.array.isRequired,
  setEventTests: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  selectedLiveStreamData: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  restoreChange: PropTypes.func.isRequired,
  filteredEventData: PropTypes.array.isRequired,
  explanation: PropTypes.string.isRequired,
  selectedEvent: PropTypes.object,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  eventDataWithId: PropTypes.array.isRequired,
};

export default EventTestCards;
