import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { deleteUser } from '../../../api/user';
import { useTranslation } from 'react-i18next';

const DeleteUserOverlay = ({ selectedUser, onClose, showDeleteConfirm, reload }) => {
    const { enqueueSnackbar } = useSnackbar(); // Assuming you're using notistack for snackbars

    const { t } = useTranslation();;

    const handleConfirmDelete = async () => {
        // You might need to handle user validation differently based on your project setup

        const result = await deleteUser(selectedUser.id);
        if (result && result.error) {
            enqueueSnackbar(t("userDeleteErrorSnack"), { variant: 'error' });
        } else {
            enqueueSnackbar(
                t("userDeleteSuccessSnack"), { variant: 'success' });
            onClose();
        }
        reload();
    };

    return (
        <Dialog
            open={showDeleteConfirm}
            onClose={onClose}
            variant="qualitatio"
        >
            <DialogTitle>{t("confirmDeletion")}</DialogTitle>
            <DialogContent>
                {t("confirmUserDeletionText")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmDelete} variant="qualitatio">
                    {t("delete")}
                </Button>
                <Button onClick={onClose} variant="qualitatio" color="secondary">
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserOverlay;
