import { Add, Cancel, Delete, Edit, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv } from 'react-icons/fa';
import 'swiper/css';
import 'swiper/css/navigation';
import { SwiperSlide } from 'swiper/react';
import axiosClient from '../../../api/axiosClient';
import {
  defectDescriptionFieldsAndLevelsEndpoint,
  defectDescriptionFieldsEndpoint,
  defectDescriptionHierarchieEndpoint,
  defectDescriptionHierarchieFileEndpoint,
  defectDocumentationLevelsEndpoint,
} from '../../../api/endpoints';
import QualitatioDropzone from '../../../components/QualitatioDropzone/QualitatioDropzone';
import QualitatioSlider from '../../../components/QualitatioSlider/QualitatioSlider';
import AnimatedTree from '../../../intelligent_testing/configurator/testOptions/components/CustomAnimatedTree/components/animatedTree';

const DefectHierarchieSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();

  const [defectDescriptionFields, setDefectDescriptionFields] = useState(['']);
  const [defectDocumentationLevels, setDefectDocumentationLevels] = useState([]);
  const [defectDescriptionHierarchie, setDefectDescriptionHierarchie] = useState([]);

  const [sortedRows, setSortedRows] = useState({});
  const [isEditInputOpen, setIsEditInputOpen] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    getDefectDescriptionFields();
  }, []);

  const backendIndexMap =
    sortedRows.defectDescriptionFieldsFrontendSorting?.reduce((map, fieldName, index) => {
      const backendIndex = defectDescriptionFields.findIndex((field) => field === fieldName);
      if (backendIndex !== -1) {
        map[index] = backendIndex;
      }
      return map;
    }, {}) || {};

  const updateDefectDescriptionHierarchie = async (defectDescriptionHierarchie) => {
    let newDefectDescriptionHierarchie = [...defectDescriptionHierarchie];
    defectDescriptionFields.forEach((defectDescriptionField, index) => {
      let hierarchy = { name: '', children: [], open: true };
      defectDescriptionHierarchie.forEach(({ path, defectDescriptionFieldName }) => {
        if (defectDescriptionFieldName === defectDescriptionField) {
          // If hierarchy has no name, initialize it with the first item in path
          if (hierarchy.name === '') {
            hierarchy.name = path[0];
            hierarchy.open = true;
          }

          let currentLevel = hierarchy;
          path.slice(hierarchy.name ? 1 : 0).forEach((item, index, slicedPath) => {
            let existingPath = currentLevel.children.find((child) => child.name === item);

            if (existingPath) {
              currentLevel = existingPath;
            } else {
              let newItem = { name: item, children: [], open: true };
              currentLevel.children.push(newItem);
              currentLevel = newItem;
            }

            // Close the final node in path
            if (index === slicedPath.length - 1) {
              currentLevel.open = false;
            }
          });
        }
      });
      newDefectDescriptionHierarchie[index] = hierarchy;
    });
    setDefectDescriptionHierarchie(newDefectDescriptionHierarchie);

    initTrees(newDefectDescriptionHierarchie);
  };

  const [displayedTree, setDisplayedTree] = useState([]);
  const [activeNode, setActiveNode] = useState([]);
  const [currentLevel, setCurrentLevel] = useState([]);

  const updateDefectDocumentationLevels = (defectDescriptionFieldName, value) => {
    const newDefectDocumentationLevels = { ...defectDocumentationLevels };
    newDefectDocumentationLevels[defectDescriptionFieldName] = value;
    setDefectDocumentationLevels(newDefectDocumentationLevels);
  };

  const saveDefectDocumentationLevels = async () => {
    try {
      const response = await axiosClient.post(defectDocumentationLevelsEndpoint, {
        defectDocumentationLevels: defectDocumentationLevels,
      });
      if (response.data.defectDocumentationLevels) {
        setDefectDocumentationLevels(response.data.defectDocumentationLevels);
        enqueueSnackbar(t('defectDocumentationLevelsUpdatedSuccessfully'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenSavingDefectDocumentationLevels'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    getDefectDescriptionHierarchie();
    if (selectedNodes?.length === 0) {
      let newSelectedNodes = [];
      defectDescriptionFields.forEach((defectDescriptionFieldName, index) => {
        newSelectedNodes[index] = '-';
      });
      setSelectedNodes(newSelectedNodes);
    }
  }, [defectDescriptionFields]);

  const handleClick = (event, nodeKey, defectDescriptionFieldName) => {
    const sortedIndex = sortedRows.defectDescriptionFieldsFrontendSorting.findIndex(
      (item) => item === defectDescriptionFieldName
    );
    const backendIndex = backendIndexMap[sortedIndex];

    const node = findNode(defectDescriptionHierarchie[backendIndex], nodeKey);

    // Make a copy of the activeNode object
    let newActiveNode = [...activeNode];
    newActiveNode[backendIndex] = node.name;
    setActiveNode(newActiveNode);

    let newSelectedNodes = [...selectedNodes];
    newSelectedNodes[backendIndex] = node.name;
    setSelectedNodes(newSelectedNodes);

    let newCurrentLevel = [...currentLevel];
    newCurrentLevel[backendIndex] = getLevel(defectDescriptionHierarchie[backendIndex], node.name);
    setCurrentLevel(newCurrentLevel);

    if (node && node.parent) {
      let newDisplayedTreePart = JSON.parse(
        JSON.stringify(findNode(defectDescriptionHierarchie[backendIndex], node.parent))
      );

      newDisplayedTreePart.children = newDisplayedTreePart.children.filter(
        (child) => child.name === node.name
      );

      newDisplayedTreePart = cutDepth(newDisplayedTreePart, 2);

      const newDisplayedTree = [...displayedTree];
      newDisplayedTree[backendIndex] = newDisplayedTreePart;
      setDisplayedTree(newDisplayedTree);
    } else if (node) {
      let newDisplayedTreePart = JSON.parse(JSON.stringify(node));
      newDisplayedTreePart = cutDepth(newDisplayedTreePart, 1);

      const newDisplayedTree = [...displayedTree];
      newDisplayedTree[backendIndex] = newDisplayedTreePart;
      setDisplayedTree(newDisplayedTree);
    }
  };

  const getLevel = (node, name, level = 0) => {
    if (node.name === name) {
      return level;
    }

    for (let i = 0; i < (node.children || []).length; i++) {
      const found = getLevel(node.children[i], name, level + 1);
      if (found !== -1) {
        return found;
      }
    }
    return -1;
  };

  const getMaxLevel = (node, level = 0) => {
    if (!node || !node.children) {
      return level;
    }
    if (node.children.length === 0) {
      return level;
    }

    let maxLevel = level;
    for (let i = 0; i < node.children.length; i++) {
      const found = getMaxLevel(node.children[i], level + 1);
      if (found > maxLevel) {
        maxLevel = found;
      }
    }
    return maxLevel;
  };

  // Recursively search for a node by its key in a tree
  const findNode = (tree, key) => {
    if (tree.name === key) {
      return tree;
    }

    for (let child of tree.children) {
      let found = findNode(child, key);

      if (found) {
        return found;
      }
    }

    return null;
  };

  useEffect(() => {
    initTrees();
  }, [activeIndex]);

  const initTrees = (argDefectDescriptionHierarchie = null) => {
    const defectDescriptionHierarchy =
      argDefectDescriptionHierarchie || defectDescriptionHierarchie;

    let newActiveNode = [...activeNode];
    let newDisplayedTree = [...displayedTree];
    let newCurrentLevel = [...currentLevel];

    selectedNodes.forEach((selectedNode, sortedIndex) => {
      const backendIndex = backendIndexMap[sortedIndex];

      if (selectedNode !== '-') {
        newActiveNode[backendIndex] = selectedNode;
        newCurrentLevel[backendIndex] = getLevel(
          defectDescriptionHierarchy[backendIndex],
          selectedNode
        );
      }
    });

    defectDescriptionFields?.forEach((defectDescriptionFieldName, backendIndex) => {
      if (defectDescriptionHierarchy[backendIndex]) {
        enrichWithParent(defectDescriptionHierarchy[backendIndex], null);

        let hierarchy = JSON.parse(JSON.stringify(defectDescriptionHierarchy[backendIndex]));

        if (selectedNodes[backendIndex] && selectedNodes[backendIndex] !== '-') {
          const node = findNode(hierarchy, selectedNodes[backendIndex]);

          // Ensure that the parent node exists before proceeding
          if (node && node.parent) {
            const parentHierarchie = findNode(hierarchy, node.parent);

            if (parentHierarchie) {
              parentHierarchie.children = parentHierarchie?.children?.filter(
                (child) => child.name === node.name
              );

              hierarchy = cutDepth(parentHierarchie, 2);
            } else {
              console.error(`Parent hierarchy node not found for node: ${node.name}`);
            }
          }
        } else {
          hierarchy = cutDepth(hierarchy, 1);
          newActiveNode[backendIndex] = hierarchy.name;
          newCurrentLevel[backendIndex] = 0;
        }
        newDisplayedTree[backendIndex] = hierarchy;
      }
    });

    setDisplayedTree(newDisplayedTree);
    setActiveNode(newActiveNode);
    setCurrentLevel(newCurrentLevel);
  };

  // Recursively add parent information to all nodes
  const enrichWithParent = (tree, parent) => {
    if (tree) {
      tree.parent = parent?.name;

      if (tree?.children?.length > 0) {
        for (let child of tree.children) {
          enrichWithParent(child, tree);
        }
      }
    }
  };

  const cutDepth = (tree, depth) => {
    if (tree) {
      if (depth === 0) {
        tree.children = [];
      } else {
        if (tree?.children?.length > 0) {
          for (let child of tree.children) {
            cutDepth(child, depth - 1);
          }
        }
      }
    }
    return tree;
  };

  const getDefectDescriptionFields = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionFieldsAndLevelsEndpoint);
      if (response.data.defectDescriptionFields) {
        const newDefectDescriptionFields = [...response.data.defectDescriptionFields];
        const newDefectDocumentationLevels = { ...response.data.defectDocumentationLevels };
        const newSortedRows = { ...response.data.defectDescriptionFieldsSettings };
        setDefectDescriptionFields(newDefectDescriptionFields);
        setDefectDocumentationLevels(newDefectDocumentationLevels);
        setSortedRows(newSortedRows);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionHierarchie = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionHierarchieEndpoint);
      if (response.data.defectDescriptionHierarchie) {
        updateDefectDescriptionHierarchie(response.data.defectDescriptionHierarchie);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const saveDefectDescriptionFields = async (updatedDefectDescriptionFields = null) => {
    try {
      // Use the parameter value if provided, otherwise use the existing state value
      const fieldsToSave = updatedDefectDescriptionFields || defectDescriptionFields;

      const response = await axiosClient.post(defectDescriptionFieldsEndpoint, {
        defectDescriptionFields: fieldsToSave.filter((item) => item !== ''),
      });

      if (response.data.defectDescriptionFields) {
        const newDefectDescriptionFields = [...response.data.defectDescriptionFields];
        setDefectDescriptionFields(newDefectDescriptionFields);

        // Update the sorted rows with the new, deleted, or updated fields but keep the sorting
        const updatedSortedRows = sortedRows.defectDescriptionFieldsFrontendSorting
          .filter((row) => newDefectDescriptionFields.includes(row)) // Remove deleted rows
          .map((row) => {
            const index = newDefectDescriptionFields.findIndex((field) => field === row);
            return index !== -1 ? newDefectDescriptionFields[index] : row;
          });

        // Find and add any new fields that aren't already in sortedRows
        const addedFields = newDefectDescriptionFields.filter(
          (field) => !sortedRows.defectDescriptionFieldsFrontendSorting.includes(field)
        );
        const finalSortedRows = [...updatedSortedRows, ...addedFields];

        setSortedRows({
          ...sortedRows,
          defectDescriptionFieldsFrontendSorting: finalSortedRows,
        });
        setNewFieldName('');
        setIsEditInputOpen(false);
        enqueueSnackbar(t('defectDescriptionFieldsUpdatedSuccessfully'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenSavingDefectDescriptionFields'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateDefectDescriptionFieldsFrontendSortingAndOptionality = async (updatedFields = {}) => {
    try {
      const updatedSortedRows = _.merge({}, sortedRows, updatedFields);
      console.log('sortedRows: ', updatedSortedRows);
      await axiosClient.patch(defectDescriptionFieldsEndpoint, {
        defectDescriptionFieldsSettings: updatedSortedRows,
      });
      enqueueSnackbar(t('defectDescriptionFieldsUpdated'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorWhenUpdatingDefectDescriptionFields'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const handleRowOrderChange = (params) => {
    const { oldIndex, targetIndex } = params;
    const reorderedRows = [...sortedRows.defectDescriptionFieldsFrontendSorting];
    const [movedRow] = reorderedRows.splice(oldIndex, 1);
    reorderedRows.splice(targetIndex, 0, movedRow);

    // Update the state
    setSortedRows({ ...sortedRows, defectDescriptionFieldsFrontendSorting: reorderedRows });

    // Call the update function with the reordered sorting
    updateDefectDescriptionFieldsFrontendSortingAndOptionality({
      defectDescriptionFieldsFrontendSorting: reorderedRows,
    });
  };

  const handleAddToTable = async () => {
    if (newFieldName.trim()) {
      await saveDefectDescriptionFields([...defectDescriptionFields, newFieldName]);
    } else {
      enqueueSnackbar(t('Field name must not be empty'), { variant: 'warning' });
    }
  };

  const [editingRowId, setEditingRowId] = useState(null);
  const [editingFieldValue, setEditingFieldValue] = useState('');

  const handleEditClick = (row) => {
    setEditingRowId(row.id);
    setEditingFieldValue(row.defectDescriptionFields);
  };

  const handleSaveClick = async (row) => {
    const newDefectDescriptionFields = [...defectDescriptionFields];
    const backendIndex = backendIndexMap[row.id]; // Get the backend index corresponding to the row id
    newDefectDescriptionFields[backendIndex] = editingFieldValue;

    await saveDefectDescriptionFields(newDefectDescriptionFields);
    setEditingRowId(null);
  };

  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditingFieldValue('');
  };

  const handleDeleteClick = async (row) => {
    const newDefectDescriptionFields = [...defectDescriptionFields];
    const backendIndex = backendIndexMap[row.id]; // Get the backend index corresponding to the row id
    newDefectDescriptionFields.splice(backendIndex, 1);

    await saveDefectDescriptionFields(newDefectDescriptionFields);
  };

  const columns = [
    {
      headerName: t('index'),
      field: 'index',
      editable: false,
      flex: 0.25,
      width: 50,
    },
    {
      headerName: t('defectDescriptionFields'),
      field: 'defectDescriptionFields',
      editable: false,
      flex: 0.5,
      width: 100,
      renderCell: (params) => {
        if (editingRowId === params.row.id) {
          return (
            <TextField
              value={editingFieldValue}
              onChange={(e) => setEditingFieldValue(e.target.value)}
              fullWidth
            />
          );
        }
        return params.value;
      },
    },
    {
      headerName: t('isMandatory'),
      field: 'isMandatory',
      flex: 0.5,
      editable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Switch
            checked={
              sortedRows.defectDescriptionFieldsMandatory &&
              sortedRows.defectDescriptionFieldsMandatory[params.row.defectDescriptionFields] !==
                undefined
                ? sortedRows.defectDescriptionFieldsMandatory[params.row.defectDescriptionFields]
                : true
            }
            onChange={async (e) => {
              const updatedMandatory = {
                ...sortedRows.defectDescriptionFieldsMandatory,
                [params.row.defectDescriptionFields]: e.target.checked,
              };

              // Update the state
              setSortedRows({
                ...sortedRows,
                defectDescriptionFieldsMandatory: updatedMandatory,
              });

              // Call the update function with the specific updated field
              updateDefectDescriptionFieldsFrontendSortingAndOptionality({
                defectDescriptionFieldsMandatory: updatedMandatory,
              });
            }}
          />
        );
      },
    },
    {
      headerName: t('actions'),
      field: 'actions',
      flex: 0.5,
      editable: false,
      width: 100,
      renderCell: (params) => {
        if (editingRowId === params.row.id) {
          return (
            <>
              <IconButton onClick={() => handleSaveClick(params.row)}>
                <Save />
              </IconButton>
              <IconButton onClick={handleCancelClick}>
                <Cancel />
              </IconButton>
            </>
          );
        }
        return (
          <>
            <IconButton onClick={() => handleEditClick(params.row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  async function uploadNewDefectDescriptionHierarchie(
    defectDescriptionHierarchieFile,
    defectDescriptionFieldName
  ) {
    // Ensure the file is passed correctly
    if (!defectDescriptionHierarchieFile) {
      enqueueSnackbar(t('File is required'), { variant: 'error' });
      return;
    }

    // When uploading save the fields first
    await saveDefectDescriptionFields();

    // Create a FormData object
    const formData = new FormData();
    formData.append('defectDescriptionHierarchieFile', defectDescriptionHierarchieFile);
    formData.append('defectDescriptionFieldName', defectDescriptionFieldName);

    // Save File to Server
    try {
      const response = await axiosClient.post(defectDescriptionHierarchieFileEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.defectDescriptionHierarchie) {
        await getDefectDescriptionHierarchie();
        enqueueSnackbar(t('defectDescriptionHierarchieUplodedSuccessfully'), {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(t('backendErrorSnacks.' + error.response?.data?.message), {
        variant: 'error',
      });
      console.error('Error:', error);
    }
  }

  const tableRows = useMemo(
    () =>
      sortedRows.defectDescriptionFieldsFrontendSorting?.map((field, index) => ({
        id: index,
        index: index + 1,
        defectDescriptionFields: field,
      })) || [],
    [sortedRows.defectDescriptionFieldsFrontendSorting]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGridPro
              rows={tableRows}
              columns={columns}
              rowReordering
              autoHeight
              onRowOrderChange={handleRowOrderChange}
              onRowClick={(params) => setActiveIndex(params.row.id)}
              rowSelectionModel={[activeIndex]}
              disableMultipleSelection
            />
          </Grid>
          {isEditInputOpen && (
            <Grid item xs={12} gap={2} display="flex" direction="column">
              <TextField
                label={t('Field Name')}
                value={newFieldName}
                onChange={(e) => setNewFieldName(e.target.value)}
                fullWidth
              />
              <Button variant="qualitatio" color="primary" fullWidth onClick={handleAddToTable}>
                {t('addToTable')}
              </Button>
            </Grid>
          )}
          <Grid item xs={12} display="flex" justifyContent="center">
            <IconButton
              variant="qualitatio"
              onClick={() => setIsEditInputOpen(!isEditInputOpen)}
              squared={true}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={8} xs={12}>
        <QualitatioSlider
          activeIndex={activeIndex}
          onActiveIndexChange={setActiveIndex}
          spaceBetween={50}
        >
          {sortedRows.defectDescriptionFieldsFrontendSorting
            ?.filter((item) => item !== '')
            .map((defectDescriptionFieldName, sortedIndex) => {
              const backendIndex = backendIndexMap[sortedIndex];

              return (
                <SwiperSlide key={backendIndex}>
                  <Card variant="configurator" style={{ height: '90vh', overflow: 'hidden' }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{ marginTop: '20px' }}
                    >
                      <QualitatioDropzone
                        acceptedFileTypes={{ 'text/csv': ['.csv'], 'application/vnd.ms-excel': ['.csv'] }}
                        onDrop={(file) =>
                          uploadNewDefectDescriptionHierarchie(file, defectDescriptionFieldName)
                        }
                        enablePreview={false}
                        argsForUpload={[defectDescriptionFieldName]}
                      >
                        <FaFileCsv size={30} />
                        <Typography>{t('dropCSVHere')}</Typography>
                        <Typography>{t('orClickToUpload')}</Typography>
                      </QualitatioDropzone>
                      <Typography variant="h6">{defectDescriptionFieldName}</Typography>
                      {displayedTree[backendIndex] && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            direction="row"
                          >
                            <Grid item>
                              <Typography>{t('defectDocumentationLevel')}</Typography>
                            </Grid>
                            <Grid item>
                              <select
                                value={defectDocumentationLevels[defectDescriptionFieldName] || -1}
                                onChange={(event) =>
                                  updateDefectDocumentationLevels(
                                    defectDescriptionFieldName,
                                    event.target.value
                                  )
                                }
                              >
                                <option value={-1}>{t('lowestLevel')}</option>
                                {Array.from(
                                  Array(
                                    getMaxLevel(defectDescriptionHierarchie[backendIndex]) + 1
                                  ).keys()
                                ).map((level) => (
                                  <option key={level} value={level}>
                                    {level}
                                  </option>
                                ))}
                              </select>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="qualitatio"
                                color="primary"
                                onClick={() => saveDefectDocumentationLevels()}
                              >
                                {t('save')}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            style={{ marginTop: '20px' }}
                            justifyContent="space-between"
                          >
                            <Grid item xs={6}>
                              <Typography>
                                {t('currentLevel')}: {currentLevel[backendIndex]}
                              </Typography>
                            </Grid>
                          </Grid>
                          <AnimatedTree
                            data={displayedTree[backendIndex]}
                            height={400}
                            width={400}
                            animated
                            svgProps={{
                              transform: 'rotate(90)',
                              className: 'tree',
                            }}
                            gProps={{
                              onClick: (e, n) => handleClick(e, n, defectDescriptionFieldName),
                            }}
                            nodeRadius={15}
                            keyProp="name"
                            textProps={{
                              transform: 'rotate(290)',
                              className: 'nodeText',
                              dy: 20,
                              dx: 0,
                            }}
                            nodeShape="circle"
                            activeNode={activeNode[backendIndex]}
                            steps={50}
                          />
                        </>
                      )}
                    </Box>
                  </Card>
                </SwiperSlide>
              );
            }) || []}
        </QualitatioSlider>
      </Grid>
    </Grid>
  );
};

export default DefectHierarchieSettings;
