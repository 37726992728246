import { Avatar, Tooltip } from '@mui/material';
import React from 'react';
import { filesRoot } from '../../api/endpoints';

export default function QualitatioAvatar({ name, size, profileImage, creatorId }) {
  return (
    <Tooltip title={name}>
      <Avatar
        sx={{ width: size, height: size, bgColor: profileImage ? 'transparent' : '#1FBEBD' }}
        src={filesRoot + 'users/profileImages/' + creatorId + '/profileImage.png'}
      >
        {profileImage
          ? null
          : name
              .split(' ')
              .map((n) => n[0])
              .join('')}
      </Avatar>
    </Tooltip>
  );
}
