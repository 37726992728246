/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/auth.store';
import './style.css';

export const Footer = ({ className }) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const { t } = useTranslation();

  const user = useAuthStore((state) => state.user);
  return (
    <>
      {user &&
        !currentPage.startsWith('/intelligent_testing/recommender') &&
        !currentPage.startsWith('/efficient_testing/recommender') &&
        !currentPage.startsWith('/setup') && (
          <div className={`footer ${className}`}>
            <div className="frame-5">
              <div className="text-wrapper-3">Datagon AI GmbH</div>
            </div>
            <div className="frame-5">
              <div className="FAQ-LEGAL-CONTACT">
                {/*TODO add faq to website?! */}
                <Link to="https://datagon.ai/faq" target="_blank" rel="noopener noreferrer">
                  FAQ
                </Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to="https://datagon.ai/imprint/" target="_blank" rel="noopener noreferrer">
                  {t('legal')}
                </Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to="https://datagon.ai/contact" target="_blank" rel="noopener noreferrer">
                  {t('contact')}
                </Link>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
