import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Typography
} from '@mui/material';
import { useTranslation } from "react-i18next";

export default function ExamplesDialog({ open, onClose, fieldName, examples }) {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="div">
                        {t("examplesFor")}
                        <strong>{" " + fieldName}</strong>
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <List>
                    {examples.map((example, ind) => (
                        <ListItem style={{ margin: "3px 0px" }}>
                            {String(example)}
                        </ListItem>
                    ))}
                </List>
            </DialogContent>

            <DialogActions>
                {/* Any footer content would go here */}
            </DialogActions>
        </Dialog>
    );
}
