import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ROLES from '../../authentication/roleConst';
import { AvatarLabelGroup } from '../../components/AvatarLabelGroup';
import { useAuthStore } from '../../store/auth.store';
import './Menu.css';

export default function Menu({ setRecommenderMenuOpen }) {
  const { logout, user } = useAuthStore((state) => ({
    logout: state.logout,
    user: state.user,
  }));

  const navigate = useNavigate();

  const location = useLocation();

  const { t } = useTranslation();

  const currentPath = `${location.pathname}${location.search}`;

  const handleAccountNavigation = () => {
    setRecommenderMenuOpen(true);
    navigate('/efficient_testing/recommender/account');
  };

  const handleHomeNavigation = () => {
    setRecommenderMenuOpen(true);
    if (user.role === ROLES.ADMIN || user.role === ROLES.ET_MANAGER) {
      navigate('/home');
    } else if (user.role === ROLES.ET_USER) {
      navigate('/efficient_testing/recommender/select');
    }
  };

  return (
    <div className="blur-pop-up">
      <div className="div-2">
        <AvatarLabelGroup
          className="avatar-label-group-instance"
          size="lg"
          state="focus"
          statusIcon="online-indicator"
          supportingTextClassName="avatar-label-group-2"
          text={user.email}
          textClassName="design-component-instance-node"
          profileImage={user.profileImage}
          name={user.name}
        />
        <div onClick={handleHomeNavigation} style={{ width: '100%', cursor: 'pointer' }}>
          <div className="frame">
            <img className="img-2" alt="Home" src="/images/Home.png" />
            <div className="text-wrapper-2">{t('home').toUpperCase()}</div>
          </div>
        </div>

        <div onClick={handleAccountNavigation} style={{ width: '100%', cursor: 'pointer' }}>
          <div className="frame-2">
            <img className="img-2" alt="User" src="/images/User.png" />
            <div className="text-wrapper-2">{t('account').toUpperCase()}</div>
          </div>
        </div>

        <div
          className="frame-4"
          onClick={(e) => {
            e.preventDefault();
            setRecommenderMenuOpen(true);
            navigate(
              `/efficient_testing/recommender/status?lastPath=${encodeURIComponent(currentPath)}`
            );
          }}
          style={{ cursor: 'pointer' }}
        >
          <img className="img-2" alt="Trending up" src="/images/Status.png" />
          <div className="text-wrapper-2">{t('status').toUpperCase()}</div>
        </div>

        <div className="logout-group" onClick={() => logout(navigate)}>
          <div className="logout-text">{t('logout')}</div>
          <img className="logout-icon" alt="Vector" src="/images/ChevronRight.png" />
        </div>
        <div className="back-group" onClick={() => setRecommenderMenuOpen(true)}>
          <img
            className="back-icon"
            alt="Vector"
            src="/images/ChevronRight.png"
            style={{ transform: 'rotate(180deg)' }}
          />
          <div className="back-text">{t('back')}</div>
        </div>
      </div>
    </div>
  );
}
