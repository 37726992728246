import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ApiConnectionForm({ readonly, formik }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const authenticationMethods = [
    { label: 'Token', value: 'token' },
    { label: 'Password', value: 'password' },
  ];

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <Typography align="left" variant="body2" textAlign="center">
        {t('apiConnectionProperties')}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: '5px' }}>
        {/* URL Field */}
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <TextField
              id="api.url"
              name="api.url"
              label="API URL"
              placeholder="example.com/api"
              value={formik.values.api.url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.api?.url && Boolean(formik.errors.api?.url)}
              helperText={formik.touched.api?.url && formik.errors.api?.url}
            />
          </FormControl>
        </Grid>

        {/* Port Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="api.port"
              name="api.port"
              label="Port"
              type="number"
              value={formik.values.api.port}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.api?.port && Boolean(formik.errors.api?.port)}
              helperText={formik.touched.api?.port && formik.errors.api?.port}
            />
          </FormControl>
        </Grid>

        {/* Security Protocol Field */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="api.securityProtocol"
              name="api.securityProtocol"
              label="Security Protocol"
              value={formik.values.api.securityProtocol}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.api?.securityProtocol && Boolean(formik.errors.api?.securityProtocol)
              }
              helperText={
                formik.touched.api?.securityProtocol && formik.errors.api?.securityProtocol
              }
            />
          </FormControl>
        </Grid>

        {/* Authentication Method Field */}
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <Autocomplete
              id="api.authentication.method"
              name="api.authentication.method"
              options={authenticationMethods}
              getOptionLabel={(option) => option.label}
              value={
                authenticationMethods.find(
                  (method) => method.value === formik.values.api?.authentication?.method,
                ) || null
              }
              onChange={(event, newValue) => {
                formik.setFieldValue('api.authentication.method', newValue?.value);
                // Reset credentials when changing authentication method
                formik.setFieldValue('api.authentication.credentials.token', '');
                formik.setFieldValue('api.authentication.credentials.username', '');
                formik.setFieldValue('api.authentication.credentials.password', '');
              }}
              renderInput={(params) => <TextField {...params} label="Authentication Method" />}
            />
          </FormControl>
        </Grid>

        {/* Conditional Token or Username and Password Fields */}
        {formik.values.api.authentication?.method === 'token' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="api.authentication.credentials.token"
                name="api.authentication.credentials.token"
                label="Token"
                value={formik.values.api.authentication.credentials.token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.api?.authentication?.credentials?.token &&
                  Boolean(formik.errors.api?.authentication?.credentials?.token)
                }
                helperText={
                  formik.touched.api?.authentication?.credentials?.token &&
                  formik.errors.api?.authentication?.credentials?.token
                }
              />
            </FormControl>
          </Grid>
        )}

        {formik.values.api.authentication?.method === 'password' && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  autoComplete="off"
                  id="api.authentication.credentials.username"
                  name="api.authentication.credentials.username"
                  label="Username"
                  value={formik.values.api.authentication.credentials.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.api?.authentication?.credentials?.username &&
                    Boolean(formik.errors.api?.authentication?.credentials?.username)
                  }
                  helperText={
                    formik.touched.api?.authentication?.credentials?.username &&
                    formik.errors.api?.authentication?.credentials?.username
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="api.authentication.credentials.password"
                  name="api.authentication.credentials.password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.api.authentication.credentials.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.api?.authentication?.credentials?.password &&
                    Boolean(formik.errors.api?.authentication?.credentials?.password)
                  }
                  helperText={
                    formik.touched.api?.authentication?.credentials?.password &&
                    formik.errors.api?.authentication?.credentials?.password
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
