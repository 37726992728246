import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from './TestCard.module.css';

const TestCardsNavigation = ({
  cards,
  currentCardIndex,
  setCurrentCardIndex,
  handlePreviousCard,
  handleNextCard,
}) => {
  const theme = useTheme();
  return (
    <div className={styles.testCardNavigation}>
      {cards && cards.length > 0 && (
        <IconButton
          className={styles.testCardControlElement}
          variant="qualitatio"
          onClick={handlePreviousCard}
          squared={true}
          style={{
            backgroundColor: theme.palette.success.secondary,
          }}
        >
          <ArrowBackIosNewRoundedIcon color="primary" />
        </IconButton>
      )}
      {cards &&
        cards.map((el, index) => {
          return (
            <svg
              key={index}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="basis-nav-buttons"
              onClick={() => setCurrentCardIndex(index)}
            >
              <circle
                cx="10"
                cy="10"
                r="9.75"
                fill={
                  index === currentCardIndex
                    ? 'var(--m-3refprimaryprimary-60)'
                    : 'var(--m3syslightsecondary-fixed)'
                }
                stroke="#0E0D13"
                strokeWidth="0.5"
              />
            </svg>
          );
        })}
      {cards && cards.length > 0 && (
        <IconButton
          className={styles.testCardControlElement}
          variant="qualitatio"
          squared={true}
          onClick={handleNextCard}
          style={{
            backgroundColor: theme.palette.success.secondary,
          }}
        >
          <ArrowForwardIosRoundedIcon color="primary" />
        </IconButton>
      )}
    </div>
  );
};

TestCardsNavigation.propTypes = {
  cards: PropTypes.array.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  handlePreviousCard: PropTypes.func.isRequired,
  handleNextCard: PropTypes.func.isRequired,
};

export default TestCardsNavigation;
