import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axiosClient from '../../api/axiosClient';
import { getControlChartDataEndpoint } from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import TrendsChart from './components/TrendsChart';
import DashboardSelector from './components/DashboardSelector';
import OverlapChart from './components/OverlapChart';
import DashboardTour from './components/DashboardTour';
import AlertsOverviewTable from './components/AlertsOverviewTable';

// Helper function to convert dayjs date range to the time period object needed by the API (UNIX timestamp in seconds)
const _convertDateRangeToTimePeriod = (dateRange) => {
  return {
    earliest: dateRange[0].unix(),
    latest: dateRange[1].unix(),
  };
};

export const Dashboard = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('dashboard'));
  const { enqueueSnackbar } = useSnackbar();

  // States:
  // Default date range is the current day
  const [selectedDateRange, setSelectedDateRange] = useState([dayjs().startOf('day'), dayjs()]);
  const [selectedMonitorSystemId, setSelectedMonitorSystemId] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [data, setData] = useState([]);
  const [ucl, setUcl] = useState([]);
  const [centerLine, setCenterLine] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [alertStatuses, setAlertStatuses] = useState([]);

  const getControlChartData = async () => {
    // The monitor system should be set before fetching the data
    if (!selectedMonitorSystemId) {
      return; // Wait until the selected system is set
    }
    try {
      const response = await axiosClient.post(
        getControlChartDataEndpoint + '/' + selectedMonitorSystemId,
        {
          timePeriod: _convertDateRangeToTimePeriod(selectedDateRange),
          filters: selectedFilters,
        }
      );
      setData(response.data.data);
      setUcl(response.data.upperControlLimit);
      setCenterLine(response.data.centerLine);
      setTimestamps(response.data.timestamps);
      setAlertStatuses(response.data.alertStatuses);
    } catch (error) {
      enqueueSnackbar(t('ei.errorLoadingControlChart'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    getControlChartData();
  }, [selectedMonitorSystemId, selectedDateRange, selectedFilters]);

  return (
    <PageFrame title={t('ei.dashboard')} Icon={BarChartRoundedIcon}>
      <div className="dashboard">
        <DashboardSelector
          selectedMonitorSystemId={selectedMonitorSystemId}
          setSelectedMonitorSystemId={setSelectedMonitorSystemId}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
        />
        <div className="tour-charts">
          <Typography variant="h3">{t('ei.alertCharts')}</Typography>
          <TrendsChart
            width={800}
            height={400}
            centerLine={centerLine}
            controlLimit={ucl}
            dataLine={data}
            timestamps={timestamps}
            selectedDateRange={selectedDateRange}
          />
          <OverlapChart
            dataLine={data}
            centerLine={centerLine}
            controlLimit={ucl}
            timestamps={timestamps}
          />
        </div>
        <div className="tour-alerts-table">
          <AlertsOverviewTable
            dataLine={data}
            controlLimit={ucl}
            timestamps={timestamps}
            alertStatuses={alertStatuses}
          />
        </div>
        <DashboardTour />
      </div>
    </PageFrame>
  );
};

Dashboard.propTypes = {
  setPageName: PropTypes.func.isRequired,
};

export default Dashboard;
