import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: 'green',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#970C0C',
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: '#FFB800',
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: '#1FBEBD',
    },
}));

const QualitatioSnackbarProvider = ({ children }) => {
    return (
        <SnackbarProvider
            maxSnack={3}
            action={(key) => (
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => closeSnackbar(key)}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
        >
            {children}
        </SnackbarProvider>
    );
}

export default QualitatioSnackbarProvider;
