import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../../api/axiosClient';
import { getAllCardsFromMongoEndpoint } from '../../../../../api/endpoints';
import { QualitatioDialog } from '../../../../../components';
import TestCard from '../../../testCards/TestCard';
import TestCardVisual from '../../../testCards/TestCardVisual';

const CardSelectionModal = ({
  cardSeletionModalOpen,
  setCardSelectionModalOpen,
  qualityStation,
  cardLayout,
  orderIdentifierMapping,
  handleClose,
  cardSelected,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [allCards, setAllCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getAllCards = async () => {
    try {
      const response = await axiosClient.get(getAllCardsFromMongoEndpoint);
      setAllCards(response.data.cards.filter((card) => card.qualityStation === qualityStation));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (cardSeletionModalOpen) {
      getAllCards();
    }
  }, [cardSeletionModalOpen]);

  const updateCurrentCardIndex = (direction) => {
    let newCardIndex = 0;
    if (direction === 'inc') {
      newCardIndex = allCards?.length > 0 ? (currentIndex + 1) % allCards.length : 0;
    } else if (direction === 'dec') {
      newCardIndex = allCards?.length > 0 ? (currentIndex - 1) % allCards.length : 0;
      if (newCardIndex < 0) newCardIndex = allCards.length - 1;
    }
    setCurrentIndex(newCardIndex);
  };

  return (
    <QualitatioDialog
      title={t('selectTestCard')}
      description={t('pleaseSelectTheTestCardWhichWillHelpDetectTheDefect')}
      open={cardSeletionModalOpen}
      onClose={() => setCardSelectionModalOpen(false)}
      actions={[
        {
          label: t('save'),
          onClick: () => {
            cardSelected(allCards[currentIndex]);
            setCardSelectionModalOpen(false);
          },
          order: 'primary',
        },
        {
          label: t('cancel'),
          onClick: () => {
            setCardSelectionModalOpen(false);
          },
          order: 'secondary',
        },
      ]}
    >
      <div className="cards-overview">
        <div className="test-card">
          {allCards?.length > 0 ? (
            cardLayout ? (
              <TestCardVisual
                id={allCards[currentIndex]?.id || ''}
                editable={false}
                title={allCards[currentIndex]?.title}
                images={allCards[currentIndex]?.images}
                testObject={allCards[currentIndex]?.testObject}
                testMethod={allCards[currentIndex]?.testMethod}
                testLocation={allCards[currentIndex]?.testLocation}
                testComment={allCards[currentIndex]?.testComment}
                orderIdentifier={allCards[currentIndex]?.orderIdentifier}
                generation={t(allCards[currentIndex]?.type)}
                explanation={allCards[currentIndex]?.explanation}
                index={currentIndex}
                numberCards={allCards?.length}
                qualityStation={qualityStation}
                orderIdentifierMapping={orderIdentifierMapping}
                currentCardIndex={currentIndex}
                editors={allCards[currentIndex]?.editors}
                creator={allCards[currentIndex]?.creator}
                creationDate={allCards[currentIndex]?.creationDate}
              />
            ) : (
              <TestCard
                id={allCards[currentIndex]?.id || ''}
                editable={false}
                title={allCards[currentIndex]?.title}
                images={allCards[currentIndex]?.images}
                testObject={allCards[currentIndex]?.testObject}
                testMethod={allCards[currentIndex]?.testMethod}
                testLocation={allCards[currentIndex]?.testLocation}
                testComment={allCards[currentIndex]?.testComment}
                orderIdentifier={allCards[currentIndex]?.orderIdentifier}
                generation={t(allCards[currentIndex]?.type)}
                explanation={allCards[currentIndex]?.explanation}
                index={currentIndex}
                numberCards={allCards?.length}
                qualityStation={qualityStation}
                orderIdentifierMapping={orderIdentifierMapping}
                editors={allCards[currentIndex]?.editors}
                creator={allCards[currentIndex]?.creator}
                creationDate={allCards[currentIndex]?.creationDate}
              />
            )
          ) : (
            <div className="no-test-cards">{t('noTestCardsAvailable')}</div>
          )}
        </div>
        <div className="test-card-manipulation">
          <>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => updateCurrentCardIndex('dec')}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <ArrowBackIosNewRoundedIcon color="primary" />
            </IconButton>
            <IconButton
              className="test-card-manipulation-element"
              variant="qualitatio"
              squared={true}
              onClick={() => updateCurrentCardIndex('inc')}
              style={{
                backgroundColor: theme.palette.success.secondary,
                width: '36px',
                height: '36px',
              }}
            >
              <ArrowForwardIosRoundedIcon color="primary" />
            </IconButton>
          </>
        </div>
      </div>
    </QualitatioDialog>
  );
};

export default CardSelectionModal;
