import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import { getTestCardFeedbackEndpoint, getTestCardPerformanceEndpoint } from '../../api/endpoints';
import QualitatioRating from '../../components/QualitatioRating/QualitatioRating';
import './CardFeedback.css';

const CardFeedback = ({ cardID }) => {
  const { t } = useTranslation();
  const [testCardPerformance, setTestCardPerformance] = useState(null);
  const [testCardFeedback, setTestCardFeedback] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImage, setSelectedImage] = useState(null); // Step 1: Add state to hold selected image URL

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Step 1: Update selected image URL in state
  };

  const handleCloseImage = () => {
    setSelectedImage(null); // Step 1: Reset selected image URL to close modal
  };

  useEffect(() => {
    getTestCardPerformance();
    getTestCardFeedback();
  }, [cardID]);

  const getTestCardPerformance = async () => {
    try {
      const response = await axiosClient.post(getTestCardPerformanceEndpoint, {
        cardID: cardID,
      });

      console.log('response:', response.data);
      if (response.data.testCardPerformance) {
        setTestCardPerformance(response.data.testCardPerformance);
      } else {
        enqueueSnackbar(t('errorWhileGettingTestCardPerformance'), {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getTestCardFeedback = async () => {
    try {
      const response = await axiosClient.post(getTestCardFeedbackEndpoint, {
        cardID: cardID,
      });

      console.log('Full Response:', response);

      if (response.data.testCardFeedback) {
        setTestCardFeedback(response.data.testCardFeedback);
      } else {
        enqueueSnackbar(t('errorWhileGettingTestCardFeedback'), {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const time = testCardPerformance?.testCardPerformanceTimeSeries?.map(
    (entry) => new Date(entry.time)
  );
  const successRates = testCardPerformance?.testCardPerformanceTimeSeries?.map((entry) =>
    parseFloat(entry.successRate)
  );

  const series = [
    {
      data: successRates,
      type: 'line',
      label: 'Success Rate',
      area: false,
      stack: 'total',
      showMark: false,
    },
  ];

  return (
    <div className="test-card-stats-wrapper">
      {/* Test Card Performance Section */}
      <div className="test-card-performance">
        <div className="performance-rate">{testCardPerformance?.testCardPerformance}</div>
        <Box
          className="performance-graph"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {series?.length > 0 && time?.length > 0 ? (
            <LineChart
              className="nathan-chart"
              width={400}
              height={300}
              series={series}
              xAxis={[
                {
                  scaleType: 'time',
                  data: time,
                  min: time[0]?.getTime(),
                  max: time[time.length - 1]?.getTime(),
                },
              ]}
              sx={{
                '--ChartsLegend-itemMarkSize': '10px',
              }}
            />
          ) : (
            <div className="no-data">{t('noDataAvailable')}</div>
          )}
        </Box>
      </div>

      <div className="separator"></div>

      {/* Test Card Feedback Section */}
      <div className="test-card-feedback">
        <div>
          <QualitatioRating
            size="3rem"
            name="image-rating"
            value={Number(testCardFeedback?.testCardAverageFeedback)}
            precision={0.1}
            readOnly
          />

          <div className="average-feedback">
            <span>
              {t('averageAllTimeRating')} {testCardFeedback?.testCardAverageFeedback.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="feedback-comments">
          {testCardFeedback?.testCardComments.map((comment, idx) => (
            <>
              {comment.comment?.length > 0 && (
                <div key={idx} className="users-feedback">
                  <div className="comment">
                    <div className="comment-user">
                      <img
                        src={comment.userImage || '/images/avatar.png'}
                        alt={comment.userName}
                        className="user-image"
                      />
                      <div className="user-name-time-wrapper">
                        <span className="user-name">{comment.userName}</span>
                        <span className="comment-time">
                          {new Date(comment.time).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Feedback Container */}
                  <div className="rating-container">
                    <div className="rating-row">
                      <Typography variant="subtitle1" className="label">
                        {t('imageRating')}
                      </Typography>
                      <div className="rating-value">
                        <QualitatioRating
                          name="image-rating"
                          value={comment?.imageRating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="rating-row">
                      <Typography variant="subtitle1" className="label">
                        {t('descriptionRating')}
                      </Typography>
                      <div className="rating-value">
                        <QualitatioRating
                          name="description-rating"
                          value={comment?.descriptionRating}
                          precision={0.5}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="rating-row">
                      <Typography variant="subtitle1" className="label">
                        {t('mostValuableInformation')}
                      </Typography>

                      <div className="rating-value">
                        {comment?.valuableInformation && (
                          <>
                            {console.log('Valuable key:', comment)}
                            {console.log('Valuable value:', comment.valuableInformation)}
                            {console.log('Valuable type:', comment.valuableInformation)}

                            {comment.valuableInformation.type === 'text' && (
                              <Typography className="feedback-text" variant="subtitle1">
                                {`${comment.valuableInformation.key} : ${comment.valuableInformation.value}`}
                              </Typography>
                            )}
                            {comment.valuableInformation.type === 'image' && (
                              <div className="feedback-image-container">
                                {console.log('Rendering Image:', comment.valuableInformation.value)}
                                <img
                                  src={comment.valuableInformation.value}
                                  alt={comment.valuableInformation.key}
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleImageClick(comment.valuableInformation.value)
                                  }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="rating-row">
                      <Typography variant="subtitle1" className="label">
                        {t('comment')}
                      </Typography>
                      <div className="rating-value">
                        <Typography className="feedback-text" variant="subtitle1">
                          {comment?.comment}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>

      {/* Step 3: Modal for Enlarged Image */}
      {selectedImage && (
        <div className="image-modal" onClick={handleCloseImage}>
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{
              width: '80%',
              height: 'auto',
              maxHeight: '90vh',
              objectFit: 'contain',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CardFeedback;
