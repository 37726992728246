import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Particles from 'react-particles';
import { useNavigate } from 'react-router-dom';
import { loadFull } from 'tsparticles';
import { useRedirectIfAuthenticated } from '../components/customHooks ';
import QualitatioButton from '../components/QualitatioButton/QualitatioButton';
import LoadingPage from './LoadingPage';
import './WelcomePage.css';

function WelcomePage({ setPageName }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useRedirectIfAuthenticated();

  useEffect(() => {
    setPageName(t('welcome'));

    // Check if the user has already visited the page and if the stored timestamp is still valid
    const visitData = JSON.parse(sessionStorage.getItem('welcomePageVisited'));
    const now = new Date().getTime();

    if (visitData && visitData.timestamp && now - visitData.timestamp < 24 * 60 * 60 * 1000) {
      // If visited within the last 24 hours, skip the loading screen
      setLoading(false);
    } else {
      // Show loading screen and update the timestamp
      sessionStorage.setItem('welcomePageVisited', JSON.stringify({ timestamp: now }));

      const timer = setTimeout(() => {
        setLoading(false);
      }, 4000); // Simulate loading time

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [setPageName, t]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="welcome-page">
          <div className="particles" />
          <Particles
            id="tsparticles"
            url="/particlesLoginScreens.json"
            init={particlesInit}
            loaded={particlesLoaded}
          />
          <div className="frame">
            <div className="div-wrapper">
              <div className="text-wrapper-2">{t('welcomeToQualiatio')}</div>
            </div>
            <img className="logo" alt="Logo" src="/images/qualitatio.svg" />

            <div className="explanation">{t('welcomeMessageLoggedOut')}</div>
            <div className="frame-6">
              <div className="buttons">
                <QualitatioButton
                  text={t('signIn')}
                  order="primary"
                  onClick={() => navigate('/login')}
                  width={'100%'}
                  height={'5vh'}
                  fontSize={'calc(12px + 0.25vw)'}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                />
                <QualitatioButton
                  text={t('signUp')}
                  order="secondary"
                  onClick={() => navigate('/register')}
                  width={'100%'}
                  height={'5vh'}
                  fontSize={'calc(12px + 0.25vw)'}
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WelcomePage;
