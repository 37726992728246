import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import InputIcon from '@mui/icons-material/Input';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { FaCalendarAlt } from 'react-icons/fa';
import axiosClient from '../../api/axiosClient';
import { inputStreamsEndpoint } from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioChipArray from '../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import { useDisclosure } from '../../utils/customHooks';
import './InputConfigurator.css';
import ConnectionSetup from './components/ConnectionSetup';
import EditInputStreamOverlay from './components/EditInputStreamOverlay';
import ExamplesDialog from './components/ExamplesDialog';

export default function InputConfigurator({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('inputConfiguration'));

  const theme = useTheme();

  /* DATA INPUT STREAMS */
  const [inputStreams, setInputStreams] = useState([]);
  const [rows, setRows] = useState([]); // For the table

  useEffect(() => {
    setRows(
      inputStreams.map((el, indInputStream) => {
        return {
          //The following two fields are a quirk of the table, the tables needs an id that is an index number
          id: indInputStream,
          inputStreamID: el.id,
          //-------------------
          ...el,
        };
      }),
    );
  }, [inputStreams]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    getInputStreams();
    // setInputStreams([inputStreamsMock]);
  }, []);

  const getInputStreams = async () => {
    try {
      const response = await axiosClient.get(inputStreamsEndpoint);
      setInputStreams(response.data.inputStreams);
      // setInputStreams(inputStreamsMock);
    } catch (error) {
      enqueueSnackbar(t('errorWhileFetchingInputStreams'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const {
    isOpen: isShowExamples,
    onOpen: onShowExamples,
    onClose: onShowExamplesClose,
  } = useDisclosure();
  const {
    isOpen: isConnectionSetupOpen,
    onOpen: onConnectionSetup,
    onClose: onConnectionSetupClose,
  } = useDisclosure();

  const [selectedExamples, setSelectedExamples] = useState([]);
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const handleButtonClick = (fieldName, indInputStream, ind) => {
    setSelectedFieldName(fieldName);
    const inputStream = inputStreams.find((el) => el.id === indInputStream);
    setSelectedExamples(inputStream.streamFields[ind].examples);
    onShowExamples();
  };

  /* HANDLE EDIT INPUT STREAM */
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState([]);

  const openEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const closeEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  /* HANDLE INPUT STREAM ACTIVATION */
  const handleInputStreamActivation = async (inputStreamID, isActive) => {
    try {
      if (isActive) {
        const snackbarKey = enqueueSnackbar(t('activatingInputStream'), {
          variant: 'info',
          persist: true,
        });
        const response = await axiosClient.post(
          `${inputStreamsEndpoint}/${inputStreamID}/activate`,
        );
        setInputStreams(response.data.inputStreams);
        closeSnackbar(snackbarKey);
        enqueueSnackbar(t('inputStreamSuccessfullyActivated'), { variant: 'success' });
      } else {
        const shouldDeactivateInputStream = await confirmDeactivation(
          'Do you really want to deactivate the input stream?',
        );
        if (shouldDeactivateInputStream) {
          const snackbarKey = enqueueSnackbar(t('deactivatingInputStream'), {
            variant: 'info',
            persist: true,
          });
          const response = await axiosClient.post(
            `${inputStreamsEndpoint}/${inputStreamID}/deactivate`,
          );
          setInputStreams(response.data.inputStreams);
          closeSnackbar(snackbarKey);
          enqueueSnackbar(t('inputStreamSuccessfullyDeactivated'), { variant: 'success' });
        } else {
          // First confirmation denied
          console.log('Input Stream deactivation canceled.');
        }
      }
    } catch (error) {
      if (isActive) {
        enqueueSnackbar(t('inputStreamActivationFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('inputStreamDeactivationFailed'), { variant: 'error' });
      }
      console.error('Error:', error);
    }
  };

  const confirmDeactivation = async (message) => {
    return new Promise((resolve) => {
      if (window.confirm(message)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  /* HANDLE DELETE INPUT STREAM */
  const deleteInputStream = async (inputStreamID) => {
    try {
      // Show first confirmation dialog
      const shouldDeleteInputStream = await confirmDeletion(
        'Do you really want to delete the input stream?',
      );

      if (shouldDeleteInputStream) {
        const snackbarKey = enqueueSnackbar(t('deletingInputStream'), {
          variant: 'info',
          persist: true,
        });
        // Proceed with model deletion
        const response = await axiosClient.delete(`${inputStreamsEndpoint}/${inputStreamID}`);
        setInputStreams(response.data.inputStreams);
        closeSnackbar(snackbarKey);
        enqueueSnackbar(t('inputStreamSuccessfullyDeleted'), { variant: 'success' });
      } else {
        // First confirmation denied
        console.log('Input Stream deletion canceled.');
      }
    } catch (error) {
      enqueueSnackbar(t('inputStreamDeletionFailed'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const confirmDeletion = async (message) => {
    return new Promise((resolve) => {
      if (window.confirm(message)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const [isInputFieldDialogOpen, setIsInputFieldDialogOpen] = useState([]);

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 130,
      flex: 1,
      renderCell: (params) =>
        params.value === 'kafka' ? 'Kafka' : params.value === 'api' ? 'API' : t('database'),
    },
    {
      field: 'streamFields',
      headerName: t('inputFields'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          <Chip
            label={t('examples')}
            onClick={(e) => {
              setIsInputFieldDialogOpen((prevState) => {
                const newState = [...prevState];
                newState[params.row.id] = true;
                return newState;
              });
            }}
            sx={{ backgroundColor: theme.palette.success.secondary }}
            icon={<BsArrowRight />}
          />
          <QualitatioDialog
            title={t('inputFields')}
            open={isInputFieldDialogOpen[params.row.id]}
            onClose={() => setIsInputFieldDialogOpen([])}
            description={t('clickOnTheInputFieldToSeeExamples')}
            children={
              <>
                <QualitatioChipArray
                  chipData={params.value.map((el, ind) => ({
                    label: el.name,
                    onClick: () => handleButtonClick(el.name, params.row.id, ind),
                  }))}
                  backgroundColor={theme.palette.primary.main}
                />
                <ExamplesDialog
                  open={isShowExamples}
                  onClose={onShowExamplesClose}
                  fieldName={selectedFieldName}
                  examples={selectedExamples}
                />
              </>
            }
            actions={[
              {
                label: t('close'),
                onClick: () => setIsInputFieldDialogOpen([]),
                color: 'primary',
              },
            ]}
          />
        </>
      ),
    },
    {
      field: 'lastEvent',
      headerName: t('lastEvent'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value ? new Date(params.value).toLocaleString() : t('noEvents')}
          sx={{ backgroundColor: theme.palette.error.secondary }}
          icon={<FaCalendarAlt />}
        />
      ),
    },
    {
      field: 'firstEvent',
      headerName: t('firstEvent'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value ? new Date(params.value).toLocaleString() : t('noEvents')}
          sx={{ backgroundColor: theme.palette.warning.main }}
          icon={<FaCalendarAlt />}
        />
      ),
    },
    {
      field: 'size',
      headerName: t('size'),
      width: 80,
      flex: 1,
      renderCell: (params) => `${params.value} MB`,
    },
    {
      field: 'maxSize',
      headerName: t('maxSize'),
      width: 130,
      flex: 1,
      renderCell: (params) => `${params.value} MB`,
    },
    {
      field: 'active',
      headerName: t('active'),
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <Grid container alignItems="center" display="flex" height="100%">
          <Switch
            variant="qualitatio"
            checked={params.value}
            onChange={(e) =>
              handleInputStreamActivation(params.row.inputStreamID, e.target.checked)
            }
          />
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <div className="actions">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => openEditOverlay(params.row.id)}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '30px',
              height: '30px',
            }}
          >
            <EditRoundedIcon color="primary" />
          </IconButton>
          <EditInputStreamOverlay
            el={params.row}
            isOpen={isEditOverlayOpen[params.row.id]}
            onClose={() => closeEditOverlay(params.row.id)}
            setInputStreams={setInputStreams}
          />
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => deleteInputStream(params.row.inputStreamID)}
            background="rgba(186, 26, 26, 0.8)"
            style={{ backgroundColor: theme.palette.error.main, width: '30px', height: '30px' }}
          >
            <DeleteForeverRoundedIcon color="white" />
          </IconButton>
        </div>
      ),
    },
  ];

  const toggleInputStreamActivation = async (idx, active) => {
    const snackbarKey = enqueueSnackbar(t('startingUpdateOfInputStream'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.patch(`${inputStreamsEndpoint}/${inputStreams[idx].id}`, {
        active: active,
      });
      setInputStreams(response.data.inputStreams);
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('inputStreamSuccesfullyUpdated'), { variant: 'success' });
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('errorWhileUpdatingInputStream'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [multipleActions, setMultipleActions] = useState();

  const multipleActionsOptions = [
    { value: 'activate', label: t('activate') },
    { value: 'deactivate', label: t('deactivate') },
    { value: 'delete', label: t('delete') },
  ];

  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const handleMultipleActions = async () => {
    if (multipleActions?.value === 'activate') {
      for (const idx of selectedRowIDs) {
        if (!inputStreams[idx].active) {
          await toggleInputStreamActivation(idx, true); // Waits for completion
        }
      }
    } else if (multipleActions?.value === 'deactivate') {
      for (const idx of selectedRowIDs) {
        if (inputStreams[idx].active) {
          await toggleInputStreamActivation(idx, false); // Waits for completion
        }
      }
    } else if (multipleActions?.value === 'delete') {
      for (const idx of selectedRowIDs) {
        await deleteInputStream(inputStreams[idx].id); // Waits for completion
      }
    }
  };

  return (
    <PageFrame title={t('inputConfiguration')} Icon={InputIcon}>
      {!isConnectionSetupOpen && (
        <Grid container alignItems="center" direction="row" spacing={2}>
          {/* First two elements in one row */}
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">{t('connectedDataSources')}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Autocomplete
              value={multipleActions}
              onChange={(event, newValue) => {
                setMultipleActions(newValue);
              }}
              options={multipleActionsOptions}
              getOptionLabel={(option) => option.label} // Assuming each option has a label property
              sx={{ width: 250 }}
              renderInput={(params) => (
                <TextField {...params} label={t('selectAction')} variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button color="primary" onClick={handleMultipleActions} variant="qualitatio">
              {t('apply')}
            </Button>
          </Grid>

          <Grid item xs={12} sm={'auto'} style={{ textAlign: 'right', margin: '0 0 0 auto' }}>
            <Button variant="qualitatio" size="small" onClick={onConnectionSetup}>
              {t('addNewDataSource')}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <QualitatioTable
              style={{ marginTop: '20px' }}
              columns={columns}
              rows={rows}
              checkboxSelection={true}
              disableRowSelectionOnClick={true}
              onRowSelectionModelChange={(selection) => {
                if (selection.length === inputStreams.length) {
                  setSelectedRowIDs(rows.map((el, idx) => idx));
                } else {
                  setSelectedRowIDs(selection.map((el, idx) => el));
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      {/* NEW DATA SOURCE FORM */}
      {isConnectionSetupOpen && (
        <ConnectionSetup
          isConnectionSetupOpen={isConnectionSetupOpen}
          onConnectionSetupClose={onConnectionSetupClose}
          setInputStreams={setInputStreams}
        />
      )}
    </PageFrame>
  );
}
