import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { resetPasswordEndpoint } from '../api/endpoints';
import { getPasswordValidationSchema } from '../utils/validationSchemas';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPasswordPage({ setPageName }) {
  const { t, i18n } = useTranslation();
  setPageName(t('resetPassword'));

  const query = useQuery();
  const token = query.get('token');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: getPasswordValidationSchema(i18n.language),
    onSubmit: async (values) => {
      setLoading(true);
      console.log(values);
      console.log(token);
      try {
        const response = await axiosClient.post(
          resetPasswordEndpoint,
          {
            password: values.password,
          },
          {
            params: {
              token: token,
            },
          }
        );

        // Handle success (navigate to login or display a success message)
        navigate('/login');
      } catch (error) {
        // Handle error (display an error message or handle it accordingly)
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="reset-password-page">
      <div className="frame">
        <h2>{t('resetPassword')}</h2>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="New Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button color="primary" variant="contained" fullWidth type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Reset Password'}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
