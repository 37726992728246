import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { externalAPIUsageEndpoint } from '../../../api/endpoints';

const APISettings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [externalTextAPIUsage, setExternalTextAPIUsage] = useState(true);
  const [internalTextModelAvailable, setInternalTextModelAvailable] = useState(false);

  const [externalImageAPIUsage, setExternalImageAPIUsage] = useState(true);
  const [internalImageModelAvailable, setInternalImageModelAvailable] = useState(false);

  useEffect(() => {
    getExternalAPIUsageSettings();
  }, []);

  const getExternalAPIUsageSettings = async () => {
    try {
      const response = await axiosClient.get(externalAPIUsageEndpoint);
      if (response.data.externalAPIUsage) {
        setExternalTextAPIUsage(response.data.externalAPIUsage.externalTextAPIUsage);
        setExternalImageAPIUsage(response.data.externalAPIUsage.externalImageAPIUsage);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSaveAPISettings = async () => {
    try {
      const response = await axiosClient.post(externalAPIUsageEndpoint, {
        externalTextAPIUsage: externalTextAPIUsage,
        externalImageAPIUsage: externalImageAPIUsage,
      });
      if (response.data.externalAPIUsage) {
        setExternalTextAPIUsage(response.data.externalAPIUsage.externalTextAPIUsage);
        setExternalImageAPIUsage(response.data.externalAPIUsage.externalImageAPIUsage);
        enqueueSnackbar(t('externalAPIUsageUpdatedSuccessfully'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhenUpdatingExternalAPIUsage'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row" spacing={2} style={{ gap: '15px' }}>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Switch
                checked={externalTextAPIUsage}
                onChange={() => setExternalTextAPIUsage(!externalTextAPIUsage)}
              />
              <Typography ml={2}>
                {externalTextAPIUsage
                  ? t('externalTextAPIUsage')
                  : internalTextModelAvailable
                    ? t('internalTextModelUsage')
                    : t('TextModelAvailable')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center">
              <Switch
                checked={externalImageAPIUsage}
                onChange={() => setExternalImageAPIUsage(!externalImageAPIUsage)}
              />
              <Typography ml={2}>
                {externalImageAPIUsage
                  ? t('externalImageAPIUsage')
                  : internalImageModelAvailable
                    ? t('internalImageModelUsage')
                    : t('ImageModelAvailable')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="qualitatio" color="primary" onClick={handleSaveAPISettings}>
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default APISettings;
