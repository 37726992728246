import AppleIcon from '@mui/icons-material/Apple';
import CloudIcon from '@mui/icons-material/Cloud'; // Generic choice for cloud providers like Azure
import EmailIcon from '@mui/icons-material/Email'; // For email-based OAuth like Apple
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';
import KeyIcon from '@mui/icons-material/Key'; // Generic choice for providers like Okta or Auth0
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import RssFeedIcon from '@mui/icons-material/RssFeed'; // Generic choice for RSS or OpenID
import TwitterIcon from '@mui/icons-material/Twitter';
import { Slide } from '@mui/material';
import React from 'react';
import { LANG } from '../language_settings/languageConst';

export const oauthIconMapping = {
  github: GitHubIcon,
  google: GoogleIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  linkedin: LinkedInIcon,
  apple: AppleIcon,
  microsoft: MicrosoftIcon,
  azure: CloudIcon,
  openid: RssFeedIcon,
  okta: KeyIcon,
  auth0: KeyIcon,
  email: EmailIcon,
};

export const renderMultipleWordsInOneCell = (stringArray, numberWordsInOneLine, state, index) => {
  if (!stringArray) return;
  const showAllState = state[index];
  if (showAllState) {
    const wordsToShow = stringArray[0]?.length > 15 ? 1 : numberWordsInOneLine;
    return stringArray
      .reduce((acc, el_inner, innerIndex) => {
        const chunkIndex = Math.floor(innerIndex / wordsToShow);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }
        acc[chunkIndex].push(el_inner);
        return acc;
      }, [])
      .map((chunk, chunkIndex, array) => (
        <div key={chunkIndex}>
          {chunk.map((el_inner, innerIndex) => (
            <React.Fragment key={innerIndex}>
              {el_inner}
              {innerIndex !== chunk.length - 1 && ', '}
            </React.Fragment>
          ))}
          {chunkIndex !== array.length - 1 && ', '}
        </div>
      ));
  } else {
    const wordsToShow = stringArray[0]?.length > 10 ? 1 : numberWordsInOneLine;
    return (
      <div>
        {stringArray.slice(0, wordsToShow).map((el_inner, innerIndex) => {
          const truncatedWord = el_inner.length > 12 ? el_inner.slice(0, 12) + '...' : el_inner;
          return (
            <span key={innerIndex}>
              {truncatedWord}
              {innerIndex !== numberWordsInOneLine - 1 &&
                innerIndex !== stringArray.length - 1 &&
                ', '}
              {innerIndex === numberWordsInOneLine - 1 &&
                stringArray.length > numberWordsInOneLine &&
                !truncatedWord.endsWith('...') &&
                '... '}
            </span>
          );
        })}
      </div>
    );
  }
};

/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The input string.
 * @returns {string} - The input string with the first letter capitalized.
 * @throws {TypeError} - Throws an error if the input is not a string.
 */
export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string as input');
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const SlideUpTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isObject = (obj) => obj && typeof obj === 'object';

/**
 * Determines if two objects are deeply equal.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if the objects are deeply equal, false otherwise.
 */
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (isObject(obj1) && isObject(obj2)) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
  return false;
}

export const flagIconCode = (option) => (option === LANG.EN ? 'gb' : option);

export const valuesChanged = (formikStream, receivedStream) => {
  return JSON.stringify(formikStream) !== JSON.stringify(receivedStream);
};

/**
 * Formats a number to have a maximum of `digits` significant digits.
 * If the integer part of the number has more digits than `digits`,
 * it's rounded to the nearest integer. Otherwise, it's formatted to
 * have up to `digits` significant digits, maintaining the condition
 * for numbers smaller than a threshold based on `digits`.
 *
 * @param {number} value - The value to format.
 * @param {number} [digits=5] - The maximum number of significant digits.
 * @returns {string} The formatted number as a string.
 */
export const formatNumber = (value, digits = 5) => {
  // Ensure the input is a number
  if (typeof value !== 'number') {
    return value;
  }
  // Determine the threshold based on the number of digits (e.g., 9999 for 4 digits)
  const threshold = Math.pow(10, digits) - 1;

  if (value > threshold) {
    // If value is above the threshold, round to nearest integer
    return String(Math.round(value));
  } else {
    // Determine how many decimal places to keep
    const integerPartLength = Math.floor(value) === 0 ? 1 : Math.floor(value).toString().length;
    const decimalPlaces = digits - integerPartLength;

    // Format the number with the determined number of decimal places,
    // ensuring it does not exceed the total number of significant digits.
    const formattedValue = value.toFixed(decimalPlaces).slice(0, digits + 1);
    return parseFloat(formattedValue).toString();
  }
};
