import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { setNestedObjectValues } from 'formik';
import { useSnackbar } from 'notistack';

export default function QualitatioFormDialog({
  dialogTitle,
  dialogDescription,
  isOpen,
  onClose,
  FormComponent, // React component to be rendered inside the dialog, assuming it uses formik
  formProps = {}, // Additional props to be passed to the FormComponent
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // Create a ref to access the form content
  const formRef = useRef(null);
  const handleSubmitClick = () => {
    const form = formRef.current;
    if (form) {
      form.validateForm().then((validationErrors) => {
        if (Object.keys(validationErrors).length > 0) {
          form.setTouched(setNestedObjectValues(validationErrors, true));
          // Show each validation error as a separate snackbar message
          for (const error of Object.values(validationErrors)) {
            enqueueSnackbar(`${error}`, { variant: 'error' });
          }
        } else {
          form.submitForm();
        }
      });
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} variant="qualitatio">
      <DialogTitle>{dialogTitle}</DialogTitle>
      <Typography variant="subtitle1" align="center">
        {dialogDescription}
      </Typography>

      <DialogContent>
        {React.cloneElement(FormComponent, { ref: formRef, ...formProps })}
      </DialogContent>

      <DialogActions>
        <Button
          variant="qualitatio"
          onClick={handleSubmitClick}
          endIcon={<ArrowForwardIosIcon />}
          color="primary"
        >
          {t('save')}
        </Button>
        <Button
          variant="qualitatio"
          onClick={() => {
            onClose();
          }}
          startIcon={<ArrowBackIosNewRoundedIcon />}
          color="secondary"
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Define the prop types for the component
QualitatioFormDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  dialogDescription: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  FormComponent: PropTypes.object.isRequired,
  formProps: PropTypes.object,
};
