import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Joyride from 'react-joyride';

export default function DashboardTour({}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  // This makes the tour restart
  const [tourKey, setTourKey] = useState(Math.random());
  const [TOUR_STEPS, setTourSteps] = useState([]);

  useEffect(() => {
    // Initialize TOUR_STEPS once translations are available
    const steps = [
      {
        target: '.tour-monitor-selector',
        content: t('ei.tour.monitorSelectorStep'),
        disableBeacon: true, // This makes the tour to start automatically without clicking
      },
      {
        target: '.tour-filter-selector',
        content: t('ei.tour.filterSelectorStep'),
        disableBeacon: true,
      },
      {
        target: '.tour-date-range-selector',
        content: t('ei.tour.dateRangeSelectorStep'),
      },
      {
        target: '.tour-charts',
        content: t('ei.tour.chartsStep'),
      },
      {
        target: '.tour-alerts-table',
        content: t('ei.tour.alertsTableStep'),
      },
    ];
    setTourSteps(steps); // Update state with steps
  }, [t]);

  function getMissingSteps() {
    const completedSteps = JSON.parse(localStorage.getItem('completedSteps')) || [];
    return TOUR_STEPS.filter((step) => !completedSteps.includes(step.target));
  }

  function handleJoyrideCallback(data) {
    const { status, type, step } = data;
    const completedSteps = JSON.parse(localStorage.getItem('completedSteps')) || [];

    if (status === 'skipped' || type === 'step:after') {
      if (!completedSteps.includes(step.target)) {
        completedSteps.push(step.target);
        localStorage.setItem('completedSteps', JSON.stringify(completedSteps));
      }
      // Check if all TOUR_STEPS targets are in completedSteps
      const allStepsCompleted = TOUR_STEPS.every((step) => completedSteps.includes(step.target));
      if (allStepsCompleted) {
        localStorage.setItem('tourCompleted', true);
      }
    }
  }

  useEffect(() => {
    // Initialize initial steps
    setSteps(getMissingSteps());
    // Observe the dynamic filter component
    const observer = new MutationObserver((mutationsList, observer) => {
      // Look through all mutations that just occured
      for (let mutation of mutationsList) {
        // If the addedNodes property has one or more nodes
        if (mutation.addedNodes.length) {
          const addedNode = mutation.addedNodes[0];
          // Check if our element was added to DOM
          if (addedNode && addedNode.matches && addedNode.matches('.tour-filter-selector')) {
            setSteps(getMissingSteps());
            observer.disconnect(); // Stop observing the DOM
          }
        }
      }
    });
    observer.observe(document.body, { attributes: false, childList: true, subtree: true });
    return () => observer.disconnect(); // Cleanup on unmount
  }, [TOUR_STEPS]);

  useEffect(() => {
    if (!localStorage.getItem('tourCompleted')) {
      setTourKey(Math.random());
      setRun(true);
    }
  }, [steps]);

  return (
    <Joyride
      key={tourKey}
      steps={steps}
      run={run}
      continuous
      showSkipButton
      callback={handleJoyrideCallback}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          zIndex: 10000,
        },
      }}
      locale={{
        last: 'End',
      }}
    />
  );
}
