import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './QualitatioDateTimePicker.css';

const QualitatioDateTimePicker = ({
  value,
  label,
  variant,
  margin,
  onChange,
  width,
  containerStyle,
  onlyFuture = false,
  compact = false,
  inputVariant = 'outlined',
  ...otherProps
}) => {
  const { t, i18n } = useTranslation();
  const wrapperStyle = {
    ...containerStyle,
    margin: margin || '0',
    width: width || '100%',
  };

  // Ensure default value is in correct format
  const formattedValue = value ? dayjs(value).locale(i18n.language) : dayjs().locale(i18n.language);

  let PickerComponent;
  switch (variant) {
    case 'mobile':
      PickerComponent = DateTimePicker;
      break;
    case 'desktop':
      PickerComponent = DateTimePicker;
      break;
    case 'static':
      PickerComponent = DateTimePicker;
      break;
    default:
      PickerComponent = DateTimePicker;
      break;
  }

  const getDateFormat = () => {
    switch (i18n.language) {
      case 'de':
        return 'DD.MM.YYYY HH:mm:ss';
      case 'en':
        return 'MM/DD/YYYY HH:mm:ss';
      default:
        return 'MM/DD/YYYY HH:mm:ss';
    }
  };

  const today = dayjs().startOf('day');

  return (
    <div
      className={`qualitatio-date-time-picker ${compact ? 'qualitatio-date-time-picker-compact' : ''}`}
      style={wrapperStyle}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PickerComponent
          sx={{ width: width }}
          slotProps={{ textField: { variant: inputVariant } }}
          label={label}
          value={formattedValue}
          format={getDateFormat()}
          onChange={onChange}
          disablePast={onlyFuture}
          {...otherProps}
        />
      </LocalizationProvider>
    </div>
  );
};

export default QualitatioDateTimePicker;
