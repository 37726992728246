import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth.store';

const LogoutPage = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('logout'));

  const logout = useAuthStore((state) => state.logout);
  // performing the "logout" operation
  useEffect(() => {
    logout();
  }, []);

  // Then navigate the user to the login page
  return <Navigate to="/login" />;
};

export default LogoutPage;
