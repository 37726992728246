import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';
import './ArtificialIntelligence.css';

export const ArtificialIntelligence = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('artificialIntelligence'));

  return (
    <PageFrame
      title={t('artificialIntelligence')}
      Icon={ScatterPlotRoundedIcon}
      particlesActive={true}
    >
      <div className="artificialIntelligence">
        <div className="text-wrapper-5">{t('welcomeToArtificialIntelligenceModule')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
        <div className="moduleCardWrapper">
          <ModulCard
            module={t('aiTraining')}
            moduleText={t('configurationAndTrainingOfAIModels')}
            icon={<SsidChartRoundedIcon color="primary" fontSize="large" />}
            href="/artificial_intelligence/configurator"
          />
          <ModulCard
            module={t('aiOptimization')}
            moduleText={t('deploymentAndOptimizationOfAIModels')}
            icon={<TuneRoundedIcon color="primary" fontSize="large" />}
            href="/artificial_intelligence/optimizer"
          />
        </div>
      </div>
    </PageFrame>
  );
};

export default ArtificialIntelligence;
