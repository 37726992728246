import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideUpTransition } from '../../utils/utilities';

/**
 * A multi-step form component for Qualitatio.
 * @param {Object} props - The component props.
 * @param {Function} props.backButtonAction - The function to be called when the back button is clicked.
 * @param {Function} props.forwardButtonAction - The function to be called when the forward button is clicked.
 * @param {number} props.numPages - The total number of pages in the form.
 * @param {number} props.progress - The current progress of the form.
 * @param {boolean} props.nextIsDisabled - Whether the next button is disabled.
 * @param {Function} props.cancelForm - The function to be called when the cancel button is clicked.
 * @param {string} props.formTitle - The title of the form.
 * @param {boolean} props.cancelDialogOpen - Whether the cancel dialog is open.
 * @param {Function} props.setCancelDialogOpen - The function to set the state of the cancel dialog.
 * @param {ReactNode} props.children - The child components of the form.
 * @returns {JSX.Element} The QualitatioMultiStepForm component.
 */
export default function QualitatioMultiStepForm({
  backButtonAction,
  forwardButtonAction,
  numPages,
  progress,
  nextIsDisabled,
  previousIsDisabled = false,
  cancelForm,
  formTitle,
  cancelDialogOpen,
  setCancelDialogOpen,
  children,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === 'ArrowRight') {
        forwardButtonAction();
      } else if (event.key === 'ArrowLeft') {
        backButtonAction();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [forwardButtonAction, backButtonAction]);

  return (
    <Grid
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      container
      style={{ marginTop: '1%' }}
    >
      <Grid item sm={1} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          color="primary"
          variant="qualitatio"
          squared
          onClick={backButtonAction}
          style={{ width: '48px', height: '48px' }}
          disabled={previousIsDisabled}
        >
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
      </Grid>
      <Grid item sm={10} md={4}>
        <Card variant="configurator">
          <CardContent>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: '20px' }}
            >
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  {progress} / {numPages} {t('steps')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <LinearProgress
                  variant="determinate"
                  value={(progress / numPages) * 100}
                  style={{ width: '100%', maxWidth: '600px' }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
              >
                <Typography variant="h3" align="center">
                  {formTitle}
                </Typography>
              </Grid>
            </Grid>
            {children}
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={1} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          color="primary"
          variant="qualitatio"
          size="small"
          squared
          onClick={forwardButtonAction}
          disabled={nextIsDisabled}
          style={{ width: '48px', height: '48px' }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid>
      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        TransitionComponent={SlideUpTransition}
        keepMounted
        variant="qualitatio"
      >
        <DialogTitle>{t('unsavedChanges')}</DialogTitle>
        <DialogContent>
          {t('youHaveUnsavedChangesWhichCanBeLostIfYouProceedNowAreYouSureYouWantToProceed')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelForm}
            variant="qualitatio"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('continue')}
          </Button>
          <Button
            onClick={() => setCancelDialogOpen(false)}
            variant="qualitatio"
            color="secondary"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

QualitatioMultiStepForm.propTypes = {
  backButtonAction: PropTypes.func.isRequired,
  forwardButtonAction: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  nextIsDisabled: PropTypes.bool,
  cancelForm: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
  cancelDialogOpen: PropTypes.bool.isRequired,
  setCancelDialogOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
};

QualitatioMultiStepForm.defaultProps = {
  nextIsDisabled: false,
  children: null,
};
