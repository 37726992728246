import { createContext, useEffect, useState } from 'react';
import { filesRoot } from '../api/endpoints';

export const LogoContext = createContext({
  companyLogo: filesRoot + "basis/logoFiles/logo.png",
  newLogoUploaded: () => { },
});

export function CompanyLogoProvider({ children }) {
  const [companyLogo, setCompanyLogo] = useState(filesRoot + "basis/logoFiles/logo.png");
  const [cacheBuster, setCacheBuster] = useState(0);

  const newLogoUploaded = () => {
    setCacheBuster((prevCacheBuster) => prevCacheBuster + 1);
  };

  useEffect(() => {
    setCompanyLogo(filesRoot + "basis/logoFiles/logo.png?cacheBuster" + cacheBuster);
  }, [cacheBuster]);

  const provider = {
    companyLogo: companyLogo,
    newLogoUploaded: newLogoUploaded,
  };

  return (
    <LogoContext.Provider value={provider}>
      {children}
    </LogoContext.Provider>
  );
};

export const ColorContext = createContext({
  colorProfile: "#000000",
  serverColorProfile: null,
});

export function ColorProfileProvider({ children }) {
  const [colorProfile, setColorProfile] = useState("#000000");
  const [serverColorProfile, setServerColorProfile] = useState(null);

  const provider = {
    colorProfile: colorProfile,
    serverColorProfile: serverColorProfile,
    changeColorProfile: (e) => {
      setColorProfile(e);
      setServerColorProfile(e);
    }
  };

  return (
    <ColorContext.Provider value={provider}>
      {children}
    </ColorContext.Provider>
  );
};