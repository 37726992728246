import React, { createContext, useContext, useState, useEffect } from 'react';
import { WebSocketContext } from './WebsocketProvider';

export const CooperationContext = createContext(null);

export const CooperationProvider = ({ children }) => {
    const [activeUsers, setActiveUsers] = useState([]);
    const { socket } = useContext(WebSocketContext); // Use the existing WebSocket context

    // Update active users when the socket receives a new 'users' event
    useEffect(() => {
        if (socket) {
            socket.on('users', (users) => {
                setActiveUsers(users);
            });

            // Clean up the event listener when the component unmounts or socket changes
            return () => {
                socket.off('users');
            };
        }
    }, [socket]);

    return (
        <CooperationContext.Provider value={{ activeUsers }}>
            {children}
        </CooperationContext.Provider>
    );
};
