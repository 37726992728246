import { Grid } from '@mui/material';
import './CardWrapper.css';

export const CardWrapper = ({ wrapperStyle = {}, gridStyle = {}, hideCard = false, children }) => {

  return (
    <div className={!hideCard ? 'cardWrapperLayout' : 'swipeCardsLayoutNotVisible'} style={wrapperStyle}>
      <Grid container className='cardWrapperGridLayout' style={gridStyle}>
        <Grid item xs={12} style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default CardWrapper;
