import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv, FaLayerGroup, FaRegClock } from 'react-icons/fa';
import {
  MdDownload,
  MdOutlineCancel,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdSave,
} from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import axiosClient from '../../api/axiosClient';
import {
  getLiveTrainingMetaDataEndpoint,
  getStreamTrainingDataAsCSVEndpoint,
  liveStreamDataEndpoint,
  mlModelEndpoint,
  retrainModelEndpoint,
} from '../../api/endpoints';
import QualitatioAccordion from '../../components/QualitatioAccordion/QualitatioAccordion';
import QualitatioChipArray from '../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioDateTimePicker from '../../components/QualitatioDateTimePicker/QualitatioDateTimePicker';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioDragAndDrop from '../../components/QualitatioDragAndDrop/QualitatioDragAndDrop';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import { Modules } from '../../config/enums';
import './AIEditConfigurator.css';
import {
  getPossibleAlgorithms,
  getPossiblePatternRecognitionTypes,
} from './components/aiConfiguratorEnums';

export default function AIEditConfigurator({
  model,
  setMLModelsData,
  closeEditOverlay,
  isEditOverlayOpen,
  checkForWrongCharacter,
  checkIfModelNameExists,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const [modelName, setModelName] = useState(model.name);
  const [creatorName, setCreatorName] = useState(model.creatorName);
  const [created, setCreated] = useState(model.created);
  const [lastRetrained, setLastRetrained] = useState(model.lastRetrained);
  const [modelType, setModelType] = useState(model.type);
  const [inputFieldNames, setInputFieldNames] = useState(model.inputFieldNames);
  const [inputFieldMapping, setInputFieldMapping] = useState(model.inputFieldMapping);
  const [defectClassFieldNames, setdefectClassFieldNames] = useState(model.defectClassFieldNames);
  const [defectClassFieldMapping, setdefectClassFieldMapping] = useState(
    model.defectClassFieldMapping
  );
  const [defectClassNames, setDefectClassNames] = useState(model.defectClassNames);
  const [allFieldNames, setAllFieldNames] = useState(model.allFieldNames);
  const [training, setTraining] = useState(model.training);
  const [retraining, setRetraining] = useState(model.retraining);
  const [performance, setPerformance] = useState(model.performance);
  const [other, setOther] = useState(model.other);
  const [active, setActive] = useState(model.active);

  const [liveTrainingData, setLiveTrainingData] = useState({});
  const [liveStreamData, setLiveStreamData] = useState([]);

  useEffect(() => {
    getLiveTrainingData();
    getLiveStreamData();
  }, []);

  useEffect(() => {
    if (training.csvFileOrLiveData === 'csv') {
      setTraining((prevTraining) => ({
        ...prevTraining,
        timePeriod: {
          startTime:
            training.timePeriod?.startTime ||
            moment(new Date()).subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss'),
          endTime: training.timePeriod?.endTime || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        },
      }));
    }
  }, []);

  const getLiveTrainingData = async () => {
    if (training.csvFileOrLiveData === 'live') {
      try {
        const response = await axiosClient.post(getLiveTrainingMetaDataEndpoint, {
          timePeriod: { startTime: 0, endTime: 'now' },
        });
        setLiveTrainingData(response.data.liveTrainingData);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await axiosClient.put(mlModelEndpoint, {
        modelID: model.id,
        model: {
          name: modelName,
          active: active,
          type: modelType,
          inputFieldNames: inputFieldNames,
          inputFieldMapping: inputFieldMapping,
          defectClassFieldNames: defectClassFieldNames,
          defectClassFieldMapping: defectClassFieldMapping,
          defectClassNames: defectClassNames,
          allFieldNames: allFieldNames,
          training: training,
          retraining: retraining,
          other: other,
        },
      });

      setMLModelsData(response.data.mlModelsData);
      enqueueSnackbar(t('modelSuccesfullyUpdated'), { variant: 'success' });
      closeEditOverlay();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInitManualRetraining = async () => {
    const snackbarKey = enqueueSnackbar(t('retrainingInitiated'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.post(retrainModelEndpoint, {
        modelID: model.id,
      });
      closeSnackbar(snackbarKey);

      setPerformance(response.data.performance);
      setMLModelsData(response.data.mlModelsData);
      enqueueSnackbar(t('modelSuccesfullyRetrained'), { variant: 'success' });
    } catch (error) {
      closeSnackbar(snackbarKey);
      if (error.response.status === 400) {
        if (error.response.data.error === 'No defect description fields configured.') {
          enqueueSnackbar(t('noDefectDescriptionFieldsConfigured'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('tooSmallDataset'), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(t('modelRetrainingFailed'), { variant: 'error' });
      }
    }
  };

  const downloadTrainingDataSet = async () => {
    let csvDataLink = '';
    if (training.csvFileOrLiveData === 'csv') {
      csvDataLink = training.csvFile;
    } else {
      if (training.streamTrainingDataAsCSV) {
        csvDataLink = training.streamTrainingDataAsCSV;
      } else {
        try {
          const response = await axiosClient.post(getStreamTrainingDataAsCSVEndpoint, {
            name: modelName,
            inputFieldMapping: inputFieldMapping,
            defectClassNames: defectClassNames,
            timePeriod: training.timePeriod,
          });
          setTraining((prevTraining) => ({
            ...prevTraining,
            streamTrainingDataAsCSV: response.data.streamTrainingDataAsCSV,
          }));
          csvDataLink = response.data.streamTrainingDataAsCSV;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', csvDataLink);
    linkElement.setAttribute('download', 'trainingDataSet.csv');
    linkElement.click();

    // Clean up the temporary URL object
    URL.revokeObjectURL(linkElement.href);
  };

  const handleSelectField = (field) => {
    if (defectClassFieldNames.includes(field)) {
      setdefectClassFieldNames(defectClassFieldNames.filter((item) => item !== field));
    } else if (inputFieldNames.includes(field)) {
      setInputFieldNames(inputFieldNames.filter((item) => item !== field));
    } else {
      setInputFieldNames([...inputFieldNames, field]);
    }
  };

  const handleInputSelectionFieldChange = (field, mappedStreamName) => {
    if (inputFieldNames.includes(field)) {
      setInputFieldNames(inputFieldNames.filter((item) => item !== field));
      // update newModelInputFieldMapping
      const inputFieldMappingCopy = [...inputFieldMapping];
      const index = inputFieldMappingCopy.findIndex((el) => el.inputFieldName === field);
      inputFieldMappingCopy.splice(index, 1);
      setInputFieldMapping(inputFieldMappingCopy);
    } else {
      setInputFieldNames([...inputFieldNames, field]);
      // update newModelInputFieldMapping
      setInputFieldMapping([
        ...inputFieldMapping,
        { inputFieldName: field, mappedStreamName: mappedStreamName, mappedStreamField: field },
      ]);
    }
  };

  const getSelectedValue = (field, state) => {
    const mapping = state.find((el) => el.inputFieldName === field || el.defectClassName === field);
    if (mapping) {
      return `${mapping.mappedStreamName} > ${mapping.mappedStreamField}`;
    }
    return '';
  };

  const updateInputFieldMapping = (field, selectedValue) => {
    setInputFieldMapping((prevMapping) => {
      const updatedMapping = [...prevMapping];
      if (selectedValue === '') {
        // Handle the case when the selected value is empty
        const index = updatedMapping.findIndex((el) => el.inputFieldName === field);
        if (index !== -1) {
          // Remove the mapping if it exists
          updatedMapping.splice(index, 1);
        }
      } else {
        // Handle the case when a valid value is selected
        const [streamName, streamField] = selectedValue.split(' > ');
        const index = updatedMapping.findIndex((el) => el.inputFieldName === field);
        if (index === -1) {
          updatedMapping.push({
            inputFieldName: field,
            mappedStreamName: streamName,
            mappedStreamField: streamField,
          });
        } else {
          updatedMapping[index] = {
            inputFieldName: field,
            mappedStreamName: streamName,
            mappedStreamField: streamField,
          };
        }
      }
      return updatedMapping;
    });
  };

  const updateBuiltInAlgorithmsUsed = (newValue) => {
    setTraining((prevTraining) => ({
      ...prevTraining,
      builtInAlgorithmsUsed: newValue,
    }));
  };

  const updateBuiltInAlgorithm = (newValue) => {
    setTraining((prevTraining) => ({
      ...prevTraining,
      builtInAlgorithm: newValue,
    }));
  };

  const updateCustomAlgorithmName = (newValue) => {
    setTraining((prevTraining) => ({
      ...prevTraining,
      customAlgorithmName: newValue,
    }));
  };

  const updateAutomaticRetrainingEnabled = (newValue) => {
    setRetraining((prevRetraining) => ({
      ...prevRetraining,
      automaticRetrainingEnabled: newValue,
    }));
  };

  const updateRetrainingBasedOnModelDecay = (newValue) => {
    setRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingBasedOnModelDecay: newValue,
    }));
  };

  const updateRetrainingModelDecayRateInPercent = (newValue) => {
    setRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingModelDecayRateInPercent: newValue,
    }));
  };

  const updateRetrainingFrequencyInDays = (newValue) => {
    setRetraining((prevRetraining) => ({
      ...prevRetraining,
      retrainingFrequencyInDays: newValue,
    }));
  };

  const updateCachingFrequencyInMinutes = (newValue) => {
    setOther((prevOther) => ({
      ...prevOther,
      cachingFrequencyInMinutes: newValue,
    }));
  };

  const updateModelIsWarranty = (newValue) => {
    setOther((prevOther) => ({
      ...prevOther,
      isWarranty: newValue,
    }));
  };

  const updateStartTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    setTraining((prevTraining) => ({
      ...prevTraining,
      timePeriod: { startTime: selectedDateTime, endTime: prevTraining.timePeriod.endTime },
    }));
  };

  const updateEndTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    setTraining((prevTraining) => ({
      ...prevTraining,
      timePeriod: { startTime: prevTraining.timePeriod.startTime, endTime: selectedDateTime },
    }));
  };

  const cachingFrequencies = [
    { value: 1, label: '1 ' + t('minutes') },
    { value: 2, label: '2 ' + t('minutes') },
    { value: 5, label: '5 ' + t('minutes') },
    { value: 10, label: '10 ' + t('minutes') },
    { value: 15, label: '15 ' + t('minutes') },
    { value: 30, label: '30 ' + t('minutes') },
  ];

  const numberOfKPIs = 4;

  const performanceKPIs = [
    {
      set: t('training'),
      score: t('accuracy'),
    },
    {
      set: t('training'),
      score: t('f1Score'),
    },
    {
      set: t('validation'),
      score: t('accuracy'),
    },
    {
      set: t('validation'),
      score: t('f1Score'),
    },
  ];

  const [animatedNumbers, setAnimatedNumbers] = useState([]);

  useEffect(() => {
    setAnimatedNumbers([]);
    if (performance === undefined || !performance) return;
    const timer = setInterval(() => {
      setAnimatedNumbers((prevNumbers) => {
        if (prevNumbers.length === numberOfKPIs) {
          clearInterval(timer);
          return prevNumbers;
        } else {
          if (prevNumbers.length === 0) {
            return [...prevNumbers, performance.trainingAccuracy];
          } else if (prevNumbers.length === 1) {
            return [...prevNumbers, performance.trainingF1];
          } else if (prevNumbers.length === 2) {
            return [...prevNumbers, performance.validationAccuracy];
          } else if (prevNumbers.length === 3) {
            return [...prevNumbers, performance.validationF1];
          }
        }
      });
    }, 1000); // Change the animation duration as desired

    return () => {
      clearInterval(timer);
    };
  }, [performance]);

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeEditOverlay(model.id);
  };

  const [nameError, setNameError] = React.useState('');

  const setModelNameWithCheck = (newModelName) => {
    const wrongCharacter = checkForWrongCharacter(newModelName);
    const modelNameExists = checkIfModelNameExists(newModelName);
    const modelNameEqualsOldName =
      newModelName.trim().toLowerCase().replace(/\s+/g, '_') ===
      model.name.trim().toLowerCase().replace(/\s+/g, '_');
    if (wrongCharacter) {
      setNameError(t('wrongCharacter'));
    } else if (modelNameExists && !modelNameEqualsOldName) {
      setNameError(t('modelAlreadyExists'));
    } else {
      setNameError('');
    }
    setModelName(newModelName);
  };

  const algorithmsOptions = getPossibleAlgorithms(
    model.module,
    modelType === 'anomalyDetection',
    t
  ).map((algorithm) => (
    <MenuItem value={algorithm.value} key={algorithm.value} className="option">
      {algorithm.label}
    </MenuItem>
  ));

  const saveIsDisabled =
    modelName.length < 3 ||
    nameError ||
    training.csvFileOrLiveData === null ||
    (training.csvFileOrLiveData === 'live' && liveTrainingData === null) ||
    (allFieldNames.length === 0 && training.csvFileOrLiveData === 'csv') ||
    (model.module !== Modules.EARLY_WARNING_SYSTEM &&
      defectClassNames.length === 0 &&
      training.csvFileOrLiveData === 'live') ||
    ((inputFieldNames.length === 0 ||
      (model.module !== Modules.EARLY_WARNING_SYSTEM && defectClassFieldNames.length === 0)) &&
      training.csvFileOrLiveData === 'csv') ||
    ((!training.selectedFeatureStreams || training.selectedFeatureStreams.length === 0) &&
      training.csvFileOrLiveData === 'live') ||
    ((inputFieldMapping.some((item) => item.mappedStreamField == null) ||
      inputFieldMapping.length < inputFieldNames.length) &&
      training.csvFileOrLiveData == 'csv') ||
    (inputFieldNames.length === 0 && training.csvFileOrLiveData === 'live') ||
    (training.builtInAlgorithmsUsed &&
      (training.builtInAlgorithm === null || training.builtInAlgorithm === '')) ||
    (!training.builtInAlgorithmsUsed &&
      (training.customAlgorithmName === null || training.customAlgorithmName === '')) ||
    (retraining.automaticRetrainingEnabled &&
      ((retraining.retrainingBasedOnModelDecay &&
        (retraining.retrainingModelDecayRateInPercent === null ||
          retraining.retrainingModelDecayRateInPercent === '')) ||
        (!retraining.retrainingBasedOnModelDecay &&
          (retraining.retrainingFrequencyInDays === null ||
            retraining.retrainingFrequencyInDays === '')))) ||
    other.cachingFrequencyInMinutes === '';

  const [infoOpen, setInfoOpen] = useState(false);

  const handleDefectClassChange = (defectClassName, e) => {
    if (e.target.checked) {
      setDefectClassNames([...defectClassNames, defectClassName]);
    } else {
      setDefectClassNames(defectClassNames.filter((item) => item !== defectClassName));
    }
    setTraining((prevTraining) => ({
      ...prevTraining,
      streamTrainingDataAsCSV: null,
    }));
  };

  const updateSelectedFeatureStream = (el, e) => {
    let newSelectedFeatureStreams = [];
    if (e.target.checked) {
      if (training.selectedFeatureStreams) {
        newSelectedFeatureStreams = [...training.selectedFeatureStreams, el.streamName];
      } else {
        newSelectedFeatureStreams = [el.streamName];
      }
      let newAllFieldNames = [...allFieldNames, ...el.fieldNames];
      setAllFieldNames(newAllFieldNames);
    } else {
      newSelectedFeatureStreams = training.selectedFeatureStreams.filter((el_inner) => {
        return el_inner != el.streamName;
      });
      // Remove all fields from the allFieldNames array that are from the stream that was unchecked
      let newAllFieldNames = allFieldNames.filter((el_inner) => {
        return !el.fieldNames.includes(el_inner);
      });
      setAllFieldNames(newAllFieldNames);
    }
    setTraining((prevTraining) => ({
      ...prevTraining,
      selectedFeatureStreams: newSelectedFeatureStreams,
      streamTrainingDataAsCSV: null,
    }));
  };

  return (
    <QualitatioDialog
      key={model.id}
      title={t('edit') + ' "' + modelName + '"'}
      open={isEditOverlayOpen[model.id]}
      onClose={onClose}
      subheader={
        t('created') +
        ': ' +
        new Date(created).toLocaleDateString() +
        ' ' +
        t('by') +
        ' ' +
        creatorName
      }
      actions={[
        {
          label: t('cancel'),
          onClick: () => closeEditOverlay(model.id),
          order: 'secondary',
          startIcon: <MdOutlineCancel />,
          width: '35%',
        },
        {
          label: t('save'),
          onClick: () => handleSave(),
          order: 'primary',
          startIcon: <MdSave />,
          width: '35%',
          disabled: saveIsDisabled,
        },
      ]}
      buttonOnLeft={{
        label: t('initManualRetraining'),
        onClick: async () => {
          await handleSave();
          await handleInitManualRetraining();
        },
        order: 'primary',
        startIcon: <VscDebugRestart />,
        width: '60%',
        disabled: saveIsDisabled,
      }}
      children={
        <div className="ai-edit-summary">
          <div className="model-settings">
            <QualitatioInput
              label={t('nameOfModel')}
              value={modelName}
              onChange={(e) => setModelNameWithCheck(e.target.value)}
              width="100%"
              placeholder={t('enterAModelName')}
            />
            {nameError && <div className="error-message">{nameError}</div>}
            <div className="model-warranty-checkbox">
              {t('isWarranty')}
              <Checkbox
                variant="qualitatio"
                checked={other.isWarranty}
                onChange={(e) => updateModelIsWarranty(e.target.checked)}
              />
            </div>
            <QualitatioChipArray
              chipData={[
                {
                  label: t(model.module),
                  icon: <FaLayerGroup />,
                  backgroundColor: theme.palette.warning.main,
                },
                {
                  label: training.csvFileOrLiveData === 'csv' ? t('csvFile') : t('liveData'),
                  icon: training.csvFileOrLiveData === 'csv' ? <FaFileCsv /> : <FaRegClock />,
                  backgroundColor: theme.palette.success.secondary,
                },
                {
                  label: t('downloadTrainingSet'),
                  icon: <MdDownload />,
                  color: 'default',
                  onClick: () => {
                    downloadTrainingDataSet();
                  },
                },
              ]}
              justifyContent="left"
            />
            {training.csvFileOrLiveData === 'live' && (
              <div className="summary-date-time-picker">
                <QualitatioDateTimePicker
                  value={training.timePeriod?.startTime}
                  onChange={(e) => updateStartTime(e.$d)}
                  label={t('from')}
                />
                <QualitatioDateTimePicker
                  value={training.timePeriod?.endTime}
                  onChange={(e) => updateEndTime(e.$d)}
                  label={t('to')}
                />
              </div>
            )}
            <QualitatioAccordion
              accordionData={[
                {
                  title: t('featureAndPredictionFields'),
                  panel: 'panel1',
                  render: (
                    <div className="step-4-step-5-summary">
                      {training.csvFileOrLiveData === 'csv' ? (
                        <div className="step-4-summary">
                          <div className="select-fields">
                            {t('selectFields')}
                            <IconButton
                              variant="qualitatio"
                              squared={true}
                              onClick={() => setInfoOpen(true)}
                              style={{
                                backgroundColor: theme.palette.success.secondary,
                                width: '36px',
                                height: '36px',
                              }}
                            >
                              <InfoRoundedIcon color="primary" />
                            </IconButton>
                            <QualitatioDialog
                              open={infoOpen}
                              onClose={() => setInfoOpen(false)}
                              title={t('selectFields')}
                              description={t('selectFeatureAndPredictionFieldsInfoText')}
                              maxWidth="sm"
                              actions={[
                                {
                                  label: t('alright'),
                                  onClick: () => setInfoOpen(false),
                                  order: 'primary',
                                },
                              ]}
                            />
                          </div>
                          <div className="feature-and-prediction-fields">
                            <QualitatioDragAndDrop
                              fields={allFieldNames.filter(
                                (el) =>
                                  !defectClassFieldNames.includes(el) &&
                                  !inputFieldNames.includes(el)
                              )}
                              setFields={setAllFieldNames}
                              columns={[
                                {
                                  name: t('feature'),
                                  fields: inputFieldNames,
                                  setFields: setInputFieldNames,
                                  color: theme.palette.success.secondary,
                                },
                                {
                                  name: t('target'),
                                  fields: defectClassFieldNames,
                                  setFields: setdefectClassFieldNames,
                                  color: theme.palette.error.secondary,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="step-3-live-summary">
                            <div className="feature-input-streams">
                              {model.module !== Modules.EARLY_WARNING_SYSTEM && (
                                <div className="select-prediction-fields">
                                  <div className="select-defect-classes-as-predictions-text">
                                    {t('selectDefectClassesAsPredictions')}
                                  </div>
                                  <div className="prediction-streams">
                                    <div className="number-of-predictions">
                                      {t('youCan') +
                                        (liveTrainingData.numberCarriedOutTests || 0) +
                                        t('predictions')}
                                    </div>
                                    <div className="prediction-streams-list">
                                      {liveTrainingData.availableDefectClasses?.map((el, index) => (
                                        <div className="prediction-streams-list-item">
                                          {el.nameDefectClass +
                                            ' (' +
                                            el.numberSuggestions +
                                            ' ' +
                                            t('labelsWith') +
                                            ' ' +
                                            el.numberOccurances +
                                            ' ' +
                                            t('positive') +
                                            ')'}
                                          <Checkbox
                                            variant="qualitatio"
                                            checked={defectClassNames.includes(el.nameDefectClass)}
                                            onChange={(e) =>
                                              handleDefectClassChange(el.nameDefectClass, e)
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="select-data-source-as-features">
                                <div className="select-data-sources-as-features-text">
                                  {t('selectDataSourcesAsFeatures')}
                                </div>
                                {liveStreamData?.map((el, index) => (
                                  <div className="data-source-item">
                                    <div className="data-source-item-name">{el.streamName}</div>
                                    <div className="data-source-item-checkbox">
                                      <Checkbox
                                        variant="qualitatio"
                                        id={el.streamName}
                                        checked={
                                          training.selectedFeatureStreams &&
                                          training.selectedFeatureStreams.includes(el.streamName)
                                        }
                                        onChange={(e) => updateSelectedFeatureStream(el, e)}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="step-4-live-summary">
                            <div className="select-fields">{t('selectFields')}</div>
                            <QualitatioChipArray
                              chipData={[
                                {
                                  label: t('feature'),
                                  icon: <MdOutlineCheckBox />,
                                  backgroundColor: theme.palette.success.secondary,
                                },
                                {
                                  label: t('notSelected'),
                                  icon: <MdOutlineCheckBoxOutlineBlank />,
                                  color: 'default',
                                },
                              ]}
                              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                            />
                            <div className="divider-container">
                              <Divider variant="middle" />
                            </div>
                            <div className="list-of-all-fields-and-names">
                              {liveStreamData?.map((stream) => (
                                <>
                                  {training.selectedFeatureStreams &&
                                    training.selectedFeatureStreams.includes(stream.streamName) && (
                                      <>
                                        <div className="stream-name">{stream.streamName}</div>
                                        <QualitatioChipArray
                                          chipData={stream.fieldNames.map((fieldName, index) => ({
                                            label: fieldName,
                                            icon:
                                              inputFieldNames.includes(fieldName) &&
                                              inputFieldMapping.find(
                                                (el) => el.inputFieldName === fieldName
                                              ).mappedStreamName === stream.streamName ? (
                                                <MdOutlineCheckBox />
                                              ) : (
                                                <MdOutlineCheckBoxOutlineBlank />
                                              ),
                                            backgroundColor:
                                              inputFieldNames.includes(fieldName) &&
                                              inputFieldMapping.find(
                                                (el) => el.inputFieldName === fieldName
                                              ).mappedStreamName === stream.streamName
                                                ? theme.palette.success.secondary
                                                : 'default',
                                            onClick: () =>
                                              handleInputSelectionFieldChange(
                                                fieldName,
                                                stream.streamName
                                              ),
                                          }))}
                                          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                                        />
                                      </>
                                    )}
                                </>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {training.csvFileOrLiveData === 'csv' && (
                        <div className="step-5-summary">
                          <div className="map-feature-fields">
                            {t('featureFieldDataSourceMapping')}
                          </div>
                          <div className="feature-field-mapping">
                            {inputFieldNames.map((fieldName, index) => (
                              <div key={fieldName} className="grid-item">
                                <Chip
                                  label={fieldName}
                                  icon={<MdOutlineCheckBox />}
                                  sx={{ backgroundColor: theme.palette.success.secondary }}
                                />
                                <QualitatioSelect
                                  value={getSelectedValue(fieldName, inputFieldMapping)}
                                  onChange={(e) =>
                                    updateInputFieldMapping(fieldName, e.target.value)
                                  }
                                  optionProps={liveStreamData?.flatMap((stream) => [
                                    <ListSubheader
                                      key={`header-${stream.streamName}`}
                                      className="subheader"
                                    >
                                      {stream.streamName}
                                    </ListSubheader>,
                                    ...stream.fieldNames.map((field) => (
                                      <MenuItem
                                        value={`${stream.streamName} > ${field}`}
                                        key={`${stream.streamName}-${field}`}
                                        className="option"
                                      >
                                        {`${stream.streamName} > ${field}`}
                                      </MenuItem>
                                    )),
                                  ])}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: t('algorithm'),
                  panel: 'panel2',
                  render: (
                    <div className="step-6-summary">
                      <div className="built-in-algorithm">
                        <Switch
                          variant="qualitatio"
                          checked={training.builtInAlgorithmsUsed}
                          onChange={(e) => updateBuiltInAlgorithmsUsed(e.target.checked)}
                        />
                        <div className="built-in-algorithm-text">{t('builtInAlgorithm')}</div>
                      </div>
                      {model.module === Modules.EARLY_WARNING_SYSTEM && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          marginTop={5}
                        >
                          <Typography variant="body1">
                            {t('ews.selectPatternRecognitionType')}
                          </Typography>
                          <RadioGroup
                            column
                            value={modelType}
                            onChange={(e) => {
                              setModelType(e.target.value);
                            }}
                          >
                            {getPossiblePatternRecognitionTypes(t).map((modelType) => (
                              <FormControlLabel
                                value={modelType.value}
                                control={<Radio />}
                                label={modelType.label}
                              />
                            ))}
                          </RadioGroup>
                        </Box>
                      )}
                      <div className="algorithm-name">
                        {training.builtInAlgorithmsUsed ? (
                          <QualitatioSelect
                            value={training.builtInAlgorithm}
                            onChange={(e) => updateBuiltInAlgorithm(e.target.value)}
                            optionProps={algorithmsOptions}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <QualitatioInput
                            label={t('algorithmName')}
                            value={training.customAlgorithmName}
                            onChange={(e) => updateCustomAlgorithmName(e.target.value)}
                            width="100%"
                          />
                        )}
                      </div>
                    </div>
                  ),
                },
                {
                  title:
                    t('retrainingAndCaching') +
                    ' (' +
                    t('lastRetrainedOn') +
                    ' ' +
                    new Date(lastRetrained).toLocaleDateString() +
                    ')',
                  panel: 'panel3',
                  render: (
                    <div className="step-7-summary">
                      <div className="define-automatic-retraining">
                        <Switch
                          variant="qualitatio"
                          checked={training.automaticRetrainingEnabled}
                          disabled={model.module === Modules.EARLY_WARNING_SYSTEM}
                          onChange={(e) => updateAutomaticRetrainingEnabled(e.target.checked)}
                        />
                        <div className="automatic-retraining">{t('automaticRetraining')}</div>
                      </div>
                      {retraining.automaticRetrainingEnabled && (
                        <div className="retraining-settings">
                          <Switch
                            variant="qualitatio"
                            checked={retraining.retrainingBasedOnModelDecay}
                            onChange={(e) => updateRetrainingBasedOnModelDecay(e.target.checked)}
                          />
                          <div className="retraining-based-on-model-decay">
                            {t('retrainingBasedOnModelDecay')}
                          </div>
                          <QualitatioInput
                            label={
                              retraining.retrainingBasedOnModelDecay
                                ? t('retrainingModelDecayRateInPercent')
                                : t('retrainingFrequencyInDays')
                            }
                            value={
                              retraining.retrainingBasedOnModelDecay
                                ? retraining.retrainingModelDecayRateInPercent
                                : retraining.retrainingFrequencyInDays
                            }
                            onChange={(e) => {
                              retraining.retrainingBasedOnModelDecay
                                ? updateRetrainingModelDecayRateInPercent(e.target.value)
                                : updateRetrainingFrequencyInDays(e.target.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                {retraining.retrainingBasedOnModelDecay ? '%' : t('days')}
                              </InputAdornment>
                            }
                            width="100%"
                          />
                        </div>
                      )}
                      <div className="caching-settings">
                        <Switch variant="qualitatio" disabled defaultChecked />
                        <div className="caching-frequency-in-minutes">
                          {t('cachingFrequencyInMinutes')}
                        </div>
                        <QualitatioSelect
                          value={other.cachingFrequencyInMinutes}
                          onChange={(e) => updateCachingFrequencyInMinutes(e.target.value)}
                          optionProps={cachingFrequencies.map((frequency) => (
                            <MenuItem
                              value={frequency.value}
                              key={frequency.value}
                              className="option"
                            >
                              {frequency.label}
                            </MenuItem>
                          ))}
                        />
                      </div>
                    </div>
                  ),
                },
                {
                  title: t('performance'),
                  panel: 'panel4',
                  render:
                    model.module !== Modules.EARLY_WARNING_SYSTEM ? (
                      <div className="model-performance">
                        {animatedNumbers.map((number, index) => (
                          <div className="model-performance-frame">
                            <div className="model-performance-ellipse">
                              <div className="model-performance-content">
                                <div className="model-performance-number-wrapper">
                                  {parseInt(number * 100)}
                                </div>
                                <div className="model-performance-text-wrapper">
                                  {performanceKPIs[index].set}
                                  <br />
                                  {performanceKPIs[index].score}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h6" gutterBottom>
                          {t('ews.noModelPerformance')}
                        </Typography>
                      </Box>
                    ),
                },
              ]}
            />
            <div className="model-active">
              <label htmlFor="active" className="model-active-text">
                {t('active')}
              </label>
              <Switch
                variant="qualitatio"
                id="active"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
