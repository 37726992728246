import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import QualitatioTable from '../../../components/QualitatioTable/QualitatioTable';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const AvailableStreamsTable = ({ availableOutputStreams, setAvailableOutputStreams }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  /* HANDLE EXAMPLES */
  const [fieldsIsOpen, setFieldsIsOpen] = useState();
  const [examplesIsOpen, setExamplesIsOpen] = useState();
  const [dataIndex, setDataIndex] = useState();

  const openFieldsOverlay = (index) => {
    setFieldsIsOpen(true);
    setDataIndex(index);
  };

  const openExamplesOverlay = (index) => {
    setExamplesIsOpen(true);
    setDataIndex(index);
  };

  /**Qualitatio Table Settings */
  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
    },
    {
      field: 'description',
      headerName: t('description'),
      width: 200,
    },
    {
      field: 'fields',
      headerName: t('fields'),
      width: 75,
      renderCell: (params) => {
        return (
          <IconButton
            variant="qualitatio"
            squared={true}
            color="primary"
            onClick={() => openFieldsOverlay(params.row.id)}
            style={{ backgroundColor: theme.palette.info.secondary }}
          >
            <OpenInNewIcon color="primary" />
          </IconButton>
        );
      },
    },
    {
      field: 'firstEvent',
      headerName: t('firstEvent'),
      width: 200,
      renderCell: (params) => {
        let label;
        if (params?.value === null || params?.value === undefined || params?.value === '') {
          label = t('never');
        } else {
          let date = new Date(params?.value);
          label = date.toLocaleString();
        }

        return (
          <Chip
            label={label}
            sx={{ backgroundColor: theme.palette.warning.main }}
            icon={<FaCalendarAlt />}
          />
        );
      },
    },
    {
      field: 'lastEvent',
      headerName: t('lastEvent'),
      width: 200,
      renderCell: (params) => {
        let label;
        if (params?.value === null || params?.value === undefined || params?.value === '') {
          label = t('never');
        } else {
          let date = new Date(params?.value);
          label = date.toLocaleString();
        }

        return (
          <Chip
            label={label}
            sx={{ backgroundColor: theme.palette.warning.main }}
            icon={<FaCalendarAlt />}
          />
        );
      },
    },
    {
      field: 'size',
      headerName: t('size'),
      width: 100,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value + ' MB'}
            sx={{ backgroundColor: theme.palette.warning.main }}
            icon={<SdStorageIcon fontSize="10px" />}
          />
        );
      },
    },
    {
      field: 'eventsPerMinute',
      headerName: t('eventsPerMinute'),
      width: 175,
    },
    {
      field: 'module',
      headerName: t('module'),
      width: 100,
      flex: 1,
    },
    {
      field: 'examples',
      headerName: t('examples'),
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            variant="qualitatio"
            squared={true}
            color="primary"
            onClick={() => openExamplesOverlay(params.row.id)}
            style={{ backgroundColor: theme.palette.info.secondary }}
          >
            <OpenInNewIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const rows = availableOutputStreams.map((el, idx) => {
    return {
      id: idx,
      name: el.name,
      description: el.description,
      fields: el.fields,
      firstEvent: el.firstEvent,
      lastEvent: el.lastEvent,
      size: el.size,
      eventsPerMinute: el.eventsPerMinute,
      module: el.module,
      examples: el.examples,
    };
  });

  return (
    <>
      <QualitatioTable
        style={{
          marginTop: '20px',
        }}
        columns={columns}
        rows={rows}
        checkboxSelection={false}
        disableRowSelectionOnClick={false}
      />
      <Dialog variant="qualitatio" open={fieldsIsOpen} onClose={() => setFieldsIsOpen(false)}>
        <DialogTitle>{t('fields')}</DialogTitle>

        <DialogContent>
          {availableOutputStreams[dataIndex]?.fields?.map((field, fieldIndex) => (
            <Chip key={fieldIndex} label={field} sx={{ margin: '5px' }}>
              {field}
            </Chip>
          ))}
        </DialogContent>

        <DialogActions>
          <Button
            variant="qualitatio"
            onClick={() => setFieldsIsOpen(false)}
            color="secondary"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog variant="qualitatio" open={examplesIsOpen} onClose={() => setExamplesIsOpen(false)}>
        <DialogTitle>{t('examples')}</DialogTitle>

        <DialogContent>
          {availableOutputStreams[dataIndex]?.examples?.map((example, exampleIndex) => (
            <Box
              key={exampleIndex}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <Typography variant="body1">{JSON.stringify(example, null, 2)}</Typography>
            </Box>
          ))}
          {availableOutputStreams[dataIndex]?.examples?.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px',
              }}
            >
              <Typography variant="body1">{t('noExamples')}</Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="qualitatio"
            onClick={() => setExamplesIsOpen(false)}
            color="secondary"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AvailableStreamsTable;
