import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const InfoTooltip = ({ description }) => (
  <Tooltip title={description} arrow>
    <InfoRoundedIcon color="primary" style={{ cursor: 'pointer', marginLeft: '8px' }} />
  </Tooltip>
);

InfoTooltip.propTypes = {
  description: PropTypes.string.isRequired,
};

InfoTooltip.defaultProps = {
  description: 'Default description',
};

export default InfoTooltip;
