const { default: axiosClient } = require('../../api/axiosClient');
const _ = require('lodash');
const {
  qualityStationStaticCardsEndpoint,
  qualityStationRuleCardsEndpoint,
  qualityStationEventCardsEndpoint,
  qualityStationAICardsEndpoint,
  getAllEventsFromSplunkEndpoint,
  defectMappingEndpoint,
  saveTestCardImageEndpoint,
} = require('../../api/endpoints');

export const loadQualityStationStaticCards = async (selectedQualityStation) => {
  try {
    const response = await axiosClient.get(qualityStationStaticCardsEndpoint, {
      headers: {
        qualityStationName: selectedQualityStation,
      },
    });
    return response.data.static;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const loadQualityStationRulesCards = async (selectedQualityStation) => {
  try {
    const response = await axiosClient.get(qualityStationRuleCardsEndpoint, {
      headers: {
        qualityStationName: selectedQualityStation,
      },
    });
    return response.data.rules;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const loadQualityStationDynamicCards = async (
  selectedQualityStation,
  startTime,
  endTime,
) => {
  try {
    const response = await axiosClient.get(qualityStationEventCardsEndpoint, {
      headers: {
        qualityStationName: selectedQualityStation,
      },
    });
    return response.data.dynamic;
    // setEventData(response.data.dynamic.filter(el => !el.isDefectDescription));
    // setHierarchieData(response.data.dynamic.filter(el => el.isDefectDescription));
  } catch (error) {
    console.error('Error:', error);
  }
};

export const loadAllEventsFromSplunk = async (selectedLiveStreamData, startTime, endTime) => {
  try {
    const response = await axiosClient.post(getAllEventsFromSplunkEndpoint, {
      streams: selectedLiveStreamData,
      startTime: startTime,
      endTime: endTime,
    });
    return response.data.events;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const loadQualityStationAICards = async (selectedQualityStation) => {
  try {
    const response = await axiosClient.get(qualityStationAICardsEndpoint, {
      headers: {
        qualityStationName: selectedQualityStation,
      },
    });
    return response.data.ai;

    // setIsChecked(response.data.ai.map((ai) => ai.active))
  } catch (error) {
    console.error('Error:', error);
  }
};

export const uploadCardImageToServer = async (file, qualityStation, type) => {
  // Create a FormData object
  const formData = new FormData();
  formData.append('file', file);
  formData.append('qualityStationName', qualityStation);
  formData.append('cardType', type);

  // Save File to Server
  try {
    const response = await axiosClient.post(saveTestCardImageEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.testCardImageURL;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const loadDefectMapping = async (selectedQualityStation) => {
  try {
    const response = await axiosClient.get(defectMappingEndpoint, {
      headers: {
        qualityStationName: selectedQualityStation,
      },
    });
    return response.data.defectMapping;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const isEqualWithoutMetaFields = (obj1, obj2, fieldsToExclude) => {
  // Clone the objects to avoid modifying the originals
  const clone1 = _.cloneDeep(obj1);
  const clone2 = _.cloneDeep(obj2);

  // Remove the specified fields
  fieldsToExclude.forEach((field) => {
    if (Array.isArray(clone1) && Array.isArray(clone2)) {
      clone1.forEach((el) => delete el[field]);
      clone2.forEach((el) => delete el[field]);
    } else {
      delete clone1[field];
      delete clone2[field];
    }
  });

  // Compare the modified clones
  return _.isEqual(clone1, clone2);
};
