import { StepLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './QualitatioProgressBar.css';

const QualitatioProgressBar = ({
  steps,
  nonLinear,
  alternativeLabel,
  activeStep: activeStepProp,
  nonLinearSteps = [],
  setActiveStep: setActiveStepProp,
  orientation = 'horizontal',
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const { t } = useTranslation();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setActiveStepProp(step + 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box className="qualitatio-progress-bar">
      {orientation === 'horizontal' ? (
        <Stepper
          nonLinear={nonLinear}
          alternativeLabel={alternativeLabel}
          activeStep={activeStepProp || activeStep}
        >
          {nonLinear
            ? steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton className="qualitatio-step-button" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))
            : steps.map((label, index) => (
                <Step
                  key={label}
                  completed={false}
                  active={true}
                  sx={{
                    '.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                      color: 'var(--m-3refprimaryprimary-70)',
                    },
                    '.MuiStepConnector-line': { borderColor: 'var(--m-3refprimaryprimary-70)' },
                  }}
                >
                  {nonLinearSteps?.length > 0 && nonLinearSteps.includes(index) ? (
                    <StepButton className="qualitatio-step-button" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  ) : (
                    <StepLabel>{label}</StepLabel>
                  )}
                </Step>
              ))}
        </Stepper>
      ) : (
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step
              key={step}
              completed={false}
              active={true}
              sx={{
                '.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                  color: 'var(--m-3refprimaryprimary-70)',
                },
                '.MuiStepConnector-line': { borderColor: 'var(--m-3refprimaryprimary-70)' },
              }}
            >
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {nonLinear && (
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>{t('allStepsCompleted')}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>{t('resetToDefault')}</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                {t('stepX', { step: activeStep + 1 })}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                  {t('next')}
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                      {t('stepXAlreadyComplete', { step: activeStep + 1 })}
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1 ? t('finish') : t('completeStep')}
                    </Button>
                  ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      )}
    </Box>
  );
};

export default QualitatioProgressBar;
