import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { staticCardEndpoint } from '../../../api/endpoints';
import { useAuthStore } from '../../../store/auth.store';
import { useCardOperations } from '../testCards/useCardOperations';
import TestCardWrapper from '../testCards/TestCardWrapper';
import CardEditDialogs from './components/CardEditDialogs';
import PropTypes from 'prop-types';
import './BasisTests.css';

const BasisTests = ({
  currentIndex,
  setCurrentIndex,
  staticTestsGroundTruth,
  setStaticTestsGroundTruth,
  staticTests,
  setStaticTests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { addTestCard, saveTestCard, deleteTestCard } = useCardOperations();
  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const [deletionWarningOpen, setDeletionWarningOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = staticTests.find((el) => el.id === id);
      if (card) {
        const index = staticTests.indexOf(card);
        if (index !== -1) {
          setCurrentIndex(index);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [staticTests]);

  const setCardField = (field, value, modifier = null) => {
    setStaticTests((prevState) =>
      prevState.map((card, index) => {
        const updatedProperty = modifier ? modifier(card[field], value) : value;
        return index === currentIndex ? { ...card, [field]: updatedProperty } : card;
      })
    );
  };

  const handleAddCard = addTestCard({
    qualityStation: qualityStation,
    creator: user,
    customFields: {
      type: 'Basis',
      explanation: t('fromStaticTestPlan'),
    },
    currentTestCards: staticTests,
    setTestCards: setStaticTests,
    setCurrentCardIndex: setCurrentIndex,
  });

  const deleteCard = deleteTestCard({
    indexToRemove: currentIndex,
    currentTestCards: staticTests,
    setTestCards: setStaticTests,
    groundTruthCards: staticTestsGroundTruth,
    setGroundTruthCards: setStaticTestsGroundTruth,
    setCurrentCardIndex: setCurrentIndex,
    cardEndpoint: staticCardEndpoint,
    errorMessageKey: 'errorWhileDeletingStaticTestProposal',
    successMessageKey: 'staticTestProposalDeletedSuccessfully',
  });

  const _isValidCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== ''
      );
    });
  };

  const _validateCards = (updatedStaticTests) => {
    if (!_isValidCards(updatedStaticTests || staticTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'error' });
      return false;
    }
    return true;
  };

  const handleSaveCard = saveTestCard({
    validateCards: _validateCards,
    filterCardToSave: (card, groundTruth, index) => !card.id || !_.isEqual(card, groundTruth),
    cardEndpoint: staticCardEndpoint,
    qualityStation: qualityStation,
    creator: user,
    currentTestCards: staticTests,
    setTestCards: setStaticTests,
    groundTruthCards: staticTestsGroundTruth,
    setGroundTruthCards: setStaticTestsGroundTruth,
    errorMessageKey: 'errorWhileSavingStaticTestProposal',
    successMessageKey: 'staticTestProposalSavedSuccessfully',
  });

  const updateCurrentIndex = (direction) => {
    if (direction === 'inc') {
      if (currentIndex < staticTests.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    <div className="cards">
      <TestCardWrapper
        cardsToDisplay={staticTests}
        currentCardIndex={currentIndex}
        setCurrentCardIndex={setCurrentIndex}
        testCardProps={{
          editable: true,
          qualityStation,
          orderIdentifierMapping,
          restoreChange,
          index: currentIndex,
          generation: t('Basis'),
          explanation: t('fromStaticTestPlan'),
        }}
        setCardField={setCardField}
        handlePreviousCard={() => updateCurrentIndex('dec')}
        handleNextCard={() => updateCurrentIndex('inc')}
        handleAddCard={handleAddCard}
        handleDeleteCard={() => setDeletionWarningOpen(true)}
        handleSaveCard={handleSaveCard}
        isAddCardDisabled={false}
        isCopilotButtonPresent={false}
      />
      <CardEditDialogs
        isDeleteCardWarningPresent={true}
        isDiscardChangesWarningPresent={false}
        deleteCardWarningDialog={{
          deleteCardWarningOpen: deletionWarningOpen,
          setDeleteCardWarningOpen: setDeletionWarningOpen,
          deleteCard: deleteCard,
        }}
      />
    </div>
  );
};

BasisTests.propTypes = {
  currentIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  staticTestsGroundTruth: PropTypes.array,
  setStaticTestsGroundTruth: PropTypes.func,
  staticTests: PropTypes.array,
  setStaticTests: PropTypes.func,
  qualityStation: PropTypes.string,
  setQualityStationStats: PropTypes.func,
  orderIdentifierMapping: PropTypes.object,
  restoreChange: PropTypes.func,
};

export default BasisTests;
