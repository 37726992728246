import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SystemSecurityUpdateGoodRoundedIcon from '@mui/icons-material/SystemSecurityUpdateGoodRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';
import { Modules } from '../config/enums';

export const IntelligentTesting = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t(Modules.INTELLIGENT_TESTING));

  return (
    <PageFrame
      title={t(Modules.INTELLIGENT_TESTING)}
      Icon={VerifiedRoundedIcon}
      particlesActive={true}
    >
      <div className="menu-page">
        <div className="text-wrapper-5">{t('welcomeToIntelligentTestingModule')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
        <div className="moduleCardWrapper">
          <ModulCard
            module={t('productionConfigurator')}
            moduleText={t('configurationOfQualityStationsAndTestCards')}
            icon={<AccountTreeRoundedIcon color="primary" fontSize="large" />}
            href="/intelligent_testing/configurator"
          />
          <ModulCard
            module={t('aiBasedTesting')}
            moduleText={t('executionOfAiBasedTestingStrategies')}
            icon={<SystemSecurityUpdateGoodRoundedIcon color="primary" fontSize="large" />}
            href="/intelligent_testing/recommender/select"
          />
          <ModulCard
            module={t('testKnowledge')}
            moduleText={t('knowledgeDescription')}
            icon={<MenuBookRoundedIcon color="primary" fontSize="large" />}
            href="/intelligent_testing/knowledge"
          />
        </div>
      </div>
    </PageFrame>
  );
};

export default IntelligentTesting;
