import { Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioButton from '../QualitatioButton/QualitatioButton';
import QualitatioInput from '../QualitatioInput/QualitatioInput';
import createLabelText from '../QualitatioTable/tableLanguages';

function updateRowPosition(initialIndex, newIndex, rows, positionIdentifierAsIndex) {
  return new Promise((resolve) => {
    setTimeout(
      () => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);

        // Update the notation field based on the new order
        if (positionIdentifierAsIndex) {
          const updatedRows = rowsClone.map((row, index) => ({
            ...row,
            positionIdentifier: index, // or another logic if needed
          }));
          resolve(updatedRows);
        } else {
          const updatedRows = rowsClone.map((row, index) => ({
            ...row,
            id: index,
          }));
          resolve(updatedRows);
        }
      },
      Math.random() * 500 + 100
    ); // simulate network latency
  });
}

export default function QualitatioProTable({
  maxHeight = '400px',
  width = '100%',
  rows,
  setRows = null,
  rowIndex = 0,
  columns,
  height,
  isAddPossible = false,
  rowSelection = true,
  checkboxSelection = true,
  onRowSelectionModelChange = null,
  disableRowSelectionOnClick = false,
  disableMultipleRowSelection = true,
  positionIdentifierAsIndex = false,
  handleRowReorder = null,
  ...otherProps
}) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);

  const handleRowOrderChange = async (params) => {
    setLoading(true); // Set loading state to true during the operation

    try {
      const newRows = await updateRowPosition(
        params.oldIndex,
        params.targetIndex,
        rows,
        positionIdentifierAsIndex
      );
      setRows(newRows);
    } catch (error) {
      // Handle any errors here
      console.error('Error:', error);
      enqueueSnackbar(t('Error occurred'), { variant: 'error' });
    } finally {
      setLoading(false); // Set loading state back to false when the operation is completed
    }
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState({
    rows: rows,
    columns: columns,
  });

  const labelText = createLabelText();

  React.useEffect(() => {
    setData({
      rows: positionIdentifierAsIndex ? rows[rowIndex] : rows,
      columns: columns,
    });
  }, [rows]);

  const [isEditInputOpen, setIsEditInputOpen] = React.useState(false);

  const handleAdd = () => {
    //check if notation is already in use and if orderIdentifier is already in use and if orderIdentifier is a number
    if (rows?.some((row) => row.notation === notation)) {
      enqueueSnackbar(t('notationAlreadyInUse'), { variant: 'warning' });
      return;
    }
    if (rows?.some((row) => row.orderIdentifier === orderIdentifier)) {
      enqueueSnackbar(t('orderIdentifierAlreadyInUse'), { variant: 'warning' });
      return;
    }
    if (isNaN(orderIdentifier)) {
      enqueueSnackbar(t('orderIdentifierMustBeNumber'), { variant: 'warning' });
      return;
    }
    if (notation === '') {
      enqueueSnackbar(t('notationMustNotBeEmpty'), { variant: 'warning' });
      return;
    }

    const newRows = [...rows];
    newRows.push({
      id: Number(orderIdentifier),
      notation: notation,
      orderIdentifier: Number(orderIdentifier),
    });
    setRows(newRows);
    setNotation('');
    setIsEditInputOpen(false);
  };

  React.useEffect(() => {
    setOrderIdentifier(Number(rows?.length));
  }, [rows]);

  const [notation, setNotation] = React.useState('');
  const [orderIdentifier, setOrderIdentifier] = React.useState(Number(rows?.length));

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} style={{ height: height, maxHeight: maxHeight, width: width }}>
        <DataGridPro
          variant="qualitatio"
          {...data}
          rows={positionIdentifierAsIndex ? rows[rowIndex] : rows}
          rowReordering={true}
          loading={loading}
          onRowOrderChange={
            handleRowReorder
              ? (params) => handleRowReorder(rowIndex, params)
              : (params) => handleRowOrderChange(params)
          }
          localeText={labelText}
          checkboxSelection={checkboxSelection}
          disableMultipleRowSelection={disableMultipleRowSelection}
          rowSelection={rowSelection}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          {...otherProps}
          onRowSelectionModelChange={(e) => {
            if (onRowSelectionModelChange) {
              onRowSelectionModelChange(e);
            }
          }}
        />
      </Grid>
      {isEditInputOpen && (
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          gap={2}
        >
          <QualitatioInput
            label={t('orderIdentifier')}
            value={orderIdentifier}
            onChange={(e) => setOrderIdentifier(e.target.value)}
            fullWidth
            readOnly={true}
          />
          <QualitatioInput
            label={t('notation')}
            value={notation}
            onChange={(e) => setNotation(e.target.value)}
            fullWidth
          />
          <QualitatioButton
            text={t('addToTable')}
            order="primary"
            width={'100%'}
            height={'5vh'}
            fontSize={'calc(12px + 0.25vw)'}
            onClick={() => {
              handleAdd();
            }}
          />
        </Grid>
      )}
      {isAddPossible && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => setIsEditInputOpen(true)}
            style={{
              backgroundColor: theme.palette.success.secondary,
              width: '36px',
              height: '36px',
            }}
          >
            <img src="/images/addIcon.svg" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
