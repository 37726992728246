import BuildIcon from '@mui/icons-material/Build';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { qrCodeSettingsEndpoint, saveDefectImageEndpoint } from '../../../../api/endpoints';
import LabelIcon from '../../../../assets/icons/LabelIcon';
import TestCommentIcon from '../../../../assets/icons/TestCommentIcon';
import QualitatioCamera from '../../../../components/QualitatioCamera';
import QualitatioDropdown from '../../../../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from '../../../../components/QualitatioInput/QualitatioInput';
import Menu from '../../Menu';
import CardPaperWrapper from '../CardPaperWrapper';
import CardWrapper from '../CardWrapper';
import { ImagePanel } from '../SwipeCard/ImagePanel';
import { SwipeCardButtonBar } from '../SwipeCard/SwipeCardButtonBar';
import { SwipeCardButtonWithLabel } from '../SwipeCard/SwipeCardButtonWithLabel';
import { SwipeCardFooter } from '../SwipeCard/SwipeCardFooter';
import { SwipeCardHeader } from '../SwipeCard/SwipeCardHeader';
import SwipeCardInput from '../SwipeCard/SwipeCardInput';
import AIProcessingPanel from './AIProcessing/AIProcessingPanel';
import './DefectInput.css';
import { DefectInputImageStepper } from './DefectInputImageStepper';
import { QRCodeComponent } from './QRCodeComponent';
import {
  getAndSetIntelliDocLicense,
  getDataFromResponse,
  getIntelliDocSettings,
  reorderArray,
} from './utils';

//Note from Tim: This is the worst code I have ever seen.
// This will be replaced in the middle of November 2024.
// Please dont spend too much time on this code.

export default function DefectInput({
  productID,
  qualityStation,
  dataIndex,
  isDefectInput,
  setIsDefectInput,
  defectsFound,
  setDefectsFound,
  updateCurrentDefect,
  defectsCurrentIndex,
  cameraState,
  setCameraState,
  defectDescriptionFields,
  defectDescriptionFieldOptions,
  setDefectDescriptionFieldOptions,
  defectDescriptionCanceled,
  defectDescriptionSaved,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
  handleCameraInput,
  aiProcessingResponse,
  setAiProcessingResponse,
  aiIsProcessing,
  setAiIsProcessing,
  itCards,
  defectPhotoRequired,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  // Add this at the top of your component
  const hasPrefilled = useRef({});

  /**
   * Sets a defectDescriptionFieldOption for given field. For more details, see the docs of DefectDescriptionHierarchie endpoint in Swagger.
   * @param {String} field defectDescriptionField to be updated
   * @param {String} value value of defectDescriptionFieldOption which should be set
   * @param {String} id ID of defectDescriptionFieldOption
   */
  const updateDefectDescriptionFields = (field, value, id) => {
    updateCurrentDefect(
      {
        [field]: value,
        [field + '_id']: id,
        defectFixed: defectsFound?.defectFixed || false,
      },
      defectsFound[defectsCurrentIndex]?.otherDefectID || defectsFound[defectsCurrentIndex]?.cardID
    );
  };

  /** =============== Retrieve License Information =============== */
  const [intelliDocLicense, setIntelliDocLicense] = useState(false);
  useEffect(() => {
    getAndSetIntelliDocLicense(setIntelliDocLicense);
  }, []);

  /** =============== Retrieve Intelligent Documentation Settings =============== */
  useEffect(() => {
    if (intelliDocLicense) configureIntelliDocSettings();
  }, [intelliDocLicense]);

  const [isAudioProcessingEnabled, setIsAudioProcessingEnabled] = useState(true);
  const [isImageProcessingEnabled, setIsImageProcessingEnabled] = useState(true);

  const configureIntelliDocSettings = async () => {
    const settings = await getIntelliDocSettings();
    if (
      settings &&
      settings.speechBasedDocumentation != undefined &&
      settings.imageBasedDocumentation != undefined
    ) {
      setIsAudioProcessingEnabled(settings.speechBasedDocumentation);
      setIsImageProcessingEnabled(settings.imageBasedDocumentation);
    } else {
      console.error(
        'Response from API for intelligentDocumentationSettings did not contain at least one of the expected keys: ' +
          '"speechBasedDocumentation", "imageBasedDocumentation". Has the format changed?\n' +
          'Both audio and image processing defaulted to being enabled.'
      );
    }
  };

  /** =============== Retrieve QR Code Settings =============== */
  const [displayQRCode, setDisplayQRCode] = useState(false);
  const [qrEncodingString, setQREncodingString] = useState('');
  useEffect(() => {
    getQRCodeSettings();
  }, []);

  const getQRCodeSettings = async () => {
    try {
      const response = await axiosClient.get(qrCodeSettingsEndpoint);
      if (response.data.qrCodeSettings) {
        setQREncodingString(response.data.qrCodeSettings.qrEncodingString);
        setDisplayQRCode(response.data.qrCodeSettings.displayQRCode);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  /** =============== Handle AI Processing Response =============== */
  useEffect(() => {
    resetAiSuggestions();
    handleAiProcessingResponse(aiProcessingResponse);
  }, [aiProcessingResponse]);

  useEffect(() => {
    resetAiSuggestions();
  }, [isDefectInput]);

  const [aiProcessingPromt, setAiProcessingPromt] = useState('');

  const setNoDetectionPromt = () => {
    setAiProcessingPromt(t('noDetectionPromt'));
  };

  const setPartialDetectionPromt = () => {
    setAiProcessingPromt(t('partialDetectionPromt'));
  };

  /**
   * Sorts the given option highest in the Dropdown options and marks it by color.
   * The marking is done by adding a field ['aiSuggestion']=true for the given option in the DefectDescriptionFieldOptions object.
   * @param {String} field defectDescriptionField
   * @param {{_id: String}} option a defectDescriptionFieldOption as suggested by the AI backend
   */
  const markOptionAsAiSuggestion = (field, option) => {
    setDefectDescriptionFieldOptions((previousOptions) => {
      const newOptions = { ...previousOptions };
      const optionIndex = newOptions[field].findIndex((x) => x._id === option._id);
      if (optionIndex !== -1) {
        reorderArray(newOptions[field], optionIndex, 0);
        newOptions[field][0]['aiSuggestion'] = true;
      }
      return newOptions;
    });
  };

  const resetAiSuggestions = () => {
    if (defectDescriptionFieldOptions)
      Object.keys(defectDescriptionFieldOptions).forEach((field) => {
        defectDescriptionFieldOptions[field].forEach((option) => {
          delete option['aiSuggestion'];
        });
      });
  };

  const styleAiSuggestion = { color: 'white', backgroundColor: theme.palette.success.main };

  /**
   * Updates the DefectInput dropdowns with defects detected by the AI models.
   * The first suggestions is selected, the others are marked by color and sorted highest in the dropdown options.
   * The user is informed via promt in case no or only partial detection was possible.
   * An unexpected format in the backend response is treated as if no defect was found.
   * @param {*} aiProcessingResponse response from "/intelligent_documentation" endpoint
   */
  const handleAiProcessingResponse = async (aiProcessingResponse) => {
    if (!aiProcessingResponse) return;
    if (aiProcessingResponse.status != 200) {
      setNoDetectionPromt();
      return;
    }

    const data = aiProcessingResponse.data.results.defectDescriptionFieldValues;
    const comment = aiProcessingResponse?.data?.results?.comment;
    // Update defect fields
    let countChanges = 0;
    for (let field of defectDescriptionFields?.defectDescriptionFieldsFrontendSorting) {
      const aiSuggestions = getDataFromResponse(data, field);
      if (!aiSuggestions) continue;

      let defectData = undefined;
      let i = 0;
      // If first suggestions cannot be found among options, try next one
      while (defectData === undefined && i < aiSuggestions.length) {
        defectData = defectDescriptionFieldOptions[field].find(
          (x) => x._id == aiSuggestions[i]._id
        );
        i++;
      }
      // Set DefectInputField to most likely AI suggestions
      if (defectData !== undefined) {
        const pathIDParts = defectData.pathID.split('/');
        const defectID = pathIDParts[pathIDParts.length - 1];
        updateDefectDescriptionFields(field, defectData.name, defectID);
        countChanges += 1;
      }
      // Sort further AI suggestions highest in dropdown options
      aiSuggestions.splice(0, i);
      aiSuggestions.reverse();
      aiSuggestions.forEach((suggestion) => {
        markOptionAsAiSuggestion(field, suggestion);
      });
    }

    if (comment && typeof comment === 'string') {
      updateCurrentDefect(
        { defectComment: comment },
        defectsFound[defectsCurrentIndex]?.otherDefectID ||
          defectsFound[defectsCurrentIndex]?.cardID
      );
    }

    // Display promt to user if not all fields have been detected
    if (countChanges == 0) setNoDetectionPromt();
    else if (countChanges != defectDescriptionFields?.defectDescriptionFields?.length)
      setPartialDetectionPromt();
  };
  /** ------------------------------------------------------------- */

  const uploadPhoto = async (srcImage) => {
    try {
      const response = await axiosClient.post(saveDefectImageEndpoint, {
        image: srcImage,
        qualityStation: qualityStation,
        productID: productID,
      });
      updateCurrentDefect(
        {
          defectImages: [
            ...defectsFound[defectsCurrentIndex]?.defectImages,
            response.data.imageURL,
          ],
        },
        defectsFound[defectsCurrentIndex]?.otherDefectID ||
          defectsFound[defectsCurrentIndex]?.cardID
      );
      setCameraState(false);
    } catch (error) {
      setCameraState(false);
      enqueueSnackbar(t('errorUploadingImage'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const [capturedImages, setCapturedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const deleteCapturedImage = () => {
    const updatedImages = capturedImages.filter((image) => image !== selectedImage);
    updateCurrentDefect(
      { defectImages: updatedImages },
      defectsFound[defectsCurrentIndex]?.otherDefectID || defectsFound[defectsCurrentIndex]?.cardID
    );
  };

  useEffect(() => {
    if (defectsFound[defectsCurrentIndex]?.defectImages.length > 0) {
      setCapturedImages(defectsFound[defectsCurrentIndex]?.defectImages);
      setSelectedImage(
        defectsFound[defectsCurrentIndex]?.defectImages[
          defectsFound[defectsCurrentIndex]?.defectImages.length - 1
        ]
      );
    } else {
      setCapturedImages([]);
      setSelectedImage(null);
    }
  }, [defectsCurrentIndex, defectsFound[defectsCurrentIndex]?.defectImages]);

  const handleNext = () => {
    const currentIndex = capturedImages.indexOf(selectedImage);
    const nextIndex = currentIndex + 1;
    if (nextIndex < capturedImages.length) {
      setSelectedImage(capturedImages[nextIndex]);
    }
  };

  const handleDefectSolved = () => {
    updateCurrentDefect(
      { defectFixed: !defectsFound[defectsCurrentIndex]?.defectFixed },
      defectsFound[defectsCurrentIndex]?.otherDefectID || defectsFound[defectsCurrentIndex]?.cardID
    );
  };

  const handleBack = () => {
    const currentIndex = capturedImages.indexOf(selectedImage);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setSelectedImage(capturedImages[prevIndex]);
    }
  };

  const centerHeaderText =
    defectsFound[defectsCurrentIndex]?.otherDefect !== 'yes'
      ? `${dataIndex + 1}/${itCards.length}`
      : t('otherDefect');
  const backButtonLabel =
    defectsFound[defectsCurrentIndex]?.otherDefect === 'yes' ? t('delete') : t('reset');

  useEffect(() => {
    if (
      !hasPrefilled.current[defectsCurrentIndex] &&
      isDefectInput &&
      defectsFound[defectsCurrentIndex] &&
      defectDescriptionFields &&
      defectDescriptionFieldOptions &&
      itCards[defectsCurrentIndex] &&
      itCards[defectsCurrentIndex].mostLikelyDefect
    ) {
      defectDescriptionFields.defectDescriptionFieldsFrontendSorting.forEach((field) => {
        if (!defectsFound[defectsCurrentIndex][field]) {
          const mappingId = itCards[defectsCurrentIndex].mostLikelyDefect[field];
          if (mappingId) {
            const option = defectDescriptionFieldOptions[field].find(
              (option) => option._id === mappingId
            );
            if (option) {
              const pathIDParts = option.pathID.split('/');
              const defectID = pathIDParts[pathIDParts.length - 1];
              updateDefectDescriptionFields(field, option.name, defectID);
            }
          }
        }
      });
      hasPrefilled.current[defectsCurrentIndex] = true;
    }
  }, [
    isDefectInput,
    defectsCurrentIndex,
    defectDescriptionFields,
    defectDescriptionFieldOptions,
    itCards,
  ]);

  return (
    <>
      {recommenderMenuOpen ? (
        <>
          {isDefectInput && !cameraState && (
            <CardWrapper>
              <Badge
                badgeContent={t('defectFixed')}
                variant="warning"
                className="defectFixedBadge"
                invisible={!defectsFound[defectsCurrentIndex]?.defectFixed}
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <CardPaperWrapper>
                  <SwipeCardHeader
                    productID={productID}
                    centerText={centerHeaderText}
                    qualityStation={qualityStation}
                    isExploding={false}
                  />
                  <ImagePanel
                    images={capturedImages}
                    backgroundImage={selectedImage}
                    heightOffset={10}
                  >
                    {displayQRCode && (
                      <QRCodeComponent
                        defectFound={defectsFound[defectsCurrentIndex]}
                        encodingString={qrEncodingString}
                      />
                    )}

                    <DefectInputImageStepper
                      capturedImages={capturedImages}
                      deleteCapturedImage={deleteCapturedImage}
                      selectedImage={selectedImage}
                      setCameraState={setCameraState}
                      handleNext={handleNext}
                      handleBack={handleBack}
                      defectPhotoRequired={defectPhotoRequired}
                    />
                  </ImagePanel>
                  {intelliDocLicense &&
                    (isAudioProcessingEnabled || isImageProcessingEnabled) &&
                    setAiProcessingResponse !== undefined && (
                      <AIProcessingPanel
                        handleCameraInput={handleCameraInput}
                        response={aiProcessingResponse}
                        setResponse={setAiProcessingResponse}
                        isProcessing={aiIsProcessing}
                        setIsProcessing={setAiIsProcessing}
                        isAudioProcessingEnabled={isAudioProcessingEnabled}
                        isImageProcessingEnabled={isImageProcessingEnabled}
                        promt={aiProcessingPromt}
                        setPromt={setAiProcessingPromt}
                      />
                    )}
                  {defectDescriptionFields?.defectDescriptionFieldsFrontendSorting?.map(
                    (field, indexOut) => (
                      <SwipeCardInput Icon={LabelIcon} key={indexOut}>
                        <QualitatioDropdown
                          id={'defect_dropdown_' + field.toString().toLowerCase()}
                          label={
                            field +
                            (defectDescriptionFields.defectDescriptionFieldsMandatory[field]
                              ? ' *'
                              : '')
                          }
                          options={defectDescriptionFieldOptions[field].map((option) => {
                            const pathIDParts = option.pathID.split('/');
                            const defectID = pathIDParts[pathIDParts.length - 1];
                            return {
                              label: option.name,
                              value: option.name,
                              _id: option._id,
                              defectID: defectID,
                              aiSuggestion: option.aiSuggestion,
                            };
                          })}
                          groupBy={(option) => (option.aiSuggestion ? 'ai' : 'other')}
                          renderGroup={(params) => (
                            <p
                              style={
                                params.group === 'ai'
                                  ? { margin: 0, ...styleAiSuggestion }
                                  : { margin: 0 }
                              }
                            >
                              {params.children}
                            </p>
                          )}
                          xs={true}
                          size="lg"
                          width="100%"
                          onChange={(event, newValue) => {
                            if (!newValue) newValue = { value: '', _id: '' };
                            updateDefectDescriptionFields(field, newValue.value, newValue.defectID);
                          }}
                          value={
                            defectsFound[defectsCurrentIndex] &&
                            defectsFound[defectsCurrentIndex][field]
                              ? {
                                  value: defectsFound[defectsCurrentIndex][field],
                                  label: defectsFound[defectsCurrentIndex][field],
                                  _id: defectsFound[defectsCurrentIndex][field + '_id'],
                                }
                              : { value: '', label: '', _id: '' }
                          }
                        />
                      </SwipeCardInput>
                    )
                  )}
                  <SwipeCardInput Icon={TestCommentIcon}>
                    <QualitatioInput
                      label={t('comment')}
                      type="text"
                      placeholder={t('addComment')}
                      width={'100%'}
                      onChange={(e) =>
                        updateCurrentDefect(
                          { defectComment: e.target.value },
                          defectsFound[defectsCurrentIndex]?.otherDefectID ||
                            defectsFound[defectsCurrentIndex]?.cardID
                        )
                      }
                      value={defectsFound[defectsCurrentIndex]?.defectComment || ''}
                      xs={true}
                    />
                  </SwipeCardInput>
                  <SwipeCardButtonBar>
                    <SwipeCardButtonWithLabel
                      Icon={CloseIcon}
                      label={backButtonLabel}
                      handleClick={defectDescriptionCanceled}
                      backgroundColor="#ff0000"
                    />
                    <SwipeCardButtonWithLabel
                      Icon={BuildIcon}
                      label={t('defectFixed')}
                      handleClick={handleDefectSolved}
                      backgroundColor="#E0E3E2"
                      color="black"
                      labelStyle={{ textAlign: 'center' }}
                    />
                    <SwipeCardButtonWithLabel
                      Icon={CheckIcon}
                      label={t('save')}
                      handleClick={() => defectDescriptionSaved(defectsCurrentIndex)}
                      backgroundColor="#1fbebd"
                    />
                  </SwipeCardButtonBar>
                  <SwipeCardFooter text={t('defectDocumentation')} />
                </CardPaperWrapper>
              </Badge>
            </CardWrapper>
          )}
          {cameraState && (
            <div className="camera-wrapper">
              <QualitatioCamera
                onTakePhoto={(dataUri) => {
                  uploadPhoto(dataUri);
                }}
                onCancel={() => setCameraState(false)}
              />
            </div>
          )}
        </>
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}

DefectInput.propTypes = {
  productID: PropTypes.string.isRequired,
  qualityStation: PropTypes.string.isRequired,
  dataIndex: PropTypes.number.isRequired,
  isDefectInput: PropTypes.bool.isRequired,
  setIsDefectInput: PropTypes.func.isRequired,
  defectsFound: PropTypes.arrayOf(
    PropTypes.shape({
      defectFixed: PropTypes.bool,
      defectImages: PropTypes.arrayOf(PropTypes.string),
      otherDefectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cardID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      otherDefect: PropTypes.string,
      defectComment: PropTypes.string,
    })
  ).isRequired,
  setDefectsFound: PropTypes.func.isRequired,
  updateCurrentDefect: PropTypes.func.isRequired,
  defectsCurrentIndex: PropTypes.number.isRequired,
  cameraState: PropTypes.bool.isRequired,
  setCameraState: PropTypes.func.isRequired,
  defectDescriptionFields: PropTypes.shape({
    defectDescriptionFieldsFrontendSorting: PropTypes.arrayOf(PropTypes.string).isRequired,
    defectDescriptionFieldsMandatory: PropTypes.objectOf(PropTypes.bool).isRequired,
  }).isRequired,
  defectDescriptionFieldOptions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pathID: PropTypes.string.isRequired,
        aiSuggestion: PropTypes.bool,
      })
    )
  ).isRequired,
  setDefectDescriptionFieldOptions: PropTypes.func.isRequired,
  defectDescriptionCanceled: PropTypes.func.isRequired,
  defectDescriptionSaved: PropTypes.func.isRequired,
  recommenderMenuOpen: PropTypes.bool.isRequired,
  setRecommenderMenuOpen: PropTypes.func.isRequired,
  handleCameraInput: PropTypes.func.isRequired,
  aiProcessingResponse: PropTypes.object,
  setAiProcessingResponse: PropTypes.func.isRequired,
  aiIsProcessing: PropTypes.bool.isRequired,
  setAiIsProcessing: PropTypes.func.isRequired,
  itCards: PropTypes.array.isRequired,
};
