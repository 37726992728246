import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QualitatioDialog } from '../../../../../components';
import QualitatioTable from '../../../../../components/QualitatioTable/QualitatioTable';

// This component can be used to select a specific event from a list of events to map to a test card to it.
const EventSelectionModal = ({
  eventModalOpen,
  setEventModalOpen,
  events,
  selectedLiveStreamData,
  eventSelected,
}) => {
  const { t } = useTranslation();
  const [eventDataWithId, setEventDataWithId] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const columnsEventForQualitatio = [
    { field: 'eventStreamName', headerName: t('streamName') },
    ...selectedLiveStreamData.reduce((acc, { streamName, fieldNames, filterFieldNames }) => {
      return [
        ...acc,
        ...fieldNames.map((fieldName) => ({
          field: fieldName,
          headerName: fieldName,
          flex: 1,
          minWidth: 100,
        })),
        ...filterFieldNames?.map((fieldName) => ({
          field: fieldName,
          headerName: fieldName,
          flex: 1, // dynamic width
          minWidth: 100,
        })),
      ];
    }, []),
    { field: 'eventFrequency', headerName: t('frequency') },
  ];

  useEffect(() => {
    if (events.length > 0 && events[0] !== null) {
      const eventDataObjects = events?.map((eventArray, index) => {
        const eventDataObject = { id: index };

        // Assuming the first element is always the stream name
        eventDataObject.eventStreamName = eventArray[0];

        // Assign field values from the eventArray to their corresponding fields
        selectedLiveStreamData.forEach((streamData) => {
          if (streamData.streamName === eventArray[0]) {
            streamData.fieldNames.forEach((fieldName, fieldIndex) => {
              eventDataObject[fieldName] = eventArray[fieldIndex + 1];
            });
            streamData.filterFieldNames.forEach((filterName, filterIndex) => {
              eventDataObject[filterName] =
                eventArray[streamData.fieldNames.length + filterIndex + 1];
            });
          }
        });

        // Assuming the last element is always the frequency
        eventDataObject.eventFrequency = Number(eventArray[eventArray.length - 1]);

        return eventDataObject;
      });

      setEventDataWithId(eventDataObjects);
    }
  }, [events, selectedLiveStreamData]);

  return (
    <QualitatioDialog
      title={t('selectEvent')}
      description={t('pleaseSelectTheEventYouWantToMapToTheTestCard')}
      open={eventModalOpen}
      onClose={() => setEventModalOpen(false)}
      actions={[
        {
          label: t('save'),
          onClick: () => {
            eventSelected(eventDataWithId[currentIndex]);
            setEventModalOpen(false);
          },
          order: 'primary',
        },
        {
          label: t('cancel'),
          onClick: () => {
            setEventModalOpen(false);
          },
          order: 'secondary',
        },
      ]}
    >
      {eventDataWithId.length === 0 ? (
        <div className="no-data">{t('noEventsAvailable')}</div>
      ) : (
        <QualitatioTable
          rows={eventDataWithId}
          columns={columnsEventForQualitatio}
          pageSize={10}
          pageSizeOptions={[10]}
          height={'100%'}
          maxHeight={'570px'}
          setCurrentIndex={setCurrentIndex}
        />
      )}
    </QualitatioDialog>
  );
};

export default EventSelectionModal;
