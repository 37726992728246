import { Chip, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QualitatioTable } from '../../../components';
import PropTypes from 'prop-types';

export default function AlertsOverviewTable({ dataLine, controlLimit, timestamps, alertStatuses }) {
  const { t } = useTranslation();

  const [tableRows, setTableRows] = useState([]);

  const calculateTableContent = () => {
    let labelCounter = 1;
    const result = dataLine.reduce((acc, data, index) => {
      if (data > controlLimit[index] && controlLimit[index] !== 0) {
        acc.push({
          id: labelCounter++,
          defectRate: data,
          limit: controlLimit[index],
          timestamp: new Date(timestamps[index]),
          alertStatus: alertStatuses[index] || 'Pending',
        });
      }
      return acc;
    }, []);
    return result;
  };

  useEffect(() => {
    setTableRows(calculateTableContent());
  }, [dataLine, controlLimit, timestamps, alertStatuses]);

  const columns = [
    {
      field: 'id',
      headerName: t('ei.alerts.label'),
      width: 50,
      renderCell: (params) => <span style={{ fontWeight: 'bold' }}>{params.value}</span>,
    },
    {
      field: 'defectRate',
      headerName: t('ei.alerts.defectRate'),
      width: 100,
      renderCell: (params) => {
        return `${params.value.toFixed(2)}%`;
      },
    },
    {
      field: 'limit',
      headerName: t('ei.alerts.controlLimit'),
      width: 100,
      renderCell: (params) => {
        return `${params.value.toFixed(2)}%`;
      },
    },
    {
      field: 'timestamp',
      headerName: t('ei.alerts.timestamp'),
      width: 200,
      flex: 1,
    },
    {
      field: 'alertStatus',
      headerName: t('ei.status'),
      width: 120,
      renderCell: (params) => <Chip label={params.value} />,
    },
  ];

  return (
    <Grid container alignItems="center" direction="row" spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h3">{t('ei.alertOverview')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <QualitatioTable
          rows={tableRows}
          columns={columns}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
        />
      </Grid>
    </Grid>
  );
}

AlertsOverviewTable.propTypes = {
  dataLine: PropTypes.array.isRequired,
  controlLimit: PropTypes.array.isRequired,
  timestamps: PropTypes.array.isRequired,
  alertStatuses: PropTypes.array.isRequired,
};
