import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import MuiXLicense from './MuiXLicense';
import axiosClient from './api/axiosClient';
import { allowedModulesEndpoint } from './api/endpoints';
import ArtificialIntelligence from './artificial_intelligence/ArtificialIntelligence';
import AIConfigurator from './artificial_intelligence/configurator/AIConfigurator';
import AIOptimizer from './artificial_intelligence/optimizer/AIOptimizer';
import LoginPage from './authentication/LoginPage';
import LogoutPage from './authentication/LogoutPage';
import MainLayout from './authentication/MainLayout';
import OAuthCallbackHandler from './authentication/OAuthCallbackHandler';
import ProtectedRoute from './authentication/ProtectedRoute';
import RegisterPage from './authentication/RegisterPage';
import RequestPasswordResetPage from './authentication/RequesetPasswordResetPage';
import ResetPasswordPage from './authentication/ResetPasswordPage';
import UnauthorizedPage from './authentication/UnauthorizedPage';
import VerifyEmailPage from './authentication/VerifyEmailPage';
import WelcomePage from './authentication/WelcomePage';
import ROLES from './authentication/roleConst';
import Basis from './basis/Basis';
import ConsumerConfigurator from './basis/consumer_configurator/ConsumerConfigurator';
import InputConfigurator from './basis/input_configurator/InputConfigurator';
import SystemConfigurator from './basis/system_configurator/SystemConfigurator';
import UserManager from './basis/user_management/UserManager';
import BusinessInsights from './business_insights/BusinessInsights';
import Monitor from './business_insights/monitor/Monitor';
import Reporter from './business_insights/reporter/Reporter';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { Navigationbarclosed } from './components/Navigationbarclosed';
import { Navigationbaropen } from './components/Navigationbaropen';
import ProfilePage from './components/ProfilePage';
import SupportPage from './components/SupportPage';
import EfficientTesting from './efficient_testing/EfficientTesting';
import EfficientTestingConfigurator from './efficient_testing/configurator/EfficientTestingConfigurator';
import ScanET from './efficient_testing/recommender/scan/ScanET';
import SelectET from './efficient_testing/recommender/select/SelectET';
import TestET from './efficient_testing/recommender/swipe/TestET';
import ETRules from './efficient_testing/rules/ETRules';
import ExtendedInsights from './extended_insights/ExtendedInsights';
import Dashboard from './extended_insights/dashboard/Dashboard';
import Settings from './extended_insights/settings/Settings';
import Homepage from './homepage/Homepage';
import IntelligentTesting from './intelligent_testing/IntelligentTesting';
import ProductionConfigurator from './intelligent_testing/configurator/ProductionConfigurator';
import Knowledge from './intelligent_testing/knowledge/Knowledge';
import Account from './intelligent_testing/recommender/account/Account';
import Scan from './intelligent_testing/recommender/scan/Scan';
import Select from './intelligent_testing/recommender/select/Select';
import Status from './intelligent_testing/recommender/status/Status';
import Swipe from './intelligent_testing/recommender/swipe/Swipe';
import './language_settings/i18n';
import {
  CompanyLogoProvider,
  CooperationProvider,
  QualitatioSnackbarProvider,
  WebSocketProvider,
} from './provider/index';
import SetupProcessPage from './setup_process/SetupProcessPage';
import { useAuthStore } from './store/auth.store';
import muiTheme from './theme/theme';
import MonitoredSystemInfo from './warning_system/MonitoredSystemInfo';
import WarningSystem from './warning_system/WarningSystem';

function App() {
  const [loading, setLoading] = useState(true);
  const fetchUserStatus = useAuthStore((state) => state.fetchUserStatus);
  const authState = useAuthStore();
  console.log('Auth State:', authState);
  const [pageTitle, setPageTitle] = useState('Home - Qualitatio');
  const setPageName = (name) => {
    setPageTitle(name + ' - Qualitatio');
  };
  const [productID, setProductID] = useState('');
  const [activeQualityStation, setActiveQualityStation] = useState('');

  const [recommenderMenuOpen, setRecommenderMenuOpen] = useState(true);

  const [navigationBarOpen, setNavigationBarOpen] = useState(true);

  const [allowedModules, setAllowedModules] = useState([]);
  const [allowedModulesLoading, setAllowedModulesLoading] = useState(true);
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  // Combine the effects
  useEffect(() => {
    const initializeApp = async () => {
      try {
        await fetchUserStatus(); // Fetch user status first

        // Once authentication is checked, fetch allowed modules
        const response = await axiosClient.get(allowedModulesEndpoint);
        if (response.data.modules) {
          setAllowedModules(response.data.modules);
          setLicenseExpired(response.data.expired);
          setDaysLeft(response.data.daysLeft);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setAllowedModulesLoading(false);
      }
    };

    initializeApp();
  }, [fetchUserStatus]);

  if (loading) {
    return <LoadingScreen loading={loading} />;
  }

  return (
    <Router>
      <WebSocketProvider>
        <CooperationProvider>
          <CompanyLogoProvider>
            <ThemeProvider theme={muiTheme}>
              <QualitatioSnackbarProvider>
                <div className="qualitatio-container">
                  <MuiXLicense />
                  <Header
                    className="header-instance"
                    frameClassName="header-2"
                    recommenderMenuOpen={recommenderMenuOpen}
                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                    licenseExpired={licenseExpired}
                    daysLeft={daysLeft}
                  />
                  {navigationBarOpen ? (
                    <Navigationbaropen
                      className="navigationbaropen-dark"
                      setNavigationBarOpen={setNavigationBarOpen}
                      allowedModules={allowedModules}
                    />
                  ) : (
                    <Navigationbarclosed
                      className="navigationbarclosed-dark"
                      setNavigationBarOpen={setNavigationBarOpen}
                      allowedModules={allowedModules}
                    />
                  )}
                  <div className="pageContent">
                    <Routes>
                      <Route path="/" element={<Navigate to="/home" />} />
                      <Route
                        path="/setup"
                        element={
                          <ProtectedRoute
                            element={<SetupProcessPage setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />

                      <Route
                        path="/home"
                        element={
                          <ProtectedRoute
                            element={
                              <Homepage setPageName={setPageName} allowedModules={allowedModules} />
                            }
                            allowedRoles={[
                              ROLES.ADMIN,
                              ROLES.IT_MANAGER,
                              ROLES.ET_MANAGER,
                              ROLES.AI_ENGINEER,
                              ROLES.BUSINESS_ANALYST,
                              ROLES.EWS_MANAGER,
                            ]}
                          />
                        }
                      />
                      <Route path="/welcome" element={<WelcomePage setPageName={setPageName} />} />
                      <Route path="/oauth-callback" element={<OAuthCallbackHandler />} />
                      <Route path="/" element={<MainLayout />}>
                        <Route path="login" element={<LoginPage setPageName={setPageName} />} />
                        <Route
                          path="register"
                          element={<RegisterPage setPageName={setPageName} />}
                        />
                        <Route
                          path="unauthorized"
                          element={<UnauthorizedPage setPageName={setPageName} />}
                        />
                        <Route path="logout" element={<LogoutPage setPageName={setPageName} />} />
                        <Route
                          path="reset-password"
                          element={<ResetPasswordPage setPageName={setPageName} />}
                        />
                        <Route
                          path="request-reset-password"
                          element={<RequestPasswordResetPage setPageName={setPageName} />}
                        />
                        <Route
                          path="verify-email"
                          element={<VerifyEmailPage setPageName={setPageName} />}
                        />
                      </Route>
                      <Route path="/profile" element={<ProfilePage setPageName={setPageName} />} />
                      <Route path="/support" element={<SupportPage setPageName={setPageName} />} />

                      <Route
                        path="/basis"
                        element={
                          <ProtectedRoute
                            element={<Basis setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="/basis/user_management"
                        element={
                          <ProtectedRoute
                            element={<UserManager setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="/basis/input_configurator"
                        element={
                          <ProtectedRoute
                            element={<InputConfigurator setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="/basis/consumer_configurator"
                        element={
                          <ProtectedRoute
                            element={<ConsumerConfigurator setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="/basis/system_configurator"
                        element={
                          <ProtectedRoute
                            element={<SystemConfigurator setPageName={setPageName} />}
                            allowedRoles={[ROLES.ADMIN]}
                          />
                        }
                      />

                      {(allowedModules.includes('Intelligent Testing') ||
                        allowedModulesLoading) && (
                        <>
                          <Route
                            path="/intelligent_testing"
                            element={
                              <ProtectedRoute
                                element={<IntelligentTesting setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/recommender/select"
                            element={
                              <ProtectedRoute
                                element={
                                  <Select
                                    setPageName={setPageName}
                                    setActiveQualityStation={setActiveQualityStation}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER, ROLES.IT_USER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/recommender/scan"
                            element={
                              <ProtectedRoute
                                element={
                                  <Scan
                                    setPageName={setPageName}
                                    setProductID={setProductID}
                                    qs={activeQualityStation}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER, ROLES.IT_USER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/recommender/swipe"
                            element={
                              <ProtectedRoute
                                element={
                                  <Swipe
                                    setPageName={setPageName}
                                    activeQualityStation={activeQualityStation}
                                    productID={productID}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER, ROLES.IT_USER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/recommender/account"
                            element={
                              <ProtectedRoute
                                element={
                                  <Account
                                    setPageName={setPageName}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER, ROLES.IT_USER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/recommender/status"
                            element={
                              <ProtectedRoute
                                element={
                                  <Status
                                    setPageName={setPageName}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER, ROLES.IT_USER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/configurator"
                            element={
                              <ProtectedRoute
                                element={<ProductionConfigurator setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER]}
                              />
                            }
                          />
                          <Route
                            path="/intelligent_testing/knowledge"
                            element={
                              <ProtectedRoute
                                element={<Knowledge setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.IT_MANAGER]}
                              />
                            }
                          />
                        </>
                      )}

                      {(allowedModules.includes('Artificial Intelligence') ||
                        allowedModulesLoading) && (
                        <>
                          <Route
                            path="/artificial_intelligence"
                            element={
                              <ProtectedRoute
                                element={<ArtificialIntelligence setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.AI_ENGINEER]}
                              />
                            }
                          />
                          <Route
                            path="/artificial_intelligence/configurator"
                            element={
                              <ProtectedRoute
                                element={<AIConfigurator setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.AI_ENGINEER]}
                              />
                            }
                          />
                          <Route
                            path="/artificial_intelligence/optimizer"
                            element={
                              <ProtectedRoute
                                element={<AIOptimizer setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.AI_ENGINEER]}
                              />
                            }
                          />
                        </>
                      )}

                      {(allowedModules.includes('Business Insights') || allowedModulesLoading) && (
                        <>
                          <Route
                            path="/business_insights"
                            element={
                              <ProtectedRoute
                                element={<BusinessInsights setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                          <Route
                            path="/business_insights/monitor"
                            element={
                              <ProtectedRoute
                                element={<Monitor setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                          <Route
                            path="/business_insights/reporter"
                            element={
                              <ProtectedRoute
                                element={<Reporter setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                        </>
                      )}

                      {(allowedModules.includes('Extended Insights') || allowedModulesLoading) && (
                        <>
                          <Route
                            path="/extended_insights"
                            element={
                              <ProtectedRoute
                                element={<ExtendedInsights setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                          <Route
                            path="/extended_insights/dashboard"
                            element={
                              <ProtectedRoute
                                element={<Dashboard setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                          <Route
                            path="/extended_insights/settings"
                            element={
                              <ProtectedRoute
                                element={<Settings setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.BUSINESS_ANALYST]}
                              />
                            }
                          />
                        </>
                      )}

                      {(allowedModules.includes('Efficient Testing') || allowedModulesLoading) && (
                        <>
                          <Route
                            path="/efficient_testing"
                            element={
                              <ProtectedRoute
                                element={<EfficientTesting setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/configurator"
                            element={
                              <ProtectedRoute
                                element={<EfficientTestingConfigurator setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/rules"
                            element={
                              <ProtectedRoute
                                element={<ETRules setPageName={setPageName} />}
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/recommender/select"
                            element={
                              <ProtectedRoute
                                element={
                                  <SelectET
                                    setPageName={setPageName}
                                    setActiveQualityStation={setActiveQualityStation}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER, ROLES.ET_USER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/recommender/scan"
                            element={
                              <ProtectedRoute
                                element={
                                  <ScanET
                                    setPageName={setPageName}
                                    setProductID={setProductID}
                                    qs={activeQualityStation}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER, ROLES.ET_USER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/recommender/test"
                            element={
                              <ProtectedRoute
                                element={
                                  <TestET
                                    setPageName={setPageName}
                                    activeQualityStation={activeQualityStation}
                                    productID={productID}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER, ROLES.ET_USER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/recommender/account"
                            element={
                              <ProtectedRoute
                                element={
                                  <Account
                                    setPageName={setPageName}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER, ROLES.ET_USER]}
                              />
                            }
                          />
                          <Route
                            path="/efficient_testing/recommender/status"
                            element={
                              <ProtectedRoute
                                element={
                                  <Status
                                    setPageName={setPageName}
                                    recommenderMenuOpen={recommenderMenuOpen}
                                    setRecommenderMenuOpen={setRecommenderMenuOpen}
                                  />
                                }
                                allowedRoles={[ROLES.ADMIN, ROLES.ET_MANAGER, ROLES.ET_USER]}
                              />
                            }
                          />
                        </>
                      )}
                      {(allowedModules.includes('Early Warning System') ||
                        allowedModulesLoading) && (
                        <Route
                          path="/early_warning_system/monitor"
                          element={
                            <ProtectedRoute
                              element={<Outlet />}
                              allowedRoles={[ROLES.ADMIN, ROLES.EWS_MANAGER]}
                            />
                          }
                        >
                          <Route index element={<WarningSystem setPageName={setPageName} />} />
                          <Route
                            path=":systemId"
                            element={<MonitoredSystemInfo setPageName={setPageName} />}
                          />
                        </Route>
                      )}
                      <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                  </div>
                  <Footer className="footer-instance" />
                </div>
              </QualitatioSnackbarProvider>
            </ThemeProvider>
          </CompanyLogoProvider>
        </CooperationProvider>
      </WebSocketProvider>
    </Router>
  );
}

export default App;
