import React, { useEffect, useState } from 'react';

const UserAvatar = ({ name, index }) => {
    const [visible, setVisible] = useState(false); // Start with invisible
    const initials = name?.split(" ").map(n => n[0]).join("");
    const colors = ["#FF5733", "#33C1FF", "#FFD133", "#DA33FF"];

    useEffect(() => {
        // Trigger the appearance animation shortly after the component mounts
        const appearTimer = setTimeout(() => {
            setVisible(true);
        }, 50); // A short delay before starting the appearance animation

        // Trigger the disappearance animation before the component is unmounted
        return () => {
            clearTimeout(appearTimer);
            setVisible(false); // This might not be necessary since the component is unmounted
        };
    }, [name]); // Dependency on 'name' to re-run effect if the user changes

    const avatarStyle = {
        backgroundColor: colors[index % colors.length],
        opacity: visible ? 1 : 0,
        transform: visible ? 'scale(1)' : 'scale(0.8)',
        transition: 'opacity 1.5s ease, transform 1.5s ease'
    };

    return (
        <div className={`user-avatar ${visible ? "user-avatar-visible" : ""}`} style={avatarStyle} description={name}>
            {initials}
        </div>
    );
};

export default UserAvatar;
