/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import QualitatioButton from "../QualitatioButton/QualitatioButton";
import "./style.css";

export const ModulCardPr = ({
  text,
  text1,
  icon,
  href,
  className,
  rectangleClassName,
  divClassName,
}) => {
  const { t } = useTranslation();;
  const navigate = useNavigate();
  return (
    <div className={`modul-card-pr ${className}`}>
      <div className="frame-6">
        <div className="frame-6">
          <div className={`rectangle ${rectangleClassName}`} />
          <div className="frame-7">
            <div className={`pr-fplanung-3 ${divClassName}`}>{text}</div>
            {icon}
          </div>
        </div>
        <div className="supporting-text">{text1}</div>
      </div>
      <QualitatioButton text={t("open")} onClick={() => {navigate(href) }} disabled={false} maxWidth={"25%"} />
    </div>
  );
};

ModulCardPr.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};
