import { KeyboardVoice, PhotoCamera, Stop } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { triggerAIProcessing } from '../utils';
import { AudioReviewPanel } from './AudioReviewPanel';
import { CardPanelWrapper } from './CardPanelWrapper';
import { ProcessingPromt } from './ProcessingPromt';


const AIProcessingPanel = ({ handleCameraInput, response, setResponse, isProcessing, setIsProcessing, isAudioProcessingEnabled, isImageProcessingEnabled, promt, setPromt }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // Page state variables
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioCheck, setIsAudioCheck] = useState(false);

  // reset state on every initial render, e.g., page reload
  useEffect(() => {
    resetPageState();
  }, [])

  useEffect(() => {
    if (promt)
      enqueueSnackbar(promt, { variant: 'info' });
  }, [promt])

  // update page state => hide response
  const resetPageState = () => {
    setIsRecording(false);
    setIsAudioCheck(false);
    setIsProcessing(false);
    setPromt("");
  }

  const handleCameraClick = () => {
    resetPageState();
    handleCameraInput();
  }

  /** ================ Audio Recording ================ */
  const [recordedUrl, setRecordedUrl] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);

  const handleAudioClick = () => {
    resetPageState();
    if (!isRecording) {
      startRecording();
      setIsRecording(true);
    } else {
      stopRecording();
      setIsRecording(false);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        setRecordedBlob(chunks.current[0]);
        const url = URL.createObjectURL(chunks.current[0]);
        setRecordedUrl(url);
        setIsAudioCheck(true);
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  /** ------------------------------------------------- */

  const handleAcceptAudio = () => {
    // update page state
    setIsAudioCheck(false);
    setIsProcessing(true);

    triggerAIProcessing(recordedBlob, true).then((response) => {
      setIsProcessing(false);
      setResponse(response);
    });
  }

  const handleCancelAudio = () => {
    setIsAudioCheck(false);
  }

  const gridItemStyleProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const audioButtonProps = isRecording ? {
    style: { "background-color": theme.palette.error.main },
  } : {};

  return (
    <>
      {/* ================= Audio & Image Button ================ */}
      <CardPanelWrapper>
        <Grid container>
          {isImageProcessingEnabled && (
            // conditional xs: Place at center if only one method is enabled
            <Grid item {...gridItemStyleProps} xs={isAudioProcessingEnabled ? 6 : 12} >
              <IconButton onClick={() => handleCameraClick()} variant="qualitatio" >
                <PhotoCamera />
              </IconButton>
            </Grid>
          )}
          {isAudioProcessingEnabled && (
            // conditional xs: Place at center if only one method is enabled
            <Grid item {...gridItemStyleProps} xs={isImageProcessingEnabled ? 6 : 12}>
              <IconButton onClick={() => handleAudioClick()} variant="qualitatio" {...audioButtonProps}>
                {isRecording ? <Stop /> : <KeyboardVoice />}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CardPanelWrapper>
      {/* ============== Panel to review and submit/discard a recorded audio ================== */}
      {isAudioCheck && (
        <CardPanelWrapper>
          <AudioReviewPanel recordedUrl={recordedUrl} handleAcceptAudio={handleAcceptAudio} handleCancelAudio={handleCancelAudio} />
        </CardPanelWrapper>
      )}
      {/* ================= Processing & Recording state visual ================ */}
      {isProcessing && (
        <CardPanelWrapper>
          <ProcessingPromt promt={t('processingStatusPromt')} />
        </CardPanelWrapper>
      )}
      {isRecording && (
        <CardPanelWrapper>
          <ProcessingPromt promt={t('recordingStatusPromt')} />
        </CardPanelWrapper>
      )}
    </>
  );
};

export default AIProcessingPanel;
