import { Link } from 'react-router-dom';
import './ContactCard.css';
import { filesRoot } from '../../api/endpoints';

function ContactCard({ name, email, phone, image }) {

    return (
        <div className='module-card'>
            <div className="module-card-pr class-2">
                <div className="contact-card">
                    <div className="card-sm">
                        <div className="frame-6">
                            <div className="div-3">
                                <div className='borderWrapper'>
                                    <div className='avatarContainer'>
                                        <img src={image} />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-7">
                                <div className="text-wrapper-5">{name}</div>
                                <div className="text-wrapper-6">
                                    <Link to={`mailto:${email}`} className="link">
                                        {email}
                                    </Link>
                                    <br />
                                    <Link to={`tel:${phone}`} className="link">
                                        {phone}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;