import MenuIcon from '@mui/icons-material/Menu';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { Formik, getIn } from 'formik';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../../../store/auth.store';
import {
  createSimpleRule,
  fullValidationSchema,
  getHighLevelDescription,
  getNumberOfApplications,
  getQualityStations,
  getStreamFields,
  getStreamFieldValuesForSidebar,
  highLevelDescriptionValidationSchema,
  updateSimpleRule,
} from '../utils';
import CreateRuleFormDialog from './CreateRuleFormDialog';
import CreateRuleModalAccordion from './CreateRuleModalAccordion';
import CreateRuleModalSidebar from './CreateRuleModalSidebar';
import CreateRuleModalSteps from './CreateRuleModalSteps';
export default function CreateRuleModal({ open, onClose, onRuleCreated, isEditMode, initialData }) {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();

  const accordionItems = [
    { panel: 'ruleType', label: 'Rule Type', component: 'ruleType', fields: ['ruleType'] },
    {
      panel: 'mainCondition',
      label: 'Main Condition',
      component: 'mainCondition',
      fields: ['ruleDetails.mainCondition'],
    },
    {
      panel: 'rangeSettings',
      label: 'Range Settings',
      component: 'rangeSettings',
      fields: ['ruleDetails.aggregationRange', 'ruleDetails.actionFrequency'],
    },
    {
      panel: 'generalSettings',
      label: 'General Settings',
      component: 'generalSettings',
      fields: ['qualityStation', 'expirationDate', 'ruleName', 'applicationMode'],
    },
  ];

  const [qualityStations, setQualityStations] = useState([]);
  const [streamFields, setStreamFields] = useState([]);

  useEffect(() => {
    getQualityStations().then((data) => setQualityStations(data));
    const debouncedGetStreamFields = debounce(() => {
      getStreamFields().then((data) => {
        setStreamFields(data);
      });
    }, 1500); // Adjust the debounce time as needed

    debouncedGetStreamFields(); // Call the debounced function
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [highLevelDescription, setHighLevelDescription] = useState(
    initialData?.ruleDetails?.highLevelDescription || ''
  );
  const hasHandledSubmitRef = useRef(false);
  const [lastNumberOfSubmits, setLastNumberOfSubmits] = useState(0);
  const [numberOfApplications, setNumberOfApplications] = useState(0);
  const [isPercent, setIsPercent] = useState(false);
  const [resultTimeValue, setResultTimeValue] = useState(1);
  const [resultTimeUnit, setResultTimeUnit] = useState('d');
  const [isFetchingNumberOfApplications, setIsFetchingNumberOfApplications] = useState(false);
  const [fetchRequestId, setFetchRequestId] = useState(0);
  const latestRequestId = useRef(0);
  const [groupedSidebarData, setGroupedSidebarData] = useState([]);

  const [localResultTimeValue, setLocalResultTimeValue] = useState(1);

  const debouncedSetResultTimeValue = useMemo(
    () =>
      debounce((value) => {
        setResultTimeValue(value);
      }, 500), // 500ms debounce
    []
  );

  const handleResultTimeValueChange = (e) => {
    const value = e.target.value;
    setLocalResultTimeValue(value);
    debouncedSetResultTimeValue(value);
  };

  useEffect(() => {
    return () => {
      debouncedSetResultTimeValue.cancel();
    };
  }, [debouncedSetResultTimeValue]);

  const defaultInitialValues = {
    creator: user.id,
    expirationDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
    ruleName: '',
    ruleType: 'simple',
    ruleDetails: {
      mainCondition: [
        {
          conditionOrderNumber: 0,
          columns: [],
          logicalOperator: null,
          columnOperator: '',
          operator: '',
          valueCombinations: [
            {
              combinationOrderNumber: 0,
              value: '',
              aggregationFunction: '',
              aggregationColumn: {
                column: '',
                columnType: '',
                indexName: '',
              },
              executionOrderNumber: 0,
            },
          ],
        },
      ],
      actionFrequency: {
        type: 'product',
        frequency: 1,
        timeReference: {
          timeValue: 1,
          timeUnit: 'd',
        },
      },
      aggregationRange: {
        type: '',
        range: null,
        timeUnit: '',
        groundTruthStreamIndex: '',
      },
      highLevelDescription: '',
    },
    qualityStation: '',
    applicationMode: 'test',
  };

  // **Map initialData to Formik's initialValues Structure**
  const mappedInitialValues =
    isEditMode && initialData
      ? {
          creator: initialData.creator || user.id,
          expirationDate: initialData.expirationDate
            ? new Date(initialData.expirationDate).toISOString()
            : defaultInitialValues.expirationDate,
          ruleName: initialData.ruleName || '',
          ruleType: initialData.ruleType || 'simple',
          ruleDetails: {
            mainCondition:
              initialData.ruleDetails?.mainCondition ||
              defaultInitialValues.ruleDetails.mainCondition,
            actionFrequency:
              initialData.ruleDetails?.actionFrequency ||
              defaultInitialValues.ruleDetails.actionFrequency,
            aggregationRange:
              initialData.ruleDetails?.aggregationRange ||
              defaultInitialValues.ruleDetails.aggregationRange,
            highLevelDescription: initialData.ruleDetails?.highLevelDescription || '',
          },
          qualityStation: initialData.qualityStation || '',
          applicationMode: initialData.applicationMode || 'test',
        }
      : defaultInitialValues;

  const initialValues = useMemo(() => mappedInitialValues, [isEditMode, initialData]);

  // Initialize Formik using useFormik hook
  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    setSubmitting(true);
    try {
      if (isEditMode) {
        const response = await updateSimpleRule(initialData.id, values);
        console.log('Rule updated successfully:', response);
      } else {
        const response = await createSimpleRule(values);
        console.log('Rule created successfully:', response);
      }
      onRuleCreated();
    } catch (error) {
      console.error('Error creating rule:', error);
      setErrors({
        submit: `Failed to ${isEditMode ? 'update' : 'create'} rule. Please try again.`,
      });
    } finally {
      setSubmitting(false);
      resetForm();
      setCurrentIndex(0);
      setSidebarOpen(false);
      setHighLevelDescription('');
      setNumberOfApplications(0);
      setIsPercent(false);
      setResultTimeValue(1);
      setLocalResultTimeValue(1);
      setResultTimeUnit('d');
      setGroupedSidebarData([]);
      onClose();
      enqueueSnackbar(t(isEditMode ? 'ruleUpdated' : 'ruleCreated'), {
        variant: isEditMode ? 'success' : 'warning',
      });
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handler to open the sidebar and initiate loading
  const handleOpenSidebar = () => {
    setLoading(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={fullValidationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formikProps) => {
        // Fetch high level description based on the form values
        useEffect(() => {
          const fetchDescription = async () => {
            const isLightValid = await highLevelDescriptionValidationSchema.isValid(
              formikProps.values,
              {
                abortEarly: false,
              }
            );
            if (isLightValid) {
              const description = await getHighLevelDescription(formikProps.values);
              if (description) {
                setHighLevelDescription(description);
              } else {
                setHighLevelDescription('');
              }
            }
          };
          fetchDescription();
        }, [
          formikProps.values.ruleDetails.mainCondition,
          formikProps.values.applicationMode,
          formikProps.values.ruleDetails.actionFrequency,
          formikProps.isValid,
        ]);

        useEffect(() => {
          if (
            formikProps.submitCount > 0 &&
            !hasHandledSubmitRef.current &&
            lastNumberOfSubmits < formikProps.submitCount
          ) {
            // Check for the first error only when submitCount increases
            if (Object.keys(formikProps.errors).length > 0) {
              for (let i = 0; i < accordionItems.length; i++) {
                const item = accordionItems[i];
                for (let field of item.fields) {
                  const error = getIn(formikProps.errors, field);
                  if (error) {
                    setCurrentIndex(i);
                    hasHandledSubmitRef.current = true; // Mark that the error has been handled for this submit
                    return;
                  }
                }
              }
            }
          }
        }, [formikProps.submitCount, formikProps.errors]);

        useEffect(() => {
          const currentAccordionItem = accordionItems[currentIndex];
          const hasNoErrorsAtCurrentIndex = currentAccordionItem.fields.every(
            (field) => !getIn(formikProps.errors, field)
          );

          if (formikProps.submitCount > 0 && hasNoErrorsAtCurrentIndex) {
            hasHandledSubmitRef.current = false; // Reset only when the current index's errors are resolved
          }
        }, [currentIndex, formikProps.submitCount, formikProps.errors]);

        useEffect(() => {
          if (formikProps.isSubmitting && formikProps.submitCount > lastNumberOfSubmits) {
            setLastNumberOfSubmits((prevState) => prevState + 1);
          }
        }, [formikProps.isSubmitting]);

        useEffect(() => {
          const fetchNumberOfApplications = async () => {
            setIsFetchingNumberOfApplications(true);
            const currentRequestId = fetchRequestId + 1;
            setFetchRequestId(currentRequestId);
            latestRequestId.current = currentRequestId;

            const isLightValid = await highLevelDescriptionValidationSchema.isValid(
              formikProps.values,
              {
                abortEarly: false,
              }
            );
            if (!isLightValid) {
              if (latestRequestId.current === currentRequestId) {
                setIsFetchingNumberOfApplications(false);
              }
              return;
            }
            try {
              const numberOfApplications = await getNumberOfApplications(
                formikProps.values,
                `${resultTimeValue}${resultTimeUnit}`,
                isPercent
              );
              if (latestRequestId.current === currentRequestId) {
                setNumberOfApplications(numberOfApplications);
              }
            } catch (error) {
              console.error('Error fetching number of applications:', error);
              if (latestRequestId.current === currentRequestId) {
                setNumberOfApplications(0);
              }
            } finally {
              if (latestRequestId.current === currentRequestId) {
                setIsFetchingNumberOfApplications(false); // End loading
              }
            }
          };

          fetchNumberOfApplications();
        }, [
          formikProps.values.ruleDetails.mainCondition,
          formikProps.values.applicationMode,
          formikProps.values.ruleDetails.actionFrequency,
          formikProps.values.ruleDetails.aggregationRange,
          isPercent,
          resultTimeValue,
          resultTimeUnit,
        ]);

        useEffect(() => {
          const fetchSidebarValues = async () => {
            const isLightValid = await highLevelDescriptionValidationSchema.isValid(
              formikProps.values,
              {
                abortEarly: false,
              }
            );
            if (isLightValid) {
              const values = await getStreamFieldValuesForSidebar(formikProps.values);
              if (values) setGroupedSidebarData(values);
            }
          };

          fetchSidebarValues();
        }, [formikProps.values.ruleDetails.mainCondition]);

        return (
          <CreateRuleFormDialog
            title={t('createRule')}
            subheader={t('createRuleText')}
            open={open}
            onClose={onClose}
            fullWidth
            overflow="hidden"
            description={`<em>${highLevelDescription}</em>`}
            gap={0.5}
            onSubmit={formikProps.handleSubmit} // Use formikProps.handleSubmit here
            actions={[
              {
                label: t('cancel'),
                onClick: () => {
                  onClose();
                  formikProps.resetForm();
                  setCurrentIndex(0);
                  setHighLevelDescription('');
                  setNumberOfApplications(0);
                  setIsPercent(false);
                  setResultTimeValue(1);
                  setLocalResultTimeValue(1);
                  setResultTimeUnit('d');
                  setSidebarOpen(false);
                  setGroupedSidebarData([]);
                  hasHandledSubmitRef.current = false;
                },
                order: 'secondary',
              },
              {
                label: t(isEditMode ? 'save' : 'create'),
                order: 'primary',
                disabled: formikProps.isSubmitting,
                type: 'submit',
              },
            ]}
            results={{
              timeValue: localResultTimeValue,
              timeUnit: resultTimeUnit,
              isPercent: isPercent,
              onTimeValueChange: (e) => handleResultTimeValueChange(e),
              onTimeUnitChange: (e) => setResultTimeUnit(e.target.value),
              onIsPercentChange: (e) => setIsPercent(e.target.checked),
              value: numberOfApplications,
              isFetchingNumberOfApplications: isFetchingNumberOfApplications,
            }}
          >
            <Grid container spacing={2} display={'flex'} direction={'row'} height="100%">
              <Grid
                item
                display={'flex'}
                direction={'column'}
                width={sidebarOpen ? { xs: '40%', sm: '30%' } : '60px'} // Responsive widths
                height="100%"
                sx={{
                  transition: 'width 0.3s ease', // Smooth transition
                  overflow: 'hidden',
                }}
              >
                {/* Show either the loading spinner or menu icon */}
                {!sidebarOpen && (
                  <Grid item>
                    <IconButton onClick={handleOpenSidebar}>
                      {loading ? <CircularProgress size={24} /> : <MenuIcon />}
                    </IconButton>
                  </Grid>
                )}

                {/* Sidebar Component */}

                <CreateRuleModalSidebar
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen} // Closes sidebar when triggered from child
                  groupedSidebarData={groupedSidebarData} // Ensure this prop is correctly passed
                  streamFields={streamFields} // Ensure this prop is correctly passed
                  setLoading={setLoading} // Callback to hide loading spinner
                  loading={loading}
                />
              </Grid>

              {/* Main Content */}
              <Grid
                item
                container
                display={'flex'}
                gap={'20vh'}
                width={sidebarOpen ? { xs: '60%', sm: '70%' } : 'calc(100% - 60px)'}
                height="100%"
                justifyContent={'center'}
                sx={{
                  transition: 'width 0.3s ease', // Smooth transition
                }}
              >
                <Grid item display={'flex'} direction={'column'} width={'100%'}>
                  <CreateRuleModalAccordion
                    accordionItems={accordionItems}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    qualityStations={qualityStations}
                    streamFields={streamFields}
                    sidebarOpen={sidebarOpen}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  direction="row"
                  alignItems="center"
                  position="sticky"
                  bottom={0}
                  zIndex={1}
                  mt={2}
                >
                  <CreateRuleModalSteps
                    panels={accordionItems}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CreateRuleFormDialog>
        );
      }}
    </Formik>
  );
}

CreateRuleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRuleCreated: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  initialData: PropTypes.object.isRequired,
};
