import { Cancel, CheckCircle } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const AudioReviewPanel = ({ recordedUrl, handleAcceptAudio, handleCancelAudio }) => {
    const theme = useTheme();

    return (
        <Grid container>
            <Grid item xs={3} display='flex' flexDirection='column' alignItems='center'>
                <IconButton onClick={() => handleCancelAudio()} >
                    <Cancel style={{ color: theme.palette.error.main }} />
                </IconButton>
            </Grid>
            <Grid item xs={6} display='flex' flexDirection='column' alignItems='center'>
                <audio controls controlsList='nodownload' src={recordedUrl} style={{ height: "100%", width: "100%", paddingTop: "2px", paddingBottom: "2px" }} />
            </Grid>
            <Grid item xs={3} display='flex' flexDirection='column' alignItems='center'>
                <IconButton onClick={() => handleAcceptAudio()} >
                    <CheckCircle style={{ color: theme.palette.success.main }} />
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default AudioReviewPanel;