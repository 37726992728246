/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from 'prop-types';
import React from 'react';
import { filesRoot } from '../../api/endpoints';
import './style.css';

export const BottomBarWhite = ({
  className,
  frame = filesRoot + 'basis/logoFiles/logo.png',
}) => {
  return (
    <div className={`bottom-bar-white ${className}`}>
      <img className="frame-2" alt="Frame" src={frame} />
      <div className="div-wrapper-bottom-bar">
        <div className="text-wrapper-2">powered by Datagon.AI</div>
      </div>
    </div>
  );
};

BottomBarWhite.propTypes = {
  frame: PropTypes.string,
};
