import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, Box, Button, Grid, IconButton, Switch, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import QualitatioTable from '../../../components/QualitatioTable/QualitatioTable';
import ConnectionDetailsOverlay from './ConnectionDetailsOverlay';
import DeleteOutputStreamSubscriptionOverlay from './DeleteStreamSubscriptionOverlay';
import EditSubscriptionOverlay from './EditSubscriptionOverlay';

const OutputStreamSubscriptionsTable = ({
  outputStreamSubscriptions,
  setOutputStreamSubscriptions,
  reload,
  availableOutputStreams,
  onSubscriptionSetup,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  /** HANDLE OUTPUT STREAM SUBSCRIPTION ACTIVATION CHANGE */
  const updateSubscriptionActivation = async (index, active) => {
    try {
      if (active) {
        const snackbarKey = enqueueSnackbar(t('activatingSubscription'), {
          variant: 'info',
          persist: true,
        });
        const response = await axiosClient.post(outputStreamSubscriptionEndpoint + '/activate', {
          subscriptionID: outputStreamSubscriptions[index].id,
        });
        closeSnackbar(snackbarKey);
        setOutputStreamSubscriptions(response.data.outputStreamSubscriptions);
        enqueueSnackbar(t('subscriptionSuccessfullyActivated'), { variant: 'success' });
      } else {
        const snackbarKey = enqueueSnackbar(t('deactivatingSubscription'), {
          variant: 'info',
          persist: true,
        });
        const response = await axiosClient.post(outputStreamSubscriptionEndpoint + 'deactivate', {
          subscriptionID: outputStreamSubscriptions[index].id,
        });
        setOutputStreamSubscriptions(response.data.outputStreamSubscriptions);
        closeSnackbar(snackbarKey);
        enqueueSnackbar(t('subscriptionSuccessfullyDeactivated'), { variant: 'success' });
      }
    } catch (error) {
      const serverMessage = error.response && error.response.data && error.response.data.message;
      const translationToken =
        serverMessage && serverMessage === 'Kafka Server already running on machine'
          ? 'kafkaServerAlreadyRunningOnMachine'
          : null;

      if (active) {
        enqueueSnackbar(
          (serverMessage && t(translationToken)) || t('errorWhileActivatingSubscription'),
          { variant: 'error' },
        );
        console.error('Error:', error);
      } else {
        enqueueSnackbar(serverMessage || t('errorWhileDeactivatingSubscription'), {
          variant: 'error',
        });
        console.error('Error:', error);
      }
    }
  };

  /* HANDLE CONNECTION DETAILS */
  const [isConnectionDetailOpen, setIsConnectionDetailOpen] = useState(false);
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [isDeleteOverlayOpen, setIsDeleteOverlayOpen] = useState(false);
  const [dataIndex, setDataIndex] = useState();

  const openConnectionDetailOverlay = (index) => {
    setDataIndex(index);
    setIsConnectionDetailOpen(true);
  };

  const openEditOverlay = (index) => {
    setDataIndex(index);
    setIsEditOverlayOpen(true);
  };

  const openDeleteOverlay = (index) => {
    setDataIndex(index);
    setIsDeleteOverlayOpen(true);
  };

  /**Qualitatio Table Settings */
  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
    },
    {
      field: 'stream',
      headerName: t('stream'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
    },
    {
      field: 'connectionDetails',
      headerName: t('connectionDetails'),
      flex: 1,
      renderCell: (params) => (
        <IconButton
          variant="qualitatio"
          squared={true}
          color="primary"
          onClick={() => openConnectionDetailOverlay(params.row.id)}
          style={{ backgroundColor: theme.palette.info.secondary }}
        >
          <OpenInNewIcon color="primary" />
        </IconButton>
      ),
    },
    {
      field: 'active',
      headerName: t('active'),
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={params?.value}
          onChange={(e) => updateSubscriptionActivation(params.row.id, e.target.checked)}
          variant="qualitatio"
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '15px',
              alignItems: 'center',
            }}
          >
            <IconButton
              variant="qualitatio"
              squared={true}
              onClick={() => openEditOverlay(params.row.id)}
              style={{ backgroundColor: theme.palette.info.secondary }}
            >
              <EditRoundedIcon color="primary" />
            </IconButton>
            <IconButton
              variant="qualitatio"
              squared={true}
              onClick={() => openDeleteOverlay(params.row.id)}
              style={{ backgroundColor: theme.palette.error.main }}
            >
              <DeleteForeverIcon color="white" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const rows = outputStreamSubscriptions.map((el, idx) => {
    return {
      id: idx,
      name: el.name,
      stream: el.stream,
      type: el.type,
      connectionDetails: el.connectionDetails,
      active: el.active,
    };
  });

  /** HANDLE MULTIPLE ACTIONS */
  const [multipleActions, setMultipleActions] = useState();

  const multipleActionsOptions = [
    { value: 'activate', label: t('activate') },
    { value: 'deactivate', label: t('deactivate') },
    /*         { value: "delete", label: t("delete") }, */
  ];

  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const handleMultipleActions = async () => {
    if (selectedRowIDs.length === 0) {
      enqueueSnackbar(t('noElementSelectedSnack'), { variant: 'error' });
      return;
    }
    if (multipleActions?.value === undefined || multipleActions?.value === null) {
      enqueueSnackbar(t('noActionSelectedSnack'), { variant: 'error' });
      return;
    }

    for (const idx of selectedRowIDs) {
      // Use for...of for sequential processing
      if (multipleActions === 'activate') {
        if (!outputStreamSubscriptions[idx].active) {
          await updateSubscriptionActivation(idx, true);
        }
      } else if (multipleActions === 'deactivate') {
        if (outputStreamSubscriptions[idx].active) {
          await updateSubscriptionActivation(idx, false);
        }
      } else if (multipleActions === 'delete') {
        // await deleteModel(outputStreamSubscriptions[idx].id);
      }
    }
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      direction="row"
      spacing={2}
      style={{ marginTop: '10px' }}
    >
      <Grid item>
        <Autocomplete
          value={multipleActions}
          onChange={(event, newValue) => {
            setMultipleActions(newValue);
          }}
          options={multipleActionsOptions}
          getOptionLabel={(option) => option.label} // Assuming each option has a label property
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} label={t('selectAction')} variant="outlined" />
          )}
        />
      </Grid>
      <Grid item>
        <Button color="primary" onClick={handleMultipleActions} variant="qualitatio">
          {t('apply')}
        </Button>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        style={{ display: 'flex', margin: '0 0 0 auto', justifyContent: 'flex-end' }}
      >
        <Button onClick={onSubscriptionSetup} size="small" variant="qualitatio">
          {t('setUpNewSubscription')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <QualitatioTable
          style={{ marginTop: '20px' }}
          columns={columns}
          rows={rows}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          onRowSelectionModelChange={(selection) => {
            if (selection.length === outputStreamSubscriptions.length) {
              setSelectedRowIDs(rows.map((el, idx) => idx));
            } else {
              setSelectedRowIDs(selection.map((el, idx) => el));
            }
          }}
        />
      </Grid>
      {isEditOverlayOpen && (
        <EditSubscriptionOverlay
          el={outputStreamSubscriptions[dataIndex]}
          isOpen={isEditOverlayOpen}
          onClose={() => setIsEditOverlayOpen(false)}
          setOutputStreamSubscriptions={setOutputStreamSubscriptions}
          availableOutputStreams={availableOutputStreams}
        />
      )}
      {isDeleteOverlayOpen && (
        <DeleteOutputStreamSubscriptionOverlay
          subscriptionID={outputStreamSubscriptions[dataIndex]?.id}
          onClose={() => setIsDeleteOverlayOpen(false)}
          showDeleteConfirm={isDeleteOverlayOpen}
          setOutputStreamSubscriptions={setOutputStreamSubscriptions}
          reload={reload}
        />
      )}
      {isConnectionDetailOpen && (
        <ConnectionDetailsOverlay
          open={isConnectionDetailOpen}
          connectionType={outputStreamSubscriptions[dataIndex]?.type}
          connectionDetails={outputStreamSubscriptions[dataIndex]?.connectionDetails}
          onClose={() => setIsConnectionDetailOpen(false)}
        />
      )}
    </Grid>
  );
};

export default OutputStreamSubscriptionsTable;
