import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import {
  Avatar,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { cardCommentEndpoint, filesRoot } from '../../../api/endpoints';
import QualitatioAvatar from '../../../components/QualitatioAvatar/QualitatioAvatar';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import QualitatioSelect from '../../../components/QualitatioSelect/QualitatioSelect';
import { useAuthStore } from '../../../store/auth.store';
import './CommentsAndChangesDrawer.css';

export default function CommentsAndChangesDrawer({
  open,
  onClose,
  cardTitle,
  creationDate,
  editors,
  creator,
  changeEvents,
  id,
  setChangeEvents,
  orderIdentifierMapping,
  restoreChange,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();

  const fieldOptions = [
    { value: 'title', label: t('title') },
    { value: 'testObject', label: t('testObject') },
    { value: 'testLocation', label: t('testLocation') },
    { value: 'testMethod', label: t('testMethod') },
    { value: 'testComment', label: t('testComment') },
    { value: 'orderIdentifier', label: t('orderIdentifier') },
  ];

  const [comments, setComments] = useState([]);

  const [fields, setFields] = useState([]);

  useEffect(() => {
    const initialComments = changeEvents
      .filter((ev) => ev.type === 'comment')
      .map((changeEvent) => ({ _id: changeEvent._id, text: changeEvent.comment.text }));

    setComments(initialComments);

    const initialFields = changeEvents
      .filter((ev) => ev.type === 'comment')
      .map((changeEvent) => ({ _id: changeEvent._id, field: changeEvent.comment.field }));

    setFields(initialFields);
  }, [changeEvents]);

  const processComment = async (commentId) => {
    try {
      const response = await axiosClient.post(`${cardCommentEndpoint}/${commentId}`, {
        id: id,
        creator: user.id,
        created: Date.now(),
      });
      if (response.data.changeEvents) {
        setChangeEvents(response.data.changeEvents.filter((ev) => !ev.type.startsWith('toDo')));
        enqueueSnackbar(t('successfullyProcessedComment'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenProcessingComment'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const editComment = async (commentId) => {
    try {
      const response = await axiosClient.patch(cardCommentEndpoint, {
        id: id,
        commentId: commentId,
        creator: user.id,
        created: Date.now(),
        text: comments.find((comment) => comment._id === commentId)?.text,
        field: fields.find((field) => field._id === commentId)?.field,
      });
      if (response.data.changeEvents) {
        setChangeEvents(response.data.changeEvents.filter((ev) => !ev.type.startsWith('toDo')));
        enqueueSnackbar(t('successfullyEditedComment'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenEditingComment'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('errorWhenEditingComment'), { variant: 'error' });
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const response = await axiosClient.delete(cardCommentEndpoint, {
        data: {
          id: id,
          commentId: commentId,
          creator: user.id,
          created: Date.now(),
        },
      });
      if (response.data.changeEvents) {
        setChangeEvents(response.data.changeEvents.filter((ev) => !ev.type.startsWith('toDo')));
        enqueueSnackbar(t('successfullyDeletedComment'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('errorWhenDeletingComment'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [imagesIndices, setImagesIndices] = useState({});

  useEffect(() => {
    const initialIndices = {};
    changeEvents.forEach((changeEvent) => {
      if (changeEvent.type === 'change' && changeEvent.change.field === 'images') {
        initialIndices[changeEvent._id] = { uploadedIndex: 0, deletedIndex: 0 };
      }
    });
    setImagesIndices(initialIndices);
  }, [changeEvents]);

  const updateImageIndex = (eventId, indexType, newIndex) => {
    setImagesIndices((prevIndices) => ({
      ...prevIndices,
      [eventId]: {
        ...prevIndices[eventId],
        [`${indexType}Index`]: newIndex,
      },
    }));
  };

  const getUploadedImages = (eventId) => {
    const event = changeEvents.find((event) => event._id === eventId);

    if (event && event.change.field === 'images') {
      const oldImages = event.change.oldValue.includes(',')
        ? event.change.oldValue.split(',')
        : [event.change.oldValue];
      const newImages = event.change.newValue.includes(',')
        ? event.change.newValue.split(',')
        : [event.change.newValue];

      return newImages.filter((image) => !oldImages.includes(image));
    }

    return [];
  };

  const getDeletedImages = (eventId) => {
    const event = changeEvents.find((event) => event._id === eventId);

    if (event && event.change.field === 'images') {
      const oldImages = event.change.oldValue.includes(',')
        ? event.change.oldValue.split(',')
        : [event.change.oldValue];
      const newImages = event.change.newValue.includes(',')
        ? event.change.newValue.split(',')
        : [event.change.newValue];

      return oldImages.filter((image) => !newImages.includes(image));
    }

    return [];
  };

  const renderChangeEvents = useMemo(() => {
    return changeEvents.map((changeEvent) => {
      if (changeEvent.type === 'comment' && !changeEvent.comment.processed) {
        return (
          <Paper
            elevation={2}
            className="paperWithStripe"
            style={{
              padding: '10px',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                display="flex"
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                padding=" 0 25px 25px 0"
              >
                <Grid item display="flex" direction="column">
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                  >
                    {new Date(changeEvent.created).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{
                      padding: '10px 0 0 10px',
                      color: theme.palette.primary.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('comment')}
                  </Typography>
                </Grid>
                <QualitatioAvatar
                  name={changeEvent.creator.name}
                  size={40}
                  profileImage={changeEvent.creator.profileImage}
                  creatorId={changeEvent.creator.creatorId}
                />
              </Grid>
              <Grid
                item
                display="flex"
                direction="row"
                alignItems="center"
                width="100%"
                style={{ padding: '0 25px 0 10px' }}
              >
                <QualitatioInput
                  label={t('comment')}
                  value={comments.find((comment) => comment._id === changeEvent._id)?.text}
                  onChange={(event) =>
                    setComments((prev) =>
                      prev.map((comment) =>
                        comment._id === changeEvent._id
                          ? { ...comment, text: event.target.value }
                          : comment
                      )
                    )
                  }
                  width={'100%'}
                  compact={true}
                  readOnly={user.id !== changeEvent.creator.creatorId}
                />
                <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
                  {`${t('for')}`}
                </Typography>
                <QualitatioSelect
                  value={fields.find((field) => field._id === changeEvent._id)?.field}
                  onChange={(event) =>
                    setFields((prev) =>
                      prev.map((field) =>
                        field._id === changeEvent._id
                          ? { ...field, field: event.target.value }
                          : field
                      )
                    )
                  }
                  optionProps={fieldOptions.map((fieldOption) => (
                    <MenuItem value={fieldOption.value}>{fieldOption.label}</MenuItem>
                  ))}
                  width={'100%'}
                  style={{ margin: '0px' }}
                  compact={true}
                  readOnly={user.id !== changeEvent.creator.creatorId}
                />
              </Grid>
              <Grid
                item
                display="flex"
                direction="row"
                gap="1vw"
                justifyContent="right"
                width="100%"
                padding="0 25px 0 0"
              >
                {!changeEvent.comment.processed && user.id !== changeEvent.creator.creatorId && (
                  <Tooltip title={t('processComment')}>
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      style={{ backgroundColor: theme.palette.success.main, color: 'white' }}
                      onClick={() => processComment(changeEvent._id)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {user.id === changeEvent.creator.creatorId && (
                  <IconButton
                    variant="qualitatio"
                    squared={true}
                    style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
                    onClick={() => editComment(changeEvent._id)}
                  >
                    <SaveIcon />
                  </IconButton>
                )}
                {user.id === changeEvent.creator.creatorId && (
                  <IconButton
                    variant="qualitatio"
                    squared={true}
                    style={{ backgroundColor: theme.palette.error.main, color: 'white' }}
                    onClick={() => deleteComment(changeEvent._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        );
      } else if (changeEvent.type === 'change') {
        if (changeEvent.change.field !== 'images') {
          return (
            <Paper
              elevation={2}
              className="paperWithStripe"
              style={{
                padding: '10px',
                borderRadius: '10px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  display="flex"
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  padding=" 0 25px 25px 0"
                >
                  <Grid item display="flex" direction="column">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                    >
                      {new Date(changeEvent.created).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        padding: '10px 0 0 10px',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                      }}
                    >
                      {`${t('change')} ${t(changeEvent.change.field)}`}
                    </Typography>
                  </Grid>
                  <QualitatioAvatar
                    name={changeEvent.creator.name}
                    size={40}
                    profileImage={changeEvent.creator.profileImage}
                    creatorId={changeEvent.creator.creatorId}
                  />
                </Grid>
                <Grid item display="flex" direction="row" style={{ padding: '0 25px 0 10px' }}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{
                      fontStyle: 'italic',
                      wordWrap: 'break-word', // Break words that overflow the container's width
                      overflowWrap: 'break-word', // Allows unbreakable words to be broken
                      maxWidth: '300px',
                    }}
                  >
                    {changeEvent.change.field !== 'orderIdentifier' ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{
                            textDecoration: 'line-through',
                            color: theme.palette.error.main,
                          }}
                        >
                          {changeEvent.change.oldValue}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{ color: theme.palette.success.main }}
                        >
                          {changeEvent.change.newValue}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{
                            textDecoration: 'line-through',
                            color: theme.palette.error.main,
                          }}
                        >
                          {
                            orderIdentifierMapping.find(
                              (e) => e.orderIdentifier === Number(changeEvent.change.oldValue)
                            )?.notation
                          }
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{ color: theme.palette.success.main }}
                        >
                          {
                            orderIdentifierMapping.find(
                              (e) => e.orderIdentifier === Number(changeEvent.change.newValue)
                            )?.notation
                          }
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="right"
                  direction="row"
                  gap="1vh"
                  width="100%"
                  style={{ padding: '0 25px 0 0' }}
                >
                  <Tooltip title={t('restoreChanges')}>
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
                      onClick={() => restoreChange(id, changeEvent._id, onClose)}
                    >
                      <RestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          );
        } else {
          const uploadedImages = getUploadedImages(changeEvent._id);
          const deletedImages = getDeletedImages(changeEvent._id);

          return (
            <Paper
              elevation={2}
              className="paperWithStripe"
              style={{
                padding: '10px',
                borderRadius: '10px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                container
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  display="flex"
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  padding=" 0 25px 25px 0"
                >
                  <Grid item display="flex" direction="column">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                    >
                      {new Date(changeEvent.created).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        padding: '10px 0 0 10px',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                      }}
                    >
                      {`${t('change')} ${t(changeEvent.change.field)}`}
                    </Typography>
                  </Grid>
                  <QualitatioAvatar
                    name={changeEvent.creator.name}
                    size={40}
                    profileImage={changeEvent.creator.profileImage}
                    creatorId={changeEvent.creator.creatorId}
                  />
                </Grid>
                {uploadedImages.filter((image) => image.trim().length > 0).length > 0 && (
                  <Grid item display="flex" direction="row" gap="1vh" alignItems="center">
                    <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
                      {`${t('uploaded')}: `}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        updateImageIndex(
                          changeEvent._id,
                          'uploaded',
                          imagesIndices[changeEvent._id]?.uploadedIndex - 1
                        )
                      }
                      disabled={imagesIndices[changeEvent._id]?.uploadedIndex === 0}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <div
                      style={{
                        minHeight: '100px',
                        minWidth: '100px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundImage: `url(${encodeURI(uploadedImages[imagesIndices[changeEvent._id]?.uploadedIndex])})`,
                      }}
                    />
                    <IconButton
                      onClick={() =>
                        updateImageIndex(
                          changeEvent._id,
                          'uploaded',
                          imagesIndices[changeEvent._id]?.uploadedIndex + 1
                        )
                      }
                      disabled={
                        imagesIndices[changeEvent._id]?.uploadedIndex === uploadedImages.length - 1
                      }
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Grid>
                )}
                {deletedImages.filter((image) => image.trim().length > 0).length > 0 && (
                  <Grid item display="flex" direction="row" gap="1vh" alignItems="center">
                    <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
                      {`${t('deleted')}: `}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        updateImageIndex(
                          changeEvent._id,
                          'deleted',
                          imagesIndices[changeEvent._id]?.deletedIndex - 1
                        )
                      }
                      disabled={imagesIndices[changeEvent._id]?.deletedIndex === 0}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <div
                      style={{
                        minHeight: '100px',
                        minWidth: '100px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundImage: `url(${encodeURI(deletedImages[imagesIndices[changeEvent._id]?.deletedIndex])})`,
                      }}
                    />
                    <IconButton
                      onClick={() =>
                        updateImageIndex(
                          changeEvent._id,
                          'deleted',
                          imagesIndices[changeEvent._id]?.deletedIndex + 1
                        )
                      }
                      disabled={
                        imagesIndices[changeEvent._id]?.deletedIndex === deletedImages.length - 1
                      }
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid
                  item
                  display="flex"
                  justifyContent="right"
                  direction="row"
                  gap="1vh"
                  width="100%"
                  style={{ padding: '0 25px 0 0' }}
                >
                  <Tooltip title={t('restoreChanges')}>
                    <IconButton
                      variant="qualitatio"
                      squared={true}
                      style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
                      onClick={() => restoreChange(id, changeEvent._id, onClose)}
                    >
                      <RestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      } else if (changeEvent.type !== 'change' && changeEvent.type !== 'comment') {
        return (
          <Paper
            elevation={2}
            className="paperWithStripe"
            style={{
              padding: '10px',
              borderRadius: '10px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              container
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                display="flex"
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                padding=" 0 25px 25px 0"
              >
                <Grid item display="flex" direction="column">
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ padding: '0 0 0 10px', fontStyle: 'italic' }}
                  >
                    {new Date(changeEvent.created).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{
                      padding: '10px 0 0 10px',
                      color: theme.palette.primary.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {t(changeEvent.type)}
                  </Typography>
                </Grid>
                <QualitatioAvatar
                  name={changeEvent.creator.name}
                  size={40}
                  profileImage={changeEvent.creator.profileImage}
                  creatorId={changeEvent.creator.creatorId}
                />
              </Grid>
            </Grid>
          </Paper>
        );
      }
    });
  }, [changeEvents, imagesIndices, comments, fields]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid
        container
        display="flex"
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: '10px' }}
      >
        <Grid item display="flex">
          <Typography variant="h4" component="div" style={{ padding: '10px', fontWeight: 'bold' }}>
            {`${t('changeLogsOf')} ${cardTitle}`}
          </Typography>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
            {`${t('createdAt')} ${new Date(creationDate).toLocaleString()} ${t('by')}: `}
          </Typography>
          <Tooltip title={creator.name}>
            <Avatar
              alt={creator.name}
              src={filesRoot + '/users/profileImages/' + creator.creatorId + '/profileImage.png'}
              sx={{ width: 24, height: 24 }}
            />
          </Tooltip>
        </Grid>
        <Grid item display="flex" alignItems="center" direction="row">
          <Typography variant="subtitle1" component="div" style={{ padding: '10px' }}>
            {`${t('editedBy')}: `}
          </Typography>
          {editors?.map((editor) => (
            <Typography variant="subtitle1" component="div" style={{ padding: '0 2px 0 2px' }}>
              <Tooltip title={editor.name}>
                <Avatar
                  alt={editor.name}
                  src={filesRoot + '/users/profileImages/' + editor.editorId + '/profileImage.png'}
                  sx={{ width: 24, height: 24 }}
                />
              </Tooltip>
            </Typography>
          ))}
        </Grid>
        <Grid item display="flex" direction="column" gap="1vh">
          {renderChangeEvents}
        </Grid>
      </Grid>
    </Drawer>
  );
}
