import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import QualitatioTable from '../../../../components/QualitatioTable/QualitatioTable';
import styles from './EventTests.module.css';

//Please dont judge me for all the useEffects I just reworked the code and I am not repsonsible for the useEffects I just kept them as they were
const EventTable = ({ tableState, tableData }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const columnsEventForQualitatio = [
    { field: 'eventStreamName', headerName: t('streamName') },
    ...tableData.selectedLiveStreamData.reduce(
      (acc, { streamName, fieldNames, filterFieldNames }) => {
        return [
          ...acc,
          ...fieldNames.map((fieldName) => ({
            field: fieldName,
            headerName: fieldName,
            flex: 1,
            minWidth: 100,
          })),
          ...filterFieldNames?.map((fieldName) => ({
            field: fieldName,
            headerName: fieldName,
            flex: 1,
            minWidth: 100,
          })),
        ];
      },
      []
    ),
    { field: 'eventFrequency', headerName: t('frequency') },
  ];

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = tableState.eventTests.find((el) => el.id === id);
      if (card) {
        const index = tableData.eventDataWithId.findIndex((el) => {
          for (let key in el) {
            if (
              ![
                'eventImageField',
                'eventFrequency',
                'isEvent',
                '_id',
                'id',
              ].includes(key)
            ) {
              if (card.dynamic[key] !== el[key]) {
                return false;
              }
            }
          }
          return true;
        });
        const cardIndex = tableState.eventTests
          .filter((el) => el.explanation === card.explanation)
          .indexOf(card);
        if (index !== -1 && cardIndex !== -1) {
          tableState.setCurrentIndex(index);
          tableState.setCurrentCardIndex(cardIndex);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [tableState.eventTests, tableData.eventDataWithId]);

  useEffect(() => {
    let event = {};
    columnsEventForQualitatio.forEach((column, index) => {
      if (
        column.field !== 'eventStreamName' &&
        column.field !== 'eventFrequency'
      ) {
        event[column.field] =
          tableData.filteredEventData[tableState.currentIndex] &&
          tableData.filteredEventData[tableState.currentIndex][index];
      }
    });
    tableState.setSelectedEvent(event);
  }, [tableState.currentIndex]);

  useEffect(() => {
    if (tableData.eventDataWithId[tableState.currentIndex]) {
      const event = {};
      columnsEventForQualitatio.forEach((column, index) => {
        if (
          column.field !== 'eventStreamName' &&
          column.field !== 'eventFrequency'
        ) {
          event[column.field] =
            tableData.eventDataWithId[tableState.currentIndex] &&
            tableData.eventDataWithId[tableState.currentIndex][index];
        }
      });
      tableState.setSelectedEvent(event); // Assuming setSelectedEvent is managed in the parent component
    }
  }, [
    tableState.currentIndex,
    tableData.eventDataWithId,
    columnsEventForQualitatio,
  ]);

  useEffect(() => {
    if (
      tableData.filteredEventData.length > 0 &&
      tableData.filteredEventData[0] !== null
    ) {
      const eventDataObjects = tableData.filteredEventData?.map(
        (eventArray, index) => {
          const eventDataObject = { id: index };
          eventDataObject.eventStreamName = eventArray[0];
          tableData.selectedLiveStreamData.forEach((streamData) => {
            if (streamData.streamName === eventArray[0]) {
              streamData.fieldNames.forEach((fieldName, fieldIndex) => {
                eventDataObject[fieldName] = eventArray[fieldIndex + 1];
              });
              streamData.filterFieldNames.forEach((filterName, filterIndex) => {
                eventDataObject[filterName] =
                  eventArray[streamData.fieldNames.length + filterIndex + 1];
              });
            }
          });
          eventDataObject.eventFrequency = Number(
            eventArray[eventArray.length - 1]
          );
          return eventDataObject;
        }
      );
      tableState.setEventDataWithId(eventDataObjects);
    } else {
      tableState.setEventDataWithId([]);
    }
  }, [tableData.filteredEventData, tableData.selectedLiveStreamData]);

  return (
    <div className={styles.eventsTableWrapper}>
      {tableData.eventDataWithId?.length === 0 ? (
        <div className={styles.noData}>{t('noEventsAvailable')}</div>
      ) : (
        <QualitatioTable
          rows={tableData.eventDataWithId}
          columns={columnsEventForQualitatio}
          pageSize={10}
          pageSizeOptions={[10]}
          height={'100%'}
          setCurrentIndex={tableState.setCurrentIndex}
          setCurrentCardIndex={tableState.setCurrentCardIndex}
          openDiscardWarning={
            !_.isEqual(tableState.eventTestsGroundTruth, tableState.eventTests)
          }
          setDiscardWarningOpen={(index) =>
            tableState.setDiscardChangesWarningOpen(index)
          }
          currentIndex={tableState.currentIndex}
        />
      )}
    </div>
  );
};

const tableStatePropTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  eventTestsGroundTruth: PropTypes.array.isRequired,
  eventTests: PropTypes.array.isRequired,
  setDiscardChangesWarningOpen: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired,
  setEventDataWithId: PropTypes.func.isRequired,
};

const tableDataPropTypes = {
  selectedLiveStreamData: PropTypes.array.isRequired,
  filteredEventData: PropTypes.array.isRequired,
  eventDataWithId: PropTypes.array.isRequired,
};

EventTable.propTypes = {
  tableState: PropTypes.shape(tableStatePropTypes).isRequired,
  tableData: PropTypes.shape(tableDataPropTypes).isRequired,
};

export default EventTable;
