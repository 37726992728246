import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const ConnectionDetailsOverlay = ({ open, onClose, connectionType, connectionDetails }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Assuming you're using notistack for snackbars

    console.log("connectionDetails: ", connectionDetails)
    console.log("connectionType: ", connectionType)

    const { t } = useTranslation();;

    const renderDetails = () => {
        switch (connectionType) {
            case "kafka":
                return (
                    <Grid container spacing={2}>
                        {[
                            [t("topic"), connectionDetails["topic"]],
                            [t("format"), connectionDetails["format"]],
                            ["Bootstrap Server Port", connectionDetails["bootstrapServerPort"]],
                            ["Bootstrap Server IP Address", connectionDetails["bootstrapServerIPAddress"]],
                            [t("updateFrequency"), (connectionDetails["updateFrequency"] + "s")]
                        ].map(([label, value]) => (
                            <React.Fragment key={label}>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{label}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{value}</Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                );
            case "api":
                return (
                    <div>
                        <p>{t("apiURL")}: {connectionDetails["url"]}</p>
                    </div>
                );
            default:
                return (
                    <div>
                        <p>{t("unknownConnectionType")}</p>
                    </div>
                );
        }
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            variant="qualitatio"
        >
            <DialogTitle>{t("connectionDetails")}</DialogTitle>
            <DialogContent>
                {renderDetails()}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="qualitatio" color="secondary">
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConnectionDetailsOverlay;
