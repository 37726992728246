import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioButton from '../../../../components/QualitatioButton/QualitatioButton';
import QualitatioDateRangePicker from '../../../../components/QualitatioDateRangePicker/QualitatioDateRangePicker';
import styles from './EventTests.module.css';

const eventStatePropTypes = {
  filterOption: PropTypes.string.isRequired,
  setFilterOption: PropTypes.func.isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
  setStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.instanceOf(Date).isRequired,
  setEndTime: PropTypes.func.isRequired,
  eventTestsGroundTruth: PropTypes.array.isRequired,
  eventTests: PropTypes.array.isRequired,
  updateEventTestsData: PropTypes.func.isRequired,
  setDiscardWarningOpen: PropTypes.func.isRequired,
};

const eventDataPropTypes = {
  events: PropTypes.array.isRequired,
  setFilteredEventData: PropTypes.func.isRequired,
  selectedLiveStreamData: PropTypes.array.isRequired,
};

const EventHeader = ({ eventState, eventData }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [animateUpdateButton, setAnimateUpdateButton] = React.useState(false);

  const handleAnimation = () => {
    setAnimateUpdateButton(true);
    setTimeout(() => setAnimateUpdateButton(false), 2000);
  };

  const handleFilterChange = (event, newFilterOption) => {
    if (newFilterOption !== null) {
      eventState.setFilterOption(newFilterOption);

      let newFilteredData = eventData.events;

      if (newFilterOption === 'unlabeled') {
        newFilteredData = eventData.events.filter((event) => {
          const streamData = eventData.selectedLiveStreamData.find(
            (s) => s.streamName === event[0]
          );
          if (!streamData) return true;
          const filterFieldStartIndex = 1 + streamData.fieldNames.length;
          const eventFieldNames = event.slice(1, filterFieldStartIndex).join(' - ');
          const eventFieldNamesParts = new Set(eventFieldNames.split(' - '));
          return !eventState.eventTests.some((test) => {
            const testExplanationParts = new Set(test.explanation.split(' - '));
            const isSubsetOrMatch =
              [...testExplanationParts].every((part) => eventFieldNamesParts.has(part)) ||
              test.explanation === eventFieldNames;
            return isSubsetOrMatch;
          });
        });
      } else if (newFilterOption === 'labeled') {
        newFilteredData = eventData.events.filter((event) => {
          const streamData = eventData.selectedLiveStreamData.find(
            (s) => s.streamName === event[0]
          );
          if (!streamData) return false;
          const filterFieldStartIndex = 1 + streamData.fieldNames.length;
          const eventFieldNames = event.slice(1, filterFieldStartIndex).join(' - ');
          const eventFieldNamesParts = new Set(eventFieldNames.split(' - '));
          return eventState.eventTests.some((test) => {
            const testExplanationParts = new Set(test.explanation.split(' - '));
            const isSubsetOrMatch =
              [...testExplanationParts].every((part) => eventFieldNamesParts.has(part)) ||
              test.explanation === eventFieldNames;
            return isSubsetOrMatch;
          });
        });
      }

      eventData.setFilteredEventData(newFilteredData);
    }
  };

  const handleDateRangeChange = (newValue) => {
    handleAnimation();
    const [start, end] = newValue;
    eventState.setStartTime(start);
    eventState.setEndTime(end);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.eventsHeaderWrapper}>
        <ToggleButtonGroup
          variant="qualitatio"
          exclusive
          value={eventState.filterOption}
          onChange={handleFilterChange}
          style={{ height: '100%' }}
        >
          <Tooltip title={t('showAllEvents')}>
            <ToggleButton variant="qualitatio" value="all">
              {t('all')}
            </ToggleButton>
          </Tooltip>
          <Tooltip title={t('showOnlyLabeledEvents')}>
            <ToggleButton variant="qualitatio" value="labeled">
              {t('labeled')}
            </ToggleButton>
          </Tooltip>
          <Tooltip title={t('showOnlyUnlabeledEvents')}>
            <ToggleButton variant="qualitatio" value="unlabeled">
              {t('unlabeled')}
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <div className={styles.dateTimePickers}>
          <QualitatioDateRangePicker
            startText={t('selectStartTime')}
            endText={t('selectEndTime')}
            value={[dayjs(eventState.startTime), dayjs(eventState.endTime)]}
            onChange={handleDateRangeChange}
          />
        </div>
        <QualitatioButton
          text={t('update')}
          order="primary"
          disabled={!eventState.startTime || !eventState.endTime}
          onClick={() =>
            _.isEqual(eventState.eventTestsGroundTruth, eventState.eventTests)
              ? eventState.updateEventTestsData()
              : eventState.setDiscardWarningOpen(true)
          }
          className={animateUpdateButton ? 'animate' : ''}
          width={'12%'}
          minWidth={'140px'}
          height={'100%'}
          fontSize={'calc(12px + 0.25vw)'}
          margin={'0 0 0 3px'}
        />
      </div>
    </LocalizationProvider>
  );
};

EventHeader.propTypes = {
  eventState: PropTypes.shape(eventStatePropTypes).isRequired,
  eventData: PropTypes.shape(eventDataPropTypes).isRequired,
};

export default EventHeader;
