import PropTypes from 'prop-types';
import React from 'react';
import QualitatioCamera from '../../../../../components/QualitatioCamera';
import { triggerAIProcessing } from '../utils';

export const ImageInput = ({ handleProcessing, handleResponse }) => {
  return (
    <div className="camera-wrapper">
      <QualitatioCamera
        onTakePhoto={async (dataUri) => {
          handleProcessing();

          const blob = await fetch(dataUri).then((r) => r.blob());
          triggerAIProcessing(blob, false).then((response) => {
            handleResponse(response);
          });
        }}
      />
    </div>
  );
};

ImageInput.propTypes = {
  handleProcessing: PropTypes.func.isRequired,
  handleResponse: PropTypes.func.isRequired,
};

export default ImageInput;
