import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { QualitatioLanguageSelector } from '../components';
import QualitatioButton from '../components/QualitatioButton/QualitatioButton';
import QualitatioDropdown from '../components/QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from '../components/QualitatioInput/QualitatioInput';
import { useRedirectIfAuthenticated } from '../components/customHooks ';
import { LANG } from '../language_settings/languageConst';
import { useAuthStore } from '../store/auth.store';
import { getRedirectPath } from '../utils/accessUtils';
import { capitalizeFirstLetter } from '../utils/utilities';
import { addUserValidationSchema } from '../utils/validationSchemas';
import './RegisterPage.css';
import ROLES from './roleConst';
const { useFormik } = require('formik');

function RegisterPage({ setPageName }) {
  const { t, i18n } = useTranslation();
  setPageName(t('register'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { register, isAuthenticated, user } = useAuthStore((state) => ({
    register: state.register,
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  }));
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      role: ROLES.IT_USER,
      language: 'en',
    },
    validationSchema: addUserValidationSchema(i18n.language),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useRedirectIfAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      // if user has role user, redirect to recommender
      navigate(getRedirectPath(user));
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (values) => {
    try {
      const user = await register(values);
      if (user) {
        enqueueSnackbar(t('registerSuccessSnack') || 'Registered successfully', {
          variant: 'success',
        });
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || error.message;
      enqueueSnackbar(t(errorMessage) || 'Something went wrong!', {
        variant: 'error',
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <div className="register-page">
      <div className="frame">
        <div className="div-wrapper">
          <div className="text-wrapper-2">{t('signUp')}</div>
        </div>
        <div className="frame-2">
          <div className="frame-3">
            <QualitatioInput
              {...formik.getFieldProps('name')}
              label={t('name')}
              type="text"
              placeholder={t('name')}
              size="lg"
              width={'100%'}
              onKeyDown={handleKeyDown}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <QualitatioInput
              {...formik.getFieldProps('email')}
              label={t('email')}
              type="email"
              placeholder="you@mail.com"
              size="lg"
              width={'100%'}
              onKeyDown={handleKeyDown}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <QualitatioInput
              {...formik.getFieldProps('password')}
              label={t('password')}
              type="password"
              placeholder="*******"
              size="lg"
              width={'100%'}
              showInput={showPassword}
              toggleShowInput={() => setShowPassword(!showPassword)}
              onKeyDown={handleKeyDown}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <QualitatioDropdown
              {...formik.getFieldProps('role')}
              label={t('role')}
              options={Object.values(ROLES)}
              getOptionLabel={(option) => capitalizeFirstLetter(option)}
              size="lg"
              onChange={(event, newValue) =>
                formik.setFieldValue('role', newValue || ROLES.IT_USER)
              }
              width={'100%'}
              onKeyDown={handleKeyDown}
              disableClearable
            />
            <QualitatioLanguageSelector
              {...formik.getFieldProps('language')}
              onChange={(event, newValue) => formik.setFieldValue('language', newValue || LANG.EN)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="frame-6">
          <div className="buttons">
            <QualitatioButton
              text={t('signUp')}
              order="primary"
              onClick={formik.handleSubmit}
              width={'100%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              endIcon={<ArrowForwardIosRoundedIcon />}
            />
            <QualitatioButton
              text={t('cancel')}
              order="secondary"
              onClick={() => navigate('/welcome')}
              width={'100%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            />
          </div>
          <div className="schon-konto">
            <p className="sie-haben-schon-konto">
              <span className="span">{t('alreadyAccount')}</span>
              <Link to="/login" className="link">
                <span className="text-wrapper-6">{t('loginHere')}</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
