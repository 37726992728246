import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { outputStreamSubscriptionEndpoint } from '../../../api/endpoints';
import QualitatioMultiStepForm from '../../../components/QualitatioMultiStepForm/QualitatioMultiStepForm';
import { useAuthStore } from '../../../store/auth.store';
import { getSubscriptionValidationSchema } from '../../../utils/validationSchemas';
import KafkaSubscriptionForm from './KafkaSubscriptionForm';
import SubscriptionSummary from './SubscriptionSummary';

//TODO: improvements:
// - only make the test needed when the form has been changed since the last test (now it is always needed when the user clicks back form page 3)
export default function SubscriptionSetup({
  onSubscriptionSetupClose,
  setOutputStreamSubscriptions,
  availableOutputStreams,
  reload,
}) {
  /** AUTHENTICATION */
  const { user } = useAuthStore((state) => ({ user: state.user }));

  /* FORM HELPERS */
  const numPages = 4;
  const [progress, setProgress] = useState(1);
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);
  const [testConnectionSuccess, setTestConnectionSuccess] = useState(false);

  /* GENEREL FORM INPUTS */
  const [outputStreamSubscription, setOutputStreamSubscription] = useState({});
  const { t, i18n } = useTranslation();
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      stream: '',
      type: 'kafka',
      active: true,
      connectionDetails: {
        topic: '',
        format: 'json',
        updateFrequency: 15,
      },
    },
    validationSchema: getSubscriptionValidationSchema(progress, i18n.language),
    onSubmit: (values) => {
      testSubscription(values);
    },
  });

  /** OTHER VARIABLES */
  const { enqueueSnackbar } = useSnackbar();

  const resetForm = () => {
    // Set formik to initial values
    formik.setValues({
      name: '',
      stream: '',
      type: 'kafka',
      active: true,
      connectionDetails: {
        topic: '',
        format: 'json',
        updateFrequency: 15,
      },
    });
    // Reset formik errors
    formik.setErrors({});
    // Reset formik touched
    formik.setTouched({});
    // Reset formik isSubmitting
    formik.setSubmitting(false);
    // Reset progress
    setProgress(1);
    // Reset test connection success
    setTestConnectionSuccess(false);
  };

  /** TEST CONNECTION */
  const testSubscription = async (values) => {
    try {
      setTestConnectionLoading(true);
      const response = await axiosClient.post(outputStreamSubscriptionEndpoint + 'test', {
        name: values.name,
        creatorName: user.name,
        stream: values.stream,
        type: values.type,
        active: values.active,
        connectionDetails: {
          topic: values.connectionDetails.topic,
          format: values.connectionDetails.format,
          updateFrequency: values.connectionDetails.updateFrequency,
        },
      });

      if (response.data.success) {
        enqueueSnackbar(t('subscriptionSuccessfullyEstablished'), { variant: 'success' });
        setTestConnectionLoading(false);
        setTestConnectionSuccess(true);
        setOutputStreamSubscription(response.data.outputStreamSubscription);
        setProgress((prevStep) => prevStep + 1);
      } else {
        enqueueSnackbar(t('subscriptionCouldNotBeEstablishedOrNoDataCouldBeFetched'), {
          variant: 'error',
        });
        setTestConnectionLoading(false);
      }
    } catch (error) {
      setTestConnectionLoading(false);
      console.error('Error:', error);
    }
  };

  /** SAVE INPUT STREAM */
  const saveSubscription = async () => {
    try {
      const response = await axiosClient.post(outputStreamSubscriptionEndpoint, {
        outputStreamSubscription: outputStreamSubscription,
      });
      if (response.data.outputStreamSubscriptions) {
        enqueueSnackbar(t('subscriptionSuccessfullySaved'), { variant: 'success' });
        setOutputStreamSubscriptions(response.data.outputStreamSubscriptions);
        formik.resetForm();
        setProgress(1);
        reload();
        onSubscriptionSetupClose();
        resetForm();
      } else {
        enqueueSnackbar(t('subscriptionCouldNotBeSaved'), { variant: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const nextIsDisabled = progress === 3 && !testConnectionSuccess;

  const backButtonAction = () => {
    if (progress !== 1) {
      if (progress === 3) {
        setTestConnectionSuccess(false);
      }
      setProgress(progress - 1);
    } else {
      setCancelDialogOpen(true);
    }
  };

  const forwardButtonAction = async () => {
    if (progress !== numPages) {
      if (progress === 1) {
        formik.setTouched({
          name: true,
          stream: true,
          type: true,
        });
      }
      if (progress === 2) {
        formik.setTouched({
          connectionDetails: {
            topic: true,
            format: true,
            updateFrequency: true,
          },
        });
      }
      // Manually trigger validation
      const errors = await formik.validateForm();
      // Check if errors object is empty, indicating successful validation
      if (
        (Object.keys(errors).length === 0 && errors.constructor === Object) ||
        (progress === 3 && testConnectionSuccess)
      ) {
        setProgress(progress + 1);
      }
    } else {
      saveSubscription();
    }
  };

  const cancelForm = () => {
    setCancelDialogOpen(false);
    formik.resetForm();
    onSubscriptionSetupClose();
  };

  return (
    <QualitatioMultiStepForm
      progress={progress}
      numPages={numPages}
      backButtonAction={backButtonAction}
      forwardButtonAction={forwardButtonAction}
      nextIsDisabled={nextIsDisabled}
      cancelForm={cancelForm}
      formTitle={t('setupNewOutputStreamSubscription')}
      cancelDialogOpen={cancelDialogOpen}
      setCancelDialogOpen={setCancelDialogOpen}
    >
      {progress === 1 && (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} justifyContent="center">
            <TextField
              {...formik.getFieldProps('name')}
              label={t('name')}
              placeholder={t('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CreateOutlinedIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} justifyContent="center">
            <FormControl required fullWidth>
              <Autocomplete
                {...formik.getFieldProps('stream')}
                value={availableOutputStreams.find(
                  (stream) => stream?.name === formik.values.stream
                )}
                onChange={(event, newValue) => {
                  formik.setFieldValue('stream', newValue?.name);
                }}
                options={availableOutputStreams}
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                placeholder={t('availableOutputStreams')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('availableOutputStreams')}
                    error={formik.touched.stream && Boolean(formik.errors.stream)}
                    helperText={formik.touched.stream && formik.errors.stream}
                    required
                    fullWidth
                  />
                )}
                disableClearable
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} justifyContent="center">
            <FormControl required fullWidth>
              <Autocomplete
                {...formik.getFieldProps('type')}
                options={['kafka', 'api']}
                onChange={(event, newValue) => {
                  formik.setFieldValue('type', newValue);
                }}
                getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
                placeholder={t('type')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('type')}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    required
                    fullWidth
                  />
                )}
                disableClearable
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      {progress === 2 && formik.values.type === 'kafka' && (
        <KafkaSubscriptionForm formik={formik} />
      )}

      {progress === 3 && (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {testConnectionLoading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={80} />
                <Typography variant="body1" marginTop="2%">
                  {t('testingSubscriptionConnection')}
                </Typography>
              </div>
            )}
            {testConnectionSuccess && (
              <Typography variant="body1" marginTop="15%">
                {t(subscriptionSuccessfullyEstablished)}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="qualitatio"
              size="large"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={testConnectionLoading}
            >
              {testConnectionSuccess
                ? t('testSubscriptionConnectionAgain')
                : t('testSubscriptionConnection')}
            </Button>
          </Grid>
        </Grid>
      )}

      {progress === 4 && (
        <SubscriptionSummary
          formik={formik}
          readonly={true}
          availableOutputStreams={availableOutputStreams}
        />
      )}
    </QualitatioMultiStepForm>
  );
}
