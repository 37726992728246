import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import {
  activeAuthenticationSystemsEndpoint,
  oauthSettingsEndpoint,
  toggleAuthSystemActivationEndpoint,
} from '../../../api/endpoints';
import { oauthIconMapping } from '../../../utils/utilities';
import styles from './OAuthSettings.module.css';
import OAuthProviderDialog from './OauthSettingsDialog';

function OAuthSettings() {
  const [oauthSettings, setOauthSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [secretChanged, setSecretChanged] = useState(false);
  const [active, setActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const settings = await axiosClient.get(oauthSettingsEndpoint);
        const activeAuthSystems = await axiosClient.get(activeAuthenticationSystemsEndpoint);
        setActive(activeAuthSystems.data.includes('oauth'));
        setOauthSettings(settings.data);
      } catch (error) {
        // enqueueSnackbar(t('oauth.failedToFetchSettingsSnack'), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDialogOpen = (provider = null) => {
    setCurrentProvider(provider);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentProvider(null);
  };

  const handleToggleOAuthActive = async () => {
    try {
      await axiosClient.post(toggleAuthSystemActivationEndpoint, { authSystem: 'oauth' });
      setActive(!active);
      enqueueSnackbar(t('oauth.authSystemToggledSnack'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('oauth.failedToToggleAuthSystemSnack'), { variant: 'error' });
    }
  };

  const handleSaveProvider = async (providerData, isPatch) => {
    try {
      // Clone providerData to avoid mutating the original object
      let dataToSubmit = { ...providerData };

      if (!secretChanged) {
        delete dataToSubmit.clientSecret;
      }

      let updatedProvider;
      if (isPatch) {
        updatedProvider = await axiosClient.patch(oauthSettingsEndpoint, dataToSubmit);
        setOauthSettings(
          oauthSettings?.map((p) => (p.id === updatedProvider.data.id ? updatedProvider.data : p))
        );
      } else {
        updatedProvider = await axiosClient.post(oauthSettingsEndpoint, dataToSubmit);
        setOauthSettings([...oauthSettings, updatedProvider.data]);
      }

      enqueueSnackbar(t('oauth.providerSavedSnack', { provider: updatedProvider.providerName }), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(
        t('oauth.failedToSaveProviderSnack', { provider: providerData.providerName }),
        { variant: 'error' }
      );
    }
  };

  const handleDeleteProvider = async (providerData) => {
    try {
      await axiosClient.delete(oauthSettingsEndpoint, {
        params: { providerName: providerData.providerName },
      });
      setOauthSettings(oauthSettings.filter((p) => p.id !== providerData.id));
      enqueueSnackbar(t('oauth.providerDeletedSnack', { provider: providerData.providerName }), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(
        t('oauth.failedToDeleteProviderSnack', { provider: providerData.providerName }),
        { variant: 'error' }
      );
    }
  };

  const activeToggle = async (provider) => {
    const updatedProvider = { ...provider, active: !provider.active };
    try {
      await axiosClient.patch(oauthSettingsEndpoint, updatedProvider);
      setOauthSettings(oauthSettings?.map((p) => (p.id === provider.id ? updatedProvider : p)));
      enqueueSnackbar(
        t(`oauth.providerActiveToggleSnack`, {
          provider: updatedProvider.providerName,
          activeStatus: updatedProvider.active,
        }),
        {
          variant: 'success',
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t('oauth.failedToToggleProviderActiveSnack', {
          provider: updatedProvider.providerName,
          activeStatus: updatedProvider.active ? 'enable' : 'disable',
        }),
        { variant: 'error' }
      );
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        className={styles.container}
      >
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Switch onChange={handleToggleOAuthActive} checked={active} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem divider />
            {oauthSettings?.map((provider) => (
              <ListItem key={provider.id} divider className={styles.listItem}>
                <ListItemIcon>
                  {oauthIconMapping[provider.providerName] ? (
                    React.createElement(oauthIconMapping[provider.providerName])
                  ) : (
                    <LockIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={provider.providerName}
                  secondary={t('oauth.activeStatus', {
                    activeStatus: provider.active,
                  })}
                  className={styles.listItemText}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={() => activeToggle(provider)}
                    checked={provider.active}
                  />
                  <IconButton edge="end" onClick={() => handleDialogOpen(provider)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDeleteProvider(provider)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="qualitatio"
              onClick={() => handleDialogOpen()}
              startIcon={<AddIcon />}
              className={mobile ? styles.addButtonMobile : styles.addButton}
            >
              {t('oauth.addProvider')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <OAuthProviderDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        provider={currentProvider}
        onSave={handleSaveProvider}
        onSecretChange={(newSecret) => {
          setSecretChanged(!!newSecret);
        }}
      />
    </>
  );
}

export default OAuthSettings;
