import { useCallback } from 'react';
import Particles from "react-particles";
import { Outlet } from 'react-router-dom';
import { loadFull } from "tsparticles";
import './MainLayout.css';

//maybe use this instead of particles and same structure for all auth pages

function MainLayout() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    return (
        <div className="main-layout">
            <div className='particles' />
            <Particles id="tsparticles" url="/particlesLoginScreens.json" init={particlesInit} loaded={particlesLoaded} />
            <div className="frame">
                <Outlet />
            </div>
        </div>
    );
}

export default MainLayout;