import { styled } from '@mui/system';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import './QualitatioToggleGroup.css';  // If you have specific CSS here

// Style the ToggleButtonGroup itself
const QualitatioToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // Add styles for the group here if needed
}));

// Style individual ToggleButtons within the group
export const QualitatioToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: 'var(--m-3refprimaryprimary-70)',  // Default color
  width: '100%',
  
  '&.Mui-selected': {
    color: 'var(--m-3refprimaryprimary-70)',  // Selected color
    backgroundColor: 'rgba(31, 190, 188, 0.1)',  // Background for selected toggle button
  },

  '&.Mui-disabled': {
    color: 'rgba(25, 28, 28, 1)',  // Disabled color
  },

  '&:hover': {
    backgroundColor: 'rgba(31, 190, 188, 0.1)',  // Hover background
    '&.Mui-selected': {
      backgroundColor: 'rgba(31, 190, 188, 0.2)',  // Adjust as necessary
    },
  },
}));

export default QualitatioToggleGroup;
