// WebSocketStatusIndicator.js
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebSocketContext } from '../../provider/WebsocketProvider';

function WebSocketStatusIndicator() {
  const [socketConnected, setSocketConnected] = useState(false);
  const { socket } = useContext(WebSocketContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (socket) {
      setSocketConnected(socket.connected);
    }
  }, [socket]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {socketConnected ? (
        <Tooltip title={t('ews.liveConnectionActive')}>
          <WifiTetheringIcon style={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <Tooltip title={t('ews.liveConnectionInactive')}>
          <PortableWifiOffIcon style={{ color: 'red' }} />
        </Tooltip>
      )}
      <Typography variant="body1" sx={{ ml: 1, color: socketConnected ? 'green' : 'red' }}>
        {socketConnected ? 'Connected' : 'Not Connected'}
      </Typography>
    </Box>
  );
}

export default WebSocketStatusIndicator;
