import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, TextField, Typography, Switch } from '@mui/material';
import {
  cardLayoutEndpoint,
  defectDescriptionFieldsEndpoint,
  qrCodeSettingsEndpoint,
} from '../../../api/endpoints';
import axiosClient from '../../../api/axiosClient';
import InfoTooltip from '../../../components/TooltipWrapper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function CardLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [cardLayout, setCardLayout] = useState(false);
  const [displayQRCode, setDisplayQRCode] = useState(false);
  const [qrEncodingString, setQREncodingString] = useState('');
  const [defectDescriptionFields, setDefectDescriptionFields] = useState(null);
  const [qrEncodingInvalid, setQREncodingInvalid] = useState(false);
  const [qrEncodingTip, setQREncodingTip] = useState(t('qrEncodingTip'));

  useEffect(() => {
    getCardLayout();
    getQrEncodingSetting();
  }, []);

  useEffect(() => {
    if (displayQRCode) {
      getDefectDescriptionFields();
    } else {
      setQREncodingString('');
    }
  }, [displayQRCode]);

  useEffect(() => {
    updateEncodingStringTip();
  }, [defectDescriptionFields]);

  const getCardLayout = async () => {
    try {
      const response = await axiosClient.get(cardLayoutEndpoint);
      setCardLayout(response.data.layout);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingCardLayout'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const getQrEncodingSetting = async () => {
    try {
      const response = await axiosClient.get(qrCodeSettingsEndpoint);
      if (response.data.qrCodeSettings) {
        setDisplayQRCode(response.data.qrCodeSettings.displayQRCode);
        setQREncodingString(response.data.qrCodeSettings.qrEncodingString);
      }
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingQRCodeSettings'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionFields = async () => {
    if (!defectDescriptionFields) {
      try {
        const response = await axiosClient.get(defectDescriptionFieldsEndpoint);
        setDefectDescriptionFields(response.data.defectDescriptionFields);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const validateQRCodeEncoding = () => {
    if (qrEncodingString === '') {
      enqueueSnackbar(t('qrEncodingStringIsRequired'), { variant: 'error' });
      return false;
    }
    const regex = /\{(\w+)\}/g;
    const matches = qrEncodingString.match(regex);
    if (!matches) {
      enqueueSnackbar(t('noPlaceholderFound'), { variant: 'error' });
      return false;
    }
    // Check if all placeholders are valid, i.e. they are part of the defect description fields.
    // Note that the placeholders can be the name of the field or end with '_id'.
    for (const match of matches) {
      const matchWithoutCurlyBraces = match.substring(1, match.length - 1);
      const matchWithoutCurlyBracesAndId = matchWithoutCurlyBraces.endsWith('_id')
        ? matchWithoutCurlyBraces.substring(0, matchWithoutCurlyBraces.length - 3)
        : matchWithoutCurlyBraces;
      if (
        !defectDescriptionFields.includes(matchWithoutCurlyBracesAndId) &&
        matchWithoutCurlyBraces !== 'defectComment' // 'defectComment' is always part of the defect card.
      ) {
        enqueueSnackbar(t('invalidPlaceholder') + ': ' + matchWithoutCurlyBraces, {
          variant: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const updateEncodingStringTip = () => {
    let tip = t('qrEncodingTip');
    if (defectDescriptionFields) {
      // Construct another array with all defectDescriptionFields, their values concatenated with '_id',
      // and the fixed field 'defectComment'.
      const acceptedFields = defectDescriptionFields.concat(
        defectDescriptionFields.map((field) => field + '_id'),
        'defectComment'
      );
      tip += '\n' + acceptedFields.join(', ');
    }
    setQREncodingTip(tip);
  };

  const handleCardLayoutChange = async () => {
    try {
      await axiosClient.post(cardLayoutEndpoint, {
        layout: cardLayout,
      });
      if (displayQRCode) {
        const isValid = validateQRCodeEncoding();
        setQREncodingInvalid(!isValid);
        if (isValid) {
          await axiosClient.post(qrCodeSettingsEndpoint, {
            displayQRCode: true,
            qrEncodingString: qrEncodingString,
          });
        } else {
          throw new Error('Invalid QR encoding string');
        }
      } else {
        await axiosClient.post(qrCodeSettingsEndpoint, {
          displayQRCode: false,
          qrEncodingString: '',
        });
      }
      enqueueSnackbar(t('cardLayoutChangedSuccessfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorWhenChangingCardLayout'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Switch checked={cardLayout} onChange={() => setCardLayout(!cardLayout)} />
          <Typography ml={2}>{cardLayout ? t('visual') : t('textual')}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          <Switch checked={displayQRCode} onChange={() => setDisplayQRCode(!displayQRCode)} />
          <Typography ml={2}>{t('displayQRCode')}</Typography>
        </Box>
        {displayQRCode && (
          <Box display="flex" alignItems="center" ml={2} mt={2}>
            <TextField
              label={`${t('qrEncoding')}*`}
              type="text"
              placeholder={`${t('enterQREncodingString')}...`}
              error={qrEncodingInvalid}
              helperText={qrEncodingInvalid ? t('invalidQREncodingString') : ''}
              fullWidth
              onChange={(e) => setQREncodingString(e.currentTarget.value)}
              value={qrEncodingString}
            />
            <InfoTooltip description={qrEncodingTip} />
          </Box>
        )}
      </Grid>
      <Grid item>
        <Button variant="qualitatio" color="primary" onClick={handleCardLayoutChange}>
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  );
}
