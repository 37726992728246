import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { getETQualityStationImageEndpoint } from '../../../api/endpoints';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import Menu from '../Menu';
import './ScanET.css';

export default function ScanET({
  setPageName,
  setProductID,
  qs,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
}) {
  const { t } = useTranslation();
  setPageName(t('scan'));

  const [queryParameters] = useSearchParams();
  const qualityStation = qs || queryParameters.get('qualityStationName');
  const [qualityStationImageFromServer, setQualityStationImageFromServer] = useState('');
  const qualityStationImage =
    queryParameters.get('qualityStationImage') || qualityStationImageFromServer;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getQualityStationImage();
  }, []);

  const getQualityStationImage = async () => {
    try {
      const response = await axiosClient.get(getETQualityStationImageEndpoint, {
        headers: { qualitystation: qualityStation },
      });
      setQualityStationImageFromServer(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [isTestRun, setIsTestRun] = useState(true);

  const [value, setValue] = useState('');

  const isValid = (str) => /^[a-zA-Z0-9_-]+$/.test(str);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && value && isValid(value)) {
      document.getElementById('link').click();
    } else if (event.key === 'Enter' && !isValid(value)) {
      enqueueSnackbar(t('invalidProductID'), { variant: 'warning' });
    }
  };

  const navigate = useNavigate();

  const handleStartClick = (qualityStation, value) => {
    if (value && isValid(value)) {
      const url = encodeURI(
        `/efficient_testing/recommender/test?qualityStation=${qualityStation}&productID=${value}&isTestRun=${isTestRun}`
      );
      navigate(url);
    }
  };

  const handleCancelClick = () => {
    navigate('/efficient_testing/recommender/select');
  };

  return (
    <>
      {recommenderMenuOpen ? (
        <div className="scan-layout">
          <div className="scan-upper-bar">
            <div className="scan-upper-bar-content">
              <div className="scan-upper-bar-text">{t('startNewTest')}...</div>
              <img className="image" alt="Layer" src="/images/qualitatio.svg" />
            </div>
          </div>
          <div className="scan-product">
            <div className="scan-product-content">
              <div className="scan-product-input">
                <QualitatioButton
                  text={qualityStation}
                  disabled={true}
                  startIcon={<img src={qualityStationImage} />}
                  startIconIsImage={true}
                  startIconRounded={true}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  width={'100%'}
                />
                <Grid item display={'flex'} direction={'column'} gap={1}>
                  <Grid item display={'flex'} justifyContent={'flex-end'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isTestRun}
                          onChange={() => setIsTestRun(!isTestRun)}
                          color="primary"
                        />
                      }
                      label={t('testRun')}
                      style={{ marginRight: 0 }}
                    />
                  </Grid>
                  <QualitatioInput
                    label={t('enterProductID') + '*'}
                    type="text"
                    placeholder={t('enterProductID') + '...'}
                    width={'100%'}
                    size={'small'}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    onKeyDown={handleKeyPress}
                    value={value}
                  />
                </Grid>
              </div>
              <div className="scan-product-buttons">
                <QualitatioButton
                  text={t('startTest')}
                  id="link"
                  order="primary"
                  width={'100%'}
                  height={'5vh'}
                  fontSize={'calc(12px + 0.25vw)'}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  disabled={!value || !isValid(value)}
                  onClick={() => handleStartClick(qualityStation, value)}
                />
                <QualitatioButton
                  text={t('cancel')}
                  order="secondary"
                  width={'100%'}
                  height={'5vh'}
                  fontSize={'calc(12px + 0.25vw)'}
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                  onClick={handleCancelClick}
                />
              </div>
            </div>
          </div>
          <div className="scan-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}

ScanET.propTypes = {
  setPageName: PropTypes.func,
  setProductID: PropTypes.func,
  qs: PropTypes.string,
  recommenderMenuOpen: PropTypes.bool,
  setRecommenderMenuOpen: PropTypes.func,
};
