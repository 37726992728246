import ImageIcon from '@mui/icons-material/Image';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import axiosClient from '../../../api/axiosClient';
import { saveLogoFileEndpoint } from '../../../api/endpoints';
import { LogoContext } from "../../../provider/CompanyLogoProvider";
import QualitatioDropzone from "../../../components/QualitatioDropzone/QualitatioDropzone";
import { useTranslation } from 'react-i18next';
import PanoramaIcon from '@mui/icons-material/Panorama';

/**
 * Renders a component for uploading and displaying a company logo.
 * @returns {JSX.Element} The CompanyLogoSettings component.
 */
const CompanyLogoSettings = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();;
    const { companyLogo, newLogoUploaded } = useContext(LogoContext);

    /**
     * Uploads a company logo file to the server.
     * @param {File} logoFile - The logo file to upload.
     * @returns {Promise<void>} A Promise that resolves when the file is uploaded successfully.
     */
    const uploadCompanyLogo = async (logoFile) => {
        // Create a FormData object
        const formData = new FormData();
        formData.append('logoFile', logoFile);

        // Save File to Server
        try {
            const response = await axiosClient.post(saveLogoFileEndpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
                }
            });
            enqueueSnackbar(t("companyLogoUploadedSuccessfully"), { variant: 'success' });
            newLogoUploaded(response.data.logoFileURL);
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar(t("errorWhenUploadingCompanyLogo"), { variant: 'error' });
        }
    }

    return (
        <div>
            <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-evenly"
                width="100%">
                <QualitatioDropzone
                    onDrop={uploadCompanyLogo}
                    enablePreview={true}
                    acceptedFileTypes={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
                    previewOld={companyLogo}
                >
                    <ImageIcon size={30} />
                    <Typography>
                        {t("dropImagesHere")}
                    </Typography>
                    <Typography>
                        {t("orClickToUpload")}
                    </Typography>
                </QualitatioDropzone>
                {companyLogo === null ?
                    <PanoramaIcon style={{ maxHeight: '15%', maxWidth: '15%' }} />
                    :
                    <img src={companyLogo} alt="Company Logo" style={{ maxHeight: '15%', maxWidth: '15%' }} />}

            </Box>
        </div>)
}

export default CompanyLogoSettings;