import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { outputStreamSubscriptionEndpoint } from '../../../api/endpoints';
import { getSubscriptionValidationSchema } from '../../../utils/validationSchemas';
import SubscriptionSummary from './SubscriptionSummary';

const EditSubscriptionOverlay = ({
  el,
  isOpen,
  onClose,
  setOutputStreamSubscriptions,
  availableOutputStreams,
}) => {
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      ...el,
    },
    validationSchema: getSubscriptionValidationSchema(-1, i18n.language),
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSave = async (values) => {
    const snackbarKey = enqueueSnackbar(t('startingUpdateOfOutputStreamSubscription'), {
      variant: 'info',
      persist: true,
    });
    //set all formik values to touched so that the validation is triggered for all fields
    formik.setTouched({
      name: true,
      stream: true,
      type: true,
      active: true,
      connectionDetails: {
        topic: true,
        format: true,
        updateFrequency: true,
      },
    });
    try {
      const response = await axiosClient.put(outputStreamSubscriptionEndpoint, {
        outputStreamSubscription: formik.values,
      });
      setOutputStreamSubscriptions(response.data.outputStreamSubscriptions);
      enqueueSnackbar(t('outputStreamSubscriptionSuccesfullyUpdated'), { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(t('errorWhileUpdatingOutputStreamSubscription'), { variant: 'error' });
      console.error('Error:', error);
    } finally {
      if (snackbarKey) closeSnackbar(snackbarKey);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} variant="qualitatio">
      <DialogTitle>
        Edit "{el.name}"
        <Typography variant="subtitle2" color="textSecondary">
          Created by {formik.values.creatorName} on{' '}
          {new Date(formik.values.created).toLocaleDateString()}, last Event on{' '}
          {new Date(formik.values.lastEvent).toLocaleString()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <SubscriptionSummary
          formik={formik}
          readonly={false}
          availableOutputStreams={availableOutputStreams}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="qualitatio"
          color="primary"
          onClick={handleSave}
          endIcon={<ArrowForwardIosIcon />}
        >
          {t('save')}
        </Button>
        <Button
          variant="qualitatio"
          color="secondary"
          startIcon={<ArrowBackIosNewRoundedIcon />}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubscriptionOverlay;
