import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function QualitatioChipArray({
    chipData,
    display = 'flex',
    flexDirection = 'row',
    justifyContent = 'center',
    flexWrap = 'wrap',
    listStyle = 'none',
    padding = 0.5,
    width = '100%',
    margin = 0,
    boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor = '',
    height = '100%',
}) {

    return (
        <Paper
            sx={{
                display: display,
                justifyContent: justifyContent,
                flexDirection: flexDirection,
                flexWrap: flexWrap,
                listStyle: listStyle,
                p: padding,
                m: margin,
                boxShadow: boxShadow,
                width: width,
                height: height,
            }}
            component="ul"
        >
            {chipData.map((data) => {

                return (
                    <ListItem key={data.label}>
                        <Chip
                            icon={data.icon}
                            label={data.label}
                            color={data.color}
                            onClick={data.onClick}
                            avatar={data.avatar ? <Avatar src={data.avatar} alt='A'/> : null}
                            sx={{ backgroundColor: backgroundColor || data.backgroundColor }}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
}
