import QualitatioButton from '../QualitatioButton/QualitatioButton';
import QualitatioDropdown from '../QualitatioDropdown/QualitatioDropdown';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './QualitatioDropdownList.module.css';

export default function QualitatioDropdownList({
  dropdownListValues,
  defaultValues,
  selectedDropdownValues,
  onDropdownListChange, // (dropdownListName, newValue) => {}
  includeApplyButton = false,
  onApply,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.dropdownContainer}>
      {Object.keys(dropdownListValues).map((dropdownListName) => (
        <QualitatioDropdown
          key={dropdownListName}
          label={dropdownListName}
          options={dropdownListValues[dropdownListName]}
          getOptionLabel={(option) => option}
          value={
            selectedDropdownValues[dropdownListName]
              ? selectedDropdownValues[dropdownListName]
              : defaultValues[dropdownListName]
          }
          size="lg"
          onChange={(e, newValue) => {
            onDropdownListChange(dropdownListName, newValue);
          }}
          width={'15%'}
          minWidth={'150px'}
        />
      ))}
      {includeApplyButton && (
        <QualitatioButton
          text={t('apply')}
          order="primary"
          onClick={onApply}
          width={'12%'}
          minWidth={'150px'}
          height={'100%'}
          fontSize={'calc(12px + 0.25vw)'}
        />
      )}
    </div>
  );
}

QualitatioDropdownList.propTypes = {
  dropdownListValues: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired, // Assuming the dropdown lists are collections of strings
  defaultValues: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedDropdownValues: PropTypes.objectOf(PropTypes.string).isRequired,
  onDropdownListChange: PropTypes.func.isRequired, // Function callback (dropdownListName, newValue)
  includeApplyButton: PropTypes.bool,
  onApply: PropTypes.func, // Function callback for the apply button
};
