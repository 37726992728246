import { ReactComponent as CzFlag } from '../../assets/flags/cz.svg';
import { ReactComponent as DeFlag } from '../../assets/flags/de.svg';
import { ReactComponent as EnFlag } from '../../assets/flags/en.svg';
import { ReactComponent as EsFlag } from '../../assets/flags/es.svg';
import { ReactComponent as FrFlag } from '../../assets/flags/fr.svg';
import { ReactComponent as HuFlag } from '../../assets/flags/hu.svg';
import { ReactComponent as ItFlag } from '../../assets/flags/it.svg';
import { ReactComponent as PlFlag } from '../../assets/flags/pl.svg';
import { ReactComponent as RoFlag } from '../../assets/flags/ro.svg';
import { ReactComponent as RuFlag } from '../../assets/flags/ru.svg';
import { ReactComponent as TrFlag } from '../../assets/flags/tr.svg';

export const flagComponents = {
  en: EnFlag,
  de: DeFlag,
  fr: FrFlag,
  es: EsFlag,
  ru: RuFlag,
  ro: RoFlag,
  pl: PlFlag,
  cz: CzFlag,
  hu: HuFlag,
  it: ItFlag,
  tr: TrFlag,
};
