import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../api/axiosClient';
import { monitoredSystemsEndpoint } from '../../api/endpoints';
import { monitoredSystemThresholdValidationSchema } from '../../utils/validationSchemas';

/**
 * Displays a dialog for setting upper and lower thresholds.
 *
 * `selectedRow` contains information about the current field and its thresholds.
 * It allows users to update the threshold values for a specific field.
 *
 * @param {Object} selectedRow - The data for the selected row including field name, mean, percentile,
 *                               thresholds, and field status.
 * @param {boolean} isCritical - Indicates if the threshold being set is a critical threshold or warning threshold.
 * @param {Function} onClose - Callback function to be called when the dialog is closed.
 */
const ThresholdOverlay = ({ selectedRow, isCritical, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  // Determine which thresholds to use based on `isCritical` flag
  const thresholdsKey = isCritical ? 'criticalThresholds' : 'warnThresholds';
  const thresholds = selectedRow[thresholdsKey] || [-Infinity, Infinity];

  const handleSubmitThreshold = async (values) => {
    //set all formik fields to touched to show error messages
    formik.setTouched({
      upperThreshold: true,
      lowerThreshold: true,
    });

    //trigger validation
    formik.validateForm();

    if (formik.errors.upperThreshold || formik.errors.lowerThreshold) {
      return;
    }
    try {
      const lowerThreshold =
        values.lowerThreshold || values.lowerThreshold === 0 ? values.lowerThreshold : null;
      const upperThreshold =
        values.upperThreshold || values.upperThreshold === 0 ? values.upperThreshold : null;
      const body = {
        [thresholdsKey]: {
          [selectedRow.field]: [lowerThreshold, upperThreshold],
        },
      };

      const response = await axiosClient.patch(
        `${monitoredSystemsEndpoint}/${selectedRow.systemId}`,
        body,
      );
      if (response.data && !response.data.error) {
        enqueueSnackbar(t('ews.monitoredSystemCreateSuccessSnack'), { variant: 'success' });
        onClose();
      } else {
        enqueueSnackbar(t('ews.monitoredSystemCreateErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('ews.monitoredSystemCreateErrorSnack'), { variant: 'error' });
    } finally {
      formik.resetForm();
      onClose();
    }
  };
  const upperThreshold = selectedRow[thresholdsKey]?.[1] ?? +Infinity;
  const lowerThreshold = selectedRow[thresholdsKey]?.[0] ?? -Infinity;

  const formik = useFormik({
    initialValues: {
      upperThreshold: Number(upperThreshold),
      lowerThreshold: Number(lowerThreshold),
    },
    validationSchema: monitoredSystemThresholdValidationSchema(i18n.language),
    onSubmit: handleSubmitThreshold,
  });

  return (
    <Dialog open={true} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t('ews.setThresholdTitle')}</DialogTitle>
      <Typography variant="subtitle1" align="center">
        {t('ews.setThresholdDescription')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('upperThreshold')}
                placeholder={t('ews.noUpperThreshold')}
                label={t('ews.upperThreshold')}
                type="number"
                fullWidth
                error={formik.touched.upperThreshold && Boolean(formik.errors.upperThreshold)}
                helperText={formik.touched.upperThreshold && formik.errors.upperThreshold}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateOutlinedIcon
                        color={
                          formik.touched.name && Boolean(formik.errors.name) ? 'error' : 'primary'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('lowerThreshold')}
                placeholder={t('ews.noLowerThreshold')}
                label={t('ews.lowerThreshold')}
                type="number"
                error={formik.touched.lowerThreshold && Boolean(formik.errors.lowerThreshold)}
                helperText={formik.touched.lowerThreshold && formik.errors.lowerThreshold}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateOutlinedIcon
                        color={
                          formik.touched.name && Boolean(formik.errors.name) ? 'error' : 'primary'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="qualitatio"
            endIcon={<ArrowForwardIosIcon />}
          >
            {selectedRow ? t('save') : t('create')}
          </Button>
          <Button
            onClick={onClose}
            color="secondary"
            variant="qualitatio"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ThresholdOverlay.propTypes = {
  selectedRow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    systemId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    mean: PropTypes.number,
    ninetiethPercentile: PropTypes.number,
    warnThresholds: PropTypes.arrayOf(PropTypes.number).isRequired,
    criticalThresholds: PropTypes.arrayOf(PropTypes.number).isRequired,
    fieldStatus: PropTypes.string,
  }),
  isCritical: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ThresholdOverlay.defaultProps = {
  selectedRow: null,
  isCritical: false,
};

export default ThresholdOverlay;
