import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import MonitorSystemsTable from './components/MonitorSystemsTable';

import { useTranslation } from 'react-i18next';
import PageFrame from '../../components/PageFrame';
import PropTypes from 'prop-types';

export default function Settings({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('ei.settings'));

  return (
    <PageFrame title={t('ei.settings')} Icon={SettingsRoundedIcon}>
      <MonitorSystemsTable />
    </PageFrame>
  );
}

Settings.propTypes = {
  setPageName: PropTypes.func.isRequired,
};
