// Dialogs.js
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardEditDialogs from '../configurator/testOptions/components/CardEditDialogs';

const Dialogs = ({ deleteCardWarningDialog, discardChangesWarningDialog }) => {
  const { t } = useTranslation();

  return (
    <CardEditDialogs
      deleteCardWarningDialog={deleteCardWarningDialog}
      discardChangesWarningDialog={discardChangesWarningDialog}
    />
  );
};

const deleteCardWarningDialogPropTypes = {
  deleteCardWarningOpen: PropTypes.bool.isRequired,
  setDeleteCardWarningOpen: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

const discardChangesWarningDialogPropTypes = {
  discardChangesWarningOpen: PropTypes.number.isRequired,
  setDiscardChangesWarningOpen: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};

Dialogs.propTypes = {
  deleteCardWarningDialog: PropTypes.shape(deleteCardWarningDialogPropTypes).isRequired,
  discardChangesWarningDialog: PropTypes.shape(discardChangesWarningDialogPropTypes).isRequired,
};

export default Dialogs;
