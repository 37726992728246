import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Camera component that captures photos using the device's camera.
 *
 * @component
 * @example
 * const handleTakePhoto = (dataUri) => {
 *   console.log(dataUri);
 * };
 *
 * const handleCancel = () => {
 *   console.log("Camera canceled");
 * };
 *
 * <QualitatioCamera onTakePhoto={handleTakePhoto} onCancel={handleCancel} />
 */
const QualitatioCamera = ({ onTakePhoto, onCancel }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        // Try to get the environment-facing camera
        let stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: 'environment' } },
        });
        videoRef.current.srcObject = stream;
      } catch (err) {
        console.warn('Environment-facing camera not available, falling back to any camera: ', err);
        try {
          // Fall back to any available camera
          let stream = await navigator.mediaDevices.getUserMedia({ video: true });
          videoRef.current.srcObject = stream;
        } catch (error) {
          console.error('Error accessing any camera: ', error);
          setError('Could not access any camera. Please check your camera settings.');
        }
      }
    };

    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  /**
   * Captures a photo from the video stream and calls the onTakePhoto prop with the photo's data URI.
   */
  const handleCapture = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!video || !canvas) {
      console.error('Video or canvas not available');
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');

    if (!context) {
      console.error('Could not get canvas context');
      return;
    }

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUri = canvas.toDataURL('image/jpeg');
    console.log('Captured image data URI: ', dataUri);

    if (onTakePhoto) {
      onTakePhoto(dataUri);
    }
  };

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <video ref={videoRef} autoPlay playsInline style={styles.video}></video>
      <canvas ref={canvasRef} style={styles.canvas}></canvas>
      <div style={styles.overlay}>
        <IconButton onClick={onCancel} style={styles.cancelButton}>
          <CloseIcon style={{ color: 'white', fontSize: 30 }} />
        </IconButton>
        <button onClick={handleCapture} style={styles.captureButton}></button>
      </div>
    </div>
  );
};

QualitatioCamera.propTypes = {
  /**
   * Function to call when a photo is taken.
   * @param {string} dataUri - The data URI of the captured photo.
   */
  onTakePhoto: PropTypes.func.isRequired,

  /**
   * Function to call when the cancel button is pressed.
   */
  onCancel: PropTypes.func.isRequired,
};

const styles = {
  container: {
    position: 'absolute',
    top: '0px', // Adjust this value based on your navbar height
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  canvas: {
    display: 'none',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '15%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButton: {
    position: 'absolute',
    left: '20px',
    bottom: '20px',
  },
  captureButton: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: 'white',
    border: '4px solid rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: '20px',
    textAlign: 'center',
  },
};

export default QualitatioCamera;
