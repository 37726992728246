import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageFrame from '../../components/PageFrame';
import { getAndSetIntelliDocLicense } from '../../intelligent_testing/recommender/swipe/DefectInput/utils';
import './SystemConfigurator.css';
import AuthTokenSettings from './components/AuthTokenSettings';
import CardLayout from './components/CardLayout';
import CompanyLogoSettings from './components/CompanyLogoSettings';
import DefectHierarchieSettings from './components/DefectHierarchieSettings';
import ETProductRelevanceCycle from './components/ETProductRelevanceCycle';
import APISettings from './components/GenAISettings';
import IDSettings from './components/IDSettings';
import LanguageSettings from './components/LanguageSettings';
import LicenseSettings from './components/LicenseSettings';
import OAuthSettings from './components/OAuthSettings';
import AccordionWrapper from './components/SettingAccordion';
import ThreeDModelSettings from './components/ThreeDModelSettings';

//note from franzi: maybe add a progress bar to the page, so the user knows how far he is in the process of configuring the system

/**
 * A component that displays the system configuration settings.
 * @param {Object} setPageName - A function to set the page name.
 * @returns {JSX.Element} - A JSX element representing the system configuration settings.
 */
export default function SystemConfigurator({ setPageName }) {
  const { t } = useTranslation();
  setPageName(t('systemConfiguration'));

  /** =============== Retrieve License Information =============== */
  const [intelliDocLicense, setIntelliDocLicense] = useState(false);
  useEffect(() => {
    getAndSetIntelliDocLicense(setIntelliDocLicense);
  }, []);

  const accordionData = [
    {
      title: t('uploadCompanyLogo'),
      subTitle: t('uploadCompanyLogoDescription'),
      content: <CompanyLogoSettings />,
      actions: '',
    },
    {
      title: t('configureDefectDescriptionStandards'),
      subTitle: t('configureDefectDescriptionStandardsDescription'),
      content: <DefectHierarchieSettings />,
      actions: '',
    },
    {
      title: t('authToken.settingTitle'),
      subTitle: t('authToken.settingDescription'),
      content: <AuthTokenSettings />,
      actions: '',
    },
    // LDAP is not used in the current version
    /*     {
      title: t('ldapSettings'),
      subTitle: t('ldapSettingsDescription'),
      content: <LDAPSettings />,
      actions: '',
    }, */
    {
      title: t('oauth.settings'),
      subTitle: t('oauth.settingsDescription'),
      content: <OAuthSettings />,
      actions: '',
    },
    {
      title: t('defaultLanguageSettingsTitle'),
      subTitle: t('defaultLanguageSettingsDescription'),
      content: <LanguageSettings />,
      actions: '',
    },
    {
      title: t('cardLayout'),
      subTitle: t('cardLayoutDescription'),
      content: <CardLayout />,
      actions: '',
    },
    {
      title: t('product3DModel'),
      subTitle: t('product3DModelDescription'),
      content: <ThreeDModelSettings />,
      actions: '',
    },
    {
      title: t('licenseSettings'),
      subTitle: t('licenseSettingsDescription'),
      content: <LicenseSettings />,
      actions: '',
    },
    {
      title: t('apiSettings'),
      subTitle: t('apiSettingsDescription'),
      content: <APISettings />,
      actions: '',
    },
    {
      title: t('etProductRelevanceCycle'),
      subTitle: t('etProductRelevanceCycleDescription'),
      content: <ETProductRelevanceCycle />,
    },
  ];

  if (intelliDocLicense)
    accordionData.push({
      title: t('idSettings'),
      subTitle: t('idSettingsDescription'),
      content: <IDSettings />,
      actions: '',
    });

  return (
    <PageFrame title={t('systemConfiguration')} Icon={SettingsRoundedIcon}>
      <div className="grid-container-sys-conf">
        <AccordionWrapper accordionData={accordionData} />
      </div>
    </PageFrame>
  );
}
