import { Autocomplete, Avatar, Chip, CircularProgress, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './QualitatioChipDropdown.css';

export default function QualitatioChipDropdown({
  options,
  asyncOptions,
  label,
  value,
  onChange,
  width,
  minWidth,
  margin,
  placeholder,
  renderOption,
  groupBy,
  disabled = false,
  compact = false,
  xs = false,
  readOnly = false,
  multiple = false,
  filterSelectedOptions = true,
  disableCloseOnSelect = false,
  ...otherProps
}) {
  const [open, setOpen] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState(options);
  const [loading, setLoading] = useState(false);

  // Handle the opening of the dropdown
  const handleOpen = () => {
    setOpen(true);
    if (asyncOptions && typeof asyncOptions === 'function') {
      setLoading(true);
      asyncOptions().then((fetchedOptions) => {
        setLoadedOptions(fetchedOptions);
        setLoading(false);
      });
    }
  };

  // Handle the closing of the dropdown
  const handleClose = () => {
    setOpen(false);
    setLoadedOptions(options); // Reset to default options if asyncOptions is not provided
  };

  const renderTags = (value, getTagProps) =>
    value?.map((option, index) => (
      <Chip
        key={option.value}
        avatar={
          !option.notDeletable ? (
            option.profileImage ? (
              <Avatar src={option.profileImage} alt={option.label[0]} />
            ) : (
              <Avatar>{option.label[0]}</Avatar>
            )
          ) : null
        }
        label={option.label}
        {...(!option.notDeletable ? getTagProps({ index }) : {})}
        disabled={disabled}
        size="small"
        color={option.color}
      />
    ));

  const style = {
    width: width || 'auto',
    minWidth: minWidth || 'auto',
    background: 'white',
    margin: margin || '0',
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      options={loadedOptions}
      multiple={multiple}
      readOnly={readOnly}
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions={filterSelectedOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      loading={loading}
      renderTags={renderTags}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      style={style}
      renderOption={renderOption || ((props, option) => <li {...props}>{option.label}</li>)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          className={`${compact ? 'qualitatio-chips-dropdown-compact' : ''} ${xs ? 'xs-chip-dropdown' : ''}`}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            readOnly,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          style={style}
        />
      )}
      {...otherProps}
    />
  );
}

QualitatioChipDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  asyncOptions: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
  groupBy: PropTypes.func,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  xs: PropTypes.bool,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  filterSelectedOptions: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
};
