import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { inputStreamsEndpoint } from '../../../api/endpoints';
import InputStreamSummary from './InputStreamSummary';

import { useFormik } from 'formik';
import { valuesChanged } from '../../../utils/utilities';
import { getConnectionSetupValidationSchema } from '../../../utils/validationSchemas';

//doesnt work for APIStreams (and probably kafka streams as well) because the formik and el have different strucutres
//solution: map the el values in the formik object to the formik strucutre or in any other way
// you need to rework the inputStreamSummary as well anyways.

const EditInputStreamOverlay = ({ el, isOpen, onClose, setInputStreams }) => {
  const getIdRight = (rowElement) => {
    const { inputStreamID, ...rest } = rowElement; // Destructure to separate inputStreamID and the rest
    return { id: inputStreamID, ...rest }; // Rename inputStreamID to id and merge the res
  };
  const [inputStream, setInputStream] = useState(getIdRight(el));

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //From here on is formik the single source of truth
  const formik = useFormik({
    initialValues: getIdRight(el),
    validationSchema: getConnectionSetupValidationSchema(-1, i18n.language),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      //The on submit will put the inputStream to the server if any changes have been made in the last step
      if (valuesChanged(values, inputStream)) {
        const streamID = values.id;
        let requestBody = { ...values };
        delete requestBody.lastEvent;
        delete requestBody.firstEvent;
        delete requestBody.size;
        delete requestBody.kafka?.hecToken;
        delete requestBody.api?.hecToken;
        delete requestBody.streamFields;
        delete requestBody.created;
        delete requestBody.updated;
        delete requestBody.id;
        delete requestBody.index;
        //if method is set and to password, delete token
        if (requestBody.api?.authentication?.method === 'password') {
          delete requestBody.api.authentication.credentials.token;
        } else if (requestBody.api?.authentication?.method === 'token') {
          delete requestBody.api.authentication.credentials.username;
          delete requestBody.api.authentication.credentials.password;
        }

        let enqueuedSnackbar;
        try {
          enqueuedSnackbar = enqueueSnackbar(t('startingUpdateOfInputStream'), {
            variant: 'info',
            persist: true,
          });
          const response = await axiosClient.patch(
            `${inputStreamsEndpoint}/${streamID}`,
            requestBody,
          );
          if (response.status === 400 && response.data.inputStreams) {
            //this happens when there was an error with the new input stream an a rollback was performed
            setInputStreams(response.data.inputStreams);
            enqueueSnackbar(t('inputStreamRollbackSnack'), { variant: 'error' });
          } else if (response.status === 200 && response.data.inputStreams) {
            enqueueSnackbar(t('inputStreamSuccessfullySaved'), { variant: 'success' });
            setInputStreams(response.data.inputStreams);
            setInputStream(response.data.updatedStream);
            resetForm();
          } else {
            enqueueSnackbar(t('inputStreamCouldNotBeSaved'), { variant: 'error' });
          }
        } catch (error) {
          console.error('Error:', error);
          enqueueSnackbar(t('inputStreamCouldNotBeSaved'), { variant: 'error' });
        } finally {
          closeSnackbar(enqueuedSnackbar);
          setSubmitting(false);
          onClose();
        }
      } else {
        enqueueSnackbar(t('noChangesMade'), { variant: 'info' });
      }
    },
  });

  // Use useEffect to reset Formik's values when el changes
  useEffect(() => {
    // Function to adjust el's structure to match Formik's expectations, if necessary
    const adjustedEl = getIdRight(el);
    formik.resetForm({ values: adjustedEl });
  }, [el, formik.resetForm]);

  return (
    <Dialog open={isOpen} onClose={onClose} variant="qualitatio">
      <DialogTitle>
        {t('edit')}
        {' ' + el.name}
        <Typography>
          Created by {el.creatorName} on {new Date(el.created).toLocaleDateString()}, last Event on{' '}
          {new Date(el.lastEvent).toLocaleString()}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <InputStreamSummary readonly={false} formik={formik} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="qualitatio"
          size="small"
          onClick={formik.handleSubmit}
          endIcon={<ArrowForwardIosIcon />}
          color="primary"
        >
          {t('save')}
        </Button>
        <Button
          variant="qualitatio"
          size="small"
          onClick={() => {
            formik.resetForm();
            onClose();
          }}
          startIcon={<ArrowBackIosNewRoundedIcon />}
          color="secondary"
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInputStreamOverlay;
