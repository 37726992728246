import FeedbackIcon from '@mui/icons-material/Feedback';
import { Avatar, Button, Grid, Paper, Popover, Tooltip, Typography } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUsers } from '../../api/user';
import ROLES from '../../authentication/roleConst';
import { useAuthStore } from '../../store/auth.store';
import QualitatioChipDropdown from '../QualitatioChipDropdown/QualitatioChipDropdown';
import QualitatioDateTimePicker from '../QualitatioDateTimePicker/QualitatioDateTimePicker';
import QualitatioDropdown from '../QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from '../QualitatioInput/QualitatioInput';
import './QualitatioToDoCard.css';

export default function QualitatioToDoCard({
  onSave,
  onCancel,
  onOpenCard,
  openCard,
  field,
  toDo,
  setToDo,
}) {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const { enqueueSnackbar } = useSnackbar();

  const refs = {
    title: useRef(null),
    testObject: useRef(null),
    testLocation: useRef(null),
    testMethod: useRef(null),
    testComment: useRef(null),
    orderIdentifier: useRef(null),
  };

  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers({ excludedRoles: `${ROLES.IT_USER},${ROLES.ET_USER}` });
      if (fetchedUsers && !fetchedUsers.error) {
        setUsers(fetchedUsers);
      } else {
        enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('userLoadingErrorSnack'), { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <>
      <Tooltip title={t('addToDo')} placement="right">
        <FeedbackIcon
          ref={refs[`${field}`]}
          style={{
            position: 'absolute',
            right: '-50px',
            height: '24px',
            width: '24px',
            cursor: 'pointer',
            top: '30px',
            zIndex: '1000',
          }}
          onClick={async () => {
            onOpenCard(field);
            await fetchUsers();
          }}
        />
      </Tooltip>
      <Popover
        open={openCard === field}
        anchorEl={refs[`${field}`].current}
        onClose={onCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper elevation={3} square={false} style={{ maxWidth: '300px', padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={user.profileImage || '/images/avatar.png'}
                style={{ marginRight: '8px' }}
              />
              <Typography variant="body2" color="text.secondary">
                {user.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <QualitatioInput
                label={t('title')}
                value={toDo.title}
                onChange={(e) => setToDo({ ...toDo, title: e.target.value })}
                width="100%"
                compact={true}
              />
            </Grid>
            <Grid item xs={12}>
              <QualitatioInput
                label={t('description')}
                value={toDo.description}
                onChange={(e) => setToDo({ ...toDo, description: e.target.value })}
                width="100%"
                compact={true}
                multiline={true}
                maxRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <QualitatioDropdown
                label={t('priority')}
                value={toDo.priority}
                onChange={(e, newValue) => setToDo({ ...toDo, priority: newValue })}
                options={[
                  { label: t('low'), value: 'low' },
                  { label: t('medium'), value: 'medium' },
                  { label: t('high'), value: 'high' },
                ]}
                width="100%"
                compact={true}
              />
            </Grid>
            <Grid item xs={12}>
              <QualitatioDropdown
                label={t('type')}
                value={toDo.type}
                onChange={(e, newValue) => setToDo({ ...toDo, type: newValue })}
                options={[
                  { label: t('task'), value: 'task' },
                  { label: t('fix'), value: 'fix' },
                  { label: t('improvement'), value: 'improvement' },
                  { label: t('verification'), value: 'verification' },
                ]}
                width="100%"
                compact={true}
              />
            </Grid>
            <Grid item xs={12}>
              <QualitatioDateTimePicker
                label={t('deadline')}
                value={toDo.deadline}
                onChange={(value) => setToDo({ ...toDo, deadline: value })}
                width="100%"
                compact={true}
                onlyFuture={true}
                noTime={true}
              />
            </Grid>
            <Grid item xs={12}>
              <QualitatioChipDropdown
                label={t('assignees')}
                value={toDo?.assignees}
                onChange={(newValue) =>
                  setToDo({
                    ...toDo,
                    assignees: newValue,
                  })
                }
                options={users.map((user) => ({
                  label: `${user.name} (${user.email})`,
                  value: user.id,
                  profileImage: user.profileImage,
                }))}
                width="100%"
                compact={true}
                multiple={true}
                rows={1}
                maxRows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiChipsInput
                className="qualitatio-chips-input-compact"
                value={toDo.keywords}
                onChange={(value) => setToDo({ ...toDo, keywords: value })}
                multiline
                rows={1} // Start with a single line
                maxRows={4} // Allow it to expand to more lines as needed
                fullWidth
                label={t('keywords')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: '1vw' }}>
              <Button variant="outlined" onClick={onCancel} size="small">
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={() => onSave()} size="small">
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </>
  );
}
