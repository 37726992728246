import React from 'react';
import { useTranslation } from 'react-i18next';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import { IconsIo } from '../../../components/IconsIo';
import { IconsNio } from '../../../components/IconsNio';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import QualitatioList from '../../../components/QualitatioList/QualitatioList';
import './TestOverview.css';

export default function TestOverview({
  qualityStation,
  cards,
  defectsFound,
  handleGoBackButtonClicked,
  isSummaryOpen,
  setIsSummaryOpen,
  handleDiscardButtonClicked,
  handleSaveButtonClicked,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
  defectDescriptionFields,
}) {
  const { t } = useTranslation();

  const displayedTitle = (element, index) => {
    const actualIndex = defectsFound.length - 1 - index; // Adjust the index for reversed array
    let numberOfFollowingOtherDefects = 0;
    for (let i = index + 1; i < defectsFound.length; i++) {
      if (defectsFound[i].otherDefect === 'yes') {
        numberOfFollowingOtherDefects++;
      }
    }

    if (element.otherDefect === 'yes') {
      return `${defectDescriptionFields.defectDescriptionFields.map((field) => element[field]).join(' - ')}`;
    } else if (numberOfFollowingOtherDefects > 0) {
      return cards[actualIndex - numberOfFollowingOtherDefects].character.title;
    } else {
      return cards[actualIndex]?.character.title;
    }
  };

  const handleListElementClicked = (index) => {
    setIsSummaryOpen(false);
    handleGoBackButtonClicked(index);
  };

  const items = defectsFound.map((el, index) => ({
    text: displayedTitle(el, index),
    buildingBlocksWrapperBuildingBlocksClassName:
      el.defectDetected === 'yes'
        ? el.defectFixed
          ? 'fixed-circle'
          : 'error-circle'
        : 'success-circle',
    listIndex: index < 9 ? '0' + (index + 1) : index + 1,
    onClick: () =>
      el.cardID ? handleListElementClicked(el.cardID) : handleListElementClicked(el.otherDefectID),
    endIcon:
      el.defectDetected === 'yes' ? (
        el.defectFixed ? (
          <IconsIo className="icons-io-fixed-instance" />
        ) : (
          <IconsNio className="icons-nio-instance" />
        )
      ) : (
        <IconsIo className="icons-io-instance" />
      ),
  }));

  return (
    <>
      {recommenderMenuOpen && (
        <div className={'test-result-overview-layout-' + (isSummaryOpen ? 'display' : 'hide')}>
          <div className="test-result-overview-header-bar">
            <div className="test-result-overview-header-text">{t('confirmAllTestResults')}</div>
          </div>
          <div className="test-result-overview-list">
            <QualitatioList items={items} gap="1.5vh" />
          </div>
          <div className="test-result-overview-actions">
            <div className="test-result-overview-buttons">
              <QualitatioButton
                text={t('discard')}
                order="secondary"
                onClick={handleDiscardButtonClicked}
                width={'90%'}
                height={'5vh'}
                fontSize={'calc(12px + 0.25vw)'}
              />
              <QualitatioButton
                text={t('saveAndFinish')}
                order="primary"
                onClick={() => {
                  handleSaveButtonClicked();
                }}
                width={'90%'}
                height={'5vh'}
                fontSize={'calc(12px + 0.25vw)'}
              />
            </div>
          </div>
          <div className="test-result-overview-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      )}
    </>
  );
}
