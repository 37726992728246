import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Chip, Divider, IconButton, MenuItem, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSave } from 'react-icons/bi';
import { FaFileCsv, FaRegClock } from 'react-icons/fa';
import { FiArrowDown, FiArrowUp, FiCircle, FiHexagon, FiTriangle } from 'react-icons/fi';
import { MdDownload, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import axiosClient from '../../api/axiosClient';
import {
  defectDescriptionFieldsEndpoint,
  generateCSVOptimizationFileFromStreamEndpoint,
  liveStreamDataEndpoint,
  optimizationDataEndpoint,
  saveCSVFileOptimizationEndpoint,
} from '../../api/endpoints';
import QualitatioAccordion from '../../components/QualitatioAccordion/QualitatioAccordion';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioChipArray from '../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioDateTimePicker from '../../components/QualitatioDateTimePicker/QualitatioDateTimePicker';
import QualitatioHighlightBox from '../../components/QualitatioHighlightBox/QualitatioHighlightBox';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioMobileStepper from '../../components/QualitatioMobileStepper/QualitatioMobileStepper';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import QualitatioTransferList from '../../components/QualitatioTransferList/QualitatioTransferList';
import { useAuthStore } from '../../store/auth.store';
import './AIOptimizerSteps.css';

export default function AIOptimizerSteps({
  activeStep,
  setActiveStep,
  checkForWrongCharacter,
  checkIfDataSourceNameAlreadyExists,
  setOptimizationData,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAuthStore((state) => state.user);

  // Form Variables
  const [name, setName] = useState('');
  const [lookupFileName, setLookupFileName] = useState('');
  const [active, setActive] = useState(true);
  const [type, setType] = useState('');
  const [streamName, setStreamName] = useState('');
  const [allFieldNames, setAllFieldNames] = useState([]);
  const [defectDescriptionFieldNames, setDefectDescriptionFieldNames] = useState([]);
  const [defectDescriptionFieldMapping, setDefectDescriptionFieldMapping] = useState([]);
  const [kpi, setKpi] = useState([]);
  const [csvFile, setCsvFile] = useState('');
  const [streamOptimizationDataAsCSV, setStreamOptimizationDataAsCSV] = useState('');
  const [ordering, setOrdering] = useState('');
  const [priorization, setPriorization] = useState('');
  const [timePeriod, setTimePeriod] = useState({
    startTime: moment().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss'),
    endTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
  });
  const [keepLearning, setKeepLearning] = useState(false);
  const [examples, setExamples] = useState([]);

  const [dateField, setDateField] = useState('');
  const [costField, setCostField] = useState('');

  // Helper Variables
  const [startTime, setStartTime] = useState(moment().subtract(1, 'years').format().split('+')[0]);
  const [endTime, setEndTime] = useState(moment().format().split('+')[0]);
  const [liveStreamData, setLiveStreamData] = useState([]);
  const [defectDescriptionFields, setDefectDescriptionFields] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const nextIsDisabled =
    (activeStep === 2 && name.length < 2) ||
    (activeStep === 2 &&
      (checkForWrongCharacter(name) || checkIfDataSourceNameAlreadyExists(name))) ||
    (activeStep === 3 && type === '') ||
    (activeStep === 4 && allFieldNames.length === 0) ||
    (activeStep === 5 && (defectDescriptionFieldNames.length === 0 || kpi.length === 0)) ||
    (activeStep === 6 &&
      (defectDescriptionFieldMapping.length !== defectDescriptionFields.length ||
        defectDescriptionFieldMapping.some((el) => el.mappedFieldName === ''))) ||
    (activeStep === 7 && (ordering === '' || priorization === '')) ||
    (activeStep === 8 && type === 'csv') ||
    (activeStep === 9 && type === 'live');

  const resetForm = () => {
    setName('');
    setLookupFileName('');
    setActive(true);
    setType('');
    setStreamName('');
    setAllFieldNames([]);
    setDefectDescriptionFieldNames([]);
    setDefectDescriptionFieldMapping([]);
    setKpi('');
    setCsvFile('');
    setStreamOptimizationDataAsCSV('');
    setOrdering('');
    setPriorization('');
    setTimePeriod({
      startTime: moment().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss'),
      endTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    });
    setKeepLearning(false);
    setExamples([]);
  };

  const handleSaveNewOptimizationData = async () => {
    const snackbarKey = enqueueSnackbar(t('startedSavingDatasource'), {
      variant: 'info',
      persist: true,
    });
    try {
      const response = await axiosClient.post(optimizationDataEndpoint, {
        name: name,
        lookupFileName: lookupFileName,
        active: active,
        creatorName: user.name,
        type: type,
        streamName: streamName,
        allFieldNames: allFieldNames,
        defectDescriptionFieldNames: defectDescriptionFieldNames,
        defectDescriptionFieldMapping: defectDescriptionFieldMapping,
        kpi: kpi.toString(),
        csvFile: csvFile,
        streamOptimizationDataAsCSV: streamOptimizationDataAsCSV,
        ordering: ordering,
        priorization: priorization,
        timePeriod: timePeriod,
        keepLearning: keepLearning,
        examples: JSON.stringify(examples),
        costField: costField,
        dateField: dateField,
      });
      setOptimizationData(response.data.optimizationData);
      closeSnackbar(snackbarKey);
      setActiveStep(0);
      resetForm();
      enqueueSnackbar(t('optimizationDataSourceSuccesfullySaved'), { variant: 'success' });
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(
        error?.response?.data?.errorMessageByServer || t('errorWhileSavingOptimizationDataSource'),
        { variant: 'error' }
      );
    }
  };

  const updateStartTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    setStartTime(selectedDateTime);
    setTimePeriod({ startTime: selectedDateTime, endTime: endTime });
  };

  const updateEndTime = async (e) => {
    const selectedDateTime = moment(e).format('YYYY-MM-DDTHH:mm:ss');
    setEndTime(selectedDateTime);
    setTimePeriod({ startTime: startTime, endTime: selectedDateTime });
  };

  const csvFileUploaded = async (e) => {
    const input = document.getElementById('dataSourceFile');
    if (input.files && input.files[0]) {
      const csvFile = input.files[0];
      var reader = new FileReader();

      reader.addEventListener('load', function (e) {
        let csvdata = e.target.result;
        getParsecsvdata(csvdata);
        saveCSVFileOnServer(e.target.result);
      });

      reader.readAsText(csvFile, 'utf8');
    }
  };

  const getParsecsvdata = (data) => {
    // Parse CSV data with PapaParse
    Papa.parse(data, {
      complete: (results) => {
        const parsedData = results.data; // Parsed data rows

        // Assume the first row contains field names
        const fieldNames = parsedData[0];

        // Initialize an array to keep track of whether each column might be a Date
        let potentialDateColumns = new Array(fieldNames.length).fill(true);
        let potentialCostColumns = new Array(fieldNames.length).fill(true);

        // Check the first 3 rows (excluding header) to determine if each column could be a Date
        for (let rowIndex = 1; rowIndex < Math.min(parsedData.length, 3); rowIndex++) {
          for (let colIndex = 0; colIndex < fieldNames.length; colIndex++) {
            if (!isValidDate(parsedData[rowIndex][colIndex])) {
              potentialDateColumns[colIndex] = false;
            }
            if (!isPotentialCost(parsedData[rowIndex][colIndex])) {
              potentialCostColumns[colIndex] = false;
            }
          }
        }

        const potentialCostFieldIndex = fieldNames.findIndex(
          (el) =>
            el.toLowerCase().includes('cost') ||
            el.toLowerCase().includes('kosten') ||
            el.toLowerCase().includes('warranty') ||
            el.toLowerCase().includes('gewährleistung')
        );

        // Find the first column that could be a Date and call setDateField with its name
        const dateColumnIndex = potentialDateColumns.indexOf(true);
        if (dateColumnIndex !== -1) {
          setDateField(fieldNames[dateColumnIndex]);
        }

        if (potentialCostFieldIndex !== -1 && potentialCostColumns[potentialCostFieldIndex]) {
          setCostField(fieldNames[potentialCostFieldIndex]);
        }

        setAllFieldNames(fieldNames);
        setDefectDescriptionFieldNames([]);
        setKpi([]);
      },
      skipEmptyLines: true,
    });
  };

  // Utility function to check if a string could be a Date
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const isPotentialCost = (value) => {
    // A basic check for cost; adjust according to your data format
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  const saveCSVFileOnServer = async (csvFileContent) => {
    try {
      const response = await axiosClient.post(saveCSVFileOptimizationEndpoint, {
        modelName: name,
        csvFile: csvFileContent,
      });
      setCsvFile(response.data.csvFile);
      setExamples(response.data.examples);
      setLookupFileName(response.data.lookupFileName);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateStreamName = (e) => {
    setStreamName(e);
    setAllFieldNames(liveStreamData.find((stream) => stream.streamName == e).fieldNames);
    setKpi([]);
    setDefectDescriptionFieldNames([]);
  };

  const updateDefectDescriptionFieldNamesAndKPI = (field) => {
    if (defectDescriptionFieldNames.includes(field)) {
      setDefectDescriptionFieldNames(
        defectDescriptionFieldNames.filter((el) => {
          return el != field;
        })
      );
    } else if (kpi.includes(field)) {
      setKpi([]);
    } else {
      setDefectDescriptionFieldNames([...defectDescriptionFieldNames, field]);
    }
  };

  const getDefectDescriptionFields = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionFieldsEndpoint);
      if (
        response.data.defectDescriptionFields === undefined ||
        response.data.defectDescriptionFields === null
      ) {
        enqueueSnackbar(t('noDefectDescriptionFieldsFound'), { variant: 'error' });
        return;
      }
      setDefectDescriptionFields(response.data.defectDescriptionFields);
      prepopulateDefectDescriptionFieldMapping(response.data.defectDescriptionFields);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const prepopulateDefectDescriptionFieldMapping = (defectDescriptionFields) => {
    const newDefectDescriptionFieldMapping = [];
    defectDescriptionFields.forEach((field) => {
      const index = defectDescriptionFieldNames.findIndex((el) => el === field);
      if (index === -1) {
        return;
      } else {
        newDefectDescriptionFieldMapping.push({
          defectDescriptionFieldName: field,
          mappedFieldName: defectDescriptionFieldNames[index],
        });
      }
    });
    setDefectDescriptionFieldMapping(newDefectDescriptionFieldMapping);
  };

  const updateDefectDescriptionMapping = (field, selectedValue) => {
    setDefectDescriptionFieldMapping((prevMapping) => {
      const updatedMapping = [...prevMapping];
      const index = updatedMapping.findIndex((el) => el.defectDescriptionFieldName === field);
      if (index === -1) {
        updatedMapping.push({
          defectDescriptionFieldName: field,
          mappedFieldName: selectedValue,
        });
        return updatedMapping;
      } else {
        updatedMapping[index] = {
          defectDescriptionFieldName: field,
          mappedFieldName: selectedValue,
        };
      }
      return updatedMapping;
    });
  };

  const updateStates = () => {
    if (activeStep === 3 && type === 'live') {
      getLiveStreamData();
    } else if (activeStep === 4) {
      getDefectDescriptionFields();
    } else if (activeStep === 4 && type === 'csv' && defectDescriptionFieldMapping.length === 0) {
      setDefectDescriptionFieldMapping(() => {
        const newDefectDescriptionFieldMapping = [];
        defectDescriptionFields?.map((field) => {
          defectDescriptionFieldNames.includes(field) &&
            newDefectDescriptionFieldMapping.push({
              defectDescriptionFieldName: field,
              mappedFieldName: field,
            });
        });
        return newDefectDescriptionFieldMapping;
      });
    }
  };

  const [nameError, setNameError] = useState('');

  const setNameWithCheck = (newName) => {
    const wrongCharacter = checkForWrongCharacter(newName);
    const modelNameExists = checkIfDataSourceNameAlreadyExists(newName);
    if (wrongCharacter) {
      setNameError(t('wrongCharacter'));
    } else if (modelNameExists) {
      setNameError(t('dataSourceAlreadyExists'));
    } else {
      setNameError('');
    }
    setName(newName);
  };

  const downloadOptimizationDataSet = async () => {
    const snackbarKey = enqueueSnackbar(t('downloadInitiated'), { variant: 'info' });
    let csvDataLink = '';
    if (type === 'csv') {
      csvDataLink = csvFile;
    } else {
      if (streamOptimizationDataAsCSV) {
        csvDataLink = streamOptimizationDataAsCSV;
      } else {
        try {
          const response = await axiosClient.post(generateCSVOptimizationFileFromStreamEndpoint, {
            name: name,
            streamName: streamName,
            defectDescriptionFieldNames: defectDescriptionFieldNames,
            kpi: kpi,
            timePeriod: timePeriod,
          });
          setStreamOptimizationDataAsCSV(response.data.streamOptimizationDataAsCSV);
          setLookupFileName(response.data.lookupFileName);
          csvDataLink = response.data.streamOptimizationDataAsCSV;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    closeSnackbar(snackbarKey);
    enqueueSnackbar(t('downloadFinished'), { variant: 'success' });

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', csvDataLink);
    linkElement.setAttribute('download', 'optimizationDataSet.csv');
    linkElement.click();

    // Clean up the temporary URL object
    URL.revokeObjectURL(linkElement.href);
  };

  const exampleColumns =
    examples.length > 0
      ? Object.keys(examples[0]).map((key, index, array) => ({
          field: key,
          headerName: key.replace('_kpi', ''),
          width: 150, // Adjust the width as needed
          flex: 1,
          headerClassName: index === array.length - 1 ? 'last-column-header' : '', // Add CSS class for last column header
        }))
      : [];

  const exampleRows = examples.map((example, exampleIndex) => ({
    id: exampleIndex,
    ...example,
  }));

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  }, [nextIsDisabled, activeStep]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !nextIsDisabled) {
      event.preventDefault();
      setActiveStep(activeStep + 1);
      updateStates();
    }
  };

  return (
    <div className="aiOptimizerAllSteps">
      <div className="container-back">
        <IconButton
          variant="qualitatio"
          squared={true}
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
          style={{ background: theme.palette.success.secondary, width: '48px', height: '48px' }}
        >
          <ArrowBackIosNewRoundedIcon color="primary" />
        </IconButton>
      </div>
      <div className="container-card">
        <div className="steps-overview">
          {activeStep - 1} {t('/of7Steps')}
        </div>
        <div className="mobile-stepper-container">
          <QualitatioMobileStepper
            totalSteps={8}
            activeStep={activeStep - 1}
            controllable={false}
          />
        </div>

        {activeStep === 2 && (
          <div className="step-1">
            <div className="name-the-model">{t('nameTheModel')}</div>
            <div className="model-name-input">
              <QualitatioInput
                label={t('nameOfDataSource')}
                value={name}
                onChange={(e) => setNameWithCheck(e.target.value)}
                width="100%"
                placeholder={t('enterDataSourceName')}
              />
              {nameError && <div className="error-message">{nameError}</div>}
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="step-2">
            <div className="select-data-source">{t('selectADataSource')}</div>
            <div className="csv-or-live">
              <QualitatioHighlightBox
                text={t('csvFile')}
                color="#1fbebc"
                icon={<FaFileCsv />}
                onClick={() => setType('csv')}
                border={
                  type === 'csv'
                    ? '1px solid rgba(31, 190, 188, 1)'
                    : '1px solid rgba(31, 190, 188, 0)'
                }
                opacity={type === 'csv' ? '1' : '0.5'}
                backgroundColor={
                  type === 'csv' ? 'rgba(31, 190, 188, 0.3)' : 'rgba(31, 190, 188, 0.1)'
                }
              />
              <QualitatioHighlightBox
                text={t('liveData')}
                color="#1fbebc"
                icon={<FaRegClock />}
                onClick={() => {
                  setType('live');
                  updateStartTime(startTime);
                }}
                border={
                  type === 'live'
                    ? '1px solid rgba(31, 190, 188, 1)'
                    : '1px solid rgba(31, 190, 188, 0)'
                }
                opacity={type === 'live' ? '1' : '0.5'}
                backgroundColor={
                  type === 'live' ? 'rgba(31, 190, 188, 0.3)' : 'rgba(31, 190, 188, 0.1)'
                }
              />
            </div>
            {type === 'live' && (
              <div className="date-picker">
                <div className="select-date">{t('selectDate')}</div>
                <QualitatioDateTimePicker
                  value={startTime}
                  onChange={(e) => updateStartTime(e.currentTarget.value)}
                  label={t('from')}
                />
                <QualitatioDateTimePicker
                  value={endTime}
                  onChange={(e) => updateEndTime(e.currentTarget.value)}
                  label={t('to')}
                />
              </div>
            )}
          </div>
        )}

        {activeStep === 4 && type === 'live' && (
          <div className="step-3-live">
            <div className="select-streams">{t('selectAStream')}</div>
            <QualitatioChipArray
              chipData={liveStreamData?.map((stream) => ({
                label: stream.streamName,
                icon:
                  streamName === stream.streamName ? (
                    <MdOutlineCheckBox />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank />
                  ),
                backgroundColor:
                  streamName === stream.streamName && theme.palette.success.secondary,
                onClick: () => updateStreamName(stream.streamName),
              }))}
              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
            />
          </div>
        )}

        {activeStep === 4 && type === 'csv' && (
          <div className="step-3">
            <div className="select-data-set">{t('selectADataSet')}</div>
            <div className="data-set-input">
              <QualitatioInput
                id="dataSourceFile"
                label={t('csvFile')}
                onChange={csvFileUploaded}
                type="file"
                inputProps={{ accept: '.csv' }}
              />
            </div>
          </div>
        )}

        {activeStep === 5 && (
          <div className="step-4">
            <div className="select-fields">{t('selectFields')}</div>
            <div className="list-legend">
              <QualitatioChipArray
                chipData={[
                  {
                    label: t('defectDescriptionField'),
                    icon: <MdOutlineCheckBox />,
                    backgroundColor: theme.palette.success.secondary,
                  },
                  {
                    label: t('kpi'),
                    icon: <MdOutlineCheckBox />,
                    backgroundColor: theme.palette.error.secondary,
                  },
                  {
                    label: t('notSelected'),
                    icon: <MdOutlineCheckBoxOutlineBlank />,
                    color: 'default',
                  },
                ]}
                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              />
            </div>
            <div className="divider-container">
              <Divider variant="middle" />
            </div>
            <QualitatioChipArray
              chipData={allFieldNames.map((fieldName, index) => ({
                label: fieldName,
                icon:
                  kpi.includes(fieldName) || defectDescriptionFieldNames.includes(fieldName) ? (
                    <MdOutlineCheckBox />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank />
                  ),
                backgroundColor: defectDescriptionFieldNames.includes(fieldName)
                  ? theme.palette.success.secondary
                  : kpi.includes(fieldName)
                    ? theme.palette.error.secondary
                    : 'default',
                onClick: () => updateDefectDescriptionFieldNamesAndKPI(fieldName),
              }))}
              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
            />

            <div className="feature-and-prediction-fields">
              <QualitatioTransferList
                nameLeft={t('feature')}
                nameRight={t('target')}
                left={defectDescriptionFieldNames}
                right={kpi}
                setLeft={setDefectDescriptionFieldNames}
                setRight={setKpi}
                isRightString={true}
              />
            </div>
          </div>
        )}

        {activeStep === 6 && (
          <div className="step-5">
            <div className="map-feature-fields">{t('mapDescriptionFieldsToDataFields')}</div>
            <div className="feature-field-mapping">
              {defectDescriptionFields?.map((field, index) => (
                <div key={field} className="grid-item">
                  <Chip
                    label={field}
                    icon={<MdOutlineCheckBox />}
                    sx={{ backgroundColor: theme.palette.success.secondary }}
                  />
                  <QualitatioSelect
                    value={
                      defectDescriptionFieldMapping.find(
                        (el) => el.defectDescriptionFieldName === field
                      )?.mappedFieldName || ''
                    }
                    onChange={(e) => updateDefectDescriptionMapping(field, e.target.value)}
                    optionProps={defectDescriptionFieldNames?.map((fieldName) => (
                      <MenuItem key={fieldName} value={fieldName} className="option">
                        {fieldName}
                      </MenuItem>
                    ))}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeStep === 7 && (
          <div className="step-6">
            <div className="define-priorization">{t('definePriorization')}</div>
            <div className="natural-ordering">
              <div className="natural-ordering-text">{t('naturalOrdering')}</div>
              <QualitatioChipArray
                chipData={[
                  {
                    label: t('ascending'),
                    icon: <FiArrowUp />,
                    backgroundColor: ordering === 'ascending' && theme.palette.success.secondary,
                    onClick: () => setOrdering('ascending'),
                  },
                  {
                    label: t('descending'),
                    icon: <FiArrowDown />,
                    backgroundColor: ordering === 'descending' && theme.palette.error.secondary,
                    onClick: () => setOrdering('descending'),
                  },
                ]}
                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              />
            </div>
            <div className="priority-of-kpi">
              <div className="priority-of-kpi-text">{t('priorityOfKPI')}</div>
              <QualitatioChipArray
                chipData={[
                  {
                    label: t('low'),
                    icon: <FiHexagon />,
                    backgroundColor: priorization === 'low' && theme.palette.success.secondary,
                    onClick: () => setPriorization('low'),
                  },
                  {
                    label: t('medium'),
                    icon: <FiCircle />,
                    backgroundColor: priorization === 'medium' && theme.palette.warning.main,
                    onClick: () => setPriorization('medium'),
                  },
                  {
                    label: t('high'),
                    icon: <FiTriangle />,
                    backgroundColor: priorization === 'high' && theme.palette.error.secondary,
                    onClick: () => setPriorization('high'),
                  },
                ]}
                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
              />
            </div>
            {type === 'live' && (
              <div className="keep-learning">
                <label className="keep-learning-text" htmlFor="keepLearning">
                  {t('keepLearning')}
                </label>
                <Switch
                  variant="qualitatio"
                  id="keepLearning"
                  checked={keepLearning}
                  onChange={() => setKeepLearning(!keepLearning)}
                />
              </div>
            )}
          </div>
        )}

        {activeStep === 8 && (
          <div className="step-8">
            <div className="check-model-settings">{t('checkModelSettings')}</div>
            <div className="model-settings">
              <QualitatioInput
                label={t('nameOfDataSource')}
                value={name}
                onChange={(e) => setNameWithCheck(e.target.value)}
                width="100%"
                placeholder={t('enterDataSourceName')}
              />
              <QualitatioChipArray
                chipData={[
                  {
                    label: type === 'csv' ? t('csvFile') : t('liveData'),
                    icon: type === 'csv' ? <FaFileCsv /> : <FaRegClock />,
                    backgroundColor: theme.palette.success.secondary,
                  },
                  {
                    label: t('downloadOptimizationDataSet'),
                    icon: <MdDownload />,
                    color: 'default',
                    onClick: () => {
                      downloadOptimizationDataSet();
                    },
                  },
                ]}
                justifyContent="left"
              />
              <QualitatioAccordion
                accordionData={[
                  {
                    title: t('optimizationInput'),
                    panel: 'panel1',
                    render: (
                      <div className="step-4-step-5-summary">
                        {type === 'live' && (
                          <div className="step-3-live-summary">
                            <div className="select-streams">{t('selectAStream')}</div>
                            <QualitatioChipArray
                              chipData={liveStreamData?.map((stream) => ({
                                label: stream.streamName,
                                icon:
                                  streamName === stream.streamName ? (
                                    <MdOutlineCheckBox />
                                  ) : (
                                    <MdOutlineCheckBoxOutlineBlank />
                                  ),
                                backgroundColor:
                                  streamName === stream.streamName &&
                                  theme.palette.success.secondary,
                                onClick: () => updateStreamName(stream.streamName),
                              }))}
                              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                            />
                          </div>
                        )}
                        {
                          <div className="step-4-summary">
                            <div className="select-fields">{t('selectFields')}</div>
                            <div className="list-legend">
                              <QualitatioChipArray
                                chipData={[
                                  {
                                    label: t('defectDescriptionField'),
                                    icon: <MdOutlineCheckBox />,
                                    backgroundColor: theme.palette.success.secondary,
                                  },
                                  {
                                    label: t('kpi'),
                                    icon: <MdOutlineCheckBox />,
                                    backgroundColor: theme.palette.error.secondary,
                                  },
                                  {
                                    label: t('notSelected'),
                                    icon: <MdOutlineCheckBoxOutlineBlank />,
                                    color: 'default',
                                  },
                                ]}
                                boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                              />
                            </div>
                            <div className="divider-container">
                              <Divider variant="middle" />
                            </div>
                            <QualitatioChipArray
                              chipData={allFieldNames.map((fieldName, index) => ({
                                label: fieldName,
                                icon:
                                  kpi.includes(fieldName) ||
                                  defectDescriptionFieldNames.includes(fieldName) ? (
                                    <MdOutlineCheckBox />
                                  ) : (
                                    <MdOutlineCheckBoxOutlineBlank />
                                  ),
                                backgroundColor: defectDescriptionFieldNames.includes(fieldName)
                                  ? theme.palette.success.secondary
                                  : kpi.includes(fieldName)
                                    ? theme.palette.error.secondary
                                    : 'default',
                                onClick: () => updateDefectDescriptionFieldNamesAndKPI(fieldName),
                              }))}
                              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                            />

                            <div className="feature-and-prediction-fields">
                              <QualitatioTransferList
                                nameLeft={t('feature')}
                                nameRight={t('target')}
                                left={defectDescriptionFieldNames}
                                right={kpi}
                                setLeft={setDefectDescriptionFieldNames}
                                setRight={setKpi}
                                isRightString={true}
                              />
                            </div>
                          </div>
                        }
                        {
                          <div className="step-5-summary">
                            <div className="map-feature-fields">
                              {t('mapDescriptionFieldsToDataFields')}
                            </div>
                            <div className="feature-field-mapping">
                              {defectDescriptionFields?.map((field, index) => (
                                <div key={field} className="grid-item">
                                  <Chip
                                    label={field}
                                    icon={<MdOutlineCheckBox />}
                                    sx={{ backgroundColor: theme.palette.success.secondary }}
                                  />
                                  <QualitatioSelect
                                    value={
                                      defectDescriptionFieldMapping.find(
                                        (el) => el.defectDescriptionFieldName === field
                                      )?.mappedFieldName || ''
                                    }
                                    onChange={(e) =>
                                      updateDefectDescriptionMapping(field, e.target.value)
                                    }
                                    optionProps={defectDescriptionFieldNames?.map((fieldName) => (
                                      <MenuItem
                                        key={fieldName}
                                        value={fieldName}
                                        className="option"
                                      >
                                        {fieldName}
                                      </MenuItem>
                                    ))}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      </div>
                    ),
                  },
                  {
                    title: t('optimizationCriteria'),
                    panel: 'panel2',
                    render: (
                      <div className="step-6-summary">
                        <div className="define-priorization">{t('definePriorization')}</div>
                        <div className="natural-ordering">
                          <div className="natural-ordering-text">{t('naturalOrdering')}</div>
                          <QualitatioChipArray
                            chipData={[
                              {
                                label: t('ascending'),
                                icon: <FiArrowUp />,
                                backgroundColor:
                                  ordering === 'ascending' && theme.palette.success.secondary,
                                onClick: () => setOrdering('ascending'),
                              },
                              {
                                label: t('descending'),
                                icon: <FiArrowDown />,
                                backgroundColor:
                                  ordering === 'descending' && theme.palette.error.secondary,
                                onClick: () => setOrdering('descending'),
                              },
                            ]}
                            boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                          />
                        </div>
                        <div className="priority-of-kpi">
                          <div className="priority-of-kpi-text">{t('priorityOfKPI')}</div>
                          <QualitatioChipArray
                            chipData={[
                              {
                                label: t('low'),
                                icon: <FiHexagon />,
                                backgroundColor:
                                  priorization === 'low' && theme.palette.success.secondary,
                                onClick: () => setPriorization('low'),
                              },
                              {
                                label: t('medium'),
                                icon: <FiCircle />,
                                backgroundColor:
                                  priorization === 'medium' && theme.palette.warning.main,
                                onClick: () => setPriorization('medium'),
                              },
                              {
                                label: t('high'),
                                icon: <FiTriangle />,
                                backgroundColor:
                                  priorization === 'high' && theme.palette.error.secondary,
                                onClick: () => setPriorization('high'),
                              },
                            ]}
                            boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                          />
                        </div>
                        {type === 'live' && (
                          <div className="keep-learning">
                            <label className="keep-learning-text" htmlFor="keepLearning">
                              {t('keepLearning')}
                            </label>
                            <Switch
                              variant="qualitatio"
                              id="keepLearning"
                              checked={keepLearning}
                              onChange={() => setKeepLearning(!keepLearning)}
                            />
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    title: t('optimizationOther'),
                    panel: 'panel3',
                    render: (
                      <div className="step-7-summary">
                        <QualitatioTable
                          columns={exampleColumns}
                          rows={exampleRows}
                          width="100%"
                          checkboxSelection={false}
                          disableRowSelectionOnClick={true}
                        />
                      </div>
                    ),
                  },
                ]}
              />
              {
                <div className="is-active">
                  <label className="is-active-text" htmlFor="isActive">
                    {t('active')}
                  </label>
                  <Switch
                    variant="qualitatio"
                    id="isActive"
                    checked={active}
                    onChange={() => setActive(!active)}
                  />
                </div>
              }
              <QualitatioButton
                text={t('saveOptimizationDataSource')}
                width="30%"
                onClick={() => {
                  handleSaveNewOptimizationData();
                }}
                startIcon={<BiSave />}
                startIconIsImage={false}
                startIconRounded={false}
              />
            </div>
          </div>
        )}
      </div>
      <div className="container-next">
        <IconButton
          variant="qualitatio"
          squared={true}
          disabled={nextIsDisabled}
          onClick={() => {
            setActiveStep(activeStep + 1);
            updateStates();
          }}
          style={{ background: theme.palette.success.secondary, width: '48px', height: '48px' }}
        >
          <ArrowForwardIosRoundedIcon color="primary" />
        </IconButton>
      </div>
    </div>
  );
}
