import {
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axiosClient from '../../../api/axiosClient';
import { saveUserProfileImageEndpoint, usersEndpoint } from '../../../api/endpoints';
import { QualitatioButton, QualitatioLanguageSelector } from '../../../components';
import { Avatar } from '../../../components/Avatar';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioInput from '../../../components/QualitatioInput/QualitatioInput';
import { useAuthStore } from '../../../store/auth.store';
import Menu from '../Menu';
import './Account.css';

export default function Account({ setPageName, recommenderMenuOpen, setRecommenderMenuOpen }) {
  const { t } = useTranslation();
  setPageName(t('account'));

  const { user, setUser } = useAuthStore((state) => ({ user: state.user, setUser: state.setUser }));

  const { enqueueSnackbar } = useSnackbar();

  // avatar click
  const avatarRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const avatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCameraState(false);
  };

  // upload profile image
  const [deleteProfileImage, setDeleteProfileImage] = useState(false);

  const fileInputRef = useRef(null);

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
    setAnchorEl(null);
  };

  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue('previewImage', reader.result);
      };
      reader.readAsDataURL(file);

      formik.setFieldValue('profileImageFile', file);
    }
  };

  //take profile photo
  const [cameraState, setCameraState] = useState(false);

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadPhoto = (imageSrc) => {
    formik.setFieldValue('previewImage', imageSrc);
    formik.setFieldValue('profileImage', imageSrc);

    // Convert data URL to a File object
    const imageFile = dataURLtoFile(imageSrc, 'captured_image.png');
    formik.setFieldValue('profileImageFile', imageFile);
  };

  const capturePhoto = async (imageSrc) => {
    setCameraState(false);
    setAnchorEl(null);
    try {
      uploadPhoto(imageSrc);
    } catch (error) {
      console.error(t('errorCapturingCameraImage'), error);
    }
  };

  // edit language
  const [language, setLanguage] = useState(user.language);

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalidEmailAddress')).required(t('required')),
    password: Yup.string()
      .min(8, t('passwordMustBe8CharactersOrMore'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()_\-+=<>?{}[\]|/\\:;"',.]{8,}$/,
        t('passwordMustContainAtLeastOneLetterAndOneNumber')
      )
      .notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      name: user.name || '',
      email: user.email || '',
      password: '',
      role: user.role || '',
      authSystem: user.authSystem || '',
      profileImage: user.profileImage || null, // Add this line
      previewImage: null,
      profileImageFile: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleProfileUpdate(values);
    },
  });

  const handleProfileUpdate = async (values) => {
    const { role, authSystem, profileImageFile, previewImage, ...payload } = values;

    // If both previewImage and profileImage are null, set profileImage in payload to empty string
    if (deleteProfileImage) {
      payload.profileImage = '';
    } else if (!deleteProfileImage && !previewImage && !profileImageFile) {
      delete payload.profileImage;
    }
    // If there's a profileImageFile, upload it
    if (profileImageFile) {
      const formData = new FormData();
      formData.append('file', profileImageFile);
      formData.append('user', user.id);

      try {
        const response = await axiosClient.post(saveUserProfileImageEndpoint, formData);
        formik.setFieldValue('profileImage', response.data.userProfileImageURL);
        console.log(response.data);
        payload.profileImage = response.data.userProfileImageURL;
        enqueueSnackbar(t('profileImageUpdated'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('errorUploadingImage'), { variant: 'error' });
      }
    }

    if (payload.password === '') {
      delete payload.password;
    }
    const patchEndpoint = usersEndpoint + '/' + user.id;

    payload.language = language;

    try {
      const response = await axiosClient.patch(patchEndpoint, payload);
      setUser(response.data);

      if (response.status === 200) {
        enqueueSnackbar(t('profileUpdated'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('updateFailed'), { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('errorUpdatingProfile'), { variant: 'error' });
    }
  };

  return (
    <>
      {recommenderMenuOpen ? (
        !cameraState ? (
          <Grid
            container
            display="flex"
            justifyContent="center"
            flexDirection="column"
            padding="30px 2% 0 2%"
            gap={2}
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-around"
              flexDirection="row"
              alignItems="center"
            >
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {t('workersAccount')}
              </Typography>
              <Avatar
                ref={avatarRef}
                profileImage={formik.values.previewImage || formik.values.profileImage}
                onClick={avatarClick}
                cursor="pointer"
              />
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <List component="nav">
                  <ListItem button onClick={() => handleImageUploadClick()}>
                    <ListItemText primary={t('uploadImage')} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setDeleteProfileImage(true);
                      formik.setFieldValue('profileImage', null);
                      formik.setFieldValue('previewImage', null);
                      formik.setFieldValue('profileImageFile', null);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemText primary={t('deleteProfileImage')} />
                  </ListItem>
                  <ListItem button onClick={() => setCameraState(!cameraState)}>
                    <ListItemText primary={t('takeProfileImage')} />
                  </ListItem>
                </List>
              </Popover>
              <input
                ref={fileInputRef}
                type="file"
                id="avatar-input"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleImageSelection}
              />
            </Grid>
            <FormControl>
              <Grid container display="flex" justifyContent="center" flexDirection="column" gap={2}>
                <Grid item xs={12}>
                  <QualitatioInput
                    label={t('name')}
                    value={formik.values.name}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    readOnly={user.authSystem !== 'password'}
                    width={'100%'}
                    {...formik.getFieldProps('name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <QualitatioInput
                    label={t('email')}
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    readOnly={user.authSystem !== 'password'}
                    width={'100%'}
                    {...formik.getFieldProps('email')}
                  />
                </Grid>
                {user.authSystem === 'password' && (
                  <Grid item xs={12}>
                    <QualitatioInput
                      label={t('password')}
                      value={formik.values.password}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      width={'100%'}
                      type="password"
                      showInput={showPassword}
                      toggleShowInput={() => setShowPassword(!showPassword)}
                      {...formik.getFieldProps('password')}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <QualitatioLanguageSelector
                    value={language}
                    onChange={(event, newValue) => {
                      setLanguage(newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <QualitatioButton text={t('save')} onClick={formik.handleSubmit} width={'100%'} />
                </Grid>
              </Grid>
            </FormControl>
            <Grid
              container
              spacing={2}
              style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 100 }}
            >
              <BottomBarWhite />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Camera
              onTakePhoto={(dataUri) => {
                capturePhoto(dataUri);
              }}
              idealFacingMode={'user'}
              isFullscreen={true}
            />
          </Grid>
        )
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}
