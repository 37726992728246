import { Chip, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export default function QualitatioDragAndDrop({ fields = [], setFields, columns = [] }) {
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceColumn = columns.find((column) => column.name === source.droppableId);
    const destColumn = columns.find((column) => column.name === destination.droppableId);

    // Clone the source fields
    let newSourceFields = sourceColumn ? [...sourceColumn.fields] : [...fields];
    // Remove the item from the source
    const [removed] = newSourceFields.splice(source.index, 1);

    let newDestFields;

    // If source and destination columns are the same, we've already removed the item
    if (source.droppableId === destination.droppableId) {
      newDestFields = newSourceFields;
    } else {
      // Clone the destination fields if it's a different column
      newDestFields = destColumn ? [...destColumn.fields] : [...fields];
    }

    // Insert the item into the destination
    newDestFields.splice(destination.index, 0, removed);

    // Update the state
    if (!sourceColumn && !destColumn) {
      // Moving within the main fields list
      setFields(newDestFields);
    } else {
      // Moving from a column to another column or to/from the main fields list
      if (sourceColumn) {
        sourceColumn.setFields(newSourceFields);
      } else {
        // We moved from the main fields list to a column
        setFields(newSourceFields);
      }
      if (destColumn) {
        destColumn.setFields(newDestFields);
      } else {
        // We moved from a column to the main fields list
        setFields(newDestFields);
      }
    }
  };

  const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
      const animation = requestAnimationFrame(() => setEnabled(true));
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, []);
    if (!enabled) {
      return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2} display="flex" alignItems="center" direction="column">
        <Grid item display="flex" justifyContent="center" alignItems="center" xs={12} width="100%">
          <StrictModeDroppable droppableId="fields" direction="horizontal">
            {(provided) => (
              <Paper
                className="QualitatioDragAndDrop"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minWidth: '400px',
                  width: '100%',
                  minHeight: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '8px',
                  padding: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {fields.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Chip
                          label={item}
                          {...provided.dragHandleProps}
                          style={{
                            margin: snapshot.isDragging ? '0' : '8px',
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Paper>
            )}
          </StrictModeDroppable>
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          direction="row"
          width="100%"
        >
          {columns.map((column, colIndex) => (
            <StrictModeDroppable droppableId={column.name} key={column.name}>
              {(provided) => (
                <Paper
                  className="QualitatioDragAndDrop"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    minHeight: '500px',
                    minWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {column.name}
                  </Typography>
                  {column.fields.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <Chip
                            label={item}
                            {...provided.dragHandleProps}
                            style={{
                              margin: snapshot.isDragging ? '0' : '8px',
                              backgroundColor: column.color,
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Paper>
              )}
            </StrictModeDroppable>
          ))}
        </Grid>
      </Grid>
    </DragDropContext>
  );
}
