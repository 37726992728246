// EventTests.js
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../../api/axiosClient';
import { eventCardEndpoint } from '../../../../api/endpoints';
import EventTestCards from './EventTestCards';
import Dialogs from './Dialogs';
import EventHeader from './EventHeader';
import EventTable from './EventTable';
import styles from './EventTests.module.css';

const EventTests = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  setEventTestsGroundTruth,
  eventTestsGroundTruth,
  eventTests,
  setEventTests,
  qualityStation,
  orderIdentifierMapping,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  getEventTests,
  selectedLiveStreamData,
  events,
  restoreChange,
}) => {
  const [filteredEventData, setFilteredEventData] = useState([]);
  const [explanation, setExplanation] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filterOption, setFilterOption] = useState('all'); // New state for filter option
  const [discardWarningOpen, setDiscardWarningOpen] = useState(false);
  const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
  const [discardChangesWarningOpen, setDiscardChangesWarningOpen] = useState(-1);

  const [eventDataWithId, setEventDataWithId] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredEventData(events);
  }, [events]);

  useEffect(() => {
    if (filteredEventData[currentIndex] && selectedLiveStreamData) {
      const selectedStream = selectedLiveStreamData.find(
        (stream) => stream.streamName === filteredEventData[currentIndex][0]
      );

      if (selectedStream) {
        const explanationParts = selectedStream.fieldNames.map(
          (fieldName, index) => filteredEventData[currentIndex][index + 1]
        );
        setExplanation(explanationParts.filter((part) => part).join(' - '));
      }
    }
  }, [currentIndex, filteredEventData, events, selectedLiveStreamData, filterOption]);

  const deleteCard = async () => {
    const currentEventExplanation = explanation;
    const currentEventExplanationParts = new Set(currentEventExplanation.split(' - '));
    const matchingCards = eventTests.filter((test) => {
      const testExplanationParts = new Set(test.explanation.split(' - '));
      return [...testExplanationParts].every((part) => currentEventExplanationParts.has(part));
    });

    const indexInSubset = matchingCards.findIndex((_, index) => index === currentCardIndex);
    const cardToDelete = matchingCards[indexInSubset];
    matchingCards.splice(indexInSubset, 1);

    const updatedEventTests = eventTests
      .map((test) => {
        const testExplanationParts = new Set(test.explanation.split(' - '));
        if ([...testExplanationParts].every((part) => currentEventExplanationParts.has(part))) {
          return matchingCards.shift();
        }
        return test;
      })
      .filter(Boolean);

    if (cardToDelete.id) {
      const updatedEventTestsGroundTruth = eventTestsGroundTruth.filter(
        (test) => test.id !== cardToDelete.id
      );
      try {
        await axiosClient.delete(eventCardEndpoint, {
          data: {
            cardID: cardToDelete.id,
          },
        });
        enqueueSnackbar(t('eventBasedTestingDeletedSuccessfully'), { variant: 'success' });
        setEventTestsGroundTruth(updatedEventTestsGroundTruth);
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingDynamicTestProposal'), { variant: 'error' });
        console.error('Error:', error);
      }
    }

    setEventTests(updatedEventTests);
    setCurrentCardIndex(currentCardIndex > 0 ? currentCardIndex - 1 : 0);
  };

  const discardChanges = () => {
    setEventTests(eventTestsGroundTruth);
    setCurrentIndex(discardChangesWarningOpen);
    setCurrentCardIndex(0);
  };

  return (
    <>
      <div className={styles.eventsWrapper}>
        <div className={styles.events}>
          <EventHeader
            eventState={{
              filterOption,
              setFilterOption,
              startTime,
              setStartTime,
              endTime,
              setEndTime,
              eventTestsGroundTruth,
              eventTests,
              updateEventTestsData: getEventTests,
              setDiscardWarningOpen,
            }}
            eventData={{
              events,
              setFilteredEventData,
              selectedLiveStreamData,
            }}
          />
          <EventTable
            tableState={{
              currentIndex,
              setCurrentIndex,
              setCurrentCardIndex,
              eventTestsGroundTruth,
              eventTests,
              setDiscardChangesWarningOpen,
              setSelectedEvent,
              setEventDataWithId,
            }}
            tableData={{
              selectedLiveStreamData,
              filteredEventData,
              eventDataWithId,
            }}
          />
        </div>
        <div className={styles.cards}>
          <EventTestCards
            currentIndex={currentIndex}
            currentCardIndex={currentCardIndex}
            setCurrentCardIndex={setCurrentCardIndex}
            setEventTestsGroundTruth={setEventTestsGroundTruth}
            eventTestsGroundTruth={eventTestsGroundTruth}
            eventTests={eventTests}
            setEventTests={setEventTests}
            qualityStation={qualityStation}
            orderIdentifierMapping={orderIdentifierMapping}
            selectedLiveStreamData={selectedLiveStreamData}
            events={events}
            restoreChange={restoreChange}
            filteredEventData={filteredEventData}
            explanation={explanation}
            selectedEvent={selectedEvent}
            setDeleteCardWarningOpen={setDeleteCardWarningOpen}
            eventDataWithId={eventDataWithId}
          />
        </div>
      </div>
      <Dialogs
        discardWarningDialog={{
          discardWarningOpen,
          setDiscardWarningOpen,
          updateEventTestsData: getEventTests,
        }}
        deleteCardWarningDialog={{
          deleteCardWarningOpen,
          setDeleteCardWarningOpen,
          deleteCard,
        }}
        discardChangesWarningDialog={{
          discardChangesWarningOpen,
          setDiscardChangesWarningOpen,
          discardChanges,
        }}
      />
    </>
  );
};

EventTests.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  setEventTestsGroundTruth: PropTypes.func.isRequired,
  eventTestsGroundTruth: PropTypes.array.isRequired,
  eventTests: PropTypes.array.isRequired,
  setEventTests: PropTypes.func.isRequired,
  qualityStation: PropTypes.object.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  startTime: PropTypes.string.isRequired,
  setStartTime: PropTypes.func.isRequired,
  endTime: PropTypes.string.isRequired,
  setEndTime: PropTypes.func.isRequired,
  getEventTests: PropTypes.func.isRequired,
  selectedLiveStreamData: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  restoreChange: PropTypes.func.isRequired,
};

export default EventTests;
