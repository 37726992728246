import React, { forwardRef } from 'react';
import './style.css';

export const Avatar = forwardRef(({ profileImage, cursor = 'default', onClick }, ref) => {
  return (
    <div className="avatar" onClick={onClick} ref={ref}>
      <div className="avatar-wrapper">
        <img
          className="img-3"
          alt="Avatar"
          src={profileImage || '/images/avatar.png'}
          style={{ cursor: cursor }}
        />
      </div>
    </div>
  );
});
