import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../components/TooltipWrapper';
import { oAuthSettingsInitialValues } from '../../../utils/initFormikValues';
import { oAuthValidationSchema } from '../../../utils/validationSchemas';
import styles from './OAuthSettingsDialog.module.css';

/**
 * Renders a dialog for OAuth provider settings, allowing the user to configure or edit OAuth provider details.
 * It utilizes Formik for form state management and validation based on predefined schemas.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Indicates if the dialog is open.
 * @param {Function} props.onClose - Function to call on closing the dialog.
 * @param {Object|null} props.provider - The provider details to be edited; null for new providers.
 * @param {Function} props.onSave - Function to call when form is submitted.
 * @param {Function} props.onSecretChange - Function to call when the client secret changes.
 */
const OAuthProviderDialog = ({ open, onClose, provider, onSave, onSecretChange }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: provider || oAuthSettingsInitialValues(),
    validationSchema: oAuthValidationSchema,
    onSubmit: (values) => {
      onSave(values, !!provider);
      onClose();
    },
    enableReinitialize: true,
  });

  const addItem = (fieldPath, newItem) => {
    const list = _.get(formik.values, fieldPath);
    list.push(newItem);
    formik.setFieldValue(fieldPath, list);
  };

  const removeItem = (fieldPath, key) => {
    const list = _.get(formik.values, fieldPath);
    //remove the item with the key from the list
    list.splice(
      list.findIndex((item) => item.key === key),
      1,
    );

    formik.setFieldValue(fieldPath, list);
  };

  return (
    <Dialog open={open} onClose={onClose} variant="qualitatio">
      <DialogTitle>
        {provider
          ? t('oauth.editProvider', { provider: provider.providerName })
          : t('oauth.addProvider')}
      </DialogTitle>
      <Typography variant="subtitle1" align="center">
        {
          provider ? t('oauth.editProviderSubtitle') : t('oauth.addProviderSubtitle') // Ensure the description is correct
        }
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    name="active"
                  />
                }
                label={
                  formik.values.active ? t('active') : t('inactive') // Ensure the label is correct
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="providerName"
                name="providerName"
                label={t('oauth.providerName')}
                placeholder={t('oauth.providerNamePlaceholder')}
                {...formik.getFieldProps('providerName')} // This replaces value and onChange
                error={formik.touched.providerName && Boolean(formik.errors.providerName)}
                helperText={formik.touched.providerName && formik.errors.providerName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.providerNameHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="clientID"
                name="clientID"
                label={t('oauth.clientID')}
                placeholder={t('oauth.clientIDPlaceholder')}
                {...formik.getFieldProps('clientID')}
                error={formik.touched.clientID && Boolean(formik.errors.clientID)}
                helperText={formik.touched.clientID && formik.errors.clientID}
                autocomplete="new-clientID"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.clientIDHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="clientSecret"
                name="clientSecret"
                label={t('oauth.clientSecret')}
                placeholder={t('oauth.clientSecretPlaceholder')}
                type="password" // Consider using type="password" for better security
                onChange={(event) => {
                  formik.setFieldValue('clientSecret', event.target.value);
                  onSecretChange(event.target.value);
                }}
                error={formik.touched.clientSecret && Boolean(formik.errors.clientSecret)}
                helperText={
                  (formik.touched.clientSecret && formik.errors.clientSecret) ||
                  'Leave blank if unchanged'
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.clientSecretHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="authorizationURL"
                name="authorizationURL"
                label={t('oauth.authorizationURL')}
                placeholder={t('oauth.authorizationURLPlaceholder')}
                {...formik.getFieldProps('authorizationURL')} // This replaces value and onChange
                error={formik.touched.authorizationURL && Boolean(formik.errors.authorizationURL)}
                helperText={formik.touched.authorizationURL && formik.errors.authorizationURL}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.authorizationURLHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="tokenExchangeURL"
                name="tokenExchangeURL"
                label={t('oauth.tokenExchangeURL')}
                placeholder={t('oauth.tokenExchangeURLPlaceholder')}
                {...formik.getFieldProps('tokenExchangeURL')} // This replaces value and onChange
                error={formik.touched.tokenExchangeURL && Boolean(formik.errors.tokenExchangeURL)}
                helperText={formik.touched.tokenExchangeURL && formik.errors.tokenExchangeURL}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.tokenExchangeURLHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="userInformation.url"
                name="userInformation.url"
                label={t('oauth.userInformationURL')}
                placeholder={t('oauth.userInformationURLPlaceholder')}
                {...formik.getFieldProps('userInformation.url')}
                error={
                  formik.touched.userInformation?.url && Boolean(formik.errors.userInformation?.url)
                }
                helperText={
                  formik.touched.userInformation?.url && formik.errors.userInformation?.url
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InfoTooltip description={t('oauth.userInformationURLHelperText')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                id="userInformation.method"
                name="userInformation.method"
                label={t('oauth.userInformationMethod')}
                {...formik.getFieldProps('userInformation.method')}
                error={
                  formik.touched.userInformation?.method &&
                  Boolean(formik.errors.userInformation?.method)
                }
                helperText={
                  formik.touched.userInformation?.method && formik.errors.userInformation?.method
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: '12px' }}>
                      <InfoTooltip description={t('oauth.userInformationMethodHelperText')} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="get">GET</MenuItem>
                <MenuItem value="post">POST</MenuItem>
              </TextField>
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Grid item>
                <Typography variant="h4">{t('oauth.scopes')}</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip description={t('oauth.scopesHelperText')} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {formik.values?.scopes?.map((scope, index) => (
                <Box mb={2} key={'scopes_' + index}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        id={`scopes.${index}`}
                        name={`scopes.${index}`}
                        placeholder={t('oauth.scopesPlaceholder')}
                        value={scope}
                        onChange={formik.handleChange}
                        error={formik.touched.scopes && Boolean(formik.errors.scopes)}
                        helperText={formik.touched.scopes && formik.errors.scopes}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeItem('scopes', index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Grid container justifyContent="center">
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => addItem('scopes', '')}
                  variant="qualitatio"
                  className={styles.addButton}
                >
                  {t('oauth.addScopeButton')}
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Grid item>
                <Typography variant="h4">{t('oauth.userInformationHeaders')}</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip description={t('oauth.userInformationHeadersHelperText')} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {formik.values.userInformation?.headers?.map((header, index) => (
                <Box mb={2} key={'headers' + index}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.headers.${index}.key`}
                        name={`userInformation.headers.${index}.key`}
                        label="Key"
                        {...formik.getFieldProps(`userInformation.headers.${index}.key`)}
                        error={
                          formik.touched.userInformation?.headers &&
                          Boolean(formik.errors.userInformation?.headers?.[index]?.key) // Ensure the error path is correct
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.headers.${index}.value`}
                        name={`userInformation.headers.${index}.value`}
                        label="Value"
                        {...formik.getFieldProps(`userInformation.headers.${index}.value`)}
                        error={
                          formik.touched.userInformation?.headers &&
                          Boolean(formik.errors.userInformation?.headers?.[index]?.value) // Ensure the error path is correct
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeItem('userInformation.headers', index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Grid container justifyContent="center" className={styles.buttonGrid}>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => addItem('userInformation.headers', { key: '', value: '' })}
                  variant="qualitatio"
                  className={styles.addButton}
                >
                  {t('oauth.addHeaderButton')}
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Grid item>
                <Typography variant="h4">Mapping</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip description={t('oauth.userInformationMappingHelperText')} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {formik.values.userInformation?.mapping?.map((obj, index) => (
                <Box mb={2} key={'mapping' + index}>
                  {' '}
                  {/* Add Box for consistent margin */}
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.mapping.${index}.key`}
                        name={`userInformation.mapping.${index}.key`}
                        label={t('oauth.key')}
                        {...formik.getFieldProps(`userInformation.mapping.${index}.key`)}
                        InputProps={{
                          readOnly:
                            obj.key === 'email' || obj.key === 'name' || obj.key === 'groups',
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.mapping.${index}.value`}
                        name={`userInformation.mapping.${index}.value`}
                        label={t('oauth.value')}
                        {...formik.getFieldProps(`userInformation.mapping.${index}.value`)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {!(obj.key === 'email' || obj.key === 'name' || obj.key === 'groups') && (
                        <IconButton onClick={() => removeItem('userInformation.mapping', obj.key)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Grid container justifyContent="center" className={styles.buttonGrid}>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => addItem('userInformation.mapping', { key: '', value: '' })}
                  variant="qualitatio"
                  className={styles.addButton}
                >
                  {t('oauth.addMappingButton')}
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Grid item>
                <Typography variant="h4">Params</Typography>
              </Grid>
              <Grid item>
                <InfoTooltip description={t('oauth.userInformationParamsHelperText')} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {formik.values.userInformation?.params?.map((obj, index) => (
                <Box mb={2} key={'params' + index}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.params.${index}.key`}
                        name={`userInformation.params.${index}.key`}
                        label={t('oauth.key')}
                        {...formik.getFieldProps(`userInformation.params.${index}.key`)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id={`userInformation.params.${index}.value`}
                        name={`userInformation.params.${index}.value`}
                        label={t('oauth.value')}
                        {...formik.getFieldProps(`userInformation.params.${index}.value`)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeItem('userInformation.params', obj.key)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Grid container justifyContent="center" className={styles.buttonGrid}>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => addItem('userInformation.params', { key: '', value: '' })}
                  variant="qualitatio"
                  className={styles.addButton}
                >
                  {t('oauth.addParameterButton')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="qualitatio"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('save')}
          </Button>
          <Button
            onClick={onClose}
            variant="qualitatio"
            color="secondary"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

OAuthProviderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  provider: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onSecretChange: PropTypes.func.isRequired,
};
OAuthProviderDialog.defaultProps = {
  provider: null, // Assuming 'provider' can be null if not provided
};

export default OAuthProviderDialog;
