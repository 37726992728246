import { Close, Search } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QualitatioChipDropdown from '../../../../components/QualitatioChipDropdown/QualitatioChipDropdown';

export default function CreateRuleModalSidebar({
  sidebarOpen,
  setSidebarOpen,
  groupedSidebarData,
  streamFields,
  setLoading,
  loading,
}) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGroupedFields, setFilteredGroupedFields] = useState({});
  const [groupedValuesMap, setGroupedValuesMap] = useState({});

  // Define chip colors based on type
  const chipColors = {
    value: 'primary', // Blue
    aggregation: 'secondary', // Green
    valueCombination: 'error', // Red
  };

  // Handle search functionality
  useEffect(() => {
    const processStreamFields = () => {
      const result = {};

      // Populate result with values from streamFields
      streamFields.reduce((acc, streamField) => {
        const { indexName, name, examples } = streamField;
        const columnMatches = name.toLowerCase().includes(searchTerm.toLowerCase());
        const exampleMatches = examples.some((example) =>
          example.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (columnMatches || exampleMatches) {
          if (!acc[indexName]) {
            acc[indexName] = [];
          }
          acc[indexName].push({ column: name, options: [] });
        }
        return acc;
      }, result);

      setFilteredGroupedFields(result);
    };

    setTimeout(processStreamFields, 0); // Keep async behavior
  }, [streamFields, searchTerm]);

  // Effect for processing groupedSidebarData to populate groupedValuesMap
  useEffect(() => {
    const processGroupedSidebarData = () => {
      const map = {};

      groupedSidebarData.forEach((el) => {
        const key = `${el.indexName}-${el.column}`;
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push({
          label: el.value,
          value: el.value,
          color: chipColors[el.type],
          notDeletable: true,
        });
      });

      setGroupedValuesMap(map);
      if (loading) {
        setSidebarOpen(true);
        setLoading(false); // Notify parent that loading is complete
      }
    };
    setTimeout(processGroupedSidebarData, 0); // Keep async behavior
  }, [groupedSidebarData, loading]);

  const getAsyncOptions = (field, indexName) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Find the correct field and return its examples in { label, value } format
        const fieldData = streamFields.find(
          (streamField) => streamField.name === field && streamField.indexName === indexName
        );
        if (fieldData && fieldData.examples) {
          resolve(fieldData.examples.map((example) => ({ label: example, value: example })));
        } else {
          resolve([]); // Resolve with an empty array if no data is found
        }
      }, 300); // Adjust the delay as needed
    });
  };

  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        transition: 'all 0.3s ease',
      }}
    >
      {/* Header with Toggle Button */}
      {sidebarOpen && (
        <>
          <Grid
            item
            display="flex"
            justifyContent={sidebarOpen ? 'space-between' : 'center'}
            alignItems="center"
            direction="row"
            padding={1}
          >
            <Typography variant="h3" gutterBottom>
              {t('columnSearch')}
            </Typography>

            <IconButton onClick={handleToggle}>
              <MenuIcon />
            </IconButton>
          </Grid>

          {/* Search Bar */}
          <Grid item paddingX={1} paddingBottom={2}>
            <TextField
              id="search"
              label={t('search')}
              variant="standard"
              size="small"
              fullWidth
              InputProps={{
                endAdornment:
                  searchTerm === '' ? (
                    <Search />
                  ) : (
                    <IconButton onClick={() => setSearchTerm('')}>
                      <Close />
                    </IconButton>
                  ),
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>

          {/* Legend */}
          <Grid item padding={1} gap={2} display={'flex'} flexDirection={'row'}>
            <Typography variant="h6">{t('Legend')}</Typography>
            <Grid container spacing={1}>
              {Object.entries(chipColors).map(([type, color]) => (
                <Grid item key={type}>
                  <Chip label={t(type)} color={color} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Columns List */}
          <Grid item display="flex" flexDirection="column" gap={2} height="45vh" overflow="auto">
            {Object.keys(filteredGroupedFields).map((indexName, i) => (
              <React.Fragment key={indexName}>
                {/* Group Label */}
                {i !== 0 && <Divider />}
                <ListSubheader
                  sx={{
                    zIndex: 3,
                  }}
                >
                  {indexName}
                </ListSubheader>
                {/* Fields in this group */}
                {filteredGroupedFields[indexName].map(({ column, options }) => {
                  return (
                    <QualitatioChipDropdown
                      key={`${indexName}-${column}`}
                      label={column}
                      value={groupedValuesMap[`${indexName}-${column}`] || []}
                      options={options.map((option) => ({ label: option, value: option }))}
                      asyncOptions={() => getAsyncOptions(column, indexName)}
                      onChange={() => {}}
                      multiple
                      width="100%"
                      filterSelectedOptions
                      disableCloseOnSelect
                      limitTags={2}
                      getOptionDisabled={() => true}
                    />
                  );
                })}
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

CreateRuleModalSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
  groupedSidebarData: PropTypes.array.isRequired,
  streamFields: PropTypes.array.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
