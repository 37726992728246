import {
    Autocomplete,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from 'react-i18next';


export default function KafkaSubscriptionForm({ formik,
    readonly }) {

    /**
     * This is the structure of formik.values:
     *  {
        name: '',
        stream: '',
        type: 'kafka',
        active: true,
        connectionDetails: {
            topic: '',
            format: 'json',
            updateFrequency: 15,
        }
    },
     */
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
                <Typography variant="h5" align="left">
                    {t("kafkaConnectionProperties")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {/* FIRST PAGE */}
                <FormControl required fullWidth>
                    <TextField
                        {...formik.getFieldProps('connectionDetails.topic')}
                        label="Topic"
                        placeholder="Topic"
                        error={formik.touched.connectionDetails?.topic && Boolean(formik.errors.connectionDetails?.topic)}
                        helperText={formik.touched.connectionDetails?.topic && formik.errors.connectionDetails?.topic}
                        fullWidth
                        disabled={readonly}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl required fullWidth>
                    <Autocomplete
                        {...formik.getFieldProps('connectionDetails.format')}
                        options={['json']}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Format"
                                placeholder="Format"
                                error={formik.touched.connectionDetails?.format && Boolean(formik.errors.connectionDetails?.format)}
                                helperText={formik.touched.connectionDetails?.format && formik.errors.connectionDetails?.format}
                            />
                        )}
                        disableClearable
                        freeSolo={false}
                        fullWidth
                        disabled={readonly}
                    />
                </FormControl>

            </Grid>
            <Grid item xs={12}>
                <FormControl required fullWidth>
                    <TextField
                        {...formik.getFieldProps('connectionDetails.updateFrequency')}
                        label="Update Frequency (s)"
                        placeholder="Update Frequency (s)"
                        value={formik.values.connectionDetails.updateFrequency || ''}
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue('connectionDetails.updateFrequency', newValue);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t('secAbbr')}</InputAdornment>,
                        }}
                        type="text"
                        error={formik.touched.connectionDetails?.updateFrequency && Boolean(formik.errors.connectionDetails?.updateFrequency)}
                        helperText={formik.touched.connectionDetails?.updateFrequency && formik.errors.connectionDetails?.updateFrequency}
                        fullWidth
                        disabled={readonly}
                    />
                </FormControl>

            </Grid>
        </Grid>
    );

}