/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Avatar } from "../Avatar";
import "./style.css";

export const AvatarLabelGroup = ({
  size,
  statusIcon,
  state,
  className,
  textClassName,
  supportingTextClassName,
  text = "olivia@untitledui.com",
  profileImage,
  name
}) => {
  return (
    <div className={`avatar-label-group size-4-${size} ${className}`}>
      {size === "lg" && statusIcon === "online-indicator" && state === "focus" && (
        <div className="text-and-supporting">
          <div className={`div ${textClassName}`}>{name}</div>
          <div className={`supporting-text ${supportingTextClassName}`}>{text}</div>
        </div>
      )}

      <Avatar
        className={`${
          size === "lg" && statusIcon === "online-indicator" && state === "focus"
            ? "class-19"
            : statusIcon === "online-indicator" && size === "sm" && state === "focus"
            ? "class-20"
            : size === "sm" && state === "focus" && statusIcon === "company"
            ? "class-21"
            : statusIcon === "false" && size === "sm" && state === "focus"
            ? "class-22"
            : ["default", "hover"].includes(state)
            ? "class-23"
            : "class-24"
        }`}
        placeholder={false}
        size={size === "md" ? "md" : size === "xl" ? "xl" : size === "sm" ? "sm" : "lg"}
        state={state === "focus" ? "focused" : "default"}
        statusIcon={statusIcon === "company" ? "company" : statusIcon === "false" ? "false" : "online-indicator"}
        text={false}
        profileImage={profileImage}
      />
      {((size === "lg" && state === "default" && statusIcon === "online-indicator") ||
        (size === "lg" && state === "hover" && statusIcon === "online-indicator") ||
        (size === "md" && statusIcon === "online-indicator") ||
        (size === "sm" && statusIcon === "online-indicator") ||
        (size === "xl" && statusIcon === "online-indicator") ||
        statusIcon === "company" ||
        statusIcon === "false") && (
        <div className="text-and-supporting">
          <div className="text-2">{name}</div>
          <div className="supporting-text-2">{text}</div>
        </div>
      )}
    </div>
  );
};

AvatarLabelGroup.propTypes = {
  size: PropTypes.oneOf(["md", "sm", "lg", "xl"]),
  statusIcon: PropTypes.oneOf(["false", "company", "online-indicator"]),
  state: PropTypes.oneOf(["default", "hover", "focus"]),
  text: PropTypes.string,
};
