import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';


/**
 * Renders an accordion component with the provided data.
 * @param {Object} props - The component props.
 * @param {Array} props.accordionData - The data to render in the accordion.
 * Example: { accordionData: [{ title: "title", subTitle: "subTitle", content: "content", actions: "actions" }] }
 * Title and subTitle are rendered in the accordion header and need to be strings.
 * Content and actions are rendered in the accordion body and need to be JSX elements.
 * @returns {JSX.Element} - The rendered accordion component.
 */
function AccordionWrapper({ accordionData }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {accordionData.map((item, index) => (
                <Accordion
     /*                variant="qualitatio" */
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {item.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{item.subTitle}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '24px 24px 24px 24px'
                    }}>
                        {item.content}
                    </AccordionDetails>
                    <AccordionActions>
                        {item.actions}
                    </AccordionActions>
                </Accordion>
            ))}
        </div>
    );
}

export default AccordionWrapper;