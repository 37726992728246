import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LoadingScreen.module.css';

/**
 * LoadingScreen component that displays a loading animation and a timeout message if loading takes too long.
 *
 * @component
 * @param {boolean} loading - Determines if the loading screen should be displayed.
 */
const LoadingScreen = ({ loading }) => {
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTimeoutMessage(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <Box className={styles.loadingContainer} data-testid="loading-screen">
      {showTimeoutMessage ? (
        <Typography variant="h6" color="error">
          {t('loadingTimeoutMessage')}
        </Typography>
      ) : (
        <Box className={styles.loadingSpinnerContainer}>
          <CircularProgress className={styles.loadingSpinner} />
          <Typography variant="h6">{t('loadingMessage')}</Typography>
        </Box>
      )}
    </Box>
  );
};

LoadingScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingScreen;
