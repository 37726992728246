import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import { getUserStatsEndpoint } from '../../../api/endpoints';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import { useAuthStore } from '../../../store/auth.store';
import Menu from '../Menu';
import './Status.css';

// This component can be used to display the stats of the user about the tests he has done and the defects he has found
export default function Status({ setPageName, recommenderMenuOpen, setRecommenderMenuOpen }) {
  const { t } = useTranslation();
  setPageName(t('status'));

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lastPath = searchParams.get('lastPath');
  const [productsTested, setProductsTested] = useState(0);
  const [numberDefectsFound, setNumberOfDefectsFound] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { user } = useAuthStore((state) => ({ user: state.user }));

  const getUserStats = async () => {
    try {
      const response = await axiosClient.post(getUserStatsEndpoint, {
        userID: user.id,
      });
      if (response.data.numberDefectsFound) {
        setNumberOfDefectsFound(response.data.numberDefectsFound);
      }
      if (response.data.productsTested) {
        setProductsTested(response.data.productsTested);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getUserStats();
  }, []);

  return (
    <>
      {recommenderMenuOpen ? (
        <Grid
          container
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={5}
          width="100%"
          padding="10px 0px 0 0px"
        >
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3">{t('wellDone')}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <img className="test-status-image" alt="Mask group" src="/images/enjoy.svg" />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            direction="row"
            gap={2}
          >
            <Typography variant="h6">{`${t('numberOfTestedProducts')}: `}</Typography>
            <Typography
              variant="h6"
              style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
            >
              {productsTested}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            direction="row"
            gap={2}
          >
            <Typography variant="h6">{`${t('numberOfDefectsFound')}: `}</Typography>
            <Typography
              variant="h6"
              style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
            >
              {numberDefectsFound}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            direction="column"
            width="100%"
          >
            <QualitatioButton
              text={t('back')}
              order="secondary"
              onClick={() => navigate(lastPath)}
              width={'80%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            />
            <QualitatioButton
              text={t('newTest')}
              order="primary"
              onClick={() => navigate('/intelligent_testing/recommender/select')}
              width={'80%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              endIcon={<ArrowForwardIosRoundedIcon />}
            />
          </Grid>
          <div className="test-status-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </Grid>
      ) : (
        <Menu setRecommenderMenuOpen={setRecommenderMenuOpen} />
      )}
    </>
  );
}
