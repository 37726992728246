import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { BuildingBlocksWrapper } from '../BuildingBlocksWrapper';
import './QualitatioList.css';


export default function QualitatioList({
    items,
    gap
}) {
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex', justifyContent: 'center', maxHeight: 'calc(100px + 25vh)', overflowY: 'auto', }}>
            <List sx={{ width: '80%', display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: gap, alignItems: 'center', height: '100%' }}>
                {items.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ width: '90%' }}>
                        <ListItemButton onClick={item.onClick} sx={{ borderRadius: '8px', border: 'solid 1px #1FBEBD', width: '90%' }}>
                            <ListItemIcon>
                                <BuildingBlocksWrapper
                                    className={item.buildingBlocksWrapperBuildingBlocksClassName}
                                    initialClassName='design-component-instance-node'
                                    text={item.listIndex}
                                />
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                            <ListItemIcon>
                                {item.endIcon}
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}