import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Grid, IconButton, MobileStepper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from 'react-i18next';

export const DefectInputImageStepper = ({
  capturedImages,
  deleteCapturedImage,
  selectedImage,
  setCameraState,
  handleNext,
  handleBack,
  defectPhotoRequired,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
        {capturedImages.length !== 0 && (
          <IconButton
            style={{
              backgroundColor: theme.palette.error.main,
              height: '25px',
              width: '25px',
            }}
            onClick={() => deleteCapturedImage()}
          >
            <DeleteIcon color={'#1fbebd'} />
          </IconButton>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          spacing={1}
          display="flex"
          direction="column"
          alignItems="center"
          onClick={() => {
            setCameraState((prevState) => !prevState);
          }}
          style={{
            cursor: 'pointer',
            zIndex: 10,
            color: capturedImages.length === 0 ? '' : theme.palette.primary.main,
          }}
        >
          <CameraAltIcon />
          <Typography variant="body2">
            {t('uploadImage') + (defectPhotoRequired ? ' *' : '')}
          </Typography>{' '}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MobileStepper
          variant="dots"
          steps={capturedImages.length || 0}
          position="static"
          activeStep={capturedImages.findIndex((el) => el === selectedImage)}
          sx={{
            width: '100%',
            flexGrow: 1,
            backgroundColor: theme.palette.success.secondary,
            '& .MuiMobileStepper-dots': {
              zIndex: 1,
            },
            padding: '0px 8px',
          }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={
                capturedImages.findIndex((image) => image === selectedImage) ===
                capturedImages.length - 1
              }
            >
              {t('next')}
              <KeyboardArrowRightIcon />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={
                capturedImages.findIndex((image) => image === selectedImage) === 0 ||
                capturedImages.findIndex((image) => image === selectedImage) === -1
              }
            >
              <KeyboardArrowLeftIcon />
              {t('back')}
            </Button>
          }
        />
      </Grid>
    </>
  );
};

export default DefectInputImageStepper;
