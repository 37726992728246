/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const TableToolbar = ({ className, divClassName, text, width }) => {
  return (
    // Set width as !important to override the default width of 100%.
    <div className={`table-toolbar ${className}`} style={{ width: width }}>
      <div className={`table-name ${divClassName}`}>{text}</div>
    </div>
  );
};