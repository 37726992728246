import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth.store';

const OAuthCallbackHandler = () => {
  const fetchUserStatus = useAuthStore((state) => state.fetchUserStatus);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAfterOAuth = async () => {
      try {
        // Assuming you have an endpoint that verifies the session and returns the user object
        await fetchUserStatus();
        navigate('/home'); // Redirect to home or dashboard
      } catch (error) {
        console.error('OAuth callback handling error:', error);
        // Handle error, possibly redirect to login with an error message
        navigate('/login');
      }
    };

    fetchUserAfterOAuth();
  }, [navigate, fetchUserStatus]);

  // Optionally, show a loading indicator or a "please wait" message
  return <div>Please wait...</div>;
};

export default OAuthCallbackHandler;
