import AddCommentIcon from '@mui/icons-material/AddComment';
import { Avatar, Button, Grid, Paper, Popover, Tooltip, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../store/auth.store';
import QualitatioInput from '../QualitatioInput/QualitatioInput';

export default function QualitatioCommentCard({
  onSave,
  comment,
  setComment,
  onCancel,
  onOpenCard,
  openCard,
  field,
}) {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));

  const refs = {
    title: useRef(null),
    testObject: useRef(null),
    testLocation: useRef(null),
    testMethod: useRef(null),
    testComment: useRef(null),
    orderIdentifier: useRef(null),
  };

  return (
    <>
      <Tooltip title={t('addComment')} placement="right">
        <AddCommentIcon
          ref={refs[`${field}`]}
          style={{
            position: 'absolute',
            right: '-50px',
            height: '24px',
            width: '24px',
            cursor: 'pointer',
            top: 0,
            zIndex: 1000,
          }}
          onClick={() => onOpenCard(field)}
        />
      </Tooltip>
      <Popover
        open={openCard === field}
        anchorEl={refs[`${field}`].current}
        onClose={onCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper elevation={3} square={false} style={{ maxWidth: '300px', padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt="Remy Sharp"
                src={user.profileImage || '/images/avatar.png'}
                style={{ marginRight: '8px' }}
              />
              <Typography variant="body2" color="text.secondary">
                {user.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <QualitatioInput
                label={t('comment')}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
                width={'100%'}
                compact={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-start', gap: '1vw' }}
            >
              <Button variant="outlined" onClick={onCancel} size="small">
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onSave();
                  setComment('');
                  onCancel();
                }}
                size="small"
                disabled={comment === ''}
              >
                {t('toComment')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </>
  );
}
