import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function RuleType() {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } = useFormikContext();

  return (
    <Grid
      item
      display={'flex'}
      direction={'column'}
      justifyContent={'center'}
      width={'100%'}
      gap={3}
    >
      <Typography variant={'body2'}>{t('ruleTypeDescription')}</Typography>
      <FormControl component="fieldset" error={errors.ruleType && touched.ruleType}>
        <Grid item display={'flex'} direction={'row'} justifyContent={'center'}>
          <RadioGroup
            row
            aria-label="ruleType"
            name="ruleType"
            value={values.ruleType}
            onChange={(event) => setFieldValue('ruleType', event.target.value)}
          >
            <FormControlLabel value="simple" control={<Radio />} label={t('simpleRule')} disabled />
            <FormControlLabel
              value="complex"
              control={<Radio />}
              label={t('complexRule')}
              disabled
            />
          </RadioGroup>
          {errors.ruleType && touched.ruleType && (
            <FormHelperText error>{errors.ruleType}</FormHelperText>
          )}
        </Grid>
      </FormControl>
    </Grid>
  );
}
