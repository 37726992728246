import { Chip, Divider, MenuItem, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv, FaRegClock, FaSave } from 'react-icons/fa';
import { FiArrowDown, FiArrowUp, FiCircle, FiHexagon, FiTriangle } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import { MdDownload, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import axiosClient from '../../api/axiosClient';
import {
  defectDescriptionFieldsEndpoint,
  generateCSVOptimizationFileFromStreamEndpoint,
  liveStreamDataEndpoint,
  optimizationDataEndpoint,
} from '../../api/endpoints';
import QualitatioAccordion from '../../components/QualitatioAccordion/QualitatioAccordion';
import QualitatioChipArray from '../../components/QualitatioChipArray/QualitatioChipArray';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioInput from '../../components/QualitatioInput/QualitatioInput';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import QualitatioTransferList from '../../components/QualitatioTransferList/QualitatioTransferList';
import './AIEditOptimizer.css';

export default function AIEditOptimizer({
  dataSource,
  isEditOverlayOpen,
  closeEditOverlay,
  setOptimizationData,
  checkForWrongCharacter,
  checkIfDataSourceNameAlreadyExists,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [id, setId] = useState(dataSource.id);
  const [name, setName] = useState(dataSource.name);
  const [lookupFileName, setLookupFileName] = useState(dataSource.lookupFileName);
  const [active, setActive] = useState(dataSource.active);
  const [creatorName, setCreatorName] = useState(dataSource.creatorName);
  const [created, setCreated] = useState(dataSource.created);
  const [lastModified, setLastModified] = useState(dataSource.lastModified);
  const [type, setType] = useState(dataSource.type);
  const [streamName, setStreamName] = useState(dataSource.streamName);
  const [allFieldNames, setAllFieldNames] = useState(dataSource.allFieldNames);
  const [defectDescriptionFieldNames, setDefectDescriptionFieldNames] = useState(
    dataSource.defectDescriptionFieldNames
  );
  const [defectDescriptionFieldMapping, setDefectDescriptionFieldMapping] = useState(
    dataSource.defectDescriptionFieldMapping
  );
  const [kpi, setKpi] = useState([dataSource.kpi]);
  const [csvFile, setCsvFile] = useState(dataSource.csvFile);
  const [streamOptimizationDataAsCSV, setStreamOptimizationDataAsCSV] = useState(
    dataSource.streamOptimizationDataAsCSV
  );
  const [ordering, setOrdering] = useState(dataSource.ordering);
  const [priorization, setPriorization] = useState(dataSource.priorization);
  const [timePeriod, setTimePeriod] = useState(dataSource.timePeriod);
  const [keepLearning, setKeepLearning] = useState(dataSource.keepLearning);
  const [examples, setExamples] = useState(dataSource.examples);

  const [defectDescriptionFields, setDefectDescriptionFields] = useState([]);
  const [liveStreamData, setLiveStreamData] = useState([]);

  useEffect(() => {
    getLiveStreamData();
    getDefectDescriptionFields();
  }, []);

  const getLiveStreamData = async () => {
    try {
      const response = await axiosClient.get(liveStreamDataEndpoint);
      setLiveStreamData(response.data.liveStreamData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getDefectDescriptionFields = async () => {
    try {
      const response = await axiosClient.get(defectDescriptionFieldsEndpoint);
      setDefectDescriptionFields(response.data.defectDescriptionFields);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setId(dataSource.id);
    setName(dataSource.name);
    setLookupFileName(dataSource.lookupFileName);
    setActive(dataSource.active);
    setCreatorName(dataSource.creatorName);
    setCreated(dataSource.created);
    setLastModified(dataSource.lastModified);
    setType(dataSource.type);
    setStreamName(dataSource.streamName);
    setAllFieldNames(dataSource.allFieldNames);
    setDefectDescriptionFieldNames(dataSource.defectDescriptionFieldNames);
    setDefectDescriptionFieldMapping(dataSource.defectDescriptionFieldMapping);
    setKpi([dataSource.kpi]);
    setCsvFile(dataSource.csvFile);
    setStreamOptimizationDataAsCSV(dataSource.streamOptimizationDataAsCSV);
    setOrdering(dataSource.ordering);
    setPriorization(dataSource.priorization);
    setTimePeriod(dataSource.timePeriod);
    setKeepLearning(dataSource.keepLearning);
    setExamples(dataSource.examples);
  }, [dataSource]);

  const handleSave = async () => {
    const snackbarKey = enqueueSnackbar(t('startingUpdateOfOptimizationDataSource'), {
      variant: 'info',
    });
    try {
      const response = await axiosClient.put(optimizationDataEndpoint, {
        id: id,
        name: name,
        active: active,
        streamName: streamName,
        allFieldNames: allFieldNames,
        defectDescriptionFieldNames: defectDescriptionFieldNames,
        defectDescriptionFieldMapping: defectDescriptionFieldMapping,
        kpi: kpi.toString(),
        ordering: ordering,
        priorization: priorization,
        timePeriod: timePeriod,
        keepLearning: keepLearning,
      });
      setOptimizationData(response.data.optimizationData);
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('dataSourceSuccesfullyUpdated'), { variant: 'success' });
      closeEditOverlay(dataSource.id);
    } catch (error) {
      closeSnackbar(snackbarKey);
      enqueueSnackbar(t('dataSourceUpdateFailed'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const downloadOptimizationDataSet = async () => {
    const snackbarKey = enqueueSnackbar(t('downloadInitiated'), { variant: 'info' });
    let csvDataLink = '';
    if (type === 'csv') {
      csvDataLink = csvFile;
    } else {
      if (streamOptimizationDataAsCSV) {
        csvDataLink = streamOptimizationDataAsCSV;
      } else {
        try {
          const response = await axiosClient.post(generateCSVOptimizationFileFromStreamEndpoint, {
            name: name,
            streamName: streamName,
            defectDescriptionFieldNames: defectDescriptionFieldNames,
            kpi: kpi,
            timePeriod: timePeriod,
          });
          setStreamOptimizationDataAsCSV(response.data.streamOptimizationDataAsCSV);
          setLookupFileName(response.data.lookupFileName);
          csvDataLink = response.data.streamOptimizationDataAsCSV;
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }

    closeSnackbar(snackbarKey);
    enqueueSnackbar(t('downloadFinished'), { variant: 'success' });

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', csvDataLink);
    linkElement.setAttribute('download', 'optimizationDataSet.csv');
    linkElement.click();

    // Clean up the temporary URL object
    URL.revokeObjectURL(linkElement.href);
  };

  const updateDefectDescriptionFieldNamesAndKPI = (field) => {
    if (defectDescriptionFieldNames.includes(field)) {
      setDefectDescriptionFieldNames(
        defectDescriptionFieldNames.filter((el) => {
          return el != field;
        })
      );
    } else if (kpi.includes(field)) {
      setKpi([]);
    } else {
      setDefectDescriptionFieldNames([...defectDescriptionFieldNames, field]);
    }
  };

  const updateDefectDescriptionMapping = (field, selectedValue) => {
    setDefectDescriptionFieldMapping((prevMapping) => {
      const updatedMapping = [...prevMapping];
      const index = updatedMapping.findIndex((el) => el.defectDescriptionFieldName === field);
      if (index === -1) {
        updatedMapping.push({
          defectDescriptionFieldName: field,
          mappedFieldName: selectedValue,
        });
        return updatedMapping;
      } else {
        updatedMapping[index] = {
          defectDescriptionFieldName: field,
          mappedFieldName: selectedValue,
        };
      }
      return updatedMapping;
    });
  };

  const [nameError, setNameError] = useState('');

  const setNameWithCheck = (newName) => {
    const wrongCharacter = checkForWrongCharacter(newName);
    const modelNameExists = checkIfDataSourceNameAlreadyExists(newName);
    const modelNameEqualsOldName =
      newName.trim().toLowerCase().replace(/\s+/g, '_') ===
      dataSource.name.trim().toLowerCase().replace(/\s+/g, '_');
    if (wrongCharacter) {
      setNameError(t('wrongCharacter'));
    } else if (modelNameExists && !modelNameEqualsOldName) {
      setNameError(t('dataSourceAlreadyExists'));
    } else {
      setNameError('');
    }
    setName(newName);
  };

  const saveIsDisabled =
    defectDescriptionFieldMapping.length !== defectDescriptionFields.length ||
    defectDescriptionFieldMapping.some((el) => el.mappedFieldName === '') ||
    nameError ||
    name.length < 2 ||
    allFieldNames.length === 0 ||
    defectDescriptionFieldNames.length === 0 ||
    kpi.length === 0;

  const exampleColumns =
    examples.length > 0
      ? Object.keys(examples[0]).map((key, index, array) => ({
          field: key,
          headerName: key.replace('_kpi', ''),
          width: 150, // Adjust the width as needed
          flex: 1,
          headerClassName: index === array.length - 1 ? 'last-column-header' : '', // Add CSS class for last column header
        }))
      : [];

  const exampleRows = examples.map((example, exampleIndex) => ({
    id: exampleIndex,
    ...example,
  }));

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeEditOverlay(dataSource.id);
  };

  return (
    <QualitatioDialog
      key={dataSource.id}
      title={t('edit') + ' "' + name + '"'}
      open={isEditOverlayOpen[dataSource.id]}
      onClose={onClose}
      subheader={
        t('created') +
        ': ' +
        new Date(created).toLocaleDateString() +
        ' ' +
        t('by') +
        ' ' +
        creatorName
      }
      actions={[
        {
          label: t('cancel'),
          onClick: () => closeEditOverlay(dataSource.id),
          color: theme.palette.error.main,
          order: 'secondary',
          startIcon: <GiCancel />,
          width: '20%',
        },
        {
          label: t('save'),
          onClick: handleSave,
          color: theme.palette.success.main,
          order: 'primary',
          startIcon: <FaSave />,
          width: '20%',
          disabled: saveIsDisabled,
        },
      ]}
      children={
        <div className="ai-optimizer-summary">
          <div className="model-settings">
            <QualitatioInput
              label={t('nameOfDataSource')}
              value={name}
              onChange={(e) => setNameWithCheck(e.target.value)}
              width="100%"
              placeholder={t('enterDataSourceName')}
            />
            {nameError && <div className="error-message">{nameError}</div>}
            <QualitatioChipArray
              chipData={[
                {
                  label: type === 'csv' ? t('csvFile') : t('liveData'),
                  icon: type === 'csv' ? <FaFileCsv /> : <FaRegClock />,
                  backgroundColor: theme.palette.success.secondary,
                },
                {
                  label: t('downloadOptimizationDataSet'),
                  icon: <MdDownload />,
                  color: 'default',
                  onClick: () => {
                    downloadOptimizationDataSet();
                  },
                },
              ]}
              justifyContent="left"
            />
            <QualitatioAccordion
              accordionData={[
                {
                  title: t('optimizationInput'),
                  panel: 'panel1',
                  render: (
                    <div className="step-4-step-5-summary">
                      {type === 'live' && (
                        <div className="step-3-live-summary">
                          <div className="select-streams">{t('selectAStream')}</div>
                          <QualitatioChipArray
                            chipData={liveStreamData?.map((stream) => ({
                              label: stream.streamName,
                              icon:
                                streamName === stream.streamName ? (
                                  <MdOutlineCheckBox />
                                ) : (
                                  <MdOutlineCheckBoxOutlineBlank />
                                ),
                              backgroundColor:
                                streamName === stream.streamName && theme.palette.success.secondary,
                              onClick: () => updateStreamName(stream.streamName),
                            }))}
                            boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                          />
                        </div>
                      )}
                      {
                        <div className="step-4-summary">
                          <div className="select-fields">{t('selectFields')}</div>
                          <div className="list-legend">
                            <QualitatioChipArray
                              chipData={[
                                {
                                  label: t('defectDescriptionField'),
                                  icon: <MdOutlineCheckBox />,
                                  backgroundColor: theme.palette.success.secondary,
                                },
                                {
                                  label: t('kpi'),
                                  icon: <MdOutlineCheckBox />,
                                  backgroundColor: theme.palette.error.secondary,
                                },
                                {
                                  label: t('notSelected'),
                                  icon: <MdOutlineCheckBoxOutlineBlank />,
                                  color: 'default',
                                },
                              ]}
                              boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                            />
                          </div>
                          <div className="divider-container">
                            <Divider variant="middle" />
                          </div>
                          <QualitatioChipArray
                            chipData={allFieldNames.map((fieldName, index) => ({
                              label: fieldName,
                              icon:
                                kpi.includes(fieldName) ||
                                defectDescriptionFieldNames.includes(fieldName) ? (
                                  <MdOutlineCheckBox />
                                ) : (
                                  <MdOutlineCheckBoxOutlineBlank />
                                ),
                              backgroundColor: defectDescriptionFieldNames.includes(fieldName)
                                ? theme.palette.success.secondary
                                : kpi.includes(fieldName)
                                  ? theme.palette.error.secondary
                                  : 'default',
                              onClick: () => updateDefectDescriptionFieldNamesAndKPI(fieldName),
                            }))}
                            boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                          />

                          <div className="feature-and-prediction-fields">
                            <QualitatioTransferList
                              nameLeft={t('feature')}
                              nameRight={t('target')}
                              left={defectDescriptionFieldNames}
                              right={kpi}
                              setLeft={setDefectDescriptionFieldNames}
                              setRight={setKpi}
                              isRightString={true}
                            />
                          </div>
                        </div>
                      }
                      {
                        <div className="step-5-summary">
                          <div className="map-feature-fields">
                            {t('mapDescriptionFieldsToDataFields')}
                          </div>
                          <div className="feature-field-mapping">
                            {defectDescriptionFields.map((field, index) => (
                              <div key={field} className="grid-item">
                                <Chip
                                  label={field}
                                  icon={<MdOutlineCheckBox />}
                                  sx={{ backgroundColor: theme.palette.success.secondary }}
                                />
                                <QualitatioSelect
                                  value={
                                    defectDescriptionFieldMapping.find(
                                      (el) => el.defectDescriptionFieldName === field
                                    )?.mappedFieldName || ''
                                  }
                                  onChange={(e) =>
                                    updateDefectDescriptionMapping(field, e.target.value)
                                  }
                                  optionProps={defectDescriptionFieldNames?.map((fieldName) => (
                                    <MenuItem key={fieldName} value={fieldName} className="option">
                                      {fieldName}
                                    </MenuItem>
                                  ))}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                    </div>
                  ),
                },
                {
                  title: t('optimizationCriteria'),
                  panel: 'panel2',
                  render: (
                    <div className="step-6-summary">
                      <div className="define-priorization">{t('definePriorization')}</div>
                      <div className="natural-ordering">
                        <div className="natural-ordering-text">{t('naturalOrdering')}</div>
                        <QualitatioChipArray
                          chipData={[
                            {
                              label: t('ascending'),
                              icon: <FiArrowUp />,
                              backgroundColor:
                                ordering === 'ascending' && theme.palette.success.secondary,
                              onClick: () => setOrdering('ascending'),
                            },
                            {
                              label: t('descending'),
                              icon: <FiArrowDown />,
                              backgroundColor:
                                ordering === 'descending' && theme.palette.error.secondary,
                              onClick: () => setOrdering('descending'),
                            },
                          ]}
                          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                        />
                      </div>
                      <div className="priority-of-kpi">
                        <div className="priority-of-kpi-text">{t('priorityOfKPI')}</div>
                        <QualitatioChipArray
                          chipData={[
                            {
                              label: t('low'),
                              icon: <FiHexagon />,
                              backgroundColor:
                                priorization === 'low' && theme.palette.success.secondary,
                              onClick: () => setPriorization('low'),
                            },
                            {
                              label: t('medium'),
                              icon: <FiCircle />,
                              backgroundColor:
                                priorization === 'medium' && theme.palette.warning.main,
                              onClick: () => setPriorization('medium'),
                            },
                            {
                              label: t('high'),
                              icon: <FiTriangle />,
                              backgroundColor:
                                priorization === 'high' && theme.palette.error.secondary,
                              onClick: () => setPriorization('high'),
                            },
                          ]}
                          boxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0)"
                        />
                      </div>
                      {type === 'live' && (
                        <div className="keep-learning">
                          <label className="keep-learning-text" htmlFor="keepLearning">
                            {t('keepLearning')}
                          </label>
                          <Switch
                            variant="qualitatio"
                            id="keepLearning"
                            checked={keepLearning}
                            onChange={() => setKeepLearning(!keepLearning)}
                          />
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: t('optimizationOther'),
                  panel: 'panel3',
                  render: (
                    <div className="step-7-summary">
                      <QualitatioTable
                        columns={exampleColumns}
                        rows={exampleRows}
                        width="100%"
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                      />
                    </div>
                  ),
                },
              ]}
            />
            {
              <div className="is-active">
                <label className="is-active-text" htmlFor="isActive">
                  {t('active')}
                </label>
                <Switch
                  variant="qualitatio"
                  id="isActive"
                  checked={active}
                  onChange={() => setActive(!active)}
                />
              </div>
            }
          </div>
        </div>
      }
    />
  );
}
