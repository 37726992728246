import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteUser, patchUser } from '../../../api/user';
import ROLES from '../../../authentication/roleConst';
import { QualitatioLanguageSelector } from '../../../components';
import { LANG } from '../../../language_settings/languageConst';
import { capitalizeFirstLetter } from '../../../utils/utilities';

const MultiActionSpecifyOverlay = ({ selectedUsers, onClose, open, reload, action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRole, setSelectedRole] = React.useState(selectedUsers[0]?.role || ROLES.IT_USER);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    selectedUsers[0]?.language || LANG.EN
  );

  const { t } = useTranslation();

  const updateUsers = async () => {
    selectedUsers.forEach(async (selectedUser) => {
      let response;
      if (action?.value === 'changeRole') {
        response = await patchUser(selectedUser.id, { role: selectedRole });
      } else if (action?.value === 'changeLanguage') {
        response = await patchUser(selectedUser.id, { language: selectedLanguage });
      } else if (action?.value === 'delete') {
        response = await deleteUser(selectedUser.id);
      }
      if (response.error) {
        console.log(response.error);
        enqueueSnackbar(t('selfDeleteSnack'), { variant: 'error' });
      } else {
        enqueueSnackbar(t('userUpdatedSnack'), { variant: 'success' });
        reload();
      }
    });
    onClose();
  };
  let renderSelectInput;

  if (action?.value === 'delete') {
    renderSelectInput = (
      <Typography variant="body2">{t('confirmUserDeletionTextPlural')}</Typography>
    );
  } else if (action?.value === 'changeRole') {
    renderSelectInput = (
      <Autocomplete
        defaultValue={selectedRole}
        options={Object.values(ROLES)}
        getOptionLabel={(option) => capitalizeFirstLetter(option)}
        renderInput={(params) => <TextField {...params} label={t('role')} />}
        onChange={(event, newValue) => setSelectedRole(newValue || ROLES.IT_USER)}
        disableClearable
      />
    );
  } else if (action?.value === 'changeLanguage') {
    renderSelectInput = (
      <QualitatioLanguageSelector
        onChange={(event, newValue) => setSelectedLanguage(newValue || LANG.EN)}
      />
    );
  }

  return (
    <Dialog open={open} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t(action?.value)}</DialogTitle>
      <DialogContent>
        <Box marginTop="20px">{renderSelectInput}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateUsers} variant="qualitatio">
          {t(action?.value)}
        </Button>
        <Button onClick={onClose} variant="qualitatio" color="secondary">
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiActionSpecifyOverlay;
