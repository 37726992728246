import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import { CircularProgress, Grid, IconButton, MenuItem, Switch, Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaFileCsv, FaLayerGroup, FaRegClock } from 'react-icons/fa';
import { LuBrainCircuit } from 'react-icons/lu';
import axiosClient from '../../api/axiosClient';
import {
  activateModelEndpoint,
  checkModelDeletionEndpoint,
  deactivateModelEndpoint,
  mlModelEndpoint,
} from '../../api/endpoints';
import PageFrame from '../../components/PageFrame';
import QualitatioButton from '../../components/QualitatioButton/QualitatioButton';
import QualitatioDialog from '../../components/QualitatioDialog/QualitatioDialog';
import QualitatioLinearProgress from '../../components/QualitatioLinearProgress/QualitatioLinearProgress';
import QualitatioProgressBar from '../../components/QualitatioProgressBar/QualitatioProgressBar';
import QualitatioSelect from '../../components/QualitatioSelect/QualitatioSelect';
import QualitatioTable from '../../components/QualitatioTable/QualitatioTable';
import { Modules } from '../../config/enums';
import { WebSocketContext } from '../../provider/WebsocketProvider';
import { useAuthStore } from '../../store/auth.store';
import './AIConfigurator.css';
import AIConfiguratorSteps from './AIConfiguratorSteps';
import AIEditConfigurator from './AIEditConfigurator';

export default function AIConfigurator({ setPageName }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  setPageName(t('aiTraining'));

  const user = useAuthStore((state) => state.user);
  const { socket } = useContext(WebSocketContext);

  const [activeStep, setActiveStep] = useState(0);

  const [mlModelsData, setMLModelsData] = useState([]);

  useEffect(() => {
    getMLModelsData();
  }, []);

  const [currentlyTraining, setCurrentlyTraining] = useState(new Set());
  const [lastTrainingFailed, setLastTrainingFailed] = useState(new Set());

  useEffect(() => {
    const handleTrainingStatusUpdate = (trainingStatus) => {
      getMLModelsData();
      setCurrentlyTraining((prevTrainingModels) => {
        const updatedTrainingModels = new Set(prevTrainingModels);
        if (trainingStatus.currentlyTraining) {
          updatedTrainingModels.add(trainingStatus.modelID);
        } else {
          updatedTrainingModels.delete(trainingStatus.modelID);
        }
        return updatedTrainingModels;
      });
      setLastTrainingFailed((prevFailedModels) => {
        const updatedFailedModels = new Set(prevFailedModels);
        if (trainingStatus.lastTrainingFailed && !trainingStatus.currentlyTraining) {
          updatedFailedModels.add(trainingStatus.modelID);
        } else {
          updatedFailedModels.delete(trainingStatus.modelID);
        }
        return updatedFailedModels;
      });
    };
    if (socket) {
      socket.on('ai.currentlyTrainingStatus', handleTrainingStatusUpdate);

      return () => {
        socket.off('ai.currentlyTrainingStatus', handleTrainingStatusUpdate);
      };
    }
  }, [socket]);

  const getMLModelsData = async () => {
    try {
      const response = await axiosClient.get(mlModelEndpoint);
      setMLModelsData(response.data.mlModelsData);
      console.log(response.data.mlModelsData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const startTraining = async () => {
    const model = {
      name: newModelName,
      module: newModelModule,
      type: newModelType,
      lookupFileName: newModelLookupFileName,
      creatorName: user.name,
      active: true,
      created: new Date().toISOString().split('T')[0],
      lastRetrained: new Date().toISOString().split('T')[0],
      inputFieldNames: newModelInputFieldNames,
      inputFieldMapping: newModelInputFieldMapping,
      defectClassFieldNames: newModeldefectClassFieldNames,
      defectClassFieldMapping: newModeldefectClassFieldMapping,
      defectClassNames: newModeldefectClassNames,
      allFieldNames: newModelAllFieldNames,
      training: newModelTraining,
      retraining: newModelRetraining,
      other: newModelOther,
      currentlyTraining: true,
      lastTrainingFailed: false,
    };
    setActiveStep(0);
    try {
      const response = await axiosClient.post(mlModelEndpoint, {
        model: model,
      });
      setMLModelsData(response.data.mlModelsData);
      enqueueSnackbar(t('modelSuccessfullyTrained'), {
        variant: 'success',
      });
      setActiveStep(0);
      setNewModelPerformance(response.data.performance);
    } catch (error) {
      console.error('Error:', error);
      if (error.response?.status === 400) {
        if (error.response.data.error === 'No defect description fields configured.') {
          enqueueSnackbar(t('noDefectDescriptionFieldsConfigured'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('tooSmallDataset'), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(t('modelTrainingFailed'), {
          variant: 'error',
        });
      }
      setActiveStep(0);
    }
  };

  /* LIVE TRAINING DATA */
  const [liveTrainingData, setLiveTrainingData] = useState({});

  /* FORM STATES */

  const [newModelName, setNewModelName] = useState('');
  const [newModelModule, setNewModelModule] = useState('');
  const [newModelType, setNewModelType] = useState('anomalyDetection');
  const [newModelLookupFileName, setNewModelLookupFileName] = useState('');
  const [newModelInputFieldNames, setNewModelInputFieldNames] = useState([]);
  const [newModelInputFieldMapping, setNewModelInputFieldMapping] = useState([]);
  const [newModeldefectClassFieldNames, setNewModeldefectClassFieldNames] = useState([]);
  const [newModeldefectClassFieldMapping, setNewModeldefectClassFieldMapping] = useState([]);
  const [newModeldefectClassNames, setNewModeldefectClassNames] = useState([]);
  const [newModelAllFieldNames, setNewModelAllFieldNames] = useState([]);

  const [newModelTraining, setNewModelTraining] = useState({ builtInAlgorithmsUsed: true });
  const [newModelRetraining, setNewModelRetraining] = useState({
    automaticRetrainingEnabled: false,
    retrainingBasedOnModelDecay: false,
    retrainingFrequencyInDays: 30,
    onlineLearning: false,
    retrainingModelDecayRateInPercent: 5,
  });
  const [newModelPerformance, setNewModelPerformance] = useState({});
  const [newModelOther, setNewModelOther] = useState({
    cachingFrequencyInMinutes: 5,
    isWarranty: false,
  });

  /* FORM HELPERS */
  const resetForm = () => {
    setNewModelName('');
    setNewModelModule('');
    setNewModelLookupFileName('');
    setNewModelInputFieldNames([]);
    setNewModelInputFieldMapping([]);
    setNewModeldefectClassFieldNames([]);
    setNewModeldefectClassFieldMapping([]);
    setNewModeldefectClassNames([]);
    setNewModelAllFieldNames([]);
    setNewModelTraining({ builtInAlgorithmsUsed: true });
    setNewModelRetraining({
      automaticRetrainingEnabled: false,
      retrainingBasedOnModelDecay: false,
      retrainingFrequencyInDays: 30,
      onlineLearning: false,
      retrainingModelDecayRateInPercent: 5,
    });
    setNewModelPerformance({});
    setNewModelOther({ cachingFrequencyInMinutes: 5, isWarranty: false });
  };

  const switchedCsvFileOrLiveData = () => {
    setNewModelLookupFileName('');
    setNewModelInputFieldNames([]);
    setNewModelInputFieldMapping([]);
    setNewModeldefectClassFieldNames([]);
    setNewModeldefectClassFieldMapping([]);
    setNewModeldefectClassNames([]);
    setNewModelAllFieldNames([]);
    setNewModelTraining({
      csvFileOrLiveData: newModelTraining.csvFileOrLiveData,
      builtInAlgorithmsUsed: true,
    });
    setNewModelRetraining({
      automaticRetrainingEnabled: false,
      retrainingBasedOnModelDecay: false,
      retrainingFrequencyInDays: 30,
      onlineLearning: false,
      retrainingModelDecayRateInPercent: 5,
    });
    setNewModelPerformance({});
    setNewModelOther((prevOther) => ({
      ...prevOther,
      cachingFrequencyInMinutes: 5,
    }));
    if (newModelTraining.csvFileOrLiveData == 'live') {
      setNewModeldefectClassFieldNames(['defectComponent', 'defectLocation', 'defectType']);
      setNewModeldefectClassFieldMapping([
        {
          inputFieldName: 'defectComponent',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectComponent',
        },
        {
          inputFieldName: 'defectLocation',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectLocation',
        },
        {
          inputFieldName: 'defectType',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectType',
        },
      ]);
    }
  };

  useEffect(() => {
    switchedCsvFileOrLiveData();
  }, [newModelTraining.csvFileOrLiveData]);

  const csvDecisionMade = (e) => {
    setNewModelTraining((prevTraining) => ({
      ...prevTraining,
      csvFileOrLiveData: e,
    }));
    if (e == 'live') {
      setNewModeldefectClassFieldNames(['defectComponent', 'defectLocation', 'defectType']);
      setNewModeldefectClassFieldMapping([
        {
          inputFieldName: 'defectComponent',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectComponent',
        },
        {
          inputFieldName: 'defectLocation',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectLocation',
        },
        {
          inputFieldName: 'defectType',
          mappedStreamName: 'it_quality_checks',
          mappedStreamField: 'defectType',
        },
      ]);
    }
  };

  const checkIfModelNameExists = (modelName) => {
    let modelNames = mlModelsData.map((model) =>
      model.name.trim().toLowerCase().replace(/\s+/g, '_')
    );
    const modelNameTrimmed = modelName.trim().toLowerCase().replace(/\s+/g, '_');
    return modelNames.some((model) => model === modelNameTrimmed);
  };

  const checkForWrongCharacter = (modelName) => {
    const modelNameRegex = /^[a-zA-Z0-9\s]*$/;
    return !modelNameRegex.test(modelName);
  };

  const nextIsDisabled =
    (activeStep === 2 && newModelName.length <= 3) ||
    (activeStep === 2 &&
      (checkForWrongCharacter(newModelName) || checkIfModelNameExists(newModelName))) ||
    (activeStep === 3 && (newModelModule == null || newModelModule == '')) ||
    (activeStep === 4 &&
      (newModelTraining.csvFileOrLiveData == null ||
        (newModelTraining.csvFileOrLiveData == 'live' && liveTrainingData == null))) ||
    (activeStep === 5 &&
      newModelAllFieldNames.length == 0 &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (activeStep === 5 &&
      ((newModelModule !== Modules.EARLY_WARNING_SYSTEM && newModeldefectClassNames.length == 0) ||
        !newModelTraining.selectedFeatureStreams ||
        newModelTraining.selectedFeatureStreams.length == 0) &&
      newModelTraining.csvFileOrLiveData == 'live') ||
    (activeStep === 6 &&
      (newModelInputFieldNames.length == 0 ||
        (newModelModule !== Modules.EARLY_WARNING_SYSTEM && newModeldefectClassFieldNames == 0)) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (activeStep === 7 &&
      (newModelInputFieldMapping.some((item) => item.mappedStreamField == null) ||
        newModelInputFieldMapping.length < newModelInputFieldNames.length) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (activeStep === 6 &&
      newModelInputFieldNames.length == 0 &&
      newModelTraining.csvFileOrLiveData == 'live') ||
    (activeStep === 7 &&
      ((newModelTraining.builtInAlgorithmsUsed &&
        (newModelTraining.builtInAlgorithm == null || newModelTraining.builtInAlgorithm == '')) ||
        (!newModelTraining.builtInAlgorithmsUsed &&
          (newModelTraining.customAlgorithmName == null ||
            newModelTraining.customAlgorithmName == ''))) &&
      newModelTraining.csvFileOrLiveData == 'live') ||
    (activeStep === 8 &&
      ((newModelTraining.builtInAlgorithmsUsed &&
        (newModelTraining.builtInAlgorithm == null || newModelTraining.builtInAlgorithm == '')) ||
        (!newModelTraining.builtInAlgorithmsUsed &&
          (newModelTraining.customAlgorithmName == null ||
            newModelTraining.customAlgorithmName == ''))) &&
      newModelTraining.csvFileOrLiveData == 'csv') ||
    (activeStep === 9 &&
      newModelRetraining.automaticRetrainingEnabled &&
      ((newModelRetraining.retrainingBasedOnModelDecay &&
        (newModelRetraining.retrainingModelDecayRateInPercent == null ||
          newModelRetraining.retrainingModelDecayRateInPercent == '')) ||
        (!newModelRetraining.retrainingBasedOnModelDecay &&
          (newModelRetraining.retrainingFrequencyInDays == null ||
            newModelRetraining.retrainingFrequencyInDays == '')))) ||
    (activeStep === 8 &&
      newModelOther.cachingFrequencyInMinutes === '' &&
      newModelTraining.csvFileOrLiveData === 'live') ||
    (activeStep === 9 &&
      newModelOther.cachingFrequencyInMinutes === '' &&
      newModelTraining.csvFileOrLiveData === 'csv') ||
    (activeStep === 9 && newModelTraining.csvFileOrLiveData === 'live') ||
    (activeStep === 10 && newModelTraining.csvFileOrLiveData === 'csv') ||
    (activeStep === 10 && newModelTraining.csvFileOrLiveData === 'live') ||
    (activeStep === 11 && newModelTraining.csvFileOrLiveData === 'csv') ||
    (activeStep === 11 && newModelTraining.csvFileOrLiveData === 'live') ||
    (activeStep === 12 && newModelTraining.csvFileOrLiveData === 'csv');

  const prevIsDisabled =
    (activeStep >= 10 && newModelTraining.csvFileOrLiveData === 'live') ||
    (activeStep >= 11 && newModelTraining.csvFileOrLiveData === 'csv');

  /* Handle edit data source */

  const updateModelActivation = async (modelID, modelActivation) => {
    try {
      if (modelActivation) {
        const response = await axiosClient.post(activateModelEndpoint, {
          modelID: modelID,
        });
        setMLModelsData(response.data.mlModelsData);
        enqueueSnackbar(t('modelsSuccessfullyActivated'), {
          variant: 'success',
        });
      } else {
        const response = await axiosClient.post(deactivateModelEndpoint, {
          modelID: modelID,
        });
        setMLModelsData(response.data.mlModelsData);
        enqueueSnackbar(t('modelsSuccessfullyDeactivated'), {
          variant: 'success',
        });
      }
    } catch (error) {
      if (modelActivation) {
        enqueueSnackbar(t('errorDuringModelActivation'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('errorDuringModelDeactivation'), {
          variant: 'error',
        });
      }
      console.error('Error:', error);
    }
  };

  /* Handle delete Model */

  const deleteModel = async (modelID) => {
    try {
      // Show first confirmation dialog
      const shouldDeleteModel = await confirmDeletion('Do you really want to delete the model?');

      if (shouldDeleteModel) {
        const check = await axiosClient.post(checkModelDeletionEndpoint, {
          modelID: modelID,
        });

        if (check.data.modelDeletionPossible) {
          // Proceed with model deletion
          const response = await axiosClient.delete(mlModelEndpoint, {
            data: {
              modelID: modelID,
            },
          });
          setMLModelsData(response.data.mlModelsData);
          enqueueSnackbar(t('modelsSuccessfullyDeleted'), {
            variant: 'success',
          });
          console.log(response.data.mlModelsData);
        } else {
          // Show second confirmation dialog
          await confirmDeletion(
            'This model is currently used by the following quality stations: ' +
              check.data.qualityStations.join(', ') +
              '. Please switch off the model in these quality stations before deleting it.'
          );
        }
      } else {
        // First confirmation denied
        console.log('Model deletion canceled.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const confirmDeletion = async (message) => {
    return new Promise((resolve) => {
      if (window.confirm(message)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const [mlModelsDataForTable, setMLModelsDataForTable] = useState([]);

  useEffect(() => {
    setMLModelsDataForTable(mlModelsData);

    const currentlyTrainingModels = mlModelsData
      .filter((model) => model.currentlyTraining)
      .map((model) => model.id);
    setCurrentlyTraining(new Set(currentlyTrainingModels));
    const lastTrainingFailedModels = mlModelsData
      .filter((model) => model.lastTrainingFailed)
      .map((model) => model.id);
    setLastTrainingFailed(new Set(lastTrainingFailedModels));
  }, [mlModelsData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');

  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState([]);

  const openEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const closeEditOverlay = (index) => {
    setIsEditOverlayOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const showWholeText = (text, header) => {
    return (
      <>
        <Chip
          label={text.join(', ')}
          onClick={() => {
            setDialogOpen(!dialogOpen);
            setDialogDescription(text.join('\n'));
            setDialogTitle(header);
          }}
          width={'100%'}
        />

        <QualitatioDialog
          key={header}
          title={dialogTitle}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          description={dialogDescription}
          actions={[
            {
              label: t('close'),
              onClick: () => setDialogOpen(false),
              order: 'primary',
            },
          ]}
        />
      </>
    );
  };

  const columns = [
    {
      field: 'modelName',
      headerName: t('modelName'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'module',
      headerName: t('module'),
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={
            params.value === 'intelligentTesting'
              ? 'IT'
              : params.value === 'earlyWarningSystem'
                ? 'EWS'
                : 'ET'
          }
          icon={<FaLayerGroup />}
          sx={{ backgroundColor: theme.palette.warning.main }}
        />
      ),
    },
    {
      field: 'creator',
      headerName: t('creator'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'created',
      headerName: t('created'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value}
          icon={<FaCalendarAlt />}
          sx={{
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          }}
        />
      ),
    },
    {
      field: 'trainedFrom',
      headerName: t('trainedFrom'),
      minWidth: 105,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value === 'csv' ? 'CSV' : 'Live'}
          icon={params.value === 'csv' ? <FaFileCsv /> : <FaRegClock />}
          sx={{ backgroundColor: theme.palette.success.secondary }}
        />
      ),
    },
    {
      field: 'featureFields',
      headerName: t('featureFields'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => showWholeText(params.value, t('featureFields')),
    },
    {
      field: 'defectClassFields',
      headerName: t('defectClassFields'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => showWholeText(params.value, t('defectClassFields')),
    },
    {
      field: 'defectClasses',
      headerName: t('defectClasses'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => showWholeText(params.value, t('defectClasses')),
    },
    {
      field: 'performance',
      headerName: t('performance'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) =>
        !currentlyTraining.has(params.id) ? (
          !lastTrainingFailed.has(params.id) ||
          (params.value !== null && params.value !== undefined && !isNaN(params.value)) ? (
            <Grid container display="flex" alignItems="center" height="100%">
              <QualitatioLinearProgress
                progress={params.value * 100}
                sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor:
                      params.value > 0.7
                        ? theme.palette.success.main
                        : params.value > 0.3
                          ? theme.palette.warning.secondary
                          : theme.palette.error.main,
                  },
                  '&.MuiLinearProgress-colorPrimary': {
                    backgroundColor: theme.palette.gray.main,
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid container display="flex" alignItems="center" height="100%">
              <Tooltip title={t('lastTrainingFailed')}>
                <CancelIcon color="error" />
              </Tooltip>
            </Grid>
          )
        ) : (
          <Grid container display="flex" alignItems="center" height="100%">
            <CircularProgress size={20} />
          </Grid>
        ),
    },
    {
      field: 'activated',
      headerName: t('activated'),
      minWidth: 80,
      flex: 1,
      renderCell: (params) => (
        <Grid container display="flex" alignItems="center" height="100%">
          <Switch
            variant="qualitatio"
            checked={params.value}
            onChange={(e) => updateModelActivation(params.id, e.target.checked)}
          />
        </Grid>
      ),
    },
    {
      field: 'isWarranty',
      headerName: t('isWarranty'),
      minWidth: 60,
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <Grid container display="flex" alignItems="center" height="100%">
            <CheckCircleIcon color="primary" />
          </Grid>
        ) : (
          <Grid container display="flex" alignItems="center" height="100%">
            <CancelIcon color="error" />
          </Grid>
        ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <div className="edit-and-delete-buttons">
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => openEditOverlay(params.id)}
            style={{ backgroundColor: theme.palette.success.secondary }}
          >
            <EditRoundedIcon color="primary" />
          </IconButton>
          {isEditOverlayOpen[params.id] && (
            <AIEditConfigurator
              model={mlModelsDataForTable.find((model) => model.id === params.id)}
              setMLModelsData={setMLModelsData}
              closeEditOverlay={closeEditOverlay}
              isEditOverlayOpen={isEditOverlayOpen}
              checkForWrongCharacter={checkForWrongCharacter}
              checkIfModelNameExists={checkIfModelNameExists}
            />
          )}
          <IconButton
            variant="qualitatio"
            squared={true}
            onClick={() => deleteModel(params.id)}
            style={{ backgroundColor: theme.palette.error.main }}
          >
            <DeleteForeverRoundedIcon color="white" />
          </IconButton>
        </div>
      ),
    },
  ];

  const rows = mlModelsDataForTable.map((el, index) => ({
    id: el.id,
    modelName: el.name,
    module: el.module,
    creator: el.creatorName,
    created: new Date(el.created.split('T')[0].split('-').join('/')).toLocaleDateString(),
    trainedFrom: el.training.csvFileOrLiveData,
    featureFields: el.inputFieldNames,
    defectClassFields: el.defectClassFieldNames,
    defectClasses: el.defectClassNames,
    performance: el.performance?.validationF1, // You may need to format this as needed
    activated: el.active, // Assuming el.active is a boolean value
    isWarranty: el.other.isWarranty,
  }));

  const [multipleActions, setMultipleActions] = useState();

  const multipleActionsOptions = [
    { value: 'activate', label: t('activate') },
    { value: 'deactivate', label: t('deactivate') },
    { value: 'delete', label: t('delete') },
  ];

  const [selectedRowIDs, setSelectedRowIDs] = useState([]);

  const handleApplyAction = async () => {
    // Make the function async
    if (multipleActions === 'activate') {
      for (const id of selectedRowIDs) {
        // Use for...of for sequential processing
        const model = mlModelsDataForTable.find((model) => model.id === id);
        if (model && !model.active) {
          await updateModelActivation(id, true); // Await ensures each activation completes before moving to the next
        }
      }
    } else if (multipleActions === 'deactivate') {
      for (const id of selectedRowIDs) {
        const model = mlModelsDataForTable.find((model) => model.id === id);
        if (model && model.active) {
          await updateModelActivation(id, false); // Await ensures each deactivation completes before moving to the next
        }
      }
    } else if (multipleActions === 'delete') {
      for (const id of selectedRowIDs) {
        await deleteModel(id); // Await ensures each deletion completes before moving to the next
      }
    }
  };

  return (
    <PageFrame title={t('aiTraining')} Icon={SsidChartRoundedIcon}>
      <div className="aiTraining">
        {activeStep < 2 && (
          <div className="aiTrainingLayout">
            <div className="aiTrainingInputBar">
              {activeStep === 0 ? (
                <>
                  <div className="aiTrainingMultipleActions">
                    <QualitatioSelect
                      value={multipleActions}
                      onChange={(e) => setMultipleActions(e.target.value)}
                      optionProps={multipleActionsOptions.map((option) => (
                        <MenuItem className="option" key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                      width={'30%'}
                      maxWidth={'300px'}
                      defaultText={t('selectAction')}
                    />
                    <QualitatioButton
                      text={t('apply')}
                      order="primary"
                      onClick={handleApplyAction}
                      width={'15%'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                  </div>
                  <QualitatioButton
                    text={t('trainNewModel')}
                    order="primary"
                    onClick={() => setActiveStep(1)}
                    width={'30%'}
                    height={'5vh'}
                    fontSize={'calc(12px + 0.25vw)'}
                    startIcon={<LuBrainCircuit />}
                  />
                </>
              ) : (
                <div className="aiTrainingDescription">
                  {t('aiTrainingConsistsOfTheFollowingSteps')}
                </div>
              )}
            </div>
            <div className="aiTrainingTable">
              {activeStep === 0 && (
                <QualitatioTable
                  rows={rows}
                  columns={columns}
                  checkboxSelection={true}
                  disableRowSelectionOnClick={true}
                  onRowSelectionModelChange={(selection) => {
                    setSelectedRowIDs(selection);
                  }}
                  slots={{ toolbar: CustomToolbar }}
                />
              )}
              {activeStep === 1 && (
                <div className="aiTrainingProgressBarContainer">
                  <QualitatioProgressBar
                    steps={[
                      t('nameModel'),
                      t('selectModule'),
                      t('selectDataSource'),
                      t('selectDataSet'),
                      t('selectFeatureAndPredictionFields'),
                      t('mapFeatureFields'),
                      t('selectAlgorithm'),
                      t('defineRetrainingAndCaching'),
                      t('checkModelSettings'),
                    ]}
                    nonLinear={false}
                    alternativeLabel={true}
                    activeStep={-1}
                    setActiveStep={setActiveStep}
                    orientation="vertical"
                  />
                  <div className="aiTrainingProgressBarContainerButtons">
                    <QualitatioButton
                      text={t('cancel')}
                      order="secondary"
                      onClick={() => setActiveStep(0)}
                      width={'30%'}
                      max-width={'200px'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                    <QualitatioButton
                      text={t('start')}
                      order="primary"
                      onClick={() => setActiveStep(2)}
                      width={'30%'}
                      max-width={'200px'}
                      height={'5vh'}
                      fontSize={'calc(12px + 0.25vw)'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {activeStep >= 2 && (
          <AIConfiguratorSteps
            csvDecisionMade={csvDecisionMade}
            liveTrainingData={liveTrainingData}
            setLiveTrainingData={setLiveTrainingData}
            startTraining={startTraining}
            newModelType={newModelType}
            setNewModelType={setNewModelType}
            newModelPerformance={newModelPerformance}
            resetForm={resetForm}
            nextIsDisabled={nextIsDisabled}
            prevIsDisabled={prevIsDisabled}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            checkForWrongCharacter={checkForWrongCharacter}
            checkIfModelNameExists={checkIfModelNameExists}
            newModelName={newModelName}
            setNewModelName={setNewModelName}
            newModelModule={newModelModule}
            setNewModelModule={setNewModelModule}
            newModelLookupFileName={newModelLookupFileName}
            setNewModelLookupFileName={setNewModelLookupFileName}
            newModelInputFieldNames={newModelInputFieldNames}
            setNewModelInputFieldNames={setNewModelInputFieldNames}
            newModelInputFieldMapping={newModelInputFieldMapping}
            setNewModelInputFieldMapping={setNewModelInputFieldMapping}
            newModeldefectClassFieldNames={newModeldefectClassFieldNames}
            setNewModeldefectClassFieldNames={setNewModeldefectClassFieldNames}
            newModeldefectClassFieldMapping={newModeldefectClassFieldMapping}
            setNewModeldefectClassFieldMapping={setNewModeldefectClassFieldMapping}
            newModeldefectClassNames={newModeldefectClassNames}
            setNewModeldefectClassNames={setNewModeldefectClassNames}
            newModelAllFieldNames={newModelAllFieldNames}
            setNewModelAllFieldNames={setNewModelAllFieldNames}
            newModelTraining={newModelTraining}
            setNewModelTraining={setNewModelTraining}
            newModelRetraining={newModelRetraining}
            setNewModelRetraining={setNewModelRetraining}
            newModelOther={newModelOther}
            setNewModelOther={setNewModelOther}
          />
        )}
      </div>
    </PageFrame>
  );
}
