import axiosClient from './axiosClient';
import { usersEndpoint } from './endpoints';

export const getUsers = async (filters = {}, options = {}) => {
  try {
    //combine filters and options
    const params = { ...filters, ...options };

    const response = await axiosClient.get(usersEndpoint, {
      params: params,
    });
    return response.data.results;
  } catch (error) {
    console.error(error);
    return { error: error.response.data.message };
  }
};

export const patchUser = async (id, data) => {
  try {
    const response = await axiosClient.patch(`${usersEndpoint}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.response.data.message };
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosClient.delete(`${usersEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.response.data.message };
  }
};
