import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { etProductRelevanceCycle } from '../../../api/endpoints';

export default function ETProductRelevanceCycle() {
  const timeOptions = [
    { value: 'h', label: 'Hours' },
    { value: 'd', label: 'Days' },
    { value: 'w', label: 'Weeks' },
    { value: 'm', label: 'Months' },
    { value: 'y', label: 'Years' },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [productRelevanceCycleTimeValue, setProductRelevanceCycleTimeValue] = useState(1);
  const [productRelevanceCycleTimeUnit, setProductRelevanceCycleTimeUnit] = useState('y');

  useEffect(() => {
    getETProductRelevanceCycle();
  }, []);

  const getETProductRelevanceCycle = async () => {
    try {
      const response = await axiosClient.get(etProductRelevanceCycle);
      setProductRelevanceCycleTimeValue(response.data.productRelevanceCycle.timeValue);
      setProductRelevanceCycleTimeUnit(response.data.productRelevanceCycle.timeUnit);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateProductRelevanceCycle = async () => {
    try {
      await axiosClient.post(etProductRelevanceCycle, {
        productRelevanceCycleTimeValue: productRelevanceCycleTimeValue,
        productRelevanceCycleTimeUnit: productRelevanceCycleTimeUnit,
      });
      enqueueSnackbar(t('productRelevanceCycleUpdated'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('errorWhenUpdatingProductRelevanceCycle'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  return (
    <Grid container spacing={2} display={'flex'} justifyContent={'center'} direction={'column'}>
      <Grid item display={'flex'} direction={'row'} justifyContent={'center'} gap={2}>
        <TextField
          label={t('TimeValue')}
          value={productRelevanceCycleTimeValue}
          onChange={(event) => setProductRelevanceCycleTimeValue(event.target.value)}
          type="number"
          min={1}
          max={50}
        />
        <FormControl variant="outlined">
          <InputLabel>{t('TimeUnit')}</InputLabel>
          <Select
            label={t('TimeUnit')}
            value={productRelevanceCycleTimeUnit}
            onChange={(event) => setProductRelevanceCycleTimeUnit(event.target.value)}
            input={<OutlinedInput variant="qualitatio" label={t('TimeUnit')} />}
          >
            {timeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item display={'flex'} justifyContent={'flex-end'}>
        <Button variant="qualitatio" onClick={updateProductRelevanceCycle}>
          {t('Update')}
        </Button>
      </Grid>
    </Grid>
  );
}
