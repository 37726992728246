import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connectionSetupInitialValues } from '../../../utils/initFormikValues';

export default function GeneralConnectionForm({ readonly, formik }) {
  const { t } = useTranslation();

  const options = ['kafka', 'api', 'database'];

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} container justifyContent="center">
        {/* Input Source Description Field */}
        <TextField
          id="inputSourceName"
          autoComplete="off"
          fullWidth
          label={t('inputSourceName')}
          placeholder={t('inputSourceName')}
          {...formik.getFieldProps('name')}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CreateOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item sm={12} container justifyContent="center">
        {/* Input Source Description Field */}
        <TextField
          fullWidth
          label={t('inputSourceDescription')}
          placeholder={t('inputSourceDescription')}
          {...formik.getFieldProps('description')}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CreateOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item sm={12} container justifyContent="center">
        {/* Input Source Type Field */}
        <Autocomplete
          fullWidth
          id="type"
          name="type"
          options={options}
          getOptionLabel={(option) => (option ? t(option) : '')}
          value={formik.values.type}
          onChange={(_, newValue) => {
            formik.setFieldValue('type', newValue);
            if (newValue === 'kafka') {
              formik.setFieldValue('api', {});
              formik.setFieldValue('kafka', connectionSetupInitialValues.kafka);
            } else if (newValue === 'api') {
              formik.setFieldValue('kafka', {});
              formik.setFieldValue('api', connectionSetupInitialValues.api);
            }
          }}
          onBlur={() => formik.setFieldTouched('type', true)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('select')}
              placeholder={t('select')}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type ? formik.errors.type : ''}
            />
          )}
        />
      </Grid>

      <Grid item sm={12} container justifyContent="center">
        {/* Input Source Max Size Field */}
        <TextField
          fullWidth
          placeholder={t('inputSourceMaxSize')}
          label={t('inputSourceMaxSize')}
          {...formik.getFieldProps('maxSize')}
          error={formik.touched.maxSize && Boolean(formik.errors.maxSize)}
          helperText={formik.touched.maxSize && formik.errors.maxSize}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CreateOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item sm={12} container justifyContent="center">
        {/* Input Source Product ID Field Name Field */}
        <TextField
          fullWidth
          placeholder={t('inputSourceProductIDFieldName')}
          label={t('inputSourceProductIDFieldName')}
          {...formik.getFieldProps('productIDFieldName')}
          error={formik.touched.productIDFieldName && Boolean(formik.errors.productIDFieldName)}
          helperText={formik.touched.productIDFieldName && formik.errors.productIDFieldName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CreateOutlinedIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
