import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const shortcutsItems = [
  {
    label: 'Today',
    getValue: () => {
      const startOfToday = dayjs().startOf('day');
      const now = dayjs();
      return [startOfToday, now];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.startOf('month').subtract(1, 'month');
      const endOfLastMonth = today.startOf('month').subtract(1, 'day');
      return [startOfLastMonth, endOfLastMonth];
    },
  },
  { label: 'Reset', getValue: () => [dayjs().startOf('day'), dayjs()] },
];

export default function QualitatioDateRangePicker({
  defaultValue = [dayjs().startOf('day'), dayjs()],
  onDateRangeChange,
  ...props
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        defaultValue={defaultValue}
        onChange={onDateRangeChange}
        slotProps={{
          shortcuts: {
            items: shortcutsItems,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}

QualitatioDateRangePicker.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.object), // Assuming dayjs objects
  onDateRangeChange: PropTypes.func.isRequired, // The function called when the date range changes
};

QualitatioDateRangePicker.defaultProps = {
  defaultValue: [dayjs(), dayjs()],
};
