import { Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SwipeCardFooter = ({ text }) => {
    const theme = useTheme();

    return (
        <Grid item xs={12}>
            <Paper
                style={{
                    width: '100%',
                    padding: '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '5px 5px 15px 15px',
                    boxShadow: '0px 6.63px 13.25px #1018280d',
                }}
            >
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    textAlign="center"
                >
                    {text}
                </Typography>
            </Paper>
        </Grid>
    );
}

export default SwipeCardFooter;