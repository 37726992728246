import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axiosClient from '../../../api/axiosClient';
import { apiTokenEndpoint } from '../../../api/endpoints';
import ROLES from '../../../authentication/roleConst';
import InfoTooltip from '../../../components/TooltipWrapper';
import styles from './AuthTokenSettingsDialog.module.css';

const AuthTokenSettingsDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createdToken, setCreatedToken] = useState(null);

  // Formik validation schema
  const validationSchema = Yup.object({
    selectedRoles: Yup.array()
      .min(1, t('authToken.validation.atLeastOneRole'))
      .required(t('authToken.validation.required')),
    daysToExpire: Yup.number()
      .min(1, t('authToken.validation.minDays'))
      .required(t('authToken.validation.required')),
  });

  const formik = useFormik({
    initialValues: {
      selectedRoles: [],
      daysToExpire: 30,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axiosClient.post(apiTokenEndpoint, {
          scope: values.selectedRoles,
          daysToExpire: values.daysToExpire,
        });
        if (response.status === 201) {
          setCreatedToken(response.data.token);
          enqueueSnackbar(t('authToken.createSuccess'), { variant: 'success' });
        } else {
          throw new Error('Failed to create token.');
        }
      } catch (error) {
        enqueueSnackbar(t('authToken.createError'), { variant: 'error' });
      }
    },
  });

  // Reset form and token when the dialog opens
  useEffect(() => {
    if (open) {
      formik.resetForm();
      setCreatedToken(null);
    }
  }, [open]);

  const handleCopyToken = () => {
    navigator.clipboard.writeText(createdToken);
    enqueueSnackbar(t('authToken.copySuccess'), { variant: 'info' });
  };

  return (
    <Dialog open={open} onClose={() => onClose(createdToken ? true : false)} variant="qualitatio">
      <DialogTitle>{t('authToken.createDialogTitle')}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {!createdToken ? (
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="subtitle1" gutterBottom>
              {t('authToken.dialogDescription')}
            </Typography>
            <Box display="flex" alignItems="center">
              <Autocomplete
                multiple
                options={Object.values(ROLES)}
                value={formik.values.selectedRoles}
                onChange={(event, newValue) => formik.setFieldValue('selectedRoles', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={option} label={option} {...getTagProps({ index })} />
                  ))
                }
                className={styles.autoComplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('authToken.roles')}
                    placeholder={t('authToken.selectRolesPlaceholder')}
                    error={formik.touched.selectedRoles && Boolean(formik.errors.selectedRoles)}
                    helperText={formik.touched.selectedRoles && formik.errors.selectedRoles}
                  />
                )}
                fullWidth
                margin="normal"
              />
              <InfoTooltip description={t('authToken.rolesDescription')} />
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                label={t('authToken.daysToExpire')}
                type="number"
                value={formik.values.daysToExpire}
                onChange={formik.handleChange}
                name="daysToExpire"
                fullWidth
                margin="normal"
                error={formik.touched.daysToExpire && Boolean(formik.errors.daysToExpire)}
                helperText={formik.touched.daysToExpire && formik.errors.daysToExpire}
              />
              <InfoTooltip description={t('authToken.daysToExpireDescription')} />
            </Box>
          </form>
        ) : (
          <Box className={styles.tokenDisplay}>
            <Typography variant="body1" gutterBottom>
              {t('authToken.tokenGenerated')}
            </Typography>
            <TextField
              value={createdToken}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCopyToken}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="caption" color="error">
              {t('authToken.tokenWarning')}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={styles.buttonGrid}>
        {!createdToken ? (
          <>
            <Button
              onClick={formik.handleSubmit}
              variant="qualitatio"
              color="primary"
              className={styles.addButton}
            >
              {t('save')}
            </Button>
            <Button variant="qualitatio" onClick={() => onClose()} color="secondary">
              {t('cancel')}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => onClose(true)}
            variant="qualitatio"
            color="primary"
            className={styles.addButton}
          >
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

AuthTokenSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AuthTokenSettingsDialog.defaultProps = {
  open: false,
};

export default AuthTokenSettingsDialog;
