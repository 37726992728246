const NIOButton = ({ label, onClick }) => {
    return (
        <div className="nio-frame">
            <div className="group-wrapper" onClick={() => onClick()}>
                <div className="img-wrapper">
                    <img
                        className="mask-group-3"
                        alt="Mask group"
                        src="/images/Cross.svg"
                    />
                </div>
            </div>
            <div className="frame-11">
                <div className="text-wrapper-9">{label}</div>
            </div>
        </div>
    )
}

export default NIOButton;