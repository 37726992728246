export const getUserInitialValues = (user) => {
  return {
    name: user?.name || '',
    email: user?.email || '',
    password: '',
    role: user?.role || '',
    authSystem: user?.authSystem || '',
    language: user?.language || '',
    profileImage: user?.profileImage || '',
  };
};

export const getMonitoredSystemInitialValues = (selectedSystem) => {
  return {
    name: selectedSystem?.name || '',
    inputStreamID: selectedSystem?.inputStreamID || '',
    fields: selectedSystem?.fields || [],
    frequency: selectedSystem?.frequency || 60,
    days: selectedSystem?.days || [1, 2, 3, 4, 5, 6, 7],
    timespan: selectedSystem?.timespan || { start: '6', end: '18' },
    connectedUsers: selectedSystem?.connectedUsers || [],
    anomalyDetectionModel: selectedSystem?.anomalyDetectionModel || '',
    timeseriesForecastModel: selectedSystem?.timeseriesForecastModel || '',
    sampleSize: selectedSystem?.sampleSize || 50,
    forecastHorizon: selectedSystem?.forecastHorizon || 4,
  };
};

export const connectionSetupInitialValues = {
  name: '',
  description: '',
  type: '',
  maxSize: 5000,
  productIDFieldName: '',
  kafka: {
    bootstrapServers: '',
    topic: '',
    keyConverter: 'org.apache.kafka.connect.storage.StringConverter',
    valueConverter: 'org.apache.kafka.connect.storage.StringConverter',
    endpointIdentificationAlgorithm: 'https',
    securityProtocol: 'SASL_SSL',
    saslMechanism: 'PLAIN',
    saslJAASConfig: 'org.apache.kafka.common.security.plain.PlainLoginModule',
    username: '',
    password: '',
    consumerEndpointIdentificationAlgorithm: 'https',
    consumerSecurityProtocol: 'SASL_SSL',
    consumerSaslMechanism: 'PLAIN',
    consumerSaslJAASConfig: 'org.apache.kafka.common.security.plain.PlainLoginModule',
    consumerUsername: '',
    consumerPassword: '',
    consumerGroupID: '',
  },
  api: {
    url: '',
    port: '',
    securityProtocol: 'ws',
    authentication: {
      method: '',
      credentials: {
        token: '',
        username: '',
        password: '',
      },
    },
  },
};

export const oAuthSettingsInitialValues = () => {
  return {
    providerName: '',
    active: true,
    clientID: '',
    clientSecret: '',
    authorizationURL: '',
    tokenExchangeURL: '',
    scopes: [''],
    userInformation: {
      mapping: [
        { key: 'email', value: '' },
        { key: 'name', value: '' },
        { key: 'groups', value: '' },
      ],
      url: '',
      method: 'get',
      headers: [],
      params: [],
    },
  };
};
