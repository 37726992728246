import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function createLabelText() {

    const { t } = useTranslation();;

    const labelText = {
        noRowsLabel: t("noRowsLabel"),
        noResultsOverlayLabel: t("noResultsOverlayLabel"),

        // Density selector toolbar button text
        toolbarDensity: t("toolbarDensity"),
        toolbarDensityLabel: t("toolbarDensityLabel"),
        toolbarDensityCompact: t("toolbarDensityCompact"),
        toolbarDensityStandard: t("toolbarDensityStandard"),
        toolbarDensityComfortable: t("toolbarDensityComfortable"),

        // Columns selector toolbar button text
        toolbarColumns: t("toolbarColumns"),
        toolbarColumnsLabel: t("toolbarColumnsLabel"),

        // Filters toolbar button text
        toolbarFilters: t("toolbarFilters"),
        toolbarFiltersLabel: t("toolbarFiltersLabel"),
        toolbarFiltersTooltipHide: t("toolbarFiltersTooltipHide"),
        toolbarFiltersTooltipShow: t("toolbarFiltersTooltipShow"),
        toolbarFiltersTooltipActive: (count) =>
            count !== 1 ? (count + ' ' + t("columnHeaderFiltersTooltipActiveSingular")) : (count + ' ' + t("columnHeaderFiltersTooltipActivePlural")),

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: t("toolbarQuickFilterPlaceholder"),
        toolbarQuickFilterLabel: t("toolbarQuickFilterLabel"),
        toolbarQuickFilterDeleteIconLabel: t("toolbarQuickFilterDeleteIconLabel"),

        // Export selector toolbar button text
        toolbarExport: t("toolbarExport"),
        toolbarExportLabel: t("toolbarExportLabel"),
        toolbarExportCSV: t("toolbarExportCSV"),
        toolbarExportPrint: t("toolbarExportPrint"),
        toolbarExportExcel: t("toolbarExportExcel"),

        // Columns panel text
        columnsPanelTextFieldLabel: t("columnsPanelTextFieldLabel"),
        columnsPanelTextFieldPlaceholder: t("columnsPanelTextFieldPlaceholder"),
        columnsPanelDragIconLabel: t("columnsPanelDragIconLabel"),
        columnsPanelShowAllButton: t("columnsPanelShowAllButton"),
        columnsPanelHideAllButton: t("columnsPanelHideAllButton"),

        // Filter panel text
        filterPanelAddFilter: t("filterPanelAddFilter"),
        filterPanelRemoveAll: t("filterPanelRemoveAll"),
        filterPanelDeleteIconLabel: t("filterPanelDeleteIconLabel"),
        filterPanelLogicOperator: t("filterPanelLogicOperator"),
        filterPanelOperator: t("filterPanelOperator"),
        filterPanelOperatorAnd: t("filterPanelOperatorAnd"),
        filterPanelOperatorOr: t("filterPanelOperatorOr"),
        filterPanelColumns: t("filterPanelColumns"),
        filterPanelInputLabel: t("filterPanelInputLabel"),
        filterPanelInputPlaceholder: t("filterPanelInputPlaceholder"),

        // Filter operators text
        filterOperatorContains: t("filterOperatorContains"),
        filterOperatorEquals: t("filterOperatorEquals"),
        filterOperatorStartsWith: t("filterOperatorStartsWith"),
        filterOperatorEndsWith: t("filterOperatorEndsWith"),
        filterOperatorIs: t("filterOperatorIs"),
        filterOperatorNot: t("filterOperatorNot"),
        filterOperatorAfter: t("filterOperatorAfter"),
        filterOperatorOnOrAfter: t("filterOperatorOnOrAfter"),
        filterOperatorBefore: t("filterOperatorBefore"),
        filterOperatorOnOrBefore: t("filterOperatorOnOrBefore"),
        filterOperatorIsEmpty: t("filterOperatorIsEmpty"),
        filterOperatorIsNotEmpty: t("filterOperatorIsNotEmpty"),
        filterOperatorIsAnyOf: t("filterOperatorIsAnyOf"),
        'filterOperator=': '=',
        'filterOperator!=': '!=',
        'filterOperator>': '>',
        'filterOperator>=': '>=',
        'filterOperator<': '<',
        'filterOperator<=': '<=',

        // Header filter operators text
        headerFilterOperatorContains: t("headerFilterOperatorContains"),
        headerFilterOperatorEquals: t("headerFilterOperatorEquals"),
        headerFilterOperatorStartsWith: t("headerFilterOperatorStartsWith"),
        headerFilterOperatorEndsWith: t("headerFilterOperatorEndsWith"),
        headerFilterOperatorIs: t("headerFilterOperatorIs"),
        headerFilterOperatorNot: t("headerFilterOperatorNot"),
        headerFilterOperatorAfter: t("headerFilterOperatorAfter"),
        headerFilterOperatorOnOrAfter: t("headerFilterOperatorOnOrAfter"),
        headerFilterOperatorBefore: t("headerFilterOperatorBefore"),
        headerFilterOperatorOnOrBefore: t("headerFilterOperatorOnOrBefore"),
        headerFilterOperatorIsEmpty: t("headerFilterOperatorIsEmpty"),
        headerFilterOperatorIsNotEmpty: t("headerFilterOperatorIsNotEmpty"),
        headerFilterOperatorIsAnyOf: t("headerFilterOperatorIsAnyOf"),
        'headerFilterOperator=': t("headerFilterOperator="),
        'headerFilterOperator!=': t("headerFilterOperator!="),
        'headerFilterOperator>': t("headerFilterOperator>"),
        'headerFilterOperator>=': t("headerFilterOperator>="),
        'headerFilterOperator<': t("headerFilterOperator<"),
        'headerFilterOperator<=': t("headerFilterOperator<="),

        // Filter values text
        filterValueAny: t("filterValueAny"),
        filterValueTrue: t("filterValueTrue"),
        filterValueFalse: t("filterValueFalse"),

        // Column menu text
        columnMenuLabel: t("columnMenuLabel"),
        columnMenuShowColumns: t("columnMenuShowColumns"),
        columnMenuManageColumns: t("columnMenuManageColumns"),
        columnMenuFilter: t("columnMenuFilter"),
        columnMenuHideColumn: t("columnMenuHideColumn"),
        columnMenuUnsort: t("columnMenuUnsort"),
        columnMenuSortAsc: t("columnMenuSortAsc"),
        columnMenuSortDesc: t("columnMenuSortDesc"),

        // Column header text
        columnHeaderFiltersTooltipActive: (count) =>
            count !== 1 ? (count + t("columnHeaderFiltersTooltipActiveSingular")) : (count + t("columnHeaderFiltersTooltipActivePlural")),
        columnHeaderFiltersLabel: t("columnHeaderFiltersLabel"),
        columnHeaderSortIconLabel: t("columnHeaderSortIconLabel"),

        // Rows selected footer text
        footerRowSelected: (count) =>
            count !== 1
                ? (count.toLocaleString() + ' ' + t("footerRowSelectedSingular"))
                : (count.toLocaleString() + ' ' + t("footerRowSelectedPlural")),

        // Total row amount footer text
        footerTotalRows: t("footerTotalRows"),

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) =>
            visibleCount.toLocaleString() + ' ' + t("of") + ' ' + totalCount.toLocaleString(),

        // Checkbox selection text
        checkboxSelectionHeaderName: t("checkboxSelectionHeaderName"),
        checkboxSelectionSelectAllRows: t("checkboxSelectionSelectAllRows"),
        checkboxSelectionUnselectAllRows: t("checkboxSelectionUnselectAllRows"),
        checkboxSelectionSelectRow: t("checkboxSelectionSelectRow"),
        checkboxSelectionUnselectRow: t("checkboxSelectionUnselectRow"),

        // Boolean cell text
        booleanCellTrueLabel: t("booleanCellTrueLabel"),
        booleanCellFalseLabel: t("booleanCellFalseLabel"),

        // Actions cell more text
        actionsCellMore: t("actionsCellMore"),

        // Column pinning text
        pinToLeft: t("pinToLeft"),
        pinToRight: t("pinToRight"),
        unpin: t("unpin"),

        // Tree Data
        treeDataGroupingHeaderName: t("treeDataGroupingHeaderName"),
        treeDataExpand: t("treeDataExpand"),
        treeDataCollapse: t("treeDataCollapse"),

        // Grouping columns
        groupingColumnHeaderName: t("groupingColumnHeaderName"),
        groupColumn: (name) => t("groupColumn") + ` ${name}`,
        unGroupColumn: (name) => t("unGroupColumn") + ` ${name}` + ` ${t("undo")}`,

        // Master/detail
        detailPanelToggle: t("detailPanelToggle"),
        expandDetailPanel: t("expandDetailPanel"),
        collapseDetailPanel: t("collapseDetailPanel"),

        // Used core components translation keys
        MuiTablePagination: {},

        // Row reordering text
        rowReorderingHeaderName: t("rowReorderingHeaderName"),

        // Aggregation
        aggregationMenuItemHeader: t("aggregationMenuItemHeader"),
        aggregationFunctionLabelSum: t("aggregationFunctionLabelSum"),
        aggregationFunctionLabelAvg: t("aggregationFunctionLabelAvg"),
        aggregationFunctionLabelMin: t("aggregationFunctionLabelMin"),
        aggregationFunctionLabelMax: t("aggregationFunctionLabelMax"),
        aggregationFunctionLabelSize: t("aggregationFunctionLabelSize"),

    }

    return labelText;
}