import {
  LineChart,
  MarkElement,
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';
import PropTypes from 'prop-types';

export default function TrendsChart({
  width,
  height,
  dataLine,
  centerLine,
  controlLimit,
  timestamps,
  selectedDateRange,
}) {
  return (
    <LineChart
      width={width}
      height={height}
      series={[
        {
          data: dataLine,
          label: 'Data',
          id: 'dataId',
          showMark: false,
        },
        {
          data: controlLimit,
          label: 'Control Limit',
          id: 'uclId',
          showMark: false,
          color: 'red',
        },
        { data: centerLine, label: 'Mean', id: 'clId', showMark: false, color: 'grey' },
      ]}
      xAxis={[
        {
          scaleType: 'time',
          data: timestamps.map((timestamp) => new Date(timestamp)),
          min: selectedDateRange[0],
          max: selectedDateRange[1],
        },
      ]}
      sx={{
        '--ChartsLegend-itemMarkSize': '10px',
        [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
          strokeWidth: 1,
        },
        '.MuiLineElement-series-clId': {
          strokeDasharray: '5 5',
        },
        '.MuiLineElement-series-uclId': {
          strokeDasharray: '5 5',
        },
        [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
          fill: '#fff',
        },
        [`& .${markElementClasses.highlighted}`]: {
          stroke: 'none',
        },
      }}
    >
      <MarkElement> </MarkElement>
    </LineChart>
  );
}

TrendsChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dataLine: PropTypes.array.isRequired,
  centerLine: PropTypes.array.isRequired,
  controlLimit: PropTypes.array.isRequired,
  timestamps: PropTypes.array.isRequired,
  selectedDateRange: PropTypes.array.isRequired,
};
