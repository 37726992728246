import React, { createContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import ROLES from '../authentication/roleConst';
import { useAuthStore } from '../store/auth.store';

/**
 * Context for managing WebSocket connections within the application.
 * @type {React.Context<{socket: SocketIOClient.Socket | null, error: Error | null}>}
 */
export const WebSocketContext = createContext();

/**
 * Provides a WebSocket connection and error state to the component tree.
 * This component establishes a WebSocket connection when the user is authenticated
 * and cleans up the connection when the user is not authenticated or when the component unmounts.
 *
 * @param {{ children: React.ReactNode }} props Component props containing child components.
 * @returns {JSX.Element} A provider component that supplies WebSocket connection state to its children.
 */
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const { isAuthenticated, user } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  }));
  const debounceRef = useRef(null);

  //for now only admin is allowed
  const allowedRoles = [ROLES.ADMIN];

  /*   const allowedRoles = Object.values(ROLES).filter(
    (role) => !role.toLocaleLowerCase().includes('user')
  ); */

  /**
   * Connects to the WebSocket server with a debounced approach.
   * If a connection is already established, it directly resolves to the existing socket.
   * This function attempts to connect to the server specified in SERVER_URL.
   */
  const connectWebSocket = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (socket?.connected) {
        return Promise.resolve(socket);
      }

      // will be replaced by the actual URL during the build process through the entrypoint.sh script
      const SERVER_URL =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL
          : '%REACT_APP_API_URL%';
      const newSocket = io(SERVER_URL, { withCredentials: true });

      newSocket.on('connect', () => {
        setSocket(newSocket);
        setError(null);
        console.log('Connected to WebSocket server');
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
      });

      newSocket.on('connect_error', (err) => {
        console.error('WebSocket Connection error:', err);
        setError(err);
      });
    }, 500); // 500ms debounce delay
  };

  // Automatically connect if authenticated and user is admin or manager
  useEffect(() => {
    if (isAuthenticated) {
      if (allowedRoles.includes(user?.role)) {
        connectWebSocket();
      }
    } else {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    }
  }, [isAuthenticated, connectWebSocket]);

  // Expose the connect method and the current socket
  const value = {
    socket,
    error,
  };

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
};

/**
 * Example Usage:
 *
 * A component that listens for a specific WebSocket event and reacts accordingly.
 * It showcases the use of the WebSocket context within a useEffect hook, demonstrating
 * how to subscribe and cleanly unsubscribe from WebSocket events.
 *
 * @example
 * const MyComponent = () => {
 *   const { socket } = useContext(WebSocketContext);
 *
 *   useEffect(() => {
 *     if (!socket) return;
 *
 *     const handleEvent = (data) => {
 *       console.log('Event received:', data);
 *     };
 *
 *     socket.on('eventName', handleEvent);
 *
 *     // Cleanup subscription
 *     return () => {
 *       socket.off('eventName', handleEvent);
 *     };
 *   }, [socket]); // Re-subscribe if socket changes
 *
 *   return <div>Component content</div>;
 * };
 */
