import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import Rule from '@mui/icons-material/Rule';
import ShutterSpeedRoundedIcon from '@mui/icons-material/ShutterSpeedRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModulCard } from '../components/ModulCard';
import PageFrame from '../components/PageFrame';

export const EfficientTesting = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('efficientTesting'));

  return (
    <PageFrame title={t('efficientTesting')} Icon={ShutterSpeedRoundedIcon} particlesActive={true}>
      <div className="menu-page">
        <div className="text-wrapper-5">{t('welcomeToEfficientTestingModule')}</div>
        <p className="p">{t('youCanChooseFromTheFollowingFunctionalities')}</p>
        <div className="moduleCardWrapper">
          <ModulCard
            module={t('productionConfigurator')}
            moduleText={t('configurationOfTestStationsAndTestStrategies')}
            icon={<AccountTreeRoundedIcon color="primary" fontSize="large" />}
            href="/efficient_testing/configurator"
          />
          <ModulCard
            module={t('efficientTesting')}
            moduleText={t('aiBasedTestVolumeOptimization')}
            icon={<AltRouteRoundedIcon color="primary" fontSize="large" />}
            href="/efficient_testing/recommender/select"
          />
          <ModulCard
            module={t('ruleManagement')}
            moduleText={t('ruleManagementText')}
            icon={<Rule color="primary" fontSize="large" />}
            href="/efficient_testing/rules"
          />
        </div>
      </div>
    </PageFrame>
  );
};

export default EfficientTesting;
