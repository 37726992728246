import 'chart.js/auto';
import 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

function SystemInfoChart({
  dataPoints,
  selectedField,
  mean,
  ninetiethPercentile,
  criticalThresholds,
  warnThresholds,
}) {
  const { t } = useTranslation();

  const sortedDataPoints = [...dataPoints].sort((a, b) => new Date(a._time) - new Date(b._time));
  const labels = sortedDataPoints.map((point) => new Date(point._time).toLocaleTimeString());

  // Define the dataset for the selected field
  const fieldDataset = {
    label: `${selectedField} ${t('values')}`,
    data: sortedDataPoints.map((point) => point[selectedField]),
    borderColor: 'rgb(75, 192, 192)',
    tension: 0.1,
    fill: false,
  };

  // Define datasets for mean, 90th percentile, and manual thresholds
  const meanDataset = {
    label: t('mean'),
    data: Array(labels.length).fill(mean),
    borderColor: 'rgb(153, 102, 255)',
    borderDash: [5, 5],
    fill: false,
    pointRadius: 0,
  };

  const percentileDataset = {
    label: t('ews.ninetiethPercentile'),
    data: Array(labels.length).fill(ninetiethPercentile),
    borderColor: 'rgb(54, 162, 235)',
    borderDash: [5, 5],
    fill: false,
    pointRadius: 0,
  };

  const warnThresholdsDatasets = warnThresholds.map((threshold, index) => ({
    label: `${index === 0 ? t('ews.lowerWarnThreshold') : t('ews.upperWarnThreshold')}`,
    data: Array(labels.length).fill(threshold),
    borderColor: 'rgb(255, 165, 0)', // Orange color for warning thresholds
    borderDash: [5, 5],
    fill: false,
    pointRadius: 0,
  }));

  // Critical Thresholds Dataset
  const criticalThresholdsDatasets = criticalThresholds.map((threshold, index) => ({
    label: `${index === 0 ? t('ews.lowerCriticalThreshold') : t('ews.upperCriticalThreshold')}`,
    data: Array(labels.length).fill(threshold),
    borderColor: 'rgb(255, 0, 0)', // Red color for critical thresholds
    borderDash: [5, 5],
    fill: false,
    pointRadius: 0,
  }));

  const chartData = {
    labels,
    datasets: [
      fieldDataset,
      meanDataset, // Mean dataset definition here
      percentileDataset, // 90th percentile dataset definition here
      ...warnThresholdsDatasets,
      ...criticalThresholdsDatasets,
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
  };

  return <Line data={chartData} options={options} />;
}

SystemInfoChart.propTypes = {
  dataPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedField: PropTypes.string.isRequired,
  mean: PropTypes.number.isRequired,
  ninetiethPercentile: PropTypes.number.isRequired,
  criticalThresholds: PropTypes.arrayOf(PropTypes.number),
  warnThresholds: PropTypes.arrayOf(PropTypes.number),
};

SystemInfoChart.defaultProps = {
  criticalThresholds: [],
  warnThresholds: [],
};

export default SystemInfoChart;
