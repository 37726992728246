import axiosClient from '../../../../api/axiosClient';
import {
  aiProcessingInputEndpoint,
  allowedModulesEndpoint,
  intelligentDocumentationSettingsEndpoint,
} from '../../../../api/endpoints';

/**
 *
 * @param {Blob} blob
 * @param {boolean} isAudio
 *      true: audio (default)
 *      false: image
 * @param {boolean} useExternalAI
 *      If not provided, the value from Intelligent Documentation Settings is applied.
 *      true: external AI
 *      false: local AI
 * @returns response from backend / AI model
 */
export const triggerAIProcessing = async (blob, isAudio = true, useExternalAI = undefined) => {
  // conversion from boolean to number
  if (useExternalAI == undefined) {
    useExternalAI = await handleExternalAiSetting(isAudio);
  }

  const type = isAudio ? 'audio' : 'image';
  const fileNameExtension = isAudio ? '.wav' : '.jpeg';
  const typeOfAI = useExternalAI ? 'remote' : 'local';
  const mimeType = isAudio ? 'audio/wav' : 'image/jpeg';
  const fileName = 'audio' + fileNameExtension;

  // Download recorded audio file for testing purposes
  // if (isAudio)
  //     download(blob, fileName, mimeType)
  const file = new File([blob], fileName, { type: mimeType });
  const formData = new FormData();
  formData.append('file', file);

  const apiEndpoint = `${aiProcessingInputEndpoint}?type=${type}&typeOfAI=${typeOfAI}`;
  let response = '';
  try {
    response = await axiosClient.post(apiEndpoint, formData);
  } catch (error) {
    console.error(error);
  }

  return response;
};

const handleExternalAiSetting = async (isAudio) => {
  const settings = await getIntelliDocSettings();
  const useExternalAI = isAudio ? settings.externalTextAPIUsage : settings.externalImageAPIUsage;
  if (useExternalAI == undefined) {
    useExternalAI = false;
    console.error(
      'Response from API for intelligentDocumentationSettings did not contain at least one of the expected keys: ' +
        '"externalTextAPIUsage", "externalImageAPIUsage". Has the format changed?\n' +
        'AI Usage for this request defaulted to "local".'
    );
  }
  return useExternalAI;
};

/**
 * @returns API response containing intelligent documentation settings in the format { settings_key: boolean }
 */
export const getIntelliDocSettings = async () => {
  const response = await axiosClient.get(intelligentDocumentationSettingsEndpoint);
  return response.data.idSettings;
};

/**
 * This function gets a users license and uses the given setterMethod to set the return value (true/false).
 * @param {Function} setIntelliDocLicense function to control React state intelliDocLicense
 */
export const getAndSetIntelliDocLicense = async (setIntelliDocLicense) => {
  setIntelliDocLicense(await getIntelliDocLicense());
};

/**
 *
 * @returns true if user has a valid license for intelligent documentation, false otherwise or if no info can be obtained
 */
export const getIntelliDocLicense = async () => {
  const response = await axiosClient.get(allowedModulesEndpoint);
  if (response.data.modules)
    return response.data.modules.includes('Intelligent Documentation') && !response.data.expired;
  return false;
};

/**
 * This function saves a Blob as file of a given mimetype.
 * @param {Blob} blob file content as blob
 * @param {string} filename choose name of file
 * @param {string} type has to be a mimetype
 */
export function saveBlobAsFile(blob, filename, type) {
  var file = new Blob([blob], { type: type });
  if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    let a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

/**
 * Checks if the AI response provides data at a certain key
 * @param {object} responseData
 * @param {string} key
 *
 * @returns {[{_id: string, name: string}]} list of options from the AI reponse if responseData contains an object with an _id not equal to "None of the above" at the given key
 *          null otherwise
 */
export function getDataFromResponse(responseData, key) {
  if (
    responseData?.hasOwnProperty(key) &&
    responseData[key][0]?.hasOwnProperty('_id') &&
    responseData[key][0]._id != 'None of the above'
  ) {
    return responseData[key];
  }
  return null;
}

/**
 * This function can be used to download recorded audio data to a file, which might be useful for testing purposes.
 * @param {Blob} blob
 * @param {String} filename
 * @param {String} mimetype
 */
function download(blob, filename, mimetype) {
  var file = new Blob([blob], { type: mimetype });
  if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    let a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

/**
 * Moves element at position <fromIdx> to the position <toIdx> inside of <array>.
 * The given array is changed in-place.
 * @param {Array} array
 * @param {Number} fromIdx
 * @param {Number} toIdx
 */
export function reorderArray(array, fromIdx, toIdx) {
  array.splice(toIdx, 0, array.splice(fromIdx, 1)[0]);
}
