// src/components/ETRuleTable.jsx

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Switch, Typography } from '@mui/material';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createLabelText from '../../../components/QualitatioTable/tableLanguages';
import { activateRule, deactivateRule, getRuleData, getRulesTableData } from './utils';

const ETRuleTable = forwardRef(function ETRuleTable(props, ref) {
  const { t } = useTranslation();
  const labelText = createLabelText();
  const { enqueueSnackbar } = useSnackbar();
  const { onEditRule, onDeleteRule, onSelectionModelChange, selectedRuleIds } = props;

  const handleEdit = (id) => {
    try {
      getRuleData(id).then((response) => {
        onEditRule(response);
      });
    } catch (error) {
      console.error('Error getting rule data:', error);
    }
  };

  const handleDelete = (id) => {
    onDeleteRule(id);
  };

  const handleToggleIsActive = async (id, isActive) => {
    try {
      if (isActive) {
        await deactivateRule(id).then(async () => {
          await fetchRulesTableData();
          enqueueSnackbar(t('ruleDeactivated'), { variant: 'success' });
        });
      } else {
        await activateRule(id).then(async () => {
          if (new Date(rows.find((row) => row.id === id).expirationDate) < new Date()) {
            enqueueSnackbar(t('ruleExpired'), { variant: 'warning' });
          } else {
            fetchRulesTableData();
            enqueueSnackbar(t('ruleActivated'), { variant: 'success' });
          }
        });
      }
    } catch (error) {
      console.error('Error toggling isActive:', error);
    }
  };

  const columns = [
    {
      headerName: t('ruleName'),
      field: 'ruleName',
      flex: 1,
    },
    {
      headerName: t('qualityStation'),
      field: 'qualityStation',
      flex: 1,
    },
    {
      headerName: t('expirationDate'),
      field: 'expirationDate',
      flex: 1,
      renderCell: (params) => {
        const expirationDate = new Date(params.value);
        const now = new Date();
        const timeDiff = expirationDate - now;

        let color = 'inherit';
        if (timeDiff <= 0) {
          color = '#BA1A1A'; // Expired
        } else if (timeDiff <= 24 * 60 * 60 * 1000) {
          // Less than 5 minutes remaining
          color = '#FFF5D6';
        }

        return (
          <Grid item display="flex" justifyContent="center" height={'100%'} direction={'column'}>
            <Typography variant="body2" sx={{ color }}>
              {expirationDate.toLocaleString()}
            </Typography>
          </Grid>
        );
      },
    },
    {
      headerName: t('isActive'),
      field: 'isActive',
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={() => handleToggleIsActive(params.row.id, params.value)}
          color="primary"
        />
      ),
    },
    {
      headerName: t('actions'),
      field: 'actions',
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const [rows, setRows] = useState([]);

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const fetchRulesTableData = async () => {
    try {
      const data = await getRulesTableData();
      setRows(data);
    } catch (error) {
      console.error('Error fetching rules table data:', error);
    }
  };

  useEffect(() => {
    fetchRulesTableData();
  }, []);

  // Expose the refreshData method to parent components
  useImperativeHandle(ref, () => ({
    refreshData: () => {
      fetchRulesTableData();
    },
  }));

  return (
    <Grid
      item
      spacing={2}
      display="flex"
      justifyContent="center"
      direction="column"
      sx={{ height: '67vh' }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        checkboxSelection
        variant="qualitatio"
        localeText={labelText}
        onRowSelectionModelChange={onSelectionModelChange}
        rowSelectionModel={selectedRuleIds}
        disableRowSelectionOnClick
        pagination
        autoPageSize
        slots={{
          toolbar: customToolbar,
        }}
      />
    </Grid>
  );
});

ETRuleTable.propTypes = {
  props: PropTypes.object,
  ref: PropTypes.object,
};

export default ETRuleTable;

ETRuleTable.propTypes = {
  onEditRule: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
  onSelectionModelChange: PropTypes.func,
  selectedRuleIds: PropTypes.array,
};
