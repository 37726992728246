import { Fab, Typography } from '@mui/material';

export const SwipeCardButtonWithLabel = ({ Icon, label, handleClick, backgroundColor, color = "white", labelStyle = {} }) => {
    return (
        <>
            <Fab variant="round" size="small" style={{ backgroundColor: backgroundColor, color: color, }} onClick={() => handleClick()}>
                <Icon />
            </Fab>
            <Typography variant="subtitle2" color="text.secondary" style={{ marginTop: '8px', ...labelStyle }}>
                {label}
            </Typography>
        </>
    );
}

export default SwipeCardButtonWithLabel;