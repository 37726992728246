import {
    Button,
    Grid,
    LinearProgress
} from '@mui/material';
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { defaultLanguageEndpoint } from '../../../api/endpoints';
import { QualitatioLanguageSelector } from '../../../components';

function LanguageSettings() {
    const [language, setLanguage] = useState(null);

    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();;

    useEffect(() => {
        try {
            const fetchLanguage = async () => {
                setLoading(true);
                const response = await axiosClient.get(defaultLanguageEndpoint);
                const { data } = response;
                setLanguage(data.language);
                setLoading(false);
            }
            fetchLanguage();
        } catch (error) {
            enqueueSnackbar(t("fetchLanguageError") || "Something went wrong!", {
                variant: "error",
            });
        }
    }, []);


    const handleSubmit = async (values) => {
        try {
            const response = await axiosClient.post(defaultLanguageEndpoint, {
                language: language,
            });
            enqueueSnackbar(t("languageSaved") || "Language saved!", {
                variant: "success",
            });

        } catch (error) {
            enqueueSnackbar(t("languageSavedError") || "Something went wrong!", {
                variant: "error",
            });
        }
    };

    if (loading) {
        return <LinearProgress />
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <QualitatioLanguageSelector
                    value={language || ''}
                    onChange={(event, newValue) => {
                        setLanguage(newValue)
                    }}
                    resetOnOpen={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="qualitatio"
                    color="primary"
                    onClick={handleSubmit}
                >
                    {t("save")}
                </Button>
            </Grid>
        </Grid>
    );
}

export default LanguageSettings;


