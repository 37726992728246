import { Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaLayerGroup } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { TbLicense } from "react-icons/tb";
import axiosClient from '../../../api/axiosClient';
import { licenseEndpoint } from '../../../api/endpoints';
import { QualitatioDropzone } from '../../../components';

const LicenseSettings = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [license, setLicense] = useState(null);

    const getLicense = async () => {
        try {
            const response = await axiosClient.get(licenseEndpoint);
            if (response.data.license) {
                setLicense(response.data.license);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getLicense();
    }, []);

    const uploadLicenseFile = async (licenseFile) => {
        const formData = new FormData();
        formData.append('licenseFile', licenseFile);
        try {
            const response = await axiosClient.post(licenseEndpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.success) {
                enqueueSnackbar(t("licenseFileUploadedSuccessfully"), { variant: 'success' });
                setLicense(response.data.license);
            }
        } catch (error) {
            enqueueSnackbar(t("errorWhenUploadingLicenseFile"), { variant: 'error' });
            console.error('Error:', error);
        }
    }

    const formatDate = (date) => {
        return date ? new Date(date * 1000).toLocaleDateString() : "";
    }

    const secondsToWeeks = (seconds) => {
        return seconds ? seconds / 60 / 60 / 24 / 7 : "";
    }

    return (
        <Grid spacing={2} style={{ maxWidth: "100%" }}>
            <QualitatioDropzone
                onDrop={uploadLicenseFile}
                enablePreview={false}
                acceptedFileTypes={{ 'text/License': ['.License'] }}
            >
                <TbLicense size={30} />
                <Typography>
                    {t("dropLicenseHere")}
                </Typography>
                <Typography>
                    {t("orClickToUpload")}
                </Typography>
            </QualitatioDropzone>
            <TableContainer>
                <Table style={{ overflowX: 'auto', maxWidth: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t("licenseType")}
                            </TableCell>
                            <TableCell>
                                {t("licenseResponsible")}
                            </TableCell>
                            <TableCell>
                                {t("creationDate")}
                            </TableCell>
                            <TableCell>
                                {t("expirationDate")}
                            </TableCell>
                            <TableCell>
                                {t("expirationBearingInterval")}
                            </TableCell>
                            <TableCell>
                                {t("includedModules")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {license?.label.replaceAll("DO NOT DISTRIBUTE", "")}
                            </TableCell>
                            <TableCell>
                                {license?.user_name}
                            </TableCell>
                            <TableCell>
                                {license && (
                                    <Chip
                                        label={formatDate(license?.creation_time)}
                                        sx={{ backgroundColor: theme.palette.warning.main }}
                                        icon={<FaCalendarAlt />}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {license && (
                                    <Chip
                                        label={formatDate(license?.expiration_time)}
                                        sx={{ backgroundColor: theme.palette.warning.main }}
                                        icon={<FaCalendarAlt />}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {license && (
                                    <Chip
                                        label={secondsToWeeks(license?.expiration_bearing_interval) === 1 ? secondsToWeeks(license?.expiration_bearing_interval) + " " + t("week") : secondsToWeeks(license?.expiration_bearing_interval) + " " + t("weeks")}
                                        sx={{ backgroundColor: theme.palette.warning.main }}
                                        icon={<IoIosWarning />}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {license?.allowed_modules?.module.map((module, idx) => (
                                    <Chip
                                        style={{ margin: "2px 5px" }}
                                        key={idx}
                                        label={module}
                                        sx={{ backgroundColor: theme.palette.success.secondary }}
                                        icon={<FaLayerGroup />}
                                    />
                                ))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default LicenseSettings;