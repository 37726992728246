import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function QualitatioAccordion({
  accordionData,
  width = '100%',
  orderIdentifier = null,
  setUpdateState = null,
  updateState = false,
}) {
  const [expanded, setExpanded] = React.useState('');

  React.useEffect(() => {
    if (orderIdentifier !== null) {
      setExpanded('panel' + orderIdentifier); // Directly set the expanded panel
    }
  }, [orderIdentifier])


  const handleChange = (panel) => (event, newExpanded) => {
    setUpdateState && setUpdateState(!updateState);
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div style={{ width: width }}>
      {accordionData &&
        accordionData.map((data) => (
          <Accordion expanded={expanded === data.panel} onChange={handleChange(data.panel)}>
            <AccordionSummary aria-controls={data.panel + "-content"} id={data.panel + "-header"}>
              <Typography>{data.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {data.render}
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
}
