import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button, Chip, Grid, IconButton, MobileStepper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const SwipeCardImageStepper = ({
  images,
  card,
  currentImageIndex,
  setCurrentImageIndex,
  setIsFeedbackOpen,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const colorByGeneration = (basisRuleColor, otherColor) => {
    return card.character?.generation === t('Basis') || card.character?.generation === t('Rule')
      ? basisRuleColor
      : otherColor;
  };

  const handleNextClick = (imagesLength) => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagesLength);
  };

  const handlePreviousClick = (imagesLength) => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imagesLength) % imagesLength);
  };

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
        {images?.length !== 0 && (
          <IconButton
            style={{
              backgroundColor: colorByGeneration('#1fbebd', '#0b2034'),
              height: '25px',
              width: '25px',
            }}
            onClick={() => setIsFeedbackOpen(true)}
          >
            <StarBorderIcon style={{ color: colorByGeneration('#ffffff', '#1fbebd') }} />
          </IconButton>
        )}

        {card.character?.isWarranty && (
          <div
            style={{
              maxWidth: '50px',
              minWidth: '40px',
              zIndex: 1,
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                border: '2px solid',
                borderRadius: '50%',
                borderColor: '#0b2034',
              }}
              src={'/images/JDPowerLogo.png'}
            />
          </div>
        )}

        <Chip
          label={card.character?.generation}
          style={{
            zIndex: 1,
            height: '25px',
            backgroundColor: colorByGeneration('#1fbebd', '#0b2034'),
            color: colorByGeneration('#ffffff', '#1fbebd'),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MobileStepper
          variant="dots"
          steps={images?.length || 0}
          position="static"
          activeStep={currentImageIndex}
          sx={{
            width: '100%',
            flexGrow: 1,
            backgroundColor: theme.palette.success.secondary,
            '& .MuiMobileStepper-dots': { zIndex: 1 },
            padding: '0px 8px',
          }}
          nextButton={
            <Button
              size="small"
              onClick={() => handleNextClick(images?.length)}
              disabled={currentImageIndex >= images?.length - 1}
            >
              {t('next')}
              <KeyboardArrowRightIcon />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => handlePreviousClick(images?.length)}
              disabled={currentImageIndex === 0}
            >
              <KeyboardArrowLeftIcon />
              {t('back')}
            </Button>
          }
        />
      </Grid>
    </>
  );
};

export default SwipeCardImageStepper;
