import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import axiosClient from '../../../api/axiosClient';

// Custom hook to handle operations for all test cards (add, save, delete, etc.)
export const useCardOperations = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const addTestCard = ({
    qualityStation,
    creator, // User object,
    customFields, // Function to get custom fields for specific cards (e.g., rules or dynamic items)
    currentTestCards, // Current list of cards
    setTestCards, // Function to update the tests state
    setCurrentCardIndex, // Function to set the current card index
    getNewCardIndex, // Optional function to get the index for the new card
  }) => {
    return (card) => {
      // Common fields across all card types
      const baseCardFields = {
        qualityStation,
        title: card?.title || '',
        images: [],
        testObject: card?.testObject || '',
        testLocation: card?.testLocation || '',
        testMethod: card?.testMethod || '',
        testComment: card?.testComment || '',
        orderIdentifier: 0,
        positionIdentifier: 1000,
        creator: { creatorId: creator.id, name: creator.name, profileImage: creator.profileImage },
        creationDate: Date.now(),
        editors: [{ editorId: creator.id, name: creator.name, profileImage: creator.profileImage }],
      };
      const newCard = { ...baseCardFields, ...customFields }; // Combine common and specific fields

      const updatedCards = [...currentTestCards, newCard]; // Add the new card to the list
      setTestCards(updatedCards); // Update state with new card list
      const newIndex = getNewCardIndex ? getNewCardIndex(newCard) : updatedCards.length - 1;
      setCurrentCardIndex(newIndex); // Update current index if provided
    };
  };

  const saveTestCard = ({
    validateCards, // Function to validate the cards
    filterCardToSave, // Function to determine which cards should be saved
    cardEndpoint, // API endpoint for card type
    qualityStation,
    creator, // User object
    currentTestCards, // Current list of cards
    setTestCards, // Function to update the tests state
    groundTruthCards, // Ground truth cards for comparison
    setGroundTruthCards, // Function to update the ground truth state
    errorMessageKey, // Translation key for generic error messages
    successMessageKey, // Translation key for success messages
    callback, // Optional callback function to execute after saving
  }) => {
    const updateTestsAndGroundTruth = (savedCards) => {
      // Update current test cards with saved cards, reflecting changes or additions
      setTestCards((currentTests) => {
        const updatedTests = [...currentTests];
        const filteredCardsWithoutIds = updatedTests.filter((card) => card.id);
        savedCards.forEach((savedCard) => {
          const index = filteredCardsWithoutIds.findIndex((test) => test.id === savedCard.id);
          if (index !== -1) {
            filteredCardsWithoutIds[index] = _.cloneDeep(savedCard);
          } else {
            filteredCardsWithoutIds.push(_.cloneDeep(savedCard));
          }
        });
        return filteredCardsWithoutIds;
      });

      // Update ground truth cards to match the updated test cards
      // Ensuring ground truth is synchronized with the latest state
      setGroundTruthCards((currentGroundTruth) => {
        const updatedGroundTruth = [...currentGroundTruth];
        savedCards.forEach((savedCard) => {
          const index = updatedGroundTruth.findIndex((test) => test.id === savedCard.id);
          if (index !== -1) {
            updatedGroundTruth[index] = _.cloneDeep(savedCard); // Deep copy updated items
          } else {
            updatedGroundTruth.push(_.cloneDeep(savedCard)); // Deep copy new items
          }
        });
        return updatedGroundTruth;
      });
    };
    return async (updatedTestCards) => {
      if (!validateCards(updatedTestCards)) {
        return;
      }
      // Filter cards for saving using the provided custom filter logic
      const cardsToSave = (updatedTestCards || currentTestCards).filter((card, index) => {
        const groundTruth = groundTruthCards.find((el) => el.id === card.id);
        return filterCardToSave(card, groundTruth, index);
      });

      const errors = new Set();

      // Save each card via API
      const savedCards = await Promise.all(
        cardsToSave.map(async (card) => {
          try {
            const response = await axiosClient.post(cardEndpoint, {
              qualityStation: qualityStation,
              card: card,
              userID: creator.id,
            });
            return response.data.card; // API returns the saved card
          } catch (error) {
            if (error.response && error.response.data.message.startsWith('Duplicate card title:')) {
              errors.add(
                t('duplicateCardTitle') + ': "' + error.response.data.duplicateCardTitle + '"'
              );
            } else {
              errors.add(t(errorMessageKey));
              console.error('Error:', error);
            }
          }
        })
      );

      // Handle errors if any failing conditions
      if (errors.size > 0 || savedCards.some((card) => card === null)) {
        const duplicateTitleError = Array.from(errors).find((e) =>
          e.startsWith(t('duplicateCardTitle'))
        );
        if (duplicateTitleError) {
          enqueueSnackbar(duplicateTitleError, { variant: 'error' });
        } else {
          enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
        }
        return;
      }

      // On success, update ground truth and UI state
      updateTestsAndGroundTruth(savedCards);
      callback && callback(savedCards); // Execute callback if provided
      enqueueSnackbar(t(successMessageKey), { variant: 'success' });
    };
  };

  const deleteTestCard = ({
    indexToRemove, // Index of the card to remove
    currentTestCards, // Current list of cards
    setTestCards, // Function to update the tests state
    groundTruthCards, // Ground truth cards for comparison
    setGroundTruthCards, // Function to update the ground truth state
    setCurrentCardIndex, // Function to update the current index
    cardEndpoint, // API endpoint for card type
    errorMessageKey, // Translation key for generic error messages
    successMessageKey, // Translation key for success messages
  }) => {
    return async () => {
      const cardToDelete = currentTestCards[indexToRemove];
      const updatedTests = [...currentTestCards];
      updatedTests.splice(indexToRemove, 1);

      if (cardToDelete.id) {
        const updatedTestsGroundTruth = groundTruthCards.filter(
          (card) => card.id !== cardToDelete.id
        );
        try {
          await axiosClient.delete(cardEndpoint, {
            data: {
              cardID: cardToDelete.id,
            },
          });
          enqueueSnackbar(t(successMessageKey), { variant: 'success' });
          setGroundTruthCards(updatedTestsGroundTruth);
        } catch (error) {
          enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
          console.error('Error:', error);
          return;
        }
      }

      setTestCards(updatedTests);
      setCurrentCardIndex((prevIndex) =>
        prevIndex > 0 && prevIndex - 1 < updatedTests.length ? prevIndex - 1 : 0
      );
    };
  };

  return {
    addTestCard,
    saveTestCard,
    deleteTestCard,
  };
};
