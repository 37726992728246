import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Checkbox, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../api/axiosClient';
import {
  aiCardEndpoint,
  generateCardEndpoint,
  getAvailableAIClassesEndpoint,
  getMLModelNamesEndpoint,
} from '../../../api/endpoints';
import { Card } from '../../../components/Card';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import QualitatioChipDropdown from '../../../components/QualitatioChipDropdown/QualitatioChipDropdown';
import QualitatioDialog from '../../../components/QualitatioDialog/QualitatioDialog';
import { useAuthStore } from '../../../store/auth.store';
import TestCardWrapper from '../testCards/TestCardWrapper';
import { useCardOperations } from '../testCards/useCardOperations';
import './AITests.css';
import CardEditDialogs from './components/CardEditDialogs';

const AITests = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  aiTestsGroundTruth,
  setAITestsGroundTruth,
  aiTests,
  setAITests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const { enqueueSnackbar } = useSnackbar();
  const { addTestCard, saveTestCard, deleteTestCard } = useCardOperations();
  const theme = useTheme();
  const [addAIClassOpen, setAddAIClassOpen] = useState(false);

  const [mlModelNames, setMLModelNames] = useState([]);
  const [availableAIClasses, setAvailableAIClasses] = useState([]);

  const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
  const [discardChangesWarningOpen, setDiscardChangesWarningOpen] = useState(-1);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getMLModelNames();
    getAvailableAIClasses();
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = aiTests.find((el) => el.id === id);
      if (card) {
        const index = availableAIClasses.findIndex(
          (el) => el.aiClassName === card.ai.aiClassName && el.modelID === card.ai.modelID
        );
        const cardIndex = aiTests
          .filter(
            (el) => el.ai.aiClassName === card.ai.aiClassName && el.ai.modelID === card.ai.modelID
          )
          .indexOf(card);
        if (index !== -1 && cardIndex !== -1) {
          setCurrentIndex(index);
          setCurrentCardIndex(cardIndex);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [availableAIClasses, aiTests]);

  const getMLModelNames = async () => {
    try {
      const response = await axiosClient.get(getMLModelNamesEndpoint);
      setMLModelNames(response.data.mlModelNames);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getAvailableAIClasses = async () => {
    try {
      const response = await axiosClient.post(getAvailableAIClassesEndpoint, {
        qualityStationName: qualityStation,
      });
      setAvailableAIClasses(response.data.aiClasses);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setCardField = (field, value, modifier = null) => {
    setAITests((prevState) => {
      const newState = [...prevState];
      const matchingCards = newState.filter(
        (card) =>
          card.ai &&
          card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          card.ai.modelID === availableAIClasses[currentIndex]?.modelID
      );
      if (currentCardIndex < matchingCards.length) {
        const actualIndex = newState.indexOf(matchingCards[currentCardIndex]);
        if (actualIndex !== -1) {
          const updatedProperty = modifier ? modifier(newState[actualIndex][field], value) : value;
          newState[actualIndex][field] = updatedProperty;
        }
      }
      return newState;
    });
  };

  const deleteAIClass = async (index) => {
    const aiClassNameToDelete = availableAIClasses[index].aiClassName;
    const modelIDFromClassToDelete = availableAIClasses[index].modelID;

    const updatedAvailableAIClasses = availableAIClasses.map((el, ind) =>
      ind === index ? { ...el, active: false } : el
    );

    const updatedAITests = aiTests.map((test) => {
      if (
        test.ai &&
        test.ai.aiClassName === aiClassNameToDelete &&
        test.ai.modelID === modelIDFromClassToDelete
      ) {
        return { ...test, ai: { ...test.ai, active: false } };
      } else {
        return test;
      }
    });

    // Use saveTestCard helper to handle deleting cards from the deleted AI class
    const deleteCards = saveTestCard({
      validateCards: () => true, // Assume we don't need special validation for deletions
      filterCardToSave: (card) => {
        // Filter for only cards that match the AI class being deleted
        return (
          card.ai &&
          card.ai.aiClassName === aiClassNameToDelete &&
          card.ai.modelID === modelIDFromClassToDelete
        );
      },
      cardEndpoint: aiCardEndpoint,
      qualityStation: qualityStation,
      creator: user, // Assuming user information is held in `user` object
      currentTestCards: updatedAITests, // Use the updated AI tests
      setTestCards: setAITests, // Handle the AI test after saving
      groundTruthCards: aiTestsGroundTruth, // Assuming groundTruth is stored here
      setGroundTruthCards: setAITestsGroundTruth, // Function to update ground truth for AI tests
      errorMessageKey: 'errorWhileSavingAiBasedTestProposal',
      successMessageKey: 'aiBasedTestsSavedSuccessfully',
      callback: (savedCards) => {
        // Reset current index if we're deleting the current class
        if (index === currentIndex) {
          setCurrentIndex(0);
          setCurrentCardIndex(0);
        }
        // Update the available AI classes in state
        setAvailableAIClasses(updatedAvailableAIClasses);
      },
    });

    await deleteCards();
  };

  const handleCopilotButtonClick = async () => {
    // I am sorry this was a quick fix, but look at this file a proper fix would have taken me ages :'(
    try {
      const response = await axiosClient.post(generateCardEndpoint, {
        qualityStation: qualityStation,
        defect: availableAIClasses[currentIndex],
        creator: user.id,
        explanation: availableAIClasses[currentIndex]?.aiClassName,
        cardType: 'ai',
      });
      handleAddCard(response.data.card);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGeneratingTestCard'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  // ------- AI test card manipulation functions -------
  const handleAddCard = addTestCard({
    qualityStation: qualityStation,
    creator: user,
    customFields: {
      type: 'AI',
      explanation: availableAIClasses[currentIndex]?.aiClassName,
      ai: {
        aiClassName: availableAIClasses[currentIndex]?.aiClassName,
        active: true,
        modelID: availableAIClasses[currentIndex]?.modelID,
        modelActive: availableAIClasses[currentIndex]?.modelActive,
      },
    },
    currentTestCards: aiTests,
    setTestCards: setAITests,
    setCurrentCardIndex: setCurrentCardIndex,
    getNewCardIndex: (newCard) => {
      return aiTests.filter(
        (el) =>
          el.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
          el.ai.modelID === availableAIClasses[currentIndex]?.modelID
      ).length;
    },
  });

  const _calculateIndexToRemove = () => {
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Find the index of the card to delete in the aiTests array
    const indexToRemove = aiTests.findIndex((card, index) => {
      if (
        card.ai &&
        card.ai.aiClassName === currentAIClassName &&
        card.ai.modelID === currentModelID
      ) {
        const cardIndexInSubarray = aiTests
          .filter(
            (el) =>
              el.ai && el.ai.aiClassName === currentAIClassName && el.ai.modelID === currentModelID
          )
          .indexOf(card);
        return cardIndexInSubarray === currentCardIndex;
      }
      return false;
    });
    return indexToRemove;
  };

  const deleteCard = deleteTestCard({
    indexToRemove: _calculateIndexToRemove(),
    currentTestCards: aiTests,
    setTestCards: setAITests,
    groundTruthCards: aiTestsGroundTruth,
    setGroundTruthCards: setAITestsGroundTruth,
    setCurrentCardIndex: setCurrentCardIndex,
    cardEndpoint: aiCardEndpoint,
    errorMessageKey: 'errorWhileDeletingAiBasedTestProposal',
    successMessageKey: 'aiBasedTestDeletedSuccessfully',
  });

  const selectClassesOfModel = (selectedClasses, modelID) => {
    setAvailableAIClasses((prevState) =>
      prevState.map((aiClass) =>
        aiClass.modelID === modelID
          ? {
              ...aiClass,
              active:
                selectedClasses.map((el) => el.aiClassName).includes(aiClass.aiClassName) &&
                selectedClasses.map((el) => el.modelID).includes(aiClass.modelID),
            }
          : aiClass
      )
    );

    setAITests((prevState) =>
      prevState.map((test) =>
        test.ai && test.ai.modelID === modelID
          ? {
              ...test,
              ai: {
                ...test.ai,
                active:
                  selectedClasses.map((el) => el.aiClassName).includes(test.ai.aiClassName) &&
                  selectedClasses.map((el) => el.modelID).includes(test.ai.modelID),
              },
            }
          : test
      )
    );
  };

  const selectAllClassesOfModel = (modelID, active) => {
    setAvailableAIClasses((prevState) =>
      prevState.map((el) => (el.modelID === modelID ? { ...el, active: active } : el))
    );

    setAITests((prevState) => {
      return prevState.map((test) => {
        if (test.ai && test.ai.modelID === modelID) {
          return { ...test, ai: { ...test.ai, active: active } };
        } else {
          return test;
        }
      });
    });
  };

  const _isValidAITests = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.ai &&
        card.ai.modelID !== '' &&
        card.ai.aiClassName !== ''
      );
    });
  };

  const _validateCards = (updatedAITests) => {
    if (!_isValidAITests(updatedAITests || aiTests)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'warning' });
      return false;
    }
    return true;
  };

  const handleSaveCard = saveTestCard({
    validateCards: _validateCards,
    filterCardToSave: (card, groundTruth, index) =>
      (!card.id || !_.isEqual(card, groundTruth)) &&
      card.ai.aiClassName === availableAIClasses[currentIndex]?.aiClassName &&
      card.ai.modelID === availableAIClasses[currentIndex]?.modelID,
    cardEndpoint: aiCardEndpoint,
    qualityStation: qualityStation,
    creator: user,
    currentTestCards: aiTests,
    setTestCards: setAITests,
    groundTruthCards: aiTestsGroundTruth,
    setGroundTruthCards: setAITestsGroundTruth,
    errorMessageKey: 'errorWhileSavingAiBasedTestProposal',
    successMessageKey: 'aiBasedTestsSavedSuccessfully',
  });

  const persistActivationChanges = saveTestCard({
    validateCards: () => true,
    filterCardToSave: (card, groundTruth, index) => !_.isEqual(card, groundTruth),
    cardEndpoint: aiCardEndpoint,
    qualityStation: qualityStation,
    creator: user,
    currentTestCards: aiTests,
    setTestCards: setAITests,
    groundTruthCards: aiTestsGroundTruth,
    setGroundTruthCards: setAITestsGroundTruth,
    errorMessageKey: 'errorWhileSavingAiBasedTestProposal',
    successMessageKey: 'aiBasedTestsSavedSuccessfully',
    callback: (savedCards) => {
      setAddAIClassOpen(false);
    },
  });

  const updateCurrentCardIndex = (direction) => {
    // Find the AI class name for the current index in availableAIClasses
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Filter aiTests to find matching cards with the current AI class name
    const matchingCards = aiTests.filter(
      (test) =>
        test.ai && test.ai.aiClassName === currentAIClassName && test.ai.modelID === currentModelID
    );

    // Determine the next index based on the direction
    if (direction === 'inc' && currentCardIndex < matchingCards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else if (direction === 'dec' && currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const getMatchingCards = () => {
    // First, determine the AI class name and the model ID for the current index
    const currentAIClassName = availableAIClasses[currentIndex]?.aiClassName;
    const currentModelID = availableAIClasses[currentIndex]?.modelID;

    // Filter aiTests to find matching cards with the current AI class name
    const matchingCards = aiTests.filter((test) => {
      return (
        test.ai && test.ai.aiClassName === currentAIClassName && test.ai.modelID === currentModelID
      );
    });

    return matchingCards;
  };

  const selectClassesDialogData = useMemo(() => {
    return mlModelNames.map((model) => {
      // Filter AI classes for the current model
      const modelAIClasses = availableAIClasses.filter((aiClass) => aiClass.modelID === model.id);

      // Determine which AI classes are currently active
      const activeClasses = modelAIClasses.filter((aiClass) => aiClass.active);

      // Determine if all classes are selected
      const allSelected = activeClasses.length === modelAIClasses.length;

      // Create options for the dropdown
      const options = [
        { label: t('selectAllClasses'), value: 'selectAll' },
        ...modelAIClasses.map((aiClass) => ({
          label: aiClass.aiClassName,
          value: aiClass.id,
          active: aiClass.active,
        })),
      ];

      // Create value for the dropdown
      const value = activeClasses.map((aiClass) => ({
        label: aiClass.aiClassName,
        value: aiClass.id,
      }));

      return {
        model,
        options,
        value,
        allSelected,
      };
    });
  }, [mlModelNames, availableAIClasses, t, addAIClassOpen]);

  const discardChanges = () => {
    if (discardChangesWarningOpen === -2) {
      setAddAIClassOpen(true);
    } else {
      setCurrentIndex(discardChangesWarningOpen);
    }
    setCurrentCardIndex(0);
    setAITests(aiTestsGroundTruth);
  };

  return (
    <div className="ai-wrapper">
      <div className="ai">
        <div className="ai-class-wrapper">
          <div className="ai-header">
            <Card className="card-2" variant="filled">
              <div className="text-wrapper-10">{t('addNewAIClasses')}</div>
            </Card>
          </div>
          <div className="ai-body">
            {availableAIClasses.map((availableAIClass, index) => {
              return (
                availableAIClass.active &&
                availableAIClass.modelActive && (
                  <div className="ai-test" key={index}>
                    <div className="ai-test-name-wrapper">
                      <QualitatioButton
                        text={availableAIClass.aiClassName}
                        fontSize={'calc(10px + 0.25vw)'}
                        width={'100%'}
                        onClick={() => {
                          if (index !== currentIndex) {
                            if (_.isEqual(aiTests, aiTestsGroundTruth)) {
                              setCurrentIndex(index);
                              setCurrentCardIndex(0);
                            } else {
                              setDiscardChangesWarningOpen(index);
                            }
                          }
                        }}
                        endIcon={
                          <ArrowForwardIosRoundedIcon style={{ fontSize: 'calc(10px + 0.25vw)' }} />
                        }
                        order={index === currentIndex ? 'primary' : 'secondary'}
                      />
                    </div>
                    <div className="ai-test-delete-wrapper">
                      <IconButton
                        className="delete-button"
                        variant="qualitatio"
                        squared={true}
                        onClick={() => {
                          deleteAIClass(index);
                        }}
                        style={{
                          backgroundColor: theme.palette.error.main,
                          width: '36px',
                          height: '36px',
                        }}
                      >
                        <DeleteForeverRoundedIcon color="white" />
                      </IconButton>
                    </div>
                  </div>
                )
              );
            })}
            <div className="add-ai">
              <IconButton
                variant="qualitatio"
                squared={true}
                onClick={() => {
                  if (_.isEqual(aiTests, aiTestsGroundTruth)) {
                    setAddAIClassOpen(true);
                  } else {
                    setDiscardChangesWarningOpen(-2);
                  }
                }}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <AddRoundedIcon color="primary" />
              </IconButton>
              <QualitatioDialog
                title={t('addNewAIClasses')}
                description={t('addNewAIClassesInfoText')}
                open={addAIClassOpen}
                onClose={(event, reason) => {
                  if (reason && reason === 'backdropClick') {
                    return;
                  }
                  setAddAIClassOpen(false);
                }}
                maxWidth="sm"
                actions={[
                  {
                    label: t('save'),
                    onClick: () => {
                      persistActivationChanges();
                    },
                    order: 'primary',
                  },
                ]}
              >
                <div className="add-ai-class-wrapper">
                  {selectClassesDialogData.map(
                    ({ model, options, value, allSelected }, modelIndex) => (
                      <>
                        <QualitatioChipDropdown
                          label={`${t('selectClasses')} ${t('of')} ${model.name}`}
                          width={'100%'}
                          options={options}
                          value={value}
                          onChange={(newSelectedValues) => {
                            if (newSelectedValues.some((option) => option.value === 'selectAll')) {
                              selectAllClassesOfModel(model.id, !allSelected);
                            } else {
                              // Update individual class selections
                              const selectedIDs = newSelectedValues.map((option) => option.value);
                              const selectedClasses = availableAIClasses.filter((aiClass) =>
                                selectedIDs.includes(aiClass.id)
                              );
                              selectClassesOfModel(selectedClasses, model.id);
                            }
                          }}
                          multiple={true}
                          renderOption={(props, option, { selected }) => {
                            const { label, value, active } = option;
                            return (
                              <li key={value} {...props}>
                                <Checkbox
                                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                  checkedIcon={<CheckboxIcon fontSize="small" />}
                                  checked={selected}
                                  color="primary"
                                />
                                {label}
                              </li>
                            );
                          }}
                          filterSelectedOptions={false}
                          disableCloseOnSelect={true}
                        />
                      </>
                    )
                  )}
                </div>
              </QualitatioDialog>
            </div>
          </div>
        </div>
      </div>
      <div className="cards">
        <TestCardWrapper
          cardsToDisplay={getMatchingCards()}
          cardType={'ai'}
          currentCardIndex={currentCardIndex}
          setCurrentCardIndex={setCurrentCardIndex}
          testCardProps={{
            editable: true,
            qualityStation,
            orderIdentifierMapping,
            restoreChange,
            index: currentIndex,
            generation: t('AI'),
            explanation: getMatchingCards()[currentCardIndex]?.explanation,
          }}
          setCardField={setCardField}
          handlePreviousCard={() => updateCurrentCardIndex('dec')}
          handleNextCard={() => updateCurrentCardIndex('inc')}
          handleAddCard={handleAddCard}
          handleDeleteCard={() => setDeleteCardWarningOpen(true)}
          handleSaveCard={handleSaveCard}
          isAddCardDisabled={
            !availableAIClasses[currentIndex] || !availableAIClasses[currentIndex]?.active
          }
          isCopilotButtonPresent={true}
          copilotButtonProps={{
            overwriteFunction: handleCopilotButtonClick,
          }}
        />
      </div>
      <CardEditDialogs
        deleteCardWarningDialog={{
          deleteCardWarningOpen,
          setDeleteCardWarningOpen,
          deleteCard,
        }}
        discardChangesWarningDialog={{
          discardChangesWarningOpen,
          setDiscardChangesWarningOpen,
          discardChanges,
        }}
      />
    </div>
  );
};

export default AITests;

AITests.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  aiTestsGroundTruth: PropTypes.array.isRequired,
  setAITestsGroundTruth: PropTypes.func.isRequired,
  aiTests: PropTypes.array.isRequired,
  setAITests: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  setQualityStationStats: PropTypes.func.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  restoreChange: PropTypes.func.isRequired,
};
