import tr from './tr.json';
import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import it from './it.json';
import ru from './ru.json';
import ro from './ro.json';
import pl from './pl.json';
import cz from './cz.json';
import hu from './hu.json';


export const dictionaryList = {
  en,
  tr,
  de,
  fr,
  es,
  it,
  ru,
  ro,
  pl,
  cz,
  hu
};

export const languageOptions = {
  en: 'English',
  tr: 'Türkçe',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ru: 'Русский',
  ro: 'Română',
  pl: 'Polski',
  cz: 'Čeština',
  hu: 'Magyar',
};
//main file is de.json translate it with https://translate.i18next.com/