import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './QualitatioDropdown.css';

const QualitatioDropdown = ({
  options,
  label,
  value,
  onChange,
  width,
  minWidth,
  disabled,
  margin,
  placeholder,
  renderOption,
  groupBy,
  compact = false,
  xs = false,
  readOnly = false,
  multiple = false,
  ...otherProps
}) => {
  const style = {
    width: width || 'auto',
    minWidth: minWidth || 'auto',
    background: 'white',
    margin: margin || '0',
  };

  return (
    <Autocomplete
      value={value}
      defaultValue={value}
      groupBy={groupBy}
      multiple={multiple}
      onChange={(event, option) => (option ? onChange(event, option) : onChange(event, null))}
      readOnly={readOnly}
      disabled={disabled}
      options={options}
      className={compact ? 'compact-dropdown' : xs ? 'xs-dropdown' : ''}
      getOptionLabel={(option) => option.label}
      renderOption={renderOption || null}
      style={style}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          className="qualitatio-input"
          variant="outlined"
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {...otherProps}
    />
  );
};

export default QualitatioDropdown;
