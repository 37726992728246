import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Chip, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { cardLayoutEndpoint, overviewTestsEndpoint } from '../../../api/endpoints';
import QualitatioAccordion from '../../../components/QualitatioAccordion/QualitatioAccordion';
import createLabelText from '../../../components/QualitatioTable/tableLanguages';
import TestCard from '../testCards/TestCard';
import TestCardVisual from '../testCards/TestCardVisual';
import './OverviewTests.css';

const OverviewTests = ({ qualityStation, orderIdentifierMapping }) => {
  const { t } = useTranslation();
  const [cardLayout, setCardLayout] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const labelText = createLabelText();

  const theme = useTheme();

  const [currentCardIndex, setCurrentCardIndex] = useState(-1);
  const [allCards, setAllCards] = useState([]);
  const [accordionData, setAccordionData] = useState([]);

  const [updateState, setUpdateState] = useState(false);

  useEffect(() => {
    getCardLayout();
    getOverviewTestsTableData();
  }, []);

  const getCardLayout = async () => {
    try {
      const response = await axiosClient.get(cardLayoutEndpoint);
      setCardLayout(response.data.layout);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updatePositionIdentifier = async (qualityStation, cardId, positionIdentifier, type) => {
    try {
      const response = await axiosClient.patch(overviewTestsEndpoint, {
        qualityStation: qualityStation,
        cardID: cardId,
        positionIdentifier: positionIdentifier,
        type: type,
      });
      return response.data.card;
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getOverviewTestsTableData = async () => {
    try {
      const response = await axiosClient.get(overviewTestsEndpoint, {
        headers: {
          qualityStationName: qualityStation,
        },
      });
      if (response.data.cards) {
        setAllCards(response.data.cards);
      }
    } catch (error) {
      enqueueSnackbar(t('ErrorFetchingOverviewTestsTableData'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  const columns = [
    {
      headerName: t('title'),
      field: 'title',
      editable: false,
      sortable: false,
      flex: 1,
      maxWidth: 300,
    },
    {
      headerName: t('type'),
      field: 'type',
      editable: false,
      sortable: false,
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            sx={{
              backgroundColor:
                params.value === 'Basis' || params.value === 'Rule'
                  ? theme.palette.primary.main
                  : theme.palette.nightsky.main,
              color:
                params.value === 'Basis' || params.value === 'Rule'
                  ? theme.palette.white.main
                  : theme.palette.primary.main,
            }}
          />
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const handleRowReorder = async (orderIdentifier, params) => {
    try {
      setIsLoading(true);

      if (params.oldIndex === params.targetIndex) {
        return;
      }

      const movedCard = allCards.find((card) => card.id === params.row.id);
      const cardsInSameOrder = allCards.filter((card) => card.orderIdentifier === orderIdentifier);
      cardsInSameOrder.sort((a, b) => a.positionIdentifier - b.positionIdentifier);

      // Remove the moved card from its original position and insert it at the new position
      const startIndex = cardsInSameOrder.findIndex((card) => card.id === movedCard.id);
      cardsInSameOrder.splice(startIndex, 1);
      const targetIndex =
        params.targetIndex > cardsInSameOrder.length ? cardsInSameOrder.length : params.targetIndex;
      cardsInSameOrder.splice(targetIndex, 0, movedCard);

      // Create an array to hold the updated cards for batch updating later
      const updatedCards = [];

      for (let i = 0; i < cardsInSameOrder.length; i++) {
        const card = cardsInSameOrder[i];
        if (card.positionIdentifier !== i) {
          // Update the card in the database and replace the local copy with the updated one
          const updatedCard = await updatePositionIdentifier(qualityStation, card.id, i, card.type);
          updatedCards.push(updatedCard);
        } else {
          // If the positionIdentifier hasn't changed, use the original card
          updatedCards.push(card);
        }
      }

      // Update allCards with the updated cards, ensuring the array is sorted by orderIdentifier and then by positionIdentifier
      const updatedAllCards = allCards
        .map((card) => {
          const updatedCard = updatedCards.find((c) => c.id === card.id);
          return updatedCard || card; // Use the updated card if available, otherwise fall back to the original card
        })
        .sort((a, b) => {
          const orderDiff = a.orderIdentifier - b.orderIdentifier;
          if (orderDiff !== 0) return orderDiff;
          return a.positionIdentifier - b.positionIdentifier;
        });

      console.log(
        'updated positionIdentifier: ',
        updatedAllCards.map((card) => ({
          title: card.title,
          positionIdentifier: card.positionIdentifier,
        }))
      );

      setAllCards(updatedAllCards);
    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(t('Error occurred when reordering rows'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const newAccordionData = orderIdentifierMapping?.map((mapping, index) => {
      return {
        title:
          mapping.notation +
          (allCards?.filter((card) => card.orderIdentifier === mapping.orderIdentifier).length > 0
            ? ` (${allCards?.filter((card) => card.orderIdentifier === mapping.orderIdentifier).length})`
            : ``),
        panel: 'panel' + index,
        render:
          allCards?.filter((card) => card.orderIdentifier === mapping.orderIdentifier).length >
          0 ? (
            <DataGridPro
              variant="qualitatio"
              isLoading={isLoading}
              localeText={labelText}
              columns={columns}
              rows={allCards?.filter((card) => card.orderIdentifier === mapping.orderIdentifier)}
              rowReordering={true}
              checkboxSelection={false}
              onRowSelectionModelChange={(params) => {
                const newIndex = allCards?.findIndex((card) => card.id === params[0]);
                if (newIndex !== -1) {
                  setCurrentCardIndex(newIndex);
                }
              }}
              rowSelectionModel={allCards[currentCardIndex]?.id}
              onRowOrderChange={(params) => {
                handleRowReorder(mapping.orderIdentifier, params);
              }}
            />
          ) : (
            <Typography>{t('noTestCardsAvailable')}</Typography>
          ),
      };
    });

    setAccordionData(newAccordionData);
  }, [allCards, currentCardIndex]);

  const updateCurrentCardIndex = (direction) => {
    if (direction === 'inc') {
      setCurrentCardIndex((prevIndex) => (prevIndex === allCards.length - 1 ? 0 : prevIndex + 1));
    } else if (direction === 'dec') {
      setCurrentCardIndex((prevIndex) => (prevIndex === 0 ? allCards.length - 1 : prevIndex - 1));
    }
  };

  return (
    <div className="overview-wrapper">
      <div className="overview">
        <QualitatioAccordion
          updateState={updateState}
          setUpdateState={setUpdateState}
          accordionData={accordionData}
          orderIdentifier={allCards[currentCardIndex]?.orderIdentifier}
        />
      </div>
      <div className="cards-overview">
        <div className="test-card">
          {currentCardIndex !== -1 ? (
            cardLayout ? (
              <TestCardVisual
                id={allCards[currentCardIndex]?.id || ''}
                editable={false}
                title={allCards[currentCardIndex]?.title}
                images={allCards[currentCardIndex]?.images}
                testObject={allCards[currentCardIndex]?.testObject}
                testMethod={allCards[currentCardIndex]?.testMethod}
                testLocation={allCards[currentCardIndex]?.testLocation}
                testComment={allCards[currentCardIndex]?.testComment}
                orderIdentifier={allCards[currentCardIndex]?.orderIdentifier}
                generation={t(allCards[currentCardIndex]?.type)}
                explanation={allCards[currentCardIndex]?.explanation}
                index={currentCardIndex}
                numberCards={allCards?.length}
                qualityStation={qualityStation}
                orderIdentifierMapping={orderIdentifierMapping}
                currentCardIndex={currentCardIndex}
                editors={allCards[currentCardIndex]?.editors}
                creator={allCards[currentCardIndex]?.creator}
                creationDate={allCards[currentCardIndex]?.creationDate}
              />
            ) : (
              <TestCard
                id={allCards[currentCardIndex]?.id || ''}
                editable={false}
                title={allCards[currentCardIndex]?.title}
                images={allCards[currentCardIndex]?.images}
                testObject={allCards[currentCardIndex]?.testObject}
                testMethod={allCards[currentCardIndex]?.testMethod}
                testLocation={allCards[currentCardIndex]?.testLocation}
                testComment={allCards[currentCardIndex]?.testComment}
                orderIdentifier={allCards[currentCardIndex]?.orderIdentifier}
                generation={t(allCards[currentCardIndex]?.type)}
                explanation={allCards[currentCardIndex]?.explanation}
                index={currentCardIndex}
                numberCards={allCards?.length}
                qualityStation={qualityStation}
                orderIdentifierMapping={orderIdentifierMapping}
                editors={allCards[currentCardIndex]?.editors}
                creator={allCards[currentCardIndex]?.creator}
                creationDate={allCards[currentCardIndex]?.creationDate}
              />
            )
          ) : allCards?.length !== 0 ? (
            <div className="select-bucket">{t('selectBucketToSeeTestCards')}</div>
          ) : (
            <div className="no-test-cards">{t('noTestCardsAvailable')}</div>
          )}
        </div>
        <div className="test-card-manipulation">
          {currentCardIndex !== -1 && (
            <>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => updateCurrentCardIndex('dec')}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <ArrowBackIosNewRoundedIcon color="primary" />
              </IconButton>
              <IconButton
                className="test-card-manipulation-element"
                variant="qualitatio"
                squared={true}
                onClick={() => updateCurrentCardIndex('inc')}
                style={{
                  backgroundColor: theme.palette.success.secondary,
                  width: '36px',
                  height: '36px',
                }}
              >
                <ArrowForwardIosRoundedIcon color="primary" />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTests;
