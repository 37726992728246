import { primaryColor, h2Styles } from '../styleConstants.js'

export const dialogStyles = {
    '& .MuiDialog-paper': {
        padding: '0 24px 24px 24px', // Optional: Add some padding around the content
    },
    '& .MuiDialogTitle-root': {
        position: 'relative', // Required for pseudo-element positioning
        textAlign: 'center', // center align the title text
        fontSize: h2Styles.fontSize,
        fontFamily: h2Styles.fontFamily,
        top: 20, // Push the title down to compensate for the line
        marginBottom: '20px',
        '&::before': {
            content: '""',
            display: 'block',
            height: '4px', // Height of the line
            width: '25%', // Width of the line
            backgroundColor: primaryColor, // Color of the line
            position: 'absolute',
            borderRadius: '4px',
            top: 0, // Position it at the top of the title
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },
    '& .MuiDialogContent-root': {
    },
}

