/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { filesRoot } from '../../api/endpoints';
import { CooperationContext } from '../../provider/CooperationProvider';
import { useAuthStore } from '../../store/auth.store';
import { getRedirectPath } from '../../utils/accessUtils';
import UserAvatar from './UserAvatar';
import './style.css';

export const Header = ({
  className,
  frameClassName,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
  licenseExpired,
  daysLeft,
}) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const currentPath = `${location.pathname}${location.search}`;
  const { activeUsers } = useContext(CooperationContext);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (licenseExpired) {
      enqueueSnackbar(t('licenseExpiredXDaysLeft', { daysLeft: daysLeft }), {
        variant: 'error',
        persist: true,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
    }
  }, [licenseExpired, daysLeft]);

  const profileClicked = () => {
    if (
      currentPage.startsWith('/intelligent_testing/recommender') ||
      currentPage.startsWith('/efficient_testing/recommender')
    ) {
      setRecommenderMenuOpen(!recommenderMenuOpen);
    } else {
      navigate(`/profile?lastPath=${encodeURIComponent(currentPath)}`);
    }
  };

  const user = useAuthStore((state) => state.user);
  return (
    <>
      {user && !currentPage.startsWith('/setup') && (
        <Box
          className={`header ${className}`}
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Link to={getRedirectPath(user)}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'left' }}>
              <Box className={`frame-2 ${frameClassName}`} />
              <Box className="div-wrapper">
                <div className="text-wrapper-2">Qualitatio</div>
              </Box>
            </Box>
          </Link>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            {!(
              currentPage.startsWith('/intelligent_testing/recommender') ||
              currentPage.startsWith('/efficient_testing/recommender')
            ) && (
              <Box className="user-avatars" sx={{ display: 'flex' }}>
                {activeUsers
                  .filter((activeUser) => activeUser.id !== user.id)
                  .slice(0, 4)
                  .map((user, index) => (
                    <UserAvatar key={user.name} name={user.name} index={index} />
                  ))}
              </Box>
            )}
            <Box className="borderWrapper">
              <div
                className="avatarContainer"
                onClick={() => {
                  profileClicked();
                }}
              >
                <img src={user?.profileImage || '/images/avatar.png'} />
              </div>
            </Box>
            <img
              src={`${filesRoot}basis/logoFiles/logo.png`}
              style={{
                maxHeight: '46px',
                minHeight: '30px',
                width: 'auto',
                margin: '5px 0px',
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

Header.propTypes = {
  frame: PropTypes.string,
};
