import { Box } from '@mui/material';
import React from 'react';

const QualitatioHighlightBox = ({
  text,
  color,
  icon,
  onClick,
  border = 'none',
  opacity = '1',
  backgroundColor,
  maxHeight,
}) => {
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      style={{
        backgroundColor: backgroundColor || `${color}22`,
        color: color,
        padding: '3px 7px',
        borderRadius: '4px',
        border: border,
        opacity: opacity,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
      maxHeight={maxHeight}
    >
      {icon && (
        <Box style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>{icon}</Box>
      )}
      <Box>{text}</Box>
    </Box>
  );
};

export default QualitatioHighlightBox;
