import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Box, List, ListItem, ListItemText, Popover } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../api/axiosClient';
import { saveUserProfileImageEndpoint, usersEndpoint } from '../api/endpoints';
import ROLES from '../authentication/roleConst';
import { QualitatioLanguageSelector } from '../components';
import { LANG } from '../language_settings/languageConst';
import { useAuthStore } from '../store/auth.store';
import PageFrame from './PageFrame';
import './ProfilePage.css';
import QualitatioButton from './QualitatioButton/QualitatioButton';
import QualitatioDropdown from './QualitatioDropdown/QualitatioDropdown';
import QualitatioInput from './QualitatioInput/QualitatioInput';

const ProfilePage = ({ setPageName }) => {
  const { t } = useTranslation();
  setPageName(t('account'));

  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser, logout } = useAuthStore((state) => ({
    user: state.user,
    setUser: state.setUser,
    logout: state.logout,
  }));
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [deleteProfileImage, setDeleteProfileImage] = useState(false);

  const logoutUser = () => {
    logout();
    navigate('/login');
  };

  //Media Query to check if the screen is below sm
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // formik.setFieldValue('previewImage', reader.result);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      // formik.setFieldValue('profileImageFile', file);
      setProfileImageFile(file);
      setDeleteProfileImage(false);
    }
  };
  const handleProfileUpdate = async () => {
    const payload = {
      role: role,
      authSystem: authSystem,
      profileImage: profileImage,
      name: name,
      email: email,
      password: password,
      language: language,
    };
    // const { role, authSystem, profileImageFile, previewImage, ...payload } = values;

    // If both previewImage and profileImage are null, set profileImage in payload to empty string
    if (deleteProfileImage) {
      payload.profileImage = '';
    } else if (!deleteProfileImage && !previewImage && !profileImageFile) {
      delete payload.profileImage;
    }
    // If there's a profileImageFile, upload it
    if (profileImageFile) {
      const formData = new FormData();
      formData.append('file', profileImageFile);
      formData.append('user', user.id);

      try {
        const response = await axiosClient.post(saveUserProfileImageEndpoint, formData);
        // formik.setFieldValue('profileImage', response.data.userProfileImageURL);
        setProfileImage(response.data.userProfileImageURL);
        payload.profileImage = response.data.userProfileImageURL;
        enqueueSnackbar(t('profileImageUpdated'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(t('errorUploadingImage'), { variant: 'error' });
      }
    }

    if (payload.password === '') {
      delete payload.password;
    }
    //remove role and authSystem from payload
    delete payload.role;
    delete payload.authSystem;

    const patchEndpoint = usersEndpoint + '/' + user.id;

    try {
      const response = await axiosClient.patch(patchEndpoint, payload);
      //this updates the user in the context
      setUser(response.data);

      if (response.status === 200) {
        enqueueSnackbar(t('profileUpdated') || '', { variant: 'success' });
      } else {
        enqueueSnackbar(t('updateFailed') || '', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(t('errorUpdatingProfile') || '', { variant: 'error' });
    }
  };

  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(user.name);
  const [role, setRole] = useState(user.role);
  const [language, setLanguage] = useState(user.language);
  const [authSystem, setAuthSystem] = useState(user.authSystem);
  const [profileImage, setProfileImage] = useState(user.profileImage);

  // avatar click
  const avatarRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const avatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCameraState(false);
  };

  // variables
  const [previewImage, setPreviewImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);

  const fileInputRef = useRef(null);
  const handleImageUploadClick = () => {
    fileInputRef.current.click();
    setAnchorEl(null);
  };

  //take profile photo
  const [cameraState, setCameraState] = useState(false);

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const uploadPhoto = (imageSrc) => {
    setPreviewImage(imageSrc);
    setProfileImage(imageSrc);

    // Convert data URL to a File object
    const imageFile = dataURLtoFile(imageSrc, 'captured_image.png');
    setProfileImageFile(imageFile);

    setCameraState(false);
  };

  const capturePhoto = async (imageSrc) => {
    setAnchorEl(null);
    try {
      uploadPhoto(imageSrc);
    } catch (error) {
      console.error(t('errorCapturingCameraImage'), error);
    }
  };

  return (
    <>
      {!cameraState ? (
        <PageFrame title={t('yourAccount')} Icon={PersonRoundedIcon}>
          <Box
            sx={{
              margin: '0 auto',
              width: belowSm ? '100%' : '50%',
              maxWidth: '400px',
            }}
            className="profile-page"
          >
            <div className="profileImage">
              <div className="borderWrapper">
                <div className="avatarContainer" ref={avatarRef} onClick={avatarClick}>
                  <img src={previewImage || profileImage || '/images/avatar.png'} />
                </div>
              </div>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <List component="nav">
                  <ListItem
                    button
                    onClick={() => {
                      handleImageUploadClick();
                    }}
                  >
                    <ListItemText primary={t('uploadImage')} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setDeleteProfileImage(true);
                      setProfileImage(null);
                      setPreviewImage(null);
                      setProfileImageFile(null);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemText primary={t('deleteProfileImage')} />
                  </ListItem>
                  <ListItem button onClick={() => setCameraState(!cameraState)}>
                    <ListItemText primary={t('takeProfileImage')} />
                  </ListItem>
                </List>
              </Popover>
              <input
                ref={fileInputRef}
                type="file"
                id="avatar-input"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleImageSelection}
              />
            </div>
            <div className="frame-2">
              <div className="frame-3">
                <QualitatioInput
                  label={t('name')}
                  type="text"
                  value={name}
                  placeholder="Enter your name"
                  size="lg"
                  onChange={(e) => setName(e.currentTarget.value)}
                  width={'100%'}
                  readOnly={authSystem === 'LDAP'}
                />
                <QualitatioInput
                  label={t('email')}
                  type="email"
                  value={email}
                  placeholder="test@test.com"
                  size="lg"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  width={'100%'}
                  readOnly={authSystem === 'LDAP'}
                />
                <QualitatioInput
                  label={t('passwordIfYouDontWantToChangeItLeaveItEmpty')}
                  type="password"
                  value={password}
                  placeholder="*******"
                  size="lg"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  width={'100%'}
                  readOnly={authSystem === 'LDAP'}
                  showInput={showPassword}
                  toggleShowInput={() => setShowPassword(!showPassword)}
                />
                <QualitatioLanguageSelector
                  value={language}
                  onChange={(event, newValue) => {
                    setLanguage(newValue || LANG.EN);
                  }}
                />
                <QualitatioDropdown
                  label={t('role')}
                  options={Object.values(ROLES).map((role) => ({
                    value: role,
                    label: t(`users.${role}`),
                  }))}
                  value={{ value: role, label: t(`users.${role}`) }}
                  size="lg"
                  readOnly={true}
                  width={'100%'}
                />
                <QualitatioInput
                  label={t('authenticationSystem')}
                  type="text"
                  size="lg"
                  value={authSystem}
                  readOnly={true}
                  width={'100%'}
                />
              </div>
            </div>
            <div className="buttons">
              <QualitatioButton
                text={t('save')}
                order="primary"
                onClick={handleProfileUpdate}
                width={'100%'}
                height={'5vh'}
                fontSize={'calc(12px + 0.25vw)'}
                endIcon={<ArrowForwardIosRoundedIcon />}
              />
              <QualitatioButton
                text={t('logout')}
                order="secondary"
                onClick={() => logoutUser()}
                width={'100%'}
                height={'5vh'}
                fontSize={'calc(12px + 0.25vw)'}
                startIcon={<ArrowBackIosNewRoundedIcon />}
              />
            </div>
          </Box>
        </PageFrame>
      ) : (
        <div className="camera-wrapper">
          <Camera
            onTakePhoto={(dataUri) => {
              capturePhoto(dataUri);
            }}
            idealFacingMode={'user'}
            isFullscreen={true}
          />
        </div>
      )}
    </>
  );
};

export default ProfilePage;
