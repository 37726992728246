import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosClient from '../../../../api/axiosClient';
import { getQualityStationAllRulesEndpoint, ruleCardEndpoint } from '../../../../api/endpoints';
import { Card } from '../../../../components/Card';
import { useCardOperations } from '../../testCards/useCardOperations';
import { useAuthStore } from '../../../../store/auth.store';
import PropTypes from 'prop-types';
import './RuleTests.css';

import RuleTestCard from './components/RuleTestCard';
import RulesOverview from './components/RulesOverview';
import CardEditDialogs from '../components/CardEditDialogs';

const RuleTests = ({
  currentIndex,
  setCurrentIndex,
  currentCardIndex,
  setCurrentCardIndex,
  ruleTestsGroundTruth,
  setRuleTestsGroundTruth,
  ruleTests,
  setRuleTests,
  qualityStation,
  setQualityStationStats,
  orderIdentifierMapping,
  restoreChange,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const { enqueueSnackbar } = useSnackbar();
  const { addTestCard, saveTestCard, deleteTestCard } = useCardOperations();
  const [ruleEditOpen, setRuleEditOpen] = useState([]);

  const [rules, setRules] = useState([]);
  const [rulesGroundTruth, setRulesGroundTruth] = useState([]);

  const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
  // Used to remember the user action that triggered the confirmation of discarding unsaved changes
  // -1: No warning open, -2 + cardIndex: Edit rule, -3: Add rule, cardIndex: View rule
  const [discardChangesWarningOpen, setDiscardChangesWarningOpen] = useState(-1);
  const [deleteRuleIndex, setDeleteRuleIndex] = useState(0);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getQualityStationAllRules();
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      const card = ruleTests.find((el) => el.id === id);
      if (card) {
        const index = rules.findIndex((el) => _.isEqual(el, card.rule));
        const cardIndex = ruleTests.filter((el) => _.isEqual(card.rule, el.rule)).indexOf(card);
        if (index !== -1 && cardIndex !== -1) {
          setCurrentIndex(index);
          setCurrentCardIndex(cardIndex);
          searchParams.delete('id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [rules, ruleTests]);

  const getQualityStationAllRules = async () => {
    try {
      const response = await axiosClient.post(getQualityStationAllRulesEndpoint, {
        qualityStationName: qualityStation,
      });
      setRules(response.data.rules);
      setRulesGroundTruth(response.data.rules);
    } catch (error) {
      enqueueSnackbar(t('errorWhenGettingQualityStationAllRules'), { variant: 'error' });
      console.error('Error:', error);
    }
  };

  // ------- Rule test card manipulation functions -------
  const handleAddCard = addTestCard({
    qualityStation: qualityStation,
    creator: user,
    customFields: {
      type: 'Rule',
      explanation: rules[currentIndex]?.ruleName,
      rule: { ...rules[currentIndex] },
    },
    currentTestCards: ruleTests,
    setTestCards: setRuleTests,
    setCurrentCardIndex: setCurrentCardIndex,
    getNewCardIndex: (newCard) => {
      return ruleTests.filter((el) => _.isEqual(el.rule, rules[currentIndex])).length;
    },
  });

  const _calculateIndexToRemove = () => {
    return ruleTests.findIndex((card, index) => {
      if (card.rule && _.isEqual(card.rule, rules[currentIndex])) {
        const cardIndexInSubarray = ruleTests
          .filter((el) => el.rule && _.isEqual(el.rule, rules[currentIndex]))
          .indexOf(card);
        return cardIndexInSubarray === currentCardIndex;
      }
      return false;
    });
  };

  const deleteCard = deleteTestCard({
    indexToRemove: _calculateIndexToRemove(),
    currentTestCards: ruleTests,
    setTestCards: setRuleTests,
    groundTruthCards: ruleTestsGroundTruth,
    setGroundTruthCards: setRuleTestsGroundTruth,
    setCurrentCardIndex: setCurrentCardIndex,
    cardEndpoint: ruleCardEndpoint,
    errorMessageKey: 'errorWhileDeletingRuleBasedTestProposal',
    successMessageKey: 'cardDeletedSuccessfully',
  });

  const handleDeleteCard = () => {
    setDeleteCardWarningOpen(true);
  };

  const _isValidCards = (cards) => {
    return cards.every((card) => {
      return (
        card.title !== '' &&
        card.testObject !== '' &&
        card.testLocation !== '' &&
        card.testMethod !== '' &&
        card.rule?.logic?.length > 0 &&
        card.rule?.logic?.every((logic) => {
          return (
            logic.fieldOneStreamName !== '' &&
            logic.fieldOneFieldName !== '' &&
            logic.operator !== '' &&
            logic.fieldTwoValue !== ''
          );
        })
      );
    });
  };

  const _validateCards = (updatedRuleTests) => {
    const filteredCards = (updatedRuleTests || ruleTests).filter((card) =>
      _.isEqual(card.rule, rules[currentIndex])
    );
    if (!_isValidCards(filteredCards)) {
      enqueueSnackbar(t('notAllRequiredFieldsAreFilled'), { variant: 'warning' });
      return false;
    } else if (rules.filter((rule) => rule.ruleName === rules[currentIndex].ruleName).length > 1) {
      enqueueSnackbar(t('ruleNameAlreadyExists'), { variant: 'warning' });
      return false;
    }
    return true;
  };

  // Please review the entire logic of updating rules and logics with their respective id
  const _updateRuleIdAfterSave = (savedCards) => {
    const updateRulesList = (currentRules) => {
      const updatedRules = [...currentRules];
      const currentRuleName = rules[currentIndex]?.ruleName;

      // Iterate through each saved card
      savedCards.forEach((savedCard) => {
        const savedRule = savedCard.rule;

        // Only update if the rule name matches the current rule name
        if (savedRule && savedRule.ruleName === currentRuleName) {
          const matchingRuleIndex = updatedRules.findIndex((rule) =>
            _.isEqual(rule, rules[currentIndex])
          );

          if (matchingRuleIndex !== -1) {
            // Update the rule with the new rule id from savedCard
            updatedRules[matchingRuleIndex] = {
              ...updatedRules[matchingRuleIndex],
              ...savedCard.rule,
            };
          }
        }
      });

      return updatedRules;
    };

    // Update both rules and rulesGroundTruth using the same logic
    setRules(updateRulesList);
    setRulesGroundTruth(updateRulesList);
  };

  const handleSaveCard = saveTestCard({
    validateCards: _validateCards,
    filterCardToSave: (card, groundTruth, index) =>
      (!card.id || !_.isEqual(card, groundTruth)) && _.isEqual(card.rule, rules[currentIndex]),
    cardEndpoint: ruleCardEndpoint,
    qualityStation: qualityStation,
    creator: user,
    currentTestCards: ruleTests,
    setTestCards: setRuleTests,
    groundTruthCards: ruleTestsGroundTruth,
    setGroundTruthCards: setRuleTestsGroundTruth,
    errorMessageKey: 'errorWhileSavingRuleBasedTestProposal',
    successMessageKey: 'ruleBasedTestingSavedSuccessfully',
    callback: _updateRuleIdAfterSave,
  });

  const _updateCurrentCardIndex = (direction) => {
    const matchingCardsLength = ruleTests.filter((card) =>
      _.isEqual(card.rule, rules[currentIndex])
    ).length;

    if (direction === 'inc') {
      if (currentCardIndex < matchingCardsLength - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      }
    } else if (direction === 'dec') {
      if (currentCardIndex > 0) {
        setCurrentCardIndex(currentCardIndex - 1);
      }
    }
  };

  const handlePreviousCard = () => {
    _updateCurrentCardIndex('dec');
  };

  const handleNextCard = () => {
    _updateCurrentCardIndex('inc');
  };

  // ------- Rule manipulation functions -------
  const openRuleEdit = (index) => {
    setRuleEditOpen((prev) => {
      prev[index] = true;
      return [...prev];
    });
  };

  // Discard changes and shows the corresponding UI according to user actions before discarding
  const discardChanges = () => {
    // User clicked editing button
    if (String(discardChangesWarningOpen).startsWith('-2')) {
      openRuleEdit(Number(String(discardChangesWarningOpen).slice(2)));
      setCurrentIndex(Number(String(discardChangesWarningOpen).slice(2)));
      setRuleTests(ruleTestsGroundTruth);
      setRules(rulesGroundTruth);
      // User clicked add rule button
    } else if (discardChangesWarningOpen === -3) {
      addRule();
      // User clicked view rule button
    } else {
      setCurrentIndex(discardChangesWarningOpen);
      setRuleTests(ruleTestsGroundTruth);
      setRules(rulesGroundTruth);
    }
    setCurrentCardIndex(0);
    setDiscardChangesWarningOpen(-1);
  };

  const handleEditRule = (index) => {
    if (index === currentIndex) {
      openRuleEdit(index);
    } else {
      if (_.isEqual(ruleTests, ruleTestsGroundTruth)) {
        openRuleEdit(index);
        setCurrentCardIndex(0);
        setCurrentIndex(index);
      } else {
        setDiscardChangesWarningOpen(Number('-2' + index));
      }
    }
  };

  const handleDeleteRule = (index) => {
    setDeleteRuleIndex(index);
  };

  const deleteRule = async () => {
    // Filter out cards and rules that match the ruleName
    const updatedRuleTests = ruleTests.filter(
      (card) => !_.isEqual(card.rule, rules[deleteRuleIndex])
    );
    const updatedRuleTestsGroundTruth = ruleTestsGroundTruth.filter(
      (card) => !_.isEqual(card.rule, rules[deleteRuleIndex])
    );
    const updatedRules = rules.filter((rule) => !_.isEqual(rule, rules[deleteRuleIndex]));

    const updatedRulesGroundTruth = rulesGroundTruth.filter(
      (rule) => !_.isEqual(rule, rules[deleteRuleIndex])
    );

    const cardsToDelete = ruleTests.filter((card) => _.isEqual(card.rule, rules[deleteRuleIndex]));

    if (cardsToDelete.length > 0) {
      try {
        await Promise.all(
          cardsToDelete.map(async (card) => {
            if (card.id) {
              await axiosClient.delete(ruleCardEndpoint, {
                data: {
                  cardID: card.id,
                },
              });
            }
          })
        );
        if (cardsToDelete.some((card) => card.id)) {
          enqueueSnackbar(t('ruleAndCardsDeletedSuccessfully'), { variant: 'success' });
        }
      } catch (error) {
        enqueueSnackbar(t('errorWhileDeletingRuleBasedTestProposal'), { variant: 'error' });
        console.error('Error:', error);
        return;
      }
    }

    setRuleTests(updatedRuleTests);
    setRules(updatedRules);
    setRuleTestsGroundTruth(updatedRuleTestsGroundTruth);
    setRulesGroundTruth(updatedRulesGroundTruth);

    // Update currentIndex after deletion
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
    setCurrentCardIndex(deleteRuleIndex === currentIndex ? 0 : currentCardIndex);
  };

  const handleViewRule = (index) => {
    if (_.isEqual(ruleTests, ruleTestsGroundTruth)) {
      setCurrentIndex(index);
      setCurrentCardIndex(0);
    } else {
      setDiscardChangesWarningOpen(index);
    }
  };

  const handleAddRule = () => {
    if (_.isEqual(ruleTests, ruleTestsGroundTruth) && _.isEqual(rules, rulesGroundTruth)) {
      addRule();
    } else {
      setDiscardChangesWarningOpen(-3);
    }
  };

  const addRule = () => {
    const newRuleName = t('newRule');

    // New rule object for rules array
    const newRule = {
      ruleName: newRuleName,
      logic: [],
    };

    // New card object for ruleTests array
    const newCard = {
      qualityStation: qualityStation,
      type: 'Rule',
      title: '', // Initial empty title
      images: [],
      testObject: '',
      testLocation: '',
      testMethod: '',
      testComment: '',
      orderIdentifier: 0,
      positionIdentifier: 1000, // Assign a position identifier
      explanation: newRuleName,
      creator: {
        creatorId: user.id,
        name: user.name,
        profileImage: user.profileImage,
      },
      creationDate: Date.now(),
      rule: newRule,
      editors: [
        {
          editorId: user.id,
          name: user.name,
          profileImage: user.profileImage,
        },
      ],
    };

    setRules([...rulesGroundTruth, newRule]);
    setRuleTests([...ruleTestsGroundTruth, newCard]);
    setCurrentIndex(rulesGroundTruth.length); // Update currentIndex to point to the new rule
    setCurrentCardIndex(0); // Reset current card index
  };

  // NOTE: This is a quick fix to get the matching cards for the current rule
  // TODO: sanitize the card filtering logic
  const getMatchingCards = () => {
    const stripIdFromLogic = (logicArray) => {
      return logicArray.map(({ _id, ...rest }) => rest); // Removes _id field, only keeps other fields
    };
    return ruleTests.filter(
      (card) =>
        _.isEqual(card.rule?.ruleName, rules[currentIndex]?.ruleName) &&
        _.isEqual(stripIdFromLogic(card.rule?.logic), stripIdFromLogic(rules[currentIndex]?.logic))
    );
  };

  return (
    <div className="rules-wrapper">
      <div className="rules">
        <div className="rule-wrapper">
          <div className="rule-header">
            <Card className="card-2" variant="filled">
              <div className="text-wrapper-10">{t('addNewRule')}</div>
            </Card>
          </div>
          <div className="rule-body">
            <RulesOverview
              rules={rules}
              setRules={setRules}
              ruleTests={ruleTests}
              setRuleTests={setRuleTests}
              currentIndex={currentIndex}
              ruleEditOpen={ruleEditOpen}
              setRuleEditOpen={setRuleEditOpen}
              handleViewRule={handleViewRule}
              handleAddRule={handleAddRule}
              handleSave={handleSaveCard}
              handleEditRule={handleEditRule}
              handleDeleteRule={handleDeleteRule}
              deleteRule={deleteRule}
            />
          </div>
        </div>
      </div>
      <div className="cards">
        <RuleTestCard
          matchingCards={getMatchingCards()}
          rules={rules}
          setRules={setRules}
          ruleTests={ruleTests}
          setRuleTests={setRuleTests}
          currentIndex={currentIndex}
          currentCardIndex={currentCardIndex}
          setCurrentCardIndex={setCurrentCardIndex}
          qualityStation={qualityStation}
          orderIdentifierMapping={orderIdentifierMapping}
          restoreChange={restoreChange}
          handlePreviousCard={handlePreviousCard}
          handleNextCard={handleNextCard}
          handleDeleteCard={handleDeleteCard}
          handleAddCard={handleAddCard}
          handleSaveCard={handleSaveCard}
        />
      </div>
      <CardEditDialogs
        deleteCardWarningDialog={{
          deleteCardWarningOpen,
          setDeleteCardWarningOpen,
          deleteCard,
        }}
        discardChangesWarningDialog={{
          discardChangesWarningOpen,
          setDiscardChangesWarningOpen,
          discardChanges,
        }}
      />
    </div>
  );
};

RuleTests.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  ruleTestsGroundTruth: PropTypes.array.isRequired,
  setRuleTestsGroundTruth: PropTypes.func.isRequired,
  ruleTests: PropTypes.array.isRequired,
  setRuleTests: PropTypes.func.isRequired,
  qualityStation: PropTypes.string.isRequired,
  setQualityStationStats: PropTypes.func.isRequired,
  orderIdentifierMapping: PropTypes.object.isRequired,
  restoreChange: PropTypes.func.isRequired,
};

export default RuleTests;
