import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * A generic confirmation dialog component.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Controls if the dialog is open.
 * @param {string} props.title - The title of the dialog.
 * @param {string} props.content - The content of the dialog.
 * @param {string} props.confirmButtonText - Text for the confirm button.
 * @param {string} props.cancelButtonText - Text for the cancel button.
 * @param {Function} props.onConfirm - Callback function when confirmed.
 * @param {Function} props.onCancel - Callback function when canceled.
 */
const ConfirmationDialog = ({
  open,
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog open={open} onClose={onCancel} variant="qualitatio">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="qualitatio">
          {confirmButtonText}
        </Button>
        <Button onClick={onCancel} color="secondary" variant="qualitatio">
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export default ConfirmationDialog;
