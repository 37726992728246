
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import QualitatioButton from '../QualitatioButton/QualitatioButton';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import './QualitatioEmptyDialog.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QualitatioEmptyDialog = ({
    title,
    description,
    open,
    onClose,
    actions,
    children,
    disableEscapeKeyDown = true,
    ...otherProps
}) => {
    const defaultMuiTheme = createTheme();
    return (
        <MuiThemeProvider theme={defaultMuiTheme}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="qualitatio-dialog-description"
                maxWidth="lg"
                fullWidth={true}
                disableEscapeKeyDown={disableEscapeKeyDown}
                {...otherProps}
            >
                <DialogContent>
                    {children ?
                        children
                        :
                        <DialogContentText id="qualitatio-dialog-description">
                            {description}
                        </DialogContentText>
                    }
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};

export default QualitatioEmptyDialog;
