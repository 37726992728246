import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import zxcvbn from 'zxcvbn';
import axiosClient from '../../../api/axiosClient';
import { usersEndpoint } from '../../../api/endpoints';
import ROLES from '../../../authentication/roleConst';
import { QualitatioLanguageSelector } from '../../../components';
import { capitalizeFirstLetter } from '../../../utils/utilities';
import { addUserValidationSchema } from '../../../utils/validationSchemas';

export default function UserAddOverlay({ onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      role: '',
      authSystem: '',
      language: 'en',
    },
    validationSchema: addUserValidationSchema(i18n.language),
    onSubmit: (values) => {
      values.authSystem = 'password';
      axiosClient
        .post(usersEndpoint, values)
        .then((response) => {
          if (response.data && !response.data.error) {
            enqueueSnackbar(t('userAddedSuccessSnack'), { variant: 'success' });
            onClose();
          } else if (response.data && response.data.error) {
            enqueueSnackbar(t(response.data.message), { variant: 'error' });
          } else {
            throw new Error('Unexpected error');
          }
        })
        .catch((error) => {
          // Handling unexpected errors
          enqueueSnackbar(t(error.response.data.message), { variant: 'error' });
          console.error('Error adding user:', error);
        });
    },
  });

  return (
    <Dialog open={true} onClose={onClose} variant="qualitatio">
      <DialogTitle>{t('createUserTitle')}</DialogTitle>
      <Typography variant="subtitle1" align="center">
        {t('createUserDescription')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('name')}
                label={t('name')}
                placeholder={t('name')}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateOutlinedIcon
                        color={
                          formik.touched.name && Boolean(formik.errors.name) ? 'error' : 'primary'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('email')}
                label={t('email')}
                placeholder={t('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateOutlinedIcon
                        color={
                          formik.touched.email && Boolean(formik.errors.email) ? 'error' : 'primary'
                        }
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                variant="qualitatio"
                options={Object.values(ROLES)}
                getOptionLabel={(option) => capitalizeFirstLetter(option)}
                value={formik.values.role}
                onChange={(event, newValue) => {
                  formik.setFieldValue('role', newValue);
                }}
                onBlur={() => formik.setFieldTouched('role')}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('role')}
                    label={t('role')}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                    fullWidth
                  />
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...formik.getFieldProps('password')}
                placeholder={t('password')}
                label={t('password')}
                type={showPassword ? 'text' : 'password'}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={
                  (formik.touched.password && formik.errors.password) ||
                  `Password strength: ${zxcvbn(formik.values.password).score}/4`
                }
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handlePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <QualitatioLanguageSelector
                value={formik.values.language || ''}
                onChange={(event, newValue) => {
                  formik.setFieldValue('language', newValue);
                }}
                onBlur={() => formik.setFieldTouched('language')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="qualitatio"
            endIcon={<ArrowForwardIosIcon />}
          >
            {t('create')}
          </Button>
          <Button
            onClick={onClose}
            color="secondary"
            variant="qualitatio"
            startIcon={<ArrowBackIosNewRoundedIcon />}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
