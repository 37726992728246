import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDatabase, FaRocket, FaServer } from 'react-icons/fa';
import { useDisclosure } from '../../../utils/customHooks';
import ApiConnectionForm from './ApiConnectionForm';
import ExamplesDialog from './ExamplesDialog';
import './InputStreamSummary.css';
import KafkaConnectionForm from './KafkaConnectionForm';

const InputStreamSummary = ({ readonly, formik }) => {
  const { t } = useTranslation();

  const [streamInputDataIsOpen, setStreamInputDataIsOpen] = useState(false);
  const [streamMetaDataIsOpen, setStreamMetaDataIsOpen] = useState(false);
  const [streamConnectionDataIsOpen, setStreamConnectionDataIsOpen] = useState(false);

  const typeColorMapping = {
    kafka: { color: 'green', icon: <FaRocket /> },
    database: { color: 'blue', icon: <FaDatabase /> },
    api: { color: 'red', icon: <FaServer /> },
  };

  const { color, icon } = typeColorMapping[formik.values.type] || {};

  /** DISPLAY FUNCTIONS */

  const [selectedExamples, setSelectedExamples] = useState([]);
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const {
    isOpen: isShowExamples,
    onOpen: onShowExamples,
    onClose: onShowExamplesClose,
  } = useDisclosure();

  const handleButtonClick = (name, ind) => {
    setSelectedFieldName(name);
    setSelectedExamples(formik.values.streamFields[ind].examples);
    onShowExamples();
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '20px' }}>
      <Grid item sm={12} container justifyContent="center">
        <TextField
          fullWidth
          label={t('inputSourceName')}
          {...formik.getFieldProps('name')}
          error={formik.touched.name && formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item sm={12} container justifyContent="center">
        <TextField
          fullWidth
          label={t('inputSourceDescription')}
          {...formik.getFieldProps('description')}
          error={formik.touched.description && formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>
      <Grid item sm={12} container justifyContent="center">
        <FormControl fullWidth>
          <Box display="flex" alignItems="center">
            <Box mr={3}>{icon}</Box>
            <Typography>{t(formik.values.type)}</Typography>
          </Box>
        </FormControl>
      </Grid>

      <Grid item sm={12} container justifyContent="center">
        <Accordion
          expanded={streamInputDataIsOpen}
          onChange={() => setStreamInputDataIsOpen(!streamInputDataIsOpen)}
          sx={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={streamInputDataIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            <Typography variant="h6">{t('streamInputData')}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container justifyContent="center" spacing={2} direction="row">
              {formik.values?.streamFields?.map((elInputField, ind) => (
                <Grid item justifyContent="center">
                  <Chip
                    label={elInputField.name}
                    variant="qualitatio"
                    onClick={(e) => {
                      handleButtonClick(elInputField.name, ind);
                    }}
                  />
                </Grid>
              ))}
              <ExamplesDialog
                onClose={onShowExamplesClose}
                open={isShowExamples}
                fieldName={selectedFieldName}
                examples={selectedExamples}
              />

              <Grid item sm={12} container justifyContent="center">
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    {...formik.getFieldProps('productIDFieldName')}
                    onChange={(event, newValue) =>
                      formik.setFieldValue('productIDFieldName', newValue)
                    }
                    options={
                      formik.values.streamFields?.map((elInputField) => elInputField.name) || []
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t('inputSourceProductIDFieldName')} />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item sm={12} container justifyContent="center">
        <Accordion
          expanded={streamMetaDataIsOpen}
          onChange={() => setStreamMetaDataIsOpen(!streamMetaDataIsOpen)}
        >
          <AccordionSummary
            expandIcon={streamMetaDataIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            <Typography variant="h6">{t('streamMetaData')}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('maxSize')}
                  {...formik.getFieldProps('maxSize')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                  }}
                  error={Boolean(formik.touched.maxSize && formik.errors.maxSize)}
                  helperText={formik.touched.maxSize && formik.errors.maxSize}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t('currentStreamSize')}
                  value={formik.values.size || 0}
                  readOnly
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item sm={12} container justifyContent="center">
        <Accordion
          expanded={streamConnectionDataIsOpen}
          onChange={() => setStreamConnectionDataIsOpen(!streamConnectionDataIsOpen)}
        >
          <AccordionSummary
            expandIcon={streamConnectionDataIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            <Typography variant="h6">{t('streamConnectionData')}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <FormControl>
              {formik.values.type === 'kafka' ? (
                <KafkaConnectionForm readonly={readonly} formik={formik} />
              ) : (
                <ApiConnectionForm readonly={readonly} formik={formik} />
              )}
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default InputStreamSummary;
