/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const IconsNio = ({ className }) => {
  return (
    <div className={`icons-nio ${className}`}>
      <img
        className="close-cross-in"
        alt="Close cross in"
        src="/images/Cross.svg"
      />
    </div>
  );
};
