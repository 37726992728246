import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import './QualitatioInput.css';

const QualitatioInput = ({
  label,
  value,
  onChange,
  width,
  disabled,
  margin,
  placeholder,
  compact = false,
  xs = false,
  readOnly = false,
  type = 'text', // default to text input
  showInput = true,
  toggleShowInput,
  endAdornment,
  otherInputProps = {},
  inputPropsWidth = null,
  multiline = false,
  ...otherProps
}) => {
  const style = {
    width: width || 'auto',
    background: 'white',
    margin: margin || '0',
  };

  const [showEndAdornment, setShowEndAdornment] = React.useState(false);

  const onFocus = () => {
    setShowEndAdornment(true);
  };

  const onBlur = () => {
    setShowEndAdornment(false);
  };

  return (
    <TextField
      label={label}
      className={
        'qualitatio-input' +
        (compact
          ? multiline
            ? ' compact-input-multiline'
            : ' compact-input'
          : xs
            ? ' xs-input'
            : '')
      }
      multiline={multiline}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      type={type === 'password' ? (showInput ? 'text' : 'password') : type}
      disabled={disabled}
      style={style} // Ensure width is set to 100%
      variant="outlined"
      InputProps={{
        className: 'MuiOutlinedInput-root', // Optionally, define a class name for additional styling
        readOnly: readOnly,
        endAdornment:
          type === 'password' ? (
            <InputAdornment position="end">
              {showInput ? (
                <AiOutlineEye onClick={toggleShowInput} className="password-icon" />
              ) : (
                <AiOutlineEyeInvisible onClick={toggleShowInput} className="password-icon" />
              )}
            </InputAdornment>
          ) : (
            (showEndAdornment || value) && endAdornment
          ),
        ...otherInputProps,
        style: { width: inputPropsWidth || '' }, // Apply width to InputProps as well
      }}
      InputLabelProps={{
        shrink:
          type === 'date' ||
          type === 'time' ||
          type === 'datetime-local' ||
          type === 'file' ||
          value
            ? true
            : undefined,
      }}
      {...otherProps}
    />
  );
};

export default QualitatioInput;
