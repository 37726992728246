import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TestCard from './TestCard';
import TestCardsNavigation from './TestCardNavigation';
import TestCardsManipulation from './TestCardManipulation';
import { uploadCardImageToServer } from '../utils';
import styles from './TestCard.module.css';

const TestCardWrapper = ({
  cardsToDisplay,
  cardType,
  currentCardIndex,
  setCurrentCardIndex,
  setCardField,
  testCardProps,
  handlePreviousCard,
  handleNextCard,
  isAddCardDisabled,
  isCopilotButtonPresent,
  copilotButtonProps,
  handleDeleteCard,
  handleAddCard,
  handleSaveCard,
}) => {
  const theme = useTheme();
  const cardToDisplay =
    cardsToDisplay.length > 0 && currentCardIndex < cardsToDisplay.length
      ? cardsToDisplay[currentCardIndex]
      : null;

  const setImages = async (newImage) => {
    let newFileURL = '';
    if (typeof newImage === 'string') {
      newFileURL = newImage;
    } else {
      newFileURL = await uploadCardImageToServer(newImage, testCardProps.qualityStation, cardType);
    }

    // Define the modifier function to handle the image upload
    // parameters:
    // - images: the previous field value, in this case images array
    // - newFileURL: the new image to set
    const addNewImage = (images, newFileURL) => {
      const updatedImages = new Set([...images, newFileURL]);
      return Array.from(updatedImages);
    };

    // Assumes setCardField takes a modifier function that receives two arguments - the current value and the new value
    // Since we pass a function that only takes one argument and newFileURL is captured from the outer scope
    // the modifier function will ignore the second argument it receives (in this case null).
    setCardField('images', null, (images) => addNewImage(images, newFileURL));
  };

  const deleteImage = (imageIndex) => {
    const removeImageByIndex = (images, imageIndex) => {
      const updatedImages = images.filter((_, index) => index !== imageIndex);
      return updatedImages;
    };
    // Assumes setCardField takes a modifier function that receives two arguments - the current value and the new value
    setCardField('images', imageIndex, removeImageByIndex);
  };

  return (
    <>
      {cardsToDisplay.length > 0 && (
        <TestCard
          id={cardToDisplay?.id || ''}
          title={cardToDisplay?.title}
          setTitle={(newTitle) => setCardField('title', newTitle)}
          images={cardToDisplay?.images}
          setImages={setImages}
          testObject={cardToDisplay?.testObject}
          setTestObject={(newTestObject) => setCardField('testObject', newTestObject)}
          testMethod={cardToDisplay?.testMethod}
          setTestMethod={(newTestMethod) => setCardField('testMethod', newTestMethod)}
          testLocation={cardToDisplay?.testLocation}
          setTestLocation={(newTestLocation) => setCardField('testLocation', newTestLocation)}
          testComment={cardToDisplay?.testComment}
          setTestComment={(newTestComment) => setCardField('testComment', newTestComment)}
          orderIdentifier={cardToDisplay?.orderIdentifier}
          setOrderIdentifier={(newOrderIdentifier) =>
            setCardField('orderIdentifier', newOrderIdentifier)
          }
          deleteImage={deleteImage}
          explanation={cardToDisplay?.explanation}
          numberCards={cardsToDisplay?.length}
          editors={cardToDisplay?.editors}
          creator={cardToDisplay?.creator}
          creationDate={cardToDisplay?.creationDate}
          {...testCardProps}
        />
      )}
      <TestCardsNavigation
        cards={cardsToDisplay}
        currentCardIndex={currentCardIndex}
        setCurrentCardIndex={setCurrentCardIndex}
        handlePreviousCard={handlePreviousCard}
        handleNextCard={handleNextCard}
      />
      <TestCardsManipulation
        cards={cardsToDisplay}
        isAddCardDisabled={isAddCardDisabled}
        isCopilotButtonPresent={isCopilotButtonPresent}
        copilotButtonProps={copilotButtonProps}
        handleDeleteCard={handleDeleteCard}
        handleAddCard={handleAddCard}
        handleSaveCard={handleSaveCard}
      />
    </>
  );
};

TestCardWrapper.propTypes = {
  cardsToDisplay: PropTypes.array.isRequired,
  cardType: PropTypes.string.isRequired,
  currentCardIndex: PropTypes.number.isRequired,
  setCurrentCardIndex: PropTypes.func.isRequired,
  setCardField: PropTypes.func.isRequired, // Function that sets a field in the card object and applies an modifier if provided
  testCardProps: PropTypes.object.isRequired,
  handlePreviousCard: PropTypes.func.isRequired,
  handleNextCard: PropTypes.func.isRequired,
  isAddCardDisabled: PropTypes.bool.isRequired,
  isCopilotButtonPresent: PropTypes.bool.isRequired,
  copilotButtonProps: PropTypes.object,
  handleDeleteCard: PropTypes.func.isRequired,
  handleAddCard: PropTypes.func.isRequired,
  handleSaveCard: PropTypes.func.isRequired,
};

export default TestCardWrapper;
