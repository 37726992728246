const IOButton = ({ label, onClick }) => {
    
    return (
        <div className="io-frame">
            <div className="i-o-wrapper">
                <div className="group-wrapper" onClick={() => onClick()}>
                    <div className="mask-group-wrapper">
                        <img
                            className="mask-group-3"
                            alt="Mask group"
                            src="/images/Check.svg"
                        />
                    </div>
                </div>
            </div>
            <div className="div-wrapper">
                <div className="text-wrapper-7">{label}</div>
            </div>
        </div>
    )
}

export default IOButton;