import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

/**
 * Renders a frame for a page with a title and an icon and the content inside it.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the page frame.
 * @param {React.ElementType} props.Icon - The icon component to be displayed.
 * @param {boolean} props.particlesActive - Whether the particles are active or not.
 * @param {React.ReactNode} props.children - The content to be rendered inside the page frame.
 * @returns {React.ReactNode} The rendered page frame component.
 */
const PageFrame = ({ title, Icon, particlesActive = false, children }) => {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflowX: 'auto',
      }}
    >
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            gap: 1,
            height: '72px',
            paddingX: 4,
            width: '100%',
            backgroundColor: 'primary.main',
            zIndex: 2,
          }}
        >
          <Icon fill="black" />
          <Typography fontSize="24px">{title}</Typography>
        </Box>
        {particlesActive && (
          <Particles
            id="tsparticles"
            url="/particlesNavScreens.json"
            init={particlesInit}
            loaded={particlesLoaded}
          />
        )}
        <Box sx={{ padding: '2% 5%' }}>{children}</Box>
      </Box>
    </Box>
  );
};

PageFrame.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  particlesActive: PropTypes.bool,
};

export default PageFrame;
