import React, { useEffect } from 'react';
import gsap from 'gsap';
import './LoadingPage.css';

function LoadingPage() {

  useEffect(() => {
    const logo = document.getElementById("qualitatioLogo");
    const tl = gsap.timeline();

    tl.from(logo, {
      duration: 0.1,
      scale: 7,
    })
      .to(logo, {
        duration: 1,
        scale: 1,
      })
      .add(function() {
        // Add strong glow first
        logo.style.animation = "glowAnimationStrong 4.5s infinite alternate";
      })
      .to(logo, {
        duration: 4,
        rotation: 1800,
        scale: 1.5,
        ease: "power3.inOut",
        onStart: function() {
          // Once strong animation completes, switch to weak glow
          logo.style.animation = "glowAnimationWeak 2.5s infinite alternate";
        }
      });

  }, []);

  return (
    <div className="loading-page">
      <div id="smoke" className="smoke"></div>
      <div id="sparks" className="sparks"></div>
      <img id="qualitatioLogo" className="logo" alt="Logo" src="/images/qualitatio.svg" />
      <div className="qualitatio-title">
        Qualitatio
      </div>
      <div className="qualitatio-slogan">
        AI-Powered Product-Specific Quality Management
      </div>
    </div>
  );
}

export default LoadingPage;
