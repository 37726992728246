import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosClient from '../../../api/axiosClient';
import { sendFeedbackEndpoint } from '../../../api/endpoints';
import { BottomBarWhite } from '../../../components/BottomBarWhite';
import QualitatioButton from '../../../components/QualitatioButton/QualitatioButton';
import { useAuthStore } from '../../../store/auth.store';

import './TestFeedback.css';

export default function TestFeedback({
  productID,
  cardID,
  isFeedbackOpen,
  setIsFeedbackOpen,
  recommenderMenuOpen,
  setRecommenderMenuOpen,
  testTitle,
  testImages,
  testObject,
  testMethod,
  testLocation,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const theme = useTheme();
  const { t } = useTranslation();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && comment) {
      document.getElementById('link').click();
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const fontSize = isSmallScreen ? '0.8rem' : isMediumScreen ? '1.0rem' : '1.2rem';

  // State variables
  const [comment, setComment] = useState('');
  const [imageRating, setImageRating] = useState(0);
  const [descriptionRating, setDescriptionRating] = useState(0);
  const [valuableInformation, setValuableInformation] = useState({});

  // State for selected option
  const [selectedOption, setSelectedOption] = useState(null);

  // Prepare dropdown options
  const dropdownOptions = [];

  if (testTitle) {
    dropdownOptions.push({
      key: 'Test Title',
      value: testTitle.toString(),
      label: `Test Title: ${testTitle.toString()}`,
      type: 'text',
    });
  }

  if (testObject) {
    const objectContent =
      typeof testObject === 'string' ? testObject : JSON.stringify(testObject, null, 2);
    dropdownOptions.push({
      key: 'Test Object',
      value: objectContent,
      label: `Test Object: ${objectContent}`,
      type: 'object',
    });
  }

  if (testMethod) {
    dropdownOptions.push({
      key: 'Test Method',
      value: testMethod.toString(),
      label: `Test Method: ${testMethod.toString()}`,
      type: 'text',
    });
  }

  if (testLocation) {
    dropdownOptions.push({
      key: 'Test Location',
      value: testLocation.toString(),
      label: `Test Location: ${testLocation.toString()}`,
      type: 'text',
    });
  }

  if (testImages && testImages.length > 0) {
    testImages.forEach((imageSrc, index) => {
      if (imageSrc !== '') {
        // Exclude empty strings
        dropdownOptions.push({
          key: `Images${index + 1}`,
          value: imageSrc,
          label: `Images  ${index + 1}:`,
          type: 'image',
        });
      }
    });
  }
  // Handle form submission
  const sendFeedback = async () => {
    if (!imageRating || !descriptionRating || !valuableInformation) {
      enqueueSnackbar(t('pleaseFillAllRequiredFields'), { variant: 'warning' });
      return;
    }
    try {
      await axiosClient.post(sendFeedbackEndpoint, {
        productID: productID,
        cardID: cardID,
        comment: comment,
        userID: user.id,
        descriptionRating: imageRating,
        imageRating: descriptionRating,
        valuableInformation: valuableInformation,
      });
      enqueueSnackbar(t('thankYouForYourFeedback'), { variant: 'success' });
      setImageRating(0);
      setDescriptionRating(0);
      setValuableInformation({});
      setComment('');
      setSelectedOption(null);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('errorSendingFeedback'), { variant: 'error' });
    }
    setIsFeedbackOpen(false);
  };

  return (
    <>
      {recommenderMenuOpen && (
        <div className={'test-feedback-layout-' + (isFeedbackOpen ? 'display' : 'hide')}>
          <div className="test-feedback-header">{t('thanksForYourFeedback')}</div>
          <div className="test-feedback-icon">
            <img className="test-feedback-image" alt="Feedback" src="/images/feedback.svg" />
          </div>
          <div className="test-feedback-input">
            <Box component="fieldset" borderColor="transparent" className="rating-container">
              <div className="test-feedback-text">
                <Typography variant="caption " style={{ fontSize }}>
                  {t('howWellIsThisTestDescribed')}
                </Typography>
              </div>
              <Rating
                name="image-rating"
                value={imageRating}
                onChange={(event, newValue) => {
                  setImageRating(newValue);
                }}
                precision={1}
                size="large"
              />
            </Box>
            <Box component="fieldset" borderColor="transparent" className="rating-container">
              <div className="test-feedback-text">
                <Typography variant="caption" style={{ fontSize }}>
                  {t('howHelpfulAreTheImages')}
                </Typography>
              </div>
              <Rating
                name="description-rating"
                value={descriptionRating}
                onChange={(event, newValue) => {
                  setDescriptionRating(newValue);
                }}
                precision={1}
                size="large"
              />
            </Box>
            <div className="test-feedback-text">
              <Typography variant="caption" style={{ fontSize }}>
                {t('whatHelpedYouToBestUnderstandTheTest')}
              </Typography>
            </div>
            <div className="test-feedback-input" style={{ width: '70%' }}>
              <Autocomplete
                id="test_info_autocomplete"
                options={dropdownOptions}
                getOptionLabel={(option) => option.label || ''}
                renderOption={(props, option) => {
                  const { key, ...rest } = props;
                  return (
                    <li key={key} {...rest}>
                      {option.type === 'image' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={option.value}
                            alt={option.key}
                            style={{
                              width: '20px',
                              height: '20px',
                              objectFit: 'cover',
                              marginRight: '4px',
                            }}
                          />
                          <span>{option.label}</span>
                        </Box>
                      ) : (
                        <span style={{ whiteSpace: 'pre-wrap' }}>{option.label}</span>
                      )}
                    </li>
                  );
                }}
                renderInput={(params) => {
                  const isImageSelected = selectedOption && selectedOption.type === 'image';

                  return (
                    <TextField
                      {...params}
                      label={t('mostValuableInformation')}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: isImageSelected ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              src={selectedOption.value}
                              alt={selectedOption.key}
                              style={{
                                width: '20px',
                                height: '20px',
                                objectFit: 'cover',
                                marginRight: '8px',
                              }}
                            />
                          </Box>
                        ) : null,
                      }}
                      value={isImageSelected ? '' : (selectedOption && selectedOption.label) || ''}
                    />
                  );
                }}
                onChange={(event, newValue) => {
                  setSelectedOption(newValue);
                  if (newValue) {
                    // Update valuableInformation with key, value, and type properties
                    setValuableInformation({
                      key: newValue.key,
                      value: newValue.value,
                      type: newValue.type,
                    });
                  } else {
                    // Reset if no value is selected
                    setValuableInformation({});
                  }
                }}
                value={selectedOption}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                style={{ width: '100%', marginBottom: '16px' }}
              />
            </div>
            <textarea
              placeholder={`${t('addComment')}...`}
              className="test-feedback-textarea"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              onKeyDown={handleKeyPress}
              width="70%"
            />
          </div>
          <div className="test-feedback-buttons">
            <QualitatioButton
              text={t('save')}
              id="link"
              order="primary"
              width={'70%'}
              fontSize={'calc(12px + 0.25vw)'}
              endIcon={<ArrowForwardIosRoundedIcon />}
              disabled={!imageRating || !descriptionRating || !valuableInformation}
              onClick={sendFeedback}
            />
            <QualitatioButton
              text={t('cancel')}
              order="secondary"
              width={'70%'}
              height={'5vh'}
              fontSize={'calc(12px + 0.25vw)'}
              startIcon={<ArrowBackIosNewRoundedIcon />}
              onClick={() => setIsFeedbackOpen(false)}
            />
          </div>
          <div className="test-feedback-bottom-bar">
            <BottomBarWhite className="bottom-bar-white-instance" />
          </div>
        </div>
      )}
    </>
  );
}
